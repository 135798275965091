<div class="active-dispatch-pane" flex="100" layout-gt-md="row" layout-md="row">
  <div class="mat-icon" flex="5" layout="column">
    <img class="dispatching-job" src="assets/yda/spinner.gif"/>
  </div>
  <div class=" push-left push-right subtitle" flex="95" layout-gt-md="column"
       layout-md="column">
    {{this.subTitle|translate|ucFirst}}

    <button (click)="this.stopDispatch.next()" class="push-top push-right" color="warn" flex
            mat-raised-button>
      {{'stop_automated_dispatch'|translate|ucFirst}}</button>
  </div>
</div>
