import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DynamicFieldDirective} from './directives/dynamic-field.directive';
import {DynamicFormComponent} from './containers/dynamic-form.component';
import {FormInputComponent} from './components/form-input/form-input.component';
import {FormSelectComponent} from './components/form-select/form-select.component';
import {FormButtonComponent} from './components/form-button/form-button.component';
import {MaterialInputComponent} from './components/material-input/material-input.component';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MaterialSelectComponent} from './components/material-select/material-select.component';
import {CommissionInputComponent} from './components/commission-input/commission-input.component';
import {CovalentMessageModule} from '@covalent/core/message';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {UcFirstPipeModule} from '../../../pipes/uc-first.pipe';
import {ReplacePipeModule} from '../../../pipes/replace.pipe';
import {MaterialButtonComponent} from './components/material-button/material-button.component';


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'https://localization-api.dispatchapi.io/api/Projects/591afd8a17f51db67fc57968/', '');
}

@NgModule({
  imports: [
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    UcFirstPipeModule,
    ReplacePipeModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatButtonModule,
    CovalentMessageModule
  ],
  declarations: [
    DynamicFieldDirective,
    DynamicFormComponent,
    FormInputComponent,
    FormSelectComponent,
    FormButtonComponent,
    MaterialInputComponent,
    MaterialSelectComponent,
    MaterialButtonComponent,
    CommissionInputComponent
  ],
  exports: [
    DynamicFormComponent,
    MaterialButtonComponent
  ]
})
export class DynamicFormModule {
}
