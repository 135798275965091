<div class="filter push-left-sm push-right-sm">
  <div class="drop-downs">
    <mat-form-field class="push-sm push-bottom-none max-200" flex="25">
      <mat-select (selectionChange)="onToChanged($event)" [(ngModel)]="filterData.monthValue"
                  placeholder="{{'month'|translate|ucFirst}}">
        <mat-option *ngFor="let month of monthFilterValues; let i = index;" [value]="month.value">
          {{month.desc|ucFirst}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="type==='toBeInvoiced'" class="push-sm push-bottom-none max-200" flex="25">
      <mat-select (selectionChange)="onPaymentMethodChange($event)"
                  [(ngModel)]="filterData.paymentMethods"
                  class="row-select"
                  flex="100"
                  multiple
                  placeholder="{{ 'payment_methods' | translate | ucFirst }}" required>
        <mat-option *ngFor="let paymentMethod of paymentMethods" [value]="paymentMethod.id">
          {{ paymentMethod.description | translate | ucFirst}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="type==='toBeInvoiced'" class="push-sm push-bottom-none max-400" flex="50">
      <mat-select (selectionChange)="onDebtorChanged($event)"
                  [(ngModel)]="filterData.debtor"
                  class="row-select"
                  flex="100"
                  placeholder="{{ 'debtors' | translate | ucFirst }}">
        <mat-option value="all">
          {{ 'all_debtors' | translate | ucFirst}}
        </mat-option>
        <mat-option *ngFor="let debtor of debtors" [value]="debtor.id">
          {{ debtor.companyName | translate | ucFirst}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <td-search-box (searchDebounce)="onSearchChanged($event)" [alwaysVisible]="true"
                 [value]="searchTerm" class="push-sm push-bottom-none search-bar" placeholder="{{'search_here'|translate}}"></td-search-box>
</div>
