import {Component, OnInit, ViewChild} from '@angular/core';
import {NavigationService} from '../../../../../services/navigation.service';
import {IPageChangeEvent} from '@covalent/core/paging';
import {TdLoadingService} from '@covalent/core/loading';
import {TdMediaService} from '@covalent/core/media';
import {ITdDataTableSortChangeEvent, TdDataTableService, TdDataTableSortingOrder} from '@covalent/core/data-table';
import {Title} from '@angular/platform-browser';
import {RideService} from '../../../../../services/ride.service';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../../../environments/environment';
import {Ride} from '../../../../../models/ride';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {AgmMap} from '@agm/core';
import {DriverService} from '../../../../../services/driver.service';
import {CompanyWebUserService} from '../../../../../services/companyWebUser.service';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Company} from '../../../../../models/company';

@Component({
  selector: 'app-ride-dispatch-log',
  templateUrl: './ride-dispatch-log.component.html',
  styleUrls: ['./ride-dispatch-log.component.scss'],
  providers: [RideService]
})
export class RideDispatchLogComponent implements OnInit {
  @ViewChild(AgmMap) map: AgmMap;
  mapInstance: any;

  company: Company;
  companyId: string;
  rideId: string;
  translations: string[];
  ride: Ride = new Ride();
  data: any;
  selectedIndex: number;
  dataSet: any;

  tableData: any[] = [];
  tableFilteredData: any[] = [];
  tableFilteredTotal: number;

  tableSearchTerm = '';
  tableFromRow = 1;
  tableCurrentPage = 1;
  tablePageSize = 20;
  tableSortBy = 'jobOfferSent';
  tableSortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;

  columns = [
    {name: 'type', label: 'type', sortable: true, active: false},
    {name: 'name', label: 'name', sortable: true, active: false},
    {name: 'vehicleType', label: 'vehicle_type', sortable: true, active: false},
    {name: 'vehicleStatus', label: 'vehicle_status', sortable: true, active: false},
    {name: 'driverScore', label: 'driver_score', sortable: true, active: false},
    {name: 'straightDistance', label: 'straight_distance', sortable: true, active: false},
    {name: 'travelDistance', label: 'travel_distance', sortable: true, active: false},
    {name: 'etp', label: 'etp_short', sortable: true, active: false},
    {name: 'jobOfferSent', label: 'job_offer_sent', sortable: true, active: true},
    {name: 'jobOfferDelivered', label: 'job_offer_delivered', sortable: true, active: false},
    {name: 'jobOfferResponse', label: 'job_offer_response', sortable: true, active: false},
    {name: 'jobOfferResponseTime', label: 'job_offer_response_time', sortable: true, active: false},
  ];

  constructor(public media: TdMediaService,
              private _loadingService: TdLoadingService,
              private _activatedRoute: ActivatedRoute,
              private _rideService: RideService,
              private _driverService: DriverService,
              private _vault: CoolLocalStorage,
              private _companyWebUserService: CompanyWebUserService,
              private _translateService: TranslateService,
              private _dataTableService: TdDataTableService,
              private _navigationService: NavigationService,
              private _titleService: Title) {
    _translateService.get([
      'ride_dispatch_log', 'offer_type_driver', 'job_log_offer_created']).subscribe((translations: string[]) => {
      this._titleService.setTitle(translations['ride_dispatch_log'] + environment.windowTitleSuffix);
      this._navigationService.setBodyTitle(translations['ride_dispatch_log']);
      this._navigationService.setActiveSubmenu(this._activatedRoute.routeConfig['submenu']);
      this.translations = translations;
    });
    const params: any = this._activatedRoute.snapshot.params;
    const parentParams: any = this._activatedRoute.parent.snapshot.params;

    const {company} = this._activatedRoute.parent.snapshot.data;
    this.company = company;

    this.rideId = params.id;
    this.companyId = parentParams.id;
    _navigationService.setSplitLayoutButtons([
      {
        'icon': 'navigate_before',
        'tooltip': 'Back',
        'link': `/groups/${this.companyId}/jobs/${this.rideId}/details`
      },
      {
        'icon': 'edit',
        'tooltip': 'Edit',
        'link': `/groups/${this.companyId}/jobs/${this.rideId}/edit/`
      },
      {
        'icon': 'dashboard',
        'tooltip': 'Job details',
        'link': `/groups/${this.companyId}/jobs/${this.rideId}/`
      },
      {
        'icon': 'assignment',
        'tooltip': 'Dispatch Log',
        'link': `/groups/${this.companyId}/jobs/${this.rideId}/dispatch-log`
      }
    ]);
  }

  ngOnInit() {
    this._loadingService.register('data');
    this.loadData();
  }

  loadData() {
    this._rideService.get(this.rideId, {}).subscribe((ride) => {
      this.ride = ride;
      this._rideService.getDispatchSelection(this.rideId).subscribe((dispatchSelections) => {
        this.data = dispatchSelections;
        this.selectAttempt({value: dispatchSelections.length - 1})
        this._loadingService.resolve('data');
      }, (error) => {
        console.log(error);
        this._loadingService.resolve('data');
      })
    });
  }

  isValidDate(d: any) {
    return !isNaN(d) && d instanceof Date;
  }

  selectAttempt(event: any) {
    const self = this;
    this._loadingService.register('attempt');
    this.selectedIndex = event.value;
    this.dataSet = this.data[this.selectedIndex];
    this.tableData = [];
    console.log(this.data[this.selectedIndex]);
    console.log(this.selectedIndex);

    if (this.data[this.selectedIndex] && this.data[this.selectedIndex].Ride &&
      this.data[this.selectedIndex].Ride.RideLog &&
      this.data[this.selectedIndex].Ride.RideLog.Entries) {
      this.dataSet.createdBy = this.data[this.selectedIndex].Ride.RideLog.Entries.filter((entry) => {
        return (entry.action === 'offer_created')
      });
      this.dataSet.createdBy = this.dataSet.createdBy[this.dataSet.createdBy.length - 1];
    }

    if (this.dataSet.createdBy && this.dataSet.createdBy.origin &&
      this.dataSet.createdBy.origin.type === 'driver') {
      this._driverService.get(this.dataSet.createdBy.origin.driverId).subscribe((driver) => {
        // this.dataSet.createdByTxt = self.translations[this.dataSet.createdBy.item.languageTag].replace('{{dispatcher}}', `${driver.fname} ${driver.lname}`);
        this.dataSet.createdByTxt = `${driver.fname} ${driver.lname}`;
      });
    } else if (this.dataSet.createdBy && this.dataSet.createdBy.origin &&
      this.dataSet.createdBy.origin.type === 'portal') {
      this._companyWebUserService.get(this.dataSet.createdBy.origin.companyWebUserId, {'fields': ['id', 'fname', 'lname']}).subscribe((user) => {
        this.dataSet.createdByTxt = `${user.fname} ${user.lname}`;
      });
      this.dataSet.createdByTxt = 'system';
    } else if (this.dataSet.createdBy && this.dataSet.createdBy.item) {
      this.dataSet.createdByTxt = 'system';
    }

    this.dataSet.selectedDrivers.forEach((driver) => {
      const dataSet = {
        type: (this.dataSet.Offer ? self.translations[`offer_type_${this.dataSet.Offer.type}`] : ''),
        name: `${driver.fname} ${(driver.lname && driver.lname !== '-' ? driver.lname : '')}`,
        vehicleType: (driver.Vehicle ? driver.Vehicle.type : ''),
        vehicleStatus: (driver.Vehicle ? driver.Vehicle.status.code : ''),
        driverScore: '',
        straightDistance: '',
        travelDistance: '',
        etp: '',
        jobOfferSent: '',
        jobOfferDelivered: '',
        jobOfferResponse: '',
        jobOfferResponseTime: undefined,
        selected: false,
      };
      if (driver.dispatchMeta) {
        dataSet.driverScore = driver.dispatchMeta.driverScore;
        dataSet.straightDistance = (driver.dispatchMeta.straightDistance ? (driver.dispatchMeta.straightDistance / 1000).toFixed(2) : '');
        dataSet.travelDistance = (driver.dispatchMeta.distance ? (driver.dispatchMeta.distance / 1000).toFixed(2) : '');
        dataSet.etp = driver.dispatchMeta.etp;
      }

      if (driver.JobOffer) {
        const receivedDate = new Date(driver.JobOffer.received);
        const responseDateTime = new Date(receivedDate.getTime() + driver.JobOffer.responseTime);

        dataSet.jobOfferSent = driver.JobOffer.created;
        dataSet.jobOfferDelivered = driver.JobOffer.received;
        dataSet.jobOfferResponse = driver.JobOffer.response;
        dataSet.jobOfferResponseTime = (this.isValidDate(responseDateTime) ? responseDateTime : '');
        dataSet.selected = (driver.JobOffer.result === 'SELECTED');
      } else if (this.dataSet.Offer.preAssigned && this.dataSet.Offer.JobOffers && this.dataSet.Offer.JobOffers.length > 0) {
        const jobOffer = this.dataSet.Offer.JobOffers[0];
        const receivedDate = new Date(jobOffer.created);
        const responseDateTime = new Date(receivedDate.getTime() + jobOffer.responseTime);

        dataSet.jobOfferSent = jobOffer.created;
        dataSet.jobOfferDelivered = jobOffer.received;
        dataSet.jobOfferResponse = jobOffer.response;
        dataSet.jobOfferResponseTime = (this.isValidDate(responseDateTime) ? responseDateTime : '');
        dataSet.selected = (jobOffer.result === 'SELECTED');
      }
      this.tableData.push(dataSet);
    });

    this.tableFilteredData = this.tableData;
    this.tableFilteredTotal = this.tableFilteredData.length;
    this.tableFilter();
    setTimeout(() => {
      self._loadingService.resolve('attempt');
    }, 750)
  }

  tableSort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.tableSortBy = sortEvent.name;
    this.tableSortOrder = sortEvent.order;
    this.tableFilter();
  }

  tablePage(pagingEvent: IPageChangeEvent): void {
    this.tableFromRow = pagingEvent.fromRow;
    this.tableCurrentPage = pagingEvent.page;
    this.tablePageSize = pagingEvent.pageSize;
    this.tableFilter();
  }

  tableFilter(): void {
    let newData: any[] = this.tableData;
    newData = this._dataTableService.filterData(newData, this.tableSearchTerm, true);
    this.tableFilteredTotal = newData.length;
    newData = this._dataTableService.sortData(newData, this.tableSortBy, this.tableSortOrder);
    newData = this._dataTableService.pageData(newData, this.tableFromRow, this.tableCurrentPage * this.tablePageSize);
    this.tableFilteredData = newData;
  }

  tableSearch(searchTerm: string): void {
    this.tableSearchTerm = searchTerm;
    this.tableFilter();
  }

  //noinspection JSMethodCanBeStatic
  onTabChange(event: MatTabChangeEvent): void {
    if (event.index === 1) {
      if (this.dataSet) {
        this.map.triggerResize().then(res => {
        });
      }
    }
  }

  mapReady(map): void {
    this.mapInstance = map;
  }
}
