import {Component, Input} from '@angular/core';
import {AbstractControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {MatGridListModule} from '@angular/material/grid-list';
import {Rule} from 'app/models/rule';
import {Location} from '../../../../../../../../models/location';
import {LocationService} from '../../../../../../../../services/tps/location.service';
import {ActivatedRoute} from '@angular/router';

type RuleType = 'dynamic' | 'fixed';

interface AutoCompleteInput {
  type: string,
  name: string,
  tooltip: string,
  matTooltipPosition: 'above' | 'left' | 'right' | 'below',
  matTooltipShowDelay: number,
  placeholder: string,
  searchResults: Location[],
  location: AbstractControl,
  autoComplete: UntypedFormControl,
}

@Component({
  selector: 'app-location-column',
  templateUrl: './location-column.component.html',
  styleUrls: ['./location-column.component.scss'],
  providers: [LocationService]
})
export class LocationColumnComponent extends MatGridListModule {
  // If it's the first column, don't display values
  // but instead show column names
  @Input() ruleType: RuleType;
  @Input() form: UntypedFormGroup;
  @Input() rule: Rule;
  @Input() first: boolean;
  companyId: string;
  locations = [];

  constructor(
    private _locationService: LocationService,
    private _route: ActivatedRoute,
  ) {
    super();
    this._route.parent.params.subscribe(params =>
      this.companyId = params['id']);
    // this.loadData()
  }

  childRules = () => {
    return (this.form && this.form.controls.childRules
      ? this.form.controls.childRules['controls']
      : []);
  }
  isDynamic = () => 'dynamic' === this.ruleType;
  isFixed = () => 'fixed' === this.ruleType;

  /**
   * Load some latest locations that can be chosen.
   */
  loadData = async () => {
    const l = await this._locationService.getAllCached({
      where: {
        companyId: this.companyId
      },
      order: 'descriptor ASC'
    }).toPromise();

    this.locations = l;
  }
}
