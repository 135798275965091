<div class="template-outer-pane">
  <mat-card-title>{{'add_service_from_template_title'|translate|ucFirst}}</mat-card-title>
  <mat-card-subtitle>{{'add_service_from_template_subtitle'|translate|ucFirst}}</mat-card-subtitle>
  <mat-divider></mat-divider>
  <div *ngFor="let template of templates">
    <div class="flex-pane push-bottom" flex="100" layout="row">
      <div *ngIf="template.imagePath" class="image-pane" flex="25">
        <img src="{{template.imagePath}}">
      </div>
      <div *ngIf="!template.imagePath" class="image-pane" flex="25">
        <img src="assets/yda/no-image.png">
      </div>

      <div class="template-pane" flex="50" layout="column">
        <h3>{{template.name | translate | ucFirst}}</h3>
        <p>{{'max' | translate | ucFirst}} {{template.maxPassengers}} {{'passengers' | translate | ucFirst}}</p>
        <p class="extra-info">{{template.extraInfo}}</p>
      </div>

      <div class="template-pane" flex="25" layout="column">
        <button (click)="selectTemplate(template)" color="accent"
                mat-raised-button>{{(innerWidth <= 600 ? 'add' : 'select_product') | translate |
          ucFirst}}</button>
      </div>

    </div>
    <mat-divider></mat-divider>
  </div>
</div>
