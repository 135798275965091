class PriceBreakdownRule {
  total: number;
  taxPercentage: number;
}

export class PriceBreakdown {
  route: PriceBreakdownRule;
  toll: PriceBreakdownRule;
  parking: PriceBreakdownRule;
  waiting: PriceBreakdownRule;
  discount: PriceBreakdownRule;
  cleaning: PriceBreakdownRule;
  additional: PriceBreakdownRule;

  constructor() {
    this.route = {
      total: 0,
      taxPercentage: 0
    };
    this.toll = {
      total: 0,
      taxPercentage: 0
    };
    this.parking = {
      total: 0,
      taxPercentage: 0
    };
    this.waiting = {
      total: 0,
      taxPercentage: 0
    };
    this.discount = {
      total: 0,
      taxPercentage: 0
    };
    this.cleaning = {
      total: 0,
      taxPercentage: 0
    };
    this.additional = {
      total: 0,
      taxPercentage: 0
    };
  }
}
