import {AfterViewInit, Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Driver} from '../../../../../../../models/driver';
import {ActivatedRoute} from '@angular/router';
import {DriverService} from '../../../../../../../services/driver.service';
import {phoneNumberValidator} from '../../../../../../../pipes/phonenumber-validator.pipe';
import {CountryISO, SearchCountryField,} from 'ngx-intl-tel-input';
import {environment} from '../../../../../../../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Company} from '../../../../../../../models/company';
import {ucFirst} from '../../../../../../../pipes/uc-first.pipe';
import {Offer} from '../../../../../../../models/offer';
import {OfferService} from '../../../../../../../services/offer.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TdDialogService} from '@covalent/core/dialogs';
import {TdMediaService} from '@covalent/core/media';
import {debounceTime} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import {CompanyInvitationService} from '../../../../../../../services/company-invitation.service';
import {Ride} from '../../../../../../../models/ride';
import {DispatchDialogComponent} from '../../../dispatch-dialog/dispatch-dialog.component';
import {RideService} from '../../../../../../../services/ride.service';
import {GeoPoint} from '../../../../../../../models/geo-point';
import {MatTabGroup} from '@angular/material/tabs';
import {ValidateAllFormFields} from '../../../../../../../functions/validateAllFormFields';
import {ContactService} from '../../../../../../../services/contact.service';
import {CompanyWebUserService} from '../../../../../../../services/companyWebUser.service';
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions} from '@angular/material/tooltip';
import {UtilityService} from 'app/services/utility.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TdLoadingService} from '@covalent/core/loading';

@Component({
  selector: 'app-ride-edit-assign',
  templateUrl: './ride-edit-assign.component.html',
  styleUrls: ['./ride-edit-assign.component.scss'],
  providers: [
    RideService, {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: 0,
        hideDelay: 0, // default value
        touchendHideDelay: 2000, // default value
      } as MatTooltipDefaultOptions
    }
  ]
})
export class RideEditAssignComponent extends ValidateAllFormFields implements AfterViewInit, OnInit {
  @ViewChild('autoCompleteServiceHolder') autoCompleteServiceHolder;
  @ViewChild('tabs') tabGroup: MatTabGroup;
  @Output() reloadData = new EventEmitter<string>();
  @Input() ride: Ride;
  @Input() receiptUrl: string;
  @Input() driverNote: string;
  tab: string;
  currentTimeout: any;
  form: UntypedFormGroup;
  autoComplete = new UntypedFormControl();
  translations: string[];
  context = 'company';
  drivers: Driver[] = [];
  selectedDriver: any;
  company: Company;
  grandParent: any;
  driver: Driver;
  companyId: string;
  loading = false;
  reload = false;
  switchOverflow = false;
  newDriverDetected = false;
  selecting = false;
  statusTitle = '';
  statusHTML = '';
  statusTooltip = '';
  statusTooltipColor = '';
  openedClearConfirm = false;
  formErrors: any = {};
  validationMessages = {
    'driverName': {
      'fieldName': 'name',
      'required': 'form_validation_required',
    },
    'driverPhoneNumber': {
      'fieldName': 'phonenumber',
      'required': 'form_validation_required',
      'validatePhoneNumber': 'phonenumber_pattern_invalid',
    },
    'driverEmailAddress': {
      'fieldName': 'email',
      'required': 'form_validation_required',
    }
  };

  SearchCountryField = SearchCountryField;
  selectedTabIndex;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom
  ];
  data: any[] = [];
  emailData: any[] = [];

  rideId: string;
  reference: string;
  departureGps: GeoPoint;

  constructor(
    public media: TdMediaService,
    public snackBar: MatSnackBar,
    private _route: ActivatedRoute,
    private _driverService: DriverService,
    private _operatorService: CompanyWebUserService,
    private _loadingService: TdLoadingService,
    private _offerService: OfferService,
    private _companyInvitationService: CompanyInvitationService,
    private _vault: CoolLocalStorage,
    private _translationService: TranslateService,
    private _formBuilder: UntypedFormBuilder,
    private _rideService: RideService,
    private _dialogService: TdDialogService,
    private _contactService: ContactService,
    public dialogRef: MatDialogRef<DispatchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    super();
    this.loadErrorTranslations(_translationService);
    const self = this;
    if (self.dialogData && self.dialogData.parent && self.dialogData.parent.context) {
      self.context = self.dialogData.parent.context;
      self.companyId = self.dialogData.parent.companyId;
      self.company = self.dialogData.parent.company;
      self.ride = self.dialogData.ride;
      if (self.dialogData.parent.parent) {
        self.grandParent = self.dialogData.parent.parent
      }

    } else {
      const {company} = self._route.parent.snapshot.data;
      self.company = company;
      self.context = self._route.routeConfig['context'] || 'driver';
      self._route.parent.params.subscribe(params => {
        self.companyId = params['id'];
      });
    }

    _translationService.get(['yes', 'ok', 'no', 'cancel', 'return', 'unassign_job', 'confirm_unassign_driver', 'confirm_unassign_driver_title', 'job_dispatched', 'job_dispatched_stopped', 'job_dispatched_stopped_confirm', 'stop_automated_dispatch', 'job_dispatched_stopped', 'clear_driver_confirm', 'clear_driver_title', 'clear', 'cancel', 'job_assign_status_text_assigned_new_unconfirmed', 'job_assign_status_text_assigned_unconfirmed_tooltip', 'job_assign_status_text_assigned_unconfirmed', 'job_assign_status_text_assigned_confirmed']).subscribe((translations) => {
      this.translations = translations;
    });

    UtilityService.setBrowserTimeStamp(this.company);
    this.driver = this._vault.getObject(`${environment.vaultPrefix}.driver`);

    let query = {};

    if (this.context === 'company') {
      query = {
        'where': {
          companyId: self.companyId,
        },
        'include': [
          'ActiveVehicle',

        ],
        'order': [
          'type', 'firstName'
        ]
      };

      self._driverService.getAll(query, self.context).subscribe((allDrivers: any[]) => {
        const drivers = allDrivers.filter((d) => {
          return (d.status === 'accepted')
        });
        self.drivers = drivers;
        self.data = drivers;
        self.emailData = drivers;
        self._companyInvitationService.getAll({'where': {companyId: self.company.id, 'status': 'created'}}, self.context).subscribe((invites: any[]) => {
          /**
           * For some invites there already is a driver created
           */
          invites.map((i) => {
            const driver = allDrivers.filter((d) => {
              return (d.phoneNumber === i.phoneNumber);
            })[0];
            if (driver) {
              i.orignalDriverId = driver.id;
            } else {
              i.orignalDriverId = 'new-driver';
            }
          });
          self.drivers = self.data.concat(invites);
          self.data = self.drivers;
          self.emailData = self.emailData.concat(invites);
        });
      }, error => {
        console.error(error);
      });
    } else {
      query = {
        'where': {
          'driverId': self.driver.id,
          'type': 'driver'
        },
        'include': [{
          'relation': 'ConnectedDriver'
        }]
      };

      self.drivers = []
      this._contactService.getAll(query).subscribe((contacts: any[]) => {
        contacts.forEach(function (contact, i) {
          if (contact.ConnectedDriver) {
            self.drivers.push(contact.ConnectedDriver);
            self.data.push(contact.ConnectedDriver);
            self.emailData.push(contact.ConnectedDriver);
          }
        });
      });
    }
    this.selecting = true;
    this.reloadTab();
  }

  openDispatchDialog() {
    const self = this;
    if (self.selectedDriver && !self.openedClearConfirm) {
      self.clear(function () {
        self.openDispatchDialogExec();
      });
    } else {
      self.openDispatchDialogExec();
    }
  }

  openDispatchDialogExec() {
    const self = this;
    if (self.dialogData && self.dialogData.parent) {
      self.close();
    }

    self.departureGps = self.ride.departure.gps;
    self.reference = self.ride.reference;
    self.rideId = self.ride.id;
    this._dialogService.open(DispatchDialogComponent, {
      maxHeight: '80%',
      maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
      data: {
        parent: this,
        onClose: function () {
          self.selecting = true;
        }
      },
    });
  }

  ngAfterViewInit(): void {
    const setSearch = ['driverName', 'driverPhoneNumber', 'driverEmailAddress'];
    setSearch.forEach((s) => {
      const input = document.querySelector(`#${s} input[type=text]`);
      if (input) {
        input.setAttribute('type', 'search');
      }
    });
  }

  ngOnInit(): void {
    if (!this.form) {
      this.initForm();
    }

    if (this.ride && this.ride.LastOffer && !['success', 'expired', 'failed', 'canceled'].includes(this.ride.LastOffer.status)) {
      this.selectedTabIndex = 1;
    } else {
      this.selectedTabIndex = 0;
    }

    console.log(this.ride.RideForward);
  }

  reloadTab(): void {
    setTimeout(() => this.reload = false);
    setTimeout(() => this.reload = true);
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnChanges(changes: SimpleChanges) {
    this.ride = changes['ride'].currentValue;
    if (this.ride && this.ride.Driver) {
      this.selectedDriver = this.ride.Driver;
      if (!this.form) {
        this.initForm();
      }
      this.form.controls['driverName'].disable();
      this.form.controls['driverPhoneNumber'].disable();
      this.form.controls['driverEmailAddress'].disable();
      this.patchDriver();
    } else if (this.ride.id) {
      this.selectedDriver = null;
      if (!this.form && this.ride.id) {
        this.initForm();
        this.form.controls['driverName'].enable();
        this.form.controls['driverPhoneNumber'].enable();
        this.form.controls['driverEmailAddress'].enable();
      } else {
        this.form.controls['driverName'].enable();
        this.form.controls['driverPhoneNumber'].enable();
        this.form.controls['driverEmailAddress'].enable();
      }
      this.patchDriver();
    } else if (!this.ride) {
      this.initForm();
    }
    if (this.ride && this.ride.LastOffer && !['success', 'expired', 'failed', 'canceled'].includes(this.ride.LastOffer.status)) {
      this.selectedTabIndex = 1;
    } else {
      this.selectedTabIndex = 0;
    }

    this.setStatusTitle();
  }

  onTabChange(event) {
    const self = this;
    this.tab = event.tab.textLabel;
    this.switchOverflow = true;
    if (this.currentTimeout) {
      clearTimeout(this.currentTimeout);
    }
    this.currentTimeout = setTimeout(function () {
      self.switchOverflow = false;
    }, 500);
  }

  setStatusTitle() {
    if (this.ride.id) {
      this.statusTitle = '';
      this.statusTooltip = '';
      this.statusTooltipColor = '';
      this.statusHTML = '';

      if (this.translations) {
        if (!this.ride.Driver && this.selectedDriver) {
          this.statusTitle = 'job_assign_status_text_selected_existing';
        } else if (!this.ride.Driver && this.newDriverDetected) {
          this.statusTitle = 'job_assign_status_text_selected_new';
        } else if (!this.ride.Driver) {
          this.statusTitle = 'job_assign_status_text_unassigned';
        } else if (this.ride.Driver && this.ride.Driver.status === 'created') {
          // this.statusTitle = 'job_assign_status_text_assigned_unconfirmed';
          // tslint:disable-next-line:max-line-length
          this.statusTooltip = this.translations['job_assign_status_text_assigned_new_unconfirmed'].replace(/{name}/gi, `${this.ride.Driver.fname} ${this.ride.Driver.lname}`).replace(/{phonenumber}/gi, `${this.ride.Driver.phoneNumber}`);
          this.statusTooltipColor = 'Red';
          this.statusHTML = this.translations['job_assign_status_text_assigned_unconfirmed'].replace('<span>', '<span style="color:red">');
        } else if (this.ride.Driver && this.ride.LastJobOffer && this.ride.LastJobOffer.response !== 'ACKNOWLEDGED') {
          // this.statusTitle = 'job_assign_status_text_assigned_unconfirmed';
          this.statusHTML = this.translations['job_assign_status_text_assigned_unconfirmed'].replace('<span>', '<span style="color:red">');
          this.statusTooltip = this.translations['job_assign_status_text_assigned_unconfirmed_tooltip'].replace(/{name}/gi, `${this.ride.Driver.fname} ${this.ride.Driver.lname}`).replace(/{phonenumber}/gi, `${this.ride.Driver.phoneNumber}`);
          this.statusTooltipColor = 'Red';
        } else if (this.ride.Driver && this.ride.LastJobOffer && this.ride.LastJobOffer.response === 'ACKNOWLEDGED') {
          // this.statusTitle = 'job_assign_status_text_assigned_confirmed';
          this.statusHTML = this.translations['job_assign_status_text_assigned_confirmed'].replace('<span>', '<span style="color: #50f433">');
          this.statusTooltipColor = 'Green';
        }
      }
    }
  }

  selectDriver(res): void {
    // if (!this.selecting) {
    this.selectedDriver = res;
    this.patchDriver();
    // }
  }

  initForm(): void {
    const self = this;
    self.form = self._formBuilder.group({
      'driverName': [(self.selectedDriver ?
        `${self.selectedDriver.fname}${self.selectedDriver.fname}${(self.selectedDriver.lname !== '-' ? ` ${self.selectedDriver.lname}` : '')}` : ''), [Validators.required]],
      'driverPhoneNumber': [(self.selectedDriver ? `${self.selectedDriver.phoneNumber}` : ''), {
        'validators': [Validators.required, Validators.minLength(8), phoneNumberValidator((self.company && self.company.country ? self.company.country : self.driver.country))],
        'updateOn': 'blur',
      }],
      'driverEmailAddress': [(self.selectedDriver ? `${self.selectedDriver.emailAddress}` : ''), [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      'driver-invite': [true, []],
    }, {validators: []});

    self.form.controls['driverName'].valueChanges.pipe(
      debounceTime(150))
      .subscribe(q => {
        if (self.selectedDriver && !self.selecting && !self.openedClearConfirm) {
          self.clear();
        }
        self.search(q);
      });

    self.form.controls['driverEmailAddress'].valueChanges.pipe(
      debounceTime(150))
      .subscribe(q => {
        if (self.selectedDriver && !self.selecting && !self.openedClearConfirm) {
          self.clear();
        }
        self.searchEmail(q)
      });

    self.form.controls['driverPhoneNumber'].valueChanges.subscribe(
      (data: any) => {
        if (!self.selecting && !self.openedClearConfirm && data && data.e164Number) {
          self.newDriverDetected = !self.selectedDriver;
        }
        if (self.selectedDriver && !self.selecting && !self.openedClearConfirm) {
          self.clear();
        }
      });
  }

  patchDriver(): void {
    const self = this;
    this.selecting = true;
    const patch = {};
    if (this.selectedDriver) {
      patch['driverName'] = `${this.selectedDriver.fname}${(this.selectedDriver.lname !== '-' ? ` ${this.selectedDriver.lname}` : '')}`;
      patch['driverPhoneNumber'] = this.selectedDriver.phoneNumber;
      patch['driverEmailAddress'] = this.selectedDriver.emailAddress;
      this.form.controls['driverPhoneNumber'].clearValidators();
    } else {
      this.initForm();
    }

    this.form.patchValue(patch);
    this.formErrors = {};
    this.form.markAsPristine();

    setTimeout(() => {
      self.selecting = false;
    }, 300);
  }

  reloadDriverData() {
    if (this.dialogData && this.dialogData.parent) {
      this.grandParent.reloadAllData();
      this.close();
    } else {
      this.reloadData.next();
    }

  }

  assignDriver(): void {
    const self = this;

    this.validateAllFormFields(this.form);
    if (!this.form.valid) {
      return;
    }

    this._loadingService.register();
    if (!this.selectedDriver) {
      self._driverService.getAll({
        'where': {
          companyId: (this.context === 'company' ? this.company.id : null),
          phoneNumber: encodeURIComponent(this.form.controls['driverPhoneNumber'].value.e164Number)
        }
      }, 'company').subscribe((drivers: Driver[]) => {
        const newDriver = self.createTmpDriver();
        const invitation: any = JSON.parse(JSON.stringify(newDriver));
        invitation.admin = false;
        invitation.status = 'pending';
        invitation.companyId = (this.context === 'company' ? this.company.id : '');
        invitation.driverType = invitation.type;

        if (invitation.type === 'admin') {
          invitation.admin = true;
        }
        const user: any = this._vault.getObject(`${environment.vaultPrefix}.user`);
        invitation.invitee = {
          name: `${user.firstName} ${user.lastName}`,
          email: `${user.email}`,
          phoneNumber: `${user.phoneNumber}`
        };

        const offer: any = {
          productId: this.ride.id,
          productType: 'ride',
          preAssigned: true,
          status: 'success',
          type: 'driver'
        };

        if (this.form.controls['driver-invite'].value) {
          newDriver.status = 'invited';
        } else {
          newDriver.status = 'created';
        }

        if (this.context === 'company') {
          if (!drivers || (drivers && !drivers[0])) {
            self._driverService.insert(newDriver, 'company').subscribe((driver: Driver) => {
              offer.driverId = driver.id;
              self._offerService.insert(offer, this.context).subscribe((o: Offer[]) => {
                if (this.form.controls['driver-invite'].value) {
                  self._operatorService.getAll({'where': {companyId: self.company.id, 'phoneNumber': invitation.phoneNumber}}, 'company').subscribe((invite: any[]) => {
                    if (invite && invite[0]) {
                      invitation.admin = true;
                    }
                    self._companyInvitationService.sendInvite([invitation], 'company').subscribe(() => {
                    });
                  });
                } else {
                  self._driverService.sendJobNotification({driver: driver, ride: self.ride, owner: self.company}, 'company').subscribe(() => {
                  });
                }
                self.snackBar.open(ucFirst(self.translations['job_dispatched'].replace('{{driver}}', driver.fname + ' ' + driver.lname)), 'OK', {
                  duration: 3000
                });
                self.selecting = true;
                self.reloadDriverData();
                self._loadingService.resolve();
              }, (error) => {
                self.offerError(error);
                self._loadingService.resolve();
              });
            });
          } else {
            const driver = drivers[0];
            offer.driverId = driver.id;
            self._offerService.insert(offer, this.context).subscribe((o: Offer[]) => {
              if (self.form.controls['driver-invite'].value && driver.status === 'created') {
                self._companyInvitationService.sendInvite([invitation], 'company').subscribe(() => {
                });
              }
              self.snackBar.open(ucFirst(self.translations['job_dispatched'].replace('{{driver}}', driver.fname + ' ' + driver.lname)), 'OK', {
                duration: 3000
              });
              self.selecting = true;
              self.reloadDriverData();
              self._loadingService.resolve();
            }, (error) => {
              self.offerError(error);
              self.reloadDriverData();
              self._loadingService.resolve();
            });
          }
        } else {
          if (drivers && !drivers[0]) {
            newDriver.companyId = null;
            self._driverService.insert(newDriver, 'company').subscribe((driver: Driver) => {
              offer.driverId = driver.id;
              self._offerService.insert(offer, this.context).subscribe((o: Offer[]) => {
                self.snackBar.open(ucFirst(self.translations['job_dispatched'].replace('{{driver}}', driver.fname + ' ' + driver.lname)), 'OK', {
                  duration: 3000
                });
                self.selecting = true;
                self.reloadDriverData();
                self._loadingService.resolve();
              }, (error) => {
                self.offerError(error);
                self._loadingService.resolve();
              });
            });
          } else {
            const driver = drivers[0];
            self._offerService.insert(offer, this.context).subscribe((o: Offer[]) => {
              self.snackBar.open(ucFirst(self.translations['job_dispatched'].replace('{{driver}}', driver.fname + ' ' + driver.lname)), 'OK', {
                duration: 3000
              });
              self.selecting = true;
              self.reloadDriverData();
              self._loadingService.resolve();
            }, (error) => {
              self.offerError(error);
              self._loadingService.resolve();
            });
          }
        }
      });
    } else {
      let driverId = this.selectedDriver.id;
      const offer: any = {
        productId: this.ride.id,
        productType: 'ride',
        driverId: driverId,
        preAssigned: true,
        status: 'success',
        type: 'driver'
      };
      if (this.context === 'company' && this.company) {
        offer.companyId = this.company.id;
      }
      if (this.selectedDriver.orignalDriverId === 'new-driver') {
        const newDriver = self.createTmpDriver();
        newDriver.status = 'invited';
        self._driverService.insert(newDriver, 'company').subscribe((driver: Driver) => {
          offer.driverId = driver.id;
          self.createOffer(offer, driver.id);

        });
      } else if (this.selectedDriver.orignalDriverId) {
        driverId = this.selectedDriver.orignalDriverId;
        offer.driverId = driverId;
        self.createOffer(offer, driverId);
      } else {
        self.createOffer(offer, driverId);
      }
    }
    self.reloadDriverData();
    self._loadingService.resolve();
  }

  createTmpDriver(): Driver {
    /**
     * Create invite and then assign a job to an driver that does not exists
     */
    let phoneNumber = this.form.controls['driverPhoneNumber'].value.e164Number;
    if (!phoneNumber) {
      phoneNumber = this.form.controls['driverPhoneNumber'].value;
    }
    const fname = this.form.controls['driverName'].value.split(' ').slice(0, -1).join(' ');
    const lname = this.form.controls['driverName'].value.split(' ').slice(-1).join(' ');
    const newDriver: Driver = {
      fname: (!fname ? lname : fname),
      lname: (fname ? lname : '-'),
      phoneNumber: phoneNumber,
      emailAddress: this.form.controls['driverEmailAddress'].value,
      companyId: (this.context === 'company' ? this.company.id : '')
    };

    return newDriver;
  }

  createOffer(offer, driverId): void {
    console.log('createOffer');
    const self = this;
    this._offerService.insert(offer, this.context).subscribe((o: Offer[]) => {
      self._driverService.get(driverId, {}).subscribe((driver: Driver) => {
        self.snackBar.open(ucFirst(self.translations['job_dispatched'].replace('{{driver}}', driver.fname + ' ' + driver.lname)), self.translations['ok'], {
          duration: 3000
        });
        self.selecting = true;
        self.reloadDriverData();
        self._loadingService.resolve();
      }, (error) => {
        self._loadingService.resolve();
        self.reloadDriverData();
        self.offerError(error);
      });
    }, (error) => {
      self._loadingService.resolve();
      self.offerError(error);
    });
  }

  offerError(error): void {
    const self = this;
    let err;
    if (error.error && error.error.error) {
      err = error.error.error;
    } else {
      err = error.error;
    }

    self._dialogService.openAlert({
      message: ucFirst((self.translations[`dispatch_error_${err.name}`] ? self.translations[`dispatch_error_${err.name}`] : `${err.name} ${err.message}`)),
      disableClose: true,
      // title: ucFirst(self.translations['company_created_message_title']),
      closeButton: self.translations['ok']
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  clear(cb: any = null): void {
    this.openedClearConfirm = true;
    const self = this;
    const message = ucFirst(self.translations['clear_driver_confirm']);
    const title = ucFirst(self.translations['clear_driver_title']);

    self._dialogService.openConfirm({
      message: message,
      disableClose: false,
      title: title,
      cancelButton: self.translations['cancel'],
      acceptButton: self.translations['clear'],
    }).afterClosed().subscribe((accept: boolean) => {
      self.openedClearConfirm = false;
      if (accept) {
        self.selecting = true;
        self.selectedDriver = null;
        self.newDriverDetected = false;
        const patch = {};
        patch['driverName'] = ``;
        patch['driverPhoneNumber'] = '';
        patch['driverEmailAddress'] = '';
        self.form.patchValue(patch);

        if (cb) {
          cb();
        }

        setTimeout(() => {
          self.selecting = false;
        }, 300);
      } else {
        self.selecting = true;
        const patch = {};
        patch['driverName'] = `${self.selectedDriver.fname}${(self.selectedDriver.lname !== '-' ? ` ${self.selectedDriver.lname}` : '')}`;
        patch['driverPhoneNumber'] = self.selectedDriver.phoneNumber;
        patch['driverEmailAddress'] = self.selectedDriver.emailAddress;
        self.form.patchValue(patch);

        if (cb) {
          cb();
        }

        setTimeout(() => {
          self.selecting = false;
        }, 300);
      }
    });
  }

  search(term: string) {
    const self = this;
    if (self.loading === false) {
      self.loading = true;
      self.data = [];
      self.searchContacts(term)
        .then(results => {
          self.data = self.data.concat(results);
          self.loading = false;
        })
    }
  }

  searchEmail(term: string) {
    const self = this;
    if (self.loading === false) {
      self.loading = true;
      self.emailData = [];
      self.searchContacts(term)
        .then(results => {
          self.emailData = self.emailData.concat(results);
          self.loading = false;
        })
    }
  }

  searchContacts(term: string): Promise<any[]> {
    return new Promise((resolve) => {
      const results = [];
      const regex = new RegExp(term, 'gi');
      this.drivers.forEach(driver => {
        const nameMatch = driver.fname.match(regex);
        const codeMatch = driver.lname.match(regex);
        const fullMatch = `${driver.fname} ${driver.lname}`.match(regex);
        let phoneMatch
        if (driver.emailAddress) {
          phoneMatch = driver.emailAddress.match(regex);
        }
        if (nameMatch) {
          results.push(driver);
        } else if (codeMatch) {
          results.push(driver);
        } else if (fullMatch) {
          results.push(driver);
        } else if (phoneMatch) {
          results.push(driver);
        }
      });
      return resolve(results);
    });
  }

  stopDispatch(): void {
    const self = this;
    if (this.ride && this.ride.LastOffer) {
      self._dialogService.openConfirm({
        message: self.translations['job_dispatched_stopped_confirm'],
        disableClose: false,
        title: self.translations['stop_automated_dispatch'],
        cancelButton: self.translations['cancel'],
        acceptButton: self.translations['stop_automated_dispatch'],
      }).afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          self._offerService.update(this.ride.LastOffer.id, {'status': 'canceled'}).subscribe((translations) => {
            self.snackBar.open(ucFirst(self.translations['job_dispatched_stopped']), 'OK', {
              duration: 3000
            });
            self.reloadDriverData();
          });
        }
      });
    }
  }

  unassignDriver(rideId): void {
    const self = this;
    self._dialogService.openConfirm({
      message: self.translations['confirm_unassign_driver'],
      disableClose: false,
      title: self.translations['confirm_unassign_driver_title'],
      cancelButton: self.translations['no'],
      acceptButton: self.translations['yes'],
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        self._rideService.update(rideId, {'status': 'unassigned', 'driverId': null, 'vehicleId': null}, this.context).subscribe(() => {
          self.selectedDriver = null;
          self.newDriverDetected = false;
          self.reloadDriverData();
        }, error => {
          console.error(error);
        })
      }
    });
  }
}
