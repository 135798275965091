<ng-template tdLoading="{{loaderName}}" tdMediaToggle="gt-xs">
  <mat-card-title>{{'upcomming_invoice'|translate|ucFirst}}</mat-card-title>
  <mat-card-subtitle>{{'upcomming_invoice_subtitle'|translate|replace : '{due_date}'
    : this.upcommingInvoice['due_date'] |ucFirst}}</mat-card-subtitle>
  <mat-divider></mat-divider>

  <table class="push-bottom">
    <thead>
    <tr td-data-table-column-row>
      <th *ngFor="let c of columns"
          [active]="(sortBy == c.name)"
          [name]="c.name"
          [numeric]="c.numeric"
          [sortOrder]="( sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
          [sortable]="c.sortable"
          class="item_row"
          td-data-table-column>
        {{((c.name !== 'tools' ? c.label : '')|translate|ucFirst)}}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of this.filteredData" td-data-table-row>
      <td *ngFor="let c of columns"
          class="{{(c.type=='currency'?'text-right invoice_amount':'')}} item_row"
          td-data-table-cell>
        <span [ngSwitch]="c.type">
          <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span><span *ngIf="!item[c.name]">-</span></span>
          <span *ngSwitchCase="'tag'"><span *ngIf="item[c.name]">{{(item[c.name])|translate|ucFirst}}</span><span
            *ngIf="!item[c.name]">-
          </span></span>
          <span *ngSwitchCase="'currency'"><span
            *ngIf="item[c.name]" class="invoice_amount">{{(item[c.name]) | localizedCurrency}}</span><span
            *ngIf="!item[c.name]">-
          </span></span>
          <span *ngSwitchCase="'date'"><span
            *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? this.company.dateFormat : 'DD-MM-YYYY')}}</span></span>
          <span *ngSwitchCase="'period'"><span
            *ngIf="item[c.name]">{{item[c.name].start | amDateFormat:(this.company && this.company.dateFormat ? this.company.dateFormat : 'DD-MM-YYYY')}}
            - {{item[c.name].end | amDateFormat:(this.company && this.company.dateFormat ? this.company.dateFormat : 'DD-MM-YYYY')}}</span></span>
          <span *ngSwitchCase="'dateTime'"><span
            *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>
           <span *ngSwitchCase="'url'">
             <span *ngIf="item[c.name]">
                <a class="download" href="{{item[c.name].receipt.url}}"
                   target="_new">{{item[c.name].receipt.tag|translate|ucFirst}}</a> |
                <a class="download" href="{{item[c.name].invoice.url}}"
                   target="_new">{{item[c.name].invoice.tag|translate|ucFirst}}</a>
             </span>
           </span>
        </span>
      </td>
    </tr>

    <tr class="invoice-table-row">
      <td colspan='3' td-data-table-cell></td>
      <td class="text-right" td-data-table-cell>{{'subtotal'|translate|ucFirst}}</td>
      <td class="invoice_amount text-right"
          td-data-table-cell>{{(this.upcommingInvoice['subtotal'] ? this.upcommingInvoice['subtotal'] : 0) | localizedCurrency}}</td>
    </tr>
    <tr class="invoice-table-row">
      <td colspan='3' td-data-table-cell></td>
      <td class="text-right" td-data-table-cell>{{'tax'|translate|ucFirst}}</td>
      <td class="invoice_amount text-right"
          td-data-table-cell>{{(this.upcommingInvoice['tax'] ? this.upcommingInvoice['tax'] : 0) | localizedCurrency}}</td>
    </tr>
    <tr *ngIf="this.upcommingInvoice['coupon']" class="invoice-table-row">
      <td colspan='3' td-data-table-cell></td>
      <td class="text-right" td-data-table-cell>{{'discount'|translate|ucFirst}}
        ({{this.upcommingInvoice['coupon'].name}})
      </td>
      <td class="invoice_amount text-right"
          td-data-table-cell>{{(this.upcommingInvoice['discount'] ? this.upcommingInvoice['discount'] : 0) |
        localizedCurrency}}</td>
    </tr>
    <tr class="invoice-table-row last-table-row">
      <td colspan='3' td-data-table-cell></td>
      <td class="text-right" td-data-table-cell>{{'total'|translate|ucFirst}}</td>
      <td class="invoice_amount text-right"
          td-data-table-cell>{{(this.upcommingInvoice['total'] ? this.upcommingInvoice['total'] : 0) | localizedCurrency}}</td>
    </tr>
    </tbody>
  </table>
</ng-template>
