import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {DebtorService} from '../../../services/debtor.service';
import {IPageChangeEvent} from '@covalent/core/paging';
import {TdLoadingService} from '@covalent/core/loading';
import {ITdDataTableSortChangeEvent, TdDataTableService, TdDataTableSortingOrder} from '@covalent/core/data-table';
import {NavigationService} from '../../../services/navigation.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Debtor} from '../../../models/debtor';
import {Driver} from '../../../models/driver';
import {CoolLocalStorage} from '@angular-cool/storage';

@Component({
  selector: 'app-debtors',
  templateUrl: './debtors.component.html',
  styleUrls: ['./debtors.component.scss'],
  providers: [DebtorService]
})
export class DebtorsComponent implements OnInit, OnDestroy {

  driver: Driver;
  loaderName = 'policy-list';
  dataSubscription: Subscription;
  companyId: string;

  debtors: Debtor[] = [];
  filteredDebtors: Debtor[] = [];
  filteredDebtorCount = 0;

  searchTerm = '';
  fromRow = 1;
  currentPage = 1;
  pageSize = 20;
  sortBy = 'identifier';
  sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;

  columns = [
    {name: 'code', label: 'code', sortable: true, active: false},
    {name: 'firstName', label: 'first_name', sortable: true, active: true},
    {name: 'lastName', label: 'last_name', sortable: true, active: false},
    {name: 'companyName', label: 'debtor_company', sortable: true, active: false},
    {name: 'emailAddress', label: 'email', sortable: true, active: false},
    {name: 'phoneNumber', label: 'phone_number', sortable: true, active: false}
  ];

  constructor(private _debtorService: DebtorService,
              private _router: Router,
              private _vault: CoolLocalStorage,
              private _route: ActivatedRoute,
              private _dataTableService: TdDataTableService,
              private _loadingService: TdLoadingService,
              private _translateService: TranslateService,
              private _navigationService: NavigationService,
              private _titleService: Title) {
    _translateService.get(['menu_mydebtors']).subscribe((translations: any) => {
      this._titleService.setTitle(translations['menu_mydebtors'] + environment.windowTitleSuffix);
      this._navigationService.setBodyTitle(translations['menu_mydebtors']);
      this._navigationService.setActionLink(`/personal/debtors/add`);
    });
    this.driver = this._vault.getObject(`${environment.vaultPrefix}.driver`);
    this.loadData();
  }

  ngOnInit(): void {
    this._loadingService.register(this.loaderName);
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
    this._navigationService.setActionLink('');
  }

  sort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.sortBy = sortEvent.name;
    this.sortOrder = sortEvent.order;
    this.filter();
  }

  search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.filter();
  }

  page(pagingEvent: IPageChangeEvent): void {
    this.fromRow = pagingEvent.fromRow;
    this.currentPage = pagingEvent.page;
    this.pageSize = pagingEvent.pageSize;
    this.filter();
  }

  filter(): void {
    let filteredData: Debtor[] = this.debtors;

    filteredData = this._dataTableService.filterData(filteredData, this.searchTerm, true);
    filteredData = this._dataTableService.sortData(filteredData, this.sortBy, this.sortOrder);
    filteredData = this._dataTableService.pageData(filteredData, this.fromRow, this.currentPage * this.pageSize);

    this.filteredDebtorCount = filteredData.length;
    this.filteredDebtors = filteredData;
  }

  open(id: string): void {
    if (id) {
      this._router.navigate([`/personal/debtors/${id}/edit/`]);
    }
  }

  loadData(): void {
    const query = {where: {ownerId: this.driver.id, ownerType: 'Driver'}};
    this.dataSubscription = this._debtorService.getAll(query).subscribe((debtors: Debtor[]) => {
      this.debtors = debtors;
      this.filteredDebtorCount = debtors.length;

      this.filter();

      const self = this;
      setTimeout(function () {
        self._loadingService.resolve(self.loaderName);
      }, 500);
    }, error => {
      console.error(error);
      this._loadingService.resolve(this.loaderName);
    })
  }

}
