<mat-list>
  <section class="mat-typography">
    <mat-icon (click)="close()" *ngIf="this.dialogData && this.dialogData.parent" class="dialog-top-right">close
    </mat-icon>
    <div class="assign-pane" *ngIf="!this.ride.RideForward || !this.ride.RideForward[0]">
      <mat-list-item class="title-list-item" flex="50" flex-gt-sm="50">
        <mat-icon mat-list-icon>person_pin</mat-icon>
        <h4 mat-line>{{'driver'|translate|ucFirst}}</h4>
        <p></p>
      </mat-list-item>
      <a *ngIf="this.ride.LastOffer && !this.dialogData" class="dispatch-log-icon"
         routerLink="/groups/{{this.companyId}}/jobs/{{this.ride.id}}/dispatch-log">
        <mat-icon>assignment</mat-icon>
      </a>
      <mat-tab-group #tabs (selectedTabChange)="onTabChange($event)" *ngIf="this.reload"
                     [className]="(switchOverflow ? 'hiddenOverflow' : '')" [selectedIndex]="selectedTabIndex">
        <mat-tab [disabled]="this.ride.id && !((!this.ride.LastOffer ||
['success','expired','failed','canceled'].includes(this.ride.LastOffer.status)))" flex="100" label="assign_to_driver"
                 tabIndex="0">
          <ng-template mat-tab-label>
            <div (click)="switchOverflow=true" class="tab-button">
              <mat-icon>person_outline</mat-icon>
              <div>{{((media.registerQuery('gt-md') | async) ? 'assign_to_driver' :
                'assign')|translate|ucFirst}}</div>
            </div>
          </ng-template>
          <div
            *ngIf="this.statusTooltip || this.statusHTML || this.statusTitle"
            class="helptext helptext{{this.statusTooltipColor}}">
            <mat-icon #tooltip="matTooltip" (click)="tooltip.toggle()" *ngIf="this.statusTooltip"
                      matTooltip="{{this.statusTooltip|translate|ucFirst}}">help
            </mat-icon>
            <div *ngIf="this.statusTitle">{{this.statusTitle|translate|ucFirst}}</div>
            <div *ngIf="this.statusHTML" [innerHTML]="this.statusHTML|keepHtml" class="statusText"></div>
          </div>

          <form *ngIf="form" [formGroup]="form" id="assign-to-driver">
            <div [mediaClasses]="['push-sm']" class="manual-assign-top-row" layout-gt-sm="row"
                 tdMediaToggle="gt-xs">
              <mat-list-item class="value pull-bottom driverInputField" flex-gt-md="35" flex-md="100">
                <mat-icon (click)="openDispatchDialog()" *ngIf="!this.ride.driverId" class="search-driver">search
                </mat-icon>
                <mat-form-field [style.width.%]="100" flex="100">
                  <mat-label>{{'name'|translate|ucFirst}}</mat-label>
                  <input #driverName (keyup)="this.formErrors={};"
                         [formGroup]="form"
                         [matAutocomplete]="auto"
                         autocomplete="new-name"
                         formControlName="driverName"
                         id="driverName"
                         matInput
                         placeholder="{{ 'name' | translate | ucFirst }}"
                         type="text">
                  <mat-autocomplete #auto="matAutocomplete" autocomplete="new-name">
                    <mat-option
                      (onSelectionChange)="selectDriver(res)"
                      *ngFor="let res of data"
                      [value]="res.fname +' '+res.lname">
                      <span>{{res.fname}}{{(res.lname !== '-' ? ' ' + res.lname : '')}} - {{res.phoneNumber}}</span>
                    </mat-option>
                  </mat-autocomplete>
                  <mat-hint align="start" class="warning">
            <span [hidden]="!formErrors.driverName" class="tc-red-600">
              <span>{{ formErrors.driverName }}</span>
            </span>
                  </mat-hint>
                </mat-form-field>
              </mat-list-item>

              <mat-list-item *ngIf="!selectedDriver && newDriverDetected &&
        this.context === 'company'"
                             class="value {{((media.registerQuery('gt-md') | async) ? 'push-left pull-bottom' :
                       '')}} manual-assign-button-row push-top" flex="100"
                             flex-gt-sm="50">
                <mat-checkbox [checked]="true" formControlName="driver-invite"
                ></mat-checkbox>
                <mat-label>{{'invite_as_driver'|translate|ucFirst}}</mat-label>
              </mat-list-item>
            </div>

            <div [mediaClasses]="['push-sm']" class="push-bottom manual-assign-row" layout-gt-sm="row"
                 tdMediaToggle="gt-xs">
              <div
                class="phoneNumberField {{(this.form.controls['driverPhoneNumber'].value && !this.selectedDriver ?
          'phoneNumberFieldSelected' :
          '')}} {{(formErrors['driverPhoneNumber'] ? 'phoneNumberInvalid' :
         '')}}" flex flex-gt-md="35" flex-md="100">
                <ngx-intl-tel-input
                  #phone
                  (keyup)="this.formErrors={};"
                  [cssClass]="(this.ride.id && this.ride.Driver ? 'phoneNumberInput phoneNumberInputDisabled' : 'phoneNumberInput')"
                  [enableAutoCountrySelect]="true"
                  [enablePlaceholder]="true"
                  [maxLength]="15"
                  [phoneValidation]="true"
                  [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                  [searchCountryFlag]="true"
                  [selectFirstCountry]="false"
                  [selectedCountryISO]="(this.company ?
                  (this.company.country === 'EN' ? 'GB' : this.company.country) :
                  (this.driver.country ? this.driver.country : 'GB'))"
                  autocomplete="new-emailAddress"
                  flex
                  formControlName="driverPhoneNumber"
                  name="driverPhoneNumber">
                </ngx-intl-tel-input>
                <div *ngIf="(this.ride.id && this.ride.Driver)" class="phoneNumberInputDisabledBorder"></div>
                <div class="phonenumber-warning warning">
            <span [hidden]="!formErrors['driverPhoneNumber']" class="tc-red-600">
              <span>{{ formErrors['driverPhoneNumber'] }}</span>
            </span>
                </div>
              </div>
              <mat-list-item
                class="value driverInputField {{((media.registerQuery('gt-md') | async) ? '' : 'pull-bottom')}}"
                flex-gt-md="35" flex-md="100">
                <mat-form-field [style.width.%]="100" flex="100">
                  <mat-label>{{'email'|translate|ucFirst}}</mat-label>
                  <input #emailAddress (keyup)="this.formErrors={};"
                         [formGroup]="form"
                         autocomplete="new-emailAddress"
                         formControlName="driverEmailAddress"
                         id="driver-emailAddress"
                         matInput
                         placeholder="{{ 'email' | translate | ucFirst }}"
                         type="text">
                  <mat-hint align="start" class="warning">
            <span [hidden]="!formErrors.driverEmailAddress" class="tc-red-600">
              <span>{{ formErrors.driverEmailAddress }}</span>
            </span>
                  </mat-hint>
                </mat-form-field>
              </mat-list-item>
            </div>

            <div *ngIf="this.ride.id && !this.ride.Driver" class="push-top push-bottom manual-assign-button-row"
                 layout="row">
              <mat-list-item [class]="((formErrors.driverEmailAddress) ?
         '' : '')" flex-gt-md="50" flex-lt-md="100">
                <button (click)="assignDriver()" color="{{(this.selectedDriver || newDriverDetected ? 'primary' : 'accent')}}"
                        flex="100"
                        flex-gt-sm="100"
                        mat-raised-button>{{'assign_to_driver'|translate|ucFirst}}</button>
              </mat-list-item>
            </div>

            <div *ngIf="this.ride.id && this.ride.Driver" class="push-top push-bottom manual-assign-button-row"
                 layout="row">
              <mat-list-item flex-gt-md="50" flex-lt-md="100">
                <button (click)="unassignDriver(ride.id)" color="primary" flex mat-raised-button
                        target="_blank">{{'unassign_job' | translate | ucFirst}}</button>
              </mat-list-item>
            </div>
          </form>
        </mat-tab>

        <mat-tab *ngIf="this.ride" [disabled]="(this.ride.id && this.ride.Driver)" [tabIndex]="1">
          <ng-template mat-tab-label>
            <div (click)="switchOverflow=true" class="tab-button">
              <mat-icon>send</mat-icon>
              <div>{{((media.registerQuery('gt-md') | async) ? 'dispatch_job_start_automatic_button' :
                'dispatch_job_start_automatic_button_small')|translate|ucFirst}}</div>
            </div>
          </ng-template>

          <app-automatic-dispatch (closeDialog)="close()" (reloadData)="reloadDriverData()"
                                  (stopDispatch)="stopDispatch()"
                                  [company]="company"
                                  [parent]="(this.dialogData ? this.dialogData.parent : null)"
                                  [ride]="ride"></app-automatic-dispatch>
        </mat-tab>

        <mat-tab *ngIf="this.ride && false" [disabled]="(this.ride.id && this.ride.Driver)" [tabIndex]="1">
          <ng-template mat-tab-label>
            <div (click)="switchOverflow=true" class="tab-button">
              <mat-icon>forward</mat-icon>
              <div>{{'forward_to_group'|translate|ucFirst}}</div>
            </div>
          </ng-template>

          <app-forward-ride (closeDialog)="close()"
                            (reloadData)="reloadDriverData()"
                            (stopDispatch)="stopDispatch()"
                            (reloadTab)="reloadTab()"
                            [company]="company"
                            [parent]="(this.dialogData ? this.dialogData.parent : null)"
                            [ride]="ride"></app-forward-ride>
        </mat-tab>
      </mat-tab-group>

      <mat-list-item *ngIf="driverNote" class="value small" flex-gt-sm="100">
        <p mat-line>{{'note'|translate|ucFirst}}</p>
        <p mat-line>{{driverNote || '-'}}</p>
      </mat-list-item>
    </div>

    <div class="assign-pane forwarded-pane" *ngIf="this.ride.RideForward && this.ride.RideForward[0]">
      <mat-list-item class="title-list-item" flex="50" flex-gt-sm="50">
        <mat-icon mat-list-icon>person_pin</mat-icon>
        <h4 mat-line>{{'ride_forwarded'|translate|ucFirst}}</h4>
        <p></p>
      </mat-list-item>

      <mat-list-item class="forwarded" flex-gt-sm="100" *ngIf="this.ride.RideForward && this.ride.RideForward[0] && this.ride.RideForward[0].Owner">
        <strong>{{'ride_forwarded_to'|translate|ucFirst}}:</strong> {{this.ride.RideForward[0].Owner.name}}
      </mat-list-item>
    </div>
  </section>
</mat-list>
