import {Component, Inject, OnInit} from '@angular/core';
import {Company} from '../../../../../../models/company';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {DaAppInstallService} from '../../../../../../services/da-app-install.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CompanyService} from '../../../../../../services/company.service';
import {WorkspaceSelectionComponent} from '../../../../../portal/workspace-selection/workspace-selection.component';
import {TdDialogService} from '@covalent/core/dialogs';

@Component({
  selector: 'app-close-profile',
  templateUrl: './close-profile.component.html',
  styleUrls: ['./close-profile.component.scss']
})
export class CloseProfileComponent implements OnInit {
  company: Company;
  formErrors: any = {};
  helpText: string;
  confirmInput: string;
  translations: string[];

  constructor(
    private _translateService: TranslateService,
    private _router: Router,
    private _companyService: CompanyService,
    private _dialogService: TdDialogService,
    private _daAppInstallService: DaAppInstallService,
    public dialogRef: MatDialogRef<CloseProfileComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    _translateService.get([
      `close_profile_dialog_subtitle`,
      `delete_webbooker_dialog_subtitle`,
      'close_account_dialog_text_error',
      'profile_closed_alert_title',
      'profile_closed_alert_text',
      'ok',
    ]).subscribe((translations: any) => {
      this.translations = translations;
    });
  }

  ngOnInit(): void {
    this.helpText = this.translations['close_profile_dialog_subtitle'].replace('{companyName}', this.data.company.name);
  }

  deleteWebbooker() {
    const self = this;
    if (this.data.company && this.data.company.name.trim() !== this.confirmInput.trim()) {
      this.formErrors.confirm = this.translations['close_account_dialog_text_error'];
    } else {
      self._companyService.archive(this.data.company.id).subscribe(() => {
        this.dialogRef.close();

        this._dialogService.openAlert({
          title: self.translations['profile_closed_alert_title'],
          message: self.translations['profile_closed_alert_text'],
          disableClose: true,
          closeButton: self.translations['ok'],
        }).afterClosed().subscribe(() => {
          self._dialogService.open(WorkspaceSelectionComponent, {
            maxHeight: '80%',
            maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
            minWidth: '500px',
            data: {
              openFromDialog: true,
              parent: this
            },
          });
        });
      }, error => {
        console.error(error);
      });
    }
  }
}
