<section class="mat-typography">
  <mat-icon (click)="close()" class="dialog-top-right">close</mat-icon>
  <h1>{{ 'confirm_job_invoices_send_email_multi_title' | translate | ucFirst}}</h1>
  <mat-card-subtitle>
    {{ 'confirm_job_invoices_send_email_multi' | translate | ucFirst }}
  </mat-card-subtitle>
</section>

<mat-divider></mat-divider>

<div class="td-dialog-actions push-top row">
  <span class="td-dialog-spacer"></span>

  <div class="push-bottom">
    <mat-checkbox [(ngModel)]="sendCopy" class="push-right"></mat-checkbox>
    {{ translations['send_invoice_copy_to'] | ucFirst }}
  </div>

  <span class="td-dialog-spacer"></span>
  <div class="button-pane">
    <button (click)="this.close();" class="push-right" color="accent" mat-raised-button>
      {{ 'cancel' | translate | ucFirst }}
    </button>
    <button (click)="resendInvoices()" color="accent" mat-raised-button>
      {{ 'yes' | translate | ucFirst }}
    </button>
  </div>
</div>
