<mat-card *ngIf="form" [formGroup]="form">
  <mat-card-title>{{ 'onboard_availability_title' | translate | ucFirst }}</mat-card-title>
  <mat-card-subtitle>{{ 'onboard_availability_subtitle' | translate | ucFirst }}</mat-card-subtitle>

  <mat-divider></mat-divider>

  <mat-tab-group (selectedTabChange)="onTabChange($event)" *ngIf="this.data">
    <mat-tab label="rules">
      <ng-template mat-tab-label>
        <mat-icon>format_line_spacing</mat-icon>
        {{ 'default_availability'|translate|ucFirst }}
      </ng-template>
      <div class="warning-pane" *ngIf="priceError">
        <mat-icon>warning</mat-icon>
        <span [innerHTML]="priceError|translate|ucFirst|keepHtml"></span>
      </div>
      <mat-card-content>
        <div [formGroupName]="'availableDays'">
          <strong class="strong-header-margin">{{ 'available_hours' | translate | ucFirst }}</strong>
          <div *ngFor="let day of days; index as i" class="day-pane" [formArrayName]="day">
            <table border="0" cellpadding="0" cellspacing="0">
              <tr *ngFor="let interval of form.controls['availableDays']['controls'][day]['controls']; index as i2"
                  class="day-pane"
                  [formGroupName]="i2" [className]="(i2<1?'first-date':'')">
                <td style="width:120px" *ngIf="i2 < 1">
                  <div
                    [class]="(interval.controls['active'].value?'day-checkbox':'')">
                    <mat-checkbox [formControlName]="'active'" value="y" (click)="clearSubRules(day)">
                      <span>{{ day + '_short' | translate | ucFirst }}</span>
                    </mat-checkbox>
                  </div>
                </td>
                <td style="width:120px" *ngIf="i2 > 0"></td>
                <td *ngIf="interval.controls['active'].value">
                  <mat-form-field class="left-input">
                    <mat-select [formControlName]="'start'" class="time_select"
                                flex="100"
                                placeholder="{{'from'|translate|ucFirst}}"
                                required>
                      <mat-option *ngFor="let value of getTimes(day, i2)"
                                  [value]="value">{{ value | translate | ucFirst }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>
                <td *ngIf="interval.controls['active'].value">
                  <mat-form-field>
                    <mat-select [formControlName]="'end'" class="time_select"
                                flex="100"
                                placeholder="{{'to'|translate|ucFirst}}"
                                required>
                      <mat-option *ngFor="let value of getTimes2(day, i2)"
                                  [value]="value">{{ value | translate | ucFirst }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>

                <td colspan="2"
                    *ngIf="!interval.controls['active'].value">{{ 'unavailable'|translate|ucFirst }}
                </td>
                <td *ngIf="i2 < 1 && interval.controls['active'].value" class="add-interval-button"
                    (click)="addInterval(day)">
                  <button mat-icon-button matTooltip="{{(form.controls['availableDays']['controls'][day]['controls'][i2].controls.end.value !== '24:00'?'add_interval':'cannot_add_interval')|translate|ucFirst}}"
                  [disabled]="form.controls['availableDays']['controls'][day]['controls'][i2].controls.end.value === '24:00'">
                    <mat-icon>add</mat-icon>
                  </button>
                </td>
                <td *ngIf="i2 > 0 && interval.controls['active'].value" class="add-interval-button"
                    (click)="removeInterval(day, i2)">
                  <button mat-icon-button matTooltip="{{'remove'|translate|ucFirst}}">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </tr>
            </table>
          </div>

          <mat-card-actions>
            <button (click)="save()"
                    color="accent"
                    flex
                    mat-raised-button>{{ 'save'|translate|ucFirst }}
            </button>
          </mat-card-actions>
        </div>
      </mat-card-content>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>format_line_spacing</mat-icon>
        {{ 'override_availability'|translate|ucFirst }}
      </ng-template>

      <app-availability-overwrite #availabilityOverride [company]="company"></app-availability-overwrite>
    </mat-tab>
  </mat-tab-group>
</mat-card>


