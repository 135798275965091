import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CachedHttpClient {
  cachedItems: any[] = [];

  constructor(private http: HttpClient) {

  }

  getCached(url: string, options: any): Observable<any> {
    const _self = this;
    if (_self.cachedItems[url]) {
      return of(_self.cachedItems[url] as any);
    }
    return this.http.get<any>(url, options).pipe(map((item: any) => {
      _self.cachedItems[url] = item;
      return item;
    }));
  }
}
