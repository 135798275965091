import {Injectable} from '@angular/core';
import {Company} from '../models/company';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {CompanyService} from '../services/company.service';
import {Observable} from 'rxjs';
import moment from 'moment';
import {environment} from '../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';

@Injectable()
export class CompanyResolver implements Resolve<Observable<Company>> {
  private user: any;
  constructor(private _companyService: CompanyService,
              private _vault: CoolLocalStorage,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Company> {
    const _self = this;
    return Observable.create(function subscribe(observer) {
      _self._companyService.get(route.paramMap.get('id'), {'include': ['ActiveContract', 'Contract', 'Country', 'DaAppInstalls']}, 'company').subscribe((c) => {
        // console.groupCollapsed(`Loaded company ${c.name} - ${c.id} settings:`, c.settings);
        if (c.DaAppInstalls) {
          const portalInstall = c.DaAppInstalls.filter((d) => {
            return (d.daAppId === environment.daAppId);
          })[0];
          if (portalInstall) {
            _self._vault.setObject(`${environment.vaultPrefix}.companyAccessToken`, portalInstall.accessToken);
          } else {
            _self._vault.removeItem(`${environment.vaultPrefix}.companyAccessToken`);
          }
        }

        c.dateFormat = (c.settings && c.settings.dateFormat ? c.settings.dateFormat : (c.dateFormat ? c.dateFormat : 'dd-mm-YYYY'));
        c.clockFormat = (c.settings && c.settings.clock ? c.settings.clock : (c.Country.clock ? c.Country.clock : '24'));
        if (!c.ActiveContract && c.Contract) {
          c.ActiveContract = c.Contract[0];
        }

        c.clockFormat = (c.clockFormat === '12' ? 'hh:mm A' : 'HH:mm');
        _self.user = _self._vault.getObject(`${environment.vaultPrefix}.user`);
        if (_self.user && !_self.user.ydaSupportUser) {
          (window as any).intercomSettings.company = {
            id: c.id,
            name: c.name,
            website: c.website,
            created_at: c.created,
            // (optional): Insert name of the plan current company is on
            plan: (c.ActiveContract ? c.ActiveContract.type : 'Expired trial'),
            // (optional): Insert amount current company spends a month
            // monthly_spend: 10,
            // (optional): Add any custom attributes, e.g.,
            // upgraded_at: 1424941688
          };
          if (c.ActiveContract) {
            (window as any).intercomSettings['Contract'] = c.ActiveContract.type;
            (window as any).intercomSettings['Contract start'] = moment(c.ActiveContract.created).format('DD-MM-YYYY');
          }
          // console.log((window as any).intercomSettings);
        }

        // console.log('dateFormat:', c.dateFormat);
        // console.log('clockFormat:', c.clockFormat);
        // console.groupEnd();
        observer.next(c);
        observer.complete();
      });
    });
  }
}
