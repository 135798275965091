import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {TdLoadingService} from '@covalent/core/loading';
import {TdMediaService} from '@covalent/core/media';
import {ActivatedRoute} from '@angular/router';
import {RideService} from '../../../../../services/ride.service';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {NavigationService} from '../../../../../services/navigation.service';
import {environment} from '../../../../../../environments/environment';
import {Ride} from '../../../../../models/ride';

@Component({
  selector: 'app-ride-log',
  templateUrl: './ride-log.component.html',
  styleUrls: ['./ride-log.component.scss'],
  providers: [RideService]
})
export class RideLogComponent implements OnInit, AfterViewInit, OnDestroy {

  companyId: string;
  rideId: string;
  iconUrl: string;
  mapLineColor: string;
  ride: Ride = new Ride();

  constructor(public media: TdMediaService,
              private _loadingService: TdLoadingService,
              private _activatedRoute: ActivatedRoute,
              private _rideService: RideService,
              private _translateService: TranslateService,
              private _navigationService: NavigationService,
              private _titleService: Title) {
    _translateService.get('ride_log').subscribe((translation: string) => {
      this._titleService.setTitle(translation + environment.windowTitleSuffix);
      this._navigationService.setBodyTitle(translation);
    });
    const params: any = this._activatedRoute.snapshot.params;
    const parentParams: any = this._activatedRoute.parent.snapshot.params;
    this._navigationService.setActiveSubmenu(this._activatedRoute.routeConfig['submenu']);

    this.rideId = params.id;
    this.companyId = parentParams.id;
    this.iconUrl = `/assets/${environment.buildName}/icon-marker.png`;
    this.mapLineColor = `${environment.mapLineColor}`;
    _navigationService.setSplitLayoutButtons([
      {
        'icon': 'dashboard',
        'tooltip': 'Details',
        'link': `/groups/${this.companyId}/jobs/${this.rideId}/`
      },
      {
        'icon': 'assignment',
        'tooltip': 'Log',
        'link': `/groups/${this.companyId}/jobs/${this.rideId}/log`
      },
    ]);
  }

  ngOnInit() {
    this._loadingService.register('log');
    this.loadData();
  }

  ngAfterViewInit() {
    this.media.broadcast();
  }

  ngOnDestroy() {
    this._navigationService.setSplitLayoutButtons([]);
  }

  loadData() {
    const filter = {
      'include': {'RideLog': 'Entries'}
    };

    this._rideService.get(this.rideId, filter).subscribe((ride: Ride) => {
      this.ride = ride;
      this._loadingService.resolve('log');
    }, error => {
      console.error(error);
      this._loadingService.resolve('log');
    })
  }

}
