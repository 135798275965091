import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-ride-commission-data',
  templateUrl: './ride-commission-data.component.html',
  styleUrls: ['./ride-commission-data.component.scss']
})
export class RideCommissionDataComponent implements OnInit {

  @Input() isOwner = false;
  @Input() isDriver = false;
  @Input() currency = 'EUR';
  @Input() commission: any;
  @Input() commissionRules: any;
  @Input() driverPrice: any;
  @Input() driverSettlement: any;

  constructor() {
  }

  ngOnInit() {
    if (this.driverSettlement) {
      this.driverSettlement.amount = this.driverSettlement.total;
      if (this.isOwner) {
        this.driverSettlement.toPay = (this.driverSettlement.total > 0);
      } else {
        this.driverSettlement.toPay = (this.driverSettlement.total < 0);
      }
      this.driverSettlement.amount = (this.driverSettlement.amount > 0 ?
        this.driverSettlement.amount : (0 - this.driverSettlement.amount));
    }
  }
}
