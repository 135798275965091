<div *ngIf="filteredData.length > 0" [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <mat-card-title *ngIf="!this.dueContract">{{'billing_history'|translate|ucFirst}}</mat-card-title>
  <mat-card-subtitle *ngIf="!this.dueContract">{{'billing_history_subtitle'|translate|ucFirst}}</mat-card-subtitle>
  <mat-divider *ngIf="!this.dueContract"></mat-divider>

  <mat-divider *ngIf="!this.dueContract"></mat-divider>
  <ng-template tdLoading="{{loaderName}}">
    <table class="mat-clickable" td-data-table>
      <thead>
      <tr td-data-table-column-row>
        <th (sortChange)="sort($event)"
            *ngFor="let c of columns"
            [active]="(sortBy == c.name)"
            [name]="c.name"
            [numeric]="c.numeric"
            [sortOrder]="( sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
            [sortable]="c.sortable"
            td-data-table-column>
          {{((c.name !== 'tools' ? c.label : '')|translate|ucFirst)}}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr (click)="open((item['url'].invoice.url && (!this.dueContract && item['status'] === 'paid') ?
      item['url'].invoice.url :
      item['url'].receipt.url))"
          *ngFor="let item of filteredData"
          td-data-table-row>
        <td *ngFor="let c of columns" [numeric]="item.numeric"
            class="{{(c.name=='tools'?'text-right':'')}} {{item.statusClass}}"
            td-data-table-cell>
          <span [ngSwitch]="c.type">
            <span *ngSwitchCase="'status'">
              <span *ngIf="!item.paymentButton">{{('payment_status_' + item[c.name])|translate|ucFirst}}</span>
              <span *ngIf="item.paymentButton"><button color="primary" flex
                                                       mat-raised-button>{{(item[c.name])|translate|ucFirst}}</button></span>
            </span>

            <span *ngSwitchCase="'tag'"><span *ngIf="item[c.name]">{{(item[c.name])|translate|ucFirst}}</span><span
              *ngIf="!item[c.name]">-
            </span></span>

            <span *ngSwitchCase="'currency'"><span
              *ngIf="item[c.name]">{{(item[c.name]) | localizedCurrency}}</span><span
              *ngIf="!item[c.name]">-
            </span></span>

            <span *ngSwitchCase="'date'"><span
              *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? this.company.dateFormat : 'DD-MM-YYYY')}}</span></span>

            <span *ngSwitchCase="'dateTime'"><span
              *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>

            <span *ngSwitchCase="'url'">
               <span *ngIf="item[c.name] && item[c.name].receipt.url && item[c.name].invoice.url">
                  <a class="download" href="{{item[c.name].receipt.url}}"
                     target="_new">{{item[c.name].receipt.tag|translate|ucFirst}}</a> |
                  <a class="download" href="{{item[c.name].invoice.url}}"
                     target="_new">{{item[c.name].invoice.tag|translate|ucFirst}}</a>
               </span>
             </span>

            <span *ngSwitchDefault><span
              *ngIf="item[c.name]">{{item[c.name]}}</span><span *ngIf="!item[c.name]">-</span></span>
          </span>
        </td>
      </tr>
      </tbody>
    </table>
    <td-paging-bar #pagingBar [pageSize]="pageSize" [total]="filteredInvoiceCount">
      <span hide-xs>{{'rows_p_page'|translate}}:</span>
      {{pagingBar.range}} <span hide-xs>of {{pagingBar.total}}</span>
    </td-paging-bar>
  </ng-template>
</div>
