<mat-card-content class="push-bottom-none">
  <mat-card-title>{{ 'contact_role_title'|translate }}</mat-card-title>
  <mat-form-field flex>
    <mat-label>{{ 'role'|translate|ucFirst }}</mat-label>
    <mat-select [(ngModel)]="this.contact.type" class="push-top" required>
      <mat-select-trigger>
        <span class="select-text">{{ 'contact_type_' + this.contact.type | translate | ucFirst }}</span>
      </mat-select-trigger>
      <mat-option *ngFor="let type of passengerTypes" [value]="type">
        <span>{{ 'contact_type_' + type | translate | ucFirst }}</span>
      </mat-option>
    </mat-select>
  </mat-form-field>

</mat-card-content>
<mat-divider></mat-divider>
<mat-card-actions>
  <button (click)="save()" class="pull-bottom" color="accent"
          flex mat-raised-button>{{ 'save'|translate|ucFirst }}
  </button>
</mat-card-actions>
