<mat-card class="{{defaultCssClasses}} {{cssClasses}}">
  <mat-card-title>{{title|translate|ucFirst}}</mat-card-title>
  <mat-card-subtitle>{{subTitle|translate|ucFirst}}</mat-card-subtitle>
  <mat-divider></mat-divider>
  <mat-list class="will-load">
    <div *ngIf="ratings?.length === 0" class="mat-padding" layout="row" layout-align="center center">
      <h3>No ratings to display.</h3>
    </div>
    <ng-template [ngForOf]="ratings" let-last="last" let-rating ngFor>
      <mat-list-item>
        <mat-icon *ngIf="rating.type == 'passenger' || !rating.type" matListAvatar>local_taxi</mat-icon>
        <mat-icon *ngIf="rating.type == 'operator'" matListAvatar>headset_mic</mat-icon>
        <h3 matLine>
          <span *ngFor="let score of [1, 2, 3, 4, 5];">
            <mat-icon *ngIf="score <= rating.score">star</mat-icon>
            <mat-icon *ngIf="score > rating.score">star_border</mat-icon>
          </span>
        </h3>
        <p matLine title="{{rating.comment}}">{{'comment'|translate|ucFirst}}:
          <strong>{{rating.comment || '-'}}</strong></p>
        <p matLine title="{{rating.comment}}">
          {{'reference'|translate|ucFirst}}: <strong (click)="openRide(rating.Ride.id)"
                                                     style="text-decoration: underline; cursor: pointer;">{{rating.Ride.reference}}</strong>
        </p>
        <p matLine title="{{rating.comment}}">{{'date'|translate|ucFirst}}:
          <strong>{{rating.created | amDateFormat:'DD-MM-YYYY HH:mm'}}</strong></p>
      </mat-list-item>
      <mat-divider *ngIf="!last" matInset></mat-divider>
    </ng-template>
  </mat-list>
</mat-card>
