<div [formGroup]="form"
     [mediaClasses]="['push-sm']"
     class="push-top push-bottom" flex="100"
     layout-gt-sm="row"
     tdMediaToggle="gt-xs" tdMediaToggle="gt-xs">
  <mat-list-item>
    <mat-form-field flex>
      <mat-label>{{'note'|translate|ucFirst}}</mat-label>
      <textarea formControlName="note" matInput>{{ride.note}}</textarea>
    </mat-form-field>
  </mat-list-item>
</div>
