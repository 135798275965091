import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {DriverService} from '../../../services/driver.service';
import {TdLoadingService} from '@covalent/core/loading';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Driver} from '../../../models/driver';
import {NavigationService} from '../../../services/navigation.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss'],
  providers: [DriverService]
})
export class InvoicesComponent implements OnInit {

  searchIds: string[] = [];
  driver: Driver;
  companyId: string;
  dataLoaded = false;
  openInvoiceCount = 0;

  constructor(
    private _translateService: TranslateService,
    private _titleService: Title,
    private _vault: CoolLocalStorage,
    private _activatedRoute: ActivatedRoute,
    private _loadingService: TdLoadingService,
    private _navigationService: NavigationService,
    private _driverService: DriverService
  ) {
    const parentParams: any = this._activatedRoute.parent.snapshot.params;
    this.companyId = parentParams.id;
    this.driver = _vault.getObject(`${environment.vaultPrefix}.driver`);
    this.searchIds.push(this.driver.id);
    this._navigationService.setActionLink('');

    _translateService.get(['my_invoices', 'invoice_settings']).subscribe((translations: any) => {
      this._titleService.setTitle(translations.my_invoices + environment.windowTitleSuffix);
      this._navigationService.setBodyTitle(translations['my_invoices']);
    });
  }

  ngOnInit() {
    this._loadingService.register('data.open.loaded');
    this._loadingService.register('data.history.loaded');
    this.loadData();
  }

  loadData() {
    this._driverService.getCompanies().subscribe((drivers: any) => {
      console.log(`[InvoicesComponent.drivers]: `, drivers);

      drivers.forEach((driver) => {
        this.searchIds.push(driver.id);
      });

      this._loadingService.resolve('data.open.loaded');
      this._loadingService.resolve('data.history.loaded');
      this.dataLoaded = true;
    }, error => {
      console.error(error);
    });
  }

  openInvoiceCountChanged(count: number) {
    console.log(`[InvoicesComponent.openInvoiceCountChanged]: count:`, count);
    this.openInvoiceCount = count;
  }

}
