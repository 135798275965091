<div layout="column" layout-fill>
  <mat-toolbar class="mat-whiteframe-z1" color="primary">
    <a class="top-logo" routerLink="/login">
      <mat-icon class="mat-icon-logo" svgIcon="assets:logo"></mat-icon>
    </a>
    <span> | {{'signup_welcome_title'|translate|ucFirst}}</span>
  </mat-toolbar>
  <div class="mat-content" flex layout-padding>
    <div *ngIf="this.currentStep === 'usedToken'" class="margin" layout-align-gt-xs="center start" layout-gt-xs="row">
      <div flex-gt-xs="50">
        <mat-card [mediaClasses]="['push-top-lg']" tdMediaToggle="gt-xs">
          <mat-card-content>
            <h3>{{'license_key_used'|translate|ucFirst}}</h3>
          </mat-card-content>
          <mat-divider></mat-divider>
          <mat-card-actions layout="row">
            <button (click)="this.currentStep='step1';" color="accent" flex mat-raised-button
                    type="submit">{{'login'|translate|ucFirst}}</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>

    <div *ngIf="this.currentStep !== 'usedToken'" class="margin" layout-align-gt-xs="center start" layout-gt-xs="row">
      <div class="signup-pane" flex="100" flex-gt-md="50">
        <mat-card [mediaClasses]="['push-top-lg']" tdMediaToggle="gt-xs">
          <mat-card-title>{{layoutTitle|translate|ucFirst}}</mat-card-title>
          <mat-card-subtitle [innerHtml]="layoutSubTitle|translate|ucFirst"></mat-card-subtitle>
          <mat-divider></mat-divider>
          <mat-card-content
            *ngIf="this.currentStep === 'step1' || this.currentStep === 'step2' || this.currentStep === 'step2_loggedin'">
            <td-message *ngIf="showError" class="push-bottom" color="{{(error?'warn':'primary')}}"
                        icon="error" label="{{errorLabel}}" sublabel="{{errorText}}"></td-message>

            <form #loginForm="ngForm" (keyup.enter)="(this.currentStep === 'step1' ? step1() : login())"
                  [formGroup]="form">
              <div *ngIf="this.currentStep === 'step1' || this.currentStep === 'step2'">
                <div id="gSignInWrapper">
                  <div (click)="_globalFunctions.handleAuthClick();" class="customGPlusSignIn" id="customBtn">
                    <svg class="google-logo" version="1.1" viewBox="0 0 48 48"
                         xmlns="http://www.w3.org/2000/svg">
                      <g>
                        <path d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                              fill="#EA4335"></path>
                        <path d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                              fill="#4285F4"></path>
                        <path d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                              fill="#FBBC05"></path>
                        <path d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                              fill="#34A853"></path>
                        <path d="M0 0h48v48H0z" fill="none"></path>
                      </g>
                    </svg>
                    <span class="buttonText">{{'signup_with_google'|translate|ucFirst}}</span>
                  </div>
                </div>

                <div class="container">
                  <div class="line">
                    <mat-divider></mat-divider>
                  </div>
                  <div class="text mat-typography">{{'or'|translate}}</div>
                  <div class="line">
                    <mat-divider></mat-divider>
                  </div>
                </div>

                <div layout="row">
                  <h3 class="email_header" flex="100">{{'signup_with_email'|translate|ucFirst}}</h3>
                </div>
                <div layout="row">
                  <mat-form-field flex>
                    <input #userElement (keyup)="clearError($event, 'emailAddress')" [readOnly]="(this.currentStep === 'step2')"
                           formControlName="emailAddress"
                           matInput name="email"
                           onkeyup="this.value = this.value.toLowerCase()" placeholder="{{'email'|translate}}" required
                           type="text">
                    <span matPrefix><mat-icon>person</mat-icon></span>
                    <mat-hint align="start">
                      <span [hidden]="!formErrors.emailAddress" class="tc-red-600">
                        <span>{{ formErrors.emailAddress }}</span>
                      </span>
                    </mat-hint>
                  </mat-form-field>
                </div>
              </div>

              <div *ngIf="this.currentStep === 'step2' || this.currentStep === 'step2_loggedin'">
                <span *ngIf="!this.trialAllowed" class="red">{{'trial_not_allowed'|translate|ucFirst}}</span>
                <div *ngIf="this.currentStep === 'step2'" layout="row">
                  <mat-form-field flex>
                    <input #passElement formControlName="password" matInput
                           name="password" placeholder="{{'password'|translate}}" required type="password">
                    <span matPrefix><mat-icon>lock</mat-icon></span>
                    <mat-hint align="start">
                      <span [hidden]="!formErrors.password" class="tc-red-600">
                        <span>{{ formErrors.password }}</span>
                      </span>
                    </mat-hint>
                  </mat-form-field>
                </div>
                <a *ngIf="this.currentStep === 'step2'"
                   [queryParams]="{emailAddress: this.form.controls['emailAddress'].value}"
                   class="yda-link forgot_2"
                   flex href="#"
                   routerLink="/reset">{{'forgot_password'|translate}}</a>
                <div *ngIf="this.currentStep === 'step2_loggedin'">
                  <div *ngIf="this.companies && this.companies.length > 0" layout="row">
                    <mat-form-field flex>
                      <mat-select (selectionChange)="selectGroup($event)" [(value)]="selected"
                                  class="selectCompany row-select"
                                  flex
                                  formControlName="companyName"
                                  id="companyName" required>
                        <mat-select-trigger class="pull-top">
                          <mat-icon class="push-right">{{ getSelectedIcon() }}</mat-icon>
                          <span class="select-company-name">{{ getCompanyName() | translate | ucFirst}}</span>
                        </mat-select-trigger>
                        <mat-option value="createGroup">
                          <mat-icon>add</mat-icon>
                          {{ 'create_new_group' | translate | ucFirst }}
                        </mat-option>
                        <mat-option *ngFor="let company of this.companies" [value]="company.id">
                          <mat-icon>business</mat-icon>
                          {{ company.name | ucFirst }}
                        </mat-option>
                      </mat-select>
                      <mat-hint align="start">
                          <span [hidden]="!formErrors.companyName" class="tc-red-600">
                            <span>{{ formErrors.companyName }}</span>
                          </span>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div *ngIf="(this.selected === 'createGroup')" layout="row">
                    <mat-form-field flex>
                      <input #newCompanyName formControlName="newCompanyName" matInput placeholder="{{'company_name'|translate|ucFirst}}" required
                             type="text" value="">
                      <mat-hint align="start">
                      <span [hidden]="!formErrors.newCompanyName" class="tc-red-600">
                        <span>{{ formErrors.newCompanyName }}</span>
                      </span>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                  <div *ngIf="prefill && this.selected !== 'createGroup'" layout="row">
                    <mat-form-field flex>
                      <input #licenseKey formControlName="licenseKey" matInput placeholder="{{'license_key'|translate|ucFirst}}" required
                             type="text" value="">
                      <mat-hint align="start">
                      <span [hidden]="!formErrors.licenseKey" class="tc-red-600">
                        <span>{{ formErrors.licenseKey }}</span>
                      </span>
                      </mat-hint>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </form>
          </mat-card-content>

          <mat-card-content *ngIf="this.currentStep === 'step2_confirm_email'">
            <td-message *ngIf="showError" class="push-bottom" color="{{(error?'warn':'primary')}}"
                        icon="error" label="{{errorLabel}}" sublabel="{{errorText}}"></td-message>
            <form #loginForm="ngForm" [formGroup]="form4">
              <div layout="row">
                <mat-form-field flex>
                  <input #validationCode formControlName="validationCode"
                         matInput
                         maxlength="6"
                         name="validationCode" onkeyup="this.value = this.value.toLowerCase()"
                         placeholder="{{'validation_code'|translate}}" required type="text">
                  <span matPrefix><mat-icon>person</mat-icon></span>
                  <mat-hint align="start">
                    <span [hidden]="!formErrors.validationCode" class="tc-red-600">
                      <span>{{ formErrors.validationCode }}</span>
                    </span>
                  </mat-hint>
                </mat-form-field>
              </div>
            </form>
          </mat-card-content>

          <mat-card-content *ngIf="this.currentStep === 'step2_confirm_password'">
            <ng-template tdLoading="setPassword">
              <td-message *ngIf="showError" class="push-bottom" color="{{(error?'warn':'primary')}}"
                          icon="error" label="{{errorLabel}}" sublabel="{{errorText}}"></td-message>
              <form #loginForm="ngForm" [formGroup]="form5">
                <div layout="row">
                  <mat-form-field flex>
                    <input #firstPasswordElement (keypress)="confirmPassword()" formControlName="password" matInput
                           placeholder="{{'password'|translate}}" type="password">
                    <mat-hint align="start">
                    <span [hidden]="!formErrors.password" class="tc-red-600">
                      <span>{{ formErrors.password }}</span>
                    </span>
                    </mat-hint>
                  </mat-form-field>
                </div>
                <div layout="row">
                  <mat-form-field flex>
                    <input #secondPasswordElement (keypress)="confirmPassword()" formControlName="passwordConfirm"
                           matInput placeholder="{{'confirm_password'|translate}}"
                           type="password">
                    <mat-hint align="start">
                    <span [hidden]="!formErrors.passwordConfirm" class="tc-red-600">
                      <span>{{ formErrors.passwordConfirm }}</span>
                    </span>
                    </mat-hint>
                  </mat-form-field>
                </div>
              </form>
            </ng-template>
          </mat-card-content>

          <mat-card-content *ngIf="this.currentStep === 'step3'" class="push-bottom-none">
            <ng-template tdLoading="profile">
              <form [formGroup]="form2">
                <input #timezone formControlName="timezone" type="hidden" value="">
                <div class="push-bottom-signup push-top-sm">
                  <mat-form-field class="push-right signup-field" flex>
                    <input #fname (keyup)="clearError($event,
                           'fname')" formControlName="fname" matInput placeholder="{{'first_name'|translate|ucFirst}}"
                           required type="text" value="">
                    <mat-hint align="start">
                      <span [hidden]="!formErrors.fname" class="tc-red-600">
                        <span>{{ formErrors.fname }}</span>
                      </span>
                    </mat-hint>
                  </mat-form-field>
                  <mat-form-field class="signup-field" flex>
                    <input #lname (keyup)="clearError($event,
                           'lname')" formControlName="lname" matInput placeholder="{{'last_name'|translate|ucFirst}}"
                           required type="text" value="">
                    <mat-hint align="start">
                      <span [hidden]="!formErrors.lname" class="tc-red-600">
                        <span>{{ formErrors.lname }}</span>
                      </span>
                    </mat-hint>
                  </mat-form-field>
                </div>
                <div class="layout-row">
                  <mat-form-field class="push-right signup-field" flex flex-lt-md="100">
                    <input #emailAddress (keyup)="clearError($event, 'emailAddress')" [readOnly]="this.form.controls.emailAddress.valid" class="disabled" formControlName="emailAddress"
                           matInput onkeyup="this.value = this.value.toLowerCase()"
                           placeholder="{{'email'|translate|ucFirst}}"
                           readonly
                           required
                           type="email"
                           value="{{this.form.controls.emailAddress.value}}">
                    <mat-hint align="start">
                      <span [hidden]="!formErrors.emailAddress" class="tc-red-600">
                        <span>{{ formErrors.emailAddress }}</span>
                      </span>
                    </mat-hint>
                  </mat-form-field>

                  <div class="phoneNumberField push-right signup-field {{(formErrors.phoneNumber ?
                  'phoneNumberInvalid' : '')}}" flex>
                    <ngx-intl-tel-input
                      (keyup)="clearError($event, 'phoneNumber')"
                      [cssClass]="'phoneNumberInput'"
                      [enableAutoCountrySelect]="true"
                      [enablePlaceholder]="true"
                      [maxLength]="'15'"
                      [phoneValidation]="true"
                      [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                      [searchCountryFlag]="true"
                      [selectFirstCountry]="false"
                      [selectedCountryISO]="this.guessCountry()"
                      flex formControlName="phoneNumber">
                    </ngx-intl-tel-input>

                    <mat-hint align="start" class="warning">
                      <span [hidden]="!formErrors.phoneNumber" class="tc-red-600">
                        <span>{{ formErrors.phoneNumber }}</span>
                      </span>
                    </mat-hint>
                  </div>
                </div>
              </form>
            </ng-template>
          </mat-card-content>

          <mat-card-content *ngIf="this.currentStep === 'step4'" class="push-bottom-none">
            <ng-template tdLoading="signup_step_4">
              <form [formGroup]="form3">
                <div layout="row">
                  <mat-form-field class="push-right signup-field" flex>
                    <input #companyName (keyup)="clearError($event,
                           'companyName')" formControlName="companyName" matInput placeholder="{{'company_name'|translate|ucFirst}}"
                           required type="text" value="">
                    <mat-hint align="start">
                      <span [hidden]="!formErrors.companyName" class="tc-red-600">
                        <span>{{ formErrors.companyName }}</span>
                      </span>
                    </mat-hint>
                  </mat-form-field>

                  <mat-form-field class="signup-field" flex>
                    <input #website (keyup)="clearError($event,
                           'website')" formControlName="website" matInput placeholder="{{'signup_website'|translate|ucFirst}}"
                           required type="string" value="">
                    <mat-hint align="start">
                      <span [hidden]="!formErrors.website" class="tc-red-600">
                        <span>{{ formErrors.website }}</span>
                      </span>
                    </mat-hint>
                  </mat-form-field>
                </div>
                <div layout="row">
                  <mat-form-field class="push-right signup-field" flex>
                    <input #estimatedJobs formControlName="estimatedJobs" matInput placeholder="{{'signup_estimated_jobs'|translate|ucFirst}}" required
                           type="number" value="">
                    <mat-hint align="start">
                      <span [hidden]="!formErrors.estimatedJobs" class="tc-red-600">
                        <span>{{ formErrors.estimatedJobs }}</span>
                      </span>
                    </mat-hint>
                  </mat-form-field>

                  <mat-form-field class="signup-field" flex>
                    <input #fleetSize formControlName="fleetSize" matInput placeholder="{{'signup_fleet_size'|translate|ucFirst}}" required
                           type="number" value="">
                    <mat-hint align="start">
                      <span [hidden]="!formErrors.fleetSize" class="tc-red-600">
                        <span>{{ formErrors.fleetSize }}</span>
                      </span>
                    </mat-hint>
                  </mat-form-field>
                </div>

                <div layout="row">
                  <mat-form-field class="signup-field push-right" flex>
                    <mat-select flex formControlName="currentDispatchMethod"
                                placeholder="{{'signup_current_dispatch_method'|translate}}">
                      <mat-option *ngFor="let type of currentDispatchMethods"
                                  [value]="type.value">{{ type.label | translate | ucFirst }}</mat-option>
                    </mat-select>
                    <mat-hint align="start">
                      <span [hidden]="!formErrors.currentDispatchMethod" class="tc-red-600">
                        <span>{{ formErrors.currentDispatchMethod }}</span>
                      </span>
                    </mat-hint>
                  </mat-form-field>

                  <mat-form-field class="signup-field" flex>
                    <mat-select flex formControlName="howDidYouHear"
                                placeholder="{{'signup_how_did_you_hear'|translate}}">
                      <mat-option *ngFor="let type of contactRefferer"
                                  [value]="type.value">{{ type.label | translate | ucFirst }}</mat-option>
                    </mat-select>
                    <mat-hint align="start">
                      <span [hidden]="!formErrors.howDidYouHear" class="tc-red-600">
                        <span>{{ formErrors.howDidYouHear }}</span>
                      </span>
                    </mat-hint>
                  </mat-form-field>
                </div>

                <div layout="row">
                  <mat-form-field *ngIf="this.form3.controls['currentDispatchMethod'].value ===
                  'dispatchoption'" class="push-right signup-field" flex>
                    <input #companyName formControlName="currentDispatchMethodOther" matInput placeholder="{{'signup_current_dispatch_method_other'|translate|ucFirst}}" required
                           type="text" value="">
                    <mat-hint align="start">
                      <span [hidden]="!formErrors.currentDispatchMethodOther" class="tc-red-600">
                        <span>{{ formErrors.currentDispatchMethodOther }}</span>
                      </span>
                    </mat-hint>
                  </mat-form-field>

                  <mat-form-field *ngIf="this.form3.controls['howDidYouHear'].value ===
                  'other'" class="signup-field" flex>
                    <input #website formControlName="howDidYouHearOther" matInput placeholder="{{'signup_how_did_you_hear_other'|translate|ucFirst}}" required
                           type="string" value="">
                    <mat-hint align="start">
                      <span [hidden]="!formErrors.howDidYouHearOther" class="tc-red-600">
                        <span>{{ formErrors.howDidYouHearOther }}</span>
                      </span>
                    </mat-hint>
                  </mat-form-field>
                </div>

                <div layout="row">
                  <mat-form-field class="signup-field" flex>
                    <mat-select (selectionChange)="interestedinChanged($event)" flex
                                formControlName="interestedin" multiple
                                placeholder="{{'signup_interestedin'|translate}}">
                      <mat-option *ngFor="let type of interestedIn"
                                  [value]="type.value">{{ type.label | translate | ucFirst }}</mat-option>
                    </mat-select>
                    <mat-hint align="start">
                      <span [hidden]="!formErrors.currentDispatchMethod" class="tc-red-600">
                        <span>{{ formErrors.currentDispatchMethod }}</span>
                      </span>
                    </mat-hint>
                  </mat-form-field>
                </div>

                <div layout="row">
                  <mat-form-field flex>
                      <textarea class="signup-info" color="primary" formControlName="marketingInfo" matInput
                                placeholder="{{'marketing_info'|translate|ucFirst}}"></textarea>
                    <mat-hint align="start">
                      <span [hidden]="!formErrors.marketingInfo" class="tc-red-600">
                        <span>{{ formErrors.marketingInfo }}</span>
                      </span>
                    </mat-hint>
                  </mat-form-field>
                </div>
                <div *ngIf="prefill" layout="row">
                  <mat-form-field flex>
                    <input #licenseKey formControlName="licenseKey" matInput placeholder="{{'license_key'|translate|ucFirst}}" required
                           type="tel" value="">
                    <mat-hint align="start">
                      <span [hidden]="!formErrors.licenseKey" class="tc-red-600">
                        <span>{{ formErrors.licenseKey }}</span>
                      </span>
                    </mat-hint>
                  </mat-form-field>
                </div>
              </form>
            </ng-template>
          </mat-card-content>

          <mat-divider></mat-divider>
          <mat-card-actions layout="row">
            <button (click)="this.currentStep='step1';layoutTitle = 'signup_welcome_title';layoutSubTitle = 'signup_subtitle';" *ngIf="this.currentStep!=='step1'" color="accent" flex
                    mat-raised-button
                    type="submit">{{'back'|translate|ucFirst}}</button>

            <button (click)="step1()" *ngIf="this.currentStep === 'step1'" color="accent" flex
                    mat-raised-button
                    type="submit">{{'validate_email_address'|translate|ucFirst}}</button>

            <button (click)="confirmEmail()" *ngIf="this.currentStep === 'step2_confirm_email'"
                    [disabled]="!this.form4.controls.validationCode.valid" color="accent"
                    flex
                    mat-raised-button
                    type="submit">{{'step2_confirm_email_button'|translate|ucFirst}}</button>

            <button (click)="setPassword()" *ngIf="this.currentStep === 'step2_confirm_password'"
                    [disabled]="!this.form5.valid" color="accent"
                    flex
                    mat-raised-button
                    type="submit">{{'step2_confirm_password_button'|translate|ucFirst}}</button>

            <button (click)="login()" *ngIf="this.currentStep === 'step2'" [disabled]="!this.form.controls.password.valid" color="accent"
                    flex
                    mat-raised-button
                    type="submit">{{'login'|translate|ucFirst}}</button>

            <button (click)="(this.selected !== 'createGroup' ? startTrialForGroup() : connectLicenseKey())"
                    *ngIf="this.currentStep === 'step2_loggedin' && this.trialAllowed" [disabled]="!this.form.valid && this.selected === 'createGroup'" color="accent"
                    flex
                    mat-raised-button
                    type="submit">{{(this.selected !== 'createGroup' ?
              'signup_new_account_button' : 'signup_new_account_button') |translate|ucFirst}}</button>

            <button (click)="connectLicenseKey(false)" *ngIf="prefill && this.currentStep === 'step2_loggedin' && !this.trialAllowed" [disabled]="!this.form.valid" color="accent"
                    flex
                    mat-raised-button
                    type="submit">{{(this.selected !== 'createGroup' ?
              'use_license_key' : 'create_new_group') |translate|ucFirst}}</button>

            <button (click)="loginToGroup()"
                    *ngIf="!prefill && this.currentStep === 'step2_loggedin' && !this.trialAllowed"
                    color="accent"
                    flex
                    mat-raised-button
                    type="submit">{{(this.selected !== 'createGroup' ? 'login_to_group' : 'create_new_group')|translate|ucFirst}}</button>

            <button (click)="step3()" *ngIf="this.currentStep === 'step3'" color="accent" flex
                    mat-raised-button
                    type="submit">{{'validate_email_address'|translate|ucFirst}}</button>

            <button (click)="(directLogin ? connectLicenseKey(true) : signupNewUser())" *ngIf="this.currentStep === 'step4'" color="accent" flex
                    mat-raised-button
                    type="submit">{{'signup_new_account_button'|translate|ucFirst}}</button>

            <a *ngIf="this.currentStep==='step1' && false" class="yda-link-blue forgot" flex
               routerLink="/login">{{'back_to_login'|translate|ucFirst}}</a>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</div>
