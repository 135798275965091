<div *ngIf="passenger && passenger.id" class="dialog-pane">
  <mat-card-title class="push-bottom">{{'update_payment_method'|translate|ucFirst}}</mat-card-title>
  <mat-divider class="push-bottom"></mat-divider>
  <app-ride-edit-paymentmethod *ngIf="ride" [company]="company"
                               [form]="form" [paymentMeta]="ride.paymentMeta"></app-ride-edit-paymentmethod>

  <app-passenger-paymentmethods *ngIf="ride" [company]="company" [form]="form" [passenger]="ride.passenger"
                                class="value"
                                flex-gt-sm="50"></app-passenger-paymentmethods>
  <td-dialog-actions>
    <button (click)="save()" color="accent" mat-button>
      {{'save'|translate|ucFirst}}
    </button>
    <button (click)="dialogRef.close()" class="push-right-small" color="accent" mat-button>
      {{'cancel'|translate|ucFirst}}
    </button>
  </td-dialog-actions>
</div>
<div *ngIf="!passenger || !passenger.id">{{'no_passenger_selected_title'|translate|ucFirst}}</div>
