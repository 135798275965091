import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Company} from '../../../../../../../../../../models/company';
import {WebbookerService} from '../../../../../../../../../../services/webbooker.service';
import {DaAppInstallService} from '../../../../../../../../../../services/da-app-install.service';

@Component({
  selector: 'app-delete-webbooker-dialog',
  templateUrl: './delete-webbooker-dialog.component.html',
  styleUrls: ['./delete-webbooker-dialog.component.scss']
})
export class DeleteWebbookerDialogComponent implements OnInit {
  company: Company;
  formErrors: any = {};
  helpText: string;
  confirmInput: string;
  translations: string[];

  constructor(
    private _translateService: TranslateService,
    private _router: Router,
    private _webbookerService: WebbookerService,
    private _daAppInstallService: DaAppInstallService,
    public dialogRef: MatDialogRef<DeleteWebbookerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    _translateService.get([
      `delete_webbooker_dialog_subtitle`,
      'delete_webbooker_dialog_text_error'
    ]).subscribe((translations: any) => {
      this.translations = translations;
    });
  }

  ngOnInit(): void {
    this.helpText = this.translations['delete_webbooker_dialog_subtitle'].replace('{webbooker_name}', this.data.webbooker.name);
  }

  deleteWebbooker() {
    const self = this;
    if (this.data.webbooker && this.data.webbooker.name !== this.confirmInput) {
      this.formErrors.confirm = this.translations['delete_webbooker_dialog_text_error'];
    } else {
      self._webbookerService.delete(self.data.webbooker.form.id, 'company').subscribe(() => {
        self._webbookerService.deleteAccount(self.data.webbooker.form.accountId, 'company').subscribe(() => {
          self._daAppInstallService.delete(self.data.daAppInstall.id, 'company').subscribe(() => {
            this.dialogRef.close();
            this.data.parent.loadData();
          }, error => {
            console.error(error);
          });
        }, error => {
          console.error(error);
        });
      }, error => {
        console.error(error);
      });
    }
  }
}
