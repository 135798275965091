import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Ride} from '../../../../../../../models/ride';

@Component({
  selector: 'app-ride-edit-meta',
  templateUrl: './ride-edit-meta.component.html',
  styleUrls: ['./ride-edit-meta.component.scss']
})
export class RideEditMetaComponent implements OnInit {
  @Input() ride: Ride;
  @Input() form: UntypedFormGroup;
  @Input() formErrors: any;

  constructor() {
  }

  ngOnInit(): void {
  }

}
