import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Company} from '../../../../models/company';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {NavigationService} from '../../../../services/navigation.service';
import {UtilityService} from '../../../../services/utility.service';
import {environment} from '../../../../../environments/environment';
import {OpenInvoicesComponent} from '../../../shared/invoices/open-invoices/open-invoices.component';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {ToBeInvoicedComponent} from '../../../shared/invoices/to-be-invoiced/to-be-invoiced.component';

@Component({
  selector: 'app-customer-invoices',
  templateUrl: './customer-invoices.component.html',
  styleUrls: ['./customer-invoices.component.scss']
})
export class CustomerInvoicesComponent implements OnInit, OnDestroy {
  context: string;
  company: Company;
  searchIds: string[] = [];
  companyId: string;
  dataLoaded = true;
  openInvoiceCount = 0;
  selectedTabIndex = 0;
  @ViewChild('draftComponent') draftComponent!: OpenInvoicesComponent;
  @ViewChild('historyComponent') historyComponent!: OpenInvoicesComponent;
  @ViewChild(ToBeInvoicedComponent) toBeInvoicedComponent!: ToBeInvoicedComponent;

  constructor(
    private _translateService: TranslateService,
    private _titleService: Title,
    private _route: ActivatedRoute,
    private _activatedRoute: ActivatedRoute,
    private _navigationService: NavigationService
  ) {
    const parentParams: any = this._activatedRoute.parent.snapshot.params;
    this.companyId = parentParams.id;
    this.searchIds.push(this.companyId);

    this.companyId = this._route.parent.snapshot.paramMap.get('id');
    this.context = 'company';
    if (this.context === 'company') {
      const {company} = this._route.parent.snapshot.data;
      UtilityService.setBrowserTimeStamp(company);
      this.company = company;
    }

    _translateService.get(['customer_invoices_title', 'invoice_settings']).subscribe((translations: any) => {
      this._titleService.setTitle(translations['customer_invoices_title'] + environment.windowTitleSuffix);
      this._navigationService.setBodyTitle(translations['customer_invoices_title']);
      this._navigationService.setActionLink('');
      this._navigationService.setInfoAction('');
      this._navigationService.setSplitLayoutButtons([]);
    });
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    this._navigationService.setSplitLayoutButtons([]);
  }

  openInvoiceCountChanged(count: number) {
    console.log(`[InvoicesComponent.openInvoiceCountChanged]: count:`, count);
    this.openInvoiceCount = count;
  }

  //noinspection JSMethodCanBeStatic
  onTabChange(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
  }

  switchTab(tab) {
    if (tab === 'draft') {
      this.selectedTabIndex = 1;
      this.draftComponent.loadData();
      this.toBeInvoicedComponent.invoiceFilterChanged();
    } else if (tab === 'history') {
      this.selectedTabIndex = 2;
      this.historyComponent.loadData();
      this.toBeInvoicedComponent.invoiceFilterChanged();
    }
  }

  reloadAllData() {
    console.log('Reloading!');
    this.toBeInvoicedComponent.invoiceFilterChanged();
    this.draftComponent.loadData();
    this.historyComponent.loadData();
  }
}
