import {Component, Inject, Input, OnInit} from '@angular/core';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {UtilityService} from '../../../../../services/utility.service';
import {environment} from '../../../../../../environments/environment';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Company} from '../../../../../models/company';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-customer-upsert',
  templateUrl: './customer-upsert.component.html',
  styleUrls: ['./customer-upsert.component.scss']
})
export class CustomerUpsertComponent implements OnInit {
  @Input() parent;
  company: Company;
  selectedTabIndex: number;
  loadedType: string;

  constructor(
    private _titleService: Title,
    private _translateService: TranslateService,
    private _route: ActivatedRoute,
    public dialogRef: MatDialogRef<CustomerUpsertComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    _translateService.get(['add_customer']).subscribe((translations: any) => {
      this._titleService.setTitle(translations['add_customer'] + environment.windowTitleSuffix);
    });

    if (dialogData && dialogData.parent) {
      this.parent = dialogData.parent;
      this.parent.dialog = this.dialogRef;
    } else {
      const {company} = this._route.parent.snapshot.data;
      UtilityService.setBrowserTimeStamp(company);
      this.company = company;
    }

    if (this._route.routeConfig && this._route.routeConfig['activeTab'] === 'contact') {
      this.selectedTabIndex = 1;
      this.loadedType = this._route.routeConfig['activeTab'];
    }
    if (!this._route.routeConfig || !this._route.routeConfig['activeTab']) {
      this.loadedType = '';
    }
  }

  ngOnInit(): void {
  }

  onTabChange(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
