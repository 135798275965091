<ng-container>
  <div class="warning-pane" *ngIf="priceError">
    <mat-icon>warning</mat-icon>
    <span [innerHTML]="priceError|translate|ucFirst|keepHtml"></span>
  </div>

  <form *ngIf="form" [formGroup]="form">
    <div *ngIf="user.debug && user.ydaSupportUser " class="support-pane">
      {{ 'form_id' | translate | ucFirst }}: {{ booker.form.id }}
    </div>

    <div class="tab-content">
      <div flex="50" layout="row">
        <mat-form-field flex>
          <input (change)="(formErrors.name ? this.validateFormField(this.form,
                      'name') : '')" formControlName="name" matInput
                 placeholder="{{'name'|translate|ucFirst}}" type="text" value="{{booker.name}}">
          <mat-hint align="start" class="warning">
                  <span [hidden]="!formErrors.name" class="tc-red-600">
                    <span>{{ formErrors.name }}</span>
                  </span>
          </mat-hint>
        </mat-form-field>
      </div>


      <div flex="50" layout="row">{{ 'create_url_text' | translate | ucFirst }}</div>
      <div flex="50" layout="row">
        <div class="enter-url">
          <span>{{ environment.orderFormFrontEndUrl }}/</span>
          <div class="domain_url">
            <mat-form-field class="confirmation-field {{(formErrors.webbookerUrl ?
            'ng-invalid' : '')}}" flex>
              <input (blur)="checkUniqueUrl()" (keydown)="ValidateAllFormFields.noSpecialChars($event)"
                     (keyup)="this.formErrors.webbookerUrl
              = ''"
                     class="{{(formErrors.webbookerUrl ? 'input-warning' : '')}}" flex="100"
                     formControlName="webbookerUrl" matInput
                     type="text" value="">
              <mat-hint align="start" class="warning">
            <span [hidden]="!formErrors.webbookerUrl" class="tc-red-600">
              <span>{{ formErrors.webbookerUrl|translate }}</span>
            </span>
              </mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div flex="50" layout="row">
        <mat-form-field flex>
          <input (change)="(formErrors.pageTitle ? this.validateFormField(this.form,
                      'pageTitle') : '')" formControlName="pageTitle" matInput
                 placeholder="{{'webbooker_page_title'|translate|ucFirst}}" type="text" value="{{booker.pageTitle}}">
          <mat-hint align="start" class="warning">
                  <span [hidden]="!formErrors.pageTitle" class="tc-red-600">
                    <span>{{ formErrors.pageTitle }}</span>
                  </span>
          </mat-hint>
        </mat-form-field>
      </div>

      <div class="label_title" flex="100" layout="row">{{ 'features'|translate|ucFirst }}</div>
      <div flex="100" layout="row">
        <div flex="50" layout="column">
          <mat-checkbox formControlName="passengerLogin">{{ 'passenger_login'|translate|ucFirst }}
            <a class="yda-link-blue more_info" href="https://intercom.help/yourdriverapp/en/articles/8937604-web-booker"
               target="_new">{{ 'more_info'|translate|ucFirst }}</a></mat-checkbox>
        </div>

        <div flex="50" layout="column">
          <mat-checkbox
            formControlName="bookingTypesRoute">{{ 'webbooker_settings_route'|translate|ucFirst }}</mat-checkbox>
        </div>
      </div>

      <div flex="100" layout="row">
        <div flex="50" layout="column">
          <mat-checkbox
            formControlName="allowStopOvers">{{ 'webbooker_settings_allow_stopovers'|translate|ucFirst }}
          </mat-checkbox>
        </div>
        <div flex="50" layout="column">
          <mat-checkbox
            formControlName="allowReturnBookings">{{ 'webbooker_settings_allow_return'|translate|ucFirst }}
          </mat-checkbox>
        </div>
      </div>

      <div flex="100" layout="row">
        <div layout="column" flex="50">
          <mat-checkbox
            formControlName="productSummery">{{ 'webbooker_product_selection_extra_info'|translate|ucFirst }}
          </mat-checkbox>
        </div>

        <div layout="column" flex="50">
          <mat-checkbox
            formControlName="showRouteMap"
            [disabled]="(this.company.googleKey ? false : true)">{{ 'webbooker_settings_show_route_map'|translate|ucFirst }}
          </mat-checkbox>

          <mat-hint align="start" class="warning">
            <span [hidden]="!formErrors.showRouteMap" class="tc-red-600">
              <span>{{ formErrors.showRouteMap }}</span>
            </span>
          </mat-hint>
          <mat-hint align="start" *ngIf="!this.company.googleKey">
            <span class="text_addition">{{ 'not_available_no_google_key'|translate }}</span>
          </mat-hint>
        </div>
      </div>

      <div flex="50" layout="column">
        <mat-checkbox
          formControlName="luggageOption">{{ 'webbooker_settings_add_bagage_option'|translate|ucFirst }}
        </mat-checkbox>
      </div>

      <div flex="100" layout="row" class="push-top">
        <div flex="50" layout="column">
          <mat-form-field class="medium-input number-input country-select">
            <mat-select (selectionChange)="changed()"
                        class="row-select"
                        flex="100"
                        formControlName="airportDates"
                        placeholder="{{ 'webbooker_settings_airport_date' | translate | ucFirst }}" required>
              <mat-option [value]="'none'">
                {{ 'webbooker_settings_airportdate_none' | translate | ucFirst }}
              </mat-option>
              <mat-option [value]="'optional'">
                {{ 'webbooker_settings_airportdate_optional' | translate | ucFirst }}
              </mat-option>
              <mat-option [value]="'departure'">
                {{ 'webbooker_settings_airportdate_departure' | translate | ucFirst }}
              </mat-option>
              <mat-option [value]="'destination'">
                {{ 'webbooker_settings_airportdate_destination' | translate | ucFirst }}
              </mat-option>
              <mat-option [value]="'mandatory'">
                {{ 'webbooker_settings_airportdate_mandatory' | translate | ucFirst }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div flex="50" layout="column">
          <mat-form-field class="medium-input number-input country-select">
            <mat-select (selectionChange)="changed()"
                        class="row-select"
                        flex="100"
                        formControlName="mandatoryFlightNumber"
                        placeholder="{{ 'webbooker_settings_flightnumber_mandatory' | translate | ucFirst }}" required>
              <mat-option [value]="'optional'">
                {{ 'optional' | translate | ucFirst }}
              </mat-option>
              <mat-option [value]="'mandatory_airport'">
                {{ 'mandatory_when_airport_selected' | translate | ucFirst }}
              </mat-option>
              <mat-option [value]="'mandatory_airport_pickup'">
                {{ 'mandatory_when_airport_pickup' | translate | ucFirst }}
              </mat-option>
              <mat-option [value]="'mandatory_airport_dropoff'">
                {{ 'mandatory_when_airport_dropoff' | translate | ucFirst }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div flex="100" layout="row" *ngIf="this.form.controls['airportDates'].value !== 'none'">
        <div flex="50" layout="column" class="push-right">
          <mat-form-field class="number-input">
            <input (change)="(formErrors.requestedDestinationDateOffset ? this.validateFormField(this.form,
                        'requestedDestinationDateOffset') : '')"
                   (blur)="this.validateFormField(this.form,'requestedDestinationDateOffset')"
                   formControlName="requestedDestinationDateOffset"
                   matInput
                   placeholder="{{'destination_airport_date_offset'|translate|ucFirst}}" type="number">

            <mat-hint align="end" class="warning passenger-count-warning">
                <span [hidden]="!formErrors.requestedDestinationDateOffset" class="tc-red-600">
                  <span>{{ formErrors.requestedDestinationDateOffset }}</span>
                </span>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="label_title" flex="100" layout="row">{{ 'hourly_bookings'|translate|ucFirst }}</div>
      <div flex="100" layout="row">
        <div layout="column" flex="50">
          <mat-checkbox
            formControlName="bookingTypesHourly">{{ 'webbooker_settings_hourly'|translate|ucFirst }}
          </mat-checkbox>

          <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.bookingType" class="tc-red-600">
                  <span>{{ formErrors.bookingType }}</span>
                </span>
          </mat-hint>
        </div>

        <div *ngIf="this.form.controls['bookingTypesHourly'].value" flex="50" layout="column">
          <mat-checkbox
            formControlName="bookingHourlyDestination">{{ 'webbooker_settings_hourly_destination'|translate|ucFirst }}
          </mat-checkbox>
        </div>
      </div>

      <div flex="100" layout="row" *ngIf="this.form.controls['bookingTypesHourly'].value">
        <div layout="column" flex="50">
          <mat-form-field class="medium-input number-input push-top">
            <input (change)="(formErrors.hourlyMinTime ? this.validateFormField(this.form,
                        'hourlyMinTime') : '')"
                   (blur)="this.validateFormField(this.form,'hourlyMinTime')"
                   formControlName="hourlyMinTime"
                   matInput
                   [max]="24"
                   [min]="1"
                   placeholder="{{'minimum_hours'|translate|ucFirst}}" type="number">

            <mat-hint align="end" class="warning passenger-count-warning">
                <span [hidden]="!formErrors.hourlyMinTime" class="tc-red-600">
                  <span>{{ formErrors.hourlyMinTime }}</span>
                </span>
            </mat-hint>
          </mat-form-field>
        </div>

        <div layout="column" flex="50">
          <mat-form-field class="medium-input number-input push-top">
            <input (change)="(formErrors.hourlyMaxTime ? this.validateFormField(this.form,
                        'hourlyMaxTime') : '')"
                   (blur)="this.validateFormField(this.form,'hourlyMaxTime')"
                   formControlName="hourlyMaxTime"
                   matInput
                   [min]="1"
                   placeholder="{{'maximum_hours'|translate|ucFirst}}" type="number">

            <mat-hint align="end" class="warning passenger-count-warning">
                <span [hidden]="!formErrors.hourlyMaxTime" class="tc-red-600">
                  <span>{{ formErrors.hourlyMaxTime }}</span>
                </span>
            </mat-hint>
          </mat-form-field>
        </div>

      </div>

      <div class="label_title" flex="100" layout="row">{{ 'general_settings'|translate|ucFirst }}</div>
      <div flex="100" layout="row">
        <div flex="50" layout="column" class="push-right">
          <mat-form-field class="number-input">
            <input (change)="(formErrors.gtmId ? this.validateFormField(this.form,
                      'webbooker_gtmid') : '')" formControlName="gtmId" matInput
                   placeholder="{{'webbooker_gtmid'|translate|ucFirst}}" type="text">
            <mat-hint align="start" class="warning">
              <span [hidden]="!formErrors.gtmId" class="tc-red-600">
                <span>{{ formErrors.gtmId }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
        </div>

        <div layout="column" flex="50">
          <mat-form-field class="medium-input number-input">
            <input (change)="(formErrors.passengerCount ? this.validateFormField(this.form,
                        'passengerCount') : '')"
                   (blur)="this.validateFormField(this.form,'passengerCount')"
                   formControlName="passengerCount"
                   matInput
                   [max]="maxPassengers"
                   [min]="1"
                   placeholder="{{'maximum_passenger_count'|translate|ucFirst}}" type="number">

            <mat-hint align="end" class="warning passenger-count-warning">
                <span [hidden]="!formErrors.passengerCount" class="tc-red-600">
                  <span>{{ formErrors.passengerCount }}</span>
                </span>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="label_title" flex="100" layout="row">{{ 'address_settings'|translate|ucFirst }}</div>
      <div flex="100" layout="row">
        <div flex="50" layout="column">
          <mat-checkbox
            formControlName="manditoryDestination">{{ 'webbooker_mandatory_destination'|translate|ucFirst }}
          </mat-checkbox>
        </div>

        <div flex="50" layout="column">
          <mat-checkbox
            formControlName="allowBrowserLocation">{{ 'allow_browser_location'|translate|ucFirst }}
          </mat-checkbox>
        </div>
      </div>
      <div flex="100" layout="row">
        <div flex="50" layout="column">
          <mat-checkbox
            formControlName="departureHouseNumberRequired">{{ 'webbooker_mandatory_departure_housenumber'|translate|ucFirst }}
          </mat-checkbox>
        </div>

        <div flex="50" layout="column">
          <mat-checkbox
            formControlName="destinationHouseNumberRequired">{{ 'webbooker_mandatory_destination_housenumber'|translate|ucFirst }}
          </mat-checkbox>
        </div>
      </div>

      <div flex="100" layout="row" class="push-bottom" *ngIf="false">
        <div flex="50" layout="column">
          <mat-checkbox
            formControlName="departureWorkareaCheck">{{ 'webbooker_departure_workareacheck'|translate|ucFirst }}
          </mat-checkbox>
        </div>

        <div flex="50" layout="column">
          <mat-checkbox
            formControlName="destinationWorkareaCheck">{{ 'webbooker_destination_workareacheck'|translate|ucFirst }}
          </mat-checkbox>
        </div>
      </div>

      <div flex="50" layout="row">
        <mat-form-field class="medium-input number-input country-select">
          <mat-select (selectionChange)="changed()"
                      class="row-select"
                      flex="100"
                      formControlName="countries"
                      multiple
                      placeholder="{{ 'autocomplete_countries' | translate | ucFirst }}" required>
            <mat-option [value]="'all'">
              {{ 'all_countries' | translate | ucFirst }}
            </mat-option>
            <mat-option *ngFor="let country of countries" [value]="country.alpha2Code">
              {{ country.name | translate | ucFirst }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="label_title" flex="100" layout="row">{{ 'order_times'|translate|ucFirst }}</div>

      <div flex="100" layout="row">
        <div flex="50" layout="column">
          <mat-form-field class="medium-input number-input country-select">
            <mat-select (selectionChange)="changed()"
                        class="row-select"
                        flex="100"
                        formControlName="orderTimesHandler"
                        placeholder="{{ 'webbooker_settings_order_time_handler' | translate | ucFirst }}" required>
              <mat-option [value]="'availability'">
                {{ 'only_allow_within_ordertimes' | translate | ucFirst }}
              </mat-option>
              <mat-option [value]="'always_forward_time'">
                {{ 'allow_outside_not_same_night' | translate | ucFirst }}
              </mat-option>
              <mat-option [value]="'always'">
                {{ 'allow_anytime' | translate | ucFirst }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div flex="50" layout="column">
          <mat-form-field class="medium-input number-input">
            <input (change)="(formErrors.minimumOrderTime ? this.validateFormField(this.form,
                        'webbooker_minimum_order_time') : '')" formControlName="minimumOrderTime"
                   matInput
                   placeholder="{{'webbooker_minimum_order_time_minutes'|translate|ucFirst}}" type="number">
            <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.minimumOrderTime" class="tc-red-600">
                  <span>{{ formErrors.minimumOrderTime }}</span>
                </span>
            </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="label_title" flex="100" layout="row">{{ 'payment_methods'|translate|ucFirst }}</div>
      <div class="paymentMethod-pane" flex="100" layout="row">
        <div *ngFor="let paymentMethod of paymentMethods; let i=index" layout="column">
          <label (click)="(paymentMethod.id === 'STRIPE' && !this.company.stripeAccountId ? connectStripe() : null)">
            <input (change)="onCheckboxChange($event)"
                   [checked]="(checkArray.includes(paymentMethod.id))"
                   [disabled]="(paymentMethod.id === 'STRIPE' && !this.company.stripeAccountId)"
                   [value]="paymentMethod.id"
                   type="checkbox"/>
            {{ paymentMethod.description|translate|ucFirst }}
          </label>
        </div>
      </div>

      <div class="label_title" flex="100" layout="row">{{ 'documents'|translate|ucFirst }}</div>

      <div class="push-top documents" flex="100" layout="row">
        <div flex="50" layout="row">
          <mat-form-field flex="75">
            <input formControlName="termsUrl" matInput placeholder="{{'terms_url'|translate|ucFirst}}" type="text">
          </mat-form-field>
          <a class="yda-link-blue more_info" flex href="{{booker.termsUrl}}"
             target="_blank">{{ 'open'|translate|ucFirst }}</a>
        </div>

        <div flex="50" layout="row">
          <mat-form-field flex="75">
            <input formControlName="privacyUrl" matInput placeholder="{{'privacy_url'|translate|ucFirst}}" type="text">
          </mat-form-field>
          <a class="yda-link-blue more_info" flex href="{{booker.privacyUrl}}"
             target="_blank">{{ 'open'|translate|ucFirst }}</a>
        </div>
      </div>

      <div class="label_title" flex="100" layout="row">{{ 'website_urls'|translate|ucFirst }}</div>

      <div class="documents" flex="100" layout="row">
        <div flex="50" layout="row">
          <mat-form-field flex="75">
            <mat-icon [matTooltip]="'website_return_url_tooltip'|translate|ucFirst" class="input-help">help</mat-icon>

            <input formControlName="successUrl" matInput placeholder="{{'web_booker_return_url'|translate|ucFirst}}"
                   type="text">
            <mat-hint align="start" class="warning">
              <span [hidden]="!formErrors.returnUrl" class="tc-red-600">
                <span>* {{ formErrors.returnUrl | translate }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
          <a *ngIf="booker.returnUrl" class="yda-link-blue more_info" flex href="{{booker.returnUrl}}"
             target="_blank">{{ 'open'|translate|ucFirst }}</a>
        </div>

        <div flex="50" layout="row">
          <mat-form-field flex="75">
            <input formControlName="successUrl" matInput placeholder="{{'web_booker_success_url'|translate|ucFirst}}"
                   type="text">
            <mat-hint align="start" class="warning">
              <span [hidden]="!formErrors.successUrl" class="tc-red-600">
                <span>* {{ formErrors.successUrl | translate }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
          <a *ngIf="booker.successUrl" class="yda-link-blue more_info" flex href="{{booker.successUrl}}"
             target="_blank">{{ 'open'|translate|ucFirst }}</a>
        </div>
      </div>

      <div class="label_title" flex="100" layout="row">{{ 'branding'|translate|ucFirst }}</div>

      <div *ngIf="booker.form.config.logo" layout="row">
        <img alt="logo" src="{{booker.form.config.logo}}" style="max-height:200px">
      </div>

      <div *ngIf="booker.form.config.logo" class="push-top" layout="row">
        <button (click)="removeLogo()" mat-stroked-button>{{ 'remove_logo'|translate|ucFirst }}</button>
      </div>

      <div *ngIf="!booker.form.config.logo" class="push-bottom" flex="50"
           layout="row">{{ 'webbooker_logo_upload'|translate|ucFirst }}
      </div>

      <div *ngIf="!booker.form.config.logo" class="push-bottom" flex="50" layout="row">
        <input (change)="onFileChange($event)" formControlName="image" id="singleFile" type="file">
      </div>

      <div flex="50" layout="row" class="push-top">
        <div class="color-example push-right" style="background-color:{{this.form.controls['mainColor'].value}}"></div>
        <mat-form-field class="medium-input number-input" flex>
          <input (colorPickerChange)="updateMainColor($event)"
                 [(colorPicker)]="this.form.controls['mainColor'].value"
                 [cpColorMode]="'color'"
                 [cpDialogDisplay]="'popup'"
                 [cpOKButton]="true"
                 [cpOutputFormat]="'hex'"
                 [cpPosition]="'bottom-right'"
                 flex="50"
                 formControlName="mainColor"
                 matInput
                 placeholder="{{'webbooker_main_color'|translate|ucFirst}}"/>

          <mat-hint align="start" class="warning">
                  <span [hidden]="!formErrors.name" class="tc-red-600">
                    <span>{{ formErrors.name }}</span>
                  </span>
          </mat-hint>

        </mat-form-field>
      </div>


      <mat-card-actions>
        <div class="button-pane">
          <button (click)="save()"
                  class="save_webbooker"
                  color="accent"
                  flex
                  mat-raised-button>{{ 'save'|translate|ucFirst }}
          </button>
        </div>
      </mat-card-actions>
    </div>
  </form>
</ng-container>
