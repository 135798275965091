import {Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {Company} from '../../../../../models/company';
import {ServiceAreaComponent} from '../../../settings/service-area/service-area.component';
import {ActivatedRoute, Router} from '@angular/router';
import {LocationService} from '../../../../../services/tps/location.service';
import {TdLoadingService} from '@covalent/core/loading';
import {TdDialogService} from '@covalent/core/dialogs';
import {LocationPolicyService} from '../../../../../services/tps/location-policy.service';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NavigationService} from '../../../../../services/navigation.service';
import {DaAppInstallService} from '../../../../../services/da-app-install.service';
import {CompanyService} from '../../../../../services/company.service';
import {GoogleMapsAPIWrapper, LAZY_MAPS_API_CONFIG, MapsAPILoader} from '@agm/core';
import {CustomLazyAPIKeyLoader} from '../../../../../services/CustomLazyAPIKeyLoader';
import {GoogleMapConfig} from '../../../../googleMapConfig';

export enum LocationType {
  area = 'area',
  point = 'point',
  predefined = 'predefined',
}
@Component({
  selector: 'app-ob-servicearea',
  templateUrl: './ob-servicearea.component.html',
  styleUrls: ['./ob-servicearea.component.scss'],
  providers: [LocationService, LocationPolicyService,
    GoogleMapsAPIWrapper,
    {provide: MapsAPILoader, useClass: CustomLazyAPIKeyLoader},
    {provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapConfig}
  ],
})
export class ObServiceareaComponent extends ServiceAreaComponent implements OnInit {
  updateType = 'serviceArea';
  type = LocationType.area;
  @Input() step: string;
  @Input() wizardForm: UntypedFormGroup;
  @Input() company: Company;
  @Output() nextStep = new EventEmitter<string>();

  constructor(private __route: ActivatedRoute,
              private __router: Router,
              private __locationService: LocationService,
              private __loadingService: TdLoadingService,
              private __dialogService: TdDialogService,
              private __locationPolicyService: LocationPolicyService,
              private __translateService: TranslateService,
              private __snackbar: MatSnackBar,
              private __fb: UntypedFormBuilder,
              private __ngZone: NgZone,
              private __navigationService: NavigationService,
              private __daAppInstallService: DaAppInstallService,
              private __companyService: CompanyService) {
    super(__route, __router, __locationService, __loadingService, __dialogService, __locationPolicyService, __translateService, __snackbar, __fb, __ngZone, __navigationService, __daAppInstallService, __companyService);
  }

  setStep(step) {
    this.companyId = this.company.id;
    this.form.controls.type.setValue(LocationType.area);
    if (this.saveServiceArea()) {
      this.nextStep.emit(step);
    }
  }
}

