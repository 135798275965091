<div *ngIf="!this.extendedDispatcherSubId" style="width:700px">
  <mat-card-title class="push-bottom">{{'cloud_connect_extended_dispatcher_title'|translate|ucFirst}}</mat-card-title>
  <mat-card-subtitle
    [innerHTML]="'cloud_connect_extended_dispatcher_subtitle'|translate|keepHtml"
    class="push-bottom"></mat-card-subtitle>
  <mat-divider></mat-divider>

  <table class="add_extended_dispatcher push-bottom">
    <thead>
    <tr class="header_row" td-data-table-column-row>
      <td class="item_row"
          td-data-table-column>{{'product_name'|translate|ucFirst}}</td>
      <td class="item_row"
          td-data-table-column>{{'quantity'|translate|ucFirst}}</td>
      <td class="item_row"
          td-data-table-column>{{'total_monthly'|translate|ucFirst}}</td>
    </tr>
    </thead>
    <tbody>
    <tr>
      <td class="item_row"
          td-data-table-column>{{'extended_dispatcher' | translate}}</td>
      <td class="item_row"
          td-data-table-column>{{(this.dispatchers ? this.dispatchers.length : '')}}</td>
      <td class="item_row"
          td-data-table-column>{{(this.dispatcherAmount ? this.dispatcherAmount : 0) | localizedCurrency}}</td>
    </tr>
    </tbody>
  </table>

  <mat-divider></mat-divider>

  <div class="full-width" flex="100">
    <button (click)="this.addExtendedDispatchStatus()" *ngIf="!this.extendedDispatcherSubId" class="push-top push-right" color="primary"
            flex mat-raised-button>
      {{'extended_dispatcher_buy_now'|translate|ucFirst}}</button>
  </div>
</div>

<div *ngIf="this.extendedDispatcherSubId" style="width:500px">
  <mat-card-title
    class="push-bottom">{{'cloud_connect_extended_dispatcher_cancel_title'|translate|ucFirst}}</mat-card-title>
  <mat-card-subtitle
    [innerHTML]="'cloud_connect_extended_dispatcher_cancel_subtitle'|translate|keepHtml"
    class="push-bottom"></mat-card-subtitle>
  <mat-divider></mat-divider>

  <div class="full-width" flex="100">
    <button (click)="this.removeExtendedDispatchStatus(this.extendedDispatcherSubId)" class="push-top push-right" color="primary" flex
            mat-raised-button>
      {{'remove_extended_dispatcher'|translate|ucFirst}}</button>
  </div>
</div>
