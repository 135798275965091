<div *ngIf="(media.registerQuery('gt-md') | async) && showExport" [className]="'mat-card-title filter'">
  <button (click)="doExport()" [className]="'mat-raised-button mat-primary tool export-button'" color="primary"
          mat-raised-button>
    {{'export'|translate|ucFirst}} {{exportType|translate|ucFirst}}
  </button>
</div>

<div [mediaClasses]="[]" class="filter-fields" layout-gt-sm="row" tdMediaToggle="gt-xs" *ngIf="showSearchFilter">
  <div flex-gt-sm="100">
    <div *ngIf="showExport" layout-gt-sm="row">
      <div flex-gt-sm="90">
        <td-search-box (searchDebounce)="onSearchTermChanged($event)" [alwaysVisible]="true"
                       [showUnderline]="true" class="push-left-sm push-right-sm filter-search"
                       placeholder="{{'search_here'|translate}}"></td-search-box>
      </div>
    </div>
    <div class="date-range-picker">
      <div *ngIf="showStatusFilter" layout-md="row" layout-md="100">
        <p class="label">{{'select_ride_status'|translate|ucFirst}}:</p>
        <mat-form-field class="push-left-sm" floatPlaceholder="never">
          <mat-select (selectionChange)="onStatusChanged($event)" [value]="defaultStatus">
            <mat-option [value]="'all'">{{'all_ride_statuses'|translate|ucFirst}}</mat-option>
            <mat-option *ngFor="let status of rideStatuses;" [value]="status">
              {{'ride_status_' + status | translate |ucFirst}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div layout-md="100" layout-md="row">
        <p class="label">{{'from'|translate|ucFirst}}:</p>
        <mat-form-field class="push-left-sm" floatPlaceholder="never">
          <input (dateInput)="onFromFilterChanged($event.value)" [matDatepicker]="fromPicker" [value]="data.filterDates.from"
                 matInput placeholder="{{'choose_a_date'|translate}}">
          <mat-datepicker-toggle [for]="fromPicker" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #fromPicker (change)="onFromFilterChanged($event)" disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
      <div layout-md="100" layout-md="row">
        <p class="label">{{'to'|translate|ucFirst}}:</p>
        <mat-form-field class="push-left-sm" floatPlaceholder="never">
          <input (dateInput)="onToFilterChanged($event.value)" [matDatepicker]="toPicker" [value]="data.filterDates.to"
                 matInput placeholder="{{'no_enddate_set'|translate|ucFirst}}">
          <mat-datepicker-toggle [for]="toPicker" matSuffix></mat-datepicker-toggle>
          <mat-datepicker #toPicker (change)="onToFilterChanged($event)" disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>

      <td-search-box (searchDebounce)="onSearchTermChanged($event)" *ngIf="!showExport"
                     [alwaysVisible]="true" [showUnderline]="true"
                     class="push-left-sm push-right-sm filter-search" placeholder="{{'search_here'|translate}}"></td-search-box>
    </div>
  </div>
</div>
