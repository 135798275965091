<mat-card [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <mat-card-content class="push-bottom-none">
    <ng-template tdLoading="location-policy">
      <form *ngIf="form" [formGroup]="form">
        <div layout="row"><h3 class="push-top-none">Personal data</h3></div>
        <div *ngIf="action !== 'add'" class="push-top-sm" layout="row">
          <mat-form-field flex>
            <input disabled matInput placeholder="{{'debtor_code'|translate|ucFirst}}" type="text"
                   value="{{debtor.code}}">
          </mat-form-field>
        </div>

        <div class="pad-bottom-sm" layout="row">
          <mat-form-field class="push-right" flex>
            <input formControlName="companyName" matInput placeholder="{{'debtor_company'|translate|ucFirst}}"
                   type="text" value="{{debtor.companyName}}">
            <mat-hint align="start">
              <span [hidden]="!formErrors.companyName" class="tc-red-600">
                <span>{{ formErrors.companyName }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
        </div>

        <div layout="row">
          <mat-form-field class="push-right" flex>
            <input formControlName="firstName" matInput placeholder="{{'first_name'|translate|ucFirst}}" type="text"
                   value="{{debtor.firstName}}">
            <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.firstName" class="tc-red-600">
                  <span>{{ formErrors.firstName }}</span>
                </span>
            </mat-hint>
          </mat-form-field>
          <mat-form-field class="" flex>
            <input formControlName="lastName" matInput placeholder="{{'last_name'|translate|ucFirst}}" type="text"
                   value="{{debtor.lastName}}">
            <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.lastName" class="tc-red-600">
                  <span>{{ formErrors.lastName }}</span>
                </span>
            </mat-hint>
          </mat-form-field>
        </div>
        <div layout="row">
          <mat-form-field class="" flex>
            <input formControlName="emailAddress" matInput placeholder="{{'email'|translate|ucFirst}}" type="text"
                   value="{{debtor.emailAddress}}">
            <mat-hint align="start">
              <span [hidden]="!formErrors.emailAddress" class="tc-red-600">
                <span>{{ formErrors.emailAddress }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
          <div class="phoneNumberField push-right {{(formErrors.phoneNumber ? 'phoneNumberInvalid' : '')}}" flex>
            <ngx-intl-tel-input
              [cssClass]="'phoneNumberInput'"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true"
              [maxLength]="15"
              [phoneValidation]="true"
              [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
              [searchCountryFlag]="true"
              [selectFirstCountry]="false"
              [selectedCountryISO]="this.company.country"
              flex
              formControlName="phoneNumber"
              name="phoneNumber">
            </ngx-intl-tel-input>

            <mat-hint align="start" class="warning">
                  <span [hidden]="!formErrors.phoneNumber" class="tc-red-600">
                    <span>{{ formErrors.phoneNumber }}</span>
                  </span>
            </mat-hint>
          </div>
        </div>
        <div class="pad-bottom-sm" layout="row">
          <mat-form-field class="" flex>
            <input formControlName="note" matInput placeholder="{{'note'|translate|ucFirst}}" type="text"
                   value="{{debtor.note}}">
            <mat-hint align="start">
              <span [hidden]="!formErrors.note" class="tc-red-600">
                <span>{{ formErrors.note }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
        </div>
        <ng-container *ngIf="debtor.id">
          <mat-divider></mat-divider>
          <div layout="row"><h3 class="push-top-md">Address</h3></div>
          <div class="row">
            {{addressString}}<br/>
            <span class="description">({{'edit_address_in_app'|translate|ucFirst}})</span>
          </div>
        </ng-container>
      </form>
    </ng-template>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <button (click)="save()"
            color="accent"
            flex
            mat-raised-button>{{'save_debtor_button'|translate|ucFirst}}</button>
    <button (click)="delete()"
            *ngIf="action !== 'add'"
            class="float-right"
            color="warn"
            flex
            mat-raised-button>{{'delete_debtor_button'|translate|ucFirst}}</button>
  </mat-card-actions>
</mat-card>
