import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-pricerule-passenger-multiplier-ratio',
  templateUrl: './pricerule-passenger-multiplier-ratio.component.html',
  styleUrls: ['./pricerule-passenger-multiplier-ratio.component.scss']
})
export class PricerulePassengerMultiplierRatioComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() ruleId: string;
  maxPassengers = 8;

  constructor() { }

  ngOnInit(): void {
  }

}
