<mat-card-content class="push-bottom-none">
  <ng-template tdLoading="passenger-upsert">
    <form *ngIf="form" [formGroup]="form">
      <div layout="row"><h3 class="push-top-none">Personal data</h3></div>
      <div layout="row">
        <mat-form-field class="push-right" flex>
          <input formControlName="fname" matInput placeholder="{{'first_name'|translate|ucFirst}}" type="text"
                 value="{{passenger.fname}}">
          <mat-hint align="start">
              <span [hidden]="!formErrors.fname" class="tc-red-600">
                <span>{{ formErrors.fname }}</span>
              </span>
          </mat-hint>
        </mat-form-field>
        <mat-form-field class="" flex>
          <input formControlName="lname" matInput placeholder="{{'last_name'|translate|ucFirst}}" type="text"
                 value="{{passenger.lname}}">
          <mat-hint align="start">
              <span [hidden]="!formErrors.lname" class="tc-red-600">
                <span>{{ formErrors.lname }}</span>
              </span>
          </mat-hint>
        </mat-form-field>
      </div>
      <div layout="row">
        <mat-form-field class="push-right" flex>
          <input formControlName="email" matInput placeholder="{{'email'|translate|ucFirst}}" type="text"
                 value="{{passenger.email}}">
          <mat-hint align="start">
              <span [hidden]="!formErrors.email" class="tc-red-600">
                <span>{{ formErrors.email }}</span>
              </span>
          </mat-hint>
        </mat-form-field>
        <div class="phoneNumberField push-right {{(formErrors.phoneNumber ? 'phoneNumberInvalid' : '')}}" flex>
          <ngx-intl-tel-input
            (keyup)="clearError($event, 'phoneNumber')"
            [cssClass]="'phoneNumberInput'"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [maxLength]="15"
            [phoneValidation]="true"
            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
            [searchCountryFlag]="true"
            [selectFirstCountry]="false"
            [selectedCountryISO]="this.company.country"
            flex
            formControlName="phoneNumber"
            name="phoneNumber">
          </ngx-intl-tel-input>
          <div class="phonenumber-warning warning">
                <span [hidden]="!formErrors['phoneNumber']" class="tc-red-600">
                  <span>{{ formErrors['phoneNumber'] }}</span>
                </span>
          </div>
        </div>
      </div>
    </form>
  </ng-template>
</mat-card-content>
<mat-divider></mat-divider>
<mat-card-actions>
  <button (click)="save()"
          [disabled]="!form.valid"
          color="accent"
          flex
          mat-raised-button>{{(passenger.id ? 'save' : 'create_new_contact')|translate|ucFirst}}</button>
  <button (click)="passenger.isDeleted ? activate() : delete()"
          *ngIf="action !== 'add'"
          color="warn"
          flex
          mat-raised-button>{{'delete_passenger_button'|translate|ucFirst}}</button>
</mat-card-actions>
