<section class="mat-typography">
  <mat-icon (click)="close()" class="dialog-top-right">close</mat-icon>

  <h1 *ngIf="rideId">{{ 'job_dispatch_options' | translate | replace : '{reference}' : (this.myData.reference ?
    this.myData.reference : '') | ucFirst}}</h1>
  <h1 *ngIf="!rideId">{{ 'job_new_dispatch_options' | translate | ucFirst}}</h1>
  <mat-card-subtitle>
    {{ 'job_dispatch_options_subtext' | translate | ucFirst }}
  </mat-card-subtitle>
</section>
<mat-divider></mat-divider>

<mat-dialog-content class="td-dialog-content pull-bottom">
  <td-search-box (searchDebounce)="search($event)"
                 [alwaysVisible]="true" class="push-left push-right pull-bottom searchDriver"
                 placeholder="{{'search_here'|translate}}"></td-search-box>

  <mat-select (selectionChange)="selectGroup($event)" [(value)]="selected"
              [style.width.px]="300"
              class="selectCompany row-select"
              id="activeCompany" required>
    <mat-select-trigger>
      <mat-icon class="push-right">{{ getSelectedIcon() }}</mat-icon>
      {{ getCompanyName() | translate | ucFirst}}
    </mat-select-trigger>
    <mat-option value="personal">
      <mat-icon>person</mat-icon>
      {{'personal'|translate|ucFirst}}
    </mat-option>
    <mat-option *ngFor="let company of myCompanies" [value]="company.id">
      <mat-icon>business</mat-icon>
      {{ company.name | ucFirst }}
    </mat-option>
  </mat-select>

  <mat-divider class="row push-bottom-sm push-top-sm"></mat-divider>
  <div class="row">
    <mat-tab-group (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedTabIndex">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="icon-right">access_time</mat-icon>
          {{'all_drivers'|translate|ucFirst}} ({{this.sortedAllMembers.length}})
        </ng-template>
        <ng-template tdLoading="drivers.list4">
          <table class="mat-clickable" td-data-table>
            <thead>
            <tr td-data-table-column-row>
              <th (sortChange)="sort($event)"
                  *ngFor="let c of columns"
                  [active]="(sortBy == c.name)"
                  [name]="c.name"
                  [numeric]="c.numeric"
                  [sortOrder]="( sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
                  [sortable]="c.sortable"
                  class="{{c.maxSize}} {{c.maxWidth}} {{c.fixedSize}}"
                  td-data-table-column>
                {{((c.name !== 'tools' ? c.label : '')|translate|ucFirst)}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr (click)="selectDriver(item.id,item.fname+' '+item.lname)" *ngFor="let item of sortedAllMembers"
                class="pointer {{(this.myData.driverId == item.id ? 'activeRow' : '')}}"
                td-data-table-row>
              <td *ngFor="let c of columns" [numeric]="item.numeric" class="{{(c.name=='tools'?'text-right':'')}} {{c.maxSize}}"
                  td-data-table-cell>
                  <span [ngSwitch]="c.name">
                    <span *ngSwitchCase="'tools'">
                      <button mat-menu-item>
                        <mat-icon class="icon-right">send</mat-icon>
                        <span>{{'assign_to_driver' | translate | ucFirst}}</span>
                      </button>
                    </span>
                    <span *ngSwitchCase="'status'">
                      <span>{{item[c.name] | translate | ucFirst}}</span>
                    </span>
                    <span *ngSwitchCase="'name'">
                      <span>{{item[c.name] | translate | ucFirst}}</span>
                    </span>
                    <span *ngSwitchCase="'distance'">
                      <span
                        *ngIf="item.ActiveVehicle.status.state !== 'offline'">{{item[c.name]}} {{'km'|distanceFormat:company:false}}</span>
                    </span>
                    <span *ngSwitchCase="'radio'">
                      <span><input [checked]="(this.myData.driverId == item.id)" name="dispatchToDriver"
                                   type="radio"></span>
                    </span>
                    <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span></span>
                  </span>
              </td>
            </tr>
            </tbody>
          </table>
          <td-paging-bar #allPagingBar (change)="page($event)" [pageSize]="pageSize"
                         [total]="this.sortedAllMembers.length">
            <span hide-xs td-paging-bar-label>{{'rows_p_page'|translate}}:</span>
            {{allPagingBar.range}} <span hide-xs>of {{allPagingBar.total}}</span>
          </td-paging-bar>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="icon-right">access_time</mat-icon>
          {{'available'|translate|ucFirst}} ({{this.sortedMembers.length}})
        </ng-template>
        <ng-template tdLoading="drivers.list">
          <table class="mat-clickable" td-data-table>
            <thead>
            <tr td-data-table-column-row>
              <th (sortChange)="sort($event)"
                  *ngFor="let c of columns"
                  [active]="(sortBy == c.name)"
                  [name]="c.name"
                  [numeric]="c.numeric"
                  [sortOrder]="( sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
                  [sortable]="c.sortable"
                  class="{{c.maxSize}} {{c.maxWidth}} {{c.fixedSize}}"
                  td-data-table-column>
                {{((c.name !== 'tools' ? c.label : '')|translate|ucFirst)}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr (click)="selectDriver(item.id,item.fname+' '+item.lname)" *ngFor="let item of sortedMembers"
                class="pointer {{(this.myData.driverId == item.id ? 'activeRow' : '')}}"
                td-data-table-row>
              <td *ngFor="let c of columns" [numeric]="item.numeric" class="{{(c.name=='tools'?'text-right':'')}} {{c.maxSize}}"
                  td-data-table-cell>
          <span [ngSwitch]="c.name">
            <span *ngSwitchCase="'tools'">
              <button mat-menu-item>
                <mat-icon class="icon-right">send</mat-icon>
                <span>{{'assign_to_driver' | translate | ucFirst}}</span>
              </button>
            </span>
            <span *ngSwitchCase="'status'">
              <span>{{item[c.name] | translate | ucFirst}}</span>
            </span>
            <span *ngSwitchCase="'name'">
              <span>{{item[c.name] | translate | ucFirst}}</span>
            </span>
            <span *ngSwitchCase="'distance'">
              <span>{{item[c.name]}} {{'km'|distanceFormat:company:false}}</span>
            </span>
            <span *ngSwitchCase="'radio'">
              <span><input [checked]="(this.myData.driverId == item.id)" name="dispatchToDriver"
                           type="radio"></span>
            </span>
            <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span></span>
          </span>
              </td>
            </tr>
            </tbody>
          </table>
          <td-paging-bar #allPagingBar (change)="page($event)" [pageSize]="pageSize"
                         [total]="this.sortedMembers.length">
            <span hide-xs td-paging-bar-label>{{'rows_p_page'|translate}}:</span>
            {{allPagingBar.range}} <span hide-xs>of {{allPagingBar.total}}</span>
          </td-paging-bar>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="icon-right">access_time</mat-icon>
          {{'online'|translate|ucFirst}} ({{sortedMembersOnline.length}})
        </ng-template>
        <ng-template tdLoading="drivers.list2">
          <table class="mat-clickable" td-data-table>
            <thead>
            <tr td-data-table-column-row>
              <th (sortChange)="sort($event)"
                  *ngFor="let c of columns"
                  [active]="(sortBy == c.name)"
                  [name]="c.name"
                  [numeric]="c.numeric"
                  [sortOrder]="( sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
                  [sortable]="c.sortable"
                  class="{{c.maxSize}} {{c.maxWidth}} {{c.fixedSize}}"
                  td-data-table-column>
                {{((c.name !== 'tools' ? c.label : '')|translate|ucFirst)}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr (click)="selectDriver(item.id,item.fname+' '+item.lname)" *ngFor="let item of sortedMembersOnline"
                class="pointer {{(this.myData.driverId == item.id ? 'activeRow' : '')}}"
                td-data-table-row>
              <td *ngFor="let c of columns" [numeric]="item.numeric" class="{{(c.name=='tools'?'text-right':'')}} {{c.maxSize}}"
                  td-data-table-cell>
                <span [ngSwitch]="c.name">
                  <span *ngSwitchCase="'tools'">
                    <button mat-menu-item>
                      <mat-icon class="icon-right">send</mat-icon>
                      <span>{{'assign_to_driver' | translate | ucFirst}}</span>
                    </button>
                  </span>
                  <span *ngSwitchCase="'status'">
                    <span>{{item[c.name] | translate | ucFirst}}</span>
                  </span>
                  <span *ngSwitchCase="'name'">
                    <span>{{item[c.name] | translate | ucFirst}}</span>
                  </span>
                  <span *ngSwitchCase="'distance'">
                    <span>{{item[c.name]}} {{'km'|distanceFormat:company:false}}</span>
                  </span>
                  <span *ngSwitchCase="'radio'">
                    <span><input [checked]="(this.myData.driverId == item.id)" name="dispatchToDriver"
                                 type="radio"></span>
                  </span>
                  <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span></span>
                </span>
              </td>
            </tr>
            </tbody>
          </table>
          <td-paging-bar #allPagingBar (change)="page($event)" [pageSize]="pageSize"
                         [total]="sortedMembersOnline.length">
            <span hide-xs td-paging-bar-label>{{'rows_p_page'|translate}}:</span>
            {{allPagingBar.range}} <span hide-xs>of {{allPagingBar.total}}</span>
          </td-paging-bar>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="icon-right">access_time</mat-icon>
          {{'offline'|translate|ucFirst}} ({{this.sortedOfflineMembers.length}})
        </ng-template>
        <ng-template tdLoading="drivers.list3">
          <table class="mat-clickable" td-data-table>
            <thead>
            <tr td-data-table-column-row>
              <th (sortChange)="sort($event)"
                  *ngFor="let c of columns"
                  [active]="(sortBy == c.name)"
                  [name]="c.name"
                  [numeric]="c.numeric"
                  [sortOrder]="( sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
                  [sortable]="c.sortable"
                  class="{{c.maxSize}} {{c.maxWidth}} {{c.fixedSize}}"
                  td-data-table-column>
                {{((c.name !== 'tools' ? c.label : '')|translate|ucFirst)}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr (click)="selectDriver(item.id,item.fname+' '+item.lname)" *ngFor="let item of sortedOfflineMembers"
                class="pointer {{(this.myData.driverId == item.id ? 'activeRow' : '')}}"
                td-data-table-row>
              <td *ngFor="let c of columns" [numeric]="item.numeric" class="{{(c.name=='tools'?'text-right':'')}} {{c.maxSize}}"
                  td-data-table-cell>
              <span [ngSwitch]="c.name">
                <span *ngSwitchCase="'status'">
                  <span>{{item[c.name] | translate | ucFirst}}</span>
                </span>
                <span *ngSwitchCase="'name'">
                  <span>{{item[c.name] | translate | ucFirst}}</span>
                </span>
                <span *ngSwitchCase="'distance'">
                  <span
                    *ngIf="item.ActiveVehicle.status.state !== 'offline'">{{item[c.name]}} {{'km'|distanceFormat:company:false}}</span>
                </span>
                <span *ngSwitchCase="'radio'">
                  <span><input [checked]="(this.myData.driverId == item.id)" name="dispatchToDriver"
                               type="radio"></span>
                </span>
                <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span></span>
              </span>
              </td>
            </tr>
            </tbody>
          </table>
          <td-paging-bar #allPagingBar [pageSize]="pageSize" [total]="sortedOfflineMembers.length">
            <span hide-xs td-paging-bar-label>{{'rows_p_page'|translate}}:</span>
            {{allPagingBar.range}} <span hide-xs>of {{allPagingBar.total}}</span>
          </td-paging-bar>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div class="td-dialog-actions push-bottom push-top row">
    <span class="td-dialog-spacer"></span>
    <button (click)="(this.rideId ?
    preAssignDriver() : delayedPreAssignDriver())" [disabled]="!this.myData.driverId" color="accent" mat-raised-button>
      {{ 'dispatch_to_selected_driver' | translate | replace : '{driver}' : (this.myData.selectedDriverName ?
      this.myData.selectedDriverName : translations['driver']) | ucFirst}}
    </button>
  </div>
</mat-dialog-content>
