import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TdMediaService} from '@covalent/core/media';
import {ActivatedRoute, Router} from '@angular/router';
import {TdLoadingService} from '@covalent/core/loading';
import {TdDialogService} from '@covalent/core/dialogs';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../../../services/navigation.service';
import {Title} from '@angular/platform-browser';
import {CompanyWebUserService} from '../../../../services/companyWebUser.service';
import {CompanyWebUser} from '../../../../models/company-web-user';
import {UserService} from '../../../../services/user.service';
import {Company} from '../../../../models/company';
import {CountryISO, SearchCountryField,} from 'ngx-intl-tel-input';
import {ValidateAllFormFields} from '../../../../functions/validateAllFormFields';
import {environment} from '../../../../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';
import {DriverService} from '../../../../services/driver.service';
import {Driver} from '../../../../models/driver';
import {ucFirst} from '../../../../pipes/uc-first.pipe';
import {CompanyInvitationService} from '../../../../services/company-invitation.service';
import {UtilityService} from '../../../../services/utility.service';

@Component({
  selector: 'app-company-web-user-upsert',
  templateUrl: './webuser-upsert.component.html',
  styleUrls: ['./webuser-upsert.component.scss']
})
export class WebUserUpsertComponent extends ValidateAllFormFields implements OnInit, OnDestroy, AfterViewInit {
  id: string;
  action: string;
  company: Company;
  currentDriver: Driver;
  currentOpenInvite: any;
  companyWebUser: CompanyWebUser = new CompanyWebUser();
  SearchCountryField = SearchCountryField;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom
  ];
  companyId: string;
  isDriver: boolean;
  translations: string[];
  form: UntypedFormGroup;
  userTypes = ['operator', 'admin'];
  driverStates: object[] = [
    {
      'label': 'Accepted',
      'value': 'accepted'
    },
    {
      'label': 'Blocked',
      'value': 'blocked'
    }
  ];
  sub: any;
  parentSub: any;
  formErrors: any = {};
  validationMessages = {
    'fname': {
      'fieldName': 'first_name',
      'required': 'form_validation_required',
    },
    'lname': {
      'fieldName': 'last_name',
      'required': 'form_validation_required',
    },
    'emailAddress': {
      'fieldName': 'email',
      'required': 'form_validation_required',
    },
    'phoneNumber': {
      'fieldName': 'phonenumber',
      'required': 'form_validation_required',
      'validatePhoneNumber': 'phonenumber_pattern_invalid',
    },
    'type': {
      'fieldName': 'coupon_code',
      'required': 'form_validation_required',
    }
  };

  constructor(public snackBar: MatSnackBar,
              public media: TdMediaService,
              private _fb: UntypedFormBuilder,
              private _route: ActivatedRoute,
              private _vault: CoolLocalStorage,
              private _router: Router,
              private _loadingService: TdLoadingService,
              private _dialogService: TdDialogService,
              private _operatorService: CompanyWebUserService,
              private _driverService: DriverService,
              private _companyInvitationService: CompanyInvitationService,
              private _userService: UserService,
              private _translateService: TranslateService,
              private _navigationService: NavigationService,
              private _titleService: Title) {
    super();
    this.loadErrorTranslations(_translateService);

    const {company} = this._route.parent.snapshot.data;
    UtilityService.setBrowserTimeStamp(this.company);
    this.company = company;

    const self = this;
    _translateService.get(['operator_management_title', 'mandatory_field', 'ok', 'edit_operator',
      'mandatory_email', 'phonenumber_pattern_invalid', 'mandatory_phonenumber_minlength', 'operator_duplicate_operator',
      'operator_added', 'operator_added_title', 'add_operator', 'operator_duplicate_driver', 'member_updated'])
      .subscribe((translations: any) => {
        self.translations = translations;

        this._titleService.setTitle(translations['add_operator']);
        this._navigationService.setBodyTitle(translations['add_operator']);

        this._navigationService.setSplitLayoutButtons([
          {
            'icon': 'list',
            'tooltip': this.translations['operator_management_title'],
            'link': `/groups/${this.company.id}/drivers/`
          }
        ]);
        /**
         * Subscribe to the route params
         */
        self.sub = self._route.params.subscribe(params => {
          self.id = params['id'];
          self.action = params.action.substring(0, 1).toUpperCase() + params.action.substring(1, params.action.length);

          self._titleService.setTitle(self.translations['edit_operator']);
          self._navigationService.setBodyTitle(self.translations['edit_operator']);

          if (self.id) {
            self.loadData();
          }
        });
      });


    this.parentSub = this._route.parent.params.subscribe(params => {
      this.companyId = params['id'];
    });
  }

  ngOnInit() {
    if (!this.id) {
      this._loadingService.register('driver');
      this.initForm();
    }
  }

  initForm() {
    this.form = this._fb.group({
      fname: [{value: this.companyWebUser.fname, disabled: (this.action === 'Edit')}, [Validators.required]],
      lname: [{value: this.companyWebUser.lname, disabled: (this.action === 'Edit')}, [Validators.required]],
      emailAddress: [{value: this.companyWebUser.emailAddress, disabled: (this.action === 'Edit')},
        {
          validators: [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
          updateOn: 'blur',
          disabled: true
        }],
      phoneNumber: [{value: this.companyWebUser.phoneNumber, disabled: (this.action === 'Edit')}, {
        validators: [Validators.required, Validators.minLength(8)],
        updateOn: 'blur'
      }],
      status: [this.companyWebUser.status, []],
      invite: [this.isDriver, []],
      type: [{value: (this.companyWebUser.type !== 'owner' ? this.companyWebUser.type : ucFirst(this.companyWebUser.type)), disabled: (this.companyWebUser.type === 'owner')}, [Validators.required]],
    });
  }

  setPhoneNumberPaste() {
    const self = this;
    const phone = document.getElementById('phone');
    if (!phone) {
      setTimeout(function () {
        self.setPhoneNumberPaste();
      }, 500);
    } else {
      phone.onpaste = this.cleanPhoneNumber;
    }
  }

  cleanPhoneNumberError(e) {
    e.preventDefault();
    this.formErrors['phoneNumber'] = null;
  }

  cleanPhoneNumber(e) {
    e.preventDefault();
    let pastedText = '';

    if (e.clipboardData && e.clipboardData.getData) {
      pastedText = e.clipboardData.getData('text/plain');
    } else {
      // @ts-ignore
      if (window.clipboardData && window.clipboardData.getData) {// IE
        // @ts-ignore
        pastedText = window.clipboardData.getData('Text');
      }
    }
    // @ts-ignore
    this.value = pastedText.replace(/\D/g, '');
  };

  ngAfterViewInit() {
    this.media.broadcast();
    // this.setPhoneNumberPaste();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.parentSub.unsubscribe();
  }

  numericOnly(event) {
    const patt = /^([\+0-9])$/;
    const result = patt.test(event.key);
    return result;
  }

  save(): void {
    const data = this.form.value;
    const operator = this.companyWebUser;
    const patchOperator = {};
    operator.companyId = this.companyId;
    const self = this;
    const operatorId = operator.id;
    delete operator.id;

    if (data.phoneNumber) {
      // this.form.controls['phoneNumber'].setValue(data.phoneNumber.e164Number);
      this.companyWebUser.phoneNumber = data.phoneNumber.e164Number.replace('/\s/gi', '');
    }

    this.validateAllFormFields(this.form);
    if (!this.form.valid) {
      return;
    }

    this._loadingService.register();
    delete operator.modified;
    /**
     * Call the API
     */
    if (this.action.toLowerCase() === 'add') {
      for (const key in data) {
        operator[key] = data[key];
      }
      if (data.phoneNumber && data.phoneNumber.e164Number) {
        // this.form.controls['phoneNumber'].setValue(data.phoneNumber.e164Number);
        operator.phoneNumber = data.phoneNumber.e164Number;
      }
      operator.status = 'accepted';

      const operatorData: any = operator;
      const user: any = this._vault.getObject(`${environment.vaultPrefix}.user`);
      operatorData.invitee = {
        name: `${user.firstName} ${user.lastName}`,
        email: `${user.email}`,
        phoneNumber: `${user.phoneNumber}`
      };

      this._operatorService.insert(operatorData, 'company').subscribe(() => {
        setTimeout(function () {
          self._loadingService.resolve()
        }, 500);
        setTimeout(function () {
          self._dialogService.openAlert({
            message: self.translations['operator_added'],
            disableClose: true,
            title: self.translations['operator_added_title'],
            closeButton: self.translations['ok']
          }).afterClosed().subscribe(() => {
            self._router.navigate([`/${(self.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${self.companyId}/drivers`]);
          });
        }, 500);
      }, error => {
        if (error.error.error.name === 'DUPLICATE_DRIVER') {
          self._dialogService.openAlert({
            message: self.translations['operator_duplicate_driver'],
            disableClose: true,
            closeButton: self.translations['ok']
          });
        } else if (error.error.error.name === 'DUPLICATE_CompanyWebUser') {
          self._dialogService.openAlert({
            message: self.translations['operator_duplicate_operator'],
            disableClose: true,
            closeButton: self.translations['ok']
          });
        } else {
          self._dialogService.openAlert({
            message: error.message,
            disableClose: true,
            closeButton: self.translations['ok']
          });
        }
        self._loadingService.resolve();
      });
    } else {
      for (const key in data) {
        patchOperator[key] = data[key];
      }

      delete patchOperator['emailAddress'];
      delete patchOperator['phoneNumber'];

      self._operatorService.update(this.id, patchOperator, 'company').subscribe(result => {
        setTimeout(function () {
          self._loadingService.resolve()
        }, 500);
        setTimeout(function () {
          self.snackBar.open(self.translations['member_updated'], self.translations['ok'], {
            duration: 3000
          });
        }, 500);
      }, error => {
        self.snackBar.open(error.message, self.translations['ok'], {
          duration: 3000
        });
        self._loadingService.resolve()
      });
    }

    if (this.form.controls['invite'].value && !self.isDriver) {
      const fname = this.form.controls['fname'].value;
      const lname = this.form.controls['lname'].value;
      const newDriver: Driver = {
        fname: (!fname ? lname : fname),
        lname: (fname ? lname : '-'),
        phoneNumber: (this.form.controls['phoneNumber'].value.e164Number ? this.form.controls['phoneNumber'].value.e164Number : this.form.controls['phoneNumber'].value),
        emailAddress: this.form.controls['emailAddress'].value,
        companyId: this.companyId,
      };

      const invitation: any = JSON.parse(JSON.stringify(newDriver));
      invitation.admin = false;
      invitation.status = 'pending';
      invitation.companyId = this.companyId;
      invitation.driverType = invitation.type;
      invitation.admin = true;
      invitation.isOwner = (this.companyWebUser.type === 'owner');

      const user: any = this._vault.getObject(`${environment.vaultPrefix}.user`);
      invitation.invitee = {
        name: `${user.firstName} ${user.lastName}`,
        email: `${user.email}`,
        phoneNumber: `${user.phoneNumber}`
      };
      self._companyInvitationService.sendInvite([invitation], 'company').subscribe((result) => {
        self.currentOpenInvite = result;
        self.isDriver = true;
      });
    } else if (!this.form.controls['invite'].value && self.isDriver) {
      if (self.currentOpenInvite) {
        self._companyInvitationService.delete(self.currentOpenInvite.id, 'company')
          .subscribe(() => {
            self.currentOpenInvite = null;
            self.isDriver = false;
          });
      } else {
        self._driverService.update(self.currentDriver.id, {'status': 'removed'}, 'company')
          .subscribe(() => {
            self.currentDriver = null;
            self.isDriver = false;
          });
      }
    }
  }

  loadData(): void {
    const self = this;
    self._operatorService.get(this.id, {}, 'company').subscribe((webUser: CompanyWebUser) => {
      self.companyWebUser = webUser;
      self._driverService.getAll({
        'where': {
          'companyId': self.company.id,
          'phoneNumber': encodeURIComponent(webUser.phoneNumber),
          'status': 'accepted'
        }
      }, 'company')
        .subscribe((drivers: any[]) => {
          self.isDriver = !!(drivers && drivers.length > 0);
          self.currentDriver = drivers[0];

          self.initForm();
          if (!self.isDriver) {
            self._companyInvitationService.getAll({
              'where': {
                'companyId': self.company.id,
                'phoneNumber': encodeURIComponent(webUser.phoneNumber),
                'status': 'created'
              }
            }, 'company').subscribe((invites: any[]) => {
              self.isDriver = !!(invites && invites.length > 0);
              self.currentOpenInvite = invites[0];
              self.form.controls['invite'].setValue(self.isDriver);
            });
          }
          setTimeout(function () {
            self._loadingService.resolve('driver');
          }, 500);
        });
    }, (error) => {
      console.error(error);
      this._loadingService.resolve('driver');
    });
  }
}
