import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Company} from '../../../../models/company';

@Component({
  selector: 'app-main-dashboard',
  templateUrl: './main-dashboard.component.html',
  styleUrls: ['./main-dashboard.component.scss']
})
export class MainDashboardComponent implements OnInit {
  company: Company;

  constructor(
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    const {company} = this.route.parent.snapshot.data;
    this.company = company;
  }
}
