import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {PaymentMeta} from '../../../../../models/payment-meta';
import {PriceMeta} from '../../../../../models/price-meta';
import {Ride} from '../../../../../models/ride';

function pad(num, size) {
  let s = num + '';
  while (s.length < size) {
    s = '0' + s;
  }
  return s;
}

@Component({
  selector: 'app-ride-payment-data',
  templateUrl: './ride-payment-data.component.html',
  styleUrls: ['./ride-payment-data.component.scss']
})
export class RidePaymentDataComponent implements OnInit, OnChanges {
  @Input() paymentMeta: PaymentMeta;
  @Input() priceMeta: PriceMeta;
  @Input() ride: Ride;
  bookedFormat: string;
  hourlyRate: any;

  constructor() {
  }

  ngOnInit() {
    if (this.priceMeta) {
      this.bookedFormat = `${pad((this.priceMeta.bookedDuration / 3600), 2)}:${pad((this.priceMeta.bookedDuration % 3600), 2)}`;
      this.hourlyRate = {
        total: (this.priceMeta.hourlyRate),
        currency: this.priceMeta.currency,
      }
    }
  }

  ngOnChanges() {
    if (this.priceMeta) {
      this.bookedFormat = `${pad((this.priceMeta.bookedDuration / 3600), 2)}:${pad((this.priceMeta.bookedDuration % 3600), 2)}`;

      this.hourlyRate = {
        total: (this.priceMeta.hourlyRate),
        currency: this.priceMeta.currency,
      }
    }
  }
}
