import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-youtube-video',
  templateUrl: './youtube-video.component.html',
  styleUrls: ['./youtube-video.component.scss']
})
export class YoutubeVideoComponent implements OnInit {
  url: SafeUrl;
  videoId: string;

  constructor(
    private _sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<YoutubeVideoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.videoId = data.videoId;
  }

  ngOnInit(): void {
  }

  getYoutubeUrl() {
    return `https://www.youtube.com/embed/${this.videoId}`;
  }
}
