import {Injectable} from '@angular/core';
import {GlobalService} from './global.service';
import {HttpClient} from '@angular/common/http';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from './utility.service';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PromotionService extends GlobalService {
  constructor(_http: HttpClient, _vault: CoolLocalStorage, _utilityService: UtilityService) {
    super(_http, _vault, _utilityService);
    this.setModel('promotions');
  }

  public upload(id: string, object: any, context?: string) {
    const uploadURL = `${environment.apiBaseUrl}/${this.model}/${id}/upload`;
    return this._http.post<any>(uploadURL, object, {headers: this.getHeaders(context)});
  }

  public sendPushNotification(id: string, object: any, context?: string) {
    const sendNotificationUrl = `${environment.apiBaseUrl}/${this.model}/${id}/sendNotification`;
    return this._http.post<any>(sendNotificationUrl, object, {headers: this.getHeaders(context)});
  }
}
