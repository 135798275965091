<div tdMediaToggle="gt-xs">
  <mat-card-content class="push-bottom-none">
    <ng-template tdLoading="debtor-upsert">
      <form *ngIf="form" [formGroup]="form">
        <div layout="row"><h3 class="push-top-none">{{'account'| translate | ucFirst}}</h3></div>
        <div *ngIf="action !== 'add'" class="push-top-sm" layout="row">
          <mat-form-field flex>
            <input disabled matInput placeholder="{{'debtor_code'|translate|ucFirst}}" type="text"
                   value="{{debtor.code}}">
          </mat-form-field>
        </div>

        <div layout="row">
          <mat-form-field class="push-right" flex>
            <input (keyup)="clearError($event, 'companyName')" formControlName="companyName" matInput
                   placeholder="{{'company_name'|translate|ucFirst}}" type="text"
                   value="{{debtor.companyName}}">
            <mat-hint align="start">
              <span [hidden]="!formErrors.companyName" class="tc-red-600">
                <span>{{ formErrors.companyName }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
        </div>
        <div *ngIf="this.action!=='add'" layout="row">
          <mat-form-field class="" flex>
            <mat-label>{{'note'|translate|ucFirst}}</mat-label>
            <textarea class="note-height" formControlName="note" matInput>{{debtor.note}}</textarea>
            <mat-hint align="start">
                <span [hidden]="!formErrors.note" class="tc-red-600">
                  <span>{{ formErrors.note }}</span>
                </span>
            </mat-hint>
          </mat-form-field>
        </div>

        <div layout="row"><h3>{{'address'| translate | ucFirst}}</h3></div>
        <div layout="row">
          <mat-form-field class="push-right" flex formGroupName="address">
            <input formControlName="streetName" matInput placeholder="{{'street_name'|translate|ucFirst}}" type="text"
                   value="{{debtor.address ? debtor.address.streetName : ''}}">
            <mat-hint align="start">
              <span [hidden]="!formErrors.streetName" class="tc-red-600">
                <span>{{ formErrors.streetName }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
          <mat-form-field class="" flex formGroupName="address">
            <input formControlName="houseNumber" matInput placeholder="{{'house_number'|translate|ucFirst}}" type="text"
                   value="{{debtor.address ? debtor.address.houseNumber : ''}}">
            <mat-hint align="start">
              <span [hidden]="!formErrors.houseNumber" class="tc-red-600">
                <span>{{ formErrors.houseNumber }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
        </div>
        <div layout="row">
          <mat-form-field class="push-right" flex formGroupName="address">
            <input formControlName="city" matInput placeholder="{{'city'|translate|ucFirst}}" type="text"
                   value="{{debtor.address ? debtor.address.city : ''}}">
            <mat-hint align="start">
              <span [hidden]="!formErrors.city" class="tc-red-600">
                <span>{{ formErrors.city }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
          <mat-form-field class="" flex formGroupName="address">
            <input formControlName="postalCode" matInput placeholder="{{'postal_code'|translate|ucFirst}}" type="text"
                   value="{{debtor.address ? debtor.address.postalCode : ''}}">
            <mat-hint align="start">
              <span [hidden]="!formErrors.postalCode" class="tc-red-600">
                <span>{{ formErrors.postalCode }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
        </div>

        <mat-divider *ngIf="this.action !== 'edit'"></mat-divider>

        <div *ngIf="this.action !== 'edit'" class="push-top" layout="row"><h3
          class="push-top-none">{{'contact_type_owner'| translate | ucFirst}}</h3></div>

        <div *ngIf="this.action !== 'edit'" layout="row">
          <mat-icon *ngIf="!form.controls['createNewContact'].value" class="search-driver">search</mat-icon>
          <mat-form-field *ngIf="!form.controls['createNewContact'].value" flex="100">
            <input #autocomplete [formGroup]="form"
                   [matAutocomplete]="auto"
                   autocomplete="new-name"
                   formControlName="contact"
                   id="contactName"
                   matInput
                   placeholder="{{ 'search' | translate | ucFirst }}"
                   type="text">

            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autocomplete="new-name">
              <mat-option *ngFor="let option of filteredContacts" [value]="option">
                {{option.fname}} {{option.lname}} - {{option.email}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>

        <div *ngIf="this.action !== 'edit'" class="push-bottom" layout="row">
          <mat-checkbox formControlName="createNewContact" (change)="updateValidators()">{{'create_new_contact'|translate|ucFirst}}</mat-checkbox>
        </div>

        <div *ngIf="form.controls['createNewContact'].value">
          <div layout="row">
            <mat-form-field class="push-right" flex>
              <input formControlName="firstName" matInput placeholder="{{'first_name'|translate|ucFirst}}" type="text"
                     value="{{debtor.firstName}}">
              <mat-hint align="start">
                <span [hidden]="!formErrors.firstName" class="tc-red-600">
                  <span>{{ formErrors.firstName }}</span>
                </span>
              </mat-hint>
            </mat-form-field>
            <mat-form-field class="" flex>
              <input formControlName="lastName" matInput placeholder="{{'last_name'|translate|ucFirst}}" type="text"
                     value="{{debtor.lastName}}">
              <mat-hint align="start">
                <span [hidden]="!formErrors.lastName" class="tc-red-600">
                  <span>{{ formErrors.lastName }}</span>
                </span>
              </mat-hint>
            </mat-form-field>
          </div>
          <div layout="row">
            <mat-form-field class="push-right" flex>
              <input formControlName="emailAddress" matInput placeholder="{{'email'|translate|ucFirst}}" type="text"
                     value="{{debtor.emailAddress}}">
              <mat-hint align="start">
                <span [hidden]="!formErrors.emailAddress" class="tc-red-600">
                  <span>{{ formErrors.emailAddress }}</span>
                </span>
              </mat-hint>
            </mat-form-field>
            <div class="phoneNumberField push-right {{(formErrors.phoneNumber ? 'phoneNumberInvalid' : '')}}" flex>
              <ngx-intl-tel-input
                [cssClass]="'phoneNumberInput'"
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true"
                [maxLength]="15"
                [phoneValidation]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [searchCountryFlag]="true"
                [selectFirstCountry]="false"
                [selectedCountryISO]="(this.company.country === 'EN'? 'GB' : this.company.country)"
                flex
                formControlName="phoneNumber"
                name="phoneNumber">
              </ngx-intl-tel-input>

              <mat-hint align="start" class="warning">
                    <span [hidden]="!formErrors.phoneNumber" class="tc-red-600">
                      <span>{{ formErrors.phoneNumber }}</span>
                    </span>
              </mat-hint>
            </div>
          </div>
        </div>
      </form>
    </ng-template>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions *ngIf="form">
    <button (click)="save()"
            [disabled]="form.invalid"
            color="accent"
            flex
            mat-raised-button>{{'save_debtor_button'|translate|ucFirst}}</button>
    <button (click)="deleteDebtor()"
            *ngIf="action !== 'add'"
            class="float-right"
            color="warn"
            flex
            mat-raised-button>{{'delete_debtor_button'|translate|ucFirst}}</button>
  </mat-card-actions>
</div>
