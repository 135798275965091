import {Injectable} from '@angular/core';
import {GlobalService} from './global.service';
import {HttpClient} from '@angular/common/http';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from './utility.service';
import {environment} from '../../environments/environment';
import {EmptyObservable} from 'rxjs/observable/EmptyObservable';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyWebUserService extends GlobalService {
  constructor(_http: HttpClient, _vault: CoolLocalStorage, _utilityService: UtilityService) {
    super(_http, _vault, _utilityService);
    this.setModel('CompanyWebUsers');
  }

  getCompanies(): Observable<any> {
    const user: any = this._vault.getObject(`${environment.vaultPrefix}.user`);
    if (!user) {
      return new EmptyObservable();
    }

    const filter: object = {
      'where': {
        'userId': user.id,
        'companyId': {
          'exists': true
        }
      },
      'include': {
        'relation': 'Company',
        'scope': {
          'where': {
            'type': 'business'
          },
          'include': 'ActiveContract',
        }
      },
      'order': 'name ASC'
    };

    const filterString = JSON.stringify(this._utilityService.restrictCompanyFilter(filter, true));
    return this._http.get(`${environment.apiBaseUrl}/${this.model}/?filter=${filterString}`, {headers: this.getHeaders()});
  }

  updateOwnerShip(companyId: string, companyWebUserId: string): Observable<any> {
    return this._http.post(`${environment.apiBaseUrl}/${this.model}/${companyWebUserId}/updateOwnership`, {companyId}, {headers: this.getHeaders()});
  }
}
