<mat-card [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <ng-template tdLoading="discount">

    <mat-card-title *ngIf="form" class="text-truncate">
      {{ (form.value.name || 'New special rate') | ucFirst }}
    </mat-card-title>
    <mat-card-subtitle>
      {{ 'special_rate_subtitle' | translate | ucFirst }}
    </mat-card-subtitle>

    <mat-divider></mat-divider>

    <mat-card-content class="push-bottom-none">

      <mat-card-subtitle style="padding-left: 0">
        {{ 'special_rates_details_subtitle' | translate | ucFirst }}
      </mat-card-subtitle>

      <form *ngIf="form">
        <div [formGroup]="form">
          <mat-form-field class="push-right" flex>
            <input #name formControlName="name" matInput placeholder="{{ 'name' | translate | ucFirst }}" type="text">
          </mat-form-field>

          <mat-form-field class="type-input push-right">
            <input #priority autocomplete="off" formControlName="priority"
                   matInput
                   min="1" placeholder="{{ 'priority' | translate | ucFirst }}" type="number">
          </mat-form-field>

          <mat-form-field class="type-input push-right">
            <mat-select
              flex="33"
              formControlName="type"
              placeholder="{{ 'type' | translate | ucFirst }}"
              required>
              <mat-option *ngFor="let type of types" [value]="type">
                {{ type | ucFirst }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field class="value-input push-right">
            <span *ngIf="form.value.type === 'fixed'"
                  class="currency">
            </span>
            <input #value (blur)="valOrZero($event.target)" autocomplete="off"
                   formControlName="value"
                   matInput
                   placeholder="{{ 'amount' | translate | ucFirst }}"
                   step='0.01'
                   style="width: 80%"
                   type="number">
            <span *ngIf="form.value.type === 'percentage'"
                  class="percentage">
            </span>
          </mat-form-field>

          <mat-form-field class="push-right" flex>
            <mat-select (selectionChange)="onModifierChange($event)"
                        [value]="modifier"
                        class="row-select"
                        flex="33"
                        placeholder="{{ 'calculation' | translate | ucFirst }}"
                        required>
              <mat-option *ngFor="let modifier of [-1, 1]" [value]="modifier">
                {{ getModifierName(modifier) | translate | ucFirst }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-divider></mat-divider>

          <div layout="row">
            <!-- Select SaAppInstalls -->
            <app-pricerule-connected-daapps *ngIf="form && translations"
                                            (updateCheckArrayFixed)="updateCheckArrayFixed($event)"
                                            (updateCheckArrayMeter)="updateCheckArrayMeter($event)"
                                            [form]="form"
                                            [discountId]="this.discountId"
                                            [translations]="translations">
            </app-pricerule-connected-daapps>
          </div>

          <mat-divider></mat-divider>

          <div layout="row">
            <app-timeframes *ngFor="let timeframe of form.controls.timeframes.controls"
                            [timeframe]="timeframe">
            </app-timeframes>
          </div>

          <mat-divider></mat-divider>

          <div class="location-select" layout="row">
            <mat-form-field class="mat-flex-field" flex>
              <p [innerHtml]="'pricing_rule_location_select'|translate|ucFirst" class="field-description"></p>
              <mat-select class="row-select"
                          flex="100"
                          formControlName="ruleLocation" required>
                <mat-option value="noLimit">{{ 'pricing_rule_location_option_no_limit'|translate|ucFirst }}</mat-option>
                <mat-option value="either">{{ 'pricing_rule_location_option_either_locations'|translate|ucFirst }}
                </mat-option>
                <mat-option value="and">{{ 'pricing_rule_location_option_both_locations'|translate|ucFirst }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="location-select" layout="row">
            <app-location-picker
              [allowEmpty]="true"
              [entity]="discount"
              [ruleType]="'discount'"
              [type]="'dynamic'"
              [form]="form">
            </app-location-picker>
          </div>

          <mat-divider></mat-divider>
        </div>

      </form>

    </mat-card-content>

    <!-- <mat-divider></mat-divider> -->
    <mat-card-actions>
      <button (click)="save()" [disabled]="formDisabled()"
              color="accent"
              flex
              mat-raised-button>
        {{ 'save'|translate|ucFirst }}
      </button>
      <button (click)="delete()" *ngIf="discountId"
              [disabled]="!discountId"
              class="float-right"
              color="warn"
              flex
              mat-raised-button>
        {{ 'delete'|translate|ucFirst }}
      </button>
    </mat-card-actions>
  </ng-template>
</mat-card>
