import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {DriverService} from '../services/driver.service';
import {CoolLocalStorage} from '@angular-cool/storage';
import {environment} from '../../environments/environment';
import {Driver} from '../models/driver';

@Injectable()
export class DriverIdsResolver implements Resolve<Observable<string[]>> {
  driver: Driver;

  constructor(private driverService: DriverService,
              private vault: CoolLocalStorage) {
    this.driver = this.vault.getObject(`${environment.vaultPrefix}.driver`);
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string[]> {
    return this.driverService.getAllDriversIds(this.driver);
  }
}
