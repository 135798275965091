import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OnMeterTypes, Rule} from 'app/models/rule';

@Component({
  selector: 'app-pricing-rule-dialog',
  templateUrl: './pricing-rule-dialog.component.html',
  styleUrls: ['./pricing-rule-dialog.component.scss']
})
export class PricingRuleDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<PricingRuleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  isAllowedOnMeterName = (isAllowedOnMeter: boolean) =>
    OnMeterTypes[String(isAllowedOnMeter)];

  ruleFrontendName = (type: 'dynamic' | 'fixed' | 'meter') =>
    Rule.ruleTypeNamesMapping(type);

  onNoClick(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }
}
