import {Component, Inject, OnInit} from '@angular/core';
import {CompanyWebUserService} from '../../../../../../services/companyWebUser.service';
import {Company} from '../../../../../../models/company';
import {ActivatedRoute} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TdDialogService} from '@covalent/core/dialogs';
import {TranslateService} from '@ngx-translate/core';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-change-owner',
  templateUrl: './change-owner.component.html',
  styleUrls: ['./change-owner.component.scss']
})
export class ChangeOwnerComponent implements OnInit {
  data: any;
  admins: any[];
  company: Company;
  form: UntypedFormGroup;
  translations: string[];

  constructor(
    private _dialogService: TdDialogService,
    private _companyWebUserService: CompanyWebUserService,
    private _route: ActivatedRoute,
    private _fb: UntypedFormBuilder,
    private _translateService: TranslateService,
    public dialogRef: MatDialogRef<ChangeOwnerComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this.data = dialogData;
    this.company = dialogData.parent.company;

    const query = {
      'where': {
        companyId: this.company.id,
        type: 'admin',
        status: {
          nin: ['pending', 'removed'],
        }
      }
    };
    this.initForm();
    _translateService.get(['change_ownership_confirm', 'cancel', 'update_owner'])
      .subscribe((translations: string[]) => {
        this.translations = translations;

      });

    this._companyWebUserService.getAll(query, 'company').subscribe((admins: any[]) => {
      this.admins = admins;
    });
  }

  ngOnInit(): void {

  }

  initForm(): void {
    // See whether it's a true discount, or actually an addition
    this.form = this._fb.group({
      admin: [null, []]
    }, {validators: []});
  }

  updateOwner(): void {
    const self = this;
    self._dialogService.openConfirm({
      message: self.translations['change_ownership_confirm'],
      disableClose: false,
      cancelButton: self.translations['cancel'],
      acceptButton: self.translations['update_owner'],
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        self._companyWebUserService.updateOwnerShip(self.company.id, this.form.controls['admin'].value)
          .subscribe(() => {
            self.dialogRef.close();
            self.data.parent.loadData();
          });
      } else {
        self.dialogRef.close();
        self.data.parent.loadData();
      }
    });
  }
}
