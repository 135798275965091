import {Component, Input, OnInit} from '@angular/core';
import {Driver} from '../../../../../../../models/driver';
import {Company} from '../../../../../../../models/company';
import {UntypedFormGroup} from '@angular/forms';
import {Ride} from '../../../../../../../models/ride';

@Component({
  selector: 'app-ride-edit-note',
  templateUrl: './ride-edit-note.component.html',
  styleUrls: ['./ride-edit-note.component.scss']
})
export class RideEditNoteComponent implements OnInit {
  @Input() context: string;
  @Input() form: UntypedFormGroup;
  @Input() ride: Ride;
  @Input() driver: Driver;
  @Input() company: Company;
  @Input() reference: string;
  @Input() rideId: string;
  @Input() departureGps: any;

  constructor() {
  }

  ngOnInit(): void {
  }

}
