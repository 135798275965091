import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {TdLoadingService} from '@covalent/core/loading';
import {TdMediaService} from '@covalent/core/media';
import {ActivatedRoute} from '@angular/router';
import {RideService} from '../../../services/ride.service';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';
import {Ride} from '../../../models/ride';
import {GeoPoint} from '../../../models/geo-point';
import {GpsLogEntry} from '../../../models/gps-log-entry';
import {Shift} from '../../../models/shift';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Driver} from '../../../models/driver';
import {Rating} from '../../../models/rating';
import {RatingService} from '../../../services/rating.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NavigationService} from '../../../services/navigation.service';
import {Subscription} from 'rxjs/Rx';
import {GoogleMapsAPIWrapper, LAZY_MAPS_API_CONFIG, MapsAPILoader, MarkerManager} from '@agm/core';
import {CustomLazyAPIKeyLoader} from '../../../services/CustomLazyAPIKeyLoader';
import {GoogleMapConfig} from '../../googleMapConfig';

@Component({
  selector: 'app-ride-details',
  templateUrl: './ride-details.component.html',
  styleUrls: ['./ride-details.component.scss'],
  providers: [
    RideService, RatingService,
    MarkerManager,
    GoogleMapsAPIWrapper,
    {provide: MapsAPILoader, useClass: CustomLazyAPIKeyLoader},
    {provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapConfig}
  ],
})
export class RideDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  jobId: string;
  iconUrl: string;
  bodyTitle = 'ride_details';
  layoutButtonsSub: Subscription;
  layoutButtons: any[] = [];
  departureIconUrl: string;
  destinationIconUrl: string;
  mapLineColor: string;
  translations: string[] = [];
  mapInstance: any;
  mapCenter: GeoPoint = new GeoPoint();
  ride: Ride = new Ride();
  shift: Shift = new Shift();
  gpsLogEntries: GpsLogEntry[] = [];
  driverId: string;
  isOwner = false;
  isDriver = false;
  stopReloading = false;
  operatorRating: Rating = new Rating();
  operatorRatingSaved = '';
  hasOperatorRating = false;

  constructor(public media: TdMediaService,
              public snackBar: MatSnackBar,
              private loadingService: TdLoadingService,
              private activatedRoute: ActivatedRoute,
              private rideService: RideService,
              private translateService: TranslateService,
              private titleService: Title,
              private navigationService: NavigationService,
              private ratingService: RatingService,
              private vault: CoolLocalStorage) {
    const params: any = this.activatedRoute.snapshot.params;
    this.jobId = params.id;

    translateService.get(['job_details', 'edit', 'jobs', 'operator_rating_saved', 'save', 'saved', 'dispatch_log'])
      .subscribe((translations: any) => {
        this.titleService.setTitle(translations['job_details'] + environment.windowTitleSuffix);
        this.navigationService.setBodyTitle(translations['job_details']);
        this.operatorRatingSaved = translations['job_details'];
        this.translations = translations;

        navigationService.setSplitLayoutButtons([
          {
            'icon': 'list',
            'tooltip': this.translations['jobs'],
            'link': `/personal/dispatchpanel/`
          },
          {
            'icon': 'edit',
            'tooltip': this.translations['edit'],
            'link': `/personal/dispatchpanel/${this.jobId}/edit`
          },
          {
            'icon': 'assignment',
            'tooltip': this.translations['dispatch_log'],
            'link': `/personal/dispatchpanel/${this.jobId}/dispatch-log`
          }
        ]);
      });

    this.iconUrl = `/assets/${environment.buildName}/icon-marker.png`;
    this.departureIconUrl = `/assets/${environment.buildName}/icon-departure-marker.png`;
    this.destinationIconUrl = `/assets/${environment.buildName}/icon-destination-marker.png`;
    this.mapLineColor = `${environment.mapLineColor}`;
    const driver: Driver = this.vault.getObject(`${environment.vaultPrefix}.driver`);
    this.driverId = driver.id;
  }

  ngOnInit() {
    this.loadingService.register('ride.details');
    this.loadingService.register('ride.map');
    this.loadingService.register('ride.ratings');

    this.layoutButtonsSub = this.navigationService.getSplitLayoutButtons().subscribe(buttons => {
      this.layoutButtons = buttons
    });

    this.loadData();
  }

  ngAfterViewInit() {
    this.media.broadcast();
  }

  mapReady(map): void {
    this.mapInstance = map;
  }

  ngOnDestroy() {
    this.navigationService.setSplitLayoutButtons([]);
  }

  reloadDriverData() {
    this.loadingService.register('ride.details.driver');
    this.loadingService.register('ride.details.driver2');
    this.loadData();
  }

  loadData(): void {
    const self = this;
    const rideQuery = {
      'include': [
        'Vehicle',
        'Ratings',
        'Driver',
        'Passenger',
        'Offer',
        'Dispatcher',
        'Debtor',
        'Owner',
        'DriverTariff',
        {
          'Shift': [
            {
              'relation': 'ShiftEntries',
              'scope': {
                'where': {
                  'rideId': this.jobId
                }
              }
            },
            {
              'relation': 'GpsLogEntries',
              'scope': {
                'where': {
                  'rideId': this.jobId
                }
              }
            }
          ]
        }
      ]
    };

    this.rideService.get(this.jobId, rideQuery).subscribe((ride: Ride) => {
      this.shift = {...ride.Shift};
      this.gpsLogEntries = (typeof ride.Shift !== 'undefined' ? {...ride.Shift.GpsLogEntries} : []);
      delete ride.Shift;
      ride.intId = (ride.foreignDispatchId ? ride.foreignDispatchId : (ride.foreignBookingId ? ride.foreignBookingId : ride.intId));
      this.ride = ride;
      this.isOwner = (this.ride.ownerId === this.driverId);
      this.isDriver = (this.ride.driverId === this.driverId);
      this.ride.Ratings.forEach(r => {
        if (r.type === 'operator') {
          this.operatorRating = r;
          this.hasOperatorRating = true;
        }
      });

      if (this.ride.Offer) {
        this.ride.LastOffer = this.ride.Offer[this.ride.Offer.length - 1];
        if (this.ride.LastOffer && !this.stopReloading) {
          if (!['success', 'expired', 'failed', 'canceled'].includes(this.ride.LastOffer.status)) {
            setTimeout(function () {
              self.loadData();
            }, 5000);
          }
        }
      }

      if (typeof this.gpsLogEntries.length === 'undefined' &&
        typeof this.shift.ShiftEntries !== 'undefined' &&
        this.shift.ShiftEntries.length > 1) {
        const gpsQuery = {
          'where': {
            'or': [
              {
                'rideId': this.jobId
              }
            ],
            'created': {
              'between': [
                this.shift.ShiftEntries[0].startTime,
                this.shift.ShiftEntries[this.shift.ShiftEntries.length - 1].endTime
              ]
            }
          }
        };
        this.rideService.getGpsLog(gpsQuery).subscribe((gpsLogEntries: GpsLogEntry[]) => {
          self.gpsLogEntries = gpsLogEntries;
          setTimeout(() => {
            self.resolveAllLoaders();
          }, 500);
        });
      } else {
        setTimeout(() => {
          self.resolveAllLoaders();
        }, 500);
      }
    }, error => {
      console.error(error);
      setTimeout(() => {
        self.resolveAllLoaders();
      }, 500);
    })
  }

  resolveAllLoaders() {
    this.loadingService.resolve('ride.details');
    this.loadingService.resolve('ride.map');
    this.loadingService.resolve('ride.ratings');
    this.loadingService.resolveAll('ride.details');
    this.loadingService.resolveAll('ride.details2');
    this.loadingService.resolveAll('ride.details.map');
    this.loadingService.resolveAll('ride.details.driver');
    this.loadingService.resolveAll('ride.details.driver2');
  }

  saveOperatorRating(): void {
    this.loadingService.register('ride.operator.rating');
    const self = this;

    if (this.hasOperatorRating) {
      const body = {
        comment: this.operatorRating.comment,
        score: this.operatorRating.score,
      };
      this.ratingService.update(this.operatorRating.id, body, 'company').subscribe((operatorRating: Rating) => {
        this.operatorRating = operatorRating;
        this.loadData();
        setTimeout(() => {
          self.loadingService.resolve('ride.operator.rating');
          self.snackBar.open(self.operatorRatingSaved, 'OK', {
            duration: 2000,
          });
        }, 500);
      }, error => {
        console.error(error);
        this.loadData();
        setTimeout(() => {
          self.loadingService.resolve('ride.operator.rating');
        }, 500);
      });
    } else {
      const driver: Driver = this.vault.getObject(`${environment.vaultPrefix}.driver`);
      this.operatorRating.type = 'operator';
      this.operatorRating.rideId = this.ride.id;
      this.operatorRating.driverId = this.ride.driverId;
      this.operatorRating.ownerId = this.ride.driverId;
      this.operatorRating.ownerType = 'Driver';
      this.operatorRating.creatorId = driver.id;
      this.operatorRating.creatorType = 'Driver';

      this.ratingService.create(this.ride.id, this.operatorRating, 'company').subscribe((operatorRating: Rating) => {
        this.operatorRating = operatorRating;
        this.loadData();
        setTimeout(() => {
          self.loadingService.resolve('ride.operator.rating');
          self.snackBar.open(self.operatorRatingSaved, 'OK', {
            duration: 2000,
          });
        }, 500);
      }, error => {
        console.error(error);
        this.loadData();
        setTimeout(() => {
          self.loadingService.resolve('ride.operator.rating');
        }, 500);
      });
    }
  }

}
