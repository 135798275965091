<mat-card class="push">
  <ng-template tdLoading="log">
    <ng-template [ngForOf]="ride.RideLog.Entries" let-entry let-last="last" ngFor>
      <!--<td-expansion-panel label="" sublabel="" expand="false" disabled="false" [disableRipple]="false">-->
      <!--<ng-template td-expansion-panel-header>-->
      <!--<div class="mat-padding">-->
      <!--<div flex layout-gt-xs="row" layout-align="start center">-->
      <!--<span flex="30" class="mat-subhead"><mat-icon>assessment</mat-icon> Owner</span>-->
      <!--<span flex="5" hide-xs><mat-icon class="tc-grey-500">chevron_right</mat-icon></span>-->
      <!--<span flex>John Jameson</span>-->
      <!--</div>-->
      <!--<div flex layout-gt-xs="row" layout-align="start center">-->
      <!--<span flex="30" class="mat-subhead">Owner</span>-->
      <!--<span flex="5" hide-xs><mat-icon class="tc-grey-500">chevron_right</mat-icon></span>-->
      <!--<span flex>John Jameson</span>-->
      <!--</div>-->
      <!--<div flex layout-gt-xs="row" layout-align="start center">-->
      <!--<span flex="30" class="mat-subhead">API Key</span>-->
      <!--<span flex="5" hide-xs><mat-icon class="tc-grey-500">chevron_right</mat-icon></span>-->
      <!--<span flex>1141e8e8-8d24-4956-93c2</span>-->
      <!--</div>-->
      <!--</div>-->
      <!--</ng-template>-->
      <!--<td-expansion-summary></td-expansion-summary>-->
      <!--EXPANDED CONTENT-->
      <!--</td-expansion-panel>-->
      <td-expansion-panel class="ride-log-entry">
        <ng-template td-expansion-panel-label>
          <mat-icon *ngIf="entry.group == 'general'" class="push-right-sm">update</mat-icon>
          <mat-icon *ngIf="entry.group == 'dispatchProcess'" class="push-right-sm">subdirectory_arrow_right</mat-icon>
          <mat-icon *ngIf="entry.group == 'dispatchStats'" class="push-right-sm">assessment</mat-icon>
          <span>{{entry.action|translate}}</span>
        </ng-template>
        <ng-template td-expansion-panel-sublabel>
          <span *ngIf="entry.group == 'general'">{{entry.description|translate}}</span>
          <span *ngIf="entry.group == 'dispatchProcess'">{{entry.description|translate}}</span>
          <span *ngIf="entry.group == 'dispatchStats'">{{entry.meta.response|translate}}</span>
        </ng-template>
        <td-expansion-summary></td-expansion-summary>
        <div class="mat-padding">
          <div flex layout-align="start top" layout-gt-xs="row">
            <span class="mat-subhead" flex="30">Timestamp</span>
            <span flex="5" hide-xs><mat-icon class="tc-grey-500">chevron_right</mat-icon></span>
            <span flex>{{entry.created}}</span>
          </div>
          <div flex layout-align="start top" layout-gt-xs="row">
            <span class="mat-subhead" flex="30">Meta</span>
            <span flex="5" hide-xs><mat-icon class="tc-grey-500">chevron_right</mat-icon></span>
            <span flex>
              <pre>{{entry.meta|json}}</pre>
            </span>
          </div>
        </div>
      </td-expansion-panel>
      <!--<mat-divider></mat-divider>-->
    </ng-template>
  </ng-template>
</mat-card>
