<mat-card *ngIf="form" [formGroup]="form">
  <mat-card-title *ngIf="!this.googleConnected">{{'onboard_calendar_title' | translate | ucFirst}}</mat-card-title>
  <mat-card-title
    *ngIf="this.googleConnected">{{'onboard_calendar_connected_title' | translate | ucFirst}}</mat-card-title>
  <mat-card-subtitle>{{'onboard_calendar_subtitle' | translate | ucFirst}}</mat-card-subtitle>

  <mat-divider></mat-divider>

  <mat-card-content>
    <div class="options" flex>
      <div *ngIf="this.googleConnected">
        <strong *ngIf="this.googleConnected"
                layout="row">{{this.translations['onboard_calendar_text_1'] | ucFirst}}</strong>
        <strong *ngIf="!this.googleConnected" layout="row">{{'onboard_calendar_text_2' | translate | ucFirst}}</strong>
        <mat-list-item class="mat-checkbox-pane" flex="100" layout="row">
          <mat-checkbox [checked]="(this.form && this.form.controls['checkConflicts'].value)" [disabled]="true" class="pull-left"
                        formControlName="checkConflicts" value="true">
            <mat-label>{{this.translations['onboard_calendar_option_1']|ucFirst}}</mat-label>
          </mat-checkbox>
        </mat-list-item>
        <mat-list-item flex="100" layout="row">
          <mat-checkbox [checked]="(this.form &&
          this.form.controls['addEvents'].value)" class="pull-left" formControlName="addEvents" value="true">
            <mat-label>{{(this.primaryCalendar === 'new' ? this.translations['onboard_calendar_option_3'] :
              this.translations['onboard_calendar_option_2'])|ucFirst}}</mat-label>
          </mat-checkbox>
          <button (click)="editCalendarSettings()" *ngIf="this.googleConnected" class="edit-button"
                  mat-stroked-button>{{'edit'|translate|ucFirst}}</button>
        </mat-list-item>
      </div>
      <div *ngIf="!this.googleConnected" id="gSignInWrapper">
        <div (click)="_globalFunctions.handleAuthClick();" class="customGPlusSignIn" id="customBtn">
          <svg class="google-logo" version="1.1" viewBox="0 0 48 48"
               xmlns="http://www.w3.org/2000/svg">
            <g>
              <path d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                    fill="#EA4335"></path>
              <path d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                    fill="#4285F4"></path>
              <path d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                    fill="#FBBC05"></path>
              <path d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                    fill="#34A853"></path>
              <path d="M0 0h48v48H0z" fill="none"></path>
            </g>
          </svg>
          <span class="buttonText">{{'connected_google_account'|translate|ucFirst}}</span>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>

    <mat-card-actions *ngIf="form" class="actions">
      <div class="progress">3/5 {{'completed'|translate|ucFirst}}</div>

      <button (click)="setStep('skipped')" class="link-button"
              mat-button>{{'setup_later' | translate | ucFirst}}</button>

      <button (click)="this.setStep('service')" color="primary"
              mat-raised-button>
        {{'continue'| translate | ucFirst}}
      </button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
