import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TdDialogService} from '@covalent/core/dialogs';
import {TranslateService} from '@ngx-translate/core';
import {Company} from '../../../../../../../../../../models/company';
import { QuillModule } from 'ngx-quill';

@Component({
  selector: 'app-message-rule-action-edit-dialog-email',
  templateUrl: './message-rule-action-edit-dialog-email.component.html',
  styleUrls: ['./message-rule-action-edit-dialog-email.component.scss'],

})
export class MessageRuleActionEditDialogEmailComponent implements OnInit {
  parent: any
  form: any
  action = 'edit';
  index = 0;
  translationIndex = 0;
  i = 0;
  maxText = 160;
  initMaxText = 160;
  remainingText = [];
  currentLength = [];
  hasError= false;
  languages = [];
  translations = [];
  templates = [
    'standard',
    'standard_empty'
  ];
  items: string[] = [
    'company.name',
    'company.email',
    'company.phone_number',
    'company.owner_name',
    'passenger.first_name',
    'passenger.last_name',
    'passenger.phone_number',
    'passenger.email',
    'driver.first_name',
    'driver.last_name',
    'driver.phone_number',
    'driver.email',
    'vehicle.brand',
    'vehicle.model',
    'vehicle.type',
    'vehicle.licensePlate',
    'ride.reference',
    'ride.requestedDate',
    'ride.departure',
    'ride.destination',
    'ride.publicUrl',
    // 'ride.rating_url',
    'ride.rebook_url',
  ];

  protected readonly Math = Math;
  company: Company;
  modules = {
    'toolbar': [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'font': [], 'size':  ['8px', '9px', '10px', '12px', '14px', '16px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px'] }],
      [{ 'align': [] }],
      ['clean'],                                         // remove formatting button
      ['link', 'image', 'video'],                         // link and image, video
      ['emoji']
    ]
  }

  constructor(
    private _formBuilder: UntypedFormBuilder,
    public _dialogRef: MatDialogRef<MessageRuleActionEditDialogEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogService: TdDialogService,
    private _translationService: TranslateService,
  ) {
    this.i = data.index;
    this.translationIndex = data.translationIndex;
    this.form = data.form;
    this.action = data.action;
    this.company = data.company;
    this.parent = data.parent;

    this.languages = data.languages.filter((l) => {
      if (!this.form.controls.actions.controls[this.i].controls.messages.controls) {
        return true;
      }
      return (!this.form.controls.actions.controls[this.i].controls.messages.controls.map((c) => {
        return c.controls.language.value;
      }).includes(l.short));
    });
    if (this.action === 'add') {
      let myLanguage = this.languages.filter((l) => {
        return l.short === this.company.language;
      })[0];

      if (!myLanguage) {
        // Default language already taken? select english "en"
        myLanguage = this.languages.filter((l) => {
          return l.short === 'en';
        })[0];
      }

      this.form.controls.actions.controls[this.i].controls.messages.controls.push((this._formBuilder.group({
        language: [(myLanguage ? myLanguage.short : this.languages[0].short), [Validators.required]],
        subject: ['', [Validators.required]],
        template: ['standard', []],
        blocks: this._formBuilder.group({
          jobdetails: [false, []],
          passenger: [false, []],
          driverblock: [false, []],
          vehicle: [false, []],
        }),
        message: ['', []],
      })));
      this.translationIndex = (this.form.controls.actions.controls[this.i].controls.messages.controls.length - 1);

    }
    _translationService.get(['confirm_delete_translation', 'ok']).subscribe((translations) => {
      this.translations = translations;
    });
  }

  ngOnInit(): void {
  }

  formatMention(data) {
    return `{${data.name}}`;
  }

  changeSelectionMatchKey($event, i) {
  }

  valueChange(index, value: any) {
    this.remainingText[index] = this.calculateRemainingText(value.target.value);
    this.currentLength[index] = this.calculateRemainingText(value.target.value);
    return true;
  }

  calculateRemainingText(text) {
    let currentLength = text.length;
    const foundTags = text.match(/({.*?})/g);
    (foundTags ? foundTags : []).forEach((tag) => {
      const splitTag = tag.split('|');
      if (splitTag[1]) {
        // tslint:disable-next-line:radix
        const parsedInt = parseInt(splitTag[1].replace('}', ''));
        if (parsedInt > 0) {
          currentLength = currentLength - tag.length + parsedInt;
        }
      }
    });
    return currentLength;
  }
  close() {
    this._dialogRef.close();
    if (this.form.controls.actions.controls[this.i].controls.messages.controls.length < 1) {
      this.form.controls.actions.controls[this.i].controls.messages.controls.splice(this.translationIndex, 1);
      this.form.controls.actions.controls.splice(this.i, 1);
      this.parent.hasEmailAction = false;
    }
    this.form.controls['actions'].updateValueAndValidity();
  }
  save() {
    this.form.controls.actions.controls[this.i].controls.messages.controls[this.translationIndex].updateValueAndValidity();
    this.form.controls.actions.controls[this.i].controls.messages.updateValueAndValidity();
    this.form.controls.actions.controls[this.i].updateValueAndValidity();

    if (this.form.controls.actions.controls[this.i].controls.messages.controls[this.translationIndex].value.message !== '' && this.form.controls.actions.controls[this.i].controls.messages.controls[this.translationIndex].valid) {
      this._dialogRef.close();
    } else if (this.form.controls.actions.controls[this.i].controls.messages.controls[this.translationIndex].value.message === '') {
      this.hasError = true;
    }
  }

  delete() {
    const self = this;
    self._dialogService.openConfirm({
      'message': self.translations['confirm_delete_translation'],
      'disableClose': false,
      'acceptButton': self.translations['ok'],
    }).afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          self.form.controls.actions.controls[self.i].controls.messages.controls.splice(self.translationIndex, 1);
          const controls = self.form.controls.actions.controls[self.i].controls.messages.controls;
          self.form.controls.actions.controls[self.i].removeControl('messages');
          self.form.controls.actions.controls[self.i].addControl('messages', self._formBuilder.array(controls))
          self.form.controls.actions.controls[self.i].updateValueAndValidity();
          self.form.controls['actions'].updateValueAndValidity();
        }
        self.close();
      });
  }
}
