import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CompanyInvitationService} from '../../../../../services/company-invitation.service';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {phoneNumberValidator} from '../../../../../pipes/phonenumber-validator.pipe';
import {DriverService} from '../../../../../services/driver.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-edit-invite-dialog',
  templateUrl: './edit-invite-dialog.component.html',
  styleUrls: ['./edit-invite-dialog.component.scss']
})
export class EditInviteDialogComponent implements OnInit {
  private form;
  private invite;
  private company;
  private parent;
  private translations=[];
  private action = 'Edit';
  formErrors: any = {};
  validationMessages = {
    'fname': {
      'fieldName': 'first_name',
      'required': 'form_validation_required',
    },
    'lname': {
      'fieldName': 'last_name',
      'required': 'form_validation_required',
    },
    'emailAddress': {
      'fieldName': 'email',
      'required': 'form_validation_required',
    },
    'phoneNumber': {
      'fieldName': 'phonenumber',
      'required': 'form_validation_required',
      'validatePhoneNumber': 'phonenumber_pattern_invalid',
    },
    'type': {
      'fieldName': 'type',
      'required': 'form_validation_required',
    }
  };

  constructor(
    private _companyInvitationService: CompanyInvitationService,
    private _driverService: DriverService,
    private _fb: UntypedFormBuilder,
    public _snackBar: MatSnackBar,
    private _translateService: TranslateService,
    public dialogRef: MatDialogRef<EditInviteDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any
  ) {
   const self = this;
   this.company = data.company;
   this.parent = data.parent;
    this._companyInvitationService.get(data.inviteId).subscribe((invite) => {
      self.invite = invite;
      this.initForm();
    })
  }

  ngOnInit(): void {
    this._translateService.get(['driver_invite_updated', 'ok'
    ])
      .subscribe((translations: string[]) => {
      this.translations =  translations;
      });
  }

  initForm() {
    const self = this;
    self.form = this._fb.group({
      fname: [self.invite.fname, [Validators.required]],
      lname: [self.invite.lname, [Validators.required]],
      emailAddress: [{value: self.invite.emailAddress, disabled: (this.action === 'Edit')},
        {
          validators: [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
          updateOn: 'blur',
          disabled: true
        }],
    });
  }

  save() {
    const self = this;

    console.log(self.form.value);
    self._companyInvitationService.update(self.invite.id, self.form.value).subscribe((invite) => {
      self._driverService.getAll({where: {
        phoneNumber: encodeURIComponent(self.invite.phoneNumber),
        companyId: self.company.id,
        }}, 'company').subscribe((drivers) => {
          if (drivers[0]) {
            self._driverService.update(drivers[0].id, {
              fname: self.form.value.fname,
              lname: self.form.value.lname
            }).subscribe(() => {
              self._snackBar.open(self.translations['driver_invite_updated'], self.translations['ok'], {
                duration: 3000
              });
              self.parent.loadData();
              self.dialogRef.close();
            })
          } else {
            self._snackBar.open(self.translations['driver_invite_updated'], self.translations['ok'], {
              duration: 3000
            });
            self.parent.loadData();
            self.dialogRef.close();
          }
      })
    });
  }
}
