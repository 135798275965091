import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {DaAppInstall} from '../models/da-app-install';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from './utility.service';
import {GlobalService} from './global.service';
import AES from 'crypto-js/aes';

const appModel = 'daAppinstalls';
const base = environment.orderFormApiUrl;

@Injectable()
export class DaAppInstallService extends GlobalService {
  constructor(_http: HttpClient, _vault: CoolLocalStorage, _utilityService: UtilityService) {
    super(_http, _vault, _utilityService);
    this.setModel('daAppinstalls');
  }

  insert = (daAppInstall: DaAppInstall, context?: string): Observable<DaAppInstall> =>
    this._http.post<DaAppInstall>(
      `${base}/${appModel}`, daAppInstall,
      {headers: this.getHeaders(context)},
    );

  reportError = (data: any, context?: string): Observable<any> => {
    return this._http.post<DaAppInstall>(
      `${environment.apiBaseUrl}/${appModel}/reportError`, data,
      {headers: this.getHeaders(context)},
    );
  }

  refreshToken(formId: string): Promise<string> {
    return new Promise((resolve) => {
      const url = `${base}/jwt/login`;
      const secret = `${formId}`.replace(/-/g, '/').split('').reverse().join('');
      const publicSecret = `${secret}`.replace(/\//g, '+');
      const data: any = {
        timestamp: new Date().toISOString(),
        credentials: formId,
      };
      const body = {
        credentials: `${publicSecret}#${AES.encrypt(JSON.stringify(data), secret).toString()}`
      };
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-Form-Id': formId,
        'X-Ignore-Auth': 'true',
      });
      this._http.post<any>(
        `${url}`, body,
        {headers},
      ).subscribe((res) => {
        return resolve(res.token);
      });
    });
  }
}
