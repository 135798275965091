import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {FieldError} from '../interfaces/field-error';

@Injectable({
  providedIn: 'root'
})
export class FieldErrorService {
  private errorList = new BehaviorSubject<FieldError[]>([]);
  readonly $errors = this.errorList.asObservable();

  constructor() {
  }

  get errors(): FieldError[] {
    return this.errorList.getValue();
  }

  set errors(errors: FieldError[]) {
    this.errorList.next(errors);
  }

  addError(error: FieldError) {
    if (!this.errorExists(error.id)) {
      this.errors = [
        ...this.errors,
        error
      ];
    }
  }

  addErrors(errors: FieldError[]) {
    errors.forEach(error => {
      this.addError(error);
    });
  }

  removeErrors(ids: string[]) {
    ids.forEach(id => {
      this.removeError(id);
    });
  }

  errorExists(id: string): boolean {
    return [...this.errors].filter(error => error.id === id).length > 0;
  }

  removeError(id: string) {
    this.errors = this.errors.filter(error => error.id !== id);
  }

  removePropertyErrors(property: string) {
    this.errors = this.errors.filter(error => error.property !== property);
  }

  getFieldErrors(property: string): FieldError[] {
    return [...this.errors].filter(error => error.property === property);
  }

  fieldHasErrors(property: string): boolean {
    return [...this.errors].filter(error => error.property === property).length > 0;
  }

  hasErrors(): boolean {
    return this.errors.length > 0;
  }
}
