import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-ride-dispatcher-data',
  templateUrl: './ride-dispatcher-data.component.html',
  styleUrls: ['./ride-dispatcher-data.component.scss']
})
export class RideDispatcherDataComponent implements OnInit {

  @Input() dispatcher: any;
  @Input() operatorNote: string;

  name = '-';

  constructor() {
  }

  ngOnInit() {
    if (this.dispatcher) {
      this.name = `${this.dispatcher.fname} ${this.dispatcher.lname}`;
    }
  }
}
