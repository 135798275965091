import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DriverRoutingModule} from './driver-routing.module';
import {ShiftsComponent} from '../shared/shifts/shifts.component';
import {CovalentDataTableModule} from '@covalent/core/data-table';
import {CovalentExpansionPanelModule} from '@covalent/core/expansion-panel';
import {CovalentLayoutModule} from '@covalent/core/layout';
import {CovalentLoadingModule} from '@covalent/core/loading';
import {CovalentMediaModule} from '@covalent/core/media';
import {CovalentMenuModule} from '@covalent/core/menu';
import {CovalentMessageModule} from '@covalent/core/message';
import {CovalentPagingModule} from '@covalent/core/paging';
import {CovalentSearchModule} from '@covalent/core/search';

import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';

import {ShiftDetailsComponent} from '../shared/shifts/parts/shift-details/shift-details.component';
import {AgmCoreModule} from '@agm/core';
import {RidesComponent} from '../shared/rides/rides.component';
import {RideDetailsComponent} from './ride-details/ride-details.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {MomentModule} from 'angular2-moment';
import {UcFirstPipeModule} from '../../pipes/uc-first.pipe';
import {ReplacePipeModule} from '../../pipes/replace.pipe';
import {RatingsComponent} from './ratings/ratings.component';
import {MillisecondsToHoursPipeModule} from '../../pipes/milliseconds-to-hours.pipe';
import {SpeedPerHourPipeModule} from '../../pipes/speed-per-hour.pipe';
import {ShiftMapComponent} from './shift-map/shift-map.component';
import {SharedModule} from '../shared/shared.module';
import {InvoicesComponent} from './invoices/invoices.component';
import {SettingsComponent} from './invoices/settings/settings.component';
import {ToolbarComponent} from './invoices/parts/toolbar/toolbar.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DebtorsComponent} from './debtors/debtors.component';
import {DebtorUpsertComponent} from './debtor-upsert/debtor-upsert.component';
import {LocalizedCurrencyPipeModule} from '../../pipes/localized-currency.pipe';
import {DriversComponent} from '../shared/drivers/drivers.component';
import {CompanyModule} from '../company/company.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'https://localization-api.dispatchapi.io/api/Projects/591afd8a17f51db67fc57968/', '');
}

@NgModule({
  imports: [
    CommonModule,
    MomentModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    UcFirstPipeModule,
    MillisecondsToHoursPipeModule,
    SpeedPerHourPipeModule,
    CovalentPagingModule,
    CovalentMessageModule,
    CovalentLayoutModule,
    CovalentLoadingModule,
    CovalentMediaModule,
    CovalentMenuModule,
    CovalentDataTableModule,
    CovalentSearchModule,
    CovalentExpansionPanelModule,
    MatSnackBarModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatExpansionModule,
    MatInputModule,
    MatSidenavModule,
    MatToolbarModule,
    MatSelectModule,
    MatTooltipModule,
    MatTabsModule,
    MatDatepickerModule,
    DriverRoutingModule,
    AgmCoreModule,
    ReplacePipeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LocalizedCurrencyPipeModule,
    CompanyModule
  ],
  declarations: [
    ShiftsComponent,
    ShiftDetailsComponent,
    RidesComponent,
    RideDetailsComponent,
    RatingsComponent,
    ShiftMapComponent,
    InvoicesComponent,
    SettingsComponent,
    ToolbarComponent,
    DebtorsComponent,
    DebtorUpsertComponent,
    DriversComponent
  ]
})
export class DriverModule {
}
