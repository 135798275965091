<ng-template tdLoading="dashboard.data">
  <div [mediaClasses]="['push-sm']" layout-gt-sm="row" tdMediaToggle="gt-xs">
    <div flex-gt-sm="50">
      <driver-profile-widget
        [driver]="driver"
        [vehicles]="vehicles"
        buttonLink="/profile"
        buttonText="edit_profile"
        subTitle="profile_subtitle"
        title="profile">
      </driver-profile-widget>
      <driver-score-widget
        [driverScore]="driverScore"
        cssClasses="push-top driver-score"
        subTitle="driver_score_subtitle"
        title="driver_score">
      </driver-score-widget>
    </div>
    <div flex-gt-sm="50">
      <ride-count-widget [driverId]="driver.id"
                         buttonLink="/jobs"
                         buttonText="View all jobs"
                         subTitle="dashboard_rides_month_breakdown"
                         title="rides">
      </ride-count-widget>
      <ride-status-widget [colorScheme]="rideStatusColors"
                          [driverId]="driver.id"
                          buttonLink="/jobs"
                          buttonText="View all jobs"
                          cssClasses="push-top"
                          subTitle="dashboard_status_breakdown_subtitle"
                          title="dashboard_status_breakdown">
      </ride-status-widget>
    </div>
  </div>
</ng-template>
