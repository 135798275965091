<td-layout-card-over cardSubtitle="{{'your_profile_subtitle'|translate}}" cardTitle="{{'your_profile'|translate}}"
                     cardWidth="75">
  <mat-card-content class="push-bottom-none">
    <ng-template tdLoading="profile">
      <form [formGroup]="form">
        <div class="push-bottom-sm push-top-sm" layout="row">
          <mat-form-field class="push-right" flex>
            <input #firstName formControlName="firstName" matInput placeholder="{{'first_name'|translate}}" type="text"
                   value="{{user.firstName}}">
            <mat-hint align="start">
            <span [hidden]="!formErrors.firstName" class="tc-red-600">
              <span>{{ formErrors.firstName }}</span>
            </span>
            </mat-hint>
          </mat-form-field>
          <mat-form-field flex>
            <input #lastName formControlName="lastName" matInput placeholder="{{'last_name'|translate}}" type="text"
                   value="{{user.lastName}}">
            <mat-hint align="start">
            <span [hidden]="!formErrors.lastName" class="tc-red-600">
              <span>{{ formErrors.lastName }}</span>
            </span>
            </mat-hint>
          </mat-form-field>
        </div>
        <div layout="row">
          <mat-form-field flex>
            <input #email formControlName="email" matInput placeholder="{{'email'|translate}}" type="text"
                   value="{{user.email}}">
            <mat-hint align="start">
            <span [hidden]="!formErrors.email" class="tc-red-600">
              <span>{{ formErrors.email }}</span>
            </span>
            </mat-hint>
          </mat-form-field>
        </div>
        <div *ngIf="this.driver || (this.user.connectStatus !== 'none' && this.user.connectStatus !== 'rejected')"
             layout="row">
          <mat-list-item>
            <p class="phonenumber-header" mat-line>{{'phonenumber'|translate|ucFirst}}</p>
            <p mat-line>{{user.phoneNumber}}</p>
          </mat-list-item>
        </div>
        <div *ngIf="!this.driver && (this.user.connectStatus === 'none' || this.user.connectStatus === 'rejected')"
             class="phoneNumberField push-right {{(formErrors.phoneNumber ? 'phoneNumberInvalid' : '')}}"
             flex>
          <ngx-intl-tel-input
            [cssClass]="'phoneNumberInput'"
            [enableAutoCountrySelect]="true"
            [enablePlaceholder]="true"
            [maxLength]="15"
            [phoneValidation]="true"
            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
            [searchCountryFlag]="true"
            [selectFirstCountry]="false"
            [selectedCountryISO]="((this.driver ? this.driver.country : this.user.country) === 'EN' ? 'GB' : (this.driver ? this.driver.country : this.user.country))"
            flex
            formControlName="phoneNumber"
            name="phoneNumber">
          </ngx-intl-tel-input>

          <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.phoneNumber" class="tc-red-600">
                  <span>{{ formErrors.phoneNumber }}</span>
                </span>
          </mat-hint>
        </div>
      </form>
    </ng-template>
  </mat-card-content>
  <mat-divider></mat-divider>
  <div class="driver-status-row" layout="row">
    <mat-card-content *ngIf="this.driver" class="pull-bottom">
      <div class="strong" layout="column">{{'driver_connect_status'|translate|ucFirst}}:</div>
      <div layout="column">{{'driver_connect_status_connected'|translate|ucFirst}}</div>
    </mat-card-content>
    <mat-card-content *ngIf="!this.driver && this.user.connectStatus === 'none'" class="pull-bottom">
      <div class="strong" layout="column">{{'driver_connect_status'|translate|ucFirst}}:</div>
      <div layout="column">{{'driver_connect_status_notconnected'|translate|ucFirst}}</div>
    </mat-card-content>
    <mat-card-content *ngIf="!this.driver && this.user.connectStatus === 'proposed'" class="pull-bottom">
      <div class="strong" layout="column">{{'driver_connect_status'|translate|ucFirst}}:</div>
      <div layout="column">{{'driver_connect_status_proposed'|translate|ucFirst}}</div>
    </mat-card-content>
    <mat-card-content *ngIf="!this.driver && this.user.connectStatus === 'rejected'" class="pull-bottom">
      <div class="strong" layout="column">{{'driver_connect_status'|translate|ucFirst}}:</div>
      <div (click)="initConnect()" class="pointer yda-link-blue"
           layout="column">{{'driver_connect_status_rejected'|translate|ucFirst}}</div>
    </mat-card-content>
  </div>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <button (click)="save()" [disabled]="!form.valid" color="accent" flex
            mat-raised-button>{{'save_profile_button'|translate}}</button>
  </mat-card-actions>
</td-layout-card-over>
