import {Component} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Field} from '../../models/field.interface';
import {FieldConfig} from '../../models/field-config.interface';

@Component({
  selector: 'app-form-select',
  templateUrl: './form-select.component.html',
  styleUrls: ['./form-select.component.scss']
})
export class FormSelectComponent implements Field {
  config: FieldConfig;
  group: UntypedFormGroup;
}
