import {Component, Input, OnInit} from '@angular/core';
import {ITdDataTableSortChangeEvent, TdDataTableService, TdDataTableSortingOrder} from '@covalent/core/data-table';
import {ActivatedRoute, Router} from '@angular/router';
import {TdLoadingService} from '@covalent/core/loading';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../../../../services/navigation.service';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-billing-history',
  templateUrl: './billing-history.component.html',
  styleUrls: ['./billing-history.component.scss']
})
export class BillingHistoryComponent implements OnInit {
  @Input() billingHistory: any;
  @Input() dueContract: any;
  filteredInvoiceCount = 0;
  searchTerm = '';
  fromRow = 1;
  currentPage = 1;
  pageSize = 20;
  filteredData = [];
  loaderName = 'billingHistory';

  sortBy = 'identifier';
  sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;

  columns = [
    {name: 'number', label: 'invoice_number', sortable: true, active: false},
    {name: 'created', label: 'date', sortable: true, active: true, type: 'dateTime'},
    // { name: 'paidAt', label: 'invoice_paid_date',       sortable: true,   active: false, type: 'dateTime' },
    // { name: 'periodEnd', label: 'invoice_periode_end',       sortable: true,   active: false, type: 'dateTime' },
    // { name: 'periodEnd', label: 'invoice_periode_end',       sortable: true,   active: false, type: 'dateTime' },
    // { name: 'paymentAttempts', label: 'payment_attempts',  sortable: true,   active: false },
    {name: 'subtotal', label: 'subtotal', sortable: true, active: false, type: 'currency'},
    {name: 'tax', label: 'tax', sortable: true, active: false, type: 'currency'},
    {name: 'total', label: 'total', sortable: true, active: false, type: 'currency'},
    {name: 'status', label: 'status', sortable: true, active: false, type: 'status'},
  ];

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _dataTableService: TdDataTableService,
    private _loadingService: TdLoadingService,
    private _translateService: TranslateService,
    private _navigationService: NavigationService,
    private _titleService: Title
  ) {

  }

  ngOnInit(): void {
    this.loadData();
  }

  filter(): void {
    let filteredData: any[] = this.filteredData;

    filteredData = this._dataTableService.filterData(filteredData, this.searchTerm, true);
    filteredData = this._dataTableService.sortData(filteredData, this.sortBy, this.sortOrder);
    filteredData = this._dataTableService.pageData(filteredData, this.fromRow, this.currentPage * this.pageSize);

    this.filteredInvoiceCount = filteredData.length;
  }

  open(url: string): void {
    if (url) {
      window.open(url);
    }
  }

  sort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.sortBy = sortEvent.name;
    this.sortOrder = sortEvent.order;
    this.filter();
  }

  loadData(): void {
    const self = this;
    if (self.billingHistory && self.billingHistory.data) {
      self.filteredData = self.billingHistory.data.map((invoice) => {
        invoice['currency'] = invoice['currency'].toUpperCase();
        if (invoice.status === 'processing') {
          invoice.statusClass = 'processing';
        } else if (invoice.status !== 'paid') {
          invoice.statusClass = 'negative';
          invoice.paymentButton = true;
        }

        if (!self.dueContract || (invoice['status'] !== 'paid' && invoice['status'] !== 'void') || true) {
          return {
            number: invoice['number'],
            subtotal: {
              total: invoice['subtotal'],
              currency: invoice['currency']
            },
            tax: {
              total: invoice['tax'],
              currency: invoice['currency']
            },
            total: {
              total: invoice['total'],
              currency: invoice['currency']
            },
            amountPayed: {
              total: invoice['amount_paid'],
              currency: invoice['currency']
            },
            paymentAttempts: invoice['attempt_count'],
            created: new Date(invoice['created'] * 1000),
            paidAt: (invoice['status_transitions']['paid_at'] ? new Date(invoice['status_transitions']['paid_at'] * 1000) : ''),
            periodStart: new Date(invoice['period_start'] * 1000),
            periodEnd: new Date(invoice['period_end'] * 1000),
            status: (invoice['status'] === 'open' ? 'start_payment' : invoice['status']),
            url: {
              'receipt': {
                url: invoice['hosted_invoice_url'],
                tag: 'receipt'
              },
              'invoice': {
                url: invoice['invoice_pdf'],
                tag: 'download'
              }
            },
            statusClass: invoice.statusClass,
            paymentButton: invoice.paymentButton
          }
        }
      });

      self.filteredData = self.filteredData.filter((d) => {
        return (d)
      })
      self.filter();
    } else {
      setTimeout(function () {
        self.loadData();
      }, 500);
    }
  }
}
