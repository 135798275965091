import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Discount, DiscountLink} from 'app/models/discount';
import {Rule, RuleLink} from 'app/models/rule';
import {Observable} from 'rxjs';
import {environment} from '../../../environments/environment';
import {DaAppInstall} from '../../models/tps/da-app-install';
import {AuthService} from '../auth.service';

const appModel = 'daappinstalls';
const base = environment.tpsBaseUrl;

@Injectable()
export class DaAppInstallService {

  constructor(
    private _http: HttpClient,
    private _auth: AuthService,
  ) {
  }

  getAll = (filter?: Object): Observable<DaAppInstall[]> =>
    this._http.get<DaAppInstall[]>(
      `${base}/${appModel}?filter=${JSON.stringify(filter)}`,
      {headers: this.getHeaders()},
    );

  get = (id: string, filter?: Object): Observable<DaAppInstall> =>
    this._http.get<DaAppInstall>(
      `${base}/${appModel}/${id}?filter=${JSON.stringify(filter)}`,
      {headers: this.getHeaders()},
    );

  insert = (daAppInstall: DaAppInstall): Observable<DaAppInstall> =>
    this._http.post<DaAppInstall>(
      `${base}/${appModel}`, daAppInstall,
      {headers: this.getHeaders()},
    );

  update = (id: string, daAppInstall: DaAppInstall): Observable<DaAppInstall> =>
    this._http.patch<DaAppInstall>(
      `${base}/${appModel}/${id}`, daAppInstall,
      {headers: this.getHeaders()},
    );

  delete = (id: string): Observable<{ count: boolean }> =>
    this._http.delete<{ count: boolean }>(
      `${base}/${appModel}/${id}`,
      {headers: this.getHeaders()},
    );

  updateRelation = (id: string, model: Discount | Rule, modelName: string, add: boolean = true) => {
    const u = `${base}/${appModel}/${id}/${modelName}s/rel/${model._id}`;
    const h = {headers: this.getHeaders()};
    return add
      ? this._http.put<DiscountLink | RuleLink>(u, {}, h)
      : this._http.delete<null>(u, h);
  }

  private getHeaders = () => this._auth.getJWTHeaders();
}
