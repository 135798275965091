import {Component, Input, OnInit} from '@angular/core';
import {Ride} from '../../models/ride';
import {TranslateService} from '@ngx-translate/core';
import {Globals} from '../../../globals';
import {RideService} from '../../services/ride.service';

@Component({
  selector: 'ride-status-widget',
  templateUrl: './ride-status-widget.component.html',
  styleUrls: ['./ride-status-widget.component.scss']
})
export class RideStatusWidgetComponent implements OnInit {

  @Input() type = 'driver';
  @Input() companyId: string;
  @Input() driverId: string;

  @Input() buttonLink: string;
  @Input() buttonText: string;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() cssClasses = '';

  @Input() colorScheme: any;

  @Input() loading = true;

  breakdown: any[] = [
    {
      'name': 'Canceled',
      'series': []
    },
    {
      'name': 'Completed',
      'series': []
    }
  ];

  defaultCssClasses = 'mat-card';

  xAxisLabel: string;
  yAxisLabel: string;
  filterValues: any[];
  filterRange: number;

  rides: Ride[] = [];

  constructor(private _translateService: TranslateService,
              private _globals: Globals,
              private _rideService: RideService) {
    this.filterValues = _globals.widgetFilterValues;
    this.filterRange = this.filterValues[0].value;
    _translateService.get('rides').subscribe((translation: string) => {
      this.yAxisLabel = translation;
    });
  }

  ngOnInit() {
    this.loadData();
  }

  resetBreakdown(): void {
    this.breakdown = [
      {
        'name': 'Completed',
        'series': []
      },
      {
        'name': 'Canceled',
        'series': []
      },
    ];
  }

  loadData(event?: any) {
    const now = new Date();
    const from = new Date(now.getTime() - this.filterRange);

    from.setSeconds(1);
    from.setMinutes(0);
    from.setHours(0);

    const filter: any = {
      'where': {
        'requestedDate': {
          'between': [
            from,
            new Date()
          ]
        }
      },
      'order': 'requestedDate ASC'
    };

    if (this.type === 'company') {
      filter.where.ownerId = this.companyId;
      filter.where.ownerType = 'Company';
    } else {
      filter.where.ownerId = this.driverId;
      filter.where.ownerType = 'Driver';
    }

    this._rideService.getAll(filter, this.type).subscribe((rides) => {
      this.rides = rides;
      this.formatData();
    }, (error) => {
      console.log('Error:', error);
    })
  }

  formatData() {
    if (this.filterRange <= 86400000) {
      this._translateService.get('hours').subscribe((translation: string) => {
        this.xAxisLabel = translation;
      });
    } else {
      this._translateService.get('days').subscribe((translation: string) => {
        this.xAxisLabel = translation;
      });
    }

    if (this.rides.length > 0) {
      this.resetBreakdown();
      let currentDay = '';

      let currentCompletedData = {
        name: '',
        value: 0
      };
      let currentCanceledData = {
        name: '',
        value: 0
      };

      for (let i = 0; i < this.rides.length; i++) {
        /**
         * Figure out how many rides we have per month for the last twelve months
         */
        const rideDate = new Date(this.rides[i].requestedDate);
        const monthNumber = rideDate.getMonth() + 1;
        const dayNumber = rideDate.getDate();
        const day = '' + (dayNumber < 10 ? '0' + dayNumber : dayNumber);
        const month = '' + (monthNumber < 10 ? '0' + monthNumber : monthNumber);
        // const month = rideDate.getMonth();
        // const year = rideDate.getFullYear();

        /**
         * Set up the first month
         */
        if (i === 0) {
          currentCompletedData.name = day + '-' + month;
          currentCanceledData.name = day + '-' + month;
          currentDay = day;
        }

        /**
         * Check if we're in the same month or not and push data if not
         */
        if (day === currentDay) {
          if (this.rides[i].status.toLowerCase() === 'completed') {
            currentCompletedData.value = currentCompletedData.value + 1;
          } else if (this.rides[i].status.toLowerCase() === 'canceled' ||
            this.rides[i].status.toLowerCase() === 'canceled_driver' ||
            this.rides[i].status.toLowerCase() === 'canceled_passenger') {
            currentCanceledData.value = currentCanceledData.value + 1;
          }
        } else {
          this.breakdown[1].series.push(currentCanceledData);
          this.breakdown[0].series.push(currentCompletedData);

          currentCompletedData = {
            name: day + '-' + month,
            value: 0
          };
          currentCanceledData = {
            name: day + '-' + month,
            value: 0
          };

          if (this.rides[i].status.toLowerCase() === 'completed') {
            currentCompletedData.value = currentCompletedData.value + 1;
          } else if (this.rides[i].status.toLowerCase() === 'canceled' ||
            this.rides[i].status.toLowerCase() === 'canceled_driver' ||
            this.rides[i].status.toLowerCase() === 'canceled_passenger') {
            currentCanceledData.value = currentCanceledData.value + 1;
          }

          currentDay = day;
        }

        /**
         * Push the last data to the array
         */
        if (i >= this.rides.length - 1) {
          this.breakdown[1].series.push(currentCanceledData);
          this.breakdown[0].series.push(currentCompletedData);
        }
      }
    }
  }

}
