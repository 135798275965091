import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContractService} from '../../../../../services/contract.service';
import {TdDialogService} from '@covalent/core/dialogs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-cancel-purchase-dialog',
  templateUrl: './cancel-purchase-dialog.component.html',
  styleUrls: ['./cancel-purchase-dialog.component.scss']
})
export class CancelPurchaseDialogComponent implements OnInit {
  form: any

  constructor(
    private _formBuilder: UntypedFormBuilder,
    public _dialogService: TdDialogService,
    public _translateService: TranslateService,
    public _dialogRef: MatDialogRef<CancelPurchaseDialogComponent>,
    public _contractService: ContractService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      reason: ['', Validators.required]
    })
  }

  cancelContract() {
    this.form.validateAllFormFields();

    if (this.form.valid) {
      this._contractService.cancelContract(this.data.parent.activeContract.id, this.form.value.reason).subscribe(() => {
        this._dialogRef.close();
      }, (error) => {
        this._dialogService.openAlert({
          message: this._translateService.instant(`cancel_contract_error_${error.error.error.name.toLowerCase()}`),
        })
        this._dialogRef.close();
      })
    }
  }
}
