import {Contract} from './contract';

export class GoogleKey {
  active: boolean;
  key: string;
}

export class Company {
  name?: string;
  phoneNumber?: string;
  email?: string;
  website?: string;
  address?: string;
  addressExtra?: string;
  city?: string;
  country?: string;
  language?: string;
  timezone?: string;
  state?: string;
  postalCode?: string;
  locale?: string;
  foreignId?: number;
  id?: string;
  userId?: string;
  ownerId?: string;
  ownerType?: string;
  currency?: string;
  type?: string;
  vatNumber?: string;
  vatType?: string;
  vatPercentage?: string;
  settlementVatPercentage?: string;
  dateFormat?: string;
  clockFormat?: string;
  avatar?: any;
  settings?: any;
  googleKey?: GoogleKey;
  products?: any;
  hasDispatcherLicense?: boolean;
  hadTrial?: boolean;
  protected?: boolean;
  ActiveContract?: Contract;
  Contract?: Contract;
  Owner?: any;
  apps?: string[];
  created?: Date;
  target?: string;
  stripeAccountId?: any;
  wipayAccountId? : any;
  mspAccountId? : any;
}
