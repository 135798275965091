import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {LoginComponent} from './portal/login/login.component';
import {MainComponent} from './layout/main/main.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {NotFoundComponent} from './portal/not-found/not-found.component';
import {AuthGuard} from '../guards/auth.guard';
import {ResetPasswordComponent} from './portal/reset-password/reset-password.component';
import {UserProfileComponent} from './portal/user-profile/user-profile.component';
import {CompanyCreateComponent} from './portal/company-create/company-create.component';
import {SignupComponent} from './portal/signup/signup.component';
import {VerifyEmailComponent} from './portal/verify-email/verify-email.component';
import {SignupSuccessfulComponent} from './portal/signup-successful/signup-successful.component';
import {StripeConnectComponent} from './portal/stripe-connect/stripe-connect.component';
import {ConnectEmailComponent} from './portal/connect-email/connect-email.component';
import {OnboardingWizardComponent} from './company/onboarding-wizard/onboarding-wizard.component';
import {NavigationService} from '../services/navigation.service';
import {CompanyResolver} from '../resolvers/company-resolver';

const routes: any[] = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: ':productId/login',
    component: LoginComponent
  },
  {
    path: 'redirect/:redirect',
    component: LoginComponent
  },
  {
    path: 'login/freetrial/:redirect',
    component: LoginComponent
  },
  {
    path: 'login/redirect/:redirect',
    component: LoginComponent
  },
  {
    path: 'login/:companyId/:redirect',
    component: LoginComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'signup/:signupStep',
    component: SignupComponent
  },
  {
    path: 'startTrial',
    component: SignupComponent
  },
  {
    path: 'starttrial',
    component: SignupComponent
  },
  {
    path: 'signup-successful',
    component: SignupSuccessfulComponent
  },
  {
    path: 'signup/:token',
    component: SignupComponent
  },
  {
    path: 'verify-email/:token',
    component: VerifyEmailComponent
  },
  {
    path: 'connect-email/:token',
    component: ConnectEmailComponent
  },
  {
    path: 'reset/:token',
    component: ResetPasswordComponent
  },
  {
    path: 'reset', component: ResetPasswordComponent
  },
  {
    path: 'stripeConnect',
    component: StripeConnectComponent
  },
  {
    path: 'onboarding-wizard/:id',
    component: OnboardingWizardComponent,
    canActivate: [AuthGuard],
    resolve: {
      currentRoute: NavigationService,
      company: CompanyResolver
    },
  },
  {
    path: 'onboarding-wizard/:id/:step',
    component: OnboardingWizardComponent,
    canActivate: [AuthGuard],
    resolve: {
      currentRoute: NavigationService,
      company: CompanyResolver
    },
  },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        // canActivate: [AuthGuard],
        pathMatch: 'full',
        path: '',
        redirectTo: 'login'
      },
      {
        canActivate: [AuthGuard],
        component: DashboardComponent,
        path: 'dashboard'
      },
      {
        canActivate: [AuthGuard],
        component: ResetPasswordComponent,
        path: 'profile/password'
      },
      {
        canActivate: [AuthGuard],
        component: UserProfileComponent,
        path: 'profile'
      },
      {
        canActivate: [AuthGuard],
        component: CompanyCreateComponent,
        path: 'profile/createGroup'
      },
      {
        component: NotFoundComponent,
        path: '**',
      }
    ]
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
