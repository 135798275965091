import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {NavigationService} from '../../../services/navigation.service';
import {environment} from '../../../../environments/environment';
import {AuthGuard} from '../../../guards/auth.guard';
import {CompanyRoutes} from '../../company/company.routes';
import {DriverService} from '../../../services/driver.service';
import {TranslateService} from '@ngx-translate/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {CompanyWebUserService} from '../../../services/companyWebUser.service';
import {CompanyCreateComponent} from '../../portal/company-create/company-create.component';
import {CompanyService} from '../../../services/company.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit, OnDestroy {
  @Input() layout: any;

  mainItems: any[] = [];
  companyItems: any[] = [];
  driver: any;

  mainItemsSubscription: Subscription;
  companyItemsSubscription: Subscription;
  driverSubscription: Subscription;
  CompanyWebUserSubscription: Subscription;

  companyItemGetter: any;

  constructor(private _navigationService: NavigationService,
              private _translateService: TranslateService,
              private _vault: CoolLocalStorage,
              private _driverService: DriverService,
              private _CompanyWebUserService: CompanyWebUserService,
              private _companyService: CompanyService,
  ) {
  }

  ngOnInit() {
    this.driver = JSON.parse(this._vault.getItem(`${environment.vaultPrefix}.driver`));
    this.getUserCompanies();
    this.mainItemsSubscription = this._navigationService.getMainMenuItems()
      .subscribe(items => {
        this.mainItems = items;
      });

    this.companyItemsSubscription = this._navigationService.getCompanyMenuItems()
      .subscribe(items => {
        this.companyItems = items;
      });
  }

  getUserCompanies() {
    const routes = [];
    this._translateService.get(['create_new_group']).subscribe((translations: string[]) => {
      this.CompanyWebUserSubscription = this._CompanyWebUserService.getCompanies().subscribe((CompanyWebUsers: any[]) => {
        if (CompanyWebUsers.length > 0) {
          CompanyWebUsers = CompanyWebUsers.filter(function (CompanyWebUser) {
            if (CompanyWebUser.Company) {
              CompanyWebUser.Company.name = CompanyWebUser.Company.name.charAt(0).toUpperCase() + CompanyWebUser.Company.name.slice(1);
              return CompanyWebUser;
            }
          });

          /**
           * Sort by company name
           */
          CompanyWebUsers = CompanyWebUsers.sort(function (a, b) {
            if (a['Company'] && b['Company']) {
              return (a['Company']['name'] > b['Company']['name']) ? 1 : (a['Company']['name'] < b['Company']['name']) ? -1 : 0;
            }
          });

          CompanyWebUsers.forEach((CompanyWebUser, index) => {
            if (CompanyWebUser.Company) {
              if (CompanyWebUser.type === 'CompanyWebUser') {
                CompanyRoutes.getRoutes(this._translateService, index, CompanyWebUser.Company, 'operator').then((r) => {
                  routes.push(r);
                  this._navigationService.setCompanyMenuItems(routes);
                })
                this._navigationService.setCompanyMenuItems(routes);
              } else {
                CompanyRoutes.getRoutes(this._translateService, index, CompanyWebUser.Company).then((r) => {
                  routes.push(r);
                  this._navigationService.setCompanyMenuItems(routes);
                });
              }
            }
          });
        }

        this._companyService.getCompanies().subscribe((ownerCompanies: any[]) => {
          this.driverSubscription = this._driverService.getCompanies().subscribe((drivers: any[]) => {
            if (drivers.length > 0 || ownerCompanies.length > 0) {
              drivers = drivers.filter(function (driver) {
                if (driver.Company && (!ownerCompanies || ownerCompanies.filter((c) => {
                  if (c.id === driver.Company.id) {
                    return c;
                  }
                }).length === 0)) {
                  driver.Company.name = driver.Company.name.charAt(0).toUpperCase() + driver.Company.name.slice(1);
                  return driver.Company;
                }
              });
              drivers = drivers.map((d) => {
                return d.Company
              });
              let companies = [...ownerCompanies, ...drivers];

              /**
               * Sort by company name
               */
              companies = companies.sort(function (a, b) {
                if (a && b) {
                  return (a['name'] > b['name']) ? 1 : (a['name'] < b['name']) ? -1 : 0;
                }
              });

              companies.forEach((company, index) => {
                if (environment.companyBuild) {
                  if (company.id === environment.companyId) {
                    routes.push({
                      path: 'groups/' + company.id,
                      icon: 'dashboard',
                      group: 'admin',
                      label: 'Dashboard',
                      sort: index,
                      showInMenu: true,
                      canActivate: [AuthGuard]
                    });
                    routes.push({
                      path: 'groups/' + company.id + '/jobs',
                      icon: 'assignment_ind',
                      group: 'admin',
                      label: 'Jobs',
                      sort: index,
                      showInMenu: true,
                      canActivate: [AuthGuard]
                    });
                    routes.push({
                      path: 'groups/' + company.id + '/members',
                      icon: 'group',
                      group: 'admin',
                      label: 'Members',
                      sort: index,
                      showInMenu: true,
                      canActivate: [AuthGuard]
                    });
                    routes.push({
                      path: 'groups/' + company.id + '/products',
                      icon: 'local_taxi',
                      group: 'admin',
                      label: 'Products',
                      sort: index,
                      showInMenu: true,
                      canActivate: [AuthGuard]
                    });
                    routes.push({
                      path: 'groups/' + company.id + '/pricing',
                      icon: 'euro_symbol',
                      group: 'admin',
                      label: 'Pricing',
                      sort: index,
                      showInMenu: true,
                      canActivate: [AuthGuard]
                    });
                    routes.push({
                      path: 'groups/' + company.id + '/locations',
                      icon: 'map',
                      group: 'admin',
                      label: 'Locations',
                      sort: index,
                      showInMenu: true,
                      canActivate: [AuthGuard]
                    });
                    routes.push({
                      path: 'groups/' + company.id + '/apps',
                      icon: 'apps',
                      group: 'admin',
                      label: 'Apps',
                      sort: index,
                      showInMenu: true,
                      canActivate: [AuthGuard]
                    });
                    if (environment.showCompanyInvoices) {
                      routes.push({
                        path: 'groups/' + company.id + '/invoices',
                        icon: 'euro_symbol',
                        group: 'admin',
                        label: 'Invoices',
                        sort: index,
                        showInMenu: true,
                        canActivate: [AuthGuard]
                      });
                    }
                    this._navigationService.setCompanyMenuItems(routes);
                  }
                } else {
                  if (company) {
                    if (company.type === 'dispatch') {
                      CompanyRoutes.getRoutes(this._translateService, index, company, 'dispatchgroup').then((r) => {
                        routes.push(r);
                        this._navigationService.setCompanyMenuItems(routes);
                      });
                    } else {
                      CompanyRoutes.getRoutes(this._translateService, index, company).then((r) => {
                        routes.push(r);
                        this._navigationService.setCompanyMenuItems(routes);
                      });
                    }
                  }
                }

                if (!companies[(index + 1)]) {
                  this._translateService.get(['create_new_group']).subscribe((t: string[]) => {
                    routes.push({
                      path: 'profile/createGroup',
                      icon: 'add',
                      label: t['create_new_group'],
                      canActivate: [AuthGuard],
                      group: 'groups',
                      component: CompanyCreateComponent,
                      sort: (index + 1),
                      showInMenu: true
                    });
                    this._navigationService.setCompanyMenuItems(routes);
                  });
                }
              });
            } else {
              routes.push({
                path: 'profile/createGroup',
                icon: 'add',
                label: translations['create_new_group'],
                canActivate: [AuthGuard],
                group: 'groups',
                component: CompanyCreateComponent,
                showInMenu: true
              });
              this._navigationService.setCompanyMenuItems(routes);
            }
          });
        });
      });
    });
  }

  ngOnDestroy() {
    if (this.mainItemsSubscription) {
      this.mainItemsSubscription.unsubscribe();
    }
    if (this.companyItemsSubscription) {
      this.companyItemsSubscription.unsubscribe();
    }
    if (this.driverSubscription) {
      this.driverSubscription.unsubscribe();
    }
    if (this.driverSubscription) {
      this.driverSubscription.unsubscribe();
    }
    clearInterval(this.companyItemGetter);
  }
}
