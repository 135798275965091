import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-contact-upsert-pane',
  templateUrl: './contact-upsert-pane.component.html',
  styleUrls: ['./contact-upsert-pane.component.scss']
})
export class ContactUpsertPaneComponent implements OnInit {
  @Input() parent;
  @Input() company;

  constructor() {
  }

  ngOnInit(): void {
  }

}
