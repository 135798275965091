<app-invoices-toolbar></app-invoices-toolbar>

<div class="pull-over-container">
  <mat-card ngClass="pull-over">
    <mat-card-title>{{'invoice_settings'|translate}}</mat-card-title>
    <mat-card-subtitle>{{'invoice_settings_subtitle'|translate}}</mat-card-subtitle>

    <mat-divider></mat-divider>

    <form #settingsForm="ngForm">
      <div class="push-left-sm push-right-sm first-row" layout="row">
        <mat-form-field class="push-left-sm push-right-sm" flex flex-gt-sm="50">
          <input [(ngModel)]="settings.companyName" matInput name="companyName"
                 placeholder="{{'company_name'|translate|ucFirst}}" required>
        </mat-form-field>
        <mat-form-field class="push-left-sm push-right-sm" flex flex-gt-sm="50">
          <input [(ngModel)]="settings.name" matInput name="name" placeholder="{{'name'|translate|ucFirst}}" required>
        </mat-form-field>
      </div>

      <div class="push-left-sm push-right-sm" layout="row">
        <mat-form-field class="push-left-sm push-right-sm" flex flex-gt-sm="50">
          <input [(ngModel)]="settings.cocNr" matInput name="cocNr" placeholder="{{'coc_nr'|translate|ucFirst}}"
                 required>
        </mat-form-field>
        <mat-form-field class="push-left-sm push-right-sm" flex flex-gt-sm="50">
          <input [(ngModel)]="settings.vatNr" matInput name="vatNr" placeholder="{{'vat_nr'|translate|ucFirst}}"
                 required>
        </mat-form-field>
      </div>

      <div class="push-left-sm push-right-sm" layout="row">
        <mat-form-field class="push-left-sm push-right-sm" flex flex-gt-sm="50">
          <input [(ngModel)]="settings.address1" matInput name="address1" placeholder="{{'address1'|translate|ucFirst}}"
                 required>
        </mat-form-field>
        <mat-form-field class="push-left-sm push-right-sm" flex flex-gt-sm="50">
          <input [(ngModel)]="settings.address2" matInput name="address2"
                 placeholder="{{'address2'|translate|ucFirst}}">
        </mat-form-field>
      </div>

      <div class="push-left-sm push-right-sm" layout="row">
        <mat-form-field class="push-left-sm push-right-sm" flex flex-gt-sm="50">
          <input [(ngModel)]="settings.city" matInput name="city" placeholder="{{'city'|translate|ucFirst}}" required>
        </mat-form-field>
        <mat-form-field class="push-left-sm push-right-sm" flex flex-gt-sm="50">
          <input [(ngModel)]="settings.country" matInput name="country" placeholder="{{'country'|translate|ucFirst}}"
                 required>
        </mat-form-field>
      </div>

      <div class="push-left-sm push-right-sm" layout="row">
        <mat-form-field class="push-left-sm push-right-sm" flex flex-gt-sm="50">
          <input [(ngModel)]="settings.phoneNumber" matInput name="phoneNumber"
                 placeholder="{{'phone_number'|translate|ucFirst}}" required>
        </mat-form-field>
        <mat-form-field class="push-left-sm push-right-sm" flex flex-gt-sm="50">
          <input [(ngModel)]="settings.email" matInput name="email" placeholder="{{'email'|translate|ucFirst}}"
                 required>
        </mat-form-field>
      </div>
    </form>

    <mat-divider></mat-divider>

    <mat-card-actions>
      <button (click)="onClickSave()" [disabled]="!settingsForm.valid" color="primary" mat-raised-button>Save settings
      </button>
    </mat-card-actions>
  </mat-card>
</div>
