<mat-card tdMediaToggle="gt-xs">
  <mat-card-title [className]="'mat-card-title filter'">
    {{'contacts'|translate|ucFirst}}

    <button (click)="addContact()" [className]="'mat-raised-button mat-primary tool'" color="primary"
            mat-raised-button>
      {{'add_contact'|translate|ucFirst}}
    </button>
  </mat-card-title>
  <mat-card-subtitle>{{'debtor_contacts_subtitle'|translate}}</mat-card-subtitle>
  <mat-divider></mat-divider>
  <td-search-box (searchDebounce)="search($event)" [alwaysVisible]="true" class="push-left push-right"
                 placeholder="{{'search_here'|translate}}"></td-search-box>
  <mat-divider></mat-divider>
  <ng-template tdLoading="{{activeLoaderName}}">
    <table class="mat-clickable" td-data-table>
      <thead>
      <tr td-data-table-column-row>
        <th (sortChange)="sort($event)"
            *ngFor="let c of columns"
            [active]="(sortBy == c.name)"
            [name]="c.name"
            [numeric]="c.numeric"
            [sortOrder]="( sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
            [sortable]="c.sortable"
            td-data-table-column>
          {{((c.name !== 'tools' ? c.label : '')|translate|ucFirst)}}
        </th>
      </tr>
      </thead>
      <tbody *ngIf="activeFilteredPassengers.length > 0">
      <tr *ngFor="let item of activeFilteredPassengers" td-data-table-row>
        <td *ngFor="let c of columns" [numeric]="item.numeric" class="{{(c.name=='tools'?'text-right':'')}}"
            td-data-table-cell>
              <span [ngSwitch]="c.name">
                <span *ngSwitchCase="'action'">
                  <button [matMenuTriggerFor]="menu" mat-icon-button>
                    <mat-icon>more_vert</mat-icon>
                  </button>

                  <mat-menu #menu="matMenu">
                    <button (click)="this.updatePassenger(item)" mat-menu-item>
                      <mat-icon>edit</mat-icon>
                      <span>{{'update'|translate|ucFirst}}</span>
                    </button>

                    <button (click)="this.removePassenger(item.connectionId)" mat-menu-item>
                      <mat-icon>delete</mat-icon>
                      <span>{{'remove'|translate|ucFirst}}</span>
                    </button>
                  </mat-menu>
                </span>
                <span *ngSwitchDefault>
                  <span *ngIf="item[c.name]">{{item[c.name]}}</span>
                  <span *ngIf="!item[c.name]">-</span>
                </span>
              </span>
        </td>
      </tr>
      </tbody>
      <tbody *ngIf="activeFilteredPassengers.length === 0">
      <tr>
        <td [colSpan]="columns.length"
            class="table-placeholder">
          {{'no_contacts_in_overview'|translate|ucFirst}}
        </td>
      </tr>
      </tbody>
    </table>
    <td-paging-bar #pagingBar (change)="page($event)" [pageSize]="pageSize" [total]="activeFilteredPassengerCount">
      <span hide-xs td-paging-bar-label>{{'rows_p_page'|translate}}:</span>
      {{pagingBar.range}} <span hide-xs>of {{pagingBar.total}}</span>
    </td-paging-bar>
  </ng-template>
</mat-card>
