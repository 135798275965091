import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ITdDataTableSortChangeEvent, TdDataTableService, TdDataTableSortingOrder} from '@covalent/core/data-table';
import {IPageChangeEvent} from '@covalent/core/paging';
import {TdLoadingService} from '@covalent/core/loading';
import {TdMediaService} from '@covalent/core/media';
import {Observable, Subscription} from 'rxjs';
import {DaAppInstall} from '../../../../models/da-app-install';
import {DaAppInstallService} from '../../../../services/da-app-install.service';
import {NavigationService} from 'app/services/navigation.service';
import {Company} from '../../../../models/company';
import {environment} from '../../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {ucFirst} from '../../../../pipes/uc-first.pipe';
import {BusinessLicenseComponent} from '../../../global/business-license/business-license.component';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from '../../../../services/utility.service';

@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.scss'],
  providers: [DaAppInstallService]
})
export class AppsComponent extends BusinessLicenseComponent implements OnInit, OnDestroy, AfterViewInit {
  data: Observable<DaAppInstall[]>;
  rawData: DaAppInstall[] = [];
  filteredData: DaAppInstall[] = [];
  routeSub: Subscription;
  companyId: string;
  context: string;
  company: Company;
  columns = [
    {name: 'name', label: 'name', sortable: true, active: true},
    {name: 'type', label: 'type', sortable: true, active: true},
  ];
  pageSize = 25;
  total: number;
  translations: string[];
  searchTerm = '';
  fromRow = 1;
  currentPage = 1;
  sortBy = 'name';
  sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;

  constructor(
    public media: TdMediaService,
    private _vault: CoolLocalStorage,
    private _daAppInstallService: DaAppInstallService,
    private _dataTableService: TdDataTableService,
    private _loadingService: TdLoadingService,
    private _navigationService: NavigationService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _translateService: TranslateService,
    private _titleService: Title
  ) {
    super();
    this.routeSub = this._route.parent.params.subscribe(params => {
      this.companyId = params['id'];
      const {company} = this._route.parent.snapshot.data;
      UtilityService.setBrowserTimeStamp(company);
      this.company = company;
      this.context = 'company';

      this.setBusinessContract(_vault);
      this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);
      this.data = this._daAppInstallService.getAll({
        where: {
          companyId: this.companyId
        },
        include: 'DaApp'
      }, 'company');
      this.startLoader();
    });

    _translateService.get(['apps']).subscribe((translations: any) => {
      this.translations = translations;
      this._titleService.setTitle(ucFirst(this.translations['apps'] + environment.windowTitleSuffix));
      this._navigationService.setBodyTitle(this.translations['apps']);
    });
  }

  ngOnInit() {
    this.loadData();
  }

  ngOnDestroy() {
    this.routeSub.unsubscribe();
  }

  ngAfterViewInit() {
    this.media.broadcast();
  }

  loadData() {
    this.data.subscribe((installs) => {
      const d = installs.map((e: DaAppInstall) => {
        e.name = (typeof e.settings !== 'undefined' && e.settings.name ? e.settings.name : e.DaApp.name);
        e.type = (typeof e.DaApp !== 'undefined' && e.DaApp.name ? e.DaApp.name : e.DaApp.name);
        return e;
      });
      this.rawData = d;
      this.filteredData = d;
      this.stopLoader();
    }, (error) => {
      console.error(`[AppsComponent.loadData]: error`, error);
    })
  }

  search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.filter();
  }

  sort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.sortBy = sortEvent.name;
    this.sortOrder = sortEvent.order;
    this.filter();
  }

  page(pagingEvent: IPageChangeEvent): void {
    this.fromRow = pagingEvent.fromRow;
    this.currentPage = pagingEvent.page;
    this.pageSize = pagingEvent.pageSize;
    this.filter();
  }

  filter(): void {
    let newData: any[] = this.rawData;
    newData = this._dataTableService.filterData(newData, this.searchTerm, true);
    this.total = newData.length;
    newData = this._dataTableService.sortData(newData, this.sortBy, this.sortOrder);
    newData = this._dataTableService.pageData(newData, this.fromRow, this.currentPage * this.pageSize);
    this.filteredData = newData;
  }

  click(id: string) {
    this._router.navigate([
      `/groups/${this.companyId}/apps/${id}/details`
    ]);
  }

  startLoader = () => this._loadingService.register('apps.list');
  stopLoader = () => this._loadingService.resolve('apps.list');
}
