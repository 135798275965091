import {NgModule, Pipe, PipeTransform} from '@angular/core';

function addLeadingZeros(str, targetLength) {
  return str.toString().padStart(targetLength, '0');
}

@Pipe({
  name: 'millisecondsToHours'
})
export class MillisecondsToHoursPipe implements PipeTransform {
  transform(value: number, args?: any): any {
    let seconds = Number((value / 1000).toFixed(0));
    let minutes = Math.floor(seconds / 60);
    let hours = 0;

    if (minutes > 59) {
      hours = Math.floor(minutes / 60);
      hours = Number((hours >= 10) ? hours : '0' + hours);
      minutes = minutes - (hours * 60);
      minutes = Number((minutes >= 10) ? minutes : '0' + minutes);
    }

    seconds = Math.floor(seconds % 60);
    seconds = Number((seconds >= 10) ? seconds : '0' + seconds);

    return addLeadingZeros(hours, 2) + ':' + addLeadingZeros(minutes, 2) + ':' + addLeadingZeros(seconds, 2);
  }
}

@NgModule({
  declarations: [MillisecondsToHoursPipe],
  exports: [MillisecondsToHoursPipe]
})
export class MillisecondsToHoursPipeModule {
}
