import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {Ride} from '../../../../../../../../../models/ride';
import {Clipboard} from '@angular/cdk/clipboard';
import {TdLoadingService} from '@covalent/core/loading';
import {TranslateService} from '@ngx-translate/core';
import {CompanyService} from '../../../../../../../../../services/company.service';

@Component({
  selector: 'app-active-automatic-dispatch',
  templateUrl: './active-automatic-dispatch.component.html',
  styleUrls: ['./active-automatic-dispatch.component.scss']
})
export class ActiveAutomaticDispatchComponent implements OnInit {
  @Input() ride: Ride;
  @Output() stopDispatch = new EventEmitter<string>();
  shareLink: string;
  subTitle: string;
  translations: string[];

  constructor(
    private clipboard: Clipboard,
    private _loadingService: TdLoadingService,
    private _translationService: TranslateService,
    private _companyService: CompanyService,
  ) {
    _translationService.get(['automatic_dispatch_active_subtitle'])
      .subscribe((translations: any) => {
        this.translations = translations;
        this.subTitle = this.translations['automatic_dispatch_active_subtitle'];
      });
  }

  ngOnInit(): void {
    this._loadingService.register('automatic-loader');
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['ride'].currentValue) {
      this.ride = changes['ride'].currentValue
      if (this.ride && this.ride.LastOffer && this.ride.LastOffer.deepLinkUrl) {
        this.shareLink = this.ride.LastOffer.deepLinkUrl;
      }

      this._companyService.get(this.ride.LastOffer.companyId)
        .subscribe((c) => {
          this.subTitle = this.translations['automatic_dispatch_active_subtitle'].replace('{groupname}', c.name);
        })
    }
  }

  copyToClipboard(): void {
    this.clipboard.copy(this.shareLink);
  }
}
