import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {environment} from '../../../../../../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';

const setTime = (date: Date, time: string) => {
  const parts = time.split(':');
  date.setHours(parseInt(parts[0], 10));
  date.setMinutes(parseInt(parts[1], 10));
  return date;
}

const getTime = (date: Date) => {
  const h = date.getHours();
  const m = date.getMinutes();
  const toStringRep = (int) => int < 10 ? '0' + int : '' + int;
  return toStringRep(h) + ':' + toStringRep(m);
}


@Component({
  selector: 'app-timeframes',
  templateUrl: './timeframes.component.html',
  styleUrls: ['./timeframes.component.scss'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'nl-NL'}
  ]
})
export class TimeframesComponent implements OnInit {

  @Input() timeframe: UntypedFormControl;

  startTime: string;
  endTime: string;
  startDate: Date;
  endDate: Date;

  hours = Array.from(Array(24).keys());
  days = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ]

  constructor(
    private _vault: CoolLocalStorage,
    private dateAdapter: DateAdapter<any>
  ) {
  }

  ngOnInit() {
    const user: any = this._vault.getObject(`${environment.vaultPrefix}.user`);
    this.dateAdapter.setLocale((user.locale ? user.locale : 'nl'));
    this.startTime = getTime(
      new Date(this.timeframe['controls']['startDate'].value));
    this.endTime = getTime(
      new Date(this.timeframe['controls']['endDate'].value));
  }

  specificWeekDays() {
    return !this.timeframe['controls'].isSpecificWeekDays.value || false;
  }

  timeInputChange(name, t) {
    if (!t.srcElement.value) {
      return
    }
    ;
    const newDate = setTime(
      new Date(this.timeframe['controls'][`${name}Date`].value), t.srcElement.value
    )
    newDate.setSeconds(59);
    this.timeframe['controls'][`${name}Date`].setValue(newDate.toISOString());
    this.timeframe['controls'][`${name}Date`].markAsTouched();
    this.timeframe['controls'][`${name}Date`].markAsDirty();
  }

  getCharArrayFromString(str: string | string[]) {
    if (Array.isArray(str)) {
      return str;
    }
    return str.split('');
  }

  toggleCharAt(n: number) {
    const updatedSchedule = this.getCharArrayFromString(
      this.timeframe['controls'].weekSchedule.value);
    updatedSchedule[n] = updatedSchedule[n] === '1' ? '0' : '1';

    this.timeframe['controls'].weekSchedule.setValue(updatedSchedule.join(''));
    this.timeframe['controls'].weekSchedule.markAsTouched();
    this.timeframe['controls'].weekSchedule.markAsDirty();
  }

  getCharAt(n: number) {
    return this.timeframe['controls'].weekSchedule.value[n];
  }
}
