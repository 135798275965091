import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-stripe-dialog',
  templateUrl: './stripe-dialog.component.html',
  styleUrls: ['./stripe-dialog.component.scss']
})
export class StripeDialogComponent implements OnInit {
  myData: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.myData = data.parent;
  }

  ngOnInit(): void {
    // @ts-ignore
    const stripe = Stripe('pk_test_51Hk3p7AAAqH6BJkpNpMpwWtSOW5HnpzPXNYImfzbuZilQtW7PAZ0bZcadDSPyul7CT9gEDrrEtt70JcMXcdPq9h3004NacQkCX');
    stripe.redirectToCheckout({sessionId: this.myData.sessionId});
  }

}
