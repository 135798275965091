import {Component} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {FieldConfig} from '../../models/field-config.interface';
import {Field} from '../../models/field.interface';

@Component({
  selector: 'app-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss']
})
export class FormInputComponent implements Field {
  config: FieldConfig;
  group: UntypedFormGroup;
}
