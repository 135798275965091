import {Component, Injector, Input, OnInit} from '@angular/core';
import {TdMediaService} from '@covalent/core/media';
import {TdDialogService} from '@covalent/core/dialogs';
import {TranslateService} from '@ngx-translate/core';
import {TdLoadingService} from '@covalent/core/loading';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Title} from '@angular/platform-browser';
import {UtilityService} from '../../../../../../services/utility.service';
import {ShiftService} from '../../../../../../services/shift.service';
import {CollectionComponent} from '../../../../../global/collection/collection.component';
import {DriverService} from '../../../../../../services/driver.service';

@Component({
  selector: 'app-recent-shifts',
  templateUrl: './recent-shifts.component.html',
  styleUrls: ['./recent-shifts.component.scss'],
  providers: [ShiftService, DriverService]
})
export class RecentShiftsComponent extends CollectionComponent implements OnInit {
  static translations: string[];
  @Input() companyId: string;
  @Input() driverId: string;
  @Input() context: string;
  actionName = 'loadToBeInvoiced';
  modelName = 'rides';
  columns = [
    {name: 'identifier', label: 'id', sortable: true, active: true},
    {name: 'startTime', label: 'start', sortable: true, active: false},
    {name: 'endTime', label: 'end', sortable: true, active: false},
    // { name: 'vehicle',        label: 'vehicle',       sortable: false,  active: false },
    {name: 'duration', label: 'duration', sortable: false, active: false},
    {name: 'activityCount', label: 'activities', sortable: false, active: false}
  ];
  type = 'all';
  paging = {
    'all': {
      fromRow: 0,
      currentPage: 0,
      pageSize: 5
    }
  };
  filteredData = {
    all: [],
    grouped: {},
  }
  selectFilter = {
    where: {
      driverId: '',
    },
    limit: 100,
    sort: 'created DESC'
  };

  constructor(public media: TdMediaService,
              public _dialogService: TdDialogService,
              public _translateService: TranslateService,
              protected injector: Injector,
              private loadingService: TdLoadingService,
              private _shiftService: ShiftService,
              private _driverService: DriverService,
              private vault: CoolLocalStorage,
              private _titleService: Title) {
    super(injector);
    const self = this;
    this.modelService = _shiftService;
    this._route.params.subscribe(params => {
      this._driverService.get(params['id'], {}).subscribe((cDriver) => {
        this._driverService.getAll({'where': {'phoneNumber': encodeURIComponent(cDriver.phoneNumber), 'companyId': null}}, 'company')
          .subscribe((driver) => {
            if (driver && driver[0]) {
              self.selectFilter.where.driverId = driver[0]['id'];
            } else {
              self.loadingService.register('shifts.list');
            }
            this.loadData(this.selectFilter);
          });
      });
    });
  }

  ngOnDestroy() {
    this._navigationService.setActionLink('');
    this._navigationService.setInfoAction('');
  }

  afterFilter() {
    this.originalData['all'].forEach((item) => {
      item.departure = UtilityService.getLocationString(item.departure);
      item.destination = UtilityService.getLocationString(item.destination);
      console.log(item.duration);
      if (!item.duration) {
        const start = new Date(item.startTime).getTime();
        const now = new Date(item.endTime).getTime();
        item.duration = (now - start);
      }
    });
  }
}
