<app-no-contract *ngIf="(this.context==='company' &&
  !this.user.ydaSupportUser &&
  (
    !this.company.ActiveContract ||
    !['active','past_due'].includes(this.company.ActiveContract.foreignStatus) ||
    this.company.ActiveContract.type === 'webbooker_license'
  ) &&
  (!this.company.hasDispatcherLicense || (this.company && this.company.ActiveContract.foreignStatus !== 'active')) &&
  this.company.type === 'business')"
                 [companyId]="this.companyId" [contract]="this.company.ActiveContract"
                 [type]="'fleet'"></app-no-contract>
<mat-card [class]="(this.company && this.context==='company' && !this.user.ydaSupportUser && (!this.company.ActiveContract ||
!['active','past_due'].includes(this.company.ActiveContract.foreignStatus) || this.company.ActiveContract.type ===
'webbooker_license') && (!this.company.hasDispatcherLicense || (this.company && this.company.ActiveContract.foreignStatus !== 'active'))  &&
this.company.type === 'business' ?
'blurText' :'')" [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <td-search-box (searchDebounce)="search($event)" [alwaysVisible]="true" class="push-left push-right"
                 placeholder="{{'search_here'|translate}}"></td-search-box>

  <app-driver-rides-filter (export)="export()"
                           [showExport]="true"
                           [exportType]="'drivers'"
                           [showStatusFilter]="false"
                           [showSearchFilter]="false"
                           [title]="'my_drivers'"></app-driver-rides-filter>

  <mat-divider></mat-divider>
  <ng-template tdLoading="drivers.list">
    <mat-tab-group (selectedIndexChange)="tabChanged($event)"
                   (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedTabIndex"
                   animationDuration="100ms">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="icon-right">group</mat-icon>
          {{(context === 'company' ? 'all_members' : 'drivers')|translate|ucFirst}}
          <span *ngIf="!this.searchTerm">&nbsp;({{this.allMemberCount}})</span>
          <span *ngIf="this.searchTerm">&nbsp;({{this.allFilteredData.length}}/{{this.allMemberCount}})</span>
        </ng-template>
        <table class="mat-clickable" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="sort($event)"
                *ngFor="let c of columns"
                [active]="(sortBy == c.name)"
                [name]="c.name"
                [numeric]="c.numeric"
                [sortOrder]="( sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
                [sortable]="c.sortable"
                class="{{c.maxSize}} {{c.maxWidth}} {{c.fixedSize}}"
                td-data-table-column>
              {{((c.name !== 'tools' ? c.label : '')|translate|ucFirst)}}
            </th>
          </tr>
          </thead>
          <tbody *ngIf="allFilteredData.length > 0">
          <tr *ngFor="let item of allFilteredData" td-data-table-row>
            <td (click)="(c.name!='tools' && !item.driverType &&
                  context === 'company'? open(item.id) : false)" *ngFor="let c of columns" [numeric]="item.numeric"
                class="{{(c.name=='tools'?'text-right':'')}} {{c.maxSize}} {{c.maxWidth}}" td-data-table-cell>
                <span [ngSwitch]="c.name">
                  <div *ngIf="!item.driverType && item.type !== 'owner' && context === 'company'">
                    <span *ngSwitchCase="'tools'">
                      <button [matMenuTriggerFor]="menu" mat-icon-button>
                        <mat-icon>more_vert</mat-icon>
                      </button>

                      <mat-menu #menu="matMenu">
                        <button
                          [routerLink]="'/'+(this.company.type === 'business' ? 'groups' : 'dispatchgroup')+'/'+companyId+'/drivers/'+item.id+'/details/'"
                          mat-menu-item>
                          <mat-icon>assignment</mat-icon>
                          <span>{{'driver_profile' | translate | ucFirst}}</span>
                        </button>
                        <button
                          *ngIf="this.company.type === 'business'" [routerLink]="'/'+(this.company.type === 'business' ? 'groups' :
                        'dispatchgroup')+'/'+companyId+'/drivers/'+item.id+'/shifts/'" mat-menu-item>
                          <mat-icon>assignment</mat-icon>
                          <span>{{'open_shift_details' | translate | ucFirst}}</span>
                        </button>
                        <button
                          [routerLink]="'/'+(this.company.type === 'business' ? 'groups' : 'dispatchgroup')+'/'+companyId+'/drivers/'+item.id+'/edit/'"
                          mat-menu-item>
                          <mat-icon>edit</mat-icon>
                          <span>{{'edit_member' | translate | ucFirst}}</span>
                        </button>
                        <mat-divider class="push-bottom-sm push-top-sm"></mat-divider>
                        <button (click)="denyMember(item.id)" mat-menu-item>
                          <mat-icon>do_not_disturb_on</mat-icon>
                          <span>{{'block_member'|translate}}</span>
                        </button>
                        <button (click)="deleteDriver(item.id,'driver')" mat-menu-item>
                          <mat-icon>delete</mat-icon>
                          <span>{{'delete_driver'|translate}}</span>
                        </button>
                      </mat-menu>
                    </span>
                  </div>

                  <div *ngIf="item.driverType && item.type !== 'owner' && context === 'company'">
                    <span *ngSwitchCase="'tools'">
                      <button [matMenuTriggerFor]="menu" mat-icon-button>
                        <mat-icon>more_vert</mat-icon>
                      </button>

                      <mat-menu #menu="matMenu">
                        <button (click)="editInvite(item.id)" mat-menu-item>
                          <mat-icon>edit</mat-icon>
                          <span>{{'edit_invite' | translate | ucFirst}}</span>
                        </button>
                        <button (click)="retractInvite(item.id)" mat-menu-item>
                          <mat-icon>assignment</mat-icon>
                          <span>{{'invite_retract' | translate | ucFirst}}</span>
                        </button>
                      </mat-menu>
                    </span>
                  </div>
                  <span
                    *ngSwitchCase="'type'"><span
                    *ngIf="item[c.name]">{{item[c.name] | translate | ucFirst}}</span></span>
                  <span
                    *ngSwitchDefault><span>{{(item[c.name] ? item[c.name] : '-')}}</span></span>

                  <span *ngSwitchCase="'companyStatusTxt'">
                    <span *ngIf="item[c.name]" [class]="item['state']">{{item[c.name] | translate | ucFirst}}</span>
                    <span class="last-seen"><br class="maxSize600">{{item['companyStatusTxtSub'] | ucFirst}}</span>
                  </span>
                </span>
            </td>
          </tr>
          </tbody>
          <tbody *ngIf="allFilteredData.length === 0">
          <tr>
            <td [colSpan]="columns.length"
                class="table-placeholder">
              {{'no_driver_found_start_inviting'|translate|ucFirst}}
            </td>
          </tr>
          </tbody>
        </table>
        <td-paging-bar #allPagingBar (change)="page($event)"
                       [pageSize]="pageSize"
                       [total]="(this.searchTerm ? this.allFilteredData.length : this.allMemberCount)">
          <span hide-xs>{{'rows_p_page'|translate}}:</span>
          {{allPagingBar.range}} <span hide-xs>of {{allPagingBar.total}}</span>
        </td-paging-bar>
      </mat-tab>
      <mat-tab
        *ngIf="this.company && this.company.ActiveContract
        && ['CloudConnect', 'fleet_solutions', 'free-trial'].includes(this.company.ActiveContract.type)">
        <ng-template mat-tab-label>
          <mat-icon class="icon-right">room</mat-icon>
          {{'driver_map'|translate|ucFirst}}
          <span *ngIf="!this.searchTerm">&nbsp;({{this.mapDrivers.length}})</span>
          <span *ngIf="this.searchTerm">&nbsp;({{this.filteredMapDrivers.length}}/{{this.mapDrivers.length}})</span>
        </ng-template>
        <mat-card-content>
          <div *ngIf="mapDrivers.length <= 2"
               class="map_driver_explanation">
            <mat-icon>help</mat-icon>
            {{'map_driver_addition' | translate | ucFirst}}</div>
          <ng-template tdLoading="driverlist.map">
            <agm-map (mapReady)="mapReady($event)"
                     (click)="mapControlled=true"
                     [latitude]="0"
                     [longitude]="0"
                     [maxZoom]="17"
                     [zoom]="2">

              <agm-marker
                (mouseOut)="toggleSize(infoWindow, false)"
                (mouseOver)="toggleSize(infoWindow, true)"
                *ngFor="let d of this.filteredMapDrivers"
                [agmFitBounds]="true"
                [iconUrl]="this.defaultMarkerIconUrl"
                [latitude]="d.ActiveVehicle.location.gps.lat"
                [longitude]="d.ActiveVehicle.location.gps.lng"
                [markerDraggable]="false">

                <agm-info-window #infoWindow [disableAutoPan]="true" [isOpen]="true" class='pointer'>
                  <div (mouseenter)="toggleSize(infoWindow, true)" *ngIf="!infoWindow.large" class="infoWindow">
                    <div>
                      <b [class]="d.ActiveVehicle.status.state">
                        {{d.name}}</b><br>
                    </div>
                    <div>{{'status'|translate|ucFirst}}: <b
                      [class]="d.ActiveVehicle.status.state">{{d.statusTxt|translate}}</b></div>
                  </div>
                  <div (mouseleave)="toggleSize(infoWindow, false)" *ngIf="infoWindow.large" class="infoWindow">
                    <div>
                      <b [class]="d.ActiveVehicle.status.state">{{d.ActiveVehicle.maxPassengers}} - {{d.name}}</b><br>
                      {{d.ActiveVehicle.licensePlate}} <br>
                      {{d.ActiveVehicle.brand}} {{d.ActiveVehicle.model}}
                    </div>
                    <div>{{'status'|translate|ucFirst}}: <b
                      [class]="d.ActiveVehicle.status.state">{{d.statusTxt|translate|ucFirst}}</b></div>
                  </div>
                </agm-info-window>
              </agm-marker>
            </agm-map>
          </ng-template>
        </mat-card-content>
      </mat-tab>
      <mat-tab *ngIf="context==='company' && this.company.type === 'business'">
        <ng-template mat-tab-label>
          <mat-icon class="icon-right">person</mat-icon>
          {{'web_operators'|translate}}
          <span *ngIf="!this.searchTerm">&nbsp;({{this.operatorCount}})</span>
          <span *ngIf="this.searchTerm">&nbsp;({{this.operatorFilteredData.length}}/{{this.operatorData.length}})</span>
        </ng-template>
        <table class="mat-clickable" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="operatorSort($event)"
                *ngFor="let c of limitColumns"
                [active]="(operatorSortBy == c.name)"
                [name]="c.name"
                [numeric]="c.numeric"
                [sortOrder]="( operatorSortOrder == 'ASC' ? 'DESC' : 'ASC' )"
                [sortable]="c.sortable"
                td-data-table-column>
              {{((c.name !== 'tools' ? c.label : '')|translate|ucFirst)}}
            </th>
          </tr>
          </thead>
          <tbody *ngIf="operatorFilteredData.length > 0">
          <tr *ngFor="let item of operatorFilteredData" td-data-table-row>
            <td (click)="(c.name!='tools' && !item.driverType && context === 'company'? openCompanyWebUser(item.id) :
                false)" *ngFor="let c of limitColumns" [numeric]="item.numeric"
                class="{{(c.name=='tools'?'text-right':'')}}"
                td-data-table-cell>
                <span [ngSwitch]="c.name">
                  <span *ngSwitchCase="'type'">{{item[c.name]|translate|ucFirst}}</span>
                  <span *ngSwitchCase="'is_driver'">{{item[c.name]|translate|ucFirst}}</span>
                  <span *ngSwitchCase="'emailAddress'">
                    <span *ngIf="item[c.name] === '-' && item['originalEmail'] !== '-'">
                      {{item['originalEmail']|translate}}
                      <span class="mat-icon-warning">
                        <mat-icon (click)="openDuplicateWarning(item, item['driverUserId']);"
                                  class="red">warning
                        </mat-icon>
                      </span>
                    </span>
                    <span *ngIf="item[c.name] !== '-'">{{item[c.name]|translate}}</span>
                  </span>
                  <span *ngIf="c.name === 'tools' && item['type'] !== 'owner'">
                    {{c['type']}}
                    <button [matMenuTriggerFor]="menu" mat-icon-button>
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button [routerLink]="'/groups/'+this.company.id+'/companyWebUsers/'+item.id+'/edit'"
                              mat-menu-item>
                        <mat-icon>edit</mat-icon>
                        <span>{{'edit'|translate}}</span>
                      </button>
                      <button (click)="deleteWebUser(item.id)" *ngIf="item['type'] !== 'owner'" mat-menu-item>
                        <mat-icon>do_not_disturb_on</mat-icon>
                        <span>{{'delete_operator_account'|translate}}</span>
                      </button>
                    </mat-menu>
                  </span>
                  <span *ngIf="c.name === 'tools' && item['type'] === 'owner' && item['userId'] === this.user.id">
                    {{c['type']}}
                    <button [matMenuTriggerFor]="menu" mat-icon-button>
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button (click)="changeOwner(item.id)" mat-menu-item>
                        <mat-icon>do_not_disturb_on</mat-icon>
                        <span>{{'change_owner'|translate|ucFirst}}</span>
                      </button>
                    </mat-menu>
                  </span>
                  <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span></span>
                </span>
            </td>
          </tr>
          </tbody>
          <tbody *ngIf="operatorFilteredData.length === 0">
          <tr>
            <td [colSpan]="columns.length"
                class="table-placeholder">
              {{'no_company_web_users_in_overview'|translate|ucFirst}}
            </td>
          </tr>
          </tbody>
        </table>
        <td-paging-bar #blockedPagingBar (change)="page($event)" [pageSize]="pageSize" [total]="operatorFilteredTotal">
          <span hide-xs td-paging-bar-label>{{'rows_p_page'|translate}}:</span>
          {{blockedPagingBar.range}} <span hide-xs>of {{blockedPagingBar.total}}</span>
        </td-paging-bar>
      </mat-tab>
      <mat-tab *ngIf="context==='company'">
        <ng-template mat-tab-label>
          <mat-icon class="icon-right">do_not_disturb_on</mat-icon>
          {{'blocked_members'|translate}}
          <span *ngIf="!this.searchTerm">&nbsp;({{this.blockedData.length}})</span>
          <span *ngIf="this.searchTerm">&nbsp;({{this.blockedFilteredData.length}}/{{this.blockedData.length}})</span>
        </ng-template>
        <table class="mat-clickable" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="sort($event)"
                *ngFor="let c of limitColumns"
                [active]="(sortBy == c.name)"
                [name]="c.name"
                [numeric]="c.numeric"
                [sortOrder]="( sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
                [sortable]="c.sortable"
                td-data-table-column>
              {{((c.name !== 'tools' ? c.label : '') | translate | ucFirst)}}
            </th>
          </tr>
          </thead>
          <tbody *ngIf="blockedFilteredData.length > 0">
          <tr *ngFor="let item of blockedFilteredData" td-data-table-row>
            <td (click)="(c.name!='tools' ? open(item.id) : false)" *ngFor="let c of limitColumns" [numeric]="item.numeric"
                class="{{(c.name=='tools'?'text-right':'')}}" td-data-table-cell>
                <span [ngSwitch]="c.name">
                  <span *ngSwitchCase="'companyStatusTxt'" [class]="item['state']">
                      <span *ngIf="item[c.name]">{{item[c.name] | translate | ucFirst}}</span>
                      <span class="last-seen"><br>{{item['companyStatusTxtSub'] | ucFirst}}</span>
                  </span>
                  <span *ngSwitchCase="'type'">{{item[c.name]|translate|ucFirst}}</span>

                  <span *ngSwitchCase="'tools'">
                    <button [matMenuTriggerFor]="menu" mat-icon-button>
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button (click)="allowMember(item)" mat-menu-item>
                        <mat-icon>check</mat-icon>
                        <span>{{'send_new_invite'|translate}}</span>
                      </button>
                      <button (click)="deleteDriver(item.id,'driver')" mat-menu-item>
                        <mat-icon>delete</mat-icon>
                        <span>{{'delete_driver'|translate|ucFirst}}</span>
                      </button>
                    </mat-menu>
                  </span>
                  <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span></span>
                </span>
            </td>
          </tr>
          </tbody>
          <tbody *ngIf="blockedFilteredData.length === 0">
          <tr>
            <td [colSpan]="columns.length"
                class="table-placeholder">
              {{'no_drivers_in_overview'|translate|ucFirst}}
            </td>
          </tr>
          </tbody>
        </table>
        <td-paging-bar #blockedPagingBar (change)="page($event)" [pageSize]="pageSize" [total]="blockedFilteredTotal">
          <span hide-xs td-paging-bar-label>{{'rows_p_page'|translate}}:</span>
          {{blockedPagingBar.range}} <span hide-xs>of {{blockedPagingBar.total}}</span>
        </td-paging-bar>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</mat-card>
