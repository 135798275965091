<mat-card-content *ngIf="(companies.length > 0 || operatorCompanies.length > 0 || companies.length > 0 ||
(this.driver &&
this.driver.Installation))"
                  class="push-bottom-none">
  <ng-template tdLoading="workspaceLoader">
    <mat-card-title>{{(this.parent.openFromLogin ? 'choose_workspace' : 'switch_workspace') | translate | ucFirst}}</mat-card-title>
    <mat-card-subtitle *ngIf="!this.parent.openFromLogin">{{(this.parent.openFromLogin ?
      'select_company_workspace_subtitle' : 'select_company_workspace_subtitle') | translate | ucFirst}}</mat-card-subtitle>

    <mat-nav-list class="push-bottom push-top">
      <mat-divider></mat-divider>
      <a class="login-link" href='#'></a>
      <div *ngIf="this.driver && this.driver.Installation">
        <a (click)="openWorkSpace('/personal/dashboard')" [class]="(!this.company ? 'login-link-active' : 'login-link')"
           [skipLocationChange]="false"
           mat-list-item>
          <mat-icon class="avatar-icon">person</mat-icon>
          {{'personal'|translate|ucFirst}}
        </a>
        <mat-divider></mat-divider>
      </div>

      <ng-template *ngIf="operatorCompanies" [ngForOf]="operatorCompanies" layout="row" let-item let-last="last" ngFor>
        <a (click)="openWorkSpace('/'+(item.type === 'admin' ? 'groups' : 'operator')+'/'+item.id+'/'+(item.target ?
        item.target : target)+'', item.id)"
           [class]="(this.company && this.company.id === item.id ? 'login-link-active' : 'login-link')"
           [skipLocationChange]="false"
           class="login-link" mat-list-item>
          <mat-icon class="avatar-icon">headset_mic</mat-icon>
          {{item.name}}
        </a>
        <mat-divider></mat-divider>
      </ng-template>

      <ng-template *ngIf="companies" [ngForOf]="companies" layout="row" let-item let-last="last" ngFor>
        <div [class]="(this.company && this.company.id === item.id ? 'login-link-active' : 'login-link')">
          <a (click)="openWorkSpace('/groups/'+item.id+'/'+(item.target ? item.target : target)+'', item.id)"
             [skipLocationChange]="false"
             mat-list-item>
            <mat-icon class="avatar-icon">people</mat-icon>
            {{item.name}}
          </a>

          <span class="workspace_submenu">
            <button [matMenuTriggerFor]="menu" mat-icon-button>
              <mat-icon>more_vert</mat-icon>
            </button>

            <mat-menu #menu="matMenu">
              <button (click)="deleteFleet(item)" *ngIf="item.type==='owner'" mat-menu-item>
                <mat-icon>assignment</mat-icon>
                <span>{{'delete_fleet' | translate | ucFirst}}</span>
              </button>
              <button (click)="leaveWorkspace(item.companyWebUserId)" *ngIf="item.type!=='owner'" mat-menu-item>
                <mat-icon>assignment</mat-icon>
                <span>{{'leave_workspace' | translate | ucFirst}}</span>
              </button>
            </mat-menu>
          </span>
        </div>

        <mat-divider></mat-divider>
      </ng-template>

      <a (click)="openSupportWorkSpace()" *ngIf="this.user && this.user.ydaSupportUser"
         class="login-link"
         mat-list-item>
        <mat-icon class="avatar-icon">headset_mic</mat-icon>
        {{'support_workspace_selection'|translate|ucFirst}}
      </a>
    </mat-nav-list>
  </ng-template>
</mat-card-content>

<a (click)="openWorkSpace('/profile/createGroup')" *ngIf="companies.length >= 1"
   class="create-new-group yda-link-blue"
   href="/profile/createGroup">{{'create_new_group'|translate|ucFirst}}
</a>

<a (click)="logout()"
   class="logout-link yda-link-blue">{{'logout'|translate|ucFirst}}
</a>

<mat-card-content *ngIf="companies.length < 1 && operatorCompanies.length < 1 && companies.length < 1 && !loading"
                  class="no-companies">
  <mat-card-title>{{'select_login_no_companies_title'|translate}}</mat-card-title>
  <ng-template class="push-left push-right" tdLoading="workspace">
    <br>{{(this.translations ? this.translations['select_login_no_companies_subtitle'] : '')}}
    <br><br>
    <button (click)="logout()" color="accent" mat-raised-button>{{'logout'|translate|ucFirst}}</button>
  </ng-template>
</mat-card-content>
