import {Ride} from './ride';
import {Company} from './company';
import {Passenger} from './passenger';
import {Driver} from './driver';

export class Rating {
  comment?: string;
  score: number;
  type?: string;
  id?: string;
  created?: Date;
  updated?: Date;
  driverId?: string;
  rideId?: string;
  userId?: string;
  passengerId?: string;
  companyId?: string;
  ownerId?: string;
  ownerType?: string;
  creatorId?: string;
  creatorType?: string;
  Ride?: Ride;
  Company?: Company;
  Passenger?: Passenger;
  Driver?: Driver;
  Owner?: any;
  Creator?: any;
}
