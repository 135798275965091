<div layout="column" layout-fill>
  <mat-toolbar *ngIf="!loggedIn && !this.verifyToken" class="mat-whiteframe-z1" color="primary">
    <a class="top-logo" routerLink="/login">
      <mat-icon class="mat-icon-logo" svgIcon="assets:logo"></mat-icon>
    </a>
    <span> | {{'reset_password'|translate|ucFirst}}</span>
  </mat-toolbar>

  <div *ngIf="!loggedIn" layout="column" layout-fill>
    <div class="mat-content" flex layout-padding>
      <div class="margin" layout-align-gt-xs="center start" layout-gt-xs="row">
        <div flex-gt-xs="50">
          <mat-card *ngIf="!this.token" [mediaClasses]="['push-top-lg']" tdMediaToggle="gt-xs">
            <mat-card-title *ngIf="!this.verifyToken">{{'reset_password'|translate}}</mat-card-title>
            <mat-card-subtitle *ngIf="!this.verifyToken">{{'reset_password_subtitle'|translate}}</mat-card-subtitle>

            <mat-card-title *ngIf="this.verifyToken">{{'set_password'|translate}}</mat-card-title>
            <mat-card-subtitle
              *ngIf="this.verifyToken">{{'new_user_email_verified_success'|translate}}</mat-card-subtitle>

            <mat-divider></mat-divider>
            <mat-card-content>
              <td-message *ngIf="showMessage" class="push-bottom" color="{{(error?'warn':'primary')}}"
                          icon="error" label="{{messageLabel}}" sublabel="{{messageText}}"></td-message>
              <form #loginForm="ngForm">
                <div layout="row">
                  <mat-form-field flex>
                    <input #userControl="ngModel" #userElement [(ngModel)]="username" matInput name="username"
                           placeholder="{{'email'|translate}}" required type="text">
                    <span matPrefix><mat-icon>person</mat-icon></span>
                    <mat-hint align="start">
                      <span [hidden]="!userControl.errors?.required || userControl.pristine"
                            class="tc-red-600">{{'required'|translate}}</span>
                    </mat-hint>
                  </mat-form-field>
                </div>
              </form>
            </mat-card-content>
            <mat-divider></mat-divider>
            <mat-card-actions layout="row">
              <button (click)="requestReset()" [disabled]="!loginForm.form.valid || this.resetRequested" color="accent" flex
                      mat-raised-button>{{'reset_password_button'|translate}}</button>
              <a class="forgot yda-link" flex routerLink="/login">{{'back_to_login'|translate}}</a>
              <span>|</span>
              <a *ngIf="!this.verifyToken" class="yda-link forgot" flex
                 href="https://yourdriverapp.zendesk.com/hc/en-us/requests/new"
                 target="_new">{{'contact_us'|translate|ucFirst}}</a>
            </mat-card-actions>
          </mat-card>
          <mat-card *ngIf="this.token" [mediaClasses]="['push-top-lg']" tdMediaToggle="gt-xs">
            <mat-card-title *ngIf="!this.verifyToken">{{'reset_password'|translate}}</mat-card-title>
            <mat-card-subtitle
              *ngIf="!this.verifyToken">{{'reset_password_token_subtitle'|translate}}</mat-card-subtitle>

            <mat-card-title *ngIf="this.verifyToken">{{'set_password'|translate}}</mat-card-title>
            <mat-card-subtitle
              *ngIf="this.verifyToken">{{'new_user_email_verified_success'|translate}}</mat-card-subtitle>
            <mat-divider></mat-divider>

            <mat-card-content>
              <td-message *ngIf="showMessage" class="push-bottom" color="{{(error?'warn':'primary')}}"
                          icon="error" label="{{messageLabel}}" sublabel="{{messageText}}"></td-message>
              <form [formGroup]="resetForm">
                <div layout="row">
                  <mat-form-field flex>
                    <input #firstPasswordElement (blur)="confirmPassword()" formControlName="password" matInput
                           placeholder="{{'password'|translate}}" type="password">
                    <mat-hint align="start">
                    <span [hidden]="!formErrors.password" class="tc-red-600">
                      <span>{{ formErrors.password }}</span>
                    </span>
                    </mat-hint>
                  </mat-form-field>
                </div>
                <div layout="row">
                  <mat-form-field flex>
                    <input #secondPasswordElement (blur)="confirmPassword()" formControlName="passwordConfirm"
                           matInput placeholder="{{'confirm_password'|translate}}"
                           type="password">
                    <mat-hint align="start">
                    <span [hidden]="!formErrors.passwordConfirm" class="tc-red-600">
                      <span>{{ formErrors.passwordConfirm }}</span>
                    </span>
                    </mat-hint>
                  </mat-form-field>
                </div>
              </form>
            </mat-card-content>
            <mat-divider></mat-divider>
            <mat-card-actions layout="row">
              <button (click)="reset()" [disabled]="!resetForm.valid" color="accent" flex
                      mat-raised-button>{{'set_new_password'|translate}}</button>

              <span *ngIf="!this.verifyToken">
                <a class="yda-link forgot" flex
                   routerLink="/login">{{'back_to_login'|translate}}</a>
                |
                <a class="yda-link forgot" flex
                   href="https://yourdriverapp.zendesk.com/hc/en-us/requests/new"
                   target="_new">{{'contact_us'|translate}}</a>
              </span>
            </mat-card-actions>
          </mat-card>
        </div>
      </div>
    </div>
  </div>

  <td-layout-card-over *ngIf="loggedIn"
                       cardSubtitle="{{'change_password_subtitle'|translate}}"
                       cardTitle="{{'change_password'|translate}}"
                       cardWidth="50"
                       layout-fill>
    <mat-card-content class="push-bottom-none">
      <ng-template tdLoading="reset">
        <form [formGroup]="resetForm">
          <div class="push-bottom-sm push-top-sm" layout="row">
            <mat-form-field flex>
              <input #firstPasswordElement (blur)="confirmPassword()" formControlName="password" matInput
                     placeholder="{{'password'|translate}}" type="password">
              <mat-hint align="start">
              <span [hidden]="!formErrors.password" class="tc-red-600">
                <span>{{ formErrors.password }}</span>
              </span>
              </mat-hint>
            </mat-form-field>
          </div>
          <div layout="row">
            <mat-form-field flex>
              <input #secondPasswordElement (blur)="confirmPassword()" formControlName="passwordConfirm"
                     matInput placeholder="{{'confirm_password'|translate}}"
                     type="password">
              <mat-hint align="start">
              <span [hidden]="!formErrors.passwordConfirm" class="tc-red-600">
                <span>{{ formErrors.passwordConfirm }}</span>
              </span>
              </mat-hint>
            </mat-form-field>
          </div>
        </form>
      </ng-template>
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-actions>
      <button (click)="update()" [disabled]="!resetForm.valid" color="accent" flex
              mat-raised-button>{{'update_password'|translate}}</button>
    </mat-card-actions>
  </td-layout-card-over>
</div>
