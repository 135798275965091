import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Ride} from 'app/models/ride';

@Component({
  selector: 'app-edit-payment-moment',
  templateUrl: './edit-payment-moment.component.html',
  styleUrls: ['./edit-payment-moment.component.scss']
})
export class EditPaymentMomentComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() openbalance: number;
  @Input() ride: Ride;

  constructor() {
  }

  ngOnInit(): void {
  }

}
