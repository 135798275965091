import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Debtor} from '../../../models/debtor';
import {TdDialogService} from '@covalent/core/dialogs';
import {TdLoadingService} from '@covalent/core/loading';
import {DebtorService} from '../../../services/debtor.service';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {NavigationService} from '../../../services/navigation.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ucFirst} from '../../../pipes/uc-first.pipe';
// import { PhoneNumberUtil as phoneUtil } from 'google-libphonenumber';
import {Driver} from '../../../models/driver';
import {CoolLocalStorage} from '@angular-cool/storage';

@Component({
  selector: 'app-debtor-upsert',
  templateUrl: './debtor-upsert.component.html',
  styleUrls: ['./debtor-upsert.component.scss'],
  providers: [DebtorService]
})
export class DebtorUpsertComponent implements OnInit {

  loaderName = 'debtor';
  routeSubscription: Subscription;
  translations: string[] = [];

  id: string;
  action: string;
  debtor: Debtor = new Debtor();
  driver: Driver;

  form: UntypedFormGroup;
  formErrors: any = {
    firstName: '',
    lastName: '',
    companyName: '',
    note: '',
    emailAddress: '',
    phoneNumber: '',
    city: '',
    streetName: '',
    postalCode: '',
    houseNumber: '',
    description: ''
  };
  validationMessages = {
    'phoneNumber': {
      'phoneNumberValidator': 'Phone number is invalid',
    }
  };

  constructor(public snackBar: MatSnackBar,
              private _loadingService: TdLoadingService,
              private _formBuilder: UntypedFormBuilder,
              private _route: ActivatedRoute,
              private _router: Router,
              private _vault: CoolLocalStorage,
              private _translateService: TranslateService,
              private _titleService: Title,
              private _dialogService: TdDialogService,
              private _navigationService: NavigationService,
              private _debtorService: DebtorService) {
    this.routeSubscription = this._route.params.subscribe(params => {
      this.id = params['id'];
      this.action = params['action'] ? params['action'] : params['id'];
      this.driver = this._vault.getObject(`${environment.vaultPrefix}.driver`);

      _translateService.get([
        `${this.action}_debtor`,
        'debtor_added_message',
        'debtor_added_title',
        'debtor_updated_message',
        'delete_x',
        'message_delete_x',
        'x_deleted'
      ]).subscribe((translations: any) => {
        this.translations = translations;
        this._titleService.setTitle(ucFirst(translations[`${this.action}_debtor`]) + environment.windowTitleSuffix);
        this._navigationService.setBodyTitle(ucFirst(translations[`${this.action}_debtor`]));
      });

      if (this.action === 'edit') {
        this.loadData();
      }
    });
  }

  ngOnInit(): void {
    console.log(`[DebtorUpsertComponent.ngOnInit]: DRIVER STUFF`);
    if (this.action !== 'add') {
      this._loadingService.register(this.loaderName);
    }

    /**
     * Set up our form
     */
    this.form = this._formBuilder.group({
      firstName: [null, []],
      lastName: [null, []],
      companyName: [null, []],
      note: [null, []],
      emailAddress: [null, []],
      phoneNumber: [null, []],
      address: this._formBuilder.group({
        city: [null, []],
        streetName: [null, []],
        postalCode: [null, []],
        houseNumber: [null, []],
        description: [null, []],
      })
    }, {validators: []});

    /**
     * Subscribe to value changes in the form
     */
    this.form.valueChanges.subscribe(() => this.onValueChanged());

    this.onValueChanged();
  }

  validateAllFormFields = (formGroup: UntypedFormGroup) => {
    Object.keys(formGroup.controls).forEach(field => {
      this.formErrors[field] = '';
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsDirty();
        if (control && !control.valid) {
          const messages = this.validationMessages[field];
          Object.keys(control.errors).forEach(key => {
            this.formErrors[field] += messages[key] + ' ';
          });
        }
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  };

  onValueChanged() {
    this.validateAllFormFields(this.form);
  }

  loadData(): void {
    console.log(`[DebtorUpsertComponent.loadData]: `);
    this._debtorService.get(this.id, {}).subscribe((debtor: Debtor) => {
      this.debtor = debtor;
      this.form.patchValue({
        firstName: debtor.firstName,
        lastName: debtor.lastName,
        companyName: debtor.companyName,
        note: debtor.note,
        emailAddress: debtor.emailAddress,
        phoneNumber: debtor.phoneNumber,
        address: {
          city: debtor.address ? debtor.address.city : '',
          streetName: debtor.address ? debtor.address.streetName : '',
          postalCode: debtor.address ? debtor.address.postalCode : '',
          houseNumber: debtor.address ? debtor.address.houseNumber : '',
          description: debtor.address ? debtor.address.description : '',
        }
      });
      this.onValueChanged();
      this._loadingService.resolve(this.loaderName);
    }, error => {
      console.error(error);
      this._loadingService.resolve(this.loaderName);
    })
  }

  delete(): void {
    const self = this;
    const name = `${this.debtor.firstName} ${this.debtor.lastName} (${this.debtor.companyName})`;
    const message = self.translations['message_delete_x'].formatUnicorn(name);
    const title = self.translations['delete_x'].formatUnicorn(name);
    alert('yy');
    self._dialogService.openConfirm({
      message: message,
      disableClose: false,
      title: title,
      cancelButton: 'Cancel',
      acceptButton: 'Delete',
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        self._debtorService.delete(self.debtor.id).subscribe(() => {
          self._dialogService.openConfirm({
            message: self.translations['x_deleted'].formatUnicorn(name),
            disableClose: true,
            acceptButton: 'Ok',
          }).afterClosed().subscribe(() => {
            this._router.navigate([`/personal/debtors`]);
          });
        });
      }
    });
  }

  save(): void {
    const self = this;
    const data = this.form.value;
    const debtor = this.debtor;

    delete debtor.id;
    delete debtor.modified;
    delete debtor.created;

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        debtor[key] = data[key];
      }
    }

    debtor.ownerId = this.driver.id;
    debtor.ownerType = 'Driver';

    /**
     * Call the API
     */
    if (this.action.toLowerCase() === 'add') {
      this._loadingService.register(this.loaderName);
      this._debtorService.insert(debtor).subscribe(() => {
        setTimeout(function () {
          self._dialogService.openAlert({
            message: ucFirst(self.translations['debtor_added_message']),
            disableClose: true,
            title: ucFirst(self.translations['debtor_added_title']),
            closeButton: 'OK'
          }).afterClosed().subscribe(() => {
            self._router.navigate([`/debtors`]);
          });
        }, 500);
      }, error => {
        console.error(error);
        this._loadingService.resolve(this.loaderName);
      })
    } else {
      this._debtorService.update(this.id, debtor).subscribe(() => {
        setTimeout(function () {
          self._loadingService.resolve(self.loaderName);
          const notification = self.snackBar.open(ucFirst(self.translations['debtor_updated_message']), 'OK', {
            duration: 3000
          });
        }, 500);
      }, error => {
        console.error(error);
        this._loadingService.resolve(this.loaderName);
      })
    }
  }

}
