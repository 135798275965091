import {Component, ElementRef, Injector, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TdDataTableService, TdDataTableSortingOrder} from '@covalent/core/data-table';
import {TdLoadingService} from '@covalent/core/loading';
import {Discount} from 'app/models/discount';
import {NavigationService} from 'app/services/navigation.service';
import {DiscountService} from 'app/services/tps/discount.service';
import {forkJoin} from 'rxjs';
import {Rule} from '../../../../models/rule';
import {PricingRuleService} from '../../../../services/tps/pricing-rule.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CollectionComponent} from '../../../global/collection/collection.component';
import {DaAppInstallService} from '../../../../services/da-app-install.service';
import {PricingRuleLinkService} from '../../../../services/tps/pricing-rule-link.service';
import {WebbookerService} from '../../../../services/webbooker.service';
import {environment} from '../../../../../environments/environment';
import {User} from '../../../../models/user';
import {Company} from '../../../../models/company';
import {GlobalFunctions} from '../../../../functions/functions';

const kebabCase = string =>
  string.replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/\s+/g, '-')
    .toLowerCase();

interface Collection {
  [key: string]: any;
}

const types = {
  rules: 'rules',
  discounts: 'special-rates',
}

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss'],
  providers: [PricingRuleService, DiscountService]
})
export class PricingComponent extends CollectionComponent
  implements OnInit, OnDestroy {
  priceError: string;
  removeEventListener: any;

  webbookers: any[];
  company: Company;
  user: User;
  UPDATED_MSG = 'Updated priority.';
  hasError = false;
  days = [
    'mon', 'thu', 'wed', 'thr', 'fri', 'sat', 'sun'
  ];

  columns = [
    {name: 'priority', label: 'priority', sortable: false},
    {name: 'name', label: 'name', sortable: false, active: true},
    {name: 'type', label: 'type', sortable: false},
    {name: 'startDate', label: 'start', sortable: false},
    {name: 'endDate', label: 'end', sortable: false},
    {name: 'apps', label: 'connected_apps', sortable: false, maxSize: 'max-width-400'},
  ];
  public data: Collection;
  sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;
  protected tab: 'rules' | 'discounts';
  private drag: any;
  private yPos: number;
  private yOrigin: number;
  private rowHeight: number;
  private daAppInstallArray = [];

  constructor(
    private _webbookerService: WebbookerService,
    protected _daAppInstallService: DaAppInstallService,
    protected _pricingRuleService: PricingRuleService,
    protected _pricingRuleLinkService: PricingRuleLinkService,
    protected _discountService: DiscountService,
    protected injector: Injector,
    protected _route: ActivatedRoute,
    protected _router: Router,
    protected _snackbar: MatSnackBar,
    protected _loadingService: TdLoadingService,
    protected _dataTableService: TdDataTableService,
    protected _navigationService: NavigationService,
    private renderer: Renderer2,
    private elRef: ElementRef
  ) {
    super(injector);
    // Service that speaks with the api, must have a function
    // called getAll, that fetches all rows
    this.sortOrder = TdDataTableSortingOrder.Ascending;
    this.rowHeight = 48;
    this.sortBy = 'priority';
    this.tab = 'rules';

    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);
    let company;
    if (this._route.parent && this._route.parent.snapshot && this._route.parent.snapshot.data && this._route.parent.snapshot.data.company) {
      company = this._route.parent.snapshot.data.company;
    } else {
      company = this._route.data;
    }
    this.company = company;
    const user: any = this._vault.getObject(`${environment.vaultPrefix}.user`);
    this.user = user;

    this._route.parent.params.subscribe(params => {
      this.companyId = params['id'];
      this.setLink(types[this.tab]);
      this.setTitle(types[this.tab]);
      this.loadData();
    });
  }

  ruleFrontendName = type =>
    Rule.ruleTypeNamesMapping(type);

  ngOnInit() {
    this._loadingService.register('pricing');
  }

  ngAfterViewInit() {
    const self = this;
    setTimeout(function () {
      self.removeEventListener = self.renderer.listen(self.elRef.nativeElement, 'click', (event) => {
        if (event && event.target && event.target && event.target.attributes && event.target.attributes.link) {
          if (event.target instanceof HTMLAnchorElement) {
            // Prevent opening anchors the default way
            event.preventDefault();
            // Your custom anchor click event handler
            self.openLink(event);
          }
        }
      });
    }, 1000);
  }

  openLink(event) {
    this._router.navigate([
      `/groups/${this.companyId}/${event.target.attributes.link.nodeValue}`
    ]);
  }

  ngOnDestroy() {
    this._navigationService.setActionLink('');
    if (this.removeEventListener) {
      this.removeEventListener();
    }
  }

  /**
   * Load data from the api, sorting into different types.
   */
  loadData = () => {
    forkJoin([
      this._pricingRuleService.getAll({
        where: {
          companyId: this.companyId,
          or: [
            {parentRuleId: {exists: false}},
            {parentRuleId: null}
          ]
        },
        include: 'ruleLinks'
      }),
      this._discountService.getAll({
        where: {
          companyId: this.companyId
        }
      }),
    ]).subscribe(
      (results: [Rule[], Discount[]]) => {
        this.data = {
          rules: results[0],
          discounts: results[1],
        }

        let installs;
        this._daAppInstallService.getAll({where: {companyId: this.companyId}}, 'company')
          .subscribe((i) => {
            installs = i;
            const mapInstalls = i.map((mI) => {
              return mI.id;
            });
            if (this.data.rules) {
              this.data.rules.forEach((r, index) => {
                let daAppInstallArray = [];
                if (r.ruleLinks) {
                  daAppInstallArray = r.ruleLinks.map((l) => {
                    // return ((l.isFixed || l.isAllowedOnMeter) ? l.ruleableId : false);
                    return ((l.isFixed) ? l.ruleableId : false);
                  });
                }
                daAppInstallArray = daAppInstallArray.filter((d) => {
                  return (d);
                });
                daAppInstallArray = daAppInstallArray.sort((a, b) => {
                  if (a) {
                    return a.localeCompare(b)
                  } else {
                    return -1;
                  }

                });
                daAppInstallArray.forEach((d) => {
                  if (mapInstalls.includes(d)) {
                    const i = mapInstalls.indexOf(d);
                    const install = installs[i];
                    if (r['apps']) {
                      r['apps'] = `${r['apps']}, ${install.settings.name}`;
                      r['tooltip'] = `${r['tooltip']}
                              * ${install.settings.name}`;
                    } else {
                      r['apps'] = `${install.settings.name}`;
                      r['tooltip'] = `* ${install.settings.name}`;
                    }
                  }
                })

                if (this.data.rules[index].timeframes && this.data.rules[index].timeframes[0]) {
                  this.data.rules[index].startDate = this.data.rules[index].timeframes[0].startDate;
                }
                if (this.data.rules[index].timeframes && this.data.rules[index].timeframes[0]) {
                  this.data.rules[index].endDate = this.data.rules[index].timeframes[0].endDate;
                }
                // }
              });

              this.data.rules = this.data.rules.filter((r, x) => {
                return (x === 120 || true)
              });
            }
            if (this.data.rules && this.data.rules[0] && this.data.rules[0].ruleLinks) {
              this.daAppInstallArray = this.data.rules[0].ruleLinks.map((l) => {
                // return ((l.isFixed || l.isAllowedOnMeter) ? l.ruleableId : false);
                return ((l.isFixed) ? l.ruleableId : false);
              });
              // this.autoRecovery();
            }
          });

        // this.checkPriorityOrder();
        this.sort();
        GlobalFunctions.checkForErrors(this._daAppInstallService, this._vault, this._webbookerService, this.company, this.data.rules).then((errors) => {
          this.priceError = errors;
        })
      },
      (error: any) => {
        console.error(`[PricingComponent]: Error:`, error);
        this.hasError = true;
        this.stopLoader();
      },
      () => this.stopLoader()
    )
  }

  autoRecovery = () => {
    const self = this;
    console.groupCollapsed('autoRecovery');
    forkJoin([
      this._pricingRuleService.getAll({
        where: {
          companyId: this.companyId,
        },
        include: ['ruleLinks', 'prices', {
          'relation': 'childRules',
          'scope': {
            'include': ['ruleLinks', 'prices']
          }
        }]
      }),
      this._discountService.getAll({
        where: {
          companyId: this.companyId
        }
      }),
    ]).subscribe(
      (results: [Rule[], Discount[]]) => {
        this.data = {
          rules: results[0].filter((p) => {
            return !(p.parentRuleId)
          }),
          discounts: results[1],
        }

        let installs;
        this._daAppInstallService.getAll({where: {companyId: this.companyId}}, 'company')
          .subscribe((i) => {
            installs = i;
            const mapInstalls = i.map((mI) => {
              return mI.id;
            });

            if (this.data.rules) {
              this.data.rules.forEach((r) => {
                const hasChildRecover = r.childRules.filter((r2) => {
                  return (r.ruleLinks.length !== r2.ruleLinks.length)
                });

                if (hasChildRecover && hasChildRecover.length > 0) {
                  hasChildRecover.forEach((r2) => {
                    if (r2._id === '645cf60688bae359a8fbb70b' && false) {
                      r.ruleLinks.forEach((l) => {
                        console.log({
                          'ruleableType': 'DaAppInstall',
                          'ruleableId': l.ruleableId,
                          'ruleId': r2._id
                        });
                        this._pricingRuleLinkService.getAll({
                          where: {
                            'ruleableType': 'DaAppInstall',
                            'ruleableId': l.ruleableId,
                            'ruleId': r2._id
                          }
                        }).subscribe((duplicates: any) => {
                          if (duplicates) {
                            const promiseArray = [];
                            console.log(duplicates);

                            duplicates.forEach((dup) => {
                              promiseArray.push(new Promise<void>((resolve) => {
                                this._pricingRuleLinkService.delete(dup._id, 'company').subscribe(() => {
                                  resolve();
                                })
                              }));
                            });

                            Promise.all(promiseArray).then(() => {
                              this._pricingRuleLinkService.insert({
                                'isAllowedOnMeter': l.isAllowedOnMeter,
                                'isFixed': l.isFixed,
                                'ruleableType': l.ruleableType,
                                'ruleableId': l.ruleableId,
                                'ruleId': r2._id,
                                'companyId': l.companyId
                              }).subscribe(() => {

                              });
                            })
                          }
                        });
                      });
                    }
                  });
                }
              });
            }
          });
        this.sort();
      },
      (error: any) => {
        console.error(`[PricingComponent.]: Error:`, error);
        this.hasError = true;
        this.stopLoader();
      },
      () => {
        this.stopLoader();
        console.groupEnd();
      }
    )
  }

  setLink(name: string) {
    this._navigationService.setActionLink(
      `/groups/${this.companyId}/pricing/${name}/add`
    );
  }

  setTitle(name: string) {
    this._navigationService.setBodyTitle(
      'Pricing ' + name.replace(/-/g, ' ')
    );
  }

  onTabChange(event) {
    this.tab = event.tab.textLabel;
    this.setTitle(types[this.tab]);
    this.setLink(types[this.tab]);
  }

  click(id: string) {
    this._router.navigate([
      `/groups/${this.companyId}/pricing/${types[this.tab]}/${id}/details`
    ]);
  }

  getPos = (id: string) => this.drag && id === this.drag._id ? this.yPos : 0;
  drop = (event: any, item: any) => {
    if (!this.yPos && item && item._id) {
      this.click(item._id);
    }
    const change = Math.round(this.yPos / this.rowHeight);
    this.updatePrio(change);
    this.sort();
    this.drag = null;
    this.yPos = 0;
  }

  /**
   * When mouse is pressed, define the origin from which to calculate
   * the change in direction for the element to be dragged
   */
  pick(event: any, item: any) {
    this.yOrigin = event.y;
    this.drag = item;
  }

  /**
   * Update priority of all items at once.
   */
  updatePrio(amount: number) {
    if (!this.drag) {
      return;
    }

    // Define what the update is all about
    const oldPrio = this.drag.priority;
    const newPrio = this.drag.priority + amount;

    // Prevent update if data is out of bounds or hasn't changed
    if (newPrio < 1) {
      return;
    }
    if (newPrio === oldPrio) {
      return;
    }
    if (newPrio > this.data[this.tab].length) {
      return;
    }

    // Update the dragged object, defining the operation
    this.drag.priority = newPrio;

    // Filters, to make it easy to reason about changes
    const notDragged = (x) => x._id !== this.drag._id;
    const increased = (x) => x.priority > oldPrio && x.priority <= newPrio;
    const decreased = (x) => x.priority < oldPrio && x.priority >= newPrio;
    const decrement = (x) => x.priority--;
    const increment = (x) => x.priority++;

    const priorityWentUp = oldPrio < newPrio;
    const filter = priorityWentUp ? increased : decreased;
    const operation = priorityWentUp ? decrement : increment;

    switch (this.tab) {
      case 'rules':
        delete this.drag.apps;
        this._pricingRuleService
          .update({
            _id: this.drag._id,
            priority: this.drag.priority
          })
          .subscribe(() =>
            this._snackbar.open(this.UPDATED_MSG, '', {duration: 3000}));
        break;
      case 'discounts':
        this._discountService
          .update({
            _id: this.drag._id,
            priority: this.drag.priority
          })
          .subscribe(() =>
            this._snackbar.open(this.UPDATED_MSG, '', {duration: 3000}));
        break;
    }

    this.data[this.tab]
      .filter(notDragged)
      .filter(filter)
      .map(operation);
  }

  /**
   * When the mouse moves, and while drag._id has a value, calculate
   * the change of position relative to the origin
   */
  move = (event: any, item: any) => {
    if (this.drag) {
      this.yPos = event.y - this.yOrigin;
    } else {
      this.yPos = 0;
    }
  }

  sort() {
    Object.keys(this.data).map(key => {
      this.data[key] = this._dataTableService.sortData(
        this.data[key], this.sortBy, this.sortOrder);
    });
  }

  checkPriorityOrder() {
    if (this.data.rules) {
      let x = 1;
      this.data.rules = this.data.rules.sort(function (a, b) {
        if (a.priority) {
          if (a.priority === b.priority) {
            return a.priority > b.priority ? 1 : -1;
          } else {
            return a.priority - b.priority;
          }
        }
      });
      this.data.rules.forEach((r) => {
        if (r.priority !== x) {
          const update = {
            priority: x
          };
          this._pricingRuleService
            .update(update)
            .subscribe(() =>
              this._snackbar.open(this.UPDATED_MSG, '', {duration: 3000}));
        }
        x = x + 1;
      });
    }
  }

  /**
   * Start the spinning loader.
   */
  startLoader = () => this._loadingService.register('pricing');

  /**
   * Stop the spinning loader.
   */
  stopLoader = () => this._loadingService.resolve('pricing');

}
