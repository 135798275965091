import {Component, NgZone} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {TdDialogService} from '@covalent/core/dialogs';
import {TdLoadingService} from '@covalent/core/loading';
import {UserService} from '../../../services/user.service';
import {CoolLocalStorage} from '@angular-cool/storage';
import {environment} from '../../../../environments/environment';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../services/auth.service';
import {Subscription} from 'rxjs';
import {DriverService} from '../../../services/driver.service';
import {CompanyWebUserService} from '../../../services/companyWebUser.service';
import {CompanyService} from '../../../services/company.service';
import {RideService} from '../../../services/ride.service';
import {Ride} from '../../../models/ride';
import {WorkspaceSelectionComponent} from '../workspace-selection/workspace-selection.component';
import {MergeEmailComponent} from '../merge-email/merge-email.component';
import jwt_decode from 'jwt-decode';
import {GlobalFunctions} from '../../../functions/functions';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [UserService, TdLoadingService, RideService]
})
export class LoginComponent {
  username: string;
  password: string;
  showError = false;
  qParams: any;
  trialLogin = false;
  clearBg = false;
  error = false;
  errorLabel: string;
  errorText: string;
  loginJob: string;
  redirect: string;
  companyRedirect: string;
  translations: string[];
  user: any;
  routeSubscription: Subscription;

  portalName = '';
  currentYear: number;
  pjson = require('../../../../../package.json');
  deployDate = require('../../../../../deploy.date.json');
  deployNumber = require('../../../../../deploy.buildnumber.json');

  constructor(private _router: Router,
              private _loadingService: TdLoadingService,
              private _userService: UserService,
              private _authService: AuthService,
              private _vault: CoolLocalStorage,
              private _dialogService: TdDialogService,
              private _route: ActivatedRoute,
              private _driverService: DriverService,
              private _CompanyWebUserService: CompanyWebUserService,
              private _companyService: CompanyService,
              private _rideService: RideService,
              private _translateService: TranslateService,
              private _globalFunctions: GlobalFunctions,
              private zone: NgZone,
              private _titleService: Title) {
    const today = new Date();
    this.portalName = environment.portalName;
    this.currentYear = today.getUTCFullYear();

    this._route.params.subscribe(params => {
      if (params['redirect']) {
        this.redirect = params['redirect'];
        if (params['companyId']) {
          this.companyRedirect = params['companyId'];
        }
        this._vault.setObject(`${environment.vaultPrefix}.redirectAfterSignup`, params['redirect']);
      }

      if (this._route.snapshot.routeConfig.path.indexOf('freetrial') !== -1) {
        this.trialLogin = true;
      }

      this._translateService.get(['close', 'login_start_first_trial', 'login_start_first_trial_title', 'no_users_found', 'start_free_trial']).subscribe((translations) => {
        this.translations = translations;
      });

      const user: any = this._vault.getObject(`${environment.vaultPrefix}.user`);
      this.user = user;
      if (params['productId']) {
        this.loginJob = params['productId'];
        if (user) {
          _rideService.getAll({'where': {'reference': this.loginJob}}).subscribe((rides: Ride[]) => {
            const ride = rides[0];
            if (ride) {
              _router.navigate([`/groups/${ride.ownerId}/jobs/${ride.id}/details`]);
            }
          });
        }
      } else {
        if (user && (this._authService.currentAuthState || this.redirect)) {
          this.clearBg = true;
          this._dialogService.open(WorkspaceSelectionComponent, {
            maxHeight: '80%',
            minWidth: (window.innerWidth < 500 ? '95%' : (user.ydaSupportUser ? '700px' : '500px')),
            disableClose: true,
            data: {
              openFromDialog: true,
              openFromLogin: true,
              redirect: this.redirect,
              parent: this
            },
          });
        } else {
          this._vault.removeItem(`${environment.vaultPrefix}.user`);
          this._vault.removeItem(`${environment.vaultPrefix}.accessToken`);
          this._vault.removeItem(`${environment.vaultPrefix}.redirectAfterSignup`);
          this._vault.removeItem(`${environment.vaultPrefix}.trialAllowed`);
          this._vault.removeItem(`${environment.vaultPrefix}.loginJob`);
          this._vault.removeItem(`${environment.vaultPrefix}.driver`);
          this._vault.removeItem(`${environment.vaultPrefix}.companyDriverId`);
          this._vault.removeItem(`${environment.vaultPrefix}.operator`);
          this._vault.clear();
          this._authService.setAuthState(false);
        }
      }
    });

    this.routeSubscription = this._route.parent.queryParams.subscribe(params => {
      this.qParams = params;
      // console.log(`[LoginComponent.params]: `, params);
      if (params && params.reset === 'requested') {
        this._translateService.get('password_reset_sent').subscribe((translation) => {
          this.showError = true;
          this.error = false;
          this.errorLabel = '';
          this.errorText = translation;
        });
      } else if (params && params.reset === 'success') {
        this._translateService.get('password_reset_updated').subscribe((translation) => {
          this.showError = true;
          this.error = false;
          this.errorLabel = '';
          this.errorText = translation;
        });
      } else if (params && params.session_expired) {
        this._translateService.get(['session_expired_error_text', 'session_expired', 'close']).subscribe((translation) => {
          this._dialogService.openAlert({
            message: translation.session_expired_error_text,
            disableClose: false,
            title: translation.session_expired,
            closeButton: translation['close']
          });
        });
      }
    });
    _translateService.get('login').subscribe((translation: string) => {
      this._titleService.setTitle(translation + environment.windowTitleSuffix);
    });
  }

  ngOnInit(): void {
    this._globalFunctions.loadScript(`https://accounts.google.com/gsi/client`, this, 'activateGoogleLogin');
  }

  activateGoogleLogin(): void {
    // @ts-ignore
    google.accounts.id.initialize({
      client_id: environment.googleLoginClientId,
      callback: this.handleCredentialResponse.bind(this),
      auto_select: true,
      context: 'use',
      itp_support: false,
      ux_mode: 'popup',
      cancel_on_tap_outside: true,
    });
    console.log(document.body.clientWidth);
    // @ts-ignore
    google.accounts.id.renderButton(
      // @ts-ignore
      document.getElementById('google-button'),
      {theme: 'outline',
        type: 'standard',
        size: 'medium',
        width: (document.body.clientWidth - 50)+'px',
        'shape': 'pill', auto_prompt: 'false'}
    );
  }

  async handleCredentialResponse(response: any) {
    // Here will be your response from Google.
    const responsePayload = jwt_decode(response.credential);
    this.login(responsePayload['email'], btoa(response.credential));
  }

  googleLogin(resp): void {
    this.login('', resp);
  }

  checkUsernameExistence(): void {
    if (!this.username) {
      return
    }
    const userName = this.username.toLowerCase();
    if (userName === '') {
      return
    }

    this._authService.checkUsernameExistence(userName, environment.companyId).subscribe(result => {
        if (result.count <= 0) {
          this.showError = true;
          this.error = true;
          this.errorLabel = this.translations['no_users_found'];
          this.errorText = this.translations['no_users_found_text'];
        } else {
          this.showError = this.qParams && this.qParams.reset === 'requested';
        }
      }, () => {
        this.error = true;
        this.showError = true;
        this.errorLabel = this.translations['no_users_found'];
        this.errorText = this.translations['no_users_found_text'];
        this._loadingService.resolve();
      },
      () => this._loadingService.resolve()
    );
  }

  login(email: string = '', googlePayLoad: any): void {
    this._loadingService.register();
    const self = this;
    self.error = false;
    self.showError = false;
    const jobLogin = this.loginJob;
    const credentials: any = {
      'password': this.password
    };

    if (googlePayLoad) {
      credentials.email = email.toLowerCase();
      credentials.googlePayLoad = googlePayLoad;
      if (environment.location === 'localhost') {
        credentials.isLocalhost = true;
      }
    } else {
      credentials.email = this.username.toLowerCase();
    }

    credentials.realm = `${environment.companyId}`;

    this._authService[(googlePayLoad ? 'googleLogin' : 'login')](credentials, function (err) {
      const driver = JSON.parse(self._vault.getItem(`${environment.vaultPrefix}.driver`));
      self.user = JSON.parse(self._vault.getItem(`${environment.vaultPrefix}.user`));
      if (err) {
        self._translateService.get(`${err}`).subscribe((translation: string) => {
          self.error = true;
          self.showError = true;
          self.errorLabel = 'Login failed';
          self.errorText = translation;
          self._loadingService.resolve();
        });
      } else {
        self._userService.findUserMerge(self.user).subscribe((drivers) => {
          if (drivers && drivers.length > 0) {
            self._dialogService.open(MergeEmailComponent, {
              maxHeight: '80%',
              minWidth: (window.innerWidth < 500 ? '95%' : '500px'),
              disableClose: true,
              data: {
                drivers: drivers,
                user: self.user,
                credentials: credentials,
                parent: self,
                callback: function () {
                  self.loginToPortal(jobLogin, driver);
                },
                jobLogin: jobLogin,
                driver: driver
              },
            });
          } else {
            self.loginToPortal(jobLogin, driver);
          }
        });
      }
    });
  }

  loginToPortal(jobLogin, driver): void {
    const self = this;
    self._CompanyWebUserService.getCompanies().subscribe((CompanyWebUsers: any[]) => {
      /**
       * Remove duplicates from array CompanyWebUsers of objects by companyId
       */
      CompanyWebUsers = CompanyWebUsers.filter((c, index) => {
        return (CompanyWebUsers.findIndex((c2) => {
          return (c2.companyId === c.companyId);
        }) === index);
      });

      CompanyWebUsers = CompanyWebUsers.filter((c) => {
        return (c.Company);
      });
      const companyMembers = [...CompanyWebUsers];
      let companies = companyMembers.filter((c) => {
        return (c.companyId && c.Company);
      });
      console.log(companies);

      companies = companies.map((c) => {
        return (c.companyId);
      });

      const user: any = self._vault.getObject(`${environment.vaultPrefix}.user`);
      const trialAllowed = (!user.hadTrial);
      self._vault.setObject(`${environment.vaultPrefix}.trialAllowed`, trialAllowed);
      self._vault.setObject(`${environment.vaultPrefix}.allowedCompanies`, companies);
      if (jobLogin) {
        self._rideService.getAll({'where': {'reference': jobLogin}}).subscribe((rides: Ride[]) => {
          const ride = rides[0];
          if (ride && companies.includes(ride.ownerId)) {
            self._router.navigate([`/groups/${ride.ownerId}/jobs/${ride.id}/details`]);
          } else {
            self._dialogService.open(WorkspaceSelectionComponent, {
              maxHeight: '80%',
              minWidth: (window.innerWidth < 500 ? '95%' : (self.user.ydaSupportUser ? '750px' : '500px')),
              disableClose: true,
              data: {
                openFromDialog: true,
                openFromLogin: true,
                parent: this
              },
            });
          }
        });
      } else if (self.trialLogin && trialAllowed) {
        self._dialogService.openAlert({
          message: self.translations['login_start_first_trial'],
          disableClose: false,
          title: self.translations['login_start_first_trial_title'],
          closeButton: self.translations['start_free_trial']
        }).afterClosed().subscribe(() => {
          self._router.navigate([`/signup/step4`]);
        });
      } else {
        if (self.redirect && companies.length === 1) {
          // @ts-ignore
          self._router.navigate([`/groups/${companies[0].id}/${self.redirect}/`]);
        } else if (companyMembers.length > 0) {
          // @ts-ignore
          if (companies.length === 1 && companies[0].type !== 'dispatch') {
            const direction = (companyMembers.map((c) => {
              return c;
            })[0].type === 'operator' ? 'operator' : 'groups');

            self.checkOnboardingWizard(`/${direction}/${companies[0]}${(self.redirect ? `/${self.redirect}` : '/dispatchpanel')}`, companies[0])
              .then((url) => {
                self._router.navigate([url]);
              })
          } else if (companyMembers.length > 1) {
            if (self.redirect && self.companyRedirect && companies.includes(self.companyRedirect)) {
              // @ts-ignore
              self._router.navigate([`/groups/${self.companyRedirect}/${self.redirect}/`]);
            } else {
              this.zone.run(() => {
                self._dialogService.open(WorkspaceSelectionComponent, {
                  maxHeight: '80%',
                  minWidth: (window.innerWidth < 500 ? '95%' : (self.user.ydaSupportUser ? '700px' : '500px')),
                  disableClose: true,
                  data: {
                    openFromDialog: true,
                    openFromLogin: true,
                    redirect: self.redirect,
                    parent: self
                  },
                });
              });
            }
          } else {
            self._router.navigate(['/personal/dashboard']);
          }
        } else if (driver) {
          self._router.navigate(['/personal/dashboard']);
        } else {
          self._dialogService.open(WorkspaceSelectionComponent, {
            maxHeight: '80%',
            minWidth: (window.innerWidth < 500 ? '95%' : (self.user.ydaSupportUser ? '750px' : '500px')),
            disableClose: true,
            data: {
              openFromDialog: true,
              openFromLogin: true,
              parent: this
            },
          });
        }
      }
      self._loadingService.resolve();
    });
  }

  checkOnboardingWizard(url, companyId) {
    return new Promise((resolve) => {
      this._companyService.get(companyId)
        .subscribe((company) => {
          if ((!company.settings.wizard || !['completed', 'done'].includes(company.settings.wizard)) &&
            !(company.taxiIDContract)) {
            if (company.settings.wizard === 'skipped') {
              company.settings.wizard = 'wizard';
            }
            return resolve(`onboarding-wizard/${company.id}/${(company.settings.wizard ? company.settings.wizard : 'wizard')}`);
          } else {
            return resolve(url);
          }
        })
    });
  }
}
