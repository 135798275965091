<mat-card *ngIf="this.company.ActiveContract && !this.company.ActiveContract.taxiIDContract && this.company.ActiveContract.reference" [class]="(this.context==='company' &&
(!this.company.ActiveContract ||
(this.company.hasDispatcherLicense && !this.company.ActiveContract.foreignStatus)) ? 'blurText' :'')"
          flex="100"
          tdMediaToggle="gt-xs">
  <div layout="row">
    <div flex="92" layout="column">
      <mat-card-title>{{'contract_details_title'|translate|ucFirst}}</mat-card-title>
      <mat-card-subtitle>{{'contract_details_subtitle'|translate|ucFirst}}</mat-card-subtitle>
    </div>

    <div *ngIf="isOwner || true" class="button-column push-bottom" layout="column">
      <div class="edit-button push-top push-right">
        <span *ngIf="this.activeContract.type !== 'free-trial'">
          <a (click)="this.openContractStatus()" class="yda-link"
             flex
             href="javascript:return false;">
            {{'change_contract_status'|translate|ucFirst}}</a>
        </span>

        <span *ngIf="this.activeContract.type !== 'free-trial'"> | </span>

        <span *ngIf="this.activeContract.type !== 'free-trial'">
          <a (click)="this.openBillingDetails()"
             class="yda-link"
             flex href="javascript:return false;">
            {{'update_billing_details'|translate|ucFirst}}</a>
        </span>

        <span *ngIf="this.activeContract.type !== 'free-trial' && moment(this.activeContract.created).isAfter(moment().subtract('2', 'weeks'))"> | </span>

        <span *ngIf="this.activeContract.type !== 'free-trial' && moment(this.activeContract.created).isAfter(moment().subtract('2', 'weeks'))">
          <a (click)="this.cancelPurchase()"
             class="yda-link"
             flex href="javascript:return false;">
            {{'cancel_purchase'|translate|ucFirst}}</a>
        </span>
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <ng-template tdLoading="contract.products">
    <div class="inset" layout="column" layout-gt-sm="row">
      <div flex-gt-sm="50">
        <div layout="column">
          <mat-list>
            <mat-list-item>
              <mat-icon matListAvatar>fingerprint</mat-icon>
              <h4 matLine>{{this.activeContract.reference}}</h4>
              <p matLine>{{'contract_id'|translate|ucFirst}}</p>
            </mat-list-item>
            <mat-list-item>
              <mat-icon matListAvatar>calendar_today</mat-icon>
              <h4
                matLine>{{this.activeContract.startDate|amDateFormat:(this.company && this.company.dateFormat ? this.company.dateFormat : 'DD-MM-YYYY')}}</h4>
              <p matLine>{{'start_date'|translate|ucFirst}}</p>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
      <div flex-gt-sm="50">
        <div layout="column">
          <mat-list>
            <mat-list-item *ngIf="this.activeContract.type !== 'free-trial'">
              <mat-icon matListAvatar>assignment</mat-icon>
              <h4 matLine>{{this.activeContract.licenseKey}}</h4>
              <p matLine>{{'license_key'|translate|ucFirst}}</p>
            </mat-list-item>
            <mat-list-item *ngIf="this.activeContract.type === 'free-trial'">
              <mat-icon matListAvatar>assignment</mat-icon>
              <h4 matLine>{{'free_trail'|translate|ucFirst}}</h4>
              <p matLine>{{'status'|translate|ucFirst}}</p>
            </mat-list-item>
            <mat-list-item *ngIf="this.activeContract.cancelAtPeriodEnd">
              <mat-icon matListAvatar>calendar_today</mat-icon>
              <h4
                matLine>{{this.activeContract.currentPeriodEnd|amDateFormat:(this.company && this.company.dateFormat ? this.company.dateFormat : 'DD-MM-YYYY')}}</h4>
              <p matLine>{{'end_date'|translate|ucFirst}}</p>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div *ngIf="this.activeContract.type !== 'free-trial' && this.cloudConnectMonthly">
      <div *ngFor="let product of this.cloudConnectMonthly" flex="100">
        <mat-list-item class="pull-bottom">
          <mat-icon mat-list-icon>{{(product.nameTag == 'cloud_connect' ? 'cloud_circle' : 'description')}}</mat-icon>
          <h3 mat-line>{{(product.nameTag ? product.nameTag :
            (product.name ? product.name : 'unknown'))|translate|ucFirst}}</h3>
        </mat-list-item>
        <div flex="100" layout="row" layout-gt-sm="row" style="padding-left:9px">
          <div flex="30" layout="column">
            <mat-list-item>
              <h4
                matLine>{{'billing_scheme_' + product.billingScheme + '' + (['webbooker_license', 'webbooker_plus_license'].includes(this.activeContract.type) ? '_yearly' : '')|translate|ucFirst}}</h4>
              <p matLine>{{'billing_scheme'|translate|ucFirst}}</p>
            </mat-list-item>
          </div>
          <div flex="30" layout="column">
            <mat-list-item>
              <h4 matLine>{{(product.quantity ? product.quantity : "0")}}</h4>
              <p matLine>{{'quantity_used'|translate|ucFirst}}</p>
            </mat-list-item>
          </div>
          <div flex="30" layout="column">
            <mat-list-item>
              <h4 matLine>{{product.amount | localizedCurrency}}</h4>
              <p matLine>{{'cost'|translate|ucFirst}}</p>
            </mat-list-item>
          </div>
          <div class="edit-column" layout="column">
            <button (click)="this.openExtendedDispatcher()" *ngIf="this.extendedDispatcherSubId && product.nameTag === 'extended_dispatcher'" class="push-right push-top push-bottom edit-button" color="primary"
                    flex
                    mat-raised-button>
              {{'remove_extended_dispatcher'|translate|ucFirst}}</button>

            <button (click)="this.openUpgradeContract()" *ngIf="['webbooker_license','webbooker_plus_license',
                    'free-trial'].includes(this.activeContract.type) && ['webbooker_license','webbooker_plus_license'].includes(product.sku)" class="push-right push-top push-bottom edit-button" color="primary"
                    flex mat-raised-button>
              {{'upgrade_license'|translate|ucFirst}}</button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <mat-divider *ngIf="this.activeContract.type !== 'free-trial'"></mat-divider>
</mat-card>
<app-no-contract *ngIf="this.company && (this.context==='company' && (!this.company.ActiveContract ||
!['active','past_due', null].includes(this.company.ActiveContract.foreignStatus))) ||
this.company.ActiveContract.type === 'free-trial'"
                 [companyId]="this.companyId" [contract]="this.company.ActiveContract"
                 [step]="2" [type]="'fleet'"></app-no-contract>

<mat-card *ngIf="this.activeContract.companyId && ['CloudConnect','fleet_solutions'].includes(this.activeContract.type)" flex="100" layout="row"
          tdMediaToggle="gt-xs">
  <mat-card-title>{{'contract_monthly_usage_title'|translate|ucFirst}}</mat-card-title>
  <mat-card-subtitle>{{'contract_monthly_usage_subtitle'|translate|ucFirst}}</mat-card-subtitle>
  <mat-divider></mat-divider>

  <ng-template tdLoading="contract.cloudConnectUsage">
    <div *ngFor="let product of this.cloudConnectUsage" flex-gt-sm="100">
      <div flex="100" layout="row" layout-gt-sm="row">
        <div flex="30" layout="column">
          <mat-list-item>
            <h4 matLine>{{product.nameTag|translate|ucFirst}}</h4>
            <p *ngIf="product.descriptionTag" matLine>{{product.descriptionTag|translate|ucFirst}}</p>
          </mat-list-item>
        </div>
        <div flex="20" layout="column">
          <mat-list-item>
            <h4 matLine>{{(product.quantity ? product.quantity : "0")}}</h4>
            <p matLine>{{'quantity_used'|translate|ucFirst}}</p>
          </mat-list-item>
        </div>
        <div flex="40" layout="column">
          <mat-list-item>
            <h4 matLine>{{product.amount | localizedCurrency}}</h4>
            <p matLine>{{'cost'|translate|ucFirst}}</p>
          </mat-list-item>
        </div>
      </div>
    </div>
  </ng-template>
</mat-card>

<mat-card *ngIf="this.activeContract.companyId &&
['webbooker_license','webbooker_plus_license'].includes(this.activeContract.type) && this.cloudConnectUsage &&
this.cloudConnectUsage.length > 0"
          flex="100" layout="row"
          tdMediaToggle="gt-xs">
  <mat-card-title>{{'contract_yearly_usage_title'|translate|ucFirst}}</mat-card-title>
  <mat-card-subtitle>{{'contract_yearly_usage_subtitle'|translate|ucFirst}}</mat-card-subtitle>
  <mat-divider></mat-divider>

  <ng-template tdLoading="contract.cloudConnectUsage">
    <div *ngFor="let product of this.cloudConnectUsage" flex-gt-sm="100">
      <div flex="100" layout="row" layout-gt-sm="row">
        <div flex="30" layout="column">
          <mat-list-item>
            <h4 matLine>{{product.nameTag|translate|ucFirst}}</h4>
            <p *ngIf="product.descriptionTag" matLine>{{product.descriptionTag|translate|ucFirst}}</p>
          </mat-list-item>
        </div>
        <div flex="20" layout="column">
          <mat-list-item>
            <h4 matLine>{{(product.quantity ? product.quantity : "0")}}</h4>
            <p matLine>{{'quantity_used'|translate|ucFirst}}</p>
          </mat-list-item>
        </div>
        <div flex="40" layout="column">
          <mat-list-item>
            <h4 matLine>{{product.amount | localizedCurrency}}</h4>
            <p matLine>{{'cost'|translate|ucFirst}}</p>
          </mat-list-item>
        </div>
      </div>
    </div>
  </ng-template>
</mat-card>

<mat-card *ngIf="this.billingHistory && this.activeContract.type !== 'free-trial'" flex="100"
          layout="row" tdMediaToggle="gt-xs">
  <mat-card-title>{{'invoices'|translate|ucFirst}}</mat-card-title>
  <mat-divider></mat-divider>

  <ng-template tdLoading="upcommingInvoice">
    <mat-tab-group>
      <mat-tab *ngIf="this.upcommingInvoice" label="upcommingInvoice">
        <ng-template mat-tab-label>
          <mat-icon>euro_symbol</mat-icon>
          {{'upcomming_invoice'|translate|ucFirst}}
        </ng-template>
        <app-upcomming-invoice [upcommingInvoice]="this.upcommingInvoice"></app-upcomming-invoice>
      </mat-tab>
      <mat-tab *ngIf="this.billingHistory" label="billingHistory">
        <ng-template mat-tab-label>
          <mat-icon>euro_symbol</mat-icon>
          {{'billing_history'|translate|ucFirst}}
        </ng-template>
        <app-billing-history [billingHistory]="this.billingHistory"></app-billing-history>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</mat-card>

<mat-card *ngIf="this.company.ActiveContract && this.company.ActiveContract.taxiIDContract">
  <div layout="row">
    <div flex="92" layout="column">
      <mat-card-title>{{'contract_details_title'|translate|ucFirst}}</mat-card-title>
      <mat-card-subtitle>{{'taxiid_details_subtitle'|translate|ucFirst}}</mat-card-subtitle>
    </div>
  </div>
  <mat-divider></mat-divider>
  <app-google-api-key [company]="this.company" flex="100" layout="row"></app-google-api-key>
  <mat-divider></mat-divider>
</mat-card>
