<mat-card [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <ng-template tdLoading="companyWebUser">
    <mat-card-content class="push-bottom-none">
      <div *ngIf="!id">{{'add_new_operator'|translate|ucFirst}}</div>
      <ng-template tdLoading="companyWebUser-upsert">
        <form *ngIf="form" [formGroup]="form">
          <div class="push-bottom-sm push-top-sm" layout="row">
            <mat-form-field class="push-right" flex>
              <input #fname (keyup)="clearError($event, 'fname')" formControlName="fname" matInput placeholder="{{'first_name'|translate}}"
                     required
                     type="text" value="{{companyWebUser.fname}}">
              <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.fname" class="tc-red-600">
                  <span>{{ formErrors.fname }}</span>
                </span>
              </mat-hint>
            </mat-form-field>
            <mat-form-field flex>
              <input #lname (keyup)="clearError($event, 'lname')" formControlName="lname" matInput placeholder="{{'last_name'|translate}}"
                     required
                     type="text" value="{{companyWebUser.lname}}">
              <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.lname" class="tc-red-600">
                  <span>{{ formErrors.lname }}</span>
                </span>
              </mat-hint>
            </mat-form-field>
          </div>
          <div layout="row">
            <mat-form-field class="push-right" flex>
              <input #emailAddress (keyup)="clearError($event, 'emailAddress')" [attr.disabled]="(companyWebUser)" formControlName="emailAddress" matInput
                     placeholder="{{'email'|translate}}" required
                     type="email"
                     value="{{companyWebUser.emailAddress}}">
              <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.emailAddress" class="tc-red-600">
                  <span>{{ formErrors.emailAddress }}</span>
                </span>
              </mat-hint>
            </mat-form-field>
            <div class="phoneNumberField push-right {{(formErrors.phoneNumber ? 'phoneNumberInvalid' : '')}}" flex>
              <ngx-intl-tel-input
                (keyup)="clearError($event, 'phoneNumber')"
                [cssClass]="'phoneNumberInput'"
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true"
                [maxLength]="15"
                [phoneValidation]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [searchCountryFlag]="true"
                [selectFirstCountry]="false"
                [selectedCountryISO]="(this.company.country === 'EN'? 'GB' : this.company.country)"
                flex
                formControlName="phoneNumber"
                name="phoneNumber">
              </ngx-intl-tel-input>
              <div class="phonenumber-warning warning">
                <span [hidden]="!formErrors['phoneNumber']" class="tc-red-600">
                  <span>{{ formErrors['phoneNumber'] }}</span>
                </span>
              </div>
            </div>
          </div>

          <div class="push-top-md" layout="row">
            <mat-form-field *ngIf="this.companyWebUser.type !== 'owner'">
              <mat-select class="row-select" flex="33" formControlName="type" placeholder="Type" required>
                <mat-option *ngFor="let type of userTypes"
                            [value]="type">{{ type | translate | ucFirst }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="this.companyWebUser.type === 'owner'" class="push-right disabled" flex>
              <input #type formControlName="type" matInput required type="text">
            </mat-form-field>
            <mat-list-item class="value push-left {{(isDriver ? 'disabled' : '')}}" flex-gt-sm="50">
              <mat-checkbox [checked]="isDriver" class="pull-left" formControlName="invite"></mat-checkbox>
              <mat-label>{{('member_is_driver')|translate|ucFirst}}</mat-label>
            </mat-list-item>
          </div>
        </form>
      </ng-template>
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-actions>
      <button (click)="save()" *ngIf="!this.id" color="accent" flex
              mat-raised-button>{{'invite_driver_button'|translate}}</button>
      <button (click)="save()" *ngIf="this.id" color="accent" flex
              mat-raised-button>{{'save_profile_button'|translate}}</button>
    </mat-card-actions>
  </ng-template>
</mat-card>
