import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Address} from '../../../../../../../models/address';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ucFirst} from '../../../../../../../pipes/uc-first.pipe';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-ride-edit-location',
  templateUrl: './ride-edit-location.component.html',
  styleUrls: ['./ride-edit-location.component.scss']
})
export class RideEditLocationComponent implements OnInit {
  @Input() departure: Address;
  @Input() destination: Address;
  @Input() form: UntypedFormGroup;
  @Output() addessUpdated: EventEmitter<any> = new EventEmitter();
  hasFlex = 50;
  viaArray = [];

  protected addressArray = [];
  private drag: any;
  private yPos: number;
  private yOrigin: number;
  private rowHeight = 67;

  constructor(
    protected _translateService: TranslateService,
    private _formBuilder: UntypedFormBuilder,
    private _snackBar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    this.setupAddressArray();
  }

  setupAddressArray() {
    this.addressArray = [];
    if (this.departure) {
      this.addressArray.push(this.form.controls['departure']);
    }

    // @ts-ignore
    if (this.form.controls['stopOvers'].controls.length > 0) {
      // @ts-ignore
      this.form.controls['stopOvers'].controls.forEach((control) => {
        this.addressArray.push(control);
      });
    }

    if (this.destination) {
      this.addressArray.push(this.form.controls['destination']);
    }
  }


  getAddressArray() {
    return this.addressArray;
  }

  addDestination() {
    /**
     * Check if all stopovers are filled in before adding an new item
     */
    if (!this.form.controls['stopOvers'].invalid) {
      // @ts-ignore
      this.form.controls['stopOvers'].controls.push(this._formBuilder.group({
        city: ['', []],
        streetName: [null, []],
        houseNumber: [null, []],
        postalCode: [null, []],
        internationalAlias: [null, [Validators.required]],
        synonym: [null, []],
        placeId: [null, []],
        countryCode: [null, []],
        code: ['', []],
        gps: this._formBuilder.group({
          lng: [null, [Validators.required]],
          lat: [null, [Validators.required]],
        }),
        type: [null, []],
      }));
      this.setupAddressArray();
    } else {
      this._snackBar.open(ucFirst(this._translateService.instant('fill_stopovers_warning')), this._translateService.instant('ok'), {
        duration: 3000,
        panelClass: ['snack-warn']
      });
    }
  }

  getStopOvers() {
    if (this.form.controls['stopOvers']) {
      // @ts-ignore
      return this.form.controls['stopOvers'].controls;
    } else {
      return [];
    }
  }

  removeDestination(i: number) {
    // @ts-ignore
    this.form.controls['stopOvers'].controls.splice(i, 1);
    this.form.controls['stopOvers'].updateValueAndValidity();
    this.addressArray.splice((i + 1), 1);
  }

  getPos = (id: string) => this.drag && id === this.drag.internationalAlias ? this.yPos : 0;
  drop = (event: any, item: any) => {
    if (!this.drag) { return; }
    const change = Math.round(this.yPos / this.rowHeight);

    if ( change > 0) {
      this.updatePrio(change, event);
      this.sort();
      this.drag = null;
      this.yPos = 0;
    } else {
      this.drag = null;
      this.yPos = 0;
    }
    console.log('drop');
  }

  /**
   * When mouse is pressed, define the origin from which to calculate
   * the change in direction for the element to be dragged
   */
  pick(event: any, item: any, index) {
    this.yOrigin = event.y;
    this.drag = item;
    this.drag.index = index;
  }

  /**
   * When the mouse moves, and while drag._id has a value, calculate
   * the change of position relative to the origin
   */
  move = (event: any, item: any) => {
    if (this.drag) {
      this.yPos = event.y - this.yOrigin;
    } else {
      this.yPos = 0;
      return;
    }
    const change = Math.round(this.yPos / (this.rowHeight + 30));
    this.updatePrio(change, event);
  }

  sort() {
    // /**
    //  * Set first element to departure
    //  */
    const dataArray = [];
    this.addressArray.forEach((control, i) => {
      dataArray.push(JSON.parse(JSON.stringify(control.value)));
    });

    dataArray.forEach((data, i) => {
      delete data.index;
      if (i === 0) {
        this.form.controls['departure'].setValue(data);
        this.departure = data;
      } else if (!this.addressArray[(i + 1)]) {
        this.destination = data;
        this.form.controls['destination'].setValue(data);
      } else
        // @ts-ignore
      if (this.form.controls['stopOvers'].controls.length > 0 && this.form.controls['stopOvers'].controls[(i - 1)]) {
        // @ts-ignore
        this.form.controls['stopOvers'].controls[(i - 1)].setValue(data);
      }
    });
    this.addressArray = [];
    this.setupAddressArray();
    this.form.controls['stopOvers'].updateValueAndValidity();
  }

  updatePrio(amount: number, event) {
    if (!this.drag) {
      return;
    }

    // @ts-ignore
    const totalAddresses = this.form.controls['stopOvers'].controls.length + 2;

    if (amount) {
      if (amount > totalAddresses) {
        amount = totalAddresses;
      } else if (amount < (0 - totalAddresses)) {
        amount = (0 - totalAddresses);
      }
      const switchItem = this.drag.index + amount;
      if (this.addressArray[switchItem]) {
        const tmp =
          this.addressArray[switchItem];
        this.addressArray[switchItem] = this.addressArray[this.drag.index];
        this.addressArray[this.drag.index] = tmp;
        this.yPos = 0;
        this.yOrigin = event.y;
        this.drag.index = switchItem;
      }
      this.sort();
    }
  }
}
