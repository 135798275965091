import {AfterViewInit, Component, OnInit} from '@angular/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {environment} from '../../../../environments/environment';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {UserService} from '../../../services/user.service';
import {TdLoadingService} from '@covalent/core/loading';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {DriverService} from '../../../services/driver.service';
import {Company} from '../../../models/company';
import {Driver} from '../../../models/driver';
import {CountryISO, SearchCountryField,} from 'ngx-intl-tel-input';
import {TdDialogService} from '@covalent/core/dialogs';
import {MergeEmailComponent} from '../merge-email/merge-email.component';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  providers: [UserService]
})
export class UserProfileComponent implements OnInit, AfterViewInit {
  user: any;
  company: Company;
  form: UntypedFormGroup;
  selected: string;
  driver: Driver;
  myCompanies = [];
  translations: string[];
  formErrors: any = {
    password: '',
    passwordConfirm: ''
  };
  validationMessages = {
    'password': {
      'required': 'Password is required',
      'equals': 'Passwords don\'t match'
    },
    'passwordConfirm': {
      'required': 'Confirm password is required',
      'equals': 'Passwords don\'t match'
    }
  };
  SearchCountryField = SearchCountryField;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom
  ];

  constructor(public snackBar: MatSnackBar,
              private _driverService: DriverService,
              private _fb: UntypedFormBuilder,
              private _vault: CoolLocalStorage,
              private _loadingService: TdLoadingService,
              private _dialogService: TdDialogService,
              private _userService: UserService,
              private _translateService: TranslateService,
              private _titleService: Title) {
    _translateService.get(['your_profile', 'profile_error_duplicate_email', 'ok']).subscribe((translations: string[]) => {
      this.translations = translations;
      this._titleService.setTitle(translations['your_profile'] + environment.windowTitleSuffix);
    });

    this.driver = this._vault.getObject(`${environment.vaultPrefix}.driver`);
  }

  getSelectedIcon() {
    return (this.company ? 'business' : 'personal');
  }

  getCompanyName() {
    return (this.company ? this.company.name : 'personal');
  }

  selectGroup(event) {
    if (event.value) {
      this.myCompanies.forEach((company) => {
        if (company.id === event.value) {
          this.company = {
            id: company.id,
            name: company.name
          };
        }
      });

    } else {
      this.company = null;
    }
  }

  ngOnInit() {
    this.user = this._vault.getObject(`${environment.vaultPrefix}.user`);
    /**
     * Set up our form
     */

    this.selected = this.user.defaultCompany;

    if (this.driver || this.user.connectStatus === 'proposed') {
      this.form = this._fb.group({
        firstName: [this.user.firstName, [Validators.required]],
        lastName: [this.user.lastName, [Validators.required]],
        email: [this.user.email, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        defaultCompany: [this.user.defaultCompany, []],
      });
    } else {
      this.form = this._fb.group({
        firstName: [this.user.firstName, [Validators.required]],
        lastName: [this.user.lastName, [Validators.required]],
        email: [this.user.email, [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        phoneNumber: [this.user.phoneNumber, [Validators.required]],
        defaultCompany: [this.user.defaultCompany, []],
      });
    }


    /**
     * Subscribe to value changes in the form
     */
    this.form.valueChanges.subscribe(data => this.onValueChanged());

    this.loadCompanies();

    /**
     * (re)set validation messages now
     */
    this.onValueChanged();
  }

  ngAfterViewInit() {
  }

  onValueChanged() {
    if (!this.form) {
      return;
    }
    const form = this.form;
    for (const field in this.formErrors) {
      /**
       * clear previous error message (if any)
       */
      this.formErrors[field] = '';
      const control = form.get(field);
      /**
       * Loop through the fields and check for errors, set messages accordingly
       */
      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        for (const key in control.errors) {
          this.formErrors[field] += messages[key] + ' ';
        }
      }
    }
  }

  save(): void {
    const self = this;
    this._loadingService.register('profile');
    const data = this.form.value;
    const user = this.user;

    for (const key in data) {
      user[key] = data[key];
    }

    // if (this.user.defaultCompany) {
    //   this.user.defaultCompany = this.user.defaultCompany.id;
    // }

    if (data.phoneNumber && data.phoneNumber.e164Number) {
      // this.form.controls['phoneNumber'].setValue(data.phoneNumber.e164Number);
      this.user.phoneNumber = data.phoneNumber.e164Number;
    }
    this._userService.update(this.user.id, user).subscribe(result => {
        this._loadingService.resolve('profile');
        const notification = this.snackBar.open('Profile updated!', 'OK', {
          duration: 3000
        });
        this._vault.setObject(`${environment.vaultPrefix}.user`, result);
      }, error => {
        self._dialogService.openAlert({
          message: self.translations['profile_error_' + error.error.error.name.toLowerCase()],
          disableClose: true,
          closeButton: self.translations['ok'],
        }).afterClosed().subscribe(() => {
        });
        this._loadingService.resolve('profile');
      }
    );
  }

  initConnect() {
    const self = this;
    self.user = self._vault.getObject(`${environment.vaultPrefix}.user`);
    self._userService.findUserMerge(self.user, true).subscribe((drivers) => {
      if (drivers.length > 0) {
        self._dialogService.open(MergeEmailComponent, {
          maxHeight: '80%',
          minWidth: '330px',
          disableClose: true,
          width: '520px',
          data: {
            drivers: drivers,
            user: self.user,
            parent: self,
            successCallback: function () {
              self.refreshPage();
            }
          },
        });
      }
    });
  }

  refreshPage(): void {
    window.location.reload();
  }

  loadCompanies(): void {
    const phoneNumber = this.user.phoneNumber;

    this._driverService.getAll({
      where: {
        phoneNumber: encodeURIComponent(phoneNumber)
      }, include: ['Company']
    }).subscribe((drivers) => {
      drivers.forEach((driver) => {
        if (driver.Company) {
          this.myCompanies.push(driver.Company);

          if (driver.Company.id === this.selected) {
            this.company = driver.Company;
            this.selected = driver.Company;
          }
        }
      });
    });
  }
}
