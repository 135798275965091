import {Component, Input, OnInit} from '@angular/core';
import {Rating} from '../../models/rating';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'ratings-widget',
  templateUrl: './ratings-widget.component.html',
  styleUrls: ['./ratings-widget.component.scss']
})
export class RatingsWidgetComponent implements OnInit {

  @Input() title = '';
  @Input() subTitle = '';
  @Input() cssClasses = '';

  @Input() ratings: Rating[];

  defaultCssClasses = 'mat-card';
  companyId: string;

  constructor(private router: Router,
              private route: ActivatedRoute) {
    console.log('ROUTE', route);
    this.companyId = this.route.parent.snapshot.paramMap.get('id');
  }

  ngOnInit() {
    console.log(`[RatingsWidgetComponent.ngOnInit]: ratings`, this.ratings);
  }

  openRide(id: string) {
    this.router.navigate([`/groups/${this.companyId}/jobs/${id}`]);
  }

}
