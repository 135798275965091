import {Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {CoolLocalStorage} from '@angular-cool/storage';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {TdDialogService} from '@covalent/core/dialogs';
import {TdDataTableService, TdDataTableSortingOrder} from '@covalent/core/data-table';
import {TdLoadingService} from '@covalent/core/loading';
import {CollectionComponent} from '../../../global/collection/collection.component';
import {DriverService} from '../../../../services/driver.service';
import {RideService} from '../../../../services/ride.service';
import {UtilityService} from '../../../../services/utility.service';
import {DebtorService} from '../../../../services/debtor.service';
import {Debtor} from '../../../../models/debtor';
import {CreateDraftInvoicesComponent} from './parts/create-draft-invoices/create-draft-invoices.component';
import {environment} from '../../../../../environments/environment';
import moment from 'moment';
import md5 from 'crypto-js/md5';
import {ucFirst} from '../../../../pipes/uc-first.pipe';
import {Ride} from '../../../../models/ride';

@Component({
  selector: 'app-to-be-invoiced',
  templateUrl: './to-be-invoiced.component.html',
  providers: [],
  styleUrls: ['./to-be-invoiced.component.scss'],
  animations: [
    trigger('multiSelect', [
      state('collapsed', style({
        height: '0px',
        padding: '0px 15px',
      })),
      state('expanded', style({
        height: (window.innerWidth < 750 && window.innerWidth > 302 ? '80px' : (window.innerWidth < 302 ? '120px' : '40px')),
        padding: '15px',
      })),
      transition('void => *', animate(200)),
      transition('collapsed => expanded', animate(200)),
      transition('expanded => collapsed', animate(200)),
    ]),
  ]
})
export class ToBeInvoicedComponent extends CollectionComponent implements OnInit {
  @Input() companyId: string;
  @Input() context: string;
  @Output() reloadAllDataEmitter = new EventEmitter<number>();
  @Output() switchTab = new EventEmitter<number>();

  secret = 'coolDuaLipa';
  actionName = 'loadToBeInvoiced';
  modelName = 'rides';
  multiSelectedState = 'collapsed';
  jobSelected = false;
  types = ['all'];
  paging = {
    'all': {
      fromRow: 0,
      currentPage: 0,
      pageSize: 50
    }
  };
  filteredData = {
    all: [],
    grouped: {},
  }
  multiSelect = [];
  translations = [];
  selectedRides = [];
  driverIds = [];
  multiSelected = false;
  multiSelectBox = {};
  multiSelectDebtor = {};
  columns = [
    {name: 'check', label: '', sortable: false, active: false, fixedSize: 'w-30', maxSize: null},
    {name: 'reference', label: 'reference', sortable: false, active: false, fixedSize: 'w-80'},
    {name: 'requestedDate', label: 'requested_date', sortable: false, active: true},
    {name: 'departure', label: 'departure', sortable: false, active: false, maxWidth: 'max-width-300'},
    {name: 'status', label: 'status', sortable: false, active: false},
    {name: 'paymentOrigin', label: 'payment_method', sortable: false, active: false, maxSize: 'maxSize1024', fixedSize: 'w-150'},
    {name: 'fareAmount', label: 'fare_amount', sortable: false, active: false, numeric: true},
  ];
  debtorColumns = [
    {name: 'check', label: '', sortable: false, active: false, fixedSize: 'w-30', maxSize: null},
    // { name: 'code',       label: 'code',        sortable: false,   active: false, fixedSize: 'w-80' },
    {name: 'name', label: 'name', sortable: false, active: true},
    {name: 'contact', label: 'contact', sortable: false, active: false, maxWidth: 'max-width-300'},
    {name: '', label: '', sortable: false, active: false},
    {name: '', label: '', sortable: false, active: false},
    {name: '', label: '', sortable: false, active: false},
    {name: '', label: '', sortable: false, active: false},
  ];

  allSelected = false;
  ridesAsOwner: any = {
    data: [],
    filteredData: [],
    filteredTotal: 0,
    fromRow: 1,
    pageSize: 50,
    currentPage: 1,
    sortBy: 'requestedDateTime',
    sortOrder: TdDataTableSortingOrder.Descending,
    excludedSearchColumns: [
      'passengerName',
      'paymentDescription',
      'paymentMethod',
      'owner',
    ]
  };
  selectFilter = {
    where: {
      ownerId: '',
      ownerType: 'Company',
      status: 'completed',
      or: [{
        debtorId: {exists: true},
      },
        {
          passenger: {exists: true},
        },
        {
          passengerId: {exists: true},
        }],
      'paymentMeta.origin': {'in': ['invoice']},
      'meta.hasDebtorInvoice': false,
    },
    include: [
      'Debtor', 'Passenger'
    ],
    fields: ['id', 'reference', 'price', 'debtorId', 'departure', 'passenger', 'status', 'paymentMeta', 'requestedDate']
  };

  constructor(
    protected injector: Injector,
    public _loadingService: TdLoadingService,
    public _vault: CoolLocalStorage,
    public _dataTableService: TdDataTableService,
    private route: ActivatedRoute,
    private _translateService: TranslateService,
    private _dialogService: TdDialogService,
    private _driverService: DriverService,
    private _rideService: RideService,
    private _debtorService: DebtorService,
  ) {
    super(injector);
    this.modelService = _rideService;

    _translateService.get([
      `confirm`, 'passenger',
      'payment_origin_inTaxi', 'payment_origin_inApp', 'payment_origin_online', 'payment_origin_invoice'
    ]).subscribe((translations: string[]) => {
      this.translations = translations;
    });

    this.selectFilter.where.ownerId = this.companyId;

    const localStoragePaymentMethodFilter = this._vault.getItem(`${environment.vaultPrefix}.toBeInvoiced-paymentMethod-Filter`);
    if (localStoragePaymentMethodFilter) {
      this.selectFilter.where['paymentMeta.origin'] = {'in': JSON.parse(localStoragePaymentMethodFilter)};
    }

    let startDate = moment().startOf('month').toISOString();
    let endDate = moment().add('1', 'month').startOf('month').toISOString();
    /**
     * For current month check coolstorage or select last month or if not available select this month
     */
    const localStorageFilter = this._vault.getItem(`${environment.vaultPrefix}.toBeInvoiced-Month-Filter`);
    if (localStorageFilter) {
      startDate = moment(localStorageFilter).toISOString();
      endDate = moment(localStorageFilter).add('+1', 'month').toISOString();
    }
    this.selectFilter.where['and'] = [
      {created: {gt: startDate}},
      {created: {lt: endDate}}
    ];
  }

  collectionFormat(ride: any): any {
    const id = (ride.foreignDispatchId ?
      ride.foreignDispatchId :
      (ride.foreignBookingId ?
        ride.foreignBookingId :
        ride.intId));

    const passenger = (ride.Passenger ? ride.Passenger : ride.passenger);
    const reference = ride.reference;

    let owner = '';
    if (ride.Owner && ride.ownerType === 'Driver') {
      owner = `${ride.Owner.fname} ${ride.Owner.lname}`;
    } else if (ride.Owner && ride.ownerType === 'Company') {
      owner = `${ride.Owner.name}`;
    }

    let driver = '';
    if (ride.Driver) {
      driver = `${ride.Driver.fname} ${ride.Driver.lname}`;
    }
    return {
      ...ride,
      owner,
      driver,
      reference,
      id: ride.id,
      intId: id,
      requestedDate: new Date(ride.requestedDate),
      pickupDate: (typeof ride.pickupDate !== 'undefined' ? new Date(ride.pickupDate) : ''),
      passengerCount: ride.passengerCount,
      departure: UtilityService.getLocationString(ride.departure),
      destination: UtilityService.getLocationString(ride.destination),
      status: ride.status,
      passengerName: (passenger ? `${passenger.fname} ${passenger.lname}` : ''),
      passengerPhone: (passenger ? passenger.phoneNumber : ''),
      paymentMethod: (ride.paymentMeta ? ride.paymentMeta.origin : ''),
      paymentDescription: (ride.paymentMeta ? ride.paymentMeta.description : ''),
      paymentOrigin: (ride.paymentMeta ? this.translations[`payment_origin_${ride.paymentMeta.origin}`] : ''),
      calculationMethod: ride.priceMethod || '',
      fareAmount: (ride.price ? ride.price : 0),
      exportFareAmount: `${(ride.price ? ride.price.total / 100 : 0)}`.replace('.', ','),
      currency: (ride.price ? ride.price.currency : 'EUR'),
      exportFareCurrency: (ride.price ? ride.price.currency : 'EUR'),
      commissionAmount: (ride.commission ? ride.commission : 0),
      exportCommissionAmount: `${(ride.commission ? ride.commission.total / 100 : 0)}`.replace('.', ','),
      commissionCurrency: (ride.commission ? ride.commission.currency : 0),
      exportRoutePrice: `${(ride.price && ride.price.breakdown && ride.price.breakdown.route ?
        ride.price.breakdown.route.total / 100 :
        0)}`.replace('.', ','),
      exportAdditionalPrice: `${(ride.price && ride.price.breakdown && ride.price.breakdown.additional ?
        ride.price.breakdown.additional.total / 100 :
        0) + (ride.price && ride.price.breakdown && ride.price.breakdown.cleaning ?
        ride.price.breakdown.cleaning.total / 100 :
        0)}`.replace('.', ','),
      exportTotalPrice: `${(ride.price ? ride.price.total / 100 : 0)}`.replace('.', ','),
      exportDriverPrice: `${(ride.driverPrice ? ride.driverPrice.total / 100 : 0)}`.replace('.', ','),
      ownerDriverSettlement: `${{
        ...ride.driverSettlement, total: (ride.driverSettlement ?
          (0 - ride.driverSettlement.total) / 100 :
          0)
      }}`.replace('.', ','),
      exportOwnerDriverSettlement: `${(ride.driverSettlement ?
        (0 - ride.driverSettlement.total) / 100 :
        0)}`.replace('.', ','),
      exportDriverSettlement: `${(ride.driverSettlement ?
        ride.driverSettlement.total / 100 :
        0)}`.replace('.', ','),
      debtorCode: (ride.Debtor ? ride.Debtor.code : 0),
      debtorName: (ride.Debtor ?
        `${ride.Debtor.firstName} ${ride.Debtor.lastName} ${(ride.Debtor.companyName ? `(${ride.Debtor.companyName}` : '')}` :
        ''),
      debtorEmail: (ride.Debtor ? ride.Debtor.emailAddress : ''),
      debtorPhone: (ride.Debtor ? ride.Debtor.phoneNumber : ''),
    };
  }

  invoiceFilterChanged(data?: any): void {
    this._loadingService.register(this.actionName);
    /**
     * Deselect all options
     */
    this.multiSelect = [];
    this.multiSelected = false;
    this.multiSelectBox = [];
    this.multiSelectDebtor = [];
    this.jobSelected = false;
    this.multiSelectedState = 'collapsed';

    this.filteredData = {
      all: [],
      grouped: [],
    };
    if (data) {
      this.filterData = data;
    }
    if (!this.filterData) {
      this.filterData = {};
    }
    if (this.filterData.paymentMethods) {
      this.selectFilter.where['paymentMeta.origin'] = {'in': this.filterData.paymentMethods};
    }

    let startDate = moment().startOf('month').toISOString();
    let endDate = moment().add('1', 'month').startOf('month').toISOString();
    /**
     * For current month check coolstorage or select last month or if not available select this month
     */
    const localStorageFilter = this._vault.getItem(`${environment.vaultPrefix}.toBeInvoiced-Month-Filter`);
    if (localStorageFilter) {
      startDate = moment(localStorageFilter).toISOString();
      endDate = moment(localStorageFilter).add('+1', 'month').toISOString();
    }
    if (this.filterData.monthValue) {
      startDate = moment(`${this.filterData.monthValue}-01T00:00:00`).startOf('month').toISOString();
      endDate = moment(`${this.filterData.monthValue}-01T00:00:00`).add('+1', 'month').startOf('month').toISOString();
    }

    this.selectFilter.where['and'] = [
      {created: {gt: startDate}},
      {created: {lt: endDate}}
    ];

    if (this.filterData.debtor !== 'all' && this.filterData.debtor) {
      // @ts-ignore
      this.selectFilter.where['debtorId'] = {'in': [this.filterData.debtor]};
    } else {
      this.selectFilter.where['debtorId'] = {exists: true};
    }
    this.filteredData = {
      all: [],
      grouped: [],
    };
    this.loadData(this.selectFilter);
  }

  reloadAllData() {
    this.multiSelectBox = {};
    this.multiSelectDebtor = {};
    this.toggleMultiAction();
    this.filteredData = {
      all: [],
      grouped: [],
    };
    this.reloadAllDataEmitter.emit();
  }

  afterFilter() {
    let currentDebtor;
    this.filteredData['grouped'] = [];
    this.filteredData['debtors'] = [];

    this.filteredData['all'].forEach((r) => {
      currentDebtor = this.findDebtorId(r);
      if (!this.filteredData['grouped'][currentDebtor]) {
        this.filteredData['grouped'][currentDebtor] = [];
        if (r.debtorId) {
          const debtor = r.Debtor;
          let total = 0;
          if (this.filteredData['grouped'][currentDebtor].length > 0) {
            this.filteredData['grouped'][currentDebtor].forEach((rTotal) => {
              total += rTotal.price.total;
            });
          } else {
            total += r.price.total;
          }

          this.filteredData['debtors'].push({
            'debtorId': r.debtorId,
            'type': 'header',
            'code': debtor.code,
            'reference': debtor.reference,
            'name': debtor.companyName,
            'total': {
              'total': total,
              'currency': r.price.currency
            },
            'contact': `${debtor.firstName} ${debtor.lastName}`
          });
          this.filteredData['grouped'][currentDebtor].push(r);
        } else {
          const passenger = (r.Passenger ? r.Passenger : r.passenger);
          let total = 0;
          if (this.filteredData['grouped'][currentDebtor].length > 0) {
            this.filteredData['grouped'][currentDebtor].forEach((rTotal) => {
              total += rTotal.price.total;
            });
          } else {
            total += r.price.total;
          }

          this.filteredData['debtors'].push({
            'debtorId': currentDebtor,
            'type': 'header',
            'reference': ucFirst(this.translations['passenger']),
            'name': ucFirst(this.translations['passenger']),
            'total': {
              'total': total,
              'currency': r.price.currency
            },
            'contact': `${passenger.fname} ${passenger.lname}`
          });
          this.filteredData['grouped'][currentDebtor].push(r);
        }
      } else {
        this.filteredData['grouped'][currentDebtor].push(r);
      }
    });
    this.stopLoader();
  }

  multiAction(action, cnt): void {
    const self = this;
    const items = [];
    Object.keys(self.filteredData['grouped']).forEach(function (key) {
      const debtorJobs = self.filteredData['grouped'][key];
      debtorJobs.forEach((j) => {
        if (self.multiSelect.includes(j.id)) {
          const currentDebtor = self.findDebtorId(j);
          if (!items[currentDebtor]) {
            items[currentDebtor] = self.filteredData['debtors'].filter((d) => {
              return (`${d.debtorId}` === `${currentDebtor}` ? d : false)
            })[0];
            items[currentDebtor].jobs = [];
          }
          items[currentDebtor].jobs.push(j);
        }
      });
    });
    this._dialogService.open(CreateDraftInvoicesComponent, {
      maxHeight: '80%',
      width: '570px',
      data: {
        parent: self,
        context: self.context,
        action: action,
        'invoiceType': 'CompanyToDebtor',
        items: items,
      }
    });
    return;
  }

  findDebtorId(ride: Ride): string {
    let currentDebtor = (ride.debtorId ? ride.debtorId : ride.passengerId);
    if (!currentDebtor && ride.passenger) {
      currentDebtor = md5(JSON.stringify(ride.passenger));
    }
    return currentDebtor;
  }

  toggleMultiActionAll(): void {
    const self = this;
    self.filteredData['all'].forEach((ride) => {
      self.multiSelectBox[ride.id] = (self.allSelected);
    });
    self.toggleMultiAction();
  }

  toggleDebtorAction(debtorId): void {
    this.filteredData['grouped'][debtorId].forEach((i) => {
      this.multiSelectBox[i.id] = (this.multiSelectDebtor[debtorId]);
    });
    this.toggleMultiAction();
  }

  toggleMultiAction(): void {
    const self = this;
    self.multiSelect = [];
    self.multiSelected = false;
    Object.keys(self.multiSelectBox).forEach(function (key) {
      if (self.multiSelectBox[key]) {
        self.multiSelect.push(key);
        self.multiSelected = true;
      } else {
        self.allSelected = false;
        const debtorId = self.findDebtorId(self.originalData['all'].filter((r) => {
          return (r.id === key)
        })[0]);
        self.multiSelectDebtor[debtorId] = false;
      }
    });

    self.multiSelectedState = (self.multiSelected ? 'expanded' : 'collapsed');
    if (self.multiSelect.length > 0) {
      self.jobSelected = true;
    } else {
      self.jobSelected = false;
    }
  }

  selectAllJobs(): void {
    const self = this;
    self.multiSelect = [];
    self.allSelected = (!self.allSelected);
    if (self.allSelected && this.originalData['all'].length > 0) {
      self.originalData['all'].forEach((job) => {
        self.multiSelectBox[job.id] = job.id;
        self.multiSelect.push(job.id);
        self.multiSelectDebtor[self.findDebtorId(job)] = true;
      });
      self.jobSelected = true;
      self.multiSelectedState = 'expanded'
    } else {
      self.multiSelect = [];
      self.multiSelected = false;
      self.multiSelectBox = [];
      self.multiSelectDebtor = [];
      self.jobSelected = false;
      self.multiSelectedState = 'collapsed';
    }
  }

  switchTabEmit(tab) {
    this.switchTab.emit(tab);
  }
}
