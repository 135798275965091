import {Component, Injector} from '@angular/core';
import {CollectionComponent} from '../../../global/collection/collection.component';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../../../environments/environment';
import {PromotionService} from '../../../../services/promotion.service';
import {Promotion} from '../../../../models/promotion';
import {TdDialogService} from '@covalent/core/dialogs';
import {Company} from '../../../../models/company';
import {UtilityService} from '../../../../services/utility.service';

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss'],
  providers: [PromotionService]
})
export class PromotionsComponent extends CollectionComponent {
  loaderName = 'policy-list';
  modelName = 'promotions';
  company: Company;
  columns = [
    {name: 'name', label: 'name', sortable: true, active: true},
    {name: 'startDate', label: 'start_date', sortable: true, active: true},
    {name: 'endDate', label: 'end_date', sortable: true, active: true},
    {name: 'lastPushDate', label: 'last_push_date', sortable: true, active: true},
    {name: 'lastPushSendCount', label: 'last_push_send_count', sortable: false, active: true},
    {name: 'tools', label: 'tools', sortable: false, active: false}
  ];

  constructor(private _translateService: TranslateService,
              protected injector: Injector,
              private _promotionService: PromotionService,
              private _snackbar: MatSnackBar,
              private _dialogService: TdDialogService,
              private _titleService: Title) {
    super(injector);
    const {company} = this._route.parent.snapshot.data;
    UtilityService.setBrowserTimeStamp(this.company);
    this.company = company;
    this.originalData = {
      published: [],
      archived: [],
    }
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);

    this._route.parent.params.subscribe(params => {
      this.companyId = params['id'];
      _translateService.get(['promotions_page_title', 'promotion_archived', 'promotion_published']).subscribe((translations: any) => {
        this._titleService.setTitle(translations['promotions_page_title'] + environment.windowTitleSuffix);
        this._navigationService.setBodyTitle(translations['promotions_page_title']);
        this.translations = translations;
      });
    });
  }

  loadData = () => {
    const query = {
      'where': {
        'companyId': this.companyId
      },
      'order': 'created ASC'
    };

    this._promotionService.getAll(query).subscribe((promotions: Promotion[]) => {
      // const self = this;
      this.collectionData = [];
      this.filteredData = [];

      promotions.forEach((promotion, i) => {
        this.collectionData.push(promotion);
      });
      this.originalData['published'] = this.collectionData.filter((d) => {
        return (d.status === 'published')
      });
      this.originalData['archived'] = this.collectionData.filter((d) => {
        return (d.status === 'archived')
      });

      this.filter('published');
      this.filter('archived');
      this._loadingService.resolve('promotions.list');
    }, (error => {
      console.error(error);
      this._loadingService.resolve('promotions.list');
    }));
  }

  archive(id: string) {
    const self = this;
    self._promotionService.update(id, {status: 'archived'}).subscribe((r) => {
      self._snackbar.open(this.translations['promotion_archived'], '', {duration: 3000});
      self.loadData();
    });
  }

  publish(id: string) {
    const self = this;
    self._promotionService.update(id, {status: 'published'}).subscribe((r) => {
      self._snackbar.open(this.translations['promotion_published'], '', {duration: 3000});
      self.loadData();
    });
  }
}
