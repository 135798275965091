<div [mediaClasses]="['push-sm']" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <div flex-gt-sm="50">
    <ride-count-widget [companyId]="companyId"
                       buttonLink="/groups/{{companyId}}/jobs"
                       buttonText="View all jobs"
                       subTitle="dashboard_rides_month_breakdown"
                       title="rides"
                       type="company">
    </ride-count-widget>
    <!--<booking-breakdown-widget title="test"-->
    <!--subTitle="test"-->
    <!--[colorScheme]="rideStatusColors"-->
    <!--cssClasses="push-top"-->
    <!--type="company"-->
    <!--[companyId]="companyId">-->
    <!--</booking-breakdown-widget>-->
    <!--<ratings-widget-->
    <!--title="ratings"-->
    <!--subTitle="company_dashboard_ratings_subtitle"-->
    <!--cssClasses="push-top"-->
    <!--[ratings]="ratings">-->
    <!--</ratings-widget>-->
  </div>
  <div flex-gt-sm="50">
    <ride-status-widget [colorScheme]="rideStatusColors"
                        [companyId]="companyId"
                        buttonLink="/groups/{{companyId}}/jobs"
                        buttonText="View all jobs"
                        subTitle="dashboard_status_breakdown_subtitle"
                        title="rides"
                        type="company">
    </ride-status-widget>
  </div>
</div>
