import {Company} from './company';

export class CompanyWebUser {
  fname?: string;
  lname?: string;
  phoneNumber?: string;
  emailAddress?: string;
  email?: string;
  id?: string;
  status?: string;
  type?: string;
  companyId?: string;
  userId?: string;
  created?: string;
  modified?: string;
  shadow?: boolean;
  daAppInstallId?: string;
  Company?: Company;
  country?: string;
  password?: string;

  constructor() {
    this.id = '';
    this.phoneNumber = '';
    this.fname = '';
    this.lname = '';
    this.type = 'CompanyWebUser';
    this.password = this.generatePassword(8);
  }

  generatePassword(length: number): string {
    const charset = 'abcdefghjkmnpqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ123456789';
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }
}
