import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Price} from '../../../../../../../models/price';
import {UntypedFormGroup} from '@angular/forms';
import {getCurrencySymbol} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {PriceMeta} from '../../../../../../../models/price-meta';
import {PaymentMeta} from '../../../../../../../models/payment-meta';
import {TranslateService} from '@ngx-translate/core';
import {Ride} from '../../../../../../../models/ride';
import {Company} from '../../../../../../../models/company';
import {Passenger} from '../../../../../../../models/passenger';
import {Product} from '../../../../../../../models/product';
import {PaymentOverviewComponent} from '../../../../../../company/administration/payment-overview/payment-overview.component';
import {ucFirst} from '../../../../../../../pipes/uc-first.pipe';
import {TdDialogService} from '@covalent/core/dialogs';
import {PricingRuleService} from '../../../../../../../services/tps/pricing-rule.service';
import {Rule} from '../../../../../../../models/rule';
import {CoolLocalStorage} from '@angular-cool/storage';

const fromCents = (value: number) => (value / 100).toFixed(2);

@Component({
  selector: 'app-ride-edit-price',
  templateUrl: './ride-edit-price.component.html',
  styleUrls: ['./ride-edit-price.component.scss'],
  providers: [PricingRuleService]
})
export class RideEditPriceComponent implements OnInit {
  @ViewChild('picker') picker: any;
  @Input() context: string;
  @Input() price: Price;
  @Input() company: Company;
  @Input() passenger: Passenger;
  @Input() isOwner: Boolean;
  @Input() form: UntypedFormGroup;
  @Input() ride: Ride;
  @Input() mode: string;
  @Input() priceMeta: PriceMeta;
  @Input() paymentMeta: PaymentMeta;
  @Input() priceMethod: string;
  @Input() formErrors: any;
  @Output() conditionalFormatting = new EventEmitter<string>();
  @Output() pricingError = new EventEmitter<RideEditPriceComponent>();

  calculationMethod: string;
  currencySymbol: string;
  currencyDisplay: string;
  totalFormat: string;
  translations: string[] = [];
  hasBeenChecked = false;
  sameData = false;
  firstLoaded = false;
  hasBaseRule: any = false;
  groupPriceDisabled = '';
  showBaseRuleError = false;
  originalCommission:any;
  priceEstimate = {
    total: 0
  };
  paymentStatus: string;
  paymentStatusColor: string;
  originalOpenBalance: number;

  constructor(
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private _dialogService: TdDialogService,
    private _pricingRuleService: PricingRuleService,
    private _vault: CoolLocalStorage,
  ) {
    const self = this;
    translateService.get(['onmeter', 'select_passenger_paymentmethod', 'select_passenger_create_new', 'payment_status_pickup_at', 'job_fleet_price_not_found', 'job_fleet_price_not_found_message', 'job_fleet_price_no_destination', 'job_fleet_price_no_destination_found', 'yes', 'no', 'pricemethod_changed_reset_fixed_price', 'payment_status_pickup_at'
    ])
      .subscribe((translations: any) => {
        self.translations = translations;
      });
  }

  ngOnInit() {
    if (this.context === 'company') {
      const {company} = this.route.parent.snapshot.data;
      this.currencySymbol = getCurrencySymbol(company.currency, 'narrow');

      if (company) {
        const item = this._vault.getItem(`${company.id}_hasBaseRule`);
        if (!item) {
          this._pricingRuleService.getAll({
            where: {
              taxiMeter: true,
              companyId: company.id,
              or: [
                {parentRuleId: {exists: false}},
                {parentRuleId: null}
              ]
            },
            include: [{
              relation: 'products',
              scope: {
                include: 'prices'
              }
            }]
          }).subscribe((results: Rule[]) => {
            if (results.length > 0) {
              const nResults = results[0].products.filter((p) => {
                const prices = p.prices.filter((p) => {
                  return (p.ruleId === results[0]['_id'] && p.isEnabled)
                });
                return (prices.length > 0)
              });
              const saveResult = nResults.map((n) => {
                return {
                  id: n._id
                }
              });
              this._vault.setItem(`${company.id}_hasBaseRule`, JSON.stringify(saveResult));
              this.hasBaseRule = saveResult;
            } else {
              this._vault.removeItem(`${company.id}_hasBaseRule`)
            }

            this.driverCut();
          });
        } else {
          this.hasBaseRule = JSON.parse(item);
        }
      }
    } else {
      this.currencySymbol = getCurrencySymbol('EUR', 'narrow');
    }

    if (this.price) {
      this.currencyDisplay = getCurrencySymbol(this.price.currency, 'narrow');
      this.totalFormat = parseFloat(`${this.price.total}`).toFixed(2);
    }

    if (!this.priceMethod) {
      this.priceMethod = this.form.controls['priceMethod'].value;
    }

    this.rideLoaded();
  }

  rideLoaded() {
    const self = this;
    if (self.translations.length < 1) {
      setTimeout(() => {
        self.rideLoaded();
      }, 400);
      return;
    }

    if (this.mode === 'edit' && this.ride.id) {
      const details = (PaymentOverviewComponent.showPaymentStatus(this.translations, this.company, ((this.ride.Payments && this.ride.Payments.length > 0) ? this.ride.Payments[this.ride.Payments.length - 1] : null), this.ride));
      this.paymentStatus = details.paymentStatus;
      this.paymentStatusColor = details.paymentStatusColor;

      this.originalOpenBalance = this.ride.openBalance;
      this.originalCommission = this.ride.commission.total;
    } else {
      setTimeout(function () {
        self.rideLoaded();
      }, 500);
    }
  }

  emitConditionalFormatting() {
    this.conditionalFormatting.emit();
  }

  /**
   * If input element value is not set, make it zero.
   */
  valOrZero(input) {
    if (!input.value) {
      input.value = 0.00;
    }
    input.value = parseFloat(input.value).toFixed(2);
  }

  inputFocused(event) {
    if (this.form.controls.setManualCommission.value === 'manually' || this.priceMethod !== 'onMeter') {
      event.target.select();
    }
  }

  loaded(event) {
  }

  priceMethodChanged(confirmed = false): void {
    const self = this;
    if (this.form.controls['product'].value && this.form.controls['product'].value.price) {
      this.priceEstimate.total = this.form.controls['product'].value.price.total;
    }

    if (this.ride && this.ride.id && this.ride.price && this.ride.price.total > 0 && !confirmed) {
      this._dialogService.openConfirm({
        message: ucFirst(this.translations['pricemethod_changed_reset_fixed_price']),
        disableClose: true,
        width: '400px',
        cancelButton: this.translations['no'],
        acceptButton: this.translations['yes'],
      }).afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          self.priceMethodChanged(true);
        }
      });
    } else {
      if (this.form.controls['product'].value && this.form.controls['product'].value.price && this.form.controls['product'].value.price.total > 0) {
        // @ts-ignore
        if (this.form.controls['paymentMeta'].controls['paymentMoment'].value === 'completed') {
          // @ts-ignore
          this.form.controls['paymentMeta'].controls['paymentMoment'].setValue('pickuptime');
        } else if (this.form.controls['priceMethod'].value === 'onMeter') {
          // @ts-ignore
          this.form.controls['paymentMeta'].controls['paymentMoment'].setValue('completed');
        }
      }

      this.priceMethod = this.form.controls['priceMethod'].value;
      if (this.form.controls['priceMethod'].value === 'onMeter') {
        // @ts-ignore
        this.form.controls['price'].controls['total'].setValue(0);
      } else if (this.priceEstimate.total) {
        // @ts-ignore
        this.form.controls['price'].controls['total'].setValue((this.priceEstimate.total / 100).toFixed(2));
      }
    }
  }

  calcMethodChanged(): void {
  }

  priceCalculationPricingError(data) {
    this.pricingError.emit(data);
  }

  productChanged(data: any): void {
    if (!this.sameData && this.ride.products) {
      this.sameData = (!!this.ride.products.filter((p: Product) => {
        return (p.sku === this.form.controls['product'].value.sku || p._id === this.form.controls['product'].value.productId);
      })[0]);
    }

    if (this.form.controls['product'].value) {
      const product = JSON.parse(JSON.stringify(this.form.controls['product'].value));

      if (product && ((product.price && product.price.total === 0) || !product.price)) {
        /**
         * cannot select pricing rule!
         */

        if (!this.hasBaseRule || !this.hasBaseRule.filter((r) => {
          return (r.id === product.productId);
        })[0]) {
          this.groupPriceDisabled = 'group-price-disabled';
          this.showBaseRuleError = true;
        } else {
          this.groupPriceDisabled = '';
          this.showBaseRuleError = false;
        }

        if (!this.ride.id) {
          this.form.controls['priceMethod'].setValue((product.isFixed ? 'fixed' : 'onMeter'));
        }

        if (this.form.controls['priceMethod'].value === 'onMeter' && typeof (product.priority) !== 'undefined') {
          this.form.controls['calculationMethod'].setValue('manual');
          this.priceMethodChanged();
        }
      } else if (product && product.price && (!this.sameData || this.hasBeenChecked)) {
        this.groupPriceDisabled = '';
        this.priceEstimate.total = product.price.total;

        if (!this.ride.id) {
          this.form.controls['price'].setValue(product.price);
          this.form.controls['priceMethod'].setValue((product.isFixed ? 'fixed' : 'onMeter'));
          this.form.controls['calculationMethod'].setValue((this.context === 'company' ? 'group_pricing_rules' : 'manual'));

          this.price = JSON.parse(JSON.stringify(this.form.controls['price'].value));
          if (this.price) {
            this.currencyDisplay = getCurrencySymbol(this.price.currency, 'narrow');
            this.totalFormat = parseFloat(`${(this.price.total / 100)}`).toFixed(2);
            // this.form.controls['product'].value.price.total =
            this.price.total = (this.price.total / 100).toFixed(2);
            this.form.controls['price'].setValue(this.price);
            this.priceMethodChanged();
          }
        }
      }
    }

    if (this.sameData && !this.hasBeenChecked) {
      this.hasBeenChecked = true;
    }

    if (this.ride.id) {

    }
  }

  hourlyRateUpdated() {
    // @ts-ignore
    const split = this.form.controls['priceMeta'].controls['bookedDuration'].value.split(':');
    // @ts-ignore
    // tslint:disable-next-line:radix
    const hours = (parseInt(split[0]) + (parseInt(split[1]) / 60)) * 100;
    this.priceEstimate.total = 0;
    // @ts-ignore
    this.priceEstimate.total = this.form.controls['priceMeta'].controls['hourlyRate'].value * hours;
  }

  showNoPrice() {
    this._dialogService.openAlert({
      message: ucFirst(this.translations['job_fleet_price_not_found_message']),
      disableClose: true,
      width: '400px',
      title: ucFirst(this.translations['job_fleet_price_not_found']),
      closeButton: this.translations['ok']
    });
  }

  priceChanged() {
    if (this.ride) {
      this.ride.openBalance = this.originalOpenBalance - (0 - ((this.ride.price.total * 100) - (this.form.controls['price'].value.total * 100)));
      this.driverCut();
    }
  }

  driverCut() {
    const price = this.form.controls.price['controls'].total.value;
    if (this.form.controls.commission['controls'].total.value > price) {
      this.form.controls.commission['controls'].total.setValue(price);
    }

    const commission = this.form.controls.commission['controls'].total.value;
    const floatPrice = parseFloat(String((price - commission * 100))).toFixed(2) + '';
    this.form.controls.driverSettlement['controls'].total.setValue(floatPrice);
  }

  commissionSettingChanged() {
    this.form.controls.commission['controls'].total.setValue(this.originalCommission);
    this.driverCut();
  }
}
