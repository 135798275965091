<app-no-contract *ngIf="(this.context==='company' && (!this.company.ActiveContract ||
!['active','past_due', null].includes(this.company.ActiveContract.foreignStatus)))"
                 [companyId]="this.companyId" [contract]="this.company.ActiveContract"></app-no-contract>
<mat-card [class]="(this.context==='company' &&
(!this.company.ActiveContract ||
(this.company.hasDispatcherLicense && !this.company.ActiveContract.foreignStatus)) ? 'blurText' :'')" [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">

  <td-search-box (searchDebounce)="search($event)"
                 [alwaysVisible]="true"
                 class="push-left push-right"
                 placeholder="{{'search_here'|translate}}">
  </td-search-box>

  <mat-divider></mat-divider>

  <ng-template tdLoading="point">
    <mat-tab-group
      (selectedTabChange)="onTabChange($event)">
      <mat-tab *ngFor="let type of types" [label]="type">
        <ng-template *ngIf="filteredData[type]" mat-tab-label>
          <mat-icon>{{ typeIcon(type) }}</mat-icon>
          {{ typeName(type) | ucFirst }} ({{filteredData[type].length}})
        </ng-template>

        <table class="mat-clickable" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="sort($event)"
                *ngFor="let c of typeColumn(type, columns)"
                [active]="(sortBy == c.name)"
                [name]="c.name"
                [numeric]="c.numeric"
                [sortOrder]="( sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
                [sortable]="c.sortable"
                td-data-table-column>
              {{ c.label }}
            </th>
          </tr>
          </thead>

          <tbody class="noselect clickable">
          <tr (click)="click(item._id)"
              *ngFor="let item of filteredData[type]"
              class="noselect"
              td-data-table-row>

            <td *ngFor="let c of typeColumn(type, columns)"
                [numeric]="item.numeric"
                td-data-table-cell>
                <span [ngSwitch]="c.name">

                  <span *ngSwitchCase="'descriptor'">
                      <span *ngIf="item[c.name]">
                        {{item[c.name] | translate | ucFirst}}
                      </span>
                  </span>

                  <span *ngSwitchCase="'address'">
                    {{ item[c.name] || '-' | ucFirst }}
                  </span>

                  <span *ngSwitchCase="'policy'">
                    {{ item[c.name] || '-' | ucFirst }}
                  </span>

                  <span *ngSwitchCase="'apps'">
                    <span *ngIf="item[c.name]" class="wrap-line">
                      <span matTooltip="{{item['tooltip']}}">{{item[c.name]}}</span>
                      </span>
                    <span
                      *ngIf="!item[c.name]">-</span>
                  </span>

                  <span *ngSwitchDefault>
                    <span *ngIf="item[c.name]">{{item[c.name] | ucFirst}}</span>
                  </span>
                </span>
            </td>
          </tr>
          </tbody>
        </table>

        <td-paging-bar #pagingBar
                       (change)="page($event)"
                       [pageSize]="100"
                       [total]="(paging[type] ? paging[type].total : 0)">
          <span hide-xs>{{'rows_p_page'|translate}}:</span>
          {{pagingBar.range}}
          <span hide-xs>of {{pagingBar.total}}</span>
        </td-paging-bar>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</mat-card>
