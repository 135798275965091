import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {InvoiceFilter} from '../../../invoice-filter';
import {ActivatedRoute} from '@angular/router';
import {DebtorService} from '../../../../../../services/debtor.service';
import {Debtor} from '../../../../../../models/debtor';
import {Company} from '../../../../../../models/company';
import {RideService} from '../../../../../../services/ride.service';
import {CoolLocalStorage} from '@angular-cool/storage';
import moment from 'moment';
import {environment} from '../../../../../../../environments/environment';

@Component({
  selector: 'app-to-be-invoiced-filter',
  templateUrl: './to-be-invoiced-filter.component.html',
  styleUrls: ['./to-be-invoiced-filter.component.scss']
})
export class ToBeInvoicedFilterComponent implements OnInit {
  @Output() filterChanged = new EventEmitter<InvoiceFilter>();
  @Output() searchChanged = new EventEmitter<string>();
  @Input() senders: any[];
  @Input() context: string;
  @Input() type: string;
  @Input() companyId: string;
  searchTerm: string;
  company: Company;
  currentYear: number;
  months = [];
  yearFilterValues: number[] = [];
  debtors: Debtor[] = [];
  monthFilterValues = [];
  filterData: InvoiceFilter;
  invoiceStatusses = ['all', 'final', 'paid'];
  paymentMethods = [
    {
      'id': 'inTaxi',
      'description': 'payment_origin_intaxi',
      'directProcess': true
    },
    {
      'id': 'invoice',
      'description': 'payment_origin_invoice',
      'directProcess': true
    },
    {
      'id': 'online',
      'description': 'payment_origin_online',
      'directProcess': true
    }
  ];

  constructor(
    private _translateService: TranslateService,
    private _debtorService: DebtorService,
    private route: ActivatedRoute,
    public _vault: CoolLocalStorage,
    private _rideService: RideService,
  ) {
    const {company} = this.route.parent.snapshot.data;
    const self = this;
    this.company = company;
    _translateService.get([
      'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'
    ]).subscribe(translations => {
      this.currentYear = new Date().getFullYear();
      if (!this.filterData) {
        this.filterData = {
          year: this.currentYear,
          from: new Date().getFullYear(),
          to: (new Date().getFullYear() + 1),
          status: ['all'],
          paymentMethods: ['invoice'],
          monthValue: {
            value: self._vault.getItem(`${environment.vaultPrefix}.toBeInvoiced-Month-Filter`)
          },
          debtor: 'all',
          context: this.context,
        };
      } else {
        this.filterData.year = this.currentYear;
      }
      this.months = [
        translations.january,
        translations.february,
        translations.march,
        translations.april,
        translations.may,
        translations.june,
        translations.july,
        translations.august,
        translations.september,
        translations.october,
        translations.november,
        translations.december
      ];

      const currentValue = self._vault.getItem(`${environment.vaultPrefix}.toBeInvoiced-Month-Filter`)
      _rideService.getAll({sort: 'created asc', limit: 1}).subscribe((firstRide) => {
        const startMonth = moment(firstRide[0].created).format('M');
        const startYear = moment(firstRide[0].created).format('Y');
        for (let y: number = parseInt(startYear, 10); y < (this.currentYear + 1); y++) {
          const lStartMonth = (parseInt(startYear, 10) === this.currentYear ? parseInt(startMonth, 10) : 1);
          const lEndMonth = (y === this.currentYear ? (parseInt(moment().format('M'), 10) + 1) : 13);
          for (let x: number = lStartMonth; x < lEndMonth; x++) {
            this.monthFilterValues.push({year: y, month: x, value: `${y}-${(x < 10 ? '0' : '')}${x}`, desc: `${this.months[x - 1]} ${y}`});
          }
        }

        if (currentValue) {
          this.filterData.monthValue = currentValue;
        } else if (this.monthFilterValues[this.monthFilterValues.length - 1]) {
          this.filterData.monthValue = this.monthFilterValues[this.monthFilterValues.length - 1].value;
        } else {
          this.monthFilterValues.push({
            year: this.currentYear,
            month: moment().format('MM'),
            desc: `${this.months[parseInt(moment().format('MM'), 10) - 1]} ${this.currentYear}`,
            value: `${moment().format('YYYY')}-${moment().format('MM')}`
          });
          this.filterData.monthValue = this.monthFilterValues[this.monthFilterValues.length - 1].value;
          this.filterChanged.emit(this.filterData);
        }
      });
    });

    this.context = this.route.routeConfig['context'] || 'driver';
    this.filterData = {
      year: new Date().getFullYear(),
      from: 1,
      to: 12,
      status: ['all'],
      paymentMethods: ['invoice'],
      debtor: 'all',
      context: this.context
    };

    const storedPaymentMethods = this._vault.getItem(`${environment.vaultPrefix}.toBeInvoiced-paymentMethod-Filter`);
    if (JSON.parse(storedPaymentMethods)) {
      this.filterData.paymentMethods = JSON.parse(storedPaymentMethods);
    }
    if (this.company.id) {
      this.loadDebtors();
    }
  }

  ngOnInit() {
  }

  loadDebtors(): void {
    this._debtorService.getAll({where: {ownerId: this.company.id}})
      .subscribe((debtors) => {
        this.debtors = debtors;
      });
  }

  onToChanged(event: any): void {
    this.filterData.monthValue = event.value;
    this._vault.setItem(`${environment.vaultPrefix}.toBeInvoiced-Month-Filter`, this.filterData.monthValue);
    this.filterChanged.emit(this.filterData);
  }

  onPaymentMethodChange(event: any): void {
    this._vault.setItem(`${environment.vaultPrefix}.toBeInvoiced-paymentMethod-Filter`, JSON.stringify(this.filterData.paymentMethods));
    this.filterChanged.emit(this.filterData);
  }

  onDebtorChanged(event: any): void {
    this.filterData.debtor = event.value;
    this.filterChanged.emit(this.filterData);
  }

  onSearchChanged(searchTerm: string): void {
    this.searchChanged.emit(searchTerm);
  }
}
