import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Driver} from '../../../../../../../models/driver';
import {Company} from '../../../../../../../models/company';
import {DispatchDialogComponent} from '../../../dispatch-dialog/dispatch-dialog.component';
import {TdDialogService} from '@covalent/core/dialogs';
import {DriverService} from '../../../../../../../services/driver.service';

@Component({
  selector: 'app-ride-edit-driver',
  templateUrl: './ride-edit-driver.component.html',
  styleUrls: ['./ride-edit-driver.component.scss']
})
export class RideEditDriverComponent implements OnInit {
  @Output() newDriverEvent = new EventEmitter<string>();
  @Input() context: string;
  @Input() driver: Driver;
  @Input() myDriver: Driver;
  @Input() company: Company;
  @Input() reference: string;
  @Input() rideId: string;
  @Input() departureGps: any;
  name = '-';

  constructor(
    private _dialogService: TdDialogService,
    private _driverService: DriverService,
  ) {
  }

  ngOnInit(): void {
    if (this.driver) {
      this.name = `${this.driver.fname} ${this.driver.lname}`;
    }
  }

  close() {
    this._dialogService.closeAll();
  }

  selectDriver(driverId) {
    const self = this;
    this._driverService.get(driverId, {}).subscribe((driver) => {
      self.driver = driver;
      self.newDriverEvent.emit(driver);
    });
  }

  /**
   * Open Pricing Rule Dialog.
   */
  openDispatchDialog() {
    this._dialogService.open(DispatchDialogComponent, {
      maxHeight: '80%',
      minWidth: '600px',
      disableClose: false,
      data: {parent: this},
    });
  }
}
