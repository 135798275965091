import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {ShiftService} from '../../../../../services/shift.service';
import {ITdDataTableSortChangeEvent, TdDataTableService, TdDataTableSortingOrder} from '@covalent/core/data-table';
import {IPageChangeEvent, TdPagingBarComponent} from '@covalent/core/paging';
import {TdLoadingService} from '@covalent/core/loading';
import {TdMediaService} from '@covalent/core/media';
import {ActivatedRoute} from '@angular/router';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {AgmMap, GoogleMapsAPIWrapper, LAZY_MAPS_API_CONFIG, MapsAPILoader} from '@agm/core';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {UtilityService} from '../../../../../services/utility.service';
import {GpslogentriesService} from '../../../../../services/gpslogentries.service';
// import moment from 'moment';
// import {RideService} from '../../../../../services/ride.service';
// import {RatingService} from '../../../../../services/rating.service';
import {CustomLazyAPIKeyLoader} from '../../../../../services/CustomLazyAPIKeyLoader';
import {GoogleMapConfig} from '../../../../googleMapConfig';
// import { LocationUtility } from 'location-utilities';
// import { haversine } from 'earth-distance-js';
// import { equirectangular } from 'earth-distance-js';

declare const $: any;

@Component({
  selector: 'app-shift-details',
  templateUrl: './shift-details.component.html',
  styleUrls: ['./shift-details.component.scss'],
  providers: [ShiftService,
    GoogleMapsAPIWrapper,
    {provide: MapsAPILoader, useClass: CustomLazyAPIKeyLoader},
    {provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapConfig}]
})
export class ShiftDetailsComponent implements OnInit, AfterViewInit {

  @ViewChild('listViewPagingBar') lvPagingBar: TdPagingBarComponent;
  @ViewChild('gpsLogPagingBar') glPagingBar: TdPagingBarComponent;

  @ViewChild(AgmMap) map: AgmMap;
  mapInstance: any;
  iconUrl: string;
  mapLineColor: string;
  speedUnit: string;

  shiftId: string;
  driverId: string;
  context: string;
  gpsSkip = 0;

  shift: any = {
    Vehicle: {},
    startGps: {},
    endGps: {},
    Rides: [],
    ShiftEntries: []
  };
  gpsLogEntries: any = [];
  markerGpsLogEntries: any = [];

  listViewColumns = [
    {name: 'code', label: 'status', sortable: true, active: false},
    {name: 'startTime', label: 'start_time', sortable: true, active: true},
    {name: 'startLocation', label: 'start_location', sortable: true, active: false},
    {name: 'endTime', label: 'end_time', sortable: true, active: false},
    {name: 'endLocation', label: 'end_location', sortable: true, active: false}
  ];

  listViewData: any[] = [];
  listViewFilteredData: any[] = [];
  listViewFilteredTotal: number;
  listViewSearchTerm = '';
  listViewFromRow = 1;
  listViewCurrentPage = 1;
  listViewPageSize = 20;
  listViewSortBy = 'startTime';
  listViewSortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Ascending;

  gpsLogColumns = [
    {name: 'created', label: 'timestamp', sortable: true, active: true},
    {name: 'code', label: 'status', sortable: true, active: false},
    {name: 'speed', label: 'speed', sortable: true, active: false},
    {name: 'accuracy', label: 'gps_accuracy', sortable: true, active: false},
    {name: 'gps', label: 'gps', sortable: false, active: false}
  ];

  gpsLogData: any[] = [];
  gpsLogFilteredData: any[] = [];
  gpsLogFilteredTotal: number;
  gpsLogSearchTerm = '';
  gpsLogFromRow = 1;
  gpsLogCurrentPage = 1;
  gpsLogPageSize = 20;
  gpsLogSortBy = 'created';
  gpsLogSortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;

  ridesColumns = [
    {name: 'intId', label: 'ride_id', sortable: true, active: true},
    {name: 'requestedDate', label: 'requested_date', sortable: true, active: false},
    {name: 'departure', label: 'departure', sortable: true, active: false},
    {name: 'destination', label: 'destination', sortable: true, active: false},
    {name: 'passengerCount', label: 'passengers', sortable: true, active: false},
    {name: 'status', label: 'status', sortable: false, active: false}
  ];

  ridesData: any[] = [];
  ridesFilteredData: any[] = [];
  ridesFilteredTotal;
  ridesSearchTerm = '';
  ridesFromRow = 1;
  ridesCurrentPage = 1;
  ridesPageSize = 20;
  ridesSortBy = 'identifier';
  ridesSortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;

  constructor(public media: TdMediaService,
              private _loadingService: TdLoadingService,
              private _route: ActivatedRoute,
              private _dataTableService: TdDataTableService,
              private _activatedRoute: ActivatedRoute,
              private _shiftService: ShiftService,
              private _gpsLogEntriesService: GpslogentriesService,
              private _translateService: TranslateService,
              private _titleService: Title) {
    const self = this;

    _translateService.get('shift_details').subscribe((translation: string) => {
      this._titleService.setTitle(translation + environment.windowTitleSuffix);
    });
    this.context = this._route.routeConfig['context'] || 'driver';
    const params: any = this._activatedRoute.snapshot.params;

    if (self.context === 'company') {
      this.driverId = params.id;
      this.shiftId = params.shiftId;
    } else {
      this.shiftId = params.id;
    }

    this.iconUrl = `/assets/${environment.buildName}/icon-marker.png`;
    this.mapLineColor = `${environment.mapLineColor}`;
    this.speedUnit = `${environment.speedUnit}`;
  }

  ngOnInit() {
    this._loadingService.register('shift.details');
    this._loadingService.register('gps.loader');
    this._loadingService.register('gps.mapLoader');
    this.loadData();
  }

  ngAfterViewInit() {
    this.media.broadcast();
  }

  loadData(): void {
    const query = {
      'include': [
        'Vehicle',
        {
          'relation': 'ShiftEntries',
          'scope': {
            'order': 'startTime ASC'
          }
        },
        {
          'relation': 'Rides'
        }
      ]
    };
    this._shiftService.get(this.shiftId, query, this.context).subscribe((shift: any) => {
      this.shift = shift;

      const entries = [];
      shift.ShiftEntries.forEach(function (item) {
        entries.push({
          'code': (typeof item.status !== 'undefined' ? item.status.code : ''),
          'startTime': new Date(item.startTime),
          'endTime': (typeof item.endTime !== 'undefined' && item.endTime !== 'null' ? new Date(item.endTime) : ''),
          'startLocation': UtilityService.getLocationString(item.startLocation),
          'endLocation': UtilityService.getLocationString(item.endLocation)
        });
      });

      this.listViewData = entries;
      this.listViewFilteredData = entries;
      this.listViewFilteredTotal = entries.length;

      this.loadGPSLogEntries();
      // const self = this;
      // setInterval(function(){
      //   self.loadGPSLogEntries();
      // },3000)

      const rides = [];
      shift.Rides.forEach(function (item) {
        const id = (item.foreignDispatchId ? item.foreignDispatchId : (item.foreignBookingId ? item.foreignBookingId : item.intId));
        rides.push({
          'id': item.id,
          'intId': id,
          'requestedDate': new Date(item.requestedDate),
          'departure': UtilityService.getLocationString(item.departure),
          'destination': UtilityService.getLocationString(item.destination),
          'passengerCount': item.passengerCount,
          'status': item.status
        });
      });
      this.ridesData = rides;
      this.ridesFilteredData = rides;
      this.ridesFilteredTotal = rides.length;

      this.listViewFilter();
      this.ridesFilter();
    }, error => {
      console.error(error);
      this._loadingService.resolve('shift.details');
    })
  }

  loadGPSLogEntries(gpsSkip = 0): void {
    const self = this;
    if (gpsSkip === 0) {
      this.gpsLogEntries = [];
      this.markerGpsLogEntries = [];
    }
    console.log('loadGPSLogEntries:', gpsSkip);
    this._gpsLogEntriesService.getAll({
      'where': {
        'shiftId': this.shiftId,
        'created': {gt: (gpsSkip ? new Date(gpsSkip).toISOString() : 0)}
      },
      'limit': 3600,
      'order': 'created ASC'
    })
      .subscribe((entries) => {
        const gpsEntries = [];
        const duplicateMinutes = [];
        // const entries2 = [];
        // entries.forEach(function(item, i) {
        //   if (i > 17 && i < 23) {
        //     entries2.push(item);
        //   }
        // });
        // entries = entries2;
        // entries = entries.filter((e) => {
        //   return (e.vehicleStatus.code === 105 && !e.ignore);
        // });

        this.analyseGPSData(entries);

        entries.forEach(function (item) {
          const time = (item.location.gpsTime ? item.location.gpsTime : item.created);
          const created = new Date(time);
          const splitTime = time.split(':');
          const mTime = `${splitTime[0]} ${splitTime[1]}`;

          if (!duplicateMinutes.includes(mTime) || true) {
            duplicateMinutes.push(mTime);
            self.markerGpsLogEntries.push({
              location: item.location,
              created: item.created,
              'activeJob': (item.vehicleStatus.code === 106 || item.vehicleStatus.code === 105 ? true : false)
            });
          }
          gpsEntries.push({
            'created': created,
            'code': (item.vehicleStatus ? item.vehicleStatus.code : ''),
            'speed': item.location.gpsSpeed,
            'vehicleStatus': item.vehicleStatus.state,
            'origin': item.location.gpsOrigin,
            'priority': item.location.gpsPriority,
            'ignore': item.ignore,
            'accuracy': Number(item.location.gpsAccuracy).toFixed(0),
            'gps': `${item.location.internationalAlias} | ${item.location.gps.lat},${item.location.gps.lng}${(item.ignore ? ` | X` : ``)}${(item.appOnBackground ? ` | B` : ``)}`
          });
        });

        this.gpsLogEntries = this.gpsLogEntries.concat(gpsEntries);

        self.gpsLogData = self.gpsLogEntries;
        self.gpsLogFilteredData = self.gpsLogEntries;
        self.gpsLogFilteredTotal = self.gpsLogEntries.length;

        console.log('Done loading:', self.gpsLogData.length);
        console.log('markerGpsLogEntries:', self.markerGpsLogEntries.length);

        if (entries.length === 3600) {
          self.loadGPSLogEntries(gpsEntries[3599].created);
        } else {
          this.gpsLogFilter();
        }
      });
  }

  mapReady(map): void {
    this.mapInstance = map;
  }


  getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2): any {
    const R = 6371; // Radius of the earth in km
    const dLat = this.deg2rad(lat2 - lat1);  // deg2rad below
    const dLon = this.deg2rad(lon2 - lon1);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2)
    ;
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // Distance in km
    return d;
  }

  deg2rad(deg): any {
    return deg * (Math.PI / 180)
  }

  // Converts from degrees to radians.
  toRadians(degrees): any {
    return degrees * Math.PI / 180;
  };

// Converts from radians to degrees.
  toDegrees(radians): any {
    return radians * 180 / Math.PI;
  }

  bearing(startLat, startLng, destLat, destLng): any {
    startLat = this.toRadians(startLat);
    startLng = this.toRadians(startLng);
    destLat = this.toRadians(destLat);
    destLng = this.toRadians(destLng);

    const y = Math.sin(destLng - startLng) * Math.cos(destLat);
    const x = Math.cos(startLat) * Math.sin(destLat) -
      Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
    let brng = Math.atan2(y, x);
    brng = this.toDegrees(brng);
    return (brng + 360) % 360;
  }

  analyseGPSData(entries: any): void {
    const self = this;
    console.log('Before filter:', entries.length);
    const filteredEntries = entries;
    // .filter((e) => {
    //   return (e.vehicleStatus.code === 105 && !e.ignore);
    // });
    console.log('Filtered:', filteredEntries);
    let prev;
    let prevBearing;
    let diffBearing;
    let total = 0;

    filteredEntries.forEach(function (item, i) {
      if (prev && i < 62) {
        let rounded = parseInt((self.getDistanceFromLatLonInKm(prev.location.gps.lat, prev.location.gps.lng, item.location.gps.lat, item.location.gps.lng) * 1000).toFixed(0), 10);
        const gpsBearing = parseInt(self.bearing(prev.location.gps.lat, prev.location.gps.lng, item.location.gps.lat, item.location.gps.lng).toFixed(0), 10);
        console.log(`${i + 1}. ##########################`);
        diffBearing = (prevBearing - gpsBearing);
        diffBearing = (diffBearing < 0 ? 0 - diffBearing : diffBearing);
        prevBearing = gpsBearing;

        if (diffBearing) {
          const additionPercentage = (diffBearing / 3.6);
          const addition = parseInt((((rounded * 0.5) / 100) * additionPercentage).toFixed(0), 10);
          console.log(` ${rounded} - ${diffBearing} addition: ${addition}`);
          rounded = rounded + addition;
        }
        console.log(`##########################`);

        if (i > 1) {
          total = total + rounded;
        }
      }
      prev = item;
    });
    console.log('Total calculated distance:', total);
    // console.log('Total calculated distance:', total2);
    // console.log('Total calculated distance:', total3);
  }

  //noinspection JSMethodCanBeStatic
  onTabChange(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.lvPagingBar.navigateToPage(1);
    }
    if (event.index === 1) {
      if (this.shift.startLocation) {
        this.map.triggerResize().then(res => {
        });
      }
    }
    if (event.index === 3) {
      this.glPagingBar.navigateToPage(1);
    }
  }

  listViewSort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.listViewSortBy = sortEvent.name;
    this.listViewSortOrder = sortEvent.order;
    this.listViewFilter();
  }

  listViewSearch(searchTerm: string): void {
    this.listViewSearchTerm = searchTerm;
    this.listViewFilter();
  }

  listViewPage(pagingEvent: IPageChangeEvent): void {
    this.listViewFromRow = pagingEvent.fromRow;
    this.listViewCurrentPage = pagingEvent.page;
    this.listViewPageSize = pagingEvent.pageSize;
    this.listViewFilter();
  }

  listViewFilter(): void {
    let newData: any[] = this.listViewData;
    newData = this._dataTableService.filterData(newData, this.listViewSearchTerm, true);
    this.listViewFilteredTotal = newData.length;
    newData = this._dataTableService.sortData(newData, this.listViewSortBy, this.listViewSortOrder);
    newData = this._dataTableService.pageData(newData, this.listViewFromRow, this.listViewCurrentPage * this.listViewPageSize);
    this.listViewFilteredData = newData;
  }

  gpsLogSort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.gpsLogSortBy = sortEvent.name;
    this.gpsLogSortOrder = sortEvent.order;
    this.gpsLogFilter();
  }

  gpsLogSearch(searchTerm: string): void {
    this.gpsLogSearchTerm = searchTerm;
    this.gpsLogFilter();
  }

  gpsLogPage(pagingEvent: IPageChangeEvent): void {
    this.gpsLogFromRow = pagingEvent.fromRow;
    this.gpsLogCurrentPage = pagingEvent.page;
    this.gpsLogPageSize = pagingEvent.pageSize;
    this.gpsLogFilter();
  }

  gpsLogFilter(): void {
    let newData: any[] = this.gpsLogData;
    newData = this._dataTableService.filterData(newData, this.gpsLogSearchTerm, true);
    this.gpsLogFilteredTotal = newData.length;
    newData = this._dataTableService.sortData(newData, this.gpsLogSortBy, this.gpsLogSortOrder);
    newData = this._dataTableService.pageData(newData, this.gpsLogFromRow, this.gpsLogCurrentPage * this.gpsLogPageSize);
    this.gpsLogFilteredData = newData;
    this._loadingService.resolve('gps.loader');
    this._loadingService.resolve('gps.mapLoader');
    this._loadingService.resolve('shift.details');
  }

  ridesSort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.ridesSortBy = sortEvent.name;
    this.ridesSortOrder = sortEvent.order;
    this.ridesFilter();
  }

  ridesSearch(searchTerm: string): void {
    this.ridesSearchTerm = searchTerm;
    this.ridesFilter();
  }

  ridesPage(pagingEvent: IPageChangeEvent): void {
    this.ridesFromRow = pagingEvent.fromRow;
    this.ridesCurrentPage = pagingEvent.page;
    this.ridesPageSize = pagingEvent.pageSize;
    this.ridesFilter();
  }

  ridesFilter(): void {
    let newData: any[] = this.ridesData;
    newData = this._dataTableService.filterData(newData, this.ridesSearchTerm, true);
    this.ridesFilteredTotal = newData.length;
    newData = this._dataTableService.sortData(newData, this.ridesSortBy, this.ridesSortOrder);
    newData = this._dataTableService.pageData(newData, this.ridesFromRow, this.ridesCurrentPage * this.ridesPageSize);
    this.ridesFilteredData = newData;
  }

}
