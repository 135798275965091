<mat-list-item>
  <mat-icon mat-list-icon>business_center</mat-icon>
  <h4 mat-line>{{'debtor'|translate|ucFirst}}</h4>
  <p mat-line>{{'ride_debtor_subtitle'|translate|ucFirst}}</p>
</mat-list-item>
<div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'name'|translate|ucFirst}}</p>
    <p mat-line>{{name || '-'}}</p>
  </mat-list-item>
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'code'|translate|ucFirst}}</p>
    <p mat-line>{{debtor.code || '-'}}</p>
  </mat-list-item>
</div>
<div [mediaClasses]="['push-sm']" class="push-top-md push-bottom-sm" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'email'|translate|ucFirst}}</p>
    <p mat-line>{{debtor.emailAddress || '-'}}</p>
  </mat-list-item>
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'phone_number'|translate|ucFirst}}</p>
    <p mat-line>{{debtor.phoneNumber || '-'}}</p>
  </mat-list-item>
</div>
