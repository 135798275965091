<div layout="column" layout-fill>
  <mat-toolbar class="mat-whiteframe-z1" color="primary">
    <a class="top-logo" routerLink="/login">
      <mat-icon class="mat-icon-logo" svgIcon="assets:logo"></mat-icon>
    </a>
    <span> | {{'connect_drivers_to_user_title'|translate}}</span>
  </mat-toolbar>
  <div class="mat-content" flex layout-padding>
    <div class="margin" layout-align-gt-xs="center start" layout-gt-xs="row">
      <mat-card [mediaClasses]="['push-top-lg']" tdMediaToggle="gt-xs">
        <mat-card-title>{{'connect_drivers_to_user_title'|translate | ucFirst}}</mat-card-title>
        <div class="sub-text">{{subTitle}}</div>

        <div *ngIf="drivers && drivers[0]" class="driver-details push-bottom">
          <table border="0" cellpadding="0" cellspacing="0">
            <tr>
              <td class="col_title">{{'drivername'|translate|ucFirst}}:</td>
              <td>{{drivers[0].fname}} {{drivers[0].lname}}</td>
            </tr>
            <tr>
              <td class="col_title">{{'driverphone'|translate|ucFirst}}:</td>
              <td>{{drivers[0].phoneNumber}}</td>
            </tr>
            <tr>
              <td class="col_title">{{'driveremail'|translate|ucFirst}}:</td>
              <td>{{drivers[0].originalEmail}}</td>
            </tr>
          </table>

          <div class="footer-text">{{'signup_connect_invite_send_text2'|translate | ucFirst}}</div>
        </div>


        <mat-divider></mat-divider>

        <mat-card-actions>
          <button (click)="save('accept')" class="accept-button convert-button push-bottom push-left" flex
                  mat-raised-button>
            <mat-icon>check</mat-icon>
            {{'confirm'|translate|ucFirst}}</button>

          <button (click)="save('ignore')" class="convert-button push-bottom" color="warn" flex mat-raised-button>
            <mat-icon>clear</mat-icon>
            {{'decline'|translate|ucFirst}}</button>
        </mat-card-actions>
      </mat-card>
    </div>
  </div>
</div>
