import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {LocationService} from '../../../../services/tps/location.service';
import {CollectionComponent} from '../../../global/collection/collection.component';
import {Location, LocationType} from 'app/models/location';
import {environment} from '../../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {NavigationService} from '../../../../services/navigation.service';
import {ucFirst} from '../../../../pipes/uc-first.pipe';
import {DaAppInstallService} from '../../../../services/da-app-install.service';
import {TdDialogService} from '@covalent/core/dialogs';

/**
 * Get a value from a deep object by passing an array with
 * key names to traverse to the depths of the object.
 */
const get = (p: string[], o: {}) =>
  p.reduce((xs, x) =>
    (xs && xs[x]) ? xs[x] : null, o)

const types = {
  point: 'point',
  area: 'area',
}

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
  providers: [LocationService]
})
export class LocationsComponent
  extends CollectionComponent
  implements OnInit, OnDestroy {

  paging = {
    area: {
      total: 0
    },
    point: {
      total: 0
    },
  }

  columns = [
    {name: '_id', label: '#', sortable: true, active: true},
    {name: 'identifier', label: 'ID', sortable: true, active: true},
    {name: 'name', label: 'Name', sortable: true, active: true},
    {name: 'descriptor', label: 'Type', sortable: true},
    {name: 'address', label: 'Address', sortable: true},
    {name: 'type', label: 'Type', sortable: true},
    {name: 'policy', label: 'Policy', sortable: true},
  ];

  typeInfo = {
    point: {
      columns: ['identifier', 'name', 'descriptor', 'address', 'policy'],
      icon: 'grain',
      name: 'locations',
    },
    area: {
      columns: ['identifier', 'name', 'descriptor', 'policy'],
      icon: 'gesture',
      name: 'areas',
    }
  };
  apps = [];

  constructor(
    protected injector: Injector,
    protected _locationService: LocationService,
    protected _translateService: TranslateService,
    protected _titleService: Title,
    protected _navigationService: NavigationService,
    private _daAppInstallService: DaAppInstallService,
    private _dialogService: TdDialogService,
  ) {
    super(injector);

    // Used to set the action link, title etc..
    this.modelName = 'point';
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);

    this.modelService = _locationService;
    this.originalData = {
      point: [],
      area: [],
    }

    _translateService.get(['location']).subscribe((translations: any) => {
      _titleService.setTitle(ucFirst(translations['location'] + environment.windowTitleSuffix));
      _navigationService.setBodyTitle(translations['location']);
    });

    this._route.parent.params.subscribe(params => {
      this.companyId = params['id'];
      this.setLink(types[this.modelName]);
      this.setTitle(types[this.modelName]);
    });
  }

  click(id: string) {
    this._router.navigate([
      `/groups/${this.companyId}/locations/${types[this.modelName]}/${id}/details`
    ]);
  }

  setLink(name: 'point' | 'area') {
    const self = this;
    this._navigationService.setActionFn(() => {
      self._dialogService.openConfirm({
        message: self._translateService.instant('select_location_type_msg'),
        disableClose: false,
        'title': self._translateService.instant('select_location_type'),
        'acceptButton': self._translateService.instant('area'),
        'cancelButton': self._translateService.instant('address'),
      }).afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          this._router.navigate([
            `/groups/${this.companyId}/locations/area/add`
          ]);
        } else {
          this._router.navigate([
            `/groups/${this.companyId}/locations/point/add`
          ]);
        }
      });
    });
  }

  setTitle(name: 'point' | 'area') {
    this._navigationService.setBodyTitle(Location.frontend(name).concat('s'));
  }

  loadData = (filter: {} = {}) => this.modelService
    .getAll({
      where: {
        companyId: this.companyId,
        isShared: false,
        serviceArea: false
      },
      include: ['locationPolicy'],
      order: 'identifier DESC',
    }, this.context)
    .subscribe((locations: Location[]) => {
      const formattedLocations = [];
      locations.forEach((location, i) => {
        if (location.locationPolicy) {
          formattedLocations.push({...location, policy: location.locationPolicy.name});
        } else {
          formattedLocations.push(location);
        }
      });
      Object.keys(this.originalData)
        .forEach((key) => {
          this.originalData[key] = formattedLocations
            .filter((location: Location) => location.type === key);
          this.paging[key] = {
            total: this.originalData[key].length
          }
        });
      this.filterAllTabs();
      this.stopLoader();
    });

  onTabChange(event) {
    this.modelName = event.tab.textLabel;
    this.setTitle(types[this.modelName]);
    this.setLink(types[this.modelName]);
  }

  typeColumn = (type: string, columns: any[]) => {
    const allowed = get([type, 'columns'], this.typeInfo) || [];
    return columns.filter(c => allowed.includes(c.name))
  }
  typeIcon = (type: string) => get([type, 'icon'], this.typeInfo) || 'map';
  typeName = (type: string) => get([type, 'name'], this.typeInfo) || 'all';
  typeEquals = (type: string, equals: string) =>
    LocationType[type] === LocationType[equals];

  ngOnDestroy() {
    this._navigationService.setSplitLayoutButtons([]);
    this._navigationService.setActionFn('');
    this._navigationService.setInfoAction('');
  }
}
