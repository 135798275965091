<ng-container>
  <div class="tab-content">
    <h4 class="push-top-none push-bottom-none">{{'commission_rules_settings'|translate|ucFirst}}</h4>
    <div [innerHTML]="'commission_rules_settings_text'|translate|keepHtml"></div>
    <mat-divider></mat-divider>
    <div class="push-top-lg">
      <app-dynamic-form (submit)="onSubmit($event)" [config]="config"></app-dynamic-form>
    </div>
  </div>
</ng-container>
