import {Component, OnDestroy, OnInit} from '@angular/core';
import {DebtorService} from '../../../../services/debtor.service';
import {Driver} from '../../../../models/driver';
import {Subscription} from 'rxjs';
import {Debtor} from '../../../../models/debtor';
import {TdLoadingService} from '@covalent/core/loading';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../../../services/navigation.service';
import {environment} from '../../../../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {ucFirst} from '../../../../pipes/uc-first.pipe';

@Component({
  selector: 'app-debtor-details',
  templateUrl: './debtor-details.component.html',
  styleUrls: ['./debtor-details.component.scss']
})
export class DebtorDetailsComponent implements OnInit, OnDestroy {

  context: string;
  loaderName = 'debtor-details';
  driver: Driver;
  dataSubscription: Subscription;
  companyId: string;
  debtor: Debtor = null;

  constructor(private debtorService: DebtorService,
              private loadingService: TdLoadingService,
              private vault: CoolLocalStorage,
              private router: Router,
              private route: ActivatedRoute,
              private translateService: TranslateService,
              private _navigationService: NavigationService,
              private titleService: Title) {
    this.context = this.route.routeConfig['context'] || 'driver';
    this.driver = this.vault.getObject(`${environment.vaultPrefix}.driver`);
    this.companyId = this.route.parent.snapshot.paramMap.get('id');

    translateService.get(['customers']).subscribe((translations: any) => {
      this.titleService.setTitle(ucFirst(translations.customers + environment.windowTitleSuffix));
      this._navigationService.setBodyTitle(ucFirst(translations.customers));
      this._navigationService.setSplitLayoutButtons([
        {
          'icon': 'list',
          'tooltip': translations['customers'],
          'link': `/groups/${this.companyId}/debtors/`
        }
      ]);
    });

    this.loadData();
  }

  ngOnInit(): void {
    this.loadingService.register(this.loaderName);
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
    this._navigationService.setActionLink('');
    this._navigationService.setSplitLayoutButtons([]);
  }

  editDebtor(): void {
    if (this.context === 'driver') {
      this.router.navigate([`/debtors/${this.debtor.id}/edit`]);
    } else {
      this.router.navigate([`/groups/${this.companyId}/debtors/${this.debtor.id}/edit`]);
    }
  }

  loadData(): void {
    this.dataSubscription = this.debtorService.get(this.route.snapshot.paramMap.get('id'), {}, this.context).subscribe((debtor: Debtor) => {
      this.debtor = debtor;
      const self = this;
      setTimeout(function () {
        self.loadingService.resolve(self.loaderName);
      }, 500);
    }, error => {
      console.error(error);
      this.loadingService.resolve(this.loaderName);
    })
  }

}
