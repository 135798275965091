import {ComponentFactoryResolver, ComponentRef, Directive, Input, OnChanges, OnInit, SimpleChanges, Type, ViewContainerRef} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {FieldConfig} from '../models/field-config.interface';
import {Field} from '../models/field.interface';
import {FormInputComponent} from '../components/form-input/form-input.component';
import {FormSelectComponent} from '../components/form-select/form-select.component';
import {FormButtonComponent} from '../components/form-button/form-button.component';
import {MaterialInputComponent} from '../components/material-input/material-input.component';
import {MaterialSelectComponent} from '../components/material-select/material-select.component';
import {CommissionInputComponent} from '../components/commission-input/commission-input.component';
import {MaterialButtonComponent} from '../components/material-button/material-button.component';

const components: { [type: string]: Type<Field> } = {
  input: FormInputComponent,
  select: FormSelectComponent,
  button: FormButtonComponent,
  materialInput: MaterialInputComponent,
  materialSelect: MaterialSelectComponent,
  materialButton: MaterialButtonComponent,
  commission: CommissionInputComponent
};

@Directive({
  selector: '[appDynamicField]'
})
export class DynamicFieldDirective implements Field, OnChanges, OnInit {
  @Input() config: FieldConfig;
  @Input() group: UntypedFormGroup;

  component: ComponentRef<Field>;

  constructor(private _resolver: ComponentFactoryResolver,
              private _container: ViewContainerRef) {
  }

  ngOnInit(): void {
    if (!components[this.config.type]) {
      const supportedTypes = Object.keys(components).join(', ');
      throw new Error(
        `Trying to use an unsupported type (${this.config.type}).
        Supported types: ${supportedTypes}`
      );
    }
    const component = this._resolver.resolveComponentFactory<Field>(components[this.config.type]);
    this.component = this._container.createComponent(component);
    this.component.instance.config = this.config;
    this.component.instance.group = this.group;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.component) {
      this.component.instance.config = this.config;
      this.component.instance.group = this.group;
    }
  }
}
