<form *ngIf="parent && parent.form" [formGroup]="parent.form">
  <section class="mat-typography">
    <h2>{{ 'product_file_upload' | translate | ucFirst }}</h2>
  </section>
  <div flex="100" layout="row" class="push-bottom">
    <input (change)="upload($event)" id="singleFile" type="file" accept="image/png, image/jpeg">
  </div>

  <mat-divider></mat-divider>

  <section class="mat-typography push-top">
    <h2>{{ 'select_default_productimage' | translate | ucFirst }}</h2>
  </section>

  <div class="td-dialog-content">
    <div class="image-preview" *ngFor="let image of defaultImages">
      <img (click)="selectImage(image)" [src]="image">
    </div>
  </div>
</form>
