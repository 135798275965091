import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Company} from '../../../models/company';

@Component({
  selector: 'app-licenses',
  templateUrl: './licenses.component.html',
  styleUrls: ['./licenses.component.scss']
})
export class LicensesComponent implements OnInit {
  @Input() company: Company;

  constructor(
    public dialogRef: MatDialogRef<LicensesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data) {
      this.company = data.company;
    }
  }

  ngOnInit(): void {
  }
}
