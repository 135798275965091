<mat-card [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <ng-template tdLoading="driver">
    <mat-card-content class="push-bottom-none">
      <ng-template tdLoading="profile">
        <form *ngIf="form" [formGroup]="form">
          <div class="push-bottom-sm push-top-sm" layout="row">
            <mat-form-field class="push-right" flex>
              <input #fname formControlName="fname" matInput placeholder="{{'first_name'|translate|ucFirst}}" required
                     type="text" value="{{driver.fname}}">
              <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.fname" class="tc-red-600">
                  <span>{{ formErrors.fname }}</span>
                </span>
              </mat-hint>
            </mat-form-field>
            <mat-form-field flex>
              <input #lname formControlName="lname" matInput placeholder="{{'last_name'|translate|ucFirst}}" required
                     type="text" value="{{driver.lname}}">
              <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.lname" class="tc-red-600">
                  <span>{{ formErrors.lname }}</span>
                </span>
              </mat-hint>
            </mat-form-field>
          </div>
          <div layout="row">
            <mat-form-field class="push-right" flex>
              <input #emailAddress [attr.disabled]="(driver)" formControlName="emailAddress" matInput
                     placeholder="{{'email'|translate|ucFirst}}"
                     required type="email"
                     value="{{driver.emailAddress}}">
              <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.emailAddress" class="tc-red-600">
                  <span>{{ formErrors.emailAddress }}</span>
                </span>
              </mat-hint>
            </mat-form-field>
            <div class="phoneNumberField push-right {{(formErrors.phoneNumber ? 'phoneNumberInvalid' : '')}}" flex>
              <ngx-intl-tel-input
                (keyup)="clearError($event, 'phoneNumber')"
                [cssClass]="'phoneNumberInput'"
                [enableAutoCountrySelect]="true"
                [enablePlaceholder]="true"
                [maxLength]="15"
                [phoneValidation]="true"
                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                [searchCountryFlag]="true"
                [selectFirstCountry]="false"
                [selectedCountryISO]="this.company.country"
                flex
                formControlName="phoneNumber"
                name="phoneNumber">
              </ngx-intl-tel-input>
              <div class="phonenumber-warning warning">
                <span [hidden]="!formErrors['phoneNumber']" class="tc-red-600">
                  <span>{{ formErrors['phoneNumber'] }}</span>
                </span>
              </div>
            </div>
          </div>
          <div formGroupName="meta">
            <div layout="row">
              <mat-form-field class="push-right">
                <input formControlName="licenseNumber" matInput placeholder="{{'license'|translate|ucFirst}}"
                       type="text" value="">
                <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.licenseNumber" class="tc-red-600">
                  <span>{{ formErrors.licenseNumber }}</span>
                </span>
                </mat-hint>
              </mat-form-field>
            </div>
            <div layout="row">
              <mat-checkbox class="send-job-email" formControlName="sendJobEmail" flex
                            flex="30">{{ 'send_job_email' | translate }}
              </mat-checkbox>
            </div>
          </div>
          <div class="push-top-md" layout="row">
            <mat-form-field>
              <mat-select class="row-select" flex="33" formControlName="type" placeholder="Type" required>
                <mat-option *ngFor="let type of driverTypes"
                            [value]="type.value">{{ type.label | translate | ucFirst }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
      </ng-template>
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-actions>
      <button (click)="save()" *ngIf="!this.driverId" color="accent" flex
              mat-raised-button>{{ 'invite_driver_button'|translate }}
      </button>

      <button (click)="save()" *ngIf="this.driverId" color="accent" flex
              mat-raised-button>{{ 'save_profile_button'|translate }}
      </button>
    </mat-card-actions>
  </ng-template>
</mat-card>
