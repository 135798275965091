import {Component, Input, OnInit} from '@angular/core';
import {Debtor} from '../../../../../../../models/debtor';
import {UntypedFormGroup} from '@angular/forms';
import {Driver} from '../../../../../../../models/driver';
import {DebtorService} from '../../../../../../../services/debtor.service';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../../../../../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Company} from '../../../../../../../models/company';
import {PassengerService} from '../../../../../../../services/passenger.service';
import {Passenger} from '../../../../../../../models/passenger';
import {CustomerUpsertComponent} from '../../../../../../company/debtors/parts/customer-upsert/customer-upsert.component';
import {TdDialogService} from '@covalent/core/dialogs';

@Component({
  selector: 'app-ride-edit-debtor',
  templateUrl: './ride-edit-debtor.component.html',
  styleUrls: ['./ride-edit-debtor.component.scss'],
  providers: [PassengerService]
})
export class RideEditDebtorComponent implements OnInit {
  @Input() debtor: Debtor;
  @Input() form: UntypedFormGroup;
  @Input() context: string;
  debtors: Debtor[];
  contacts: Passenger[];
  company: Company;
  driver: Driver;
  currentDisplayValue: any;

  constructor(
    private _debtorService: DebtorService,
    private _passengerService: PassengerService,
    private _dialogService: TdDialogService,
    private activatedRoute: ActivatedRoute,
    private _vault: CoolLocalStorage,
  ) {
  }

  ngOnInit() {
    if (this.context === 'company') {
      const {company} = this.activatedRoute.parent.snapshot.data;
      this.company = company;
    } else {
      const driver: Driver = this._vault.getObject(`${environment.vaultPrefix}.driver`);
      this.driver = driver;
    }

    this.loadDebtors();
    this.selectDebtor();
  }

  loadDebtors(): void {
    const query = {
      'where': {
        ownerId: (this.context === 'driver' ? this.driver.id : this.company.id),
        ownerType: (this.context === 'driver' ? 'Driver' : 'Company'),
      }
    };
    this._debtorService.getAll(query).subscribe((debtors: Debtor[]) => {
      this.debtors = debtors;

      const queryPassenger = {where: {realm: this.company.id}};
      this._passengerService.getAll(queryPassenger, 'company').subscribe((passengers: Passenger[]) => {
        this.contacts = passengers;
      }, error => {
        console.error(error);
      })
    });
  }

  selectDebtor(): void {
    if (!this.form.controls['debtorId'].value) {
      return;
    }

    if (this.form.controls['debtorId'].value.indexOf('debtor') > -1) {
      const split = this.form.controls['debtorId'].value.split('-');
      this.form.controls['passengerId'].setValue('');
      this._debtorService.get(split[1])
        .subscribe((debtor) => {
          this.currentDisplayValue = `${debtor.code} - ${debtor.companyName} - ${debtor.firstName} ${debtor.lastName}`;
        });
    } else {
      const split = this.form.controls['debtorId'].value.split('-');
      this.form.controls['passengerId'].setValue(split[1]);
      this._passengerService.get(split[1])
        .subscribe((passenger) => {
          this.currentDisplayValue = `${passenger.fname} ${passenger.lname} - ${passenger.email}`;
        });
    }

    // @ts-ignore
    this.form.controls['paymentMeta'].controls.origin.setValue('invoice');
  }

  addNewDebtor(): void {
    this._dialogService.open(CustomerUpsertComponent, {
      maxHeight: '80%',
      maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
      panelClass: 'zero-dialog-padding',
      data: {
        parent: this,
      },
    });
  }
}
