import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {Company} from '../../../../../../../../models/company';

@Component({
  selector: 'app-message-rule-add-wizard',
  templateUrl: './message-rule-add-wizard.component.html',
  styleUrls: ['./message-rule-add-wizard.component.scss']
})
export class MessageRuleAddWizardComponent implements OnInit {
  matchValues = [];
  matchValues2 = [];
  form: UntypedFormGroup;
  parent: any;
  company: Company;
  constructor(
    public _router: Router,
    public _dialogRef: MatDialogRef<MessageRuleAddWizardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.company = data.parent.company;
    this.matchValues = data.parent.matchValues;
    this.matchValues2 = data.parent.matchValues2;
    this.form = data.parent.newForm;
    this.parent = data.parent;
  }

  ngOnInit(): void {

  }

  createNewFromWizard() {
    const self = this.parent;
    const data = this.form.value;
    // self._loadingService.register(self.loaderName);
    this.parent.wizardCompleted = true;
    // @ts-ignore
    this.parent.form.controls['requirements'].push(this.parent._formBuilder.group({
      firstMatch: [this.form.controls.newFlowCondition.value, [Validators.required]],
      secondMatch: ['', []],
      secondMatchValue: ['', []],
      startDate: ['', []],
      endDate: ['', []],
      timeMatch: ['sent_immediately', [Validators.required]],
      timeValue: ['', []],
    }, {validators: []}));

    this.parent.form.controls['name'].setValue(data.name);
    this.parent.form.controls['recipientType'].setValue(data.recipientType);

    this._dialogRef.close();
    this.parent.autoStartAddMessage();
  }

  cancel() {
    this.parent.action = 'edit';
    this._router.navigate([`/groups/${this.company.id}/message-center`]);
    this._dialogRef.close();
  }
}
