import {Injectable} from '@angular/core';
import {GlobalService} from '../global.service';
import {HttpClient} from '@angular/common/http';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from '../utility.service';
import {environment} from '../../../environments/environment';
import {AuthService} from '../auth.service';

@Injectable({
  providedIn: 'root'
})
export class PricingRuleLinkService extends GlobalService {
  /**
   * Gets JWT headers from auth service.
   */

  constructor(
    _http: HttpClient,
    _vault: CoolLocalStorage,
    _utilityService: UtilityService,
    private _auth: AuthService,
  ) {
    super(_http, _vault, _utilityService);
    this.setModel('RuleLinks');
    this.base = environment.tpsBaseUrl;
  }

  getHeaders = () => this._auth.getJWTHeaders();

}
