import {Component, Input, OnInit} from '@angular/core';
import {InvoiceFilter} from '../invoice-filter';
import {InvoiceService} from '../../../../services/pas/invoice.service';
import {ITdDataTableSortChangeEvent, TdDataTableService, TdDataTableSortingOrder} from '@covalent/core/data-table';
import {IPageChangeEvent} from '@covalent/core/paging';
import {Driver} from '../../../../models/driver';
import {environment} from '../../../../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from '../../../../services/utility.service';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-invoice-history',
  templateUrl: './invoice-history.component.html',
  styleUrls: ['./invoice-history.component.scss'],
  providers: [InvoiceService]
})
export class InvoiceHistoryComponent implements OnInit {
  @Input() companyId: string;
  @Input() context: string;
  @Input() status: string[];
  @Input() invoiceView: string;
  @Input() searchIds: string[] = [];

  driver: Driver;
  columns = [
    {name: 'created', label: 'invoice_date', sortable: true, active: true},
    {name: 'contact', label: 'contact', sortable: true, active: false},
    {name: 'jobReference', label: 'job_reference', sortable: true, active: false},
    {name: 'reference', label: 'invoice_id', sortable: true, active: false},
    {name: 'amount', label: 'amount', sortable: true, active: false, numeric: true},
  ];
  data: any[] = [];
  filteredData: any[] = [];
  filteredTotal: number;
  searchTerm = '';
  fromRow = 1;
  currentPage = 1;
  pageSize = 20;
  sortBy = 'created';
  sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;
  filterData: InvoiceFilter;

  constructor(
    private _invoiceService: InvoiceService,
    private _vault: CoolLocalStorage,
    private route: ActivatedRoute,
    private _dataTableService: TdDataTableService
  ) {
    this.context = this.route.routeConfig['context'] || 'driver';
    this.driver = this._vault.getObject(`${environment.vaultPrefix}.driver`);
    this.filterData = {
      year: new Date().getFullYear(),
      from: 1,
      to: 12,
      status: ['all'],
      context: this.context
    };
  }

  ngOnInit() {
    this.loadData();
  }

  invoiceFilterChanged(data: any): void {
    this.filterData = data;
    this.loadData();
  }

  loadData(): void {
    let query;
    if (this.invoiceView === 'Debtor') {
      query = {
        where: {
          and: [
            {'status': {inq: this.status}},
            {
              or: [
                {
                  issuerId: {
                    inq: this.searchIds
                  }
                },
                {
                  billableId: {
                    inq: this.searchIds
                  }
                }
              ]
            },
            {
              or: [
                {
                  billableType: 'Debtor'
                },
                {
                  billableType: 'Passenger'
                }
              ]
            }
          ]
        },
      };
    } else {
      query = {
        where: {
          and: [
            {'status': {inq: this.status}},
            {
              or: [
                {
                  issuerId: {
                    inq: this.searchIds
                  }
                },
                {
                  billableId: {
                    inq: this.searchIds
                  }
                }
              ]
            },
            {
              or: [
                {
                  billableType: 'Driver'
                },
                {
                  billableType: 'Company'
                }
              ]
            }
          ]
        },
      };
    }
    this._invoiceService.getInvoices(query, this.filterData).subscribe((invoices: any[]) => {
      this.formatData(invoices);
    }, error => {
      console.error(error);
    })
  }

  formatData(invoices: any[]): void {
    console.log(`[InvoiceHistoryComponent.formatData]: invoices`, invoices);

    invoices.forEach((invoice, index) => {
      invoices[index].amount = {...invoice.total};

      invoices[index].issuer = invoice.Issuer ?
        (invoice.Issuer.name ? invoice.Issuer.name : `${invoice.Issuer.fname} ${invoice.Issuer.lname}`) :
        '';
      invoices[index].billable = invoice.Billable ?
        (invoice.Billable.name ? invoice.Billable.name : `${invoice.Billable.fname} ${invoice.Billable.lname}`) :
        '';

      if (this.context === 'company') {
        if (invoice.issuerId === this.companyId) {
          invoices[index].debit = true;
          invoices[index].contact = invoices[index].billable;
        } else {
          invoices[index].credit = true;
          invoices[index].contact = invoices[index].issuer;
        }
      } else {
        if (invoice.issuerId === this.driver.id) {
          invoices[index].debit = true;
          invoices[index].contact = invoices[index].billable;
        } else {
          invoices[index].credit = true;
          invoices[index].contact = invoices[index].issuer;
        }
      }
    });

    this.data = invoices;
    this.filter(true);
  }

  search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.filter();
  }

  sort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.sortBy = sortEvent.name;
    this.sortOrder = sortEvent.order;
    this.filter(false, true);
  }

  page(pagingEvent: IPageChangeEvent): void {
    this.fromRow = pagingEvent.fromRow;
    this.currentPage = pagingEvent.page;
    this.pageSize = pagingEvent.pageSize;
    this.filter();
  }

  filter(firstLoad: boolean = false, sorting: boolean = false): void {
    let newData: any[] = this.data;

    newData = this._dataTableService.filterData(newData, this.searchTerm, true, [
      'id',
      'billableId',
      'issuerId',
      'modified'
    ]);

    if (!firstLoad) {
      if (this.sortOrder === 'DESC') {
        this.sortOrder = TdDataTableSortingOrder.Ascending;
      } else {
        this.sortOrder = TdDataTableSortingOrder.Descending;
      }
    }

    if (this.sortBy === 'created') {
      newData = UtilityService.tableDateSort(newData, 'created', this.sortOrder);
    } else {
      newData = this._dataTableService.sortData(newData, this.sortBy, this.sortOrder);
    }

    newData = this._dataTableService.pageData(newData, this.fromRow, this.currentPage * this.pageSize);

    this.filteredData = newData;
    this.filteredTotal = newData.length;
  }

  open(url: string): void {
    window.open(url, '_blank');
  }

}
