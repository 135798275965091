import {NgModule, Pipe, PipeTransform} from '@angular/core'

export const ucFirst = (value: string = '') => {
  return `${value}`.charAt(0).toUpperCase() + `${value}`.substr(1);
};

@Pipe({
  name: 'ucFirst'
})
export class UcFirstPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return ucFirst(value);
  }
}

@NgModule({
  declarations: [UcFirstPipe],
  exports: [UcFirstPipe]
})
export class UcFirstPipeModule {
}
