<mat-card [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <ng-template tdLoading="pricing">
    <mat-tab-group>
      <mat-tab label="{{'open_invoices'|translate}}">
        <ng-template mat-tab-label>
          <mat-icon>move_to_inbox</mat-icon>
          {{'open_invoices'|translate}} ({{openInvoiceCount}})
        </ng-template>
        <ng-template tdLoading="data.open.loaded">
          <app-open-invoices (count)="openInvoiceCountChanged($event)"
                             *ngIf="dataLoaded"
                             [companyId]="companyId"
                             [context]="'driver'"
                             [searchIds]="searchIds">
          </app-open-invoices>
        </ng-template>
      </mat-tab>
      <!--      <mat-tab label="{{'invoice_history'|translate}}">-->
      <!--        <ng-template mat-tab-label>-->
      <!--          <mat-icon>history</mat-icon> {{'invoice_history'|translate}}-->
      <!--        </ng-template>-->
      <!--        <ng-template tdLoading="data.history.loaded">-->
      <!--          <app-invoice-history *ngIf="dataLoaded"-->
      <!--                               [context]="'driver'"-->
      <!--                               [searchIds]="searchIds"-->
      <!--                               [companyId]="companyId">-->
      <!--          </app-invoice-history>-->
      <!--        </ng-template>-->
      <!--      </mat-tab>-->
    </mat-tab-group>
  </ng-template>
</mat-card>
