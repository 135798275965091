export class MessageCenter {
  id: string;
  name: string;
  startDate?: Date;
  endDate?: Date;
  companyId?: string;
  dateLimit?: boolean;
  status: string;
  modified?: string;
  created?: string;
  _actions?: MessageCenterAction[];
  _requirements?: MessageCenterRequirement[];

  constructor() {
  }
}

export class MessageCenterRequirement {
  id: string;
  matchKey: string;
  matchType: string;
  subtractMinutes?: number;
  addMinutes?: number;
  startDate?: string;
  endDate?: string;
  value: string;

  constructor() {
  }
}

export class MessageCenterAction {
  id: string;
  actionType: string;
  messages: MessageCenterActionMessage[];

  constructor() {
  }
}

export class MessageCenterActionMessage {
  id: string;
  language: string;
  message: string;
  template: string;
  blocks?: MessageCenterActionMessageEmailBlock;
  subject?: string;

  constructor() {
  }
}

export class MessageCenterActionMessageEmailBlock {
  jobdetails: string;
  passenger: string;
  driverblock: string;
  vehicle: string;

  constructor() {
  }
}
