import {Component, OnInit} from '@angular/core';
import {Coupon} from '../../../../../../models/coupon';
import {ucFirst} from '../../../../../../pipes/uc-first.pipe';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TdLoadingService} from '@covalent/core/loading';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {TdDialogService} from '@covalent/core/dialogs';
import {NavigationService} from '../../../../../../services/navigation.service';
import {CouponService} from '../../../../../../services/coupon.service';
import {environment} from '../../../../../../../environments/environment';
import {ValidateAllFormFields} from '../../../../../../functions/validateAllFormFields';
import moment from 'moment';
import {getCurrencySymbol} from '@angular/common';
import {Company} from '../../../../../../models/company';

function customValidator(discountType, requirement): ValidatorFn {
  return (discountType === requirement ? Validators.required : Validators.nullValidator);
}

function customBalanceLimit(form: UntypedFormGroup): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    return (form.controls['discountFixedAmount'].value > value ? {'credit_lower_fixed_per_job': true} : null)
  };
}

@Component({
  selector: 'app-coupon-upsert',
  templateUrl: './coupon-upsert.component.html',
  styleUrls: ['./coupon-upsert.component.scss']
})
export class CouponUpsertComponent extends ValidateAllFormFields implements OnInit {
  loaderName = 'coupon';
  modelName = 'coupon';
  list = 'coupons';
  context = 'company';
  currencySymbol = '';
  translations: string[] = [];
  currentAction;
  id: string;
  companyId: string;
  company: Company;
  action: string;
  currency: string;
  data: Coupon = new Coupon();

  form: UntypedFormGroup;
  formErrors: any = {};
  now = moment().format('YYYY-MM-DDTHH:mm');
  minDate = moment().format('YYYY-MM-DDTHH:mm');
  maxDate = moment().format('YYYY-MM-DDTHH:mm');
  maxStartDate = moment().add('1', 'month').format('YYYY-MM-DDTHH:mm');

  disabledOnEdit = ['discountType', 'limitByUsage', 'code',
    'limitByCustomer', 'usageLimitPerCustomer', 'LimitByBalanceByCustomer',
    'discountPercentage', 'balanceLimitByCustomer', 'discountPercentage', 'discountFixedAmount'];

  validationMessages = {
    'phoneNumber': {
      'phoneNumberValidator': 'form_validation_phonenumber_format',
    },
    'code': {
      'fieldName': 'coupon_code',
      'required': 'form_validation_required',
    },
    'name': {
      'fieldName': 'name',
      'required': 'form_validation_required',
    },
    'balanceLimitByCustomer': {
      'fieldName': 'coupon_balance_limit',
      'credit_lower_fixed_per_job': 'form_validation_credit_lower_fixed_per_job',
      'required': 'form_validation_required',
    },
    'balanceLimitByCustomerB': {
      'fieldName': 'coupon_balance_limit',
      'required': 'form_validation_required',
    },
    'discountPercentage': {
      'fieldName': 'discount',
      'required': 'form_validation_required',
    },
    'discountFixedAmount': {
      'fieldName': 'radio_coupon_fixed_radio',
      'required': 'form_validation_required',
    },
    'usageLimit': {
      'fieldName': 'checkbox_usage_count_type',
      'required': 'required',
    },
    'usageLimitPerCustomer': {
      'fieldName': 'checkbox_coupon_limit_usage_count_per_customer',
      'required': 'required',
    },
  };

  constructor(public snackBar: MatSnackBar,
              private _loadingService: TdLoadingService,
              private _formBuilder: UntypedFormBuilder,
              private _route: ActivatedRoute,
              private _router: Router,
              private _translateService: TranslateService,
              private _titleService: Title,
              private _dialogService: TdDialogService,
              private _navigationService: NavigationService,
              private _couponService: CouponService) {
    super();
    this.loadErrorTranslations(_translateService);
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);

    const self = this;

    const {company} = this._route.parent.snapshot.data;
    this.currency = company.currency;
    this.company = company;

    this._route.parent.params.subscribe(parentParams => {
      this.companyId = parentParams['id'];
      this._route.params.subscribe(params => {
        this.id = params['id'];
        this.action = params['action'] ? params['action'] : params['id'];

        _translateService.get([
          `${this.action}_coupon`,
          'coupon_added_message',
          'coupon_added_title',
          'coupon_updated_message',
          'error_duplicate_code',
          'coupon_upsert_error_title',
          'delete_x',
          'message_delete_x',
          'x_deleted'
        ]).subscribe((translations: any) => {
          self._navigationService.setSplitLayoutButtons([
            {
              'icon': 'assignment',
              'tooltip': self.translations['coupons'],
              'link': `/groups/${self.companyId}/coupons/${self.id}/details`
            },
            {
              'icon': 'list',
              'tooltip': self.translations['coupons'],
              'link': `/groups/${self.companyId}/${self.list}/`
            }
          ]);

          this.translations = translations;
          this._titleService.setTitle(ucFirst(translations[`${this.action}_coupon`]) + environment.windowTitleSuffix);
          this._navigationService.setBodyTitle(ucFirst(translations[`${this.action}_coupon`]));
        });

        self._translateService.get([
          `coupon_error_discountPercentage`,
        ]).subscribe((translations: any) => {
          Object.keys(translations).forEach((value, key) => {
            self.formErrors[key] = value;
          });
        });
        this.initForm();

        if (this.action === 'edit') {
          this.loadData();
        }
      });
    });

    if (self.context === 'company') {
      this.currencySymbol = getCurrencySymbol(company.currency, 'narrow');
    } else {
      self.currencySymbol = getCurrencySymbol('EUR', 'narrow');
    }
  }

  /**
   * If input element value is not set, make it zero.
   */
  valOrZero(input) {
    if (!input.value) {
      input.value = 0.00;
    }
    input.value = parseFloat(input.value).toFixed(2);
  }

  inputFocused(event) {
    event.target.select();
  }

  ngOnInit(): void {
    const self = this;
  }

  initForm(): void {
    /**
     * Set up our form
     */
    this.form = this._formBuilder.group({
      'name': [null, [Validators.required]],
      'code': [null, [Validators.required]],
      'discountType': [null, [Validators.required]],
      'startDate': [moment().format('YYYY-MM-DDTHH:mm'), []],
      'endDate': [this.maxStartDate, []],
      'limitByCustomer': [null, []],
      'LimitByBalanceByCustomer': [null, []],
      'limitByUsage': [null, []],
      'limitByDate': [null, []],
      'usageLimit': [null, [Validators.pattern('^[0-9]*$')]],
      'usageLimitPerCustomer': [null, []],
      'balanceLimitByCustomer': [null, []],
      'balanceLimitByCustomerB': [null, []],
      'discountPercentage': [null, [Validators.max(100), Validators.min(0)]],
      'discountFixedAmount': [null, []],
    }, {validators: []});
    /**
     * Subscribe to value changes in the form
     */
    this.form.valueChanges.subscribe(data => this.onValueChanged(data));
    this.conditionalFormatting();
  }

  loadData(): void {
    const self = this;
    const discountType = (this.form && this.form.controls && this.form.controls['discountType'] ? this.form.controls['discountType'].value : 'percentage');

    this._couponService.get(this.id, {}).subscribe((coupon: Coupon) => {
      this.data = coupon;
      /**
       * Set up our form
       */
      this.form.patchValue({
        'name': coupon.name,
        'code': coupon.code,
        'discountType': coupon.discountType,
        'startDate': moment(coupon.startDate).format('YYYY-MM-DDTHH:mm'),
        'endDate': moment(coupon.endDate).format('YYYY-MM-DDTHH:mm'),
        'limitByCustomer': coupon.limitByCustomer,
        'limitByUsage': coupon.limitByUsage,
        'limitByDate': coupon.limitByDate,
        'usageLimit': coupon.usageLimit,
        'usageLimitPerCustomer': coupon.usageLimitPerCustomer,
        'LimitByBalanceByCustomer': coupon.LimitByBalanceByCustomer,
        'discountPercentage': coupon.discountPercentage,
        'discountFixedAmount': (coupon.discountFixedAmount / 100),
        'balanceLimitByCustomer': (coupon.balanceLimitByCustomer / 100),
        'balanceLimitByCustomerB': (coupon.balanceLimitByCustomer / 100),
      });

      this.disabledOnEdit.forEach((field: string) => {
        self.form.controls[field].disable();
      });

      /**
       * Subscribe to value changes in the form
       */
      this.form.valueChanges.subscribe(data => this.onValueChanged(data));
      this._loadingService.resolve(this.loaderName);
    }, error => {
      console.error(error);
      this._loadingService.resolve(this.loaderName);
    })
  }

  conditionalFormatting() {
    this.form.get('discountType').valueChanges.subscribe(
      (mode: string) => {
        this.form.get('discountPercentage').setValidators([customValidator(mode, 'percentage')]);
        this.form.get('discountFixedAmount').setValidators([customValidator(mode, 'fixed')]);
        this.form.get('balanceLimitByCustomerB').setValidators([customValidator(mode, 'balance')]);
        this.form.get('balanceLimitByCustomerB').updateValueAndValidity();
        this.form.get('discountPercentage').updateValueAndValidity();
        this.form.get('discountFixedAmount').updateValueAndValidity();

        if (mode === 'fixed') {
          this.form.get('balanceLimitByCustomer').setValidators([customBalanceLimit(this.form)]);
          this.form.get('balanceLimitByCustomer').updateValueAndValidity();
        } else {
          this.form.get('balanceLimitByCustomer').setValidators([]);
          this.form.get('balanceLimitByCustomer').updateValueAndValidity();
        }
      });

    this.form.get('limitByUsage').valueChanges.subscribe(
      (mode: string) => {
        if (mode) {
          this.form.get('usageLimit').setValidators([Validators.required]);
        } else {
          this.form.get('usageLimit').setValidators([]);
        }
        this.form.get('usageLimit').updateValueAndValidity();
      });

    this.form.get('limitByCustomer').valueChanges.subscribe(
      (mode: string) => {
        if (mode) {
          this.form.get('usageLimitPerCustomer').setValidators([Validators.required]);
        } else {
          this.form.get('usageLimitPerCustomer').setValidators([]);
        }
        this.form.get('usageLimitPerCustomer').updateValueAndValidity();
      });

    this.form.get('LimitByBalanceByCustomer').valueChanges.subscribe(
      (mode: string) => {
        if (mode) {
          if (this.form.get('discountType').value === 'fixed') {
            this.form.get('balanceLimitByCustomer').setValidators([customBalanceLimit(this.form), Validators.required]);
          } else {
            this.form.get('balanceLimitByCustomer').setValidators([Validators.required]);
          }
        } else {
          this.form.get('balanceLimitByCustomer').setValidators([]);
        }
        this.form.get('balanceLimitByCustomer').updateValueAndValidity();
      });


    this.form.get('startDate').valueChanges.subscribe(
      (mode: string) => {
        this.minDate = moment(mode).format('YYYY-MM-DDTHH:mm');
      });

    this.form.get('endDate').valueChanges.subscribe(
      (mode: string) => {
        this.maxStartDate = moment(mode).format('YYYY-MM-DDTHH:mm');
      });

    this.form.get('discountType').setValue('percentage');
  }

  onValueChanged(data) {
  }

  delete(): void {
    // console.log(`[Company CouponUpsertComponent.delete]: `);
    const self = this;
    const name = self.data.name;
    const message = self.translations['message_delete_x'].formatUnicorn(name);
    const title = self.translations['delete_x'].formatUnicorn(name);

    self._dialogService.openConfirm({
      message: message,
      disableClose: false,
      title: title,
      cancelButton: 'Cancel',
      acceptButton: 'Delete',
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        self._couponService.delete(self.data.id, 'company').subscribe(() => {
          self._dialogService.openConfirm({
            message: self.translations['x_deleted'].formatUnicorn(name),
            disableClose: true,
            acceptButton: 'Ok',
          }).afterClosed().subscribe(() => {
            this._router.navigate([`/groups/${self.companyId}/coupons/`]);
          });
        });
      }
    });
  }

  save(): void {
    if (!this.currentAction) {
      this.currentAction = 'save';
      const self = this;
      const data = this.form.value;
      const coupon = self.data;

      delete coupon.id;
      delete coupon.modified;
      delete coupon.created;
      delete coupon['checkbox_coupon_limit_usage_count_per_customer'];
      delete coupon['checkbox_usage_count_type'];

      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          coupon[key] = data[key];
        }
      }

      if (!this.form.valid) {
        return this.validateAllFormFields(this.form);
      }

      /**
       * Call the API
       */
      coupon.companyId = this.companyId;
      if (coupon.discountFixedAmount) {
        coupon.discountFixedAmount = (coupon.discountFixedAmount * 100);
      }

      if (coupon.discountType === 'balance') {
        coupon.balanceLimitByCustomer = this.form.controls['balanceLimitByCustomerB'].value;
        coupon.LimitByBalanceByCustomer = true;
      }

      if (coupon.balanceLimitByCustomer) {
        coupon.balanceLimitByCustomer = (coupon.balanceLimitByCustomer * 100);
      }

      if (!coupon.limitByDate) {
        coupon.limitByDate = false;
      }
      if (!coupon.limitByUsage) {
        coupon.limitByUsage = false;
      }
      if (!coupon.limitByCustomer) {
        coupon.limitByCustomer = false;
      }

      if (coupon.code) {
        coupon.code = coupon.code.toUpperCase();
      }

      if (this.action.toLowerCase() === 'add') {
        this._loadingService.register(this.loaderName);
        this._couponService.insert(coupon).subscribe(() => {
          setTimeout(function () {
            self._dialogService.openAlert({
              message: ucFirst(self.translations['coupon_added_message']),
              disableClose: true,
              title: ucFirst(self.translations['coupon_added_title']),
              closeButton: 'OK'
            }).afterClosed().subscribe(() => {
              self._router.navigate([`/${(self.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${self.companyId}/coupons`]);
              self.currentAction = null;
            });
          }, 500);
        }, error => {
          self._dialogService.openAlert({
            message: self.translations['error_' + error.error.error.name.toLowerCase()],
            disableClose: true,
            title: self.translations['coupon_upsert_error_title'],
            closeButton: 'OK'
          }).afterClosed().subscribe(() => {
          });
          this._loadingService.resolve(this.loaderName);
          this.currentAction = null;
        })
      } else {
        this._couponService.update(this.id, coupon).subscribe(() => {
          setTimeout(function () {
            self._loadingService.resolve(self.loaderName);
            self.snackBar.open(ucFirst(self.translations['coupon_updated_message']), 'OK', {
              duration: 3000
            });
          }, 500);
        }, error => {
          console.error(error);
          this._loadingService.resolve(this.loaderName);
        })
      }
    }
  }
}
