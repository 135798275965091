<mat-card [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <mat-card-content class="push-bottom-none">
    <ng-template tdLoading="location-policy">
      <form *ngIf="form" [formGroup]="form">
        <div layout="row"><h3
          class="push-top-none">{{(data.name ? data.name : 'promotion_name'|translate|ucFirst)}}</h3></div>
        <div flex="50" layout="row">
          <mat-form-field flex>
            <input (change)="(formErrors.name ? this.validateFormField(this.form,
                    'name') : '')" [(ngModel)]="data.name" formControlName="name" matInput
                   placeholder="{{'name'|translate|ucFirst}}" type="text" value="{{data.name}}">
            <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.name" class="tc-red-600">
                  <span>{{ formErrors.name }}</span>
                </span>
            </mat-hint>
          </mat-form-field>
        </div>

        <div *ngIf="coupons && coupons.length > 0" flex="50" layout="row">
          <mat-form-field [ngStyle]="{'width': '100%'}" flex>
            <mat-select class="row-select" formControlName="couponId"
                        placeholder="{{'promotion_select_coupon'|translate|ucFirst}}">
              <mat-option *ngFor="let coupon of coupons"
                          [value]="coupon.id">{{coupon.name}}: {{coupon.code}} |
                <span *ngIf="coupon.discountType ==='percentage'">-{{coupon.discountPercentage}}%</span>
                <span *ngIf="coupon.discountType ==='fixed'">-
                  {{({total: (coupon.discountFixedAmount), currency: currency}) | localizedCurrency}}</span>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div flex="50" layout="row">
          <mat-form-field [ngStyle]="{'width': '100%'}" class="date-input push-right">
            <input (change)="(formErrors.startDate ? this.validateFormField(this.form,'startDate') : '')"
                   [max]="maxStartDate"
                   [min]="minDate"
                   formControlName="startDate"
                   matInput
                   placeholder="{{ 'start_date' | translate | ucFirst }}"
                   type="datetime-local">

            <mat-hint align="start" class="warning">
              <span [hidden]="!formErrors.startDate" class="tc-red-600">
                <span>{{ formErrors.startDate }}</span>
              </span>
            </mat-hint>
          </mat-form-field>

          <mat-form-field [ngStyle]="{'width': '100%'}" class="date-input">
            <input (change)="(formErrors.endDate ? this.validateFormField(this.form,'endDate') : '')"
                   [min]="maxDate"
                   formControlName="endDate"
                   matInput
                   placeholder="{{ 'end_date' | translate | ucFirst }}"
                   type="datetime-local">

            <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.endDate" class="tc-red-600">
                  <span>{{ formErrors.endDate }}</span>
                </span>
            </mat-hint>
          </mat-form-field>
        </div>

        <div flex="50" layout="row">
          <mat-form-field class="" flex>
            <input (change)="(formErrors.title ? this.validateFormField(this.form,'title') : '')" formControlName="title" matInput placeholder="{{'title'|translate|ucFirst}}"
                   type="text"
                   value="{{data.title}}">
            <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.title" class="tc-red-600">
                  <span>{{ formErrors.title }}</span>
                </span>
            </mat-hint>
          </mat-form-field>
        </div>

        <div flex="50" layout="row">
          <mat-form-field flex>
            <textarea (change)="(formErrors.text ? this.validateFormField(this.form,'text') : '')" class="inputTextArea"
                      formControlName="text" matInput
                      placeholder="{{'text'|translate|ucFirst}}"
                      rows="5" type="text" value="{{data.text}}"></textarea>

            <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.text" class="tc-red-600">
                  <span>{{ formErrors.text }}</span>
                </span>
            </mat-hint>
          </mat-form-field>
        </div>

        <div *ngIf="data.imageSrc">
          <img src="{{data.imageSrc}}" style="max-height:300px">
        </div>

        <div class="push-top" flex="50" layout="row">{{'promotion_file_upload'|translate|ucFirst}}</div>
        <div flex="50" layout="row">
          <input (change)="onFileChange($event)" formControlName="image" id="singleFile" type="file">
        </div>
      </form>
    </ng-template>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <button (click)="save()"
            color="accent"
            flex
            mat-raised-button>{{'save_promotion_button'|translate|ucFirst}}</button>

    <button (click)="openPushNotificationDialog()"
            *ngIf="action !== 'add' && data.status === 'published'"
            color="accent"
            flex
            mat-raised-button>{{'send_notification_passengerapp'|translate|ucFirst}}</button>

    <button (click)="publish(data.id)"
            *ngIf="action !== 'add' && data.status === 'archived'"
            color="accent"
            flex
            mat-raised-button>{{'publish'|translate|ucFirst}}</button>

    <button (click)="archive(data.id)"
            *ngIf="action !== 'add' && data.status === 'published'"
            color="accent"
            flex
            mat-raised-button>{{'archive'|translate|ucFirst}}</button>
  </mat-card-actions>
</mat-card>
