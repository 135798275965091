<mat-card [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <ng-template tdLoading="driver">
    <mat-card-content class="push-bottom-none">
      <ng-template tdLoading="profile">
        <form *ngIf="form" [formGroup]="form">
          <div class="push-bottom-sm push-top-sm" layout="row">
            <mat-form-field class="push-right" flex>
              <input #fname formControlName="fname" matInput placeholder="{{'first_name'|translate|ucFirst}}" required
                     type="text" value="{{invite.fname}}">
              <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.fname" class="tc-red-600">
                  <span>{{ formErrors.fname }}</span>
                </span>
              </mat-hint>
            </mat-form-field>
            <mat-form-field flex>
              <input #lname formControlName="lname" matInput placeholder="{{'last_name'|translate|ucFirst}}" required
                     type="text" value="{{invite.lname}}">
              <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.lname" class="tc-red-600">
                  <span>{{ formErrors.lname }}</span>
                </span>
              </mat-hint>
            </mat-form-field>
          </div>
        </form>
      </ng-template>
    </mat-card-content>
    <mat-divider></mat-divider>
    <mat-card-actions>
      <button (click)="save()" *ngIf="this.invite" color="accent" flex
              mat-raised-button>{{'save_profile_button'|translate}}</button>
    </mat-card-actions>
  </ng-template>
</mat-card>
