import {PriceBreakdown} from './price-breakdown';

export class Price {
  total: any;
  currency: string;
  currencyDisplay?: string;
  totalFormat?: string;
  breakdown?: PriceBreakdown;

  constructor() {
    this.breakdown = new PriceBreakdown();
    this.currency = '';
    this.total = 0;
  }
}
