<mat-list-item>
  <mat-icon mat-list-icon>assignment</mat-icon>
  <h4 mat-line>{{ 'details'|translate|ucFirst }}</h4>
  <p mat-line>{{ 'ride_details_subtitle'|translate|ucFirst }}</p>

  <mat-icon (click)="this.expanded = (!this.expanded)" *ngIf="ride.note"
            class="expand">{{ (this.expanded ? 'expand_more' : 'expand_less') }}
  </mat-icon>
</mat-list-item>
<div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{ 'reference'|translate|ucFirst }}</p>
    <p mat-line>{{ ride.reference || '-' }}</p>
  </mat-list-item>
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{ 'status'|translate|ucFirst }}</p>
    <p *ngIf="confirmed" class="status_{{ride.status}}" mat-line>
      {{ 'ride_status_' + ride.status|translate|ucFirst }}
      <span *ngIf="['canceled', 'canceled_driver', 'canceled_passenger'].includes(ride.status) && ride.cancellation">
          ({{ 'cancellation_reason_' + ride.cancellation.reason|translate|ucFirst }})
      </span>
    </p>
    <p *ngIf="!confirmed" class="tc-red-600" mat-line>{{ 'unconfirmed'|translate|ucFirst }}</p>
  </mat-list-item>
</div>
<div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="50" tdMediaToggle="gt-xs">
  <mat-list-item class="value small" flex-gt-sm="33">
    <p mat-line>{{ 'origin'|translate|ucFirst }}</p>
    <p mat-line>{{ (ride.origin ? ride.origin.name : '-') }}</p>
  </mat-list-item>
  <mat-list-item *ngIf="ride.Owner || this.expanded" class="value small" flex-gt-sm="50">
    <p mat-line>{{ 'owner'|translate|ucFirst }}</p>
    <p
      mat-line>{{ (ride.Owner && ride.ownerType === 'Driver' ? ride.Owner.fname + ' ' + ride.Owner.lname : ride.Owner.name) }}</p>
  </mat-list-item>
  <mat-list-item *ngIf="!ride.Owner" class="value small" flex-gt-sm="50">
    <p mat-line>{{ 'owner'|translate|ucFirst }}</p>
    <p mat-line>-</p>
  </mat-list-item>
</div>

<div *ngIf="ride.foreignDispatchId || ride.foreignBookingId" [mediaClasses]="['push-sm']"
     class="pull-top-sm push-bottom-sm"
     layout-gt-sm="50"
     tdMediaToggle="gt-xs">
  <mat-list-item *ngIf="ride.foreignDispatchId" class="value small" flex-gt-sm="33">
    <p mat-line>{{ 'foreign_dispatch_id_label'|translate|ucFirst }}</p>
    <p mat-line>{{ (ride.foreignDispatchId ? ride.foreignDispatchId : '-') }}</p>
  </mat-list-item>
  <mat-list-item *ngIf="ride.foreignBookingId && ride.foreignDispatchId !== ride.foreignBookingId" class="value small"
                 flex-gt-sm="50">
    <p mat-line>{{ 'foreign_booking_id_label'|translate|ucFirst }}</p>
    <p mat-line>{{ ride.foreignBookingId }}</p>
  </mat-list-item>
</div>

<div *ngIf="['canceled', 'canceled_driver', 'canceled_passenger'].includes(ride.status) && ride.cancellation && false"
     [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="50"
     tdMediaToggle="gt-xs">
  <mat-list-item *ngIf="ride.cancellation.refundStatus && false" class="value small" flex-gt-sm="50">
    <p mat-line>{{ 'refund_status'|translate|ucFirst }}</p>
    <p mat-line>{{ ride.cancellation.refundStatus.toLowerCase()|translate|ucFirst }}</p>
  </mat-list-item>
</div>

<div *ngIf="ride.note && this.expanded" [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm"
     layout-gt-sm="50"
     tdMediaToggle="gt-xs">
  <mat-list-item class="value small" flex-gt-sm="100">
    <p mat-line>{{ 'note'|translate|ucFirst }}</p>
    <p mat-line>{{ (ride.note ? ride.note : '-') }}</p>
  </mat-list-item>
</div>


<div *ngIf="['canceled', 'canceled_driver', 'canceled_passenger'].includes(ride.status) && ride.cancellation"
     [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="50"
     tdMediaToggle="gt-xs">
  <mat-list-item *ngIf="ride.cancellation.note" class="value small" flex-gt-sm="50">
    <p mat-line>{{ 'cancellation_note'|translate|ucFirst }}</p>
    <p mat-line>{{ ride.cancellation.note | translate }}</p>
  </mat-list-item>
  <mat-list-item *ngIf="ride.cancellation.cancellationChargeAmount" class="value small" flex-gt-sm="50">
    <p mat-line>{{ 'cancellation_charge_amount'|translate|ucFirst }}</p>
    <p mat-line>{{ ride.cancellation.cancellationChargeAmount | localizedCurrency }}</p>
  </mat-list-item>
</div>



