import {Component, ElementRef, Inject, OnInit} from '@angular/core';
import {Driver} from '../../../models/driver';
import {User} from '../../../models/user';
import {Company} from '../../../models/company';
import {ActivatedRoute, Router} from '@angular/router';
import {DriverService} from '../../../services/driver.service';
import {CompanyWebUserService} from '../../../services/companyWebUser.service';
import {CompanyService} from '../../../services/company.service';
import {TdLoadingService} from '@covalent/core/loading';
import {CoolLocalStorage} from '@angular-cool/storage';
import {AuthService} from '../../../services/auth.service';
import {TdDialogService} from '@covalent/core/dialogs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../../environments/environment';
import {TdDataTableService} from '@covalent/core/data-table';

@Component({
  selector: 'app-support-workspace-selection',
  templateUrl: './support-workspace-selection.component.html',
  styleUrls: ['./support-workspace-selection.component.scss']
})
export class SupportWorkspaceSelectionComponent implements OnInit {
  driver: Driver;
  user: User;
  company: Company = {};
  companies: Company[] = [];
  filteredData: any = {};
  operatorCompanies: Company[] = [];
  ownerCompanies: Company[] = [];
  supportTrialCompanies: Company[];
  supportContractCompanies: Company[];
  unpaidContractCompanies: Company[];
  expiredContractCompanies: Company[];
  expiredTrrialContractCompanies: Company[];
  taxiIDContractCompanies: Company[];
  loading = true;
  noRedirect = false;
  parent: any;
  searchTerm: string;
  target = 'dispatchpanel';

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _driverService: DriverService,
    private _companyWebUserService: CompanyWebUserService,
    private _companyService: CompanyService,
    private _loadingService: TdLoadingService,
    private _vault: CoolLocalStorage,
    private _authService: AuthService,
    private _dialogService: TdDialogService,
    private _dataTableService: TdDataTableService,
    private element: ElementRef,
    public dialogRef: MatDialogRef<SupportWorkspaceSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this._loadingService.register('workspace');
    if (data.parent && data.parent.company) {
      this.company = data.parent.company;
    }
    if (data) {
      this.parent = data;
    }
    if (data.redirect) {
      this.target = data.redirect;
    } else {
      this.noRedirect = true;
    }
  }

  ngOnInit(): void {
    const self = this;
    this.driver = JSON.parse(self._vault.getItem(`${environment.vaultPrefix}.driver`));
    this.user = JSON.parse(self._vault.getItem(`${environment.vaultPrefix}.user`));
    if (!this.user) {
      this.logout();
      return;
    }

    self.loadData();
  }

  setFocus() {
    const input = this.element.nativeElement.querySelector( 'input[type="search"]' );
    if (input) {
      input.focus()
    }
  }

  loadData() {
    const self = this;
    if (this.user.ydaSupportUser) {
      this._companyService.getAll({'where': {'hadTrial': true}, 'order': 'created desc', 'include': 'ActiveContract'})
        .subscribe((companies: any[]) => {
          this._loadingService.register('resolve');
          self.supportTrialCompanies = companies.filter((c) => {
            return (c.ActiveContract && c.ActiveContract.type === 'free-trial')
          });
        }, error => {
          console.log(error);
          this.logout();
        });


      this._companyService.getAll({'where': {'type': 'business'}, 'order': 'created desc', 'include': 'ActiveContract'})
        .subscribe((companies: any[]) => {
          this._loadingService.register('resolve');
          self.supportContractCompanies = companies.filter((c) => {
            return (c.ActiveContract && c.ActiveContract.type !== 'free-trial' && !c.ActiveContract.taxiIDContract && c.ActiveContract.foreignStatus !== 'unpaid')
          });
          self.taxiIDContractCompanies = companies.filter((c) => {
            return (c.ActiveContract && c.ActiveContract.type !== 'free-trial' && c.ActiveContract.taxiIDContract && c.ActiveContract.foreignStatus !== 'unpaid')
          });

          self.unpaidContractCompanies = companies.filter((c) => {
            return (c.ActiveContract && c.ActiveContract.type !== 'free-trial' && c.ActiveContract.foreignStatus === 'unpaid')
          });
          self.expiredContractCompanies = companies.filter((c) => {
            return ((c.ActiveContract && c.ActiveContract.foreignStatus === 'expired') || (!c.ActiveContract && c.hadTrial))
          });

          self.filteredData['supportContractCompanies'] = self.supportContractCompanies;
          self.filteredData['taxiIDContractCompanies'] = self.taxiIDContractCompanies;
          self.filteredData['supportTrialCompanies'] = self.supportTrialCompanies;
          self.filteredData['unpaidContractCompanies'] = self.unpaidContractCompanies;
          self.filteredData['expiredContractCompanies'] = self.expiredContractCompanies;

          self.loading = false;
          self._loadingService.resolve('workspace');
          self.setFocus();
        }, error => {
          console.log(error);
          this.logout();
        })
    }

    /**
     * Check if we have multiple companies, then redirect to login selection
     */
    self._companyWebUserService.getCompanies().subscribe((companyWebUser: any[]) => {
      if (companyWebUser.length > 0) {
        const operatorCompanies = companyWebUser.filter((user) => {
          return (user.Company && (user.type === 'operator'));
        });

        self.operatorCompanies = operatorCompanies.map((operator) => {
          if (operator.Company) {
            operator.Company.name = operator.Company.name.charAt(0).toUpperCase() + operator.Company.name.slice(1);
            operator.Company.type = operator.type;
            return operator.Company;
          }
        });

        self.operatorCompanies = self.operatorCompanies.sort(function (a, b) {
          if (a && b) {
            return (a['name'] > b['name']) ? 1 : (a['name'] < b['name']) ? -1 : 0;
          }
        });

        const adminCompanies = companyWebUser.filter((user) => {
          return (user.Company && (user.type === 'owner' || user.type === 'admin'));
        });

        self.companies = adminCompanies.map((company) => {
          if (company.Company) {
            company.Company.name = company.Company.name.charAt(0).toUpperCase() + company.Company.name.slice(1);
            company.Company.type = company.type;
            return company.Company;
          }
        });

        self.companies = self.companies.sort(function (a, b) {
          if (a && b) {
            return (a['name'] > b['name']) ? 1 : (a['name'] < b['name']) ? -1 : 0;
          }
        });

        if (self.companies.length === 1 && !this.noRedirect) {
          self.dialogRef.close();
          self._router.navigate([`/groups/${self.companies[0].id}/${this.target}`]);
        }
        self.filteredData['operatorCompanies'] = self.expiredContractCompanies;
        self.setFocus();
      }
    }, (error) => {
      console.log(error);
      this.logout();
    });
  }

  logout(): void {
    this._authService.logout();
  }

  openWorkSpace(url, companyId?): void {
    if (!companyId) {
      window.location.href = url;
      this.dialogRef.close();
    } else {
      this.checkOnboardingWizard(url, companyId)
        .then((redirectUrl: string) => {
          window.location.href = redirectUrl;
          this.dialogRef.close();
        });
    }
  }

  search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.filter();
  }

  filter(): void {
    const self = this;
    self.filteredData['supportContractCompanies'] = this._dataTableService.filterData(self.supportContractCompanies, this.searchTerm, true);
    self.filteredData['supportTrialCompanies'] = this._dataTableService.filterData(self.supportTrialCompanies, this.searchTerm, true);
    self.filteredData['taxiIDContractCompanies'] = this._dataTableService.filterData(self.taxiIDContractCompanies, this.searchTerm, true);
    self.filteredData['unpaidContractCompanies'] = this._dataTableService.filterData(self.unpaidContractCompanies, this.searchTerm, true);
    self.filteredData['expiredContractCompanies'] = this._dataTableService.filterData(self.expiredContractCompanies, this.searchTerm, true);
  }

  checkOnboardingWizard(url, companyId) {
    return new Promise((resolve) => {
      this._companyService.get(companyId)
        .subscribe((company) => {
          if ((!company.settings.wizard || !['completed', 'done'].includes(company.settings.wizard)) &&
            !(company.foreignId)) {
            if (company.settings.wizard === 'skipped') {
              company.settings.wizard = 'wizard';
            }
            return resolve(`onboarding-wizard/${company.id}/${(company.settings.wizard ? company.settings.wizard : 'wizard')}`);
          } else {
            return resolve(url);
          }
        })
    });
  }
}
