import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { TranslateLoader } from '@ngx-translate/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {environment} from '../../environments/environment';

@Injectable()
export class TranslationService implements TranslateLoader {

  constructor(private http: HttpClient,
              private _vault: CoolLocalStorage) { }

  getTranslation(lang: string): Observable<any> {
    if (lang === 'default') {
      return Observable.of('[]');
    }
    const self = this;
    const projectUrl = 'https://localization-api.dispatchapi.io/api/Projects/591afd8a17f51db67fc57968';
    const result = self._vault.getObject(projectUrl);
    if (result && environment.production) {
      return Observable.of(result);
    } else {
      return this.http.get(`${projectUrl}/${lang}`)
        .map((response: JSON) => {
          self._vault.setObject(projectUrl, response);
          return response;
        });
    }
  }
}
