import {Component, Input, OnInit} from '@angular/core';
import {Price} from '../../../../../models/price';
import {PaymentMeta} from '../../../../../models/payment-meta';
import {Coupon} from '../../../../../models/coupon';
import {Ride} from '../../../../../models/ride';

@Component({
  selector: 'app-ride-price-data',
  templateUrl: './ride-price-data.component.html',
  styleUrls: ['./ride-price-data.component.scss']
})
export class RidePriceDataComponent implements OnInit {
  priceMethod: string;
  priceCalculationMethod: string;
  @Input() isOwner = false;
  @Input() isDriver = false;
  @Input() vehicleProduct: any;
  @Input() paymentMeta: PaymentMeta;
  @Input() price: Price = new Price();
  @Input() ride: Ride;
  @Input() coupon: Coupon;

  ngOnInit() {
    this.waitForLoading();
  }

  waitForLoading() {
    if (!this.ride || !this.ride.priceMethod) {
      setTimeout(() => {
        this.waitForLoading();
      }, 100);
    } else {
      this.priceMethod = this.ride.priceMethod;
      if (this.priceMethod === 'onMeter') {
        if (this.ride.calculationMethod) {
          this.priceCalculationMethod = this.ride.calculationMethod;
        } else if (this.ride.driverTariffId && this.ride.DriverTariff) {
          this.priceCalculationMethod = this.ride.DriverTariff.name;
        } else if (this.ride.ownerType === 'Company') {
          this.priceCalculationMethod = 'group_pricing_rules';
        } else {
          this.priceCalculationMethod = 'manual';
        }
      }
    }
  }
}
