import {Component, Inject, OnInit} from '@angular/core';
import {Company} from '../../../../../../models/company';
import {CompanyService} from '../../../../../../services/company.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContractService} from '../../../../../../services/contract.service';
import {UntypedFormBuilder, Validators} from '@angular/forms';

@Component({
  selector: 'app-contract-edit-dialog',
  templateUrl: './contract-edit-dialog.component.html',
  styleUrls: ['./contract-edit-dialog.component.scss']
})
export class ContractEditDialogComponent implements OnInit {
  form: any
  company: Company;
  translations: string[];
  cancelStatus: string;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _companyService: CompanyService,
    private _contractService: ContractService,
    private _snackbar: MatSnackBar,
    private _translateService: TranslateService,
    public dialogRef: MatDialogRef<ContractEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.company = data.parent.company;
    this.translations = data.parent.translations;
    this.cancelStatus = `${this.company.ActiveContract.cancelAtPeriodEnd}`;
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      reason: ['', Validators.required]
    })
  }

  changeSelection(event): void {

  }

  closeDialog() {
    this.dialogRef.close();
  }

  updateContractStatus(cancelAt): void {
    const self = this;
    if (this.form.valid) {
      this.data.parent.loadingService.register('contract.products');
      self.closeDialog();
      this._contractService.update(this.company.ActiveContract.id, {
        'cancelAtPeriodEnd': cancelAt,
        'reason': this.form.value.reason,
      }).subscribe((result) => {
        if (result.error) {
          this._snackbar.open(this.translations['cloud_connect_googlekey_error'], '', {duration: 3000});
        } else {
          if (cancelAt) {
            this._snackbar.open(this.translations['cloud_connect_cancel_at_period_end_message'], '', {duration: 3000});
          } else {
            this._snackbar.open(this.translations['cloud_connect_auto_renew_contract_message'], '', {duration: 3000});
          }
          self.data.parent.loadData();
        }
      })
      return;
    }
  }
}
