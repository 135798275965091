export class PriceMeta {
  currency: string;
  bookedDuration: any;
  hourlyRate: any;

  constructor() {
    this.currency = '';
    this.bookedDuration = 0;
    this.hourlyRate = 0;
  }
}
