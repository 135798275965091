import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RideCountWidgetComponent} from './ride-count-widget/ride-count-widget.component';
import {DriverProfileWidgetComponent} from './driver-profile-widget/driver-profile-widget.component';
import {DriverScoreWidgetComponent} from './driver-score-widget/driver-score-widget.component';
import {RideStatusWidgetComponent} from './ride-status-widget/ride-status-widget.component';
import {PlannedRidesWidgetComponent} from './planned-rides-widget/planned-rides-widget.component';
import {BookingBreakdownWidgetComponent} from './booking-breakdown-widget/booking-breakdown-widget.component';
import {RatingsWidgetComponent} from './ratings-widget/ratings-widget.component';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient} from '@angular/common/http';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {AreaChartModule, BarChartModule} from '@swimlane/ngx-charts';
import {RouterModule} from '@angular/router';
import {UcFirstPipeModule} from '../pipes/uc-first.pipe';
import {ReplacePipeModule} from '../pipes/replace.pipe';
import {MomentModule} from 'angular2-moment';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'https://localization-api.dispatchapi.io/api/Projects/591afd8a17f51db67fc57968/', '');
}

@NgModule({
  imports: [
    CommonModule,
    UcFirstPipeModule,
    ReplacePipeModule,
    MatCardModule,
    AreaChartModule,
    BarChartModule,
    RouterModule,
    MatListModule,
    MatTabsModule,
    MatIconModule,
    MatSelectModule,
    MatButtonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    MomentModule
  ],
  declarations: [
    RideCountWidgetComponent,
    DriverProfileWidgetComponent,
    DriverScoreWidgetComponent,
    RideStatusWidgetComponent,
    RatingsWidgetComponent,
    PlannedRidesWidgetComponent,
    BookingBreakdownWidgetComponent
  ],
  exports: [
    RideCountWidgetComponent,
    DriverProfileWidgetComponent,
    DriverScoreWidgetComponent,
    RideStatusWidgetComponent,
    RatingsWidgetComponent,
    PlannedRidesWidgetComponent,
    BookingBreakdownWidgetComponent
  ]
})
export class WidgetsModule {
}
