import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AgmMap, GoogleMapsAPIWrapper} from '@agm/core';
import {environment} from '../../../../../../environments/environment';
import {Driver} from '../../../../../models/driver';

declare var google: any;

@Component({
  selector: 'app-driver-map',
  templateUrl: './driver-map.component.html',
  styleUrls: ['./driver-map.component.scss']
})
export class DriverMapComponent implements OnInit {
  @ViewChild(AgmMap) map: AgmMap;
  mapInstance: any;

  @Input() drivers: Driver[];

  route: any[] = [];
  mapRoute: any;
  bounds: any;
  boundsDefined = false;
  defaultMarkerIconUrl: string;
  departureMarkerIconUrl: string;
  departureMarkerIcon: any;
  destinationMarkerIconUrl: string;
  destinationMarkerIcon: any;
  lineColor: string;

  constructor(private _gMapsApi: GoogleMapsAPIWrapper) {
    this.defaultMarkerIconUrl = `/assets/${environment.buildName}/icon-marker.png`;
    this.departureMarkerIconUrl = `/assets/${environment.buildName}/icon-departure-marker.png`;
    this.destinationMarkerIconUrl = `/assets/${environment.buildName}/icon-destination-marker.png`;
    this.lineColor = `${environment.mapLineColor}`;
  }

  ngOnInit() {
    // console.log('[---DriverMapComponent---]');

    this._gMapsApi.getNativeMap().then((map) => {
      // console.log('[---DriverMapComponent---]');
      map.addListener('tilesloaded', () => {
        // console.log();
        /**
         * Check if we have a ride and whether we've already defined the bounds or not
         */
        if (this.drivers.length > 0 && !this.boundsDefined) {
          this.bounds = new google.maps.LatLngBounds();

          this.drivers.forEach((driver) => {
            // if(driver.Vehicle.location.gps) {
            //   const departureLocation = new google.maps.LatLng(this.ride.departure.gps.lat, this.ride.departure.gps.lng);
            //   const departureMarker = new google.maps.Marker({
            //     position: departureLocation,
            //     map: map,
            //     icon: this.departureMarkerIconUrl
            //   });
            //   this.bounds.extend(departureLocation);
            // }
          })
          /**
           * Force the map to fit its new bounds and pan to them so it fits all markers and polylines
           */
          this._gMapsApi.fitBounds(this.bounds);
          this._gMapsApi.panToBounds(this.bounds);
          // if (!UtilityService.notNullOrUndefined(this.ride.departure) || !UtilityService.notNullOrUndefined(this.ride.destination)) {
          this._gMapsApi.setZoom(1);
          // }
          this.boundsDefined = true;
        }
      });

    });
  }

  mapReady(map): void {
    this.mapInstance = map;
  }
}
