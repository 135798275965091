<div [formGroup]="form" style="width:400px">
  <mat-card-title class="push-bottom">{{'change_owner_title'|translate|ucFirst}}</mat-card-title>
  <mat-card-subtitle
    [innerHTML]="'change_owner_subtitle'|translate|keepHtml" class="push-bottom"></mat-card-subtitle>
  <mat-divider *ngIf="false"></mat-divider>
  <div class="table">
    <div *ngIf="admins && admins.length === 0" class="table-header" layout="row">
      <div class="left-column" layout="column">
        {{'change_owner_no_admins'|translate|ucFirst}}
      </div>
    </div>
  </div>

  <div class="table">
    <div *ngIf="admins && admins.length > 0" class="table-header" layout="row">
      <div class="left-column" layout="column">
        {{'change_owner_select_new_owner'|translate|ucFirst}}
      </div>
    </div>

    <div *ngIf="admins && admins.length > 0" flex="100" layout="row push-top">
      <mat-form-field [ngStyle]="{'width': '100%'}" flex>
        <mat-select class="row-select" formControlName="admin"
                    placeholder="{{'change_owner_select_admin'|translate|ucFirst}}">
          <mat-option *ngFor="let admin of admins"
                      [value]="admin.id">{{admin.fname}} {{admin.lname}} | {{admin.phoneNumber}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div *ngIf="admins && admins.length > 0" class="full-width" flex="100">
    <button (click)="this.updateOwner()" class="push-top push-right" color="primary" flex
            mat-raised-button>
      {{'update_owner'|translate|ucFirst}}</button>
  </div>

  <div *ngIf="admins && admins.length === 0" class="full-width" flex="100">
    <button (click)="dialogRef.close();" class="push-top push-right" color="primary" flex
            mat-raised-button>
      {{'close'|translate|ucFirst}}</button>
  </div>
</div>
