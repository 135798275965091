<table>
  <thead>
  <tr>
    <th></th>
    <th *ngFor="let item of productArray">{{item.toLowerCase() + '_license_title'|translate|ucFirst}}</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let feature of featureArray" [className]="(feature.indexOf('title|') > -1 ? 'feature_title_row' :
      (feature.indexOf('bold|') > -1 ? 'feature_bold_row' : (!feature ? 'empty_row' : '')))">
    <td>
          <span
            *ngIf="feature">{{'license_feature_' + feature.replace('title|', '').replace('bold|', '')|translate|ucFirst}}</span>
    </td>
    <td
      *ngFor="let item of productArray">{{products[item].features[feature.replace('title|', '').replace('bold|', '')]|translate|ucFirst}}</td>
  </tr>
  <tr *ngIf="this.company">
    <td></td>
    <td *ngFor="let item of productArray">
      <button (click)="initPayment(products[item].priceId, item)" [disabled]="(this.company.ActiveContract && (
                    (['webbooker_license','webbooker_plus_license','fleet_solutions'].includes(this.company.ActiveContract.type) &&
                    item === 'webbooker') ||
                    (['webbooker_plus_license','fleet_solutions'].includes(this.company.ActiveContract.type)
                    && item === 'webbookerPlus') ||
                    (['fleet_solutions'].includes(this.company.ActiveContract.type) && item === 'fleetSolutions')
                  ))"
              color="accent"
              flex
              mat-raised-button>{{(this.company.ActiveContract && (
        (this.company.ActiveContract.type === 'webbooker_license' && item === 'webbooker') ||
        (this.company.ActiveContract.type === 'webbooker_plus_license' && item === 'webbookerPlus') ||
        (this.company.ActiveContract.type === 'fleet_solutions' && item === 'fleetSolutions')
      ) ? 'active_contract' : 'buy_now')|translate|ucFirst}}</button>
    </td>
  </tr>
  </tbody>
</table>
