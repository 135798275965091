import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PaymentMeta} from '../../../../../../../models/payment-meta';
import {UntypedFormGroup} from '@angular/forms';
import {Company} from '../../../../../../../models/company';
import {TdDialogService} from '@covalent/core/dialogs';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../../../../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';

@Component({
  selector: 'app-ride-edit-paymentmethod',
  templateUrl: './ride-edit-paymentmethod.component.html',
  styleUrls: ['./ride-edit-paymentmethod.component.scss']
})
export class RideEditPaymentmethodComponent implements OnInit {
  @Input() paymentMeta: PaymentMeta;
  @Input() form: UntypedFormGroup;
  @Input() company: Company;
  @Output() conditionalFormatting = new EventEmitter<string>();
  stripeOauthConnectUrl = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${environment.stripeOauthClientId}&scope=read_write`;

  translations: string[];

  constructor(
    private _dialogService: TdDialogService,
    private _vault: CoolLocalStorage,
    private _translateService: TranslateService,
  ) {
    _translateService.get([
      `no_passenger_selected_title`,
      `no_stripe_integration_found`,
      `cancel`,
      'start_integration',
    ]).subscribe((translations: any) => {
      this.translations = translations;
    });
  }

  ngOnInit() {
  }

  changeSelection(event) {
    const self = this;
    if (this.form.controls['paymentMeta']) {
      this.form.controls['paymentMeta']['controls']['paymentMethodId'].setValue('select');
    }
    if (this.form.controls['paymentMeta']['controls']['origin'].value === 'creditcardOnline' && !self.company.stripeAccountId) {
      self._dialogService.openConfirm({
        message: self.translations['no_stripe_integration_found'],
        disableClose: false,
        cancelButton: self.translations['cancel'],
        acceptButton: self.translations['start_integration'],
      }).afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          self.startStripeIntegration(self.company);
        } else {
          this.form.controls['paymentMeta']['controls']['origin'].setValue('inTaxi');
        }
      });
    } else if (this.form.controls['paymentMeta']['controls']['origin'].value === 'creditcardOnline' && !this.form.controls['passengerId'].value) {
      self._dialogService.openAlert({
        message: self.translations['no_passenger_selected_title'],
        disableClose: false,
        closeButton: self.translations['ok'],
      }).afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          self.startStripeIntegration(self.company);
        } else {
          this.form.controls['paymentMeta']['controls']['origin'].setValue('inTaxi');
        }
      });
    } else if (this.form.controls['paymentMeta']['controls']['origin'].value === 'creditcardOnline') {
      // @ts-ignore
      this.form.controls['paymentMeta']['controls']['paymentMoment'].setValue('pickuptime');
      // @ts-ignore
      this.form.controls['paymentMeta']['controls']['hoursBeforePickup'].setValue(4);
    } else {
      this.form.controls['paymentMeta']['controls']['paymentMoment'].setValue(null);
    }
    this.conditionalFormatting.emit();
  }

  startStripeIntegration(company): void {
    this._vault.setObject(`${environment.vaultPrefix}.stripeConnect`, company);
    const redirectUrl = `${environment.portalUrl}/stripeConnect`;
    window.open(`${this.stripeOauthConnectUrl}&redirect_uri=${redirectUrl}`);
  }
}
