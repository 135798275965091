<mat-toolbar class="mat-whiteframe-z1" color="primary">
  <div (click)="switchWorkspace()" class="top-logo">
    <mat-icon class="mat-icon-logo"
              svgIcon="assets:logo"></mat-icon>
  </div>

  <div *ngIf="this.company" class="workspace-switch">
    <div (click)="switchWorkspace()" class="text-truncate">{{this.company.name}}</div>
    <a (click)="switchWorkspace()" class="workspace workspace-arrow-down">
      <mat-icon>keyboard_arrow_down</mat-icon>
    </a>
  </div>

  <!--<span>{{portalName}}</span>-->
  <span flex></span>
  <span *ngIf="this.company">{{'onboarding_wizard_header'|translate|ucFirst}}</span>
</mat-toolbar>

<div *tdLoading="'calendar-loading'" class="onboarding-pane">
  <div class="onboarding-inner-pane">
    <app-ob-webbooker (nextStep)="nextStep($event)" *ngIf="step==='webbooker' && this.webBooker"
                      [currentWebbookerId]="this.webBooker.id" [form]="form"
                      [step]="step"></app-ob-webbooker>

    <app-ob-servicearea (nextStep)="nextStep($event)" *ngIf="step==='servicearea' && company" [company]="company" [form]="form"
                    [step]="step"></app-ob-servicearea>

    <app-ob-availability (nextStep)="nextStep($event)" *ngIf="step==='availability'" [form]="form"
                         [step]="step"></app-ob-availability>


    <app-ob-calendar (nextStep)="nextStep($event)" *ngIf="step==='calendar'" [company]="company" [form]="form"
                     [step]="step"></app-ob-calendar>

    <app-ob-service (nextStep)="nextStep($event)" *ngIf="step==='service'" [company]="company" [form]="form"
                    [step]="step"></app-ob-service>

    <app-ob-completed (nextStep)="nextStep($event)" *ngIf="step==='completed' && this.webBooker" [form]="form"
                      [step]="step"
                      [webbooker]="this.webBooker"></app-ob-completed>
  </div>
</div>
