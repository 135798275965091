import {Price} from './rule';

export enum VehicleTypes {
  saloon = 'saloon',
  estate = 'estate',
  minivan = 'minivan',
  bus = 'bus',
  // limo = 'limo',
}

export class Product {
  _id?: string;
  productId?: string;
  name: string;
  maxPassengers: number;
  maxLuggage: number;
  extraInfo: string;
  description: string;
  category: string;
  type: VehicleTypes;
  imagePath: string;
  sku: string;
  price: any;
  dispatchTypes: string[];
  priceReturn: any;
  isFixed: boolean;

  constructor(public prices?: Price[]) {
  }
}
