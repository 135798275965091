import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {User} from '../models/user';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Router} from '@angular/router';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Driver} from '../models/driver';
import {Observable} from 'rxjs';
import {GlobalService} from './global.service';
import {UtilityService} from './utility.service';

@Injectable()
export class UserService extends GlobalService {
  constructor(_http: HttpClient, _vault: CoolLocalStorage, _utilityService: UtilityService, private _router: Router) {
    super(_http, _vault, _utilityService);
    this.setModel('WebPortalUsers');
  }

  getYdaDriver(id: string, filter?: any): Observable<Driver> {
    if (!filter.where) {
      filter.where = {};
    }

    filter.where.userId = id;
    filter.where.companyId = null;

    return this._http.get<Driver>(
      `${environment.apiBaseUrl}/drivers/`,
      {
        headers: this.getHeaders(),
        params: new HttpParams().set('filter', JSON.stringify(filter))
      }
    );
  }

  getCompanyDriver(phoneNumber: string, companyId: string): Observable<Driver> {
    const filter = {
      'where': {
        'phoneNumber': encodeURIComponent(phoneNumber),
        'companyId': companyId
      },
      'limit': 1
    };
    return this._http.get<Driver>(`${environment.apiBaseUrl}/drivers?filter=${JSON.stringify(filter)}`, {headers: this.getHeaders()});
  }

  create(user: User): Observable<User> {
    return this._http.post<User>(`${environment.apiBaseUrl}/WebPortalUsers`, user, {headers: this.getHeaders()});
  }

  login(credentials): Observable<any> {
    return this._http.post(`${environment.apiBaseUrl}/WebPortalUsers/login?include=user`, credentials, {headers: this.getHeaders()});
  }

  logout(): void {
    this._router.navigate(['/login']);
  }

  validateCredentials(credentials): Observable<any> {
    return this._http.post(`${environment.apiBaseUrl}/WebPortalUsers/validateCredentials`, credentials, {headers: this.getHeaders()});
  }

  connectPortalUser(userId, driverUserId): Observable<any> {
    return this._http.post(`${environment.apiBaseUrl}/WebPortalUsers/connectPortalUserRemote/`, {userId, driverUserId}, {headers: this.getHeaders()});
  }

  rejectPortalUser(userId, credentials): Observable<any> {
    return this._http.patch(`${environment.apiBaseUrl}/WebPortalUsers/${userId}/`, credentials, {headers: this.getHeaders()});
  }

  createPortalUser(user: any): Observable<any> {
    return this._http.post(`${environment.apiBaseUrl}/WebPortalUsers/createPortalUserRemote`, user, {headers: this.getHeaders()});
  }

  resendVerificationEmail(user: any): Observable<any> {
    return this._http.post(`${environment.apiBaseUrl}/WebPortalUsers/sendVerificationEmailRemote`, user, {headers: this.getHeaders()});
  }

  requestReset(credentials): Observable<any> {
    return this._http.post(`${environment.apiBaseUrl}/WebPortalUsers/requestReset`, credentials, {headers: this.getHeaders()});
  }

  reset(data): Observable<any> {
    return this._http.post(`${environment.apiBaseUrl}/WebPortalUsers/resetPassword`, data, {headers: this.getHeaders()});
  }

  verifyEmailAddress(data: any, context?: string): Observable<any> {
    return this._http.post(`${environment.apiBaseUrl}/WebPortalUsers/verifyEmailAddress`, data, {headers: this.getHeaders(context)});
  }

  confirmWebPortalUserEmail(data: any): Observable<any> {
    return this._http.post(`${environment.apiBaseUrl}/WebPortalUsers/confirmWebPortalUserEmail`, data, {headers: this.getHeaders()});
  }

  checkUsernameExistence(username: string): Observable<any> {
    username = encodeURIComponent(username);
    const filter = {
      or: [
        {
          email: username
        },
        {
          username: username
        }
      ]
    };
    return this._http.get(
      `${environment.apiBaseUrl}/WebPortalUsers/count?where=${JSON.stringify(filter)}`,
      {headers: this.getHeaders()}
    );
  }

  findUserMerge(user: any, ignoreRejected: boolean = false): Observable<any> {
    return this._http.post(`${environment.apiBaseUrl}/WebPortalUsers/${user.id}/findMergeCandidates`, {email: user.email, ignoreRejected}, {headers: this.getHeaders()});
  }

  inviteConnectPortalUser(user: any, context?: string): Observable<any> {
    return this._http.post(`${environment.apiBaseUrl}/WebPortalUsers/inviteConnectPortalUserRemote`, user, {headers: this.getHeaders(context)});
  }

  googleProfile(credentials): Observable<any> {
    return this._http.post(`${environment.apiBaseUrl}/WebPortalUsers/googleProfile?include=user`, credentials, {headers: this.getHeaders()});
  }
}
