<mat-card [class]="(dialogData ? 'dialog' : '')" [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <ng-template tdLoading="product">
    <mat-card-title *ngIf=form class="text-truncate">
      {{ (form.value.name || 'New product') | ucFirst }}
    </mat-card-title>
    <mat-card-subtitle>
      {{ 'products_subtitle' | translate | ucFirst }}
    </mat-card-subtitle>

    <mat-divider></mat-divider>

    <mat-card-content class="push-top" flex="100" layout="table">
      <form *ngIf="form" [formGroup]="form">
        <div flex="100" layout="row">
            <mat-form-field class="full-width left_form" flex="100">
              <input formControlName="name"
                     matInput
                     placeholder="{{ 'name' | translate | ucFirst }}"
                     type="text">
            </mat-form-field>
            <mat-form-field class="full-width" flex="100" *ngIf="!this.dialogData">
              <mat-select class="row-select"
                          flex="100"
                          formControlName="dispatchTypes"
                          multiple
                          placeholder="{{ 'dispatch_vehicle_types' | translate | ucFirst }}" required>
                <mat-option *ngFor="let type of types" [value]="type">
                  {{ type | translate | ucFirst }}
                </mat-option>
              </mat-select>
            </mat-form-field>
        </div>

        <div class="push-top" layout="row">
          <div flex="50" layout="column">
            <div [style.width.%]="100" class="left_form" flex="100">
              <mat-form-field [style.width.%]="50" class="left_form" flex="50">
                <input autocomplete="off" formControlName="maxPassengers"
                       matInput
                       max="1000"
                       min="0"
                       placeholder="{{ 'passenger_capacity' | translate | ucFirst }}"
                       type="number">
              </mat-form-field>

              <mat-form-field [style.width.%]="50" class="right_form" flex="50">
                <input autocomplete="off" formControlName="maxLuggage"
                       matInput
                       max="100"
                       min="0"
                       placeholder="{{ 'luggage_capacity' | translate | ucFirst }}"
                       type="number">
              </mat-form-field>
            </div>
          </div>
          <div flex="50" layout="column">
            <div [style.width.%]="100" flex="100">
                <mat-form-field class="extra-info full-width" flex="100">
                  <input
                    autocomplete="off"
                    flex="100" formControlName="extraInfo"
                    matInput
                    placeholder="{{ 'extra_info' | translate | ucFirst }}"
                    type="text">
                </mat-form-field>
            </div>
          </div>
        </div>
        <div class="push-top description" flex="100" layout="row">
            <mat-form-field class="full-width" flex="100">
              <textarea flex="100" formControlName="description"
                        matInput
                        placeholder="{{ 'description' | translate | ucFirst }}">
              </textarea>
            </mat-form-field>
        </div>
        <div class="push-top" flex="50" layout="row">
          <div flex="100">
            <div *ngIf="form.controls['imagePath'].value">
              <img class="image-preview" src="{{form.controls['imagePath'].value}}">
            </div>
              <button (click)="selectDefaultimage()"
                      color="accent"
                      flex
                      mat-raised-button>
                {{ 'select_new_image'|translate|ucFirst }}
              </button>
          </div>
        </div>
      </form>
    </mat-card-content>

    <mat-divider></mat-divider>
    <mat-card-actions>
      <button (click)="dialogRef.close()" *ngIf="dialogData" class="push-right-small" color="accent" mat-button>
        {{ 'cancel'|translate|ucFirst }}
      </button>

      <button (click)="save()" [disabled]="formDisabled()"
              color="accent"
              flex
              mat-raised-button>
        {{ 'save'|translate|ucFirst }}
      </button>

      <button (click)="delete()" *ngIf="productId && !dialogData"
              [disabled]="!productId"
              class="float-right"
              color="warn"
              flex
              mat-raised-button>
        {{ 'delete'|translate|ucFirst }}
      </button>
    </mat-card-actions>
  </ng-template>
</mat-card>
