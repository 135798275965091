import {Injectable} from '@angular/core';
import {AuthService} from '../auth.service';
import {HttpClient} from '@angular/common/http';
import {InvoiceSettings} from '../../models/pas/invoice-settings';
import {environment} from '../../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';

@Injectable()
export class SettingsService {

  constructor(
    public _http: HttpClient,
    private _vault: CoolLocalStorage,
    public _authService: AuthService,
  ) {
  }

  /**
   * Get a driver's invoicing details
   * @param {string} driverId
   * @returns {Observable<InvoiceSettings>}
   */
  getDriverDetails(driverId: string) {
    // Properly encode the filter
    const filter = encodeURIComponent(`{"where":{"driverId":"${driverId}"}}`);
    // Run the call
    return this._http.get<InvoiceSettings>(
      `${environment.pasBaseUrl}/DriverDetails/findOne?filter=${filter}`,
      {headers: this._authService.getJWTHeaders()}
    );
  }

  /**
   * Update a driver's invoicing details
   * @param {string} driverId
   * @param {InvoiceSettings} settings
   * @returns {Observable<InvoiceSettings>}
   */
  updateDriverDetails(driverId: string, settings: InvoiceSettings) {
    // Properly encode the filter
    const filter = encodeURIComponent(`{"driverId":"${driverId}"}`);
    // Set the driverId property for this call
    settings.driverId = driverId;
    // Delete the `id` property
    delete settings.id;
    // Run the call
    return this._http.post<InvoiceSettings>(
      `${environment.pasBaseUrl}/DriverDetails/upsertWithWhere?where=${filter}`,
      settings,
      {headers: this._authService.getJWTHeaders()}
    );
  };

  /**
   * Get a company's invoicing details
   * @param {string} companyId
   * @returns {Observable<InvoiceSettings>}
   */
  getCompanyDetails(companyId: string) {
    // Properly encode the filter
    const filter = encodeURIComponent(`{"where":{"companyId":"${companyId}"}}`);
    // Run the call
    return this._http.get<InvoiceSettings>(
      `${environment.pasBaseUrl}/CompanyDetails/findOne?filter=${filter}`,
      {headers: this._authService.getJWTHeaders()}
    );
  }

  /**
   * Update a company's invoicing details
   * @param {string} companyId
   * @param {InvoiceSettings} settings
   * @returns {Observable<InvoiceSettings>}
   */
  updateCompanyDetails(companyId: string, settings: InvoiceSettings) {
    // Properly encode the filter
    const filter = encodeURIComponent(`{"companyId":"${companyId}"}`);
    // Set the companyId property for this call
    settings.companyId = companyId;
    // Delete the `id` property
    delete settings.id;
    // Run the call
    return this._http.post<InvoiceSettings>(
      `${environment.pasBaseUrl}/CompanyDetails/upsertWithWhere?where=${filter}`,
      settings,
      {headers: this._authService.getJWTHeaders()}
    );
  };
}
