<app-no-contract *ngIf="(this.context==='company' && (!this.company.ActiveContract ||
!['active','past_due', null].includes(this.company.ActiveContract.foreignStatus)))"
                 [companyId]="this.companyId" [contract]="this.company.ActiveContract"></app-no-contract>
<mat-card tdMediaToggle="gt-xs">
  <td-search-box (searchDebounce)="search($event)" [alwaysVisible]="true" class="push-left-sm"
                 placeholder="{{'search_here'|translate|ucFirst}}"></td-search-box>
  <mat-divider></mat-divider>
  <ng-template tdLoading="coupons.list">
    <mat-tab-group (selectedTabChange)="onTabChange($event)"
                   [selectedIndex]="selectedTabIndex" animationDuration="200ms">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="icon-right">group</mat-icon>
          {{('coupons')|translate|ucFirst}}
          ({{filteredTotals['default']}})
        </ng-template>
        <table class="mat-clickable" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="sort($event)"
                *ngFor="let c of columns"
                [active]="(sortBy == c.name)"
                [name]="c.name"
                [numeric]="c.numeric"
                [sortOrder]="(sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
                [sortable]="c.sortable"
                td-data-table-column>
              {{c.label|translate|ucFirst}}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr (click)="openDetail($event, item.id)" *ngFor="let item of this.filteredData['default']" class="pointer"
              td-data-table-row>
            <td *ngFor="let c of columns" [numeric]="item.numeric" td-data-table-cell>
                <span [ngSwitch]="c.name">
                  <span *ngSwitchCase="'discount'">
                    <span *ngIf="item.discountType ==='balance' && item.balanceLimitByCustomer">-
                      {{({total: (item.balanceLimitByCustomer), currency: currency}) | localizedCurrency}}</span>                    <span *ngIf="item.discountType ==='percentage'">-{{item.discountPercentage}}%</span>
                    <span *ngIf="item.discountType ==='fixed' && item.discountFixedAmount">- {{({total: (item.discountFixedAmount), currency: currency}) | localizedCurrency}}</span>
                  </span>
                  <span *ngSwitchCase="'created'"><span
                    *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>
                  <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span></span>

                  <span *ngSwitchCase="'tools'">
                    <button [matMenuTriggerFor]="menu" mat-icon-button>
                      <mat-icon>more_vert</mat-icon>
                    </button>

                    <mat-menu #menu="matMenu">
                      <button (click)="openToolsEdit($event, item.id)" mat-menu-item>
                        <mat-icon>edit</mat-icon>
                        <span>{{'edit'|translate|ucFirst}}</span>
                      </button>

                      <button (click)="deleteCoupon($event, item.id, item.name)" mat-menu-item>
                        <mat-icon>delete</mat-icon>
                        <span>{{'delete'|translate|ucFirst}}</span>
                      </button>
                    </mat-menu>
                  </span>
                </span>
            </td>
          </tr>
          </tbody>
        </table>
        <td-paging-bar #paging (change)="page($event)" [pageSize]="100" [total]="filteredTotals['default']">
          <span hide-xs>{{'rows_p_page'|translate}}:</span>
          {{paging.range}} <span hide-xs>of {{paging.total}}</span>
        </td-paging-bar>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="icon-right">person</mat-icon>
          {{('coupons_referer_tab')|translate|ucFirst}}
          ({{filteredTotals['referer']}})
        </ng-template>
        <table class="mat-clickable" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="sort($event)"
                *ngFor="let c of columns"
                [active]="(sortBy == c.name)"
                [name]="c.name"
                [numeric]="c.numeric"
                [sortOrder]="(sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
                [sortable]="c.sortable"
                td-data-table-column>
              {{c.label|translate|ucFirst}}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr (click)="openDetail($event, item.id)" *ngFor="let item of this.filteredData['referer']" class="pointer"
              td-data-table-row>
            <td *ngFor="let c of columns" [numeric]="item.numeric" td-data-table-cell>
          <span [ngSwitch]="c.name">
            <span *ngSwitchCase="'discount'">
              <span *ngIf="item.discountType ==='percentage'">-{{item.discountPercentage}}%</span>
                <span *ngIf="item.discountType ==='fixed' && item.discountFixedAmount">-
                  {{({total: (item.discountFixedAmount), currency: currency}) | localizedCurrency}}</span>
            </span>
            <span *ngSwitchCase="'created'"><span
              *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>
            <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span></span>

            <span *ngSwitchCase="'tools'">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #menu="matMenu">
                <button (click)="openToolsEdit($event, item.id)" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>{{'edit'|translate|ucFirst}}</span>
                </button>

                <button (click)="deleteCoupon($event, item.id, item.name)" mat-menu-item>
                  <mat-icon>delete</mat-icon>
                  <span>{{'delete'|translate|ucFirst}}</span>
                </button>
              </mat-menu>
            </span>
          </span>
            </td>
          </tr>
          </tbody>
        </table>
        <td-paging-bar #paging (change)="page($event)" [pageSize]="100" [total]="filteredTotals['referer']">
          <span hide-xs>{{'rows_p_page'|translate}}:</span>
          {{paging.range}} <span hide-xs>of {{paging.total}}</span>
        </td-paging-bar>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</mat-card>
