import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PricingRuleDialogComponent} from '../../../../../../company/settings/apps/parts/apps-upsert/parts/pricing-rule-dialog/pricing-rule-dialog.component';

@Component({
  selector: 'app-message-log-email-dialog',
  templateUrl: './message-log-email-dialog.component.html',
  styleUrls: ['./message-log-email-dialog.component.scss']
})
export class MessageLogEmailDialogComponent implements OnInit {
  html: any;
  constructor(
    public dialogRef: MatDialogRef<PricingRuleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.html = data.html;
  }

  ngOnInit(): void {
  }

}
