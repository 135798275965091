<mat-card [mediaClasses]="['push-sm']" ngClass="ride-filter" tdMediaToggle="gt-xs">
  <td-expansion-panel [disableRipple]="false" disabled="false" expand="true" label="" ngClass="push-bottom-sm"
                      sublabel="">
    <ng-template td-expansion-panel-label>
      {{'filter_export_jobs'|translate|ucFirst}}
    </ng-template>
    <td-expansion-summary>
      <!--<mat-divider></mat-divider>-->
      <!--<div layout-gt-sm="row" tdMediaToggle="gt-xs" [mediaClasses]="[]">-->
      <!--<div flex-gt-sm="100">-->
      <!--<td-search-box class="push-left-sm" placeholder="{{'search_here'|translate}}" [alwaysVisible]="true" (searchDebounce)="searchTerm($event)"></td-search-box>-->
      <!--</div>-->
      <!--</div>-->

      <mat-divider></mat-divider>

      <div [mediaClasses]="[]" layout-gt-sm="row" tdMediaToggle="gt-xs">
        <div flex-gt-sm="100">
          <div class="date-range-picker" layout-gt-sm="row">
            <div flex-gt-sm="50">
              <p class="label">{{'from'|translate|ucFirst}}:</p>
              <mat-form-field class="push-left-sm" floatPlaceholder="never">
                <input [matDatepicker]="fromPicker" [value]="data.filterDates.from" disabled matInput
                       placeholder="{{'choose_a_date'|translate}}">
                <mat-datepicker-toggle [for]="fromPicker" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #fromPicker (change)="onFromFilterChanged($event)" disabled="false"></mat-datepicker>
              </mat-form-field>
            </div>
            <div flex-gt-sm="50">
              <p class="label">{{'to'|translate|ucFirst}}:</p>
              <mat-form-field class="push-left-sm" floatPlaceholder="never">
                <input [matDatepicker]="toPicker" [value]="data.filterDates.to" disabled matInput
                       placeholder="{{'choose_a_date'|translate}}">
                <mat-datepicker-toggle [for]="toPicker" matSuffix></mat-datepicker-toggle>
                <mat-datepicker #toPicker (change)="onToFilterChanged($event)" disabled="false"></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div *ngIf="rideOrigins" [mediaClasses]="[]" layout-gt-sm="row" tdMediaToggle="gt-xs">
        <div flex-gt-sm="100">
          <label class="label-select">
            {{'select_ride_origin'|translate|ucFirst}}:
            <mat-form-field>
              <mat-select (selectionChange)="onOriginChanged($event)" [value]="'all'">
                <mat-option value="all">{{'all_origins'|translate|ucFirst}}</mat-option>
                <mat-option *ngFor="let origin of rideOrigins;" [value]="origin.originId">
                  {{origin.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </label>
        </div>
      </div>

      <mat-divider></mat-divider>

      <div class="tools pad-sm">
        <button (click)="doExport()" color="primary"
                mat-raised-button>{{'export'|translate|ucFirst}} {{'rides'|translate}}</button>
      </div>

    </td-expansion-summary>
  </td-expansion-panel>
</mat-card>
