<div layout="column" layout-fill>
  <mat-toolbar class="mat-whiteframe-z1" color="primary">
    <a class="top-logo" routerLink="/login">
      <mat-icon class="mat-icon-logo" svgIcon="assets:logo"></mat-icon>
    </a>
    <span> | {{'set_password'|translate|ucFirst}}</span>
  </mat-toolbar>
  <div class="mat-content" flex layout-padding>
    <app-reset-password *ngIf="validated" [verifyToken]="this.token"></app-reset-password>
  </div>
</div>
