<img
  alt="" class="plan-img"
  src="https://www.yourdriverapp.com/wp-content/uploads/2020/11/cloudconnect.svg">
<mat-card-title>{{'no_contract_title'|translate|ucFirst}}</mat-card-title>
<mat-card-subtitle>{{'no_contract_subtitle'|translate|ucFirst}}</mat-card-subtitle>

<mat-divider></mat-divider>

<mat-card-content class="cloudconnecttext">
  <div [innerHTML]="this.noAdminText"></div>
</mat-card-content>
