import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from './utility.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {Passenger} from '../models/passenger';
import {GlobalService} from './global.service';
import {CompanyWebUserService} from './companyWebUser.service';

@Injectable()
export class PassengerService extends GlobalService {
  constructor(_http: HttpClient, _vault: CoolLocalStorage, _utilityService: UtilityService, private _companyWebUserService: CompanyWebUserService) {
    super(_http, _vault, _utilityService);
    this.setModel('passengers');
  }

  activate(id: string, passenger: Passenger, context?: string): Observable<any> {
    return this._http.put(`${environment.apiBaseUrl}/passengers/${id}`, passenger, {headers: this.getHeaders(context)});
  }

  updateDebtorPassenger(id: string, object: any, context?: string): Observable<any> {
    return this._http.patch<any>(`${this.base}/debtorpassengers/${id}`, object, {headers: this.getHeaders(context)});
  }

  getPassengersByDebtor(debtorId: string, filter?: Object, context?: string): Observable<any> {
    return this._http
      .get<any>(`${environment.apiBaseUrl}/debtorpassengers/?filter=${JSON.stringify(filter || {})}`, {headers: this.getHeaders(context)});
  }

  debtorInsert(debtorLink: any, context?: string): Observable<Passenger> {
    return this._http.post<Passenger>(`${environment.apiBaseUrl}/debtorpassengers/`, debtorLink, {headers: this.getHeaders(context)})
  }

  debtorDelete(id: any, context?: string): Observable<Passenger> {
    return this._http.delete<Passenger>(`${environment.apiBaseUrl}/debtorpassengers/${id}/`, {headers: this.getHeaders(context)})
  }

  addPaymentMethod(id: any, card, context?: string): Observable<any> {
    return this._http.post<Passenger>(`${environment.apiBaseUrl}/passengers/${id}/addPaymentMethod`, card, {headers: this.getHeaders(context)})
  }

  paymentMethods(id: any, context?: string): Observable<any> {
    return this._http.get<Passenger>(`${environment.apiBaseUrl}/passengers/${id}/paymentMethods`, {headers: this.getHeaders(context)})
  }

  deletePaymentMethod(passengerId: any, id: any, type: string, context?: string): Observable<any> {
    return this._http.delete<Passenger>(`${environment.apiBaseUrl}/passengers/${passengerId}/paymentMethods/${type}/${id}`, {headers: this.getHeaders(context)})
  }

  sendContactEmail(data: any, context?: string) {
    return this._http.post<Passenger>(`${environment.apiBaseUrl}/passengers/sendContactEmailRemote`, data, {headers: this.getHeaders(context)});
  }

  resetPaswordEmail(data: any, context?: string) {
    return this._http.post<Passenger>(`${environment.apiBaseUrl}/passengers/requestReset`, {headers: this.getHeaders(context)})
  }

  delete(id: string, context?: string): Observable<any> {
    return this._http.delete(`${this.base}/${this.model}/${id}`, {headers: this.getHeaders(context)});
  }

}
