import {Injectable} from '@angular/core';
import {GlobalService} from './global.service';
import {HttpClient} from '@angular/common/http';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from './utility.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs/Observable';
import {Product} from '../models/product';

@Injectable({
  providedIn: 'root'
})
export class PriceService extends GlobalService {
  constructor(_http: HttpClient, _vault: CoolLocalStorage, _utilityService: UtilityService) {
    super(_http, _vault, _utilityService);
    this.setModel('prices');
  }

  calculatePrice(ride: any, context?: string): Observable<Product[]> {
    return this._http.post<Product[]>(`${environment.apiBaseUrl}/prices/quotation/`, ride, {headers: this.getHeaders(context)});
  }
}
