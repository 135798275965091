<img
  alt="" class="plan-img"
  src="https://www.yourdriverapp.com/wp-content/uploads/2020/11/cloudconnect.svg">
<mat-card-title>{{'cloud_connect_title'|translate|ucFirst}}</mat-card-title>
<mat-card-subtitle>{{'cloud_connect_subtitle'|translate|ucFirst}}</mat-card-subtitle>

<mat-divider></mat-divider>

<div *ngIf="this.enterKey" class="push-bottom full-width" flex-gt-sm="100">
  <div class="full-width">
    <button (click)="convertKey()" class="convert-button push-bottom" color="primary" flex mat-raised-button>
      {{'cloud_connect_convert_key_button'|translate|ucFirst}}</button>

    <mat-form-field class="licenseKeyInput value">
      <input [(ngModel)]="this.licenseKey" [value]=""
             autocomplete="off"
             class="licensekey-input"
             flex="100"
             matInput
             placeholder="{{ 'license_key' | translate | ucFirst }}"
             type="text">
    </mat-form-field>
  </div>
</div>

<ng-template tdLoading="ordernow_loader">
  <div *ngIf="!this.enterKey" class="cloudconnect-action-pane">
    <mat-card-actions class="pull-bottom">
      <button (click)="startFreeTrial()" *ngIf="!this.company.hadTrial && this.trialAllowed" class="push-left push-top"
              color="primary"
              flex
              mat-raised-button>{{'start_free_trial'|translate|ucFirst}}</button>

      <a (click)="this.enterKey=true;" *ngIf="!this.enterKey" class="yda-link order-cloud push-left push-top" color="primary" flex
         href="javascript:return false;">
        {{'cloud_connect_convert_key_text'|translate|ucFirst}}</a>
    </mat-card-actions>
  </div>
</ng-template>

<mat-divider *ngIf="!this.company.hadTrial && this.trialAllowed" class="push-top"></mat-divider>

<mat-card-content class="cloudconnecttext">
  <app-license-overview></app-license-overview>
</mat-card-content>
