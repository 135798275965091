import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Ride} from '../../../../../models/ride';
import {PaymentOverviewComponent} from '../../../../company/administration/payment-overview/payment-overview.component';
import {GpsLogEntry} from '../../../../../models/gps-log-entry';
import {RideService} from '../../../../../services/ride.service';
import {Shift} from '../../../../../models/shift';
import {Rating} from '../../../../../models/rating';
import {ActivatedRoute} from '@angular/router';
import {TdMediaService} from '@covalent/core/media';
import moment from 'moment-timezone';

@Component({
  selector: 'app-ride-details-dialog',
  templateUrl: './ride-details-dialog.component.html',
  styleUrls: ['./ride-details-dialog.component.scss']
})
export class RideDetailsDialogComponent implements OnInit {
  parent: any;
  rideId: string;
  company: any;
  ride: Ride = new Ride();
  vehicleProduct: any;
  shift: Shift = new Shift();
  gpsLogEntries: GpsLogEntry[] = [];
  passengerRating: Rating = new Rating();
  operatorRating: Rating = new Rating();
  driverId: string;
  isOwner = true;
  isDriver = false;

  hasPassenger = false;
  hasPassengerRating = false;
  hasOperatorRating = false;
  translations: string[] = [];

  constructor(
    public media: TdMediaService,
    private _rideService: RideService,
    private activatedRoute: ActivatedRoute,
    public dialogRef: MatDialogRef<RideDetailsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    if (dialogData && dialogData.parent) {
      this.parent = dialogData.parent;
      this.parent.dialog = this.dialogRef;
    }

    this.company = this.parent.company;

    this.rideId = dialogData.rideId;
    this.loadData();
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
  }

  loadData(): void {
    const self = this;
    const query = {
      'include': [
        'Vehicle',
        'Driver',
        'Ratings',
        'Passenger',
        'LastJobOffer',
        'Offer',
        'Dispatcher',
        'Payments',
        'Debtor',
        'Coupon',
        'Owner',
        'MessageLog',
        {
          'relation': 'RideForward',
          'scope': {
            'include': ['Owner']
          }
        },
        'DriverTariff',
        {
          'relation': 'PaymentBalance',
          'scope': {
            'where': {
              'rideId': this.rideId
            },
            'order': 'created ASC'
          }
        },
        {
          'Shift': [
            {
              'relation': 'ShiftEntries',
              'scope': {
                'where': {
                  'rideId': this.rideId
                }
              }
            },
            {
              'relation': 'GpsLogEntries',
              'scope': {
                'where': {
                  'rideId': this.rideId,
                  'created': {'gt': 0}
                },
                'order': 'created ASC' ,
                'limit': 3600
              }
            }
          ]
        }
      ]
    };

    this._rideService.get(this.rideId, query).subscribe((ride: Ride) => {
        this.shift = {...ride.Shift};
        this.gpsLogEntries = (typeof ride.Shift !== 'undefined' ? {...ride.Shift.GpsLogEntries} : []);
        delete ride.Shift;
        ride.intId = (ride.foreignDispatchId ? ride.foreignDispatchId : (ride.foreignBookingId ? ride.foreignBookingId : ride.intId));
        this.ride = ride;

        this.vehicleProduct = (this.ride.products.filter((p) => {
          return (p.category === 'vehicleType');
        })[0]);

        if (ride.passenger) {
          this.hasPassenger = true;
        }
        this.isDriver = (this.ride.driverId === this.driverId);
        this.ride.Ratings.forEach(r => {
          if (r.type === 'operator') {
            this.operatorRating = r;
            this.hasOperatorRating = true;
          }
        });


        if (this.ride.paymentMeta && this.ride.paymentMeta.origin === 'creditcardOnline') {
          if (this.ride.Payments.length > 0) {
            this.ride.payment = this.ride.Payments[this.ride.Payments.length - 1];
          } else {
            this.ride.payment = null;
          }
          const details = PaymentOverviewComponent.showPaymentStatus(this.translations, this.company, this.ride.payment, this.ride);

          if (!this.ride.payment) {
            this.ride.payment = {};
          }
          this.ride.payment.paymentStatus = details.paymentStatus;
          this.ride.payment.paymentStatusColor = details.paymentStatusColor;
        }

        if (this.ride && this.ride.cancellation && this.ride.cancellation.cancellationChargeAmount) {
          this.ride.cancellation.cancellationChargeAmount = {
            total: this.ride.cancellation.cancellationChargeAmount,
            currency: this.company.currency
          };
        }


        if (!this.gpsLogEntries.length && this.shift.ShiftEntries && this.shift.ShiftEntries.length > 0) {
          const gpsQuery = {
            'where': {
              'created': {
                'between': [
                  this.shift.ShiftEntries[0].startTime,
                  this.shift.ShiftEntries[this.shift.ShiftEntries.length - 1].endTime
                ]
              }
            }
          };
        }
      },
      error => {
        console.error(error);
      })
  }

  protected readonly moment = moment;
}
