<!-- Required to make google places search work -->
<mat-card [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <ng-template tdLoading="location">

    <mat-card-title *ngIf=form class="text-truncate">
      {{ form.value.name | ucFirst }}
    </mat-card-title>
    <mat-card-subtitle>
      {{ 'predefined_subtitle' | translate | ucFirst }}
    </mat-card-subtitle>

    <mat-divider></mat-divider>

    <form *ngIf="form" [formGroup]="form">
      <mat-card-content class="push-bottom-none">
        <!-- Descriptor -->
        <mat-form-field *ngIf="hasShape()" class="push-right" flex>
          <input formControlName="descriptor"
                 matInput
                 placeholder="{{ 'type' | translate | ucFirst }}"
                 readonly="readonly"
                 style="text-transform:capitalize; color:gray"
                 type="text"
                 value="{{ form.value.descriptor | translate }}">
        </mat-form-field>

        <!-- Name -->
        <mat-form-field *ngIf="hasShape()" class="push-right" flex>
          <input formControlName="name"
                 matInput
                 placeholder="{{ 'name' | translate }}"
                 readonly="readonly"
                 style="text-transform:capitalize; color:gray"
                 type="text">
        </mat-form-field>

        <br>

        <!-- Address -->
        <mat-form-field *ngIf="hasShape() && isPoint()" flex
                        style="width: 100%">
          <input formControlName="address"
                 matInput
                 placeholder="{{ 'address' | translate | ucFirst }}"
                 readonly="readonly"
                 style="color: gray"
                 type="text">
        </mat-form-field>
      </mat-card-content>

      <!-- Map -->
      <mat-card-content class="map-container">
        <app-map
          (mapReady)="onMapReady($event)"
          (nrShapes)="onNrShapes($event)"
          [allowDrawingMode]="false"
          [allowEditMode]="false"
          [location]="form"
          [style.height]="'55vh'">
        </app-map>
      </mat-card-content>
    </form>

    <!-- Actions -->
    <mat-card-actions>
      <button (click)="clone()" color="accent"
              flex
              mat-raised-button>
        {{ 'import' | translate | ucFirst }}
      </button>
    </mat-card-actions>
  </ng-template>
</mat-card>
