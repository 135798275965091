<mat-card tdMediaToggle="gt-xs">
  <mat-card-title>{{'my_ratings'|translate}}</mat-card-title>
  <mat-card-subtitle>{{'my_ratings_subtitle'|translate}}</mat-card-subtitle>
  <mat-divider></mat-divider>
  <td-search-box (searchDebounce)="search($event)" [alwaysVisible]="true" class="push-left-sm"
                 placeholder="{{'search_here'|translate}}"></td-search-box>
  <mat-divider></mat-divider>
  <ng-template tdLoading="ratings.list">
    <mat-tab-group (selectedTabChange)="onTabChange($event)" selectedIndex="{{activeTab}}">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>call_received</mat-icon>
          {{'list_as_driver'|translate|ucFirst}}
        </ng-template>
        <table class="mat-clickable" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="driverSort($event)"
                *ngFor="let c of driverColumns"
                [active]="(driverSortBy == c.name)"
                [name]="c.name"
                [numeric]="c.numeric"
                [sortOrder]="(driverSortOrder == 'ASC' ? 'DESC' : 'ASC' )"
                [sortable]="c.sortable"
                td-data-table-column>
              {{c.label|translate|ucFirst}}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr (click)="openRide(item.rideId)" *ngFor="let item of driverFilteredData" td-data-table-row>
            <td *ngFor="let c of driverColumns" [numeric]="item.numeric" td-data-table-cell>
              <span [ngSwitch]="c.name">
                <span *ngSwitchCase="'score'">
                  <mat-icon *ngIf="item.type === 'passenger'">perm_identity</mat-icon>
                  <mat-icon *ngIf="item.type === 'operator'">headset_mic</mat-icon>
                  &nbsp;&nbsp;
                  <span *ngFor="let score of [1,2,3,4,5]; let i = index;">
                    <mat-icon *ngIf="score <= item[c.name]">star</mat-icon>
                    <mat-icon *ngIf="score > item[c.name]">star_border</mat-icon>
                  </span>
                </span>
                <span *ngSwitchCase="'comment'"><a class="comment-line"
                                                   title="{{item[c.name]}}">{{item.commentShort}}{{(item.comment.length > commentMaxLength ? '...' : '')}}</a></span>
                <span *ngSwitchCase="'created'"><span
                  *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>
                <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span></span>
              </span>
            </td>
          </tr>
          </tbody>
        </table>
        <td-paging-bar #driverPagingBar (change)="driverPage($event)" [pageSize]="100" [total]="driverFilteredTotal">
          <span hide-xs td-paging-bar-label>{{'rows_p_page'|translate}}:</span>
          {{driverPagingBar.range}} <span hide-xs>of {{driverPagingBar.total}}</span>
        </td-paging-bar>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>call_made</mat-icon>
          {{'list_as_owner'|translate|ucFirst}}
        </ng-template>
        <table class="mat-clickable" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="operatorSort($event)"
                *ngFor="let c of operatorColumns"
                [active]="(operatorSortBy == c.name)"
                [name]="c.name"
                [numeric]="c.numeric"
                [sortOrder]="(operatorSortOrder == 'ASC' ? 'DESC' : 'ASC')"
                [sortable]="c.sortable"
                td-data-table-column>
              {{c.label|translate|ucFirst}}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr (click)="openRide(item.rideId)" *ngFor="let item of operatorFilteredData" td-data-table-row>
            <td *ngFor="let c of operatorColumns" [numeric]="item.numeric" td-data-table-cell>
              <span [ngSwitch]="c.name">
                <span *ngSwitchCase="'score'">
                  <mat-icon *ngIf="item.type === 'passenger'">local_taxi</mat-icon>
                  <mat-icon *ngIf="item.type === 'operator'">headset_mic</mat-icon>
                  &nbsp;&nbsp;
                  <span *ngFor="let score of [1,2,3,4,5]; let i = index;">
                    <mat-icon *ngIf="score <= item[c.name]">star</mat-icon>
                    <mat-icon *ngIf="score > item[c.name]">star_border</mat-icon>
                  </span>
                </span>
                <span *ngSwitchCase="'created'"><span
                  *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>
                <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span></span>
              </span>
            </td>
          </tr>
          </tbody>
        </table>
        <td-paging-bar #operatorPagingBar (change)="operatorPage($event)" [pageSize]="100"
                       [total]="operatorFilteredTotal">
          <span hide-xs td-paging-bar-label>{{'rows_p_page'|translate}}:</span>
          {{operatorPagingBar.range}} <span hide-xs>of {{operatorPagingBar.total}}</span>
        </td-paging-bar>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</mat-card>
