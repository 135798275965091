import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {RideService} from '../../../services/ride.service';
import {Ride} from '../../../models/ride';
import {TdMediaService} from '@covalent/core/media';
import {CoolLocalStorage} from '@angular-cool/storage';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {Rating} from '../../../models/rating';
import {RatingService} from '../../../services/rating.service';
import {NavigationService} from '../../../services/navigation.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [RideService, RatingService]
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {

  driver: any;
  companyId: string;
  rides: Ride[] = [];
  ratings: Rating[] = [];
  rideStatusColors = {
    domain: ['#00C853', '#DD2C00']
  };
  sub: Subscription;

  constructor(public media: TdMediaService,
              private _vault: CoolLocalStorage,
              private _translateService: TranslateService,
              private _navigationService: NavigationService,
              private _rideService: RideService,
              private _ratingService: RatingService,
              private _route: ActivatedRoute,
              private _router: Router,
              private _titleService: Title) {
    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    _translateService.get('dashboard').subscribe((translation: string) => {
      this._titleService.setTitle(translation + environment.windowTitleSuffix);
    });
    _translateService.get('dashboard').subscribe((translation: string) => {
      this._navigationService.setBodyTitle(translation);
    });
  }

  ngOnInit() {
    this.sub = this._route.parent.params.subscribe(params => {
      this.companyId = params['id'];
      // this.loadRatings();
    });
    this.driver = this._vault.getObject(`${environment.vaultPrefix}.driver`);
  }

  ngAfterViewInit() {
    this.media.broadcast();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }


  loadRatings() {
    const filter = {
      'limit': 10,
      'companyId': this.companyId
    };

    this._ratingService.getAll(filter, 'company').subscribe((ratings: Rating[]) => {
      this.ratings = ratings;
      // this._loadingService.resolve('ratings');
    }, (error) => {
      console.error(error);
      // this._loadingService.resolve('ratings');
    })
  }

}
