<ng-container *tdLoading="loaderName">
  <mat-card tdMediaToggle="gt-xs">
    <mat-card-title [className]="'mat-card-title filter'">
      {{'contact'|translate|ucFirst}}<span *ngIf="contact">: {{contact.fname || '-'}} {{contact.lname || '-'}}</span>
      <button (click)="editcontact()" [className]="'mat-raised-button mat-primary tool'" color="primary"
              mat-raised-button>
        {{'edit_contact'|translate|ucFirst}}
      </button>
    </mat-card-title>
    <mat-card-subtitle>{{'contact_details_subtitle'|translate}}</mat-card-subtitle>
    <mat-divider></mat-divider>
    <div *ngIf="contact" class="inset" layout="column" layout-gt-sm="row">
      <div flex-gt-sm="50">
        <div layout="column">
          <mat-list>
            <mat-list-item class="pull-top">
              <mat-icon matListAvatar>person</mat-icon>
              <h4 matLine>{{contact.fname || '-'}} {{contact.lname || '-'}}</h4>
              <p matLine>{{'name'|translate|ucFirst}}</p>
            </mat-list-item>
            <mat-list-item>
              <mat-icon matListAvatar>email</mat-icon>
              <h4 matLine>{{contact.email || '-'}}</h4>
              <p matLine>{{'email'|translate|ucFirst}}</p>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
      <div flex-gt-sm="50">
        <div layout="column">
          <mat-list>
            <mat-list-item class="pull-top">
              <mat-icon matListAvatar>smartphone</mat-icon>
              <h4 matLine>{{contact.phoneNumber || '-'}}</h4>
              <p matLine>{{'phone_number'|translate}}</p>
            </mat-list-item>
          </mat-list>

          <mat-list>
            <mat-list-item class="pull-top">
              <mat-icon matListAvatar>access_time</mat-icon>
              <h4 matLine>
                {{(!contact.lastLogin ? ('invited' | ucFirst) : contact.lastLogin | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm'))}}
                -
                <a (click)="this.sendContactEmail(!!(contact.lastLogin))" class="push-top push-right yda-link-blue">
                  {{(!contact.lastLogin ? 'resend_invite_email' : 'send_password_reset')|translate|ucFirst}}</a>
              </h4>
              <p matLine>
                {{'last_login'|translate}}
              </p>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </div>
    <mat-divider></mat-divider>
    <div *ngIf="passengerPayment && passengerPayment.paymentMethods">
      <mat-card-title [className]="'mat-card-title filter small-title'" flex>
        {{'available_paymentmethods'|translate|ucFirst}}
        <button (click)="this.addPaymentMethod()" class="push-top push-right new-method" color="primary" flex
                mat-raised-button>
          {{'add_new_payment_method'|translate|ucFirst}}</button>
      </mat-card-title>
      <mat-card-content
        *ngIf="passengerPayment.paymentMethods.length === 0
        " class="push-bottom">{{'no_passenger_payment_methods_found'|translate}}</mat-card-content>
      <mat-card-content *ngIf="passengerPayment.paymentMethods.length > 0" class="pull-top" layout="row">
        <table>
          <tbody>
          <tr *ngFor="let paymentMethod of passengerPayment.paymentMethods">
            <td *ngIf="paymentMethod.type === 'invoice'">
              <mat-icon>assignment</mat-icon>
              <span class="on-account-position">{{'on_account_of'|translate}}: {{paymentMethod.name}}</span>
            </td>
            <td *ngIf="paymentMethod.type === 'card'">
              <mat-icon *ngIf="(paymentMethod.card && !['visa','mastercard'].includes(paymentMethod.card.brand))">
                credit_card
              </mat-icon>

              <img *ngIf="(paymentMethod.card && paymentMethod.card.brand === 'visa')" src="assets/yda/visa.svg">

              <img *ngIf="(paymentMethod.card && paymentMethod.card.brand === 'mastercard')"
                   src="assets/yda/mastercard.svg">

              <span class="star_position">**** **** ****</span>
              <span class="position">{{(paymentMethod.card ?
                paymentMethod.card.last4 : '')}} {{'expires' | translate}} {{(paymentMethod.card ?
                paymentMethod.card.exp_month + '/' +
                ('' + paymentMethod.card.exp_year + '') : '')}}</span>
            </td>
            <td>{{(paymentMethod.default ? 'default_payment_method' : '') | translate}}</td>
            <td class="actions payment-method-column">
              <mat-icon (click)="this.deletePaymentMethod(paymentMethod)"
                        class="delete_icon"
                        matTooltip="{{'delete_payment_method'|translate|ucFirst}}">delete_outline
              </mat-icon>
            </td>
          </tr>
          </tbody>
        </table>
      </mat-card-content>
    </div>
  </mat-card>
</ng-container>
<mat-card tdMediaToggle="gt-xs">
  <mat-card-title [className]="'mat-card-title filter'">
    {{'invoices'|translate|ucFirst}}
  </mat-card-title>
  <app-open-invoices #contactInvoices *ngIf="contact"
                     [companyId]="companyId"
                     [contactId]="contact.id"
                     [context]="'company'"
                     [invoiceView]="'Contact'"
                     [type]="'history'">
  </app-open-invoices>
</mat-card>
