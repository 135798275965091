import {Component} from '@angular/core';
import {Field} from '../../models/field.interface';
import {FieldConfig} from '../../models/field-config.interface';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-material-select',
  templateUrl: './material-select.component.html',
  styleUrls: ['./material-select.component.scss']
})
export class MaterialSelectComponent implements Field {
  config: FieldConfig;
  group: UntypedFormGroup;
}

