import {Component, EventEmitter, Inject, Input, OnInit, Output, Renderer2} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {UntypedFormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {User} from '../../../../../models/user';
import {CoolLocalStorage} from '@angular-cool/storage';
import {DOCUMENT} from '@angular/common';
import {GlobalFunctions} from '../../../../../functions/functions';
import {Company} from '../../../../../models/company';
import {CompanyService} from '../../../../../services/company.service';
import {TdDialogService} from '@covalent/core/dialogs';
import {ObCalendarSelectComponent} from './parts/ob-calendar-select/ob-calendar-select.component';
import {ucFirst} from '../../../../../pipes/uc-first.pipe';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TdLoadingService} from '@covalent/core/loading';

@Component({
  selector: 'app-ob-calendar',
  templateUrl: './ob-calendar.component.html',
  styleUrls: ['./ob-calendar.component.scss']
})
export class ObCalendarComponent implements OnInit {
  @Input() step: string;
  @Input() company: Company;
  @Input() form: UntypedFormGroup;
  @Output() nextStep = new EventEmitter<string>();
  googleConnected = false;
  localTranslations: string[] = [];
  translations: string[] = [];
  calendars: any[] = [];
  user: User;
  apiLoaded = true;
  primaryCalendar = '';

  constructor(
    private _translateService: TranslateService,
    private _companyService: CompanyService,
    private _vault: CoolLocalStorage,
    private _dialogService: TdDialogService,
    private _renderer2: Renderer2,
    private _snackBar: MatSnackBar,
    private _loadingService: TdLoadingService,
    public _globalFunctions: GlobalFunctions,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.user = this._vault.getObject(`${environment.vaultPrefix}.user`);

    _translateService.get(['settings_create_new_calendar', 'onboard_calendar_text_1', 'onboard_calendar_option_1', 'onboard_calendar_option_2', 'onboard_calendar_option_3', 'confirm_empty_calendar', 'yes', 'your_calendar', 'no', 'saved_calendar_details', 'confirm_skip_calendar'])
      .subscribe((translations: any) => {
        this.localTranslations = JSON.parse(JSON.stringify(translations));
        this.translations = translations;
      });
  }

  ngOnInit(): void {
    const self = this;
    if (!this.company) {
      setTimeout(function () {
        self.ngOnInit();
      }, 500);
    } else {
      if (self.company && self.company.settings && self.company.settings.calendar && self.company.settings.calendar.authorization && self.company.settings.calendar.authorization.access_token) {
        self.googleConnected = true;

        if (!self.company.settings.calendar.calendars || self.company.settings.calendar.calendars.length < 1) {
          self._companyService.synchronizeCalendar(self.company.id).subscribe((sync) => {
            self._companyService.get(self.company.id).subscribe((c) => {
              self.calendars = c.settings.calendar.calendars;

              if (self.calendars) {
                const primary = self.company.settings.calendar.calendars.filter((c) => {
                  return (c.id === self.company.settings.calendar.primary)
                })[0];
                if (primary) {
                  self.primaryCalendar = primary.summary;
                }
                if (!self.primaryCalendar) {
                  self.editCalendarSettings(true);
                }
                Object.keys(self.translations).forEach((t) => {
                  self.translations[t] = self.localTranslations[t].replace('{user_email}', self.primaryCalendar);
                });
              }
            });
          });
        } else {
          self.calendars = self.company.settings.calendar.calendars;
          if (self.calendars) {
            const primary = self.company.settings.calendar.calendars.filter((c) => {
              return (c.id === self.company.settings.calendar.primary)
            })[0];
            if (primary) {
              self.primaryCalendar = primary.summary;
            }

            if (!self.primaryCalendar) {
              self.editCalendarSettings(true);
            }

            Object.keys(self.translations).forEach((t) => {
              self.translations[t] = self.localTranslations[t].replace('{user_email}', self.primaryCalendar);
            });
          }
        }
      } else {
        Object.keys(this.translations).forEach((t) => {
          this.translations[t] = this.localTranslations[t].replace('"{user_email}"', this.localTranslations['your_calendar']);
        });

        self._globalFunctions.initGoogleSignup(self);
      }
    }
  }

  googleLogin(resp): void {
    this._loadingService.register();
    this.form.controls['googleAuthorization'].setValue(resp);
    this.saveCalendarSettings();
  }

  saveCalendarSettings(onlySave = false) {
    const self = this;
    const data = self.form.controls['googleAuthorization'].value;
    if (environment.location === 'localhost') {
      data.isLocalhost = true;
    }
    this.company.settings.calendar = {
      checkConflicts: self.form.controls['checkConflicts'].value,
      addEvents: self.form.controls['addEvents'].value,
      authorization: self.form.controls['googleAuthorization'].value,
      primary: self.form.controls['primary'].value,
      calendars: (this.company.settings.calendar ? this.company.settings.calendar.calendars : {})
    };

    self._companyService.update(self.company.id, this.company).subscribe(() => {
      self._snackBar.open(ucFirst(self.translations['saved_calendar_details']), self.translations['ok'], {
        duration: 3000
      });

      if (!onlySave) {
        self._companyService.synchronizeCalendar(self.company.id).subscribe((sync) => {
          self._companyService.get(self.company.id).subscribe((c) => {
            self.company = c;
            self.ngOnInit();
            self.editCalendarSettings();
            self.form.controls['googleAuthorization'].setValue(c.settings.calendar.authorization);
            self._loadingService.resolve();
          });
        });
      }
    }, error => {
    });
  }

  setupLater() {
    const self = this;
    self.nextStep.emit('done');
  }

  clearSkip(step) {
    this.form.controls['checkConflicts'].setValue(false);
    this.form.controls['addEvents'].setValue(false);
    this.setStep(step);
  }

  selectCalendar(id) {
    const self = this;
    self.form.controls['addEvents'].setValue(true);
    self.form.controls['primary'].setValue(id);
    self.primaryCalendar = id;
    const found = self.company.settings.calendar.calendars.filter((c) => {
      return (c.id === id)
    })[0];

    if (found) {
      self.primaryCalendar = found.summary;
    } else {
      self.primaryCalendar = self.localTranslations['settings_create_new_calendar'];
    }

    Object.keys(self.localTranslations).forEach((t) => {
      self.translations[t] = self.localTranslations[t].replace('{user_email}', self.primaryCalendar);
    });
  }

  editCalendarSettings(forceSelect = false) {
    this._dialogService.open(ObCalendarSelectComponent, {
      maxHeight: '80%',
      maxWidth: '500px',
      minWidth: '500px',
      minHeight: '250px',
      disableClose: forceSelect,
      data: {
        openFromDialog: true,
        parent: this,
        calendars: this.calendars,
        calendar: this.company.settings.calendar,
        forceSelect: forceSelect
      },
    });
  }

  setStep(step) {
    if (step === 'skipped') {
      this.nextStep.emit(step);
      return;
    }
    const self = this;
    if (!this.form.controls['addEvents'].value && !this.form.controls['checkConflicts'].value) {
      this._dialogService.openConfirm({
        message: this.translations['confirm_empty_calendar'],
        acceptButton: this.translations['return'],
        cancelButton: this.translations['continue'],
        disableClose: true,
      }).afterClosed()
        .subscribe(confirm => {
          if (confirm) {
            self.nextStep.emit(step);
          }
        });
    } else {
      if (step !== 'skipped') {
        self.saveCalendarSettings((self.form.controls['primary'].value !== 'new'));
      }
      self.nextStep.emit(step);
    }
  }
}
