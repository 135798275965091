import {GpsLogEntry} from './gps-log-entry';
import {Address} from './address';
import {ShiftEntry} from './shift-entry';

class DeviceDetails {
  appVersion?: string;
  deviceBrand?: string;
  deviceManufacturer?: string;
  deviceModel?: string;
  deviceOs?: string;
  osVersion?: string;
}

export class Shift {
  id: string;
  startTime?: Date;
  endTime?: Date;
  startLocation?: Address;
  endLocation?: Address;
  startMileage?: string;
  endMileage?: string;
  deviceDetails: DeviceDetails;
  duration?: number;
  activityCount?: number;
  distance?: number;
  status: boolean;

  companyId: string;
  daAppId: string;
  driverId: string;
  installationId: string;
  vehicleId: string;
  GpsLogEntries?: GpsLogEntry[];
  ShiftEntries?: ShiftEntry[];

  constructor() {
    this.GpsLogEntries = [];
  }
}
