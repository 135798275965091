<mat-list-item>
  <mat-icon mat-list-icon>person_pin</mat-icon>
  <h4 mat-line>{{'driver'|translate|ucFirst}}</h4>
  <p mat-line>{{'ride_driver_subtitle'|translate|ucFirst}}</p>
</mat-list-item>
<div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'name'|translate|ucFirst}}</p>
    <p mat-line>{{name}}</p>
  </mat-list-item>
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'phone_number'|translate|ucFirst}}</p>
    <p mat-line>{{driver.phoneNumber || '-'}}</p>
  </mat-list-item>
</div>
<div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <mat-list-item class="value small" flex-gt-sm="100">
    <p mat-line>{{'email'|translate|ucFirst}}</p>
    <p mat-line>{{driver.emailAddress || '-'}}</p>
  </mat-list-item>
  <mat-list-item *ngIf="driverNote" class="value small" flex-gt-sm="100">
    <p mat-line>{{'note'|translate|ucFirst}}</p>
    <p mat-line>{{driverNote || '-'}}</p>
  </mat-list-item>
</div>

<div *ngIf="!ride || !['completed','canceled','canceled_driver','canceled_passenger'].includes(ride.status)" [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" flex="25" layout-gt-sm="row"
     tdMediaToggle="gt-xs">
  <button (click)="unassignDriver(ride.id)" color="primary" flex mat-raised-button
          target="_blank">{{'unassign_job' | translate | ucFirst}}</button>
</div>
