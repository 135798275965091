export class Promotion {
  id: string;
  name: string;
  language: string;
  title: string;
  text?: string;
  startDate?: Date;
  endDate?: Date;
  imageSrc?: string;
  couponId?: string;
  companyId?: string;
  status: string;
  modified?: string;
  created?: string;
  image?: any;
}
