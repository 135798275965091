import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import moment from 'moment';
import {AvailabilityOverride} from '../../../../../../../../models/availability-override';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TdLoadingService} from '@covalent/core/loading';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {TdDialogService} from '@covalent/core/dialogs';
import {NavigationService} from '../../../../../../../../services/navigation.service';
import {ucFirst} from '../../../../../../../../pipes/uc-first.pipe';
import {environment} from '../../../../../../../../../environments/environment';
import {AvailabilityOverrideService} from '../../../../../../../../services/availability-override.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UpsertComponent} from '../../../../../../../global/upsert/upsert.component';
import {CountryService} from '../../../../../../../../services/country.service';
import {CoolLocalStorage} from '@angular-cool/storage';
import {WebbookerService} from '../../../../../../../../services/webbooker.service';
import {DaAppInstallService} from '../../../../../../../../services/da-app-install.service';
import {CurrencyService} from '../../../../../../../../services/currency.service';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {Company} from '../../../../../../../../models/company';

@Component({
  selector: 'app-availability-overwrite-upsert',
  templateUrl: './availability-override-upsert.component.html',
  styleUrls: ['./availability-override-upsert.component.scss']
})
export class AvailabilityOverrideUpsertComponent extends UpsertComponent implements OnInit {
  static translations: string[];
  minDate = moment().format('YYYY-MM-DDTHH:mm');
  maxDate = moment().format('YYYY-MM-DDTHH:mm');
  maxStartDate = moment().add('1', 'month').format('YYYY-MM-DDTHH:mm');
  data: AvailabilityOverride = new AvailabilityOverride();
  navType = 'dialog';
  company: Company;

  constructor(
    private _loadingService: TdLoadingService,
    private _dialogService: TdDialogService,
    private _navigationService: NavigationService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _translateService: TranslateService,
    private _availabilityOverrideService: AvailabilityOverrideService,
    private _formBuilder: UntypedFormBuilder,
    private _route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private _countryService: CountryService,
    private _vault: CoolLocalStorage,
    private _webbookerService: WebbookerService,
    private _daAppInstallService: DaAppInstallService,
    private _titleService: Title,
    private _currencyService: CurrencyService,
    public dialogRef: MatDialogRef<AvailabilityOverrideUpsertComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any) {
    super(_loadingService, _dialogService, _navigationService, _router, _snackBar, _availabilityOverrideService, _translateService, _route);

    this.context = 'company';
    const self = this;
    _translateService.get(['availability', 'availability_override_added_title', 'availability_override_added_message', 'updated_message']).subscribe((translations: any) => {
      self.translations = translations;
      self.translations['added_title'] = self.translations['availability_override_added_title'];
      self.translations['added_message'] = self.translations['availability_override_added_message'];
      this._titleService.setTitle(ucFirst(translations['availability'] + environment.windowTitleSuffix));
      this._navigationService.setBodyTitle(translations['availability']);
    });

    this.company = dialogData.company;

    if (dialogData.id) {
      this.id = dialogData.id;
      this.currentAction = 'save';
      this.action = 'save';

      this._availabilityOverrideService.get(this.id, {}).subscribe((data: any) => {
        this.data = data;
        this.initForm();
      }, error => {
        this.__loadingService.resolve(this.loaderName);
      })
    } else {
      this.companyId = dialogData.company.id;
      this.currentAction = 'add';
      this.action = 'add';

      this.initForm();
    }
  }

  initForm(): void {
    this.form = this._formBuilder.group({
      'name': [this.data.name, [Validators.required]],
      'type': [(this.data.type ? this.data.type : 'unavailable'), [Validators.required]],
      // @ts-ignore
      'startDate': [moment(this.data.startDate).format('YYYY-MM-DDTHH:mm'), [Validators.required]],
      // @ts-ignore
      'endDate': [moment(this.data.endDate).format('YYYY-MM-DDTHH:mm'), [Validators.required]],
      // @ts-ignore
      'description': [this.data.description, [Validators.required]],
    }, {validators: []});
  }

  onTabChange(event: MatTabChangeEvent) {

  }
}
