import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import {CollectionComponent} from '../../../../../global/collection/collection.component';
import {Company} from '../../../../../../models/company';
import {TdMediaService} from '@covalent/core/media';
import {TdDialogService} from '@covalent/core/dialogs';
import {TranslateService} from '@ngx-translate/core';
import {TdLoadingService} from '@covalent/core/loading';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Title} from '@angular/platform-browser';
import {UtilityService} from '../../../../../../services/utility.service';
import {AvailabilityOverrideUpsertComponent} from './parts/availability-override-upsert/availability-override-upsert.component';
import {AvailabilityOverrideService} from '../../../../../../services/availability-override.service';

@Component({
  selector: 'app-availability-overwrite',
  templateUrl: './availability-override.component.html',
  styleUrls: ['./availability-override.component.scss']
})
export class AvailabilityOverrideComponent extends CollectionComponent implements OnInit, OnDestroy {
  static translations: string[];
  @Input() company: Company;
  driver: any;
  activeTab = 0;
  companyId: string;
  selectFilter = {
    where: {
      companyId: '',
    },
  };
  columns = [
    {name: 'name', label: 'name', sortable: true, active: true},
    {name: 'type', label: 'type', sortable: false, active: false, translate: true},
    {name: 'startDate', label: 'start_date', sortable: false, active: false, fixedSize: 'w-216'},
    {name: 'endDate', label: 'end_date', sortable: false, active: false},
    {name: 'tools', label: 'actions', sortable: false, active: false},
  ];

  actionName = 'availabilityOverrides';
  modelName = 'availabilityOverrides';
  type = 'all';
  paging = {
    'all': {
      fromRow: 0,
      currentPage: 0,
      pageSize: 5
    }
  };
  filteredData = {
    all: [],
    grouped: {},
  }

  constructor(public media: TdMediaService,
              public _dialogService: TdDialogService,
              public _availabilityOverrideService: AvailabilityOverrideService,
              public _translateService: TranslateService,
              protected injector: Injector,
              private loadingService: TdLoadingService,
              private vault: CoolLocalStorage,
              private _titleService: Title) {
    super(injector);

    UtilityService.setBrowserTimeStamp(this.company);
    this.modelService = _availabilityOverrideService;
    this.selectFilter.where.companyId = this.company.id;

    _translateService.get(['availability_overwrite', 'ok', 'confirm_delete_availability_override', 'confirm_delete_availability_message']).subscribe((translations: any) => {
      this.translations = translations;
    });
  }

  ngOnInit(): void {
    this.loadData();
  }

  ngOnDestroy() {
    this._navigationService.setSplitLayoutButtons([]);
    this._navigationService.setActionFn(null);
  }

  startUpsert(): void {
    const self = this;
    this._dialogService.open(AvailabilityOverrideUpsertComponent, {
      maxHeight: '80%',
      minWidth: '300px',
      panelClass: 'no-dialog-padding',
      data: {
        openFromDialog: true,
        parent: this,
        company: this.company,
      },
    });
  }

  edit(id: string): void {
    const self = this;
    this._dialogService.open(AvailabilityOverrideUpsertComponent, {
      maxHeight: '80%',
      minWidth: '300px',
      panelClass: 'no-dialog-padding',
      data: {
        id: id,
        openFromDialog: true,
        parent: this,
        company: this.company,
      },
    });
  }

  delete(id: string): void {
    const self = this;
    this._dialogService.openConfirm({
      title: this.translations['confirm_delete_availability_override'],
      message: this.translations['confirm_delete_availability_message'],
      acceptButton: this.translations['confirm'],
      disableClose: true,
      cancelButton: this.translations['cancel']
    }).afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          this._availabilityOverrideService.delete(id, 'company')
            .subscribe(() => {
              self.loadData();
            });
        }
      });
  }
}
