<div *ngIf="form">
  <form [formGroup]="form">
    <mat-card-content class="push-bottom-none">
      <mat-card-title>{{'setup_new_workflow' | translate | ucFirst}}</mat-card-title>
      <div class="row push-top">
        <div class="inline-flex-pane first-match-condition pull-top">
          <mat-form-field [style.width.px]="325">
            <input formControlName="name" matInput cdkFocusInitial
                   placeholder="{{'name'|translate|ucFirst}}" type="text" value="">
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <strong>{{ 'message_center_conditions' | translate | ucFirst }}</strong>
        <div class="inline-flex-pane first-match-condition pull-top">
          <mat-form-field [style.width.px]="325">
            <mat-select
              class="row-select"
              flex="100"
              formControlName="newFlowCondition" required>
              <mat-option *ngFor="let matchValue of matchValues; index as i" [value]="matchValue">
                {{ matchValue + '_condition'|translate|ucFirst }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <strong>{{ 'do_this' | translate | ucFirst }}</strong>
        <div class="inline-flex-pane first-match-condition pull-top">
          <mat-form-field [style.width.px]="325">
            <mat-select
              class="row-select"
              flex="100"
              formControlName="newFlowAction" required>
              <mat-option *ngFor="let matchValue of matchValues2; index as i" [value]="matchValue">
                {{ matchValue|translate|ucFirst }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="inline-flex-pane first-match-condition pull-top">
          <mat-form-field [style.width.px]="325">
            <mat-select
              class="row-select"
              flex="100"
              formControlName="recipientType" required>
              <mat-option *ngFor="let options of data.parent.recipientTypes; index as i" [value]="options">
                {{ options+'_new'|translate|ucFirst }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </form>

  <mat-divider class="push-bottom"></mat-divider>
  <mat-card-actions class="push-top">
    <button (click)="createNewFromWizard()"
            class="float-right"
            color="accent"
            flex
            mat-raised-button>{{ 'create_new_workflow'|translate|ucFirst }}
    </button>
    <button (click)="cancel()"
            class="float-right push-right"
            flex
            mat-raised-button>{{ 'cancel'|translate|ucFirst }}
    </button>
  </mat-card-actions>
</div>
