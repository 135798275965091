<mat-card>
  <mat-card-title>{{'recent_jobs_title' | translate | ucFirst}}</mat-card-title>
  <table class="mat-clickable" td-data-table>
    <thead>
    <tr td-data-table-column-row>
      <th (sortChange)="sort($event)"
          *ngFor="let c of columns"
          [active]="(sortBy == c.name)"
          [name]="c.name"
          [numeric]="c.numeric"
          [sortOrder]="(sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
          [sortable]="c.sortable"
          td-data-table-column>
        {{c.label|translate|ucFirst}}
      </th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let item of originalData['all'];" class="todo-assign">
      <tr class="pointer {{item.colorClass}}" td-data-table-row>
        <td *ngFor="let c of columns" [numeric]="c.numeric" class="{{c.maxSize}} {{c.fixedSize}} {{c.maxWidth}} checkbox-td"
            td-data-table-cell>
                    <span [ngSwitch]="c.name">
                      <span *ngSwitchCase="'color-cote'">
                        <div class="color-coting product-type"></div>
                        <div class="color-coting meta-field-wheelchair"></div>
                        <div class="color-coting meta-field-luggage"></div>
                        <div class="color-coting meta-field-childSeats"></div>
                        <div class="color-coting meta-field-babySeats"></div>
                        <div class="color-coting meta-field-guideDog"></div>
                        <div class="color-coting meta-field-companion"></div>
                        <div class="color-coting meta-field-invoiceReference"></div>
                        <div class="color-coting meta-field-cancelationPolicy"></div>
                        <div class="color-coting meta-field-pickupPolicy"></div>
                        <div class="color-coting meta-field-requestedDateOnCall"></div>
                      </span>

                      <span *ngSwitchCase="'requestedDate' || 'dispatchTimeToPickup'">
                        <span
                          *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span>
                      </span>

                      <span *ngSwitchCase="'departure'" class="wrap-line">
                        <span *ngIf="item[c.name]" class="wrap-line"
                              title="{{item.departure}}">{{item.departure}}</span><span
                        *ngIf="!item[c.name]">-</span>
                      </span>
                      <span *ngSwitchCase="'originName'" class="wrap-line">
                        <span *ngIf="item[c.name]" class="wrap-line"
                              title="{{item[c.name]}}">{{item[c.name]}}</span><span
                        *ngIf="!item[c.name]">-</span>
                      </span>
                      <span *ngSwitchCase="'destination'" class="wrap-line">
                        <span *ngIf="item[c.name]" title="{{item.destination}}">{{item.destination}}</span>
                        <span *ngIf="!item[c.name]">-</span></span>
                      <span *ngSwitchCase="'status'">
                        <span [ngSwitch]="item.offerType">
                          <span *ngSwitchCase="'sharedToGroup'"
                                class="wrap-line dispatch-active">{{item.statusTxt}}</span>
                          <span *ngSwitchCase="'company'" class="wrap-line dispatch-active">
                            <span class="dispatch-active-text">{{item.statusTxt}}</span>
                            <div class="snippet" data-title=".dot-pulse">
                              <div class="stage">
                                <div class="dot-pulse"></div>
                              </div>
                            </div>
                          </span>
                          <span *ngSwitchCase="'failed'" class="wrap-line dispatch-active tc-red-600">
                            {{item.statusTxt | ucFirst}}
                          </span>
                          <span *ngSwitchDefault class="wrap-line">{{item.statusTxt}}</span>
                        </span>
                      </span>
                      <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span><span
                        *ngIf="!item[c.name]">-</span></span>
                    </span>
        </td>
      </tr>
    </ng-container>
    </tbody>
    <tbody *ngIf="originalData['all'].length === 0">
    <tr>
      <td [colSpan]="columns.length"
          class="table-placeholder">
        {{'no_jobs_in_overview'|translate|ucFirst}}
      </td>
    </tr>
    </tbody>
  </table>
  <td-paging-bar #paging (change)="page($event)" [pageSize]="5" [total]="originalData['all'].length">
    <span hide-xs>{{'rows_p_page'|translate}}:</span>
    {{paging.range}} <span hide-xs>of {{paging.total}}</span>
  </td-paging-bar>
</mat-card>
