<mat-toolbar>
  <span>{{'my_invoices'|translate}}</span>
  <span class="fill-space"></span>
  <button mat-icon-button matTooltip="{{'my_invoices'|translate|ucFirst}}">
    <mat-icon routerLink="/invoices">euro_symbol</mat-icon>
  </button>
  <button mat-icon-button matTooltip="{{'invoice_settings'|translate|ucFirst}}">
    <mat-icon routerLink="/invoices/settings">settings</mat-icon>
  </button>
</mat-toolbar>
