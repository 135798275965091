<div>
  <h4
    [innerHTML]="'share_webbooker_subtitle'|translate|keepHtml" class="push-bottom push-top"></h4>
  <mat-divider *ngIf="false"></mat-divider>

  <div *ngIf="this.webbookerUrl" class="table">
    <div class="table-header" layout="row">
      <div class="left-column share-link" layout="column">
        <a class="yda-link-blue" href="{{this.webbookerUrl}}"
           target="_new">{{this.webbookerUrl.replace('https://', '')}}</a>
      </div>
      <button (click)="this.copyToClipboard()" class="push-left copy-button" flex
              mat-stroked-button>
        {{'copy_link'|translate|ucFirst}}</button>
      <button (click)="openWebbookerIntegrationCode(this.webbookerId, this.webbookerUrl, this.webbooker)"
              *ngIf="showIntegrationButton==='true'"
              class="push-left share-button"
              color="primary"
              mat-raised-button
      >{{'show_integration_code'|translate|ucFirst}}</button>
    </div>
  </div>
</div>
