import {Component, Inject, OnInit} from '@angular/core';
import {InvoiceService} from '../../../../../../services/pas/invoice.service';
import {TdDialogService} from '@covalent/core/dialogs';
import {TranslateService} from '@ngx-translate/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {environment} from '../../../../../../../environments/environment';
import {MultiActionComponent} from '../../../../dispatchpanel/parts/multi-action/multi-action.component';
import {User} from '../../../../../../models/user';

@Component({
  selector: 'app-resend-invoices-dialog',
  templateUrl: './resend-invoices-dialog.component.html',
  styleUrls: ['./resend-invoices-dialog.component.scss'],
  providers: [InvoiceService]
})
export class ResendInvoicesDialogComponent implements OnInit {
  translations = [];
  sendCopy = true;
  user: User;

  constructor(
    private _invoiceService: InvoiceService,
    private _dialogService: TdDialogService,
    private _translateService: TranslateService,
    private _vault: CoolLocalStorage,
    public dialogRef: MatDialogRef<ResendInvoicesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.user = this._vault.getObject(`${environment.vaultPrefix}.user`);
    this._translateService.get([
      `confirm`,
      'payment_origin_inTaxi', 'payment_origin_inApp', 'payment_origin_online', 'payment_origin_invoice', 'send_invoice_copy_to'
    ]).subscribe((translations: string[]) => {
      this.translations = translations;
      this.translations['send_invoice_copy_to'] = this.translations['send_invoice_copy_to'].replace('{email}', this.user.email);
    });
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

  resendInvoices(): void {
    this._dialogService.open(MultiActionComponent, {
      maxHeight: '80vw',
      maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
      disableClose: true,
      data: {
        parent: this.data.parent,
        context: this.data.context,
        action: 'invoices_send_email',
        items: this.data.items,
        sendCopy: (this.sendCopy ? this.user.email : false),
        debtors: this.data.debtors,
        invoiceType: this.data.invoiceType
      },
    });
    this.close();
  }

  switchTabEmit(tab) {
    this.data.parent.switchTab.emit(tab);
  }
}
