import {AfterViewInit, Component, OnInit} from '@angular/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {environment} from '../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {Shift} from '../../models/shift';
import {TdMediaService} from '@covalent/core/media';
import {TdLoadingService} from '@covalent/core/loading';
import {RideService} from '../../services/ride.service';
import {Ride} from '../../models/ride';
import {DriverService} from '../../services/driver.service';
import {DriverScore} from '../../models/driver-score';
import {Vehicle} from '../../models/vehicle';
import {NavigationService} from '../../services/navigation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ucFirst} from '../../pipes/uc-first.pipe';
import {PersonalLicenseComponent} from '../global/personal-license/personal-license.component';
import {UserService} from '../../services/user.service';
import {TdDialogService} from '@covalent/core/dialogs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [RideService]
})
export class DashboardComponent extends PersonalLicenseComponent implements OnInit, AfterViewInit {
  context: string;
  driver: any;
  shifts: Shift[];
  rides: Ride[] = [];
  vehicles: Vehicle[] = [];
  rideStatusColors = {
    domain: ['#DD2C00', '#00C853']
  };
  driverScore: DriverScore;
  dataSetCount = 2;
  currentDataSet = 0;

  constructor(public media: TdMediaService,
              private _vault: CoolLocalStorage,
              private _route: ActivatedRoute,
              private _router: Router,
              private _translateService: TranslateService,
              private _loadingService: TdLoadingService,
              private _rideService: RideService,
              private _userService: UserService,
              private _navigationService: NavigationService,
              private _driverService: DriverService,
              private _dialogService: TdDialogService,
              private _titleService: Title) {
    super();

    _translateService.get(['dashboard', 'personal_license_expired', 'personal_license_expired_title', 'session_expired', 'close']).subscribe((translations: string[]) => {
      // this.validatePersonalLicense(_userService, _vault, translations, _dialogService, _router);

      this._titleService.setTitle(ucFirst(translations['dashboard']) + environment.windowTitleSuffix);
      this.context = this._route.routeConfig['context'] || 'driver';

      if (this.context === 'company') {
        this._navigationService.setBodyTitle(translations['dashboard']);
      } else {
        this._navigationService.setBodyTitle(translations['dashboard']);
      }
    });
  }

  ngOnInit() {
    this.driver = this._vault.getObject(`${environment.vaultPrefix}.driver`);
    this._loadingService.register('dashboard.data');
    this.loadDriverScore();
    this.loadVehicles();
  }

  ngAfterViewInit() {
    this.media.broadcast();
  }

  loadVehicles(): void {
    this._driverService.getVehicles(this.driver.id).subscribe((vehicles) => {
      console.log('vehicles', vehicles);
      this.vehicles = vehicles;

      this.currentDataSet = this.currentDataSet + 1;

      if (this.currentDataSet === this.dataSetCount) {
        const self = this;
        setTimeout(() => {
          self._loadingService.resolve('dashboard.data');
        }, 500);
      }
    }, error => {
      console.error(error);
      this.currentDataSet = this.currentDataSet + 1;
      if (this.currentDataSet === this.dataSetCount) {
        const self = this;
        setTimeout(() => {
          self._loadingService.resolve('dashboard.data');
        }, 500);
      }
    });
  }

  loadDriverScore(): void {
    this._driverService.getScore(this.driver.id).subscribe((driverScore: any) => {
      this.driverScore = driverScore;
      this.currentDataSet = this.currentDataSet + 1;

      if (this.currentDataSet === this.dataSetCount) {
        const self = this;
        setTimeout(() => {
          self._loadingService.resolve('dashboard.data');
        }, 500);
      }
    }, error => {
      console.error(error);
      this.currentDataSet = this.currentDataSet + 1;
      if (this.currentDataSet === this.dataSetCount) {
        const self = this;
        setTimeout(() => {
          self._loadingService.resolve('dashboard.data');
        }, 500);
      }
    });
  }
}
