<app-no-contract *ngIf="(this.context==='company' && (!this.company.ActiveContract ||
!['active','past_due', null].includes(this.company.ActiveContract.foreignStatus)))"
                 [companyId]="this.companyId" [contract]="this.company.ActiveContract"></app-no-contract>
<mat-card [class]="(this.context==='company' &&
(!this.company.ActiveContract ||
(this.company.hasDispatcherLicense && !this.company.ActiveContract.foreignStatus)) ? 'blurText' :'')" [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <td-search-box (searchDebounce)="search($event)" [alwaysVisible]="true" class="push-left push-right"
                 placeholder="{{'search_here'|translate}}"></td-search-box>
  <mat-divider></mat-divider>
  <ng-template tdLoading="{{loaderName}}">
    <table class="mat-clickable" td-data-table>
      <thead>
      <tr td-data-table-column-row>
        <th (sortChange)="sort($event)"
            *ngFor="let c of columns"
            [active]="(sortBy == c.name)"
            [name]="c.name"
            [numeric]="c.numeric"
            [sortOrder]="( sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
            [sortable]="c.sortable"
            td-data-table-column>
          {{((c.name !== 'tools' ? c.label : '')|translate|ucFirst)}}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of filteredPolicies" td-data-table-row>
        <td (click)="(c.name!='tools' ? open(item._id) : false)" *ngFor="let c of columns" [numeric]="item.numeric"
            class="{{c.maxSize}} {{(c.name=='tools'?'text-right':'')}}"
            td-data-table-cell>
          <span [ngSwitch]="c.name">
            <span *ngSwitchCase="'departureAllowed'">{{(item[c.name] ? 'yes' : 'no')|translate|ucFirst}}</span>
            <span *ngSwitchCase="'destinationAllowed'">{{(item[c.name] ? 'yes' : 'no')|translate|ucFirst}}</span>
            <span *ngSwitchCase="'apps'">
              <span *ngIf="item[c.name]" class="wrap-line">
                <span matTooltip="{{item['tooltip']}}">{{item[c.name]}}</span>
                </span>
              <span
                *ngIf="!item[c.name]">-</span>
            </span>
            <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span></span>
          </span>
        </td>
      </tr>
      </tbody>
    </table>
    <td-paging-bar #pagingBar (change)="page($event)" [pageSize]="pageSize" [total]="filteredPolicyCount">
      <span hide-xs td-paging-bar-label>{{'rows_p_page'|translate}}:</span>
      {{pagingBar.range}} <span hide-xs>of {{pagingBar.total}}</span>
    </td-paging-bar>
  </ng-template>
</mat-card>
