<mat-card [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <mat-card-content class="push-bottom-none">
    <ng-template tdLoading="location-policy">
      <form *ngIf="form" [formGroup]="form">
        <div class="push-bottom-sm push-top-sm" layout="row">
          <mat-form-field class="push-right" flex>
            <input formControlName="name" matInput placeholder="{{'name'|translate|ucFirst}}" type="text"
                   value="{{policy.name}}">
            <mat-hint align="start">
              <span [hidden]="!formErrors.name" class="tc-red-600">
                <span>{{ formErrors.name }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
        </div>
        <div layout="row">
          <div class="push-right" flex>
            <mat-checkbox formControlName="departureAllowed">Departure allowed?</mat-checkbox>
            <mat-hint align="start">
              <span [hidden]="!formErrors.departureAllowed" class="tc-red-600">
                <span>{{ formErrors.departureAllowed }}</span>
              </span>
            </mat-hint>
          </div>
          <div flex>
            <mat-checkbox formControlName="destinationAllowed">Destination allowed?</mat-checkbox>
            <mat-hint align="start">
              <span [hidden]="!formErrors.destinationAllowed" class="tc-red-600">
                <span>{{ formErrors.destinationAllowed }}</span>
              </span>
            </mat-hint>
          </div>
        </div>
        <div class="push-bottom-sm push-top-md" layout="row">
          <mat-form-field [style.display]="'none'" class="push-right" flex>
            <input formControlName="departureWarning" matInput placeholder="{{'location_policy_departure_warning'|translate|ucFirst}}"
                   type="text"
                   value="{{policy.departureWarning}}">
            <mat-hint align="start">
              <span [hidden]="!formErrors.departureWarning" class="tc-red-600">
                <span>{{ formErrors.departureWarning }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
          <mat-form-field [style.display]="'none'" class="" flex>
            <input formControlName="destinationWarning" matInput placeholder="{{'location_policy_destination_warning'|translate|ucFirst}}"
                   type="text"
                   value="{{policy.destinationWarning}}">
            <mat-hint align="start">
              <span [hidden]="!formErrors.destinationWarning" class="tc-red-600">
                <span>{{ formErrors.destinationWarning }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
        </div>
      </form>
    </ng-template>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <button (click)="save()" [disabled]="!form.valid" color="accent" flex
            mat-raised-button>{{'save_location_policy_button'|translate}}</button>
  </mat-card-actions>
</mat-card>
