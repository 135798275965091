import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

import {ActivatedRoute} from '@angular/router';
import {TdDataTableService, TdDataTableSortingOrder} from '@covalent/core/data-table';
import {TdDialogService} from '@covalent/core/dialogs';
import {TdLoadingService} from '@covalent/core/loading';

import {forkJoin} from 'rxjs';
import {Discount} from '../../../../../../../../models/discount';
import {FixedTypes, OnMeterTypes, Rule} from '../../../../../../../../models/rule';
import {DaAppInstall} from '../../../../../../../../models/tps/da-app-install';
import {DaAppInstallService} from '../../../../../../../../services/tps/da-app-install.service';
import {DiscountService} from '../../../../../../../../services/tps/discount.service';
import {PricingRuleService} from '../../../../../../../../services/tps/pricing-rule.service';
import {DiscountDialogComponent} from '../discount-dialog/discount-dialog.component';
import {PricingRuleDialogComponent} from '../pricing-rule-dialog/pricing-rule-dialog.component';
import {TranslateService} from '@ngx-translate/core';
import {PricingRuleLinkService} from '../../../../../../../../services/tps/pricing-rule-link.service';

interface AddedProps {
  isEnabledForApp: boolean;
  isAllowedOnMeter: boolean;
  isFixed: boolean;
  linkId: string;
}

@Component({
  selector: 'app-pricing-tab',
  templateUrl: './pricing-tab.component.html',
  styleUrls: ['./pricing-tab.component.scss'],
  providers: [DaAppInstallService, DiscountService, PricingRuleService]
})
export class PricingTabComponent implements OnInit {

  RULE_ADDED_MSG = 'Rule activated.';
  RULE_DELETED_MSG = 'Rule deactivated.';
  RULE_UPDATED_MSG = 'Rule updated.';
  DISCOUNT_ADDED_MSG = 'Special rate activated.';
  DISCOUNT_DELETED_MSG = 'Special rate deactivated.';

  appId: string;
  companyId: string;
  app: DaAppInstall;

  ruleColumns = [
    {name: 'isEnabledForApp', label: 'Enabled', sortable: false},
    {name: 'name', label: 'Name', sortable: false, active: true},
    {name: 'isFixed', label: 'Fixed', sortable: false},
    {name: 'isAllowedOnMeter', label: 'On-meter', sortable: false},
    {name: 'type', label: 'Type', sortable: false},
  ];
  ruleColumns2 = [
    {name: 'isEnabledForApp', label: 'Enabled', sortable: false},
    {name: 'name', label: 'Name', sortable: false, active: true},
    {name: 'isFixed', label: 'Fixed', sortable: false},
    {name: 'type', label: 'Type', sortable: false},
  ];

  discountColumns = [
    {name: 'isEnabledForApp', label: 'Enabled', sortable: false, active: false},
    {name: 'name', label: 'Name', sortable: false, active: true},
    {name: 'type', label: 'Type', sortable: false},
  ];

  pricingRules: (Rule & AddedProps)[];
  discounts: (Discount & AddedProps)[];
  appPricingRules: (Rule & AddedProps)[];
  appDiscounts: (Discount & AddedProps)[];

  translations = [];

  constructor(
    public dialog: MatDialog,
    private _route: ActivatedRoute,
    private _appService: DaAppInstallService,
    private _dataTableService: TdDataTableService,
    private _pricingRuleService: PricingRuleService,
    private _pricingRuleLinkService: PricingRuleLinkService,
    private _discountService: DiscountService,
    private _loadingService: TdLoadingService,
    private _dialogService: TdDialogService,
    private _snackbar: MatSnackBar,
    private _fb: UntypedFormBuilder,
    private _translateService: TranslateService,
  ) {
    _translateService.get([
      'rule_activated',
      'rule_deactivated',
      'rule_updated',
      'rule_special_rate_activated',
      'rule_special_rate_deactivated'
    ]).subscribe((translations: any) => {
      this.translations = translations;

      this.RULE_ADDED_MSG = this.translations['rule_activated'];
      this.RULE_DELETED_MSG = this.translations['rule_deactivated'];
      this.RULE_UPDATED_MSG = this.translations['rule_updated'];
      this.DISCOUNT_ADDED_MSG = this.translations['rule_special_rate_activated'];
      this.DISCOUNT_DELETED_MSG = this.translations['rule_special_rate_deactivated'];
    });
  }

  /**
   * On component initialize.
   */
  ngOnInit() {
    this._route.parent.params.subscribe(first => {
      this.companyId = first['id'];
      this._route.params.subscribe(second => {
        this.appId = second['id'];
        this.startLoader();
        this.loadData();
      });
    });
  }

  /**
   * Sort by prio ascending.
   */
  sortPrioDesc = (data: Rule[] | Discount[]) =>
    data = this._dataTableService.sortData(
      data,
      'priority',
      TdDataTableSortingOrder.Ascending
    );

  /**
   * Reload data on page when error occurs.
   */
  reload = () => this.loadData();

  /**
   * Load data from the api, sorting into different types.
   */
  loadData = () => {
    const whereCompanyId = {where: {companyId: this.companyId}};
    const self = this;
    forkJoin([
      this._appService.get(this.appId, {
        where: {companyId: this.companyId},
        include: [
          {
            relation: 'rules',
            scope: {
              where: {
                or: [
                  {parentRuleId: {exists: false}},
                  {parentRuleId: null}
                ]
              }
            }
          },
          {
            relation: 'discounts'
          }
        ]
      }),
      this._pricingRuleService.getAll({
        where: {
          companyId: this.companyId,
          or: [
            {parentRuleId: {exists: false}},
            {parentRuleId: null}
          ]
        }
      }),
      this._discountService.getAll(whereCompanyId),
      this._pricingRuleService.getRuleLinks({
        where: {
          ruleableType: 'DaAppInstall',
          ruleableId: this.appId
        },
        include: {
          relation: 'rule',
          scope: whereCompanyId
        }
      }),
      this._discountService.getDiscountLinks({
        where: {
          discountableType: 'DaAppInstall',
          discountableId: this.appId
        }
      }),
    ]).subscribe(
      (results: any) => {
        const [app, pricingRules, discounts, ruleLinks, discountLinks] = results;
        this.app = app;
        // Sort pricingRules and discounts, then adding a column.
        [this.pricingRules, this.discounts] = [pricingRules, discounts].map(this.sortPrioDesc);

        const duplicateFixer = [];
        // Update rules in the views
        ruleLinks.map(link => {
          const rule = this.pricingRules.find(e => e._id === link.ruleId);
          if (!rule) {
            return;
          }

          if (duplicateFixer.includes(rule._id)) {
            this._pricingRuleLinkService.delete(link._id, 'company').subscribe(() => {
              console.log('DELETED Duplicate:', link._id);
            });
            return;
          }

          rule.linkId = link._id;
          rule.isEnabledForApp = true;
          rule.isAllowedOnMeter = link.isAllowedOnMeter;
          rule.isFixed = link.isFixed;
          duplicateFixer.push(rule._id);
        });
        // this.pricingRules = ruleLinks;

        // Update discounts in the views
        discountLinks.map(link => {
          const discount = this.discounts.find(e => e._id === link.discountId);
          if (!discount) {
            return
          }
          discount.linkId = link._id;
          discount.isEnabledForApp = true;
        });

      },
      (e) => {
        console.error('Error loading pricing tab');
        console.error(e);
        self.stopLoader()
      },
      () => this.stopLoader()
    );
  };

  /**
   * Open Pricing Rule Dialog.
   */
  openPricingRuleDialog() {
    this._dialogService.open(PricingRuleDialogComponent, {
      maxHeight: '80%',
      maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
      data: {parent: this},
    });
  }

  /**
   * Control settings and existence of ruleLink.
   */
  async toggleRuleLinkSetting(
    propName: 'string',
    enableProp: boolean,
    rule: Rule & AddedProps
  ) {
    const propNames = ['isFixed', 'isAllowedOnMeter'];

    // If prop doesn't exist on ruleLink, developer made mistake
    if (!propNames.includes(propName)) {
      throw Error('This prop is not defined on a ruleLink entity.');
    }

    // Fate of the ruleLink
    const otherPropName = propNames.filter(p => p !== propName)[0];
    const mustBeCreated = !rule[propName] && !rule[otherPropName];
    const mustBeDeleted = !!(rule[propName] && !rule[otherPropName]);
    const mustExist = !!(mustBeCreated || !mustBeDeleted);

    if (mustBeCreated || mustBeDeleted) {
      try {
        await this.createOrDeleteRuleLink(rule, mustExist);
      } catch (e) {
        return this.reload();
      }
    }

    // The desired props are overwritten and persisted
    // and reverted if something goes wrong
    if (rule.isEnabledForApp) {
      rule[propName] = enableProp;
      try {
        const change = {};
        change[propName] = enableProp;
        await this._pricingRuleService
          .updateRuleLink(rule.linkId, change)
          .subscribe(() => {
            this._snackbar.open(this.RULE_UPDATED_MSG, '', {duration: 3000});
          })
      } catch (e) {
        return this.reload();
      }
    }
  }

  /**
   * Open Special Rates Dialog.
   */
  openDiscountDialog() {
    this._dialogService.open(DiscountDialogComponent, {
      maxHeight: '80%',
      maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
      data: {parent: this},
    });
  }

  /**
   * Add or remove association of discount (special rate).
   */
  toggleDiscount(event: MatCheckboxChange, discount: Discount & AddedProps) {
    discount.isEnabledForApp = event.checked;
    this._appService.updateRelation(
      this.appId, discount, 'discount', event.checked
    ).subscribe(() => {
      const msg = event.checked
        ? this.DISCOUNT_ADDED_MSG
        : this.DISCOUNT_DELETED_MSG;
      this._snackbar.open(msg, '', {duration: 3000});
    });
  }

  /**
   * Map type to something understandable.
   */
  ruleFrontendName = (type: 'dynamic' | 'fixed' | 'meter') =>
    Rule.ruleTypeNamesMapping(type);

  isAllowedOnMeterName = (isAllowedOnMeter: boolean) =>
    OnMeterTypes[String(isAllowedOnMeter)];

  isFixedName = (isFixed: boolean) =>
    FixedTypes[String(isFixed)];

  /**
   * Start the spinning loader.
   */
  startLoader = () => this._loadingService.register('tab.pricing');

  /**
   * Stop the spinning loader.
   */
  stopLoader = () => this._loadingService.resolve('tab.pricing');

  /**
   * Creates or deletes a rule link depending on whether
   * it must exist.
   */
  private async createOrDeleteRuleLink(
    rule: Rule & AddedProps,
    mustExist: boolean
  ) {
    const link = await this._appService
      .updateRelation(this.appId, rule, 'rule', mustExist)
      .toPromise();

    // A new link was successfully created
    if (link && mustExist) {
      rule.isEnabledForApp = true;
      rule.isAllowedOnMeter = false;
      rule.isFixed = false;
      rule.linkId = link._id;
      this._snackbar.open(this.RULE_ADDED_MSG, '', {duration: 3000});
    }

    // A link was successfully deleted
    if (!link && !mustExist) {
      rule.isEnabledForApp = false;
      rule.isAllowedOnMeter = false;
      rule.isFixed = false;
      rule.linkId = undefined;
      this._snackbar.open(this.RULE_DELETED_MSG, '', {duration: 3000});
    }
  }

}
