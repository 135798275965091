import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {forkJoin, Observable, of} from 'rxjs';
import {Rule, RuleLink} from '../../models/rule';
import {environment} from '../../../environments/environment';
import {AuthService} from '../auth.service';
import {Contract} from '../../models/contract';

// Pure function returns new model without provided props
const deleteProps = (model: any, ...props: string[]): any => {
  const copy = Object.assign({}, model);
  props.forEach(prop => delete copy[prop]);
  return copy;
}

// Remove all keys that are objects
const removeCircular = (obj: any) => {
  Object.keys(obj).forEach(p => {
    if (typeof obj[p] === 'object' && obj[p] !== null && p != 'passengerPercentage') {
      delete obj[p];
    }
  });
  return obj;
}

// Convert object to array
const objToArr = (obj: {} | null) => obj
  ? [...Object.keys(obj).map(k => obj[k])]
  : [];

// Converts property to array if object
const getPropAsArray = (model: any): any[] => {
  if (typeof model === 'object') {
    return objToArr(model);
  } else {
    return [];
  }
}

// Converts currency to cents
const toCents = (value: number) => value * 100;

// Generates some string that is accepted as objectId by APIs
const generateObjectID = () =>
  Math.random().toString(24) +
  Math.random().toString(24);

// Basic props for requests
const ruleModel = 'rules';
const base = environment.tpsBaseUrl;

@Injectable()
export class PricingRuleService {

  priceKeys = [
    'dynamicStartPrice',
    'dynamicMinimumPrice',
    'dynamicMinutePrice',
    'dynamicDistancePrice',
    'fixedPrice',
    'minuteWaitingPrice',
  ];

  constructor(
    private _http: HttpClient,
    private _auth: AuthService,
  ) {
  }

  /**
   * Get all rules.
   */
  getAll = (filter?: Object): Observable<Rule[]> => {
    return this._http.get<Rule[]>(
      `${base}/${ruleModel}?filter=${JSON.stringify(filter)}`,
      {headers: this.getHeaders()},
    );
  }

  /**
   * Get a rule by id.
   */
  get = (id: string, filter?: Object): Observable<Rule> =>
    this._http.get<Rule>(
      `${base}/${ruleModel}/${id}?filter=${JSON.stringify(filter)}`,
      {headers: this.getHeaders()},
    );

  /**
   * Get the hasMany through relation in between rule and ruleAble.
   */
  getRuleTree = (id: string, filter?: Object): Observable<Rule[]> => {
    return this._http.get<Rule[]>(
      `${base}/rules/${id}/ruleTree`,
      {headers: this.getHeaders()},
    );
  }

  /**
   * Get the hasMany through relation in between rule and ruleAble.
   */
  getRuleLinks = (filter?: Object): Observable<RuleLink> =>
    this._http.get<RuleLink>(
      `${base}/ruleLinks?filter=${JSON.stringify(filter)}`,
      {headers: this.getHeaders()},
    );

  /**
   * Update the hasMany through relation in between rule and ruleAble.
   */
  updateRuleLink = (id: string, ruleLink: {}): Observable<RuleLink> =>
    this._http.patch<RuleLink>(
      `${base}/ruleLinks/${id}`, ruleLink,
      {headers: this.getHeaders()},
    );

  /**
   * Insert new rule and relations.
   */
  insert = (givenRule: any): Observable<any> => {
    let rule = JSON.parse(JSON.stringify(givenRule));
    // Mutates the rule converting prices to cents
    this.pricesToCents(rule);
    rule = deleteProps(rule, '_id');

    rule.prices.forEach(price => {
      price.priceDynamic.thresholds.forEach(threshold => {
        threshold._id = generateObjectID();
      })
      price.priceFixed.thresholds.forEach(threshold => {
        threshold._id = generateObjectID();
      })
      price.priceHourly.thresholds.forEach(threshold => {
        threshold._id = generateObjectID();
      })
    });

    rule.timeframes.forEach(timeframe => {
      timeframe._id = generateObjectID()
    });

    return this._http.post<Rule>(
      `${base}/${ruleModel}`, rule,
      {headers: this.getHeaders()})
  }

  /**
   * Delete rule by id.
   */
  delete = (id: string): Observable<{ count: boolean }> =>
    this._http.delete<{ count: boolean }>(
      `${base}/${ruleModel}/${id}`,
      {headers: this.getHeaders()},
    );

  /**
   * Update rule with new child rules.
   */
  updateWithNewChildRules = (rule: any): Observable<any> => {
    rule = JSON.parse(JSON.stringify(rule));
    // Mutates the rule converting prices to cents
    this.pricesToCents(rule);
    return this._http.patch<any>(
      `${base}/${ruleModel}/${rule._id}`, rule,
      {headers: this.getHeaders()},
    );
  }

  /**
   * Update rule and relations.
   */
  update = (rule: any): Observable<any> =>
    forkJoin(this.updateRecursively(rule))

  /**
   * Generates an array of requests to update entities of a rule.
   */
  updateRecursively = (rule: any): Observable<any>[] => {
    if (!rule._id) {
      return [of(false)];
    }

    const requests = [];

    // Create a generic request for each model, add them to the request array
    const addToRequests = (models: any[], name: string) => {
      models.map(e => requests.push(this.updateModel(e._id, e, name)));
    }

    // If child rules exist within this rule, update them recursively as well
    if (rule.childRules) {
      for (const key in rule.childRules) {
        const child = rule.childRules[key];

        // If child has no id, nothing should happen
        if (child._id) {
          requests.push(...this.updateRecursively(child))
        }
      }
    }

    // Mutates the rule converting prices to cents
    this.pricesToCents(rule);
    // The order below is maintained, as prices obj is dissolved
    const prices = getPropAsArray(rule.prices);
    const fixed = prices.filter(p => p.priceFixed).map(p => p.priceFixed);
    const dynamic = prices.filter(p => p.priceDynamic).map(p => p.priceDynamic);
    const hourly = prices.filter(p => p.priceHourly).map(p => p.priceHourly);
    const timeframes = objToArr(rule.timeframes);

    // Add random id to thresholds because loopback can't handle it
    dynamic
      .filter(p => p.thresholds)
      .map(p => p.thresholds.map(t => t._id = generateObjectID()));

    // Add random id to thresholds because loopback can't handle it
    hourly
      .filter(p => p.thresholds)
      .map(p => p.thresholds.map(t => t._id = generateObjectID()));

    // Timeframes are embedded, and should be treated as such
    timeframes.map(t => requests.push(this.updateTimeframe(rule._id, t._id, t)))

    addToRequests(fixed, 'pricesFixed');
    addToRequests(dynamic, 'pricesDynamic');
    addToRequests(hourly, 'pricesHourly');
    addToRequests(prices.map(removeCircular), 'prices');
    addToRequests([rule].map(removeCircular), 'rules');

    return requests;
  }

  /**
   * Update a timeframe.
   */
  updateTimeframe = (
    ruleId: string, timeframeId: string, timeframe: {}
  ): Observable<any> =>
    this._http.put<any>(
      `${base}/${ruleModel}/${ruleId}/timeframe/${timeframeId}`,
      deleteProps(timeframe, 'id'),
      {headers: this.getHeaders()},
    );

  /**
   * Update a generic model.
   */
  updateModel = (id: string, model: {}, type: string): Observable<any> =>
    this._http.patch<any>(
      `${base}/${type}/${id}`, deleteProps(model, 'id'),
      {headers: this.getHeaders()},
    );

  /**
   * Update a generic model.
   */
  insertModel = (model: {}, type: string): Observable<any> =>
    this._http.post<any>(
      `${base}/${type}/`, deleteProps(model, 'id'),
      {headers: this.getHeaders()},
    );

  updatePrice = (priceId: string, data: any): Observable<any> =>
    this._http.patch<Contract>(
      `${base}/prices/${priceId}/`, data,
      {headers: this.getHeaders()},
    );

  /**
   * Get all rules.
   */
  getAllDynamicPrice = (filter?: Object): Observable<any[]> => {
    return this._http.get<Rule[]>(
      `${base}/pricesDynamic?filter=${JSON.stringify(filter)}`,
      {headers: this.getHeaders()},
    );
  }

  insertDynamicPrice = (data: any): Observable<any> =>
    this._http.post<Contract>(
      `${base}/pricesDynamic/`, data,
      {headers: this.getHeaders()},
    );

  updateDynamicPrice = (priceId: string, data: any): Observable<any> =>
    this._http.patch<Contract>(
      `${base}/pricesDynamic/${priceId}/`, data,
      {headers: this.getHeaders()},
    );

  /**
   * Gets JWT headers from auth service.
   */
  private getHeaders = () => this._auth.getJWTHeaders();

  /**
   * Convert prices to cents.
   */
  private pricesToCents = (rule) => {
    if (rule.childRules && rule.childRules.length) {
      rule.childRules.forEach(this.pricesToCents);
    }

    if (!rule.prices) {
      return;
    }

    for (const key in rule.prices) {
      const price = rule.prices[key];
      const dynamic = price.priceDynamic;
      const hourly = price.priceHourly;
      const fixed = price.priceFixed;

      if (price.minuteWaitingPrice) {
        price.minuteWaitingPrice = toCents(price.minuteWaitingPrice);
      }

      if (dynamic) {
        [
          'dynamicDistancePrice',
          'dynamicMinimumPrice',
          'dynamicMinutePrice',
          'dynamicStartPrice',
        ]
          .filter(propName => !!dynamic[propName])
          .forEach(propName => dynamic[propName] = toCents(dynamic[propName]));

        if (dynamic.thresholds && dynamic.thresholds.length) {
          dynamic.thresholds.forEach(t => t.value = toCents(t.value));
        }
      }

      if (hourly) {
        [
          'hourlyMinimumPrice',
          'hourlyPrice',
          'hourlyStartPrice',
        ]
          .filter(propName => !!hourly[propName])
          .forEach(propName => hourly[propName] = toCents(hourly[propName]));

        if (hourly.thresholds && hourly.thresholds.length) {
          hourly.thresholds.forEach(t => t.value = toCents(t.value));
        }
      }

      if (fixed) {
        if (fixed.thresholds && fixed.thresholds.length) {
          fixed.thresholds.forEach(t => t.value = toCents(t.value));
        }
        fixed.fixedPrice = toCents(fixed.fixedPrice);
        console.log(fixed);
      }
    }
  }
}
