import {Component, Input, OnInit} from '@angular/core';
import {GoogleMapsAPIWrapper} from '@agm/core';

declare var google: any;

@Component({
  selector: 'shift-map',
  templateUrl: './shift-map.component.html',
  styleUrls: ['./shift-map.component.scss']
})
export class ShiftMapComponent implements OnInit {

  @Input() markers: any[];
  mapBounds: any;
  boundsDefined = false;

  constructor(private _gMapsApi: GoogleMapsAPIWrapper) {
  }

  ngOnInit() {
    this._gMapsApi.getNativeMap().then((map) => {
      map.addListener('tilesloaded', () => {
        if (typeof this.markers !== 'undefined' && !this.boundsDefined) {
          this.mapBounds = new google.maps.LatLngBounds();

          this.markers.forEach((marker, index) => {
            this.mapBounds.extend(new google.maps.LatLng(marker.location.gps.lat, marker.location.gps.lng));
          });

          this._gMapsApi.fitBounds(this.mapBounds);
          this._gMapsApi.panToBounds(this.mapBounds);
          this.boundsDefined = true;
        }
      });
    });
  }

}
