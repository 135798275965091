import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {UpsertComponent} from '../../../global/upsert/upsert.component';
import {TdLoadingService} from '@covalent/core/loading';
import {TdDialogService} from '@covalent/core/dialogs';
import {NavigationService} from '../../../../services/navigation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {CompanyService} from '../../../../services/company.service';
import {DomSanitizer} from '@angular/platform-browser';
import {CountryService} from '../../../../services/country.service';
import {CoolLocalStorage} from '@angular-cool/storage';
import {WebbookerService} from '../../../../services/webbooker.service';
import {DaAppInstallService} from '../../../../services/da-app-install.service';
import {CurrencyService} from '../../../../services/currency.service';

@Component({
  selector: 'app-job-process',
  templateUrl: './job-process.component.html',
  styleUrls: ['./job-process.component.scss']
})
export class JobProcessComponent extends UpsertComponent implements OnInit {
  driverCancellationOptions = [{
    value: 'autoapprove',
    name: 'cancellation_setting_auto_approve',
  },
    // {
    //   value: 'wait_for_opperator',
    //   name: 'cancellation_setting_wait_for_opperator',
    // },
    {
      value: 'autoapprove_delay',
      name: 'cancellation_setting_autoapprove_delay',
    }];

  constructor(
    private _loadingService: TdLoadingService,
    private _dialogService: TdDialogService,
    private _navigationService: NavigationService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _translateService: TranslateService,
    private _companyService: CompanyService,
    private _formBuilder: UntypedFormBuilder,
    private _route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private _countryService: CountryService,
    private _vault: CoolLocalStorage,
    private _webbookerService: WebbookerService,
    private _daAppInstallService: DaAppInstallService,
    private _currencyService: CurrencyService) {
    super(_loadingService, _dialogService, _navigationService, _router, _snackBar, _companyService, _translateService, _route);
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);

    const self = this;
    const {company} = this._route.parent.snapshot.data;
    this.data = company;
    this.id = company.id;

    this.currentAction = 'save';
    this.action = 'save';

    if (!this.data.settings.cancellation) {
      this.data.settings.cancellation = {
        driverCancellationFlow: 'autoapprove',
        driverNoShowFlow: 'autoapprove',
        driverUnassignFlow: 'autoapprove'
      };
    }
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      'driverNoShowFlow': [this.data.settings.cancellation.driverNoShowFlow, [Validators.required]],
      'driverNoShowDelay': [(this.data.settings.cancellation.driverNoShowDelay ? this.data.settings.cancellation.driverNoShowDelay : 15), []],
      'driverCancellationFlow': [this.data.settings.cancellation.driverCancellationFlow, [Validators.required]],
      'driverCancellationDelay': [(this.data.settings.cancellation.driverCancellationDelay ? this.data.settings.cancellation.driverCancellationDelay : 15), []],
      'driverUnassignFlow': [this.data.settings.cancellation.driverUnassignFlow, [Validators.required]],
      'driverUnassignDelay': [(this.data.settings.cancellation.driverUnassignDelay ? this.data.settings.cancellation.driverUnassignDelay : 15), []],
    }, {validators: []});
  }

  saveDataMapping(saveData): any {
    delete saveData.companyId;
    delete saveData.driverCancellationFlow;
    delete saveData.driverCancellationDelay;
    delete saveData.driverNoShowFlow;
    delete saveData.driverNoShowDelay;
    delete saveData.driverUnassignFlow;
    delete saveData.driverUnassignDelay;

    saveData.settings.cancellation = this.form.value;

    return saveData;
  }

  saveCancellationSettings(): void {
    this.save();
  }
}
