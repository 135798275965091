import {Location} from '../location';

export class LocationPolicy {
  _id: string;
  name: string;
  departureAllowed: boolean;
  destinationAllowed: boolean;
  departureWarning: string;
  destinationWarning: string;
  companyId?: string;
  created?: string;
  modified?: string;
  numeric?: string;
  daAppInstallIds: string[];
  locations: Location[];

  constructor() {
    this._id = '';
    this.name = '';
    this.departureAllowed = true;
    this.destinationAllowed = true;
    this.departureWarning = 'location_policy_departure_warning';
    this.destinationWarning = 'location_policy_destination_warning';
    this.daAppInstallIds = [];
    this.locations = [];
  }
}
