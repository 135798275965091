import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {NavigationService} from '../../services/navigation.service';
import {ShiftsComponent} from '../shared/shifts/shifts.component';
import {ShiftDetailsComponent} from '../shared/shifts/parts/shift-details/shift-details.component';
import {RidesComponent} from '../shared/rides/rides.component';
import {DispatchpanelComponent} from '../shared/dispatchpanel/dispatchpanel.component';
import {RideDetailsComponent} from './ride-details/ride-details.component';
import {DashboardComponent} from '../dashboard/dashboard.component';
import {RatingsComponent} from './ratings/ratings.component';
import {InvoicesComponent} from './invoices/invoices.component';
import {SettingsComponent} from './invoices/settings/settings.component';
import {AuthGuard} from '../../guards/auth.guard';
import {DebtorListComponent} from '../shared/debtors/debtor-list/debtor-list.component';
import {DebtorDetailsComponent} from '../shared/debtors/debtor-details/debtor-details.component';
import {DebtorUpsertComponent} from '../shared/debtors/debtor-upsert/debtor-upsert.component';
import {PassengerUpsertComponent} from '../shared/debtors/passenger-upsert/passenger-upsert.component';
import {DriverIdsResolver} from '../../resolvers/driver-ids-resolver';
import {RideEditComponent} from '../shared/dispatchpanel/parts/ride-edit/ride-edit.component';
import {TranslateService} from '@ngx-translate/core';
import {DriversComponent} from '../shared/drivers/drivers.component';
import {SplitLayoutComponent} from '../layout/split-layout/split-layout.component';
import {CoolLocalStorage} from '@angular-cool/storage';
import {environment} from '../../../environments/environment';

const navigationRoutes: any[] = [
  {
    canActivate: [AuthGuard],
    path: 'dashboard',
    icon: 'dashboard',
    group: 'general',
    groupSort: 1,
    label: 'menu_dashboard',
    sort: 1,
    showInMenu: true,
    showMobile: true,
    component: DashboardComponent,
    resolve: {
      driverIds: DriverIdsResolver
    },
    context: 'driver'
  },
  {
    canActivate: [AuthGuard],
    path: 'dispatchpanel',
    icon: 'send',
    group: 'general',
    groupSort: 1,
    label: 'menu_dispatchpanel',
    sort: 2,
    showInMenu: true,
    showMobile: true,
    component: DispatchpanelComponent,
    resolve: {
      driverIds: DriverIdsResolver
    },
    context: 'driver'
  },
  {path: 'dispatchpanel/:action', component: RideEditComponent, context: 'driver'},
  {path: 'dispatchpanel/:id/details', component: RideDetailsComponent, context: 'driver'},
  {path: 'dispatchpanel/:id/edit', component: RideEditComponent, context: 'driver'},
  {
    canActivate: [AuthGuard],
    path: 'drivers',
    icon: 'group',
    group: 'general',
    groupSort: 1,
    label: 'drivers',
    sort: 3,
    showInMenu: true,
    component: DriversComponent,
    resolve: {
      driverIds: DriverIdsResolver
    },
    context: 'driver'
  },
  {
    canActivate: [AuthGuard],
    path: 'shifts',
    icon: 'assignment',
    group: 'general',
    groupSort: 1,
    label: 'menu_myshifts',
    sort: 4,
    showInMenu: true,
    component: ShiftsComponent,
    resolve: {
      driverIds: DriverIdsResolver
    },
    context: 'driver'
  },
  {path: 'shifts/:id', component: ShiftDetailsComponent, context: 'driver'},
  {
    canActivate: [AuthGuard],
    path: 'jobs',
    icon: 'local_taxi',
    group: 'general',
    groupSort: 1,
    label: 'menu_myjobs',
    sort: 6,
    showInMenu: true,
    showMobile: true,
    component: RidesComponent,
    resolve: {
      driverIds: DriverIdsResolver
    },
    context: 'driver'
  },
  {path: 'jobs/:action', component: RideEditComponent, context: 'driver'},
  {path: 'jobs/:id/details', component: RideDetailsComponent, context: 'driver'},
  {path: 'jobs/:id/edit', component: RideEditComponent, context: 'driver'},
  {
    canActivate: [AuthGuard],
    path: 'ratings',
    icon: 'favorite',
    group: 'general',
    groupSort: 1,
    label: 'menu_myratings',
    sort: 7,
    showInMenu: true,
    component: RatingsComponent,
    resolve: {
      driverIds: DriverIdsResolver
    },
    context: 'driver'
  },
  {
    canActivate: [AuthGuard],
    path: 'debtors',
    icon: 'business_center',
    group: 'general',
    groupSort: 1,
    label: 'menu_mydebtors',
    sort: 8,
    showInMenu: true,
    showMobile: true,
    component: DebtorListComponent,
    resolve: {
      driverIds: DriverIdsResolver
    },
    context: 'driver'
  },
  {path: 'debtors/:id/view', component: DebtorDetailsComponent, context: 'driver'},
  {path: 'debtors/:debtorId/passengers/:id/:action', component: PassengerUpsertComponent, context: 'driver'},
  {path: 'debtors/:debtorId/passengers/:action', component: PassengerUpsertComponent, context: 'driver'},
  {path: 'debtors/:id/:action', component: DebtorUpsertComponent, context: 'driver'},
  {path: 'debtors/:action', component: DebtorUpsertComponent, context: 'driver'},
  {
    path: 'invoices',
    icon: 'euro_symbol',
    group: 'general',
    groupSort: 1,
    label: 'menu_mysettlements',
    sort: 9,
    showInMenu: true,
    component: InvoicesComponent,
    canActivate: [AuthGuard],
    resolve: {
      driverIds: DriverIdsResolver
    },
    context: 'driver'
  },
  {path: 'settings', component: SettingsComponent, canActivate: [AuthGuard], context: 'driver'}
];

@NgModule({
  imports: [
    RouterModule.forChild(DriverRoutingModule.getInjectRoutes())
  ],
  exports: [
    RouterModule
  ],
  providers: [
    DriverIdsResolver
  ]
})
export class DriverRoutingModule {
  constructor(private _navigationService: NavigationService,
              private _vault: CoolLocalStorage,
              private _translateService: TranslateService) {
    const self = this;

    const operator = this._vault.getItem(`${environment.vaultPrefix}.operator`);
    // const driver = JSON.parse(this._vault.getItem(`${environment.vaultPrefix}.driver`));

    if (!operator) {
      setTimeout(function () {
        _translateService.get(['menu_personal']).subscribe((translations: string[]) => {
          const personal = {
            canActivate: [AuthGuard],
            path: 'personal/dashboard',
            icon: 'person',
            groupSort: 1,
            label: 'menu_personal',
            sort: 1,
            showInMenu: true,
            component: DashboardComponent,
            resolve: {
              driverIds: DriverIdsResolver
            },
            children: navigationRoutes
          };
          const label = translations[personal.label];
          personal.label = label.charAt(0).toUpperCase() + label.slice(1);
          _navigationService.addMainMenuItems([personal]);
        });
        self.createPersonalSubMenu();
      }, 50);
    }
  }

  static getInjectRoutes(): any[] {
    return [
      {
        path: 'personal',
        icon: 'assignment',
        canActivate: [AuthGuard],
        sort: 2,
        showInMenu: false,
        component: SplitLayoutComponent,
        resolve: {
          currentRoute: NavigationService
        },
        children: navigationRoutes
      }
    ];
  }

  createPersonalSubMenu() {
    const self = this;
    setTimeout(function () {
      self._translateService.get([
        'menu_dashboard',
        'menu_myjobs',
        'drivers',
        'menu_myratings',
        'menu_mydebtors',
        'menu_myshifts',
        'menu_dispatchpanel',
        'menu_mysettlements']
      ).subscribe((translations: string[]) => {
        Object.keys(navigationRoutes).forEach((key, i) => {
          if (translations[navigationRoutes[i].label]) {
            const label = translations[navigationRoutes[i].label];
            navigationRoutes[i].label = label.charAt(0).toUpperCase() + label.slice(1);
          }
        });
      });
    }, 50);
  }
}
