import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {DriverService} from '../../services/driver.service';
import {CompanyResolver} from '../../resolvers/company-resolver';
import {CanDeactivateGuard} from '../../guards/can-deactivate-guard';
import {CompanyRoutes} from './company.routes';

const navigationRoutes: any[] = CompanyRoutes.getInjectRoutes();

@NgModule({
  imports: [
    RouterModule.forChild(navigationRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    DriverService,
    CompanyResolver,
    CanDeactivateGuard,
  ]
})
export class CompanyRoutingModule {
}
