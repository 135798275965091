import {Component, Inject, OnInit} from '@angular/core';
import {environment} from '../../../../../../../../../../environments/environment';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Company} from 'app/models/company';
import {PassengerService} from '../../../../../../../../../services/passenger.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {GlobalFunctions} from '../../../../../../../../../functions/functions';

@Component({
  selector: 'app-passenger-add-paymentmethod',
  templateUrl: './passenger-add-paymentmethod.component.html',
  styleUrls: ['./passenger-add-paymentmethod.component.scss'],
  providers: [PassengerService]
})
export class PassengerAddPaymentmethodComponent implements OnInit {
  stripe: any;
  cardForm: FormGroup;
  card: any;
  parent: any;
  company: Company;
  cardHandler: any;
  cardError: string;

  constructor(
    private _passengerService: PassengerService,
    private _formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<PassengerAddPaymentmethodComponent>,
    public _globalFunctions: GlobalFunctions,
    @Inject(MAT_DIALOG_DATA) public dialogData: any
  ) {
    this._globalFunctions.loadScript(`https://js.stripe.com/v3/`, this, 'initStripe');
    if (dialogData && dialogData.parent) {
      this.company = dialogData.company;
      this.parent = dialogData.parent;
    }
  }

  ngOnInit(): void {
    this.cardForm = this._formBuilder.group({
      'name': [null, [Validators.required]],
    });
    const self = this;
    // @ts-ignore
    this.stripe = Stripe(`${environment.stripeKey}`, {
      stripeAccount: this.company.stripeAccountId
    });
    const elements = this.stripe.elements();

    this.card = elements.create('card');
    this.card.mount('#card');

    this.card.addEventListener('change', (event) => {
      var displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
  }

  createToken() {
    const self = this;
    this.stripe.createPaymentMethod({
      type: 'card',
      card: this.card,
    }).then((result) => {
      if (result.error) {
        self.cardError = result.error.message;
      } else {
        this._passengerService.addPaymentMethod(self.parent.passenger.id, result)
          .subscribe((result: any) => {
            // Send the token to your server
            self.parent.retrieveContactPaymentMethods(self.parent.passenger, result.id);
            self.dialogRef.close();
          }, (error) => {
            self.cardError = error.error.error.message;
          });
      }
    });
  };

}
