<ng-container *ngIf="rules.length > 0">
  <div *ngFor="let rule of rules; let i = index;" class="dynamic-field commission-input {{config.cssClass}}"
       layout="row">
    <div class="value push-right">
      <mat-form-field class="full-width">
        <span *ngIf="rules[i].type === 'fixed'" matPrefix>&euro;</span>
        <input [(ngModel)]="rules[i].value"
               (ngModelChange)="valuesChanged(i)"
               matInput
               placeholder="Amount"
               type="number">
        <span *ngIf="rules[i].type === 'percentage'" matSuffix>%</span>
      </mat-form-field>
    </div>
    <div class="type push-right">
      <mat-form-field class="full-width">
        <mat-label>Type</mat-label>
        <mat-select (ngModelChange)="valuesChanged(i)" [(ngModel)]="rules[i].type">
          <ng-container *ngFor="let type of ruleTypes">
            <mat-option [disabled]="!allowedTypes[type.value]" [value]="type.value">
              {{ type.label }}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="tools">
      <a (click)="addRow()" *ngIf="rules.length < 2">
        <mat-icon mat-list-icon>add_circle_outline</mat-icon>
      </a>
      <a (click)="deleteRow(i)">
        <mat-icon mat-list-icon>remove_circle_outline</mat-icon>
      </a>
    </div>
  </div>
</ng-container>
<div *ngIf="rules.length <= 0">
  <td-message color="primary"
              icon="info" label="{{'no_commission_rules'|translate|ucFirst}}"
              sublabel="{{'no_commission_rules_setup'|translate|ucFirst}}">
    <button (click)="addRow()" mat-raised-button td-message-actions>{{'add_new_rule'|translate|ucFirst}}</button>
  </td-message>
</div>

<ng-container *ngIf="debug">
  <div>
    <strong>DEBUG:</strong>
    <pre>{{rules|json}}</pre>
  </div>
  <div *ngFor="let rule of rules; let i = index;">
    <strong>Rule[{{i}}]:</strong>
    <pre>{{rules[i]|json}}</pre>
  </div>
</ng-container>
