<mat-card [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <ng-template tdLoading="rule">

    <mat-card-title *ngIf="form" class="text-truncate">
      {{ form.value.name | ucFirst }}
    </mat-card-title>
    <mat-card-subtitle>
      {{ 'pricing_rule_subtitle' | translate | ucFirst}}
    </mat-card-subtitle>

    <mat-divider></mat-divider>

    <mat-card-content class="push-bottom-none">
      <form *ngIf="form && form.controls && translations" [formGroup]="form">
        <!-- Basic properties -->
        <app-top *ngIf="form" [form]=form></app-top>

        <mat-checkbox *ngIf="(form && form.controls && form.controls['type'].value === 'dynamic')"
                      class="push-bottom"
                      formControlName="taxiMeter"
                      type="checkbox" value="true">
          {{'use_as_taxi_meter' | translate | ucFirst}}
        </mat-checkbox>

        <div class="multiply_link" flex="100">
          <mat-checkbox  *ngIf="(form && form.controls)"
                        class="push-bottom"
                        formControlName="passengerMultiply"
                        (click)="setDefaultPassengerPercentage()"
                        type="checkbox" value="false">
            <div [innerHTML]="this.translations['passenger_multiply']|keepHtml"></div>
          </mat-checkbox>

          <span [innerHTML]="this.translations['passenger_multiply_link']|keepHtml" class="intro-link"></span>
        </div>
        <div *ngIf="form.controls['passengerMultiply'].value && form.controls['passengerPercentage'].value.length > 1">
          <app-pricerule-passenger-multiplier-ratio [form]="form" [ruleId]="this.ruleId"></app-pricerule-passenger-multiplier-ratio>
        </div>

        <mat-divider></mat-divider>


        <div class="push-top"  flex="100">
          <mat-checkbox  *ngIf="(form && form.controls)"
                         class="push-bottom"
                         formControlName="timeLimit"
                         type="checkbox" value="limit">
            <div [innerHTML]="this.translations['price_setting_timelimit']|keepHtml"></div>
          </mat-checkbox>
        </div>
        <div *ngIf="(form && form.controls && (this.form.controls.timeLimit.value === 'limit' || this.form.controls.timeLimit.value))">
          <!-- Timeframe picker -->
          <app-timeframes
            *ngFor="let timeframe of form.controls.timeframes.controls"
            [timeframe]="timeframe">
          </app-timeframes>
        </div>

        <mat-divider></mat-divider>

        <!-- Location pair of Dynamic Rules -->
        <div *ngIf="['dynamic','meter'].includes(form.value.type) &&
        (form && form.controls && form.controls['taxiMeter'].value !== true)"
             class="localion-select location-select">
          <mat-form-field class="mat-flex-field">
            <p [innerHtml]="'pricing_rule_location_select'|translate|ucFirst" class="field-description"></p>
            <mat-select class="row-select"
                        flex="66"
                        formControlName="ruleLocation" required>
              <mat-option value="noLimit">{{'pricing_rule_location_option_no_limit'|translate|ucFirst}}</mat-option>
              <mat-option value="either">{{'pricing_rule_location_option_either_locations'|translate|ucFirst}}</mat-option>
              <mat-option value="and">{{'pricing_rule_location_option_both_locations'|translate|ucFirst}}</mat-option>
            </mat-select>
          </mat-form-field>

          <app-location-picker
            *ngIf="form && form.controls && form.controls['ruleLocation'].value !== 'noLimit'"
            [type]="'dynamic'"
            [allowEmpty]="true"
            [entity]="rule"
            [form]="form">
          </app-location-picker>
        </div>

        <mat-divider></mat-divider>

        <!-- Select SaAppInstalls -->
        <app-pricerule-connected-daapps (updateCheckArrayFixed)="updateCheckArrayFixed($event)" (updateCheckArrayMeter)="updateCheckArrayMeter($event)" *ngIf="form && translations"
                                        [form]="form"
                                        [ruleId]="this.ruleId"
                                        [taxiMeter]="form.controls['taxiMeter'].value"
                                        [translations]="translations">
        </app-pricerule-connected-daapps>

        <mat-divider></mat-divider>

        <!-- Table with columns of pricing data -->
        <app-table
          *ngIf="form.controls.prices && form.controls.prices.length ; else newProduct"
          [form]="form"
          [parent]="this"
          [prices]=form.controls.prices
          [ruleType]=form.value.type>
        </app-table>

        <ng-template #newProduct>
          <div layout="row"
               layout-align="center center"
               style="min-height: 224px;">
            {{ 'add_new_products' | translate | ucFirst}}
          </div>
        </ng-template>
      </form>

    </mat-card-content>

    <mat-divider></mat-divider>

    <mat-card-actions class="action-pane">
      <button (click)="save()" color="accent"
              flex
              mat-raised-button>
        {{ 'save' | translate | ucFirst }}
      </button>

      <button (click)="delete()" *ngIf="ruleId"
              [disabled]="!ruleId"
              color="warn"
              flex
              mat-raised-button>
        {{ 'delete' | translate | ucFirst }}
      </button>
    </mat-card-actions>
  </ng-template>
</mat-card>
