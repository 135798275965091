import {Injectable} from '@angular/core';

@Injectable()
export class Globals {
  widgetFilterValues: any[] = [
    {
      label: '30_days',
      value: 2592000000
    },
    {
      label: '7_days',
      value: 604800000
    },
    {
      label: '24_hours',
      value: 86400000
    }
  ];

  sessionExpiredShown = false;
}
