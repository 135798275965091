import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {RideMapComponent} from './rides/parts/ride-map/ride-map.component';
import {AgmCoreModule} from '@agm/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatToolbarModule} from '@angular/material/toolbar';

import {CovalentDataTableModule} from '@covalent/core/data-table';
import {CovalentMediaModule} from '@covalent/core/media';
import {CovalentPagingModule} from '@covalent/core/paging';
import {CovalentSearchModule} from '@covalent/core/search';
import {CovalentLoadingModule} from '@covalent/core/loading';
import {CovalentLayoutModule} from '@covalent/core/layout';

import {UcFirstPipeModule} from '../../pipes/uc-first.pipe';
import {ReplacePipeModule} from '../../pipes/replace.pipe';
import {RideTimeDataComponent} from './rides/parts/ride-time-data/ride-time-data.component';
import {MomentModule} from 'angular2-moment';
import {RideLocationDataComponent} from './rides/parts/ride-location-data/ride-location-data.component';
import {RidePriceDataComponent} from './rides/parts/ride-price-data/ride-price-data.component';
import {RidePassengerDataComponent} from './rides/parts/ride-passenger-data/ride-passenger-data.component';
import {RidePaymentDataComponent} from './rides/parts/ride-payment-data/ride-payment-data.component';
import {RideDriverDataComponent} from './rides/parts/ride-driver-data/ride-driver-data.component';
import {RideVehicleDataComponent} from './rides/parts/ride-vehicle-data/ride-vehicle-data.component';
import {RideDetailsDataComponent} from './rides/parts/ride-details-data/ride-details-data.component';
import {OpenInvoicesComponent} from './invoices/open-invoices/open-invoices.component';
import {InvoiceHistoryComponent} from './invoices/invoice-history/invoice-history.component';
import {InvoiceFilterComponent} from './invoices/invoice-filter/invoice-filter.component';
import {RideDispatcherDataComponent} from './rides/parts/ride-dispatcher-data/ride-dispatcher-data.component';
import {RideDebtorDataComponent} from './rides/parts/ride-debtor-data/ride-debtor-data.component';
import {RideCommissionDataComponent} from './rides/parts/ride-commission-data/ride-commission-data.component';
import {KeepHtmlPipeModule} from '../../pipes/keep-html.pipe';
import {LocalizedCurrencyPipeModule} from '../../pipes/localized-currency.pipe';
import {DebtorDetailsComponent} from './debtors/debtor-details/debtor-details.component';
import {DebtorUpsertComponent} from './debtors/debtor-upsert/debtor-upsert.component';
import {PassengerUpsertComponent} from './debtors/passenger-upsert/passenger-upsert.component';
import {PassengerListComponent} from './debtors/passenger-list/passenger-list.component';
import {DebtorListComponent} from './debtors/debtor-list/debtor-list.component';
import {DebtorService} from '../../services/debtor.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DriverMapComponent} from './drivers/parts/driver-map/driver-map.component';
import {RideEditOwnerComponent} from './dispatchpanel/parts/ride-edit/parts/ride-edit-owner/ride-edit-owner.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgxIntlTelInputModule} from 'ngx-intl-tel-input';

import {NoContractComponent} from '../company/no-contract/no-contract.component';
import {OrderNowComponent} from '../company/cloud-connect/parts/order-now/order-now.component';
import {SuspendedComponent} from '../company/cloud-connect/parts/suspended/suspended.component';
import {LicensesComponent} from '../company/licenses/licenses.component';
import {NoContractAdminComponent} from '../company/cloud-connect/parts/no-contract-admin/no-contract-admin.component';
import {BillingHistoryComponent} from '../company/cloud-connect/parts/billing-history/billing-history.component';
import {RideEditAssignComponent} from './dispatchpanel/parts/ride-edit/parts/ride-edit-assign/ride-edit-assign.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {AutomaticDispatchComponent} from './dispatchpanel/parts/ride-edit/parts/ride-edit-assign/parts/automatic-dispatch/automatic-dispatch.component';
import {ShareOfferComponent} from './dispatchpanel/parts/ride-edit/parts/ride-edit-assign/parts/share-offer/share-offer.component';
import {ActiveAutomaticDispatchComponent} from './dispatchpanel/parts/ride-edit/parts/ride-edit-assign/parts/active-automatic-dispatch/active-automatic-dispatch.component';
import {RouterModule} from '@angular/router';
import {MultiActionComponent} from './dispatchpanel/parts/multi-action/multi-action.component';
import {RideEditProductComponent} from './dispatchpanel/parts/ride-edit/parts/ride-edit-product/ride-edit-product.component';
import {RideDocumentsComponent} from './rides/parts/ride-documents/ride-documents.component';
import {ToBeInvoicedComponent} from './invoices/to-be-invoiced/to-be-invoiced.component';
import {CreateDraftInvoicesComponent} from './invoices/to-be-invoiced/parts/create-draft-invoices/create-draft-invoices.component';
import {ToBeInvoicedFilterComponent} from './invoices/to-be-invoiced/parts/to-be-invoiced-filter/to-be-invoiced-filter.component';
import {FinalizeDialogComponent} from './invoices/open-invoices/parts/finalize-dialog/finalize-dialog.component';
import {ResendInvoicesDialogComponent} from './invoices/open-invoices/parts/resend-invoices-dialog/resend-invoices-dialog.component';
import {DebtorAddPassengerComponent} from './debtors/debtor-add-passenger/debtor-add-passenger.component';
import {DebtorUpdatePassengerComponent} from './debtors/debtor-update-passenger/debtor-update-passenger.component';
import {MatMenuModule} from '@angular/material/menu';
import {ContactUpsertPaneComponent} from './debtors/contact-upsert-pane/contact-upsert-pane.component';
import {ContactDetailsComponent} from './debtors/contact-details/contact-details.component';
import {DispatchpanelActionsComponent} from './dispatchpanel/parts/dispatchpanel-actions/dispatchpanel-actions.component';
import {DispatchpanelDetailsComponent} from './dispatchpanel/parts/dispatchpanel-details/dispatchpanel-details.component';
import {LicenseOverviewComponent} from '../company/licenses/parts/license-overview/license-overview.component';
import {RideEditMetaComponent} from './dispatchpanel/parts/ride-edit/parts/ride-edit-meta/ride-edit-meta.component';
import {PassengerPaymentmethodsComponent} from './dispatchpanel/parts/ride-edit/parts/ride-edit-passenger/parts/passenger-paymentmethods/passenger-paymentmethods.component';
import {PassengerAddPaymentmethodComponent} from './dispatchpanel/parts/ride-edit/parts/ride-edit-passenger/parts/passenger-add-paymentmethod/passenger-add-paymentmethod.component';
import {PaymentBalanceComponent} from '../company/administration/rides/ride-details/parts/payment-balance/payment-balance.component';
import {
  PassengerConfirmAddContactDialogComponent
} from './dispatchpanel/parts/ride-edit/parts/ride-edit-passenger/parts/passenger-confirm-add-contact-dialog/passenger-confirm-add-contact-dialog.component';
import {RideFilterComponent} from './rides/parts/filter/ride-filter.component';
import {EditPaymentMomentComponent} from './dispatchpanel/parts/ride-edit/parts/ride-edit-price/parts/edit-payment-moment/edit-payment-moment.component';
import {SavePassengerAsContactDialogComponent} from './dispatchpanel/parts/save-passenger-as-contact-dialog/save-passenger-as-contact-dialog.component';
import {PassengerService} from '../../services/passenger.service';
import { EditInviteDialogComponent } from './drivers/parts/edit-invite-dialog/edit-invite-dialog.component';
import {RideMessagelogDataComponent} from './rides/parts/ride-messagelog-data/ride-messagelog-data.component';
import { ForwardRideComponent } from './dispatchpanel/parts/ride-edit/parts/forward-ride/forward-ride.component';
import { MessageLogEmailDialogComponent } from './rides/parts/ride-messagelog-data/parts/message-log-email-dialog/message-log-email-dialog.component';
import { DispatchpanelCancelJobDialogComponent } from './dispatchpanel/parts/dispatchpanel-actions/parts/dispatchpanel-cancel-job-dialog/dispatchpanel-cancel-job-dialog.component';
import { InvoiceSpecificationComponent } from './invoices/invoice-specification/invoice-specification/invoice-specification.component';
import { RideDetailsDialogComponent } from './rides/parts/ride-details-dialog/ride-details-dialog.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'https://localization-api.dispatchapi.io/api/Projects/591afd8a17f51db67fc57968/', '');
}

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    MatListModule,
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatButtonModule,
    MatTabsModule,
    CovalentMediaModule,
    CovalentDataTableModule,
    CovalentPagingModule,
    CovalentSearchModule,
    CovalentLoadingModule,
    CovalentLayoutModule,
    UcFirstPipeModule,
    ReplacePipeModule,
    KeepHtmlPipeModule,
    LocalizedCurrencyPipeModule,
    AgmCoreModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    MatTooltipModule,
    NgxIntlTelInputModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    RouterModule,
    MatMenuModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatSidenavModule,
    MatSnackBarModule,
    MatToolbarModule
  ],
  declarations: [
    RideMapComponent,
    RidePassengerDataComponent,
    RideTimeDataComponent,
    RideLocationDataComponent,
    RidePriceDataComponent,
    RidePaymentDataComponent,
    RideDriverDataComponent,
    RideVehicleDataComponent,
    RideDetailsDataComponent,
    RideMessagelogDataComponent,
    RideDispatcherDataComponent,
    RideDebtorDataComponent,
    RideCommissionDataComponent,
    OpenInvoicesComponent,
    InvoiceHistoryComponent,
    InvoiceFilterComponent,
    DebtorListComponent,
    DebtorDetailsComponent,
    DebtorUpsertComponent,
    PassengerUpsertComponent,
    PassengerListComponent,
    DriverMapComponent,
    RideEditOwnerComponent,
    NoContractComponent,
    OrderNowComponent,
    SuspendedComponent,
    NoContractAdminComponent,
    BillingHistoryComponent,
    RideEditAssignComponent,
    AutomaticDispatchComponent,
    ShareOfferComponent,
    ActiveAutomaticDispatchComponent,
    MultiActionComponent,
    RideEditProductComponent,
    RideDocumentsComponent,
    ToBeInvoicedComponent,
    CreateDraftInvoicesComponent,
    ToBeInvoicedFilterComponent,
    FinalizeDialogComponent,
    ResendInvoicesDialogComponent,
    DebtorAddPassengerComponent,
    DebtorUpdatePassengerComponent,
    ContactUpsertPaneComponent,
    ContactDetailsComponent,
    DispatchpanelActionsComponent,
    DispatchpanelDetailsComponent,
    LicensesComponent,
    LicenseOverviewComponent,
    RideEditMetaComponent,
    PassengerPaymentmethodsComponent,
    PassengerAddPaymentmethodComponent,
    PaymentBalanceComponent,
    PassengerConfirmAddContactDialogComponent,
    RideFilterComponent,
    EditPaymentMomentComponent,
    SavePassengerAsContactDialogComponent,
    EditInviteDialogComponent,
    ForwardRideComponent,
    MessageLogEmailDialogComponent,
    DispatchpanelCancelJobDialogComponent,
    InvoiceSpecificationComponent,
    RideDetailsDialogComponent
  ],
  exports: [
    RideMapComponent,
    RidePassengerDataComponent,
    RideTimeDataComponent,
    RideLocationDataComponent,
    RidePriceDataComponent,
    RidePaymentDataComponent,
    RideDriverDataComponent,
    RideVehicleDataComponent,
    RideDetailsDataComponent,
    RideDispatcherDataComponent,
    RideDebtorDataComponent,
    RideCommissionDataComponent,
    OpenInvoicesComponent,
    InvoiceHistoryComponent,
    InvoiceFilterComponent,
    DebtorListComponent,
    DebtorDetailsComponent,
    DebtorUpsertComponent,
    PassengerUpsertComponent,
    PassengerListComponent,
    RideEditOwnerComponent,
    NoContractComponent,
    OrderNowComponent,
    SuspendedComponent,
    NoContractAdminComponent,
    BillingHistoryComponent,
    RideEditAssignComponent,
    RideEditProductComponent,
    RideDocumentsComponent,
    ToBeInvoicedComponent,
    DispatchpanelActionsComponent,
    DispatchpanelDetailsComponent,
    ContactUpsertPaneComponent,
    LicensesComponent,
    LicenseOverviewComponent,
    RideEditMetaComponent,
    PassengerPaymentmethodsComponent,
    PaymentBalanceComponent,
    RideFilterComponent,
    EditPaymentMomentComponent,
    RideMessagelogDataComponent
  ],
  providers: [
    DebtorService,
    PassengerService,
  ]
})
export class SharedModule {
}
