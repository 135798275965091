import {Component, OnInit} from '@angular/core';
import {Coupon} from '../../../../../../models/coupon';
import {ucFirst} from '../../../../../../pipes/uc-first.pipe';
import {environment} from '../../../../../../../environments/environment';
import {getCurrencySymbol} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../../../../../services/navigation.service';
import {Title} from '@angular/platform-browser';
import {CouponService} from '../../../../../../services/coupon.service';
import {ITdDataTableSortChangeEvent, TdDataTableSortingOrder} from '@covalent/core/data-table';
import {IPageChangeEvent} from '@covalent/core/paging';
import {InvoiceFilter} from '../../../../../shared/invoices/invoice-filter';
import {TdLoadingService} from '@covalent/core/loading';
import {RideService} from '../../../../../../services/ride.service';
import {Ride} from '../../../../../../models/ride';

@Component({
  selector: 'app-coupon-details',
  templateUrl: './coupon-details.component.html',
  styleUrls: ['./coupon-details.component.scss'],
  providers: [RideService]
})
export class CouponDetailsComponent implements OnInit {
  list = 'coupons';
  currency: string;
  companyId: string;
  id: string;
  context: string;
  currencySymbol: string;
  translations: string[];

  data: any[] = [];
  coupon: any = {};
  filteredData: any[] = [];
  filteredTotal: number;
  searchTerm = '';
  fromRow = 1;
  currentPage = 1;
  pageSize = 20;
  sortBy = 'created';
  sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;
  filterData: InvoiceFilter;

  columns = [
    {name: 'requestedDate', label: 'redemptiondate', sortable: true, active: true},
    {name: 'name', label: 'name', sortable: true, active: false},
    {name: 'phoneNumber', label: 'phonenumber', sortable: true, active: false},
    {name: 'email', label: 'emailaddress', sortable: true, active: false},
    {name: 'balance', label: 'balance', sortable: true, active: false},
    {name: 'discount', label: 'discount', sortable: true, active: false},
    {name: 'jobReference', label: 'reference', sortable: true, active: false},
  ];

  constructor(
    private _route: ActivatedRoute,
    private _translateService: TranslateService,
    private _titleService: Title,
    private _navigationService: NavigationService,
    private _loadingService: TdLoadingService,
    private _couponService: CouponService,
    private _rideService: RideService
  ) {
    const self = this;
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);

    const {company} = this._route.parent.snapshot.data;
    this.currency = company.currency;
    self._loadingService.register('redemption.list');

    this._route.parent.params.subscribe(parentParams => {
      this.companyId = parentParams['id'];
      this._route.params.subscribe(params => {
        this.id = params['id'];

        _translateService.get([
          'coupons',
          'coupon_details',
          'coupon_added_message',
          'coupon_added_title',
          'coupon_updated_message',
          'delete_x',
          'message_delete_x',
          'x_deleted'
        ]).subscribe((translations: any) => {
          self.translations = translations;

          self._navigationService.setSplitLayoutButtons([
            {
              'icon': 'list',
              'tooltip': self.translations['coupons'],
              'link': `/groups/${self.companyId}/${self.list}/`
            }
          ]);

          self._titleService.setTitle(ucFirst(translations[`coupon_details`]) + environment.windowTitleSuffix);
          self._navigationService.setBodyTitle(ucFirst(translations[`coupon_details`]));

          self._navigationService.setSplitLayoutButtons([
            {
              'icon': 'list',
              'tooltip': this.translations['jobs'],
              'link': `/groups/${this.companyId}/coupons/`
            },
            {
              'icon': 'edit',
              'tooltip': this.translations['edit'],
              'link': `/groups/${this.companyId}/coupons/${this.id}/edit`
            }
          ]);
        });

        self.loadData();
      });
    });

    if (self.context === 'company') {
      self.currencySymbol = getCurrencySymbol(company.currency, 'narrow');
    } else {
      self.currencySymbol = getCurrencySymbol('EUR', 'narrow');
    }
  }

  ngOnInit(): void {
  }

  loadData(): void {
    const self = this;
    self._couponService.get(self.id, {}).subscribe((coupon: Coupon) => {
      self.coupon = coupon;

      if (self.coupon.discountType !== 'balance') {
        // @ts-ignore
        delete self.columns.splice(4, 1);
      }

      self.loadRedemptions();
    }, error => {
      console.error(error);
    })
  }


  loadRedemptions(): void {
    const self = this;
    this._loadingService.resolve('redemption.list');
    this._rideService.getAll({
      'where': {
        'couponId': self.id,
        or: [{'status': 'completed', 'paymentMeta.moment': 'afterRide'}, {'paymentMeta.moment': 'beforeRide', 'status': {'nin': ['canceled']}}]
      }
    }).subscribe((rides: Ride[]) => {
      // const redemptions = [ ];
      const redemptions = rides.map((r) => {
        return {
          jobReference: r.reference,
          requestedDate: r.requestedDate,
          name: (r.passenger ? `${r.passenger.fname} ${r.passenger.lname}` : ''),
          email: (r.passenger ? r.passenger.email : ''),
          phoneNumber: (r.passenger ? r.passenger.phoneNumber : ''),
          discount: (r.price && r.price.breakdown && r.price.breakdown.discount ? r.price.breakdown.discount.total : 0),
          redemptions: 1,
          balance: 0
        };
      });

      if (self.coupon.customers) {
        if (redemptions) {
          redemptions.forEach((r, i) => {
            const foundCustomer = self.coupon.customers.findIndex((red) => {
              return (red.email === r.email || red.phoneNumber === r.phoneNumber)
            });

            if (foundCustomer !== -1) {
              redemptions[i].balance = self.coupon.customers[foundCustomer].balance;
            }
          });
        }
      }

      // if (ridesMap) {
      //   ridesMap.forEach((r) => {
      //     const foundCustomer = redemptions.findIndex((red) => {
      //       return (red.email === r.email || red.phoneNumber === r.phoneNumber)
      //     });
      //
      //     if (foundCustomer === -1) {
      //       redemptions.push(r);
      //     } else {
      //       redemptions[foundCustomer].redemptions = redemptions[foundCustomer].redemptions + 1;
      //     }
      //   })
      // }

      this.filteredData = redemptions;
    }, error => {
      console.error(error);
    })
  }

  sort(sortEvent: ITdDataTableSortChangeEvent) {
    this.sortBy = sortEvent.name;
    this.sortOrder = sortEvent.order;
  }

  search(searchTerm: string) {
    if (searchTerm === this.searchTerm) {
      return;
    }
    this.searchTerm = searchTerm;
  }

  page(pagingEvent: IPageChangeEvent) {
    this.fromRow = pagingEvent.fromRow;
    this.currentPage = pagingEvent.page;
    this.pageSize = pagingEvent.pageSize;
  }
}
