import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MessageCenterAction, MessageCenterActionMessage} from '../../../../../../../../models/message-center';
import {Company} from '../../../../../../../../models/company';
import {TdDialogService} from '@covalent/core/dialogs';
import {MessageRuleActionEditDialogSmsComponent} from './parts/message-rule-action-edit-dialog-sms/message-rule-action-edit-dialog-sms.component';
import {MessageRuleActionEditDialogEmailComponent} from './parts/message-rule-action-edit-dialog-email/message-rule-action-edit-dialog-email.component';
import {CountryService} from '../../../../../../../../services/country.service';
import {TranslateService} from '@ngx-translate/core';
import {MessageRuleActionAddNewDialogComponent} from './parts/message-rule-action-add-new-dialog/message-rule-action-add-new-dialog.component';

@Component({
  selector: 'app-message-rule-actions-upsert',
  templateUrl: './message-rule-actions-upsert.component.html',
  styleUrls: ['./message-rule-actions-upsert.component.scss']
})
export class MessageRuleActionsUpsertComponent implements OnInit, OnChanges {
  @Input() form;
  @Input() actions = [];
  @Input() languages = [];
  @Input() company: Company = {};
  maxText = 160;
  initMaxText = 160;
  remainingText = [];
  currentLength = [];
  countries = [];
  hasEmailAction = false;
  hasEnglishEmail = false;
  hasSmsAction = false;
  hasEnglishSms = false;
  _localactions = [];
  availableActions = [
    'email',
    'sms'
  ];
  matchTypes = [
    'fullmatch',
    'partial',
    'startsWith',
    'endsWith',
    'before',
    'after'
  ];

  jobStatusses = [
    'unassigned',
    'assigned',
    'driving_to_pickup',
    'started',
    'arrived_at_destination',
    'completed',
    'canceled',
  ];
  protected readonly Math = Math;
  translations = [];
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _dialogService: TdDialogService,
    private _countryService: CountryService,
    private _translationService: TranslateService,
  ) {
    const self = this;
    self._countryService.getAll({'order': 'name ASC'}).subscribe((countries) => {
      self.countries = countries;
    });

    _translationService.get(['yes', 'ok', 'no', 'confirm_delete_message_action']).subscribe((translations) => {
      this.translations = translations;
    });
  }

  ngOnInit(): void {
  }

  loadData(): void {
    if (!this.actions || this.actions.length === 0) {
      this.setupActionRow();
    } else {
      this.actions.forEach((r) => {
        this.setupActionRow(r);
      })
    }
    this.form.controls['actions'].updateValueAndValidity();

    // this.languages.forEach((l) => {
    //   this.form.controls.actions.controls[0].controls.messages.controls.push((this._formBuilder.group({
    //     language: [l.short, [Validators.required]],
    //     message: ['', []],
    //   })));
    // });
  }

  currentActions() {
    return this.form.controls['actions'].controls;
  }

  ngOnChanges() {
    if (JSON.stringify(this._localactions) !== JSON.stringify(this.actions)) {
      this.form.controls['actions'].setValue([]);
      this.loadData();
      this._localactions = this.actions;
    } else if (!this.actions || this.actions.length === 0) {
      this.loadData();
    }
  }

  changeSelectionMatchKey($event, index) {
    console.log(this.form.controls['actions'].controls[index]);
    console.log(this.form.value);
  }

  setupActionRow(action: MessageCenterAction = null) {
    const messages = [];
    if (action && action.messages) {
      this.hasEnglishEmail = false;
      this.hasEnglishSms = false;
      action.messages.forEach((r: MessageCenterActionMessage) => {
        if (action.actionType === 'sms') {
          this.hasSmsAction = true;
          if (r.language === 'en') {
            this.hasEnglishSms = true;
          }
          messages.push(this._formBuilder.group({
            language: [(r ? r.language : this.company.language), [Validators.required]],
            message: [(r ? r.message : ''), [Validators.required]],
          }));
        } else {
          this.hasEmailAction = true;
          if (r.language === 'en') {
            this.hasEnglishEmail = true;
          }
          messages.push(this._formBuilder.group({
            subject: [(r ? r.subject : ''), [Validators.required]],
            template: [(r ? r.template : 'standard'), [Validators.required]],
            blocks: this._formBuilder.group({
              jobdetails: [(r.blocks ? r.blocks.jobdetails : ''), []],
              passenger: [(r.blocks ? r.blocks.passenger : ''), []],
              driverblock: [(r.blocks ? r.blocks.driverblock : ''), []],
              vehicle: [(r.blocks ? r.blocks.vehicle : ''), []],
            }),
            language: [(r ? r.language : this.company.language), [Validators.required]],
            message: [(r ? r.message : ''), [Validators.required]],
          }));
        }
      });

      this.form.controls['actions'].push(
        this._formBuilder.group({
          actionType: [(action ? action.actionType : 'sms'), [Validators.required]],
          messages: this._formBuilder.array(messages)
        })
      );
    }
  }

  addActionRow(type: string) {
    const messages = [];
    if (type === 'add_sms_action' || type === 'sms') {
      this.hasSmsAction = true;
      this.form.controls['actions'].push(
        this._formBuilder.group({
          actionType: ['sms', [Validators.required]],
          messages: this._formBuilder.array([])
        })
      );
    } else {
      this.hasEmailAction = true;
      this.form.controls['actions'].push(
        this._formBuilder.group({
          actionType: ['email', [Validators.required]],
          messages: this._formBuilder.array([])
        })
      );
    }
    const i = this.form.controls['actions'].controls.length - 1;
    this.addTranslation(i, this.form.controls['actions'].controls[i].value.actionType);
  }

  removeRule(i: number) {
    const self = this;
    self._dialogService.openConfirm({
      'message': self.translations['confirm_delete_message_action'],
      'disableClose': false,
      'acceptButton': self.translations['ok'],
    }).afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          self.form.controls['actions'].controls.splice(i, 1);
          self.form.controls['actions'].updateValueAndValidity();
          self.hasSmsAction = (self.form.controls['actions'].controls.filter((c) => c.value.actionType === 'sms').length > 0) ? true : false;
          self.hasEmailAction = (self.form.controls['actions'].controls.filter((c) => c.value.actionType === 'email').length > 0) ? true : false

          this.form.controls['actions'].updateValueAndValidity();
        }
      });
  }

  valueChange(index, value: any) {
    this.remainingText[index] = this.calculateRemainingText(value.target.value);
    this.currentLength[index] = this.calculateRemainingText(value.target.value);
    return true;
  }

  calculateRemainingText(text) {
    let currentLength = text.length;
    const foundTags = text.match(/({.*?})/g);
    (foundTags ? foundTags : []).forEach((tag) => {
      const splitTag = tag.split('|');
      if (splitTag[1]) {
        // tslint:disable-next-line:radix
        const parsedInt = parseInt(splitTag[1].replace('}', ''));
        if (parsedInt > 0) {
          currentLength = currentLength - tag.length + parsedInt;
        }
      }
    });
    return currentLength;
  }

  formatMention(data) {
    return `{${data.name}}`;
  }

  showPreview(i: number) {

  }

  editTranslation(index: any, translationIndex: number, type: string) {
    const self = this;
    if (type === 'sms') {
      self._dialogService.open(MessageRuleActionEditDialogSmsComponent, {
        height: 'auto',
        maxHeight: '90%',
        data: {
          index,
          translationIndex,
          form: this.form,
          parent: this,
          languages: this.languages,
          company: this.company,
          action: 'edit'
        }
      });
    } else {
      self._dialogService.open(MessageRuleActionEditDialogEmailComponent, {
        height: 'auto',
        maxHeight: '90%',
        data: {
          index,
          translationIndex,
          form: this.form,
          parent: this,
          languages: this.languages,
          company: this.company,
          action: 'edit'
        }
      });
    }
  }

  /**
   * Add a translation for a given index and type.
   *
   * @param index - The index of the translation.
   * @param type - The type of the translation.
   */
  addTranslation(index: any, type: string) {
    const self = this;
    if (type === 'sms') {
      self._dialogService.open(MessageRuleActionEditDialogSmsComponent, {
        height: 'auto',
        maxHeight: '90%',
        disableClose: true,
        data: {
          index,
          parent: this,
          form: this.form,
          languages: this.languages,
          company: this.company,
          action: 'add'
        }
      });
    } else {
      self._dialogService.open(MessageRuleActionEditDialogEmailComponent, {
        disableClose: true,
        data: {
          index,
          parent: this,
          form: this.form,
          languages: this.languages,
          company: this.company,
          action: 'add'
        }
      });
    }
  }

  getCountryFlag(language) {
    if (this.countries.length > 0) {
      const lang = this.languages.filter((l) => {
        return l.short === language
      })[0];
      const found = this.countries.filter((c) => {
        if (lang.flag) {
          return ((lang.flag.toUpperCase() === c.alpha2Code));
        }
        if (lang.country === 'ja') {
          lang.country = 'jp';
        }
        if (lang.country === 'zh') {
          lang.country = 'cn';
        }
        if (lang.country === 'uk') {
          lang.country = 'ua';
        }
        return ((lang.short.toUpperCase() === c.alpha2Code));
      })[0];
      if (found) {
        return found.flag;
      } else {
        return '-';
      }
    }
  }

  addActionRowConfirm() {
    const self = this;
    self._dialogService.open(MessageRuleActionAddNewDialogComponent, {
      disableClose: true,
      width: '400px',
      data: {
        parent: this,
      }
    });
    // addActionRow('sms')
  }
}
