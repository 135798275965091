<mat-card *ngFor="let channel of channels" class="channel">
  <mat-card-title>{{channel.title}}</mat-card-title>
  <mat-card-content>
    <div (click)="openVideo(video)" *ngFor="let video of this.channelVideos[channel.id]" class="video">
      <div>
        <img [src]="video.thumbnails.high.url" alt="..." class="card-img-top">
      </div>
      <div class="video-text">
        <div>
          {{video.title}}
        </div>
        <div class="video-date">
          {{ video.publishedAt | date }}
        </div>
      </div>
    </div>
  </mat-card-content>
</mat-card>
