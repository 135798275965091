import {Injectable} from '@angular/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {Driver} from '../models/driver';
import {HttpClient} from '@angular/common/http';
import {Vehicle} from '../models/vehicle';
import {UtilityService} from './utility.service';
import {EmptyObservable} from 'rxjs/observable/EmptyObservable';
import 'rxjs/add/operator/map'
import {GlobalService} from './global.service';

@Injectable({
  providedIn: 'root'
})
export class DriverService extends GlobalService {
  driverIds: any[];

  constructor(_http: HttpClient, _vault: CoolLocalStorage, _utilityService: UtilityService) {
    super(_http, _vault, _utilityService);
    this.setModel('drivers');
  }

  getScore(id: string, context?: string): Observable<any> {
    return this._http.get<any>(`${environment.apiBaseUrl}/drivers/${id}/DriverScore`, {headers: this.getHeaders(context)});
  }

  getAllDriversIds(driver: Driver): Observable<string[]> {
    const filter = {
      where: {
        phoneNumber: encodeURIComponent(driver.phoneNumber),
      }
    };
    const url = `${environment.apiBaseUrl}/drivers?filter=${JSON.stringify(filter)}`;
    const headers = this.getHeaders('company');
    const call = this._http.get<Driver[]>(url, {headers});
    return call.map(response => {
      return response.map(d => {
        return d.id;
      })
    });
  }

  getCompanies(filter?: any): Observable<any> {
    const user: any = this._vault.getObject(`${environment.vaultPrefix}.user`);

    if (!user) {
      return new EmptyObservable();
    }

    if (filter) {
      if (!filter['where']) {
        filter['where'] = {};
      }
      filter['where'].ownerId = user.id;
      filter['where'].ownerType = 'WebPortalUser';
    } else {
      filter = {
        'where': {
          'userId': user.id,
          'type': {
            'in': ['admin']
          },
          'companyId': {
            'exists': true
          }
        },
        'include': {
          'relation': 'Company',
          'scope': {
            'where': {
              'type': 'business',
            },
          },
        },
      };
    }

    const filterString = JSON.stringify(this._utilityService.restrictCompanyFilter(filter, true));
    return this._http.get(`${environment.apiBaseUrl}/drivers/?filter=${filterString}`, {headers: this.getHeaders()});
  }

  getVehicles(id: string, context?: string): Observable<Vehicle[]> {
    return this._http.get<Vehicle[]>(`${environment.apiBaseUrl}/drivers/${id}/vehicles`, {headers: this.getHeaders(context)});
  }

  sendJobNotification(data: any, context?: string): Observable<any> {
    return this._http.post(`${environment.apiBaseUrl}/drivers/sendJobNotification`, data, {headers: this.getHeaders(context)});
  }
}
