<app-no-contract *ngIf="(this.context==='company' && (!this.company.ActiveContract ||
!['active','past_due', null].includes(this.company.ActiveContract.foreignStatus)))"
                 [companyId]="this.companyId" [contract]="this.company.ActiveContract"></app-no-contract>
<mat-card tdMediaToggle="gt-xs">
  <td-search-box (searchDebounce)="search($event)" [alwaysVisible]="true" class="push-left-sm"
                 placeholder="{{'search_here'|translate|ucFirst}}"></td-search-box>
  <mat-divider></mat-divider>
  <ng-template tdLoading="promotions.list">
    <mat-tab-group
      (selectedTabChange)="onTabChange($event)">
      <mat-tab *ngFor="let type of types" [label]="type">
        <ng-template mat-tab-label>
          <mat-icon>active</mat-icon>
          {{ type | translate | ucFirst }}
        </ng-template>

        <table class="mat-clickable" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="sort($event)"
                *ngFor="let c of columns"
                [active]="(sortBy == c.name)"
                [name]="c.name"
                [numeric]="c.numeric"
                [sortOrder]="(sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
                [sortable]="c.sortable"
                td-data-table-column>
              {{c.label|translate|ucFirst}}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr (click)="openEdit($event, item.id)" *ngFor="let item of this.filteredData[type]"
              class="pointer" td-data-table-row>
            <td *ngFor="let c of columns" [numeric]="item.numeric" td-data-table-cell>
              <span [ngSwitch]="c.name">
                <span *ngSwitchCase="'startDate'"><span
                  *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>
                <span *ngSwitchCase="'endDate'"><span
                  *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>

                <span *ngSwitchCase="'lastPushDate'"><span
                  *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>

                <span *ngSwitchCase="'tools'">
                  <button [matMenuTriggerFor]="menu" mat-icon-button>
                    <mat-icon>more_vert</mat-icon>
                  </button>

                  <mat-menu #menu="matMenu">
                    <button (click)="archive(item.id)" *ngIf="type==='published'" mat-menu-item>
                      <mat-icon>archive</mat-icon>
                      <span>{{'archive'|translate|ucFirst}}</span>
                    </button>
                    <button (click)="publish(item.id)" *ngIf="type==='archived'" mat-menu-item>
                      <mat-icon>publish</mat-icon>
                      <span>{{'publish'|translate|ucFirst}}</span>
                    </button>
                  </mat-menu>
                </span>

                <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span></span>
              </span>
            </td>
          </tr>
          </tbody>
        </table>
        <td-paging-bar #paging (change)="page($event)" [pageSize]="100" [total]="filteredTotal">
          <span hide-xs>{{'rows_p_page'|translate}}:</span>
          {{paging.range}} <span hide-xs>of {{paging.total}}</span>
        </td-paging-bar>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</mat-card>
