import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Company} from '../../../../../../models/company';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MessageAutomationRuleService} from '../../../../../../services/message-automation-rule.service';
import {MessageCenterComponent} from '../../message-center.component';
import {MessageCenter} from '../../../../../../models/message-center';

@Component({
  selector: 'app-add-new-message',
  templateUrl: './add-new-message.component.html',
  styleUrls: ['./add-new-message.component.scss']
})
export class AddNewMessageComponent implements OnInit {
  parent: MessageCenterComponent;
  company: Company;
  selectFromTemplate = false;
  messageTemplates = [];
  constructor(
    private _router: Router,
    private _messageAutomationRuleService: MessageAutomationRuleService,
    public _dialogRef: MatDialogRef<AddNewMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.company = data.company;
    this.parent = data.parent;
    // this.collectionData = data.collectionData;
    console.log(data.collectionData);
  }

  ngOnInit(): void {
    this._messageAutomationRuleService.getAll({'where': {
      public: true
    }}).subscribe((messages) => {
      const query = {
        'where': {
          'companyId': this.company.id
        },
        'order': 'created ASC'
      };

      this._messageAutomationRuleService.getAll(query, 'company').subscribe((myjobAutomationRules: MessageCenter[]) => {
        this.messageTemplates = messages.filter((f) => {
          return !myjobAutomationRules.map((m: any) => {
            return m.templateOriginId;
          }).includes(f.id);
        });
      });
    });
  }

  addNewMessage(custom: string) {
    this._dialogRef.close();
    this._router.navigate([`/groups/${this.company.id}/message-center/JobMessageAutomationRule/add`]);
  }

  addNewMessageFromTemplate(message: any) {
    const self = this;
    const data = {
      _requirements: message._requirements,
      _actions: message._actions,
      companyId: this.company.id,
      templateOriginId: message.id,
      name: message.name,
      status: 'published'
    };
    this._messageAutomationRuleService.insert(data)
      .subscribe((messages) => {
        self._dialogRef.close();
        self.parent.loadData();
      })
  }

  protected readonly RegExp = RegExp;
}
