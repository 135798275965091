<div [mediaClasses]="['push-sm']" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <div flex-gt-sm="50">
    <driver-profile-widget
      [driver]="driver"
      [vehicles]="vehicles"
      subTitle="driver_detail_profile_subtitle"
      title="profile">
    </driver-profile-widget>
    <driver-score-widget
      [driverScore]="driverScore"
      cssClasses="push-top driver-score"
      subTitle="driver_detail_driver_score_subtitle"
      title="driver_score">
    </driver-score-widget>
  </div>
  <div flex-gt-sm="50">
    <app-ics-calender [id]="driverId" [type]="'driver'">
    </app-ics-calender>
    <ratings-widget
      [ratings]="ratings"
      subTitle="driver_detail_ratings_subtitle"
      title="ratings">
    </ratings-widget>
  </div>
</div>
