import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CoolLocalStorage} from '@angular-cool/storage';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {Rating} from '../models/rating';
import {UtilityService} from './utility.service';
import {GlobalService} from './global.service';

@Injectable()
export class RatingService extends GlobalService {
  constructor(_http: HttpClient, _vault: CoolLocalStorage, _utilityService: UtilityService) {
    super(_http, _vault, _utilityService);
    this.setModel('ratings');
  }

  create(rideId: string, rating: Rating, context?: string): Observable<Rating> {
    return this._http.post<Rating>(`${environment.apiBaseUrl}/rides/${rideId}/ratings`, rating, {headers: this.getHeaders(context)});
  }
}
