import {Component, Injector, Input, OnInit} from '@angular/core';
import {CollectionComponent} from '../../../../../global/collection/collection.component';
import {TdMediaService} from '@covalent/core/media';
import {TdDialogService} from '@covalent/core/dialogs';
import {TranslateService} from '@ngx-translate/core';
import {TdLoadingService} from '@covalent/core/loading';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Title} from '@angular/platform-browser';
import {RideService} from '../../../../../../services/ride.service';
import {UtilityService} from '../../../../../../services/utility.service';

@Component({
  selector: 'app-recent-jobs',
  templateUrl: './recent-jobs.component.html',
  styleUrls: ['./recent-jobs.component.scss'],
  providers: [],
})
export class RecentJobsComponent extends CollectionComponent implements OnInit {
  static translations: string[];
  @Input() companyId: string;
  @Input() driverId: string;
  @Input() context: string;
  actionName = 'loadToBeInvoiced';
  modelName = 'rides';
  columns: any[] = [
    {name: 'reference', label: 'reference', sortable: true, active: false, fixedSize: 'w-80', maxSize: null},
    {name: 'requestedDate', label: 'requested_date', sortable: true, active: false, maxSize: null},
    {name: 'passengerCount', label: 'passengers', sortable: true, active: false, maxSize: 'maxSize950'},
    {name: 'departure', label: 'departure', sortable: true, active: false, fixedSize: 'w-200', maxSize: 'max-width-300'},
    {name: 'destination', label: 'destination', sortable: true, active: false, maxSize: 'maxSize1280'}
  ];
  type = 'all';
  paging = {
    'all': {
      fromRow: 0,
      currentPage: 0,
      pageSize: 5
    }
  };
  filteredData = {
    all: [],
    grouped: {},
  }
  selectFilter = {
    where: {
      driverId: '',
      ownerId: '',
      ownerType: 'Company',
      status: 'completed',
    },
    include: [
      'Debtor', 'Passenger'
    ],
    fields: ['id', 'reference', 'price', 'debtorId', 'departure', 'passenger', 'status', 'paymentMeta', 'requestedDate'],
    limit: 100,
  };

  constructor(public media: TdMediaService,
              public _dialogService: TdDialogService,
              public _translateService: TranslateService,
              protected injector: Injector,
              private loadingService: TdLoadingService,
              private _rideService: RideService,
              private vault: CoolLocalStorage,
              private _titleService: Title) {
    super(injector);
    this.modelService = _rideService;
    this.selectFilter.where.ownerId = this.companyId;
    this._route.params.subscribe(params => {
      this.selectFilter.where.driverId = params['id'];
    });
  }

  ngOnDestroy() {
    this._navigationService.setActionLink('');
    this._navigationService.setInfoAction('');
  }

  afterFilter() {
    this.originalData['all'].forEach((item) => {
      item.departure = UtilityService.getLocationString(item.departure);
      item.destination = UtilityService.getLocationString(item.destination);
    });
  }
}
