<mat-card *ngIf="form" [formGroup]="form">
  <mat-card-title>{{'onboard_webbooker_title' | translate | ucFirst}}</mat-card-title>
  <mat-card-subtitle>{{'onboard_webbooker_subtitle' | translate | ucFirst}}</mat-card-subtitle>

  <mat-divider></mat-divider>

  <mat-card-content>
    <p><strong>{{'create_url_title' | translate | ucFirst}}</strong></p>
    <div>{{'create_url_text' | translate | ucFirst}}</div>

    <div class="enter-url">
      <span>{{frontEndUrl}}/</span>
      <div class="domain_url">
        <mat-form-field class="confirmation-field {{(formErrors.webbookerUrl ? 'ng-invalid' : '')}}" flex>
          <input (keydown)="ValidateAllFormFields.noSpecialChars($event)" class="{{(formErrors.webbookerUrl ? 'input-warning' : '')}}" flex="100"
                 formControlName="webbookerUrl" matInput
                 type="text" value="">
          <mat-hint align="start" class="warning">
            <span [hidden]="!formErrors.webbookerUrl" class="tc-red-600">
              <span>{{ formErrors.webbookerUrl|translate }}</span>
            </span>
          </mat-hint>
        </mat-form-field>
      </div>
    </div>

    <mat-divider></mat-divider>

    <div class="timezone-pane" flex="100">
      <mat-form-field>
        <mat-select class="row-select" flex="100" formControlName="timezone"
                    placeholder="{{'timezone'|translate|ucFirst}}"
                    required>
          <mat-option *ngFor="let value of timeZones"
                      [value]="value">{{ value | translate | ucFirst }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-divider></mat-divider>

    <mat-card-actions>
      <div class="progress">0/5 {{'completed'|translate|ucFirst}}</div>

      <button (click)="setStep('skipped')" class="link-button"
              mat-button>{{'setup_later' | translate | ucFirst}}</button>

      <button (click)="setStep('servicearea')" color="primary"
              mat-raised-button>{{'continue' | translate | ucFirst}}</button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
