<ng-template tdLoading="tab.pricing">
  <div *ngIf="app" layout-gt-sm="row">
    <mat-card [mediaClasses]="['push-sm']"
              flex-gt-sm="100"
              tdMediaToggle="gt-xs">
      <mat-card-title class="text-truncate">
        {{ 'pricing_rules' | translate | ucFirst }}
      </mat-card-title>
      <mat-card-subtitle>
        {{ 'toggle_pricing_rules' | translate | ucFirst }}
      </mat-card-subtitle>
      <mat-card-actions>
        <button (click)="openPricingRuleDialog()"
                color="accent"
                mat-raised-button>
          {{ 'choose' | translate | ucFirst }}
          {{ 'pricing_rules' | translate }}
        </button>
      </mat-card-actions>

      <mat-divider></mat-divider>

      <table class="mat-clickable" td-data-table>
        <thead *ngIf="this.pricingRules.length > 0">
        <tr td-data-table-column-row>
          <th *ngFor="let c of ruleColumns | slice:1"
              [name]="c.name"
              td-data-table-column>
            {{ c.label }}
          </th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let item of pricingRules">
          <tr *ngIf="item.isEnabledForApp" td-data-table-row>
            <td *ngFor="let c of ruleColumns | slice:1"
                [numeric]="item.numeric"
                td-data-table-cell>
              <span [ngSwitch]="c.name">

                <span *ngSwitchCase="'isFixed'">
                  {{ isFixedName(item[c.name] || false) | translate | ucFirst }}
                </span>

                <span *ngSwitchCase="'isAllowedOnMeter'">
                  {{ isAllowedOnMeterName(item[c.name] || false) | translate | ucFirst }}
                </span>

                <span *ngSwitchCase="'type'">
                  {{ ruleFrontendName(item[c.name]) | translate | ucFirst }}
                </span>

                <span *ngSwitchDefault>
                    {{item[c.name] | ucFirst}}
                </span>

              </span>
            </td>
          </tr>
        </ng-container>
        </tbody>
        <tbody *ngIf="this.pricingRules.length === 0">
        <tr>
          <td [colSpan]="pricingRules.length"
              class="table-placeholder">{{'no_pricing_rules_selected'|translate}}</td>
        </tr>
        </tbody>
      </table>
    </mat-card>
  </div>

  <div *ngIf="app" layout-gt-sm="row">
    <mat-card [mediaClasses]="['push-sm']"
              flex-gt-sm="100"
              tdMediaToggle="gt-xs">
      <mat-card-title class="text-truncate">
        {{ 'special_rates' | translate | ucFirst }}
      </mat-card-title>
      <mat-card-subtitle>
        {{ 'toggle_special_rates' | translate | ucFirst }}
      </mat-card-subtitle>
      <mat-card-actions>
        <button (click)="openDiscountDialog()"
                color="accent"
                mat-raised-button>
          {{ 'choose' | translate | ucFirst }}
          {{ 'special_rates' | translate }}
        </button>
      </mat-card-actions>

      <mat-divider></mat-divider>
      <table class="mat-clickable" td-data-table>
        <thead *ngIf="this.discounts.length > 0">
        <tr td-data-table-column-row>
          <th *ngFor="let c of discountColumns | slice:1"
              [name]="c.name"
              td-data-table-column>
            {{ c.label }}
          </th>
        </tr>
        </thead>
        <tbody *ngIf="this.discounts.length > 0">
        <ng-container *ngFor="let item of this.discounts">
          <tr *ngIf="item.isEnabledForApp" td-data-table-row>
            <td *ngFor="let c of discountColumns | slice:1" [numeric]="item.numeric" td-data-table-cell>
                <span [ngSwitch]="c.name">
                  <span *ngSwitchDefault>
                    <span *ngIf="item[c.name]">
                      {{item[c.name] | ucFirst}}
                    </span>
                  </span>
                </span>
            </td>
          </tr>
        </ng-container>
        </tbody>
        <tbody *ngIf="this.discounts.length === 0">
        <tr>
          <td [colSpan]="discountColumns.length"
              class="table-placeholder">{{'no_discount_rules_selected'|translate}}</td>
        </tr>
        </tbody>
      </table>
    </mat-card>
  </div>

  <div *ngIf="!app" layout-gt-sm="row">
    <div [mediaClasses]="['push-sm']"
         flex-gt-sm="100"
         tdMediaToggle="gt-xs">
      <mat-card-title class="text-truncate">
        {{ 'notice' | translate | ucFirst }}
      </mat-card-title>
      <mat-card-subtitle>
        {{ 'app_not_linked_to_tps' | translate | ucFirst }}
      </mat-card-subtitle>
    </div>
  </div>

</ng-template>
