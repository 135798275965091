<mat-card [class]="(dialogData ? 'dialogData' : '')" [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <ng-template tdLoading="product">
    <mat-card-title *ngIf=form class="text-truncate">
      {{ (form.value.name || 'new_availability_override') | translate | ucFirst }}
    </mat-card-title>
    <mat-card-subtitle>
      {{ 'availability_override_subtitle' | translate | ucFirst }}
    </mat-card-subtitle>

    <mat-divider></mat-divider>

    <mat-card-content class="push-top" flex="100" layout="table">
      <form *ngIf="form" [formGroup]="form">
        <div flex="100" layout="row">
          <mat-list-item class="push-right" flex="50" flex-gt-sm="50">
            <mat-form-field class="full-width" flex="100">
              <input formControlName="name"
                     matInput
                     placeholder="{{ 'name' | translate | ucFirst }}"
                     type="text">
            </mat-form-field>
          </mat-list-item>
          <mat-form-field class="left-input" flex="50" flex>
            <mat-select class="row-select"
                        formControlName="type"
                        placeholder="{{'type'|translate|ucFirst}}"
                        required>
              <mat-option value="unavailable">{{'unavailable'|translate|ucFirst}}</mat-option>
              <mat-option value="available">{{'available'|translate|ucFirst}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div flex="50" layout="row">
          <mat-form-field [ngStyle]="{'width': '100%'}" class="date-input push-right">
            <input (change)="(formErrors.startDate ? this.validateFormField(this.form,'startDate') : '')"
                   [min]="minDate"
                   formControlName="startDate"
                   matInput
                   placeholder="{{ 'start_date' | translate | ucFirst }}"
                   type="datetime-local">

            <mat-hint align="start" class="warning">
              <span [hidden]="!formErrors.startDate" class="tc-red-600">
                <span>{{ formErrors.startDate }}</span>
              </span>
            </mat-hint>
          </mat-form-field>

          <mat-form-field [ngStyle]="{'width': '100%'}" class="date-input">
            <input (change)="(formErrors.endDate ? this.validateFormField(this.form,'endDate') : '')"
                   [min]="maxDate"
                   formControlName="endDate"
                   matInput
                   placeholder="{{ 'end_date' | translate | ucFirst }}"
                   type="datetime-local">

            <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.endDate" class="tc-red-600">
                  <span>{{ formErrors.endDate }}</span>
                </span>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="push-top description" flex="100" layout="row">
          <mat-list-item flex="100" flex-gt-sm="100">
            <mat-form-field class="full-width" flex="100">
              <textarea flex="100" formControlName="description"
                        matInput
                        placeholder="{{ 'description' | translate | ucFirst }}">
              </textarea>
            </mat-form-field>
          </mat-list-item>
        </div>
      </form>
    </mat-card-content>

    <mat-divider></mat-divider>
    <mat-card-actions>
      <button (click)="dialogRef.close()" *ngIf="dialogData" class="push-right-small" color="accent" mat-button>
        {{'cancel'|translate|ucFirst}}
      </button>

      <button (click)="delete()" *ngIf="this.id"
              class="float-right"
              color="warn"
              flex
              mat-raised-button>
        {{'delete'|translate|ucFirst}}
      </button>

      <button (click)="save()" color="accent"
              flex
              mat-raised-button>
        {{'save'|translate|ucFirst}}
      </button>
    </mat-card-actions>
  </ng-template>
</mat-card>
