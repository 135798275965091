import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../../services/user.service';
import {TdDialogService} from '@covalent/core/dialogs';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-merge-email',
  templateUrl: './merge-email.component.html',
  styleUrls: ['./merge-email.component.scss']
})
export class MergeEmailComponent implements OnInit {
  translations: string[];
  drivers: any[];
  selectedDriver: any;
  subTitle: string;

  constructor(
    private _translationService: TranslateService,
    private _userService: UserService,
    private _dialogService: TdDialogService,
    private _authService: AuthService,
    private _router: Router,
    public dialogRef: MatDialogRef<MergeEmailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.drivers = data.drivers;
    this.selectedDriver = this.drivers[0].userId;

    _translationService.get(['connect_drivers_to_user_title', 'connect_drivers_to_user_success_title', 'connect_drivers_to_user_success_text', 'ok', 'connect_drivers_connectyda_confirmed', 'connect_drivers_to_user_subtitle']).subscribe((translations) => {
      this.translations = translations;
      this.subTitle = translations['connect_drivers_to_user_subtitle'].replace('{emailaddress}', this.data.user.email)
    });
  }

  ngOnInit(): void {

  }

  toggleSelection(item: any): void {
    this.selectedDriver = item;
  }

  confirmConnection(): void {
    const self = this;

    this._userService.connectPortalUser(this.data.user.id, this.selectedDriver)
      .subscribe((result) => {
        this._dialogService.openAlert({
          title: self.translations['connect_drivers_to_user_success_title'],
          message: self.translations['connect_drivers_to_user_success_text'],
          disableClose: true,
          closeButton: self.translations['ok'],
        }).afterClosed().subscribe(() => {
          self.dialogRef.close();
          if (self.data.callback) {
            self.data.callback();
          } else if (self.data.successCallback) {
            self.data.successCallback()
          } else {
            self._authService.login(self.data.credentials, function () {
            });
          }
        });
      });
  }

  rejectConnection(): void {
    const self = this;
    this._userService.rejectPortalUser(this.data.user.id, {connectStatus: 'rejected'})
      .subscribe((result) => {
        self.dialogRef.close();
        if (self.data.callback) {
          self.data.callback();
        } else if (self.data.rejectCallback) {
          self.data.rejectCallback()
        }
      });
  }

  save(selection): void {
    if (selection === 'ignore') {
      this.rejectConnection();
    } else {
      this.confirmConnection();
    }
  }
}
