import {NgModule, Pipe, PipeTransform} from '@angular/core'
import {AbstractControl, ValidationErrors} from '@angular/forms';
import googleLib from 'google-libphonenumber';

export const phoneNumberValidator = (country: string = '') => {
  return (control: AbstractControl): ValidationErrors | null => {
    // tslint:disable-next-line:no-shadowed-variable
    const value = control.value;
    if (!value) {
      return null;
    }
    // @ts-ignore
    const phoneUtil = googleLib.PhoneNumberUtil.getInstance();
    try {
      const number = phoneUtil.parseAndKeepRawInput(value, country);
      if (phoneUtil.isValidNumber(number)) {
        return null;
      } else {
        return {'phoneNumberValidator': true, 'number': number};
      }
    } catch (err) {
      return null;
    }
  }
};

@Pipe({
  name: 'ucFirst'
})
export class PhoneNumberValidatorPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return phoneNumberValidator(value);
  }
}

@NgModule({
  declarations: [PhoneNumberValidatorPipe],
  exports: [PhoneNumberValidatorPipe]
})
export class PhoneNumberValidatorPipeModule {
}

export const freeTrialValidator = (selected: string) => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) {
      return null;
    }

    if (selected === 'createGroup') {
      return null;
    } else {
      return {'freeTrialValidator': true};
    }
  }
}
