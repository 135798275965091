import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ITdDataTableSortChangeEvent, TdDataTableService, TdDataTableSortingOrder} from '@covalent/core/data-table';
import {IPageChangeEvent} from '@covalent/core/paging';
import {TdLoadingService} from '@covalent/core/loading';
import {TdMediaService} from '@covalent/core/media';
import {ShiftService} from '../../../services/shift.service';
import {CoolLocalStorage} from '@angular-cool/storage';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {UtilityService} from '../../../services/utility.service';
import {DriverService} from '../../../services/driver.service';
import {NavigationService} from '../../../services/navigation.service';
import {Company} from '../../../models/company';

@Component({
  selector: 'app-shifts',
  templateUrl: './shifts.component.html',
  styleUrls: ['./shifts.component.scss'],
  providers: [ShiftService]
})
export class ShiftsComponent implements OnInit, AfterViewInit {
  context: string;
  companyId: string;
  company: Company;
  driverId: string;
  translations: string[];
  columns = [
    {name: 'identifier', label: 'id', sortable: true, active: true},
    {name: 'startTime', label: 'start', sortable: true, active: false},
    {name: 'endTime', label: 'end', sortable: true, active: false},
    {name: 'vehicle', label: 'vehicle', sortable: false, active: false},
    {name: 'duration', label: 'duration', sortable: false, active: false},
    {name: 'activityCount', label: 'activities', sortable: false, active: false}
  ];

  driver: any;

  data: any[] = [];
  filteredData: any[] = [];
  filteredTotal: number;
  sortable = true;

  searchTerm = '';
  fromRow = 1;
  currentPage = 1;
  pageSize = 20;
  sortBy = 'identifier';
  sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;

  constructor(public media: TdMediaService,
              private _router: Router,
              private _route: ActivatedRoute,
              private _vault: CoolLocalStorage,
              private _navigationService: NavigationService,
              private _loadingService: TdLoadingService,
              private _dataTableService: TdDataTableService,
              private _shiftService: ShiftService,
              private _driverService: DriverService,
              private _translateService: TranslateService,
              private _titleService: Title) {
    const self = this;
    this.context = this._route.routeConfig['context'] || 'driver';
    this.companyId = this._route.parent.snapshot.paramMap.get('id');
    this._navigationService.setActionLink('');
    const {company} = this._route.parent.snapshot.data;
    UtilityService.setBrowserTimeStamp(this.company);
    this.company = company;

    this._route.params.subscribe(params => {
      self.driverId = params['id'];
      if (self.context === 'company') {
        this._driverService.get(self.driverId, {}).subscribe((cDriver) => {
          if (!this.company.settings.hasWhiteLabelDriver) {
            self._driverService.getAll({'where': {'phoneNumber': encodeURIComponent(cDriver.phoneNumber), 'companyId': null}})
              .subscribe((driver) => {
                if (driver && driver[0]) {
                  self.driver = driver[0];
                  self._loadingService.register('shifts.list');
                  self.loadData();
                }
              });
          } else {
            self.driver = cDriver;
            self.loadData();
          }
        })
      } else {
        this.driver = this._vault.getObject(`${environment.vaultPrefix}.driver`);
        this._loadingService.register('shifts.list');
        this.loadData();
      }
    });

    _translateService.get(['my_shifts', 'driver_shifts', 'company_drivers', 'drivers']).subscribe((translations: any) => {
      this.translations = translations;
      this._titleService.setTitle(translations['driver_shifts'] + environment.windowTitleSuffix);

      if (this.context === 'company') {
        this._navigationService.setSplitLayoutButtons([
          {
            'icon': 'list',
            'tooltip': this.translations['drivers'],
            'link': `/${self.company.type === 'business' ? 'groups' : 'dispatchgroup'}/${self.companyId}/drivers/`
          }
        ]);
      }

      if (this.context === 'company') {
        this._navigationService.setBodyTitle(translations['driver_shifts']);
      } else {
        this._navigationService.setBodyTitle(translations['my_shifts']);
      }
    });
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    this.media.broadcast();
  }

  sort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.sortBy = sortEvent.name;
    this.sortOrder = sortEvent.order;
    if (this.sortBy === 'startTime') {
      this.filteredData = UtilityService.tableDateSort(this.data, 'startTime', sortEvent.order);
    } else if (this.sortBy === 'endTime') {
      this.filteredData = UtilityService.tableDateSort(this.data, 'startTime', sortEvent.order);
    } else {
      this.filter();
    }
  }

  search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.filter();
  }

  page(pagingEvent: IPageChangeEvent): void {
    this.fromRow = pagingEvent.fromRow;
    this.currentPage = pagingEvent.page;
    this.pageSize = pagingEvent.pageSize;
    this.filter();
  }

  filter(): void {
    console.log('------------------------------------------------------------------');
    // console.log(`[ShiftsComponent.filter]: this.searchTerm`, this.searchTerm);
    // console.log(`[ShiftsComponent.filter]: this.sortBy`, this.sortBy);
    // console.log(`[ShiftsComponent.filter]: this.sortOrder`, this.sortOrder);
    // console.log(`[ShiftsComponent.filter]: this.fromRow`, this.fromRow);
    // console.log(`[ShiftsComponent.filter]: this.currentPage`, this.currentPage);
    // console.log(`[ShiftsComponent.filter]: this.pageSize`, this.pageSize);
    // console.log(`[ShiftsComponent.filter]: this.data`, this.data);
    let newData: any[] = this.data;
    newData = this._dataTableService.filterData(newData, this.searchTerm, true);
    this.filteredTotal = newData.length;
    newData = this._dataTableService.sortData(newData, this.sortBy, this.sortOrder);
    newData = this._dataTableService.pageData(newData, this.fromRow, this.currentPage * this.pageSize);
    this.filteredData = newData;
  }

  loadData(): void {
    const filter = {
      'where': {
        'driverId': this.driver.id
      },
      'include': [
        'Vehicle',
        {
          'relation': 'ShiftEntries',
          'scope': {
            'fields': [
              'shiftId'
            ]
          }
        }
      ]
    };
    console.log(filter);
    this._shiftService.get('', filter).subscribe((shifts: any[]) => {
      const data = [];
      shifts.forEach((shift) => {
        if (typeof shift.duration === 'undefined') {
          const start = new Date(shift.startTime).getTime();
          const now = new Date().getTime();
          shift.duration = (now - start);
        }

        if (shift.activityCount) {
          shift.activityCount = shift.ShiftEntries.length;
        }

        data.push(shift);
      });
      this.data = data;
      this.filteredData = data;
      this.filteredTotal = this.filteredData.length;
      this.filter();
      this._loadingService.resolve('shifts.list');
    }, error => {
      console.error(error);
      this._loadingService.resolve('shifts.list');
    })
  }

  open(id): void {
    if (this.context === 'company') {
      this._router.navigate([`/groups/${this.companyId}/drivers/${this.driver.id}/shifts/${id}`]);
    } else {
      this._router.navigate([`/personal/shifts/${id}`]);
    }
  }

}
