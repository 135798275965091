import {Component, Inject, OnInit} from '@angular/core';
import {Company} from '../../../../../../models/company';
import {CompanyService} from '../../../../../../services/company.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ContractService} from '../../../../../../services/contract.service';
import {environment} from '../../../../../../../environments/environment';

@Component({
  selector: 'app-extend-dispatcher-dialog',
  templateUrl: './extend-dispatcher-dialog.component.html',
  styleUrls: ['./extend-dispatcher-dialog.component.scss']
})
export class ExtendDispatcherDialogComponent implements OnInit {
  company: Company;
  translations: string[];
  dispatcherStatus: string;
  extendedDispatcherSubId: string;
  dispatchers: string[];
  dispatcherAmount: any;

  constructor(
    private _companyService: CompanyService,
    private _contractService: ContractService,
    private _snackbar: MatSnackBar,
    private _translateService: TranslateService,
    public dialogRef: MatDialogRef<ExtendDispatcherDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.company = data.parent.company;
    this.translations = data.parent.translations;
    this.dispatcherStatus = 'false';

    const extendedDispatcher = this.company.ActiveContract.ContractProducts.filter((item) => {
      return (item.sku === 'extended_dispatcher');
    })[0];

    if (extendedDispatcher) {
      this.extendedDispatcherSubId = extendedDispatcher.subscriptionItemId;
    } else {
      _companyService.getExtendedDispatchers(this.company.id).subscribe((drivers) => {
        this.dispatchers = drivers;
        this.dispatcherAmount = {
          'total': this.dispatchers.length * 1500,
          'currency': 'EUR'
        }
      });
    }
  }

  ngOnInit(): void {
  }

  closeDialog() {
    this.dialogRef.close();
  }

  addExtendedDispatchStatus(): void {
    const self = this;
    const googleKey = {
      active: true
    }

    this.data.parent.loadingService.register('upcommingInvoice');
    this.data.parent.loadingService.register('billingHistory');
    this.data.parent.loadingService.register('contract.products');
    this.data.parent.loadingService.register('contract.cloudConnectUsage');

    self.closeDialog();

    this._contractService.addProduct(this.company.ActiveContract.id, {
      'sku': 'extended_dispatcher',
      'stripePriceId': environment.extendedDispatcherPriceId
    }).subscribe((result) => {
      if (result.error) {
        this._snackbar.open(this.translations['cloud_connect_googlekey_error'], '', {duration: 3000});
      } else {
        this._snackbar.open(this.translations['cloud_connect_extended_dispatcher_activated'], '', {duration: 3000});
        self.data.parent.setSubscriptionItemId(true);
        setTimeout(function () {
          self.data.parent.loadData();
        }, 2500);
      }
    })
    return;
  }

  removeExtendedDispatchStatus(subscriptionItemId): void {
    const self = this;
    this.data.parent.loadingService.register('upcommingInvoice');
    this.data.parent.loadingService.register('billingHistory');
    this.data.parent.loadingService.register('contract.products');
    this.data.parent.loadingService.register('contract.cloudConnectUsage');

    self.closeDialog();

    this._contractService.removeProduct(this.company.ActiveContract.id, {
      'sku': 'extended_dispatcher',
      'subscriptionItemId': subscriptionItemId
    }).subscribe((result) => {
      if (result.error) {
        this._snackbar.open(this.translations['cloud_connect_googlekey_error'], '', {duration: 3000});
      } else {
        this._snackbar.open(this.translations['cloud_connect_extended_dispatcher_deactivated'], '', {duration: 3000});
        self.data.parent.setSubscriptionItemId(false);
        self.data.parent.loadData();
      }
    })
    return;
  }
}
