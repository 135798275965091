<ng-container *ngIf="policies">
  <div class="tab-content">
    <h4 class="push-top-none push-bottom-none">{{'location_policy_settings'|translate|ucFirst}}</h4>
    <p>{{'location_policy_settings_text'|translate}}</p>
    <mat-divider></mat-divider>
    <div class="push-top-lg">
      <p *ngFor="let policy of policies">
        <mat-checkbox
          (ngModelChange)="togglePolicy(policy._id)"
          [(ngModel)]="policyStates[policy._id]">
          {{policy.name}}
        </mat-checkbox>
      </p>
    </div>
    <div *ngIf="policies.length === 0" class="table-placeholder">
      {{'no_policies_warning'|translate|ucFirst}}
      <a (click)="goToPolicy()" class="yda-link-blue">{{'click_here'|translate|ucFirst}}</a>
      {{'no_policies_warning_2'|translate|ucFirst}}
    </div>
  </div>
</ng-container>
