import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TdDialogService} from '@covalent/core/dialogs';
import {TdLoadingService} from '@covalent/core/loading';
import {TdMediaService} from '@covalent/core/media';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../../../environments/environment';
import {DriverService} from '../../../../services/driver.service';
import {Driver} from '../../../../models/driver';
import {ActivatedRoute, Router} from '@angular/router';
import {NavigationService} from '../../../../services/navigation.service';
import {CompanyInvitationService} from '../../../../services/company-invitation.service';
import {Company} from '../../../../models/company';
import {ValidateAllFormFields} from '../../../../functions/validateAllFormFields';
import {phoneNumberValidator} from '../../../../pipes/phonenumber-validator.pipe';
import {CountryISO, SearchCountryField,} from 'ngx-intl-tel-input';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from '../../../../services/utility.service';

@Component({
  selector: 'app-driver-upsert',
  templateUrl: './driver-upsert.component.html',
  styleUrls: ['./driver-upsert.component.scss'],
  providers: [TdDialogService]
})
export class DriverUpsertComponent extends ValidateAllFormFields implements OnInit, OnDestroy, AfterViewInit {
  driverId: string;
  action: string;
  driver: Driver = new Driver();
  company: Company;
  companyId: string;
  translations: string[];
  form: UntypedFormGroup;

  SearchCountryField = SearchCountryField;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom
  ];
  driverTypes: object[] = [
    {
      'label': 'Driver',
      'value': 'driver'
    },
    {
      'label': 'Operator',
      'value': 'operator'
    },
    {
      'label': 'Admin',
      'value': 'admin'
    }
  ];
  driverStates: object[] = [
    {
      'label': 'Accepted',
      'value': 'accepted'
    },
    {
      'label': 'Blocked',
      'value': 'blocked'
    }
  ];

  disabledOnEdit = ['emailAddress', 'phoneNumber'];

  sub: any;
  parentSub: any;
  formErrors: any = {};
  validationMessages = {
    'fname': {
      'fieldName': 'first_name',
      'required': 'form_validation_required',
    },
    'lname': {
      'fieldName': 'last_name',
      'required': 'form_validation_required',
    },
    'emailAddress': {
      'fieldName': 'email',
      'required': 'form_validation_required',
    },
    'phoneNumber': {
      'fieldName': 'phonenumber',
      'required': 'form_validation_required',
      'validatePhoneNumber': 'phonenumber_pattern_invalid',
    },
    'type': {
      'fieldName': 'type',
      'required': 'form_validation_required',
    }
  };

  constructor(public snackBar: MatSnackBar,
              public media: TdMediaService,
              private _fb: UntypedFormBuilder,
              private _route: ActivatedRoute,
              private _router: Router,
              private _vault: CoolLocalStorage,
              private _loadingService: TdLoadingService,
              private _dialogService: TdDialogService,
              private _driverService: DriverService,
              private _companyInvitationService: CompanyInvitationService,
              private _translateService: TranslateService,
              private _navigationService: NavigationService,
              private _titleService: Title) {
    super();
    const self = this;
    this.loadErrorTranslations(_translateService);

    _translateService.get(['add_driver_title', 'driver_invited', 'edit_driver_title', 'driver_invited_exists', 'driver_invited_exists_title', 'phonenumber_pattern_invalid', 'driver_invited_error_company_duplicate', 'driver_invite_error']).subscribe((translations: string[]) => {
      this.translations = translations;

      const {company} = this._route.parent.snapshot.data;
      UtilityService.setBrowserTimeStamp(company);
      this.company = company;
      this.company.country = (this.company.country === 'EN' ? 'GB' : this.company.country);

      this._navigationService.setSplitLayoutButtons([
        {
          'icon': 'list',
          'tooltip': this.translations['operator_management_title'],
          'link': `/groups/${this.company.id}/drivers/`
        }
      ]);

      /**
       * Subscribe to the route params
       */
      this.sub = this._route.params.subscribe(params => {
        this.driverId = params['id'];
        this.action = params.action.substring(0, 1).toUpperCase() + params.action.substring(1, params.action.length);
        if (self.driverId) {
          this._titleService.setTitle(self.translations['edit_driver_title'] + environment.windowTitleSuffix);
          this._navigationService.setBodyTitle(self.translations['edit_driver_title']);
          this.loadData();
        } else {
          this._titleService.setTitle(this.translations['add_driver_title'] + environment.windowTitleSuffix);
          this._navigationService.setBodyTitle(this.translations['add_driver_title']);
          this.driver = {};
          this.initForm();
        }
      });

      this.parentSub = this._route.parent.params.subscribe(params => {
        this.companyId = params['id'];
      });
    });
  }

  ngOnInit() {
    if (this.driverId) {
      this._loadingService.register('driver');
    }
  }

  initForm() {
    if (!this.driver.meta) {
      this.driver.meta = {};
    }
    const self = this;
    self.form = this._fb.group({
      fname: [this.driver.fname, [Validators.required]],
      lname: [this.driver.lname, [Validators.required]],
      emailAddress: [{value: this.driver.emailAddress, disabled: (this.action === 'Edit')},
        {
          validators: [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')],
          updateOn: 'blur',
          disabled: true
        }],
      meta: this._fb.group({
        licenseNumber: [this.driver.meta.licenseNumber],
        sendJobEmail: [this.driver.meta.sendJobEmail, []],
      }),
      phoneNumber: [this.driver.phoneNumber, {
        validators: [Validators.required, Validators.minLength(8), phoneNumberValidator(this.company.country)],
        updateOn: 'blur'
      }],
      type: [(this.driver.type ? this.driver.type : 'driver'), [Validators.required]],
      status: [this.driver.status, []],
    });

    if (self.driver.id) {
      self.disabledOnEdit.forEach((field: string) => {
        self.form.controls[field].disable();
      });
    }
  }

  ngAfterViewInit() {
    this.media.broadcast();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.parentSub.unsubscribe();
    this._navigationService.setActionLink('');
  }

  save(): void {
    this._loadingService.register('driver');
    const data = this.form.value;
    const invitation: any = this.driver;
    const patchDriver = {};
    const self = this;
    delete invitation.id;
    delete invitation.type;
    delete invitation.modified;

    this.validateAllFormFields(this.form);
    if (!this.form.valid) {
      this._loadingService.resolve('driver');
      return;
    }

    /**
     * Call the API
     */
    if (this.action.toLowerCase() === 'add') {
      /**
       * We add a driver through an companyInvitation
       */
      Object.keys(data).forEach(key => {
        invitation[key] = data[key];
      });
      if (data.phoneNumber && data.phoneNumber.e164Number) {
        // this.form.controls['phoneNumber'].setValue(data.phoneNumber.e164Number);
        invitation.phoneNumber = data.phoneNumber.e164Number;
      }
      invitation.admin = false;
      invitation.status = 'pending';
      /**
       * Validate if the driver is already in the group, and return an error if so
       */
      this._driverService.getAll({
        'where': {
          'companyId': this.companyId,
          'phoneNumber': encodeURIComponent(invitation.phoneNumber),
          'status': 'accepted'
        }
      }, 'company').subscribe(driver => {
        if (driver && driver[0]) {
          this._loadingService.resolve('driver');
          self._dialogService.openAlert({
            message: self.translations['driver_invited_exists'],
            disableClose: true,
            title: self.translations['driver_invited_exists_title'],
            closeButton: 'OK'
          }).afterClosed().subscribe(() => {
          });
        } else {
          invitation.companyId = this.companyId;
          invitation.driverType = invitation.type;
          if (invitation.type === 'admin') {
            invitation.admin = true;
          }
          const user: any = this._vault.getObject(`${environment.vaultPrefix}.user`);
          invitation.invitee = {
            name: `${user.firstName} ${user.lastName}`,
            email: `${user.email}`,
            phoneNumber: `${user.phoneNumber}`
          };

          this._companyInvitationService.sendInvite([invitation], 'company').subscribe(() => {
            this._loadingService.resolve('driver');

            self._dialogService.openAlert({
              message: self.translations['driver_invited'],
              disableClose: true,
              title: self.translations['driver_invited_title'],
              closeButton: 'OK'
            }).afterClosed().subscribe(() => {
              self._router.navigate([`/${(self.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${self.companyId}/drivers`]);
            });
          }, error => {
            this._loadingService.resolve('driver');

            let invited;
            if (error.error.name === 'COMPANY_DUPLICATE') {
              invited = self.translations['driver_invited_exists'];
            }

            self._dialogService.openAlert({
              message: invited,
              disableClose: true,
              title: self.translations['driver_invited_exists_title'],
              closeButton: 'OK'
            }).afterClosed().subscribe(() => {
              self._router.navigate([`/${(self.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${self.companyId}/drivers`]);
            });
          });
        }
      });
    } else {
      Object.keys(data).forEach(key => {
        patchDriver[key] = data[key];
      });

      delete patchDriver['emailAddress'];
      delete patchDriver['phoneNumber'];

      this._driverService.update(this.driverId, patchDriver, 'company').subscribe(() => {
        setTimeout(function () {
          self._loadingService.resolve('driver');
          self.snackBar.open('Member updated!', 'OK', {
            duration: 3000
          });
        }, 500);
      }, error => {
        console.error(error);
        this._loadingService.resolve('driver');
      });
    }
  }

  loadData(): void {
    this._driverService.get(this.driverId, {}, 'company').subscribe((driver: Driver) => {
      delete driver.activeVehicleId;
      delete driver.avatar;
      delete driver.created;
      delete driver.connections;
      delete driver.daAppInstallId;
      this.driver = driver;

      if(!this.driver.meta) {
        this.driver.meta = {
          sendJobEmail: true
        };
      }

      this.initForm();
      const self = this;
      setTimeout(function () {
        self._loadingService.resolve('driver');
      }, 500);
    }, error => {
      console.error(error);
      this._loadingService.resolve('driver');
    })
  }
}
