<mat-card class="{{defaultCssClasses}} {{cssClasses}}">
  <mat-card-title>{{title|translate|ucFirst}}</mat-card-title>
  <mat-card-subtitle>{{subTitle|translate|ucFirst}}</mat-card-subtitle>
  <mat-divider></mat-divider>
  <mat-card-content class="widget-filter">
    <span class="label">{{'filter'|translate|ucFirst}}</span>
    <mat-form-field>
      <mat-select (selectionChange)="loadData($event)" [(value)]="filterRange">
        <mat-option *ngFor="let filter of filterValues" [value]="filter.value">
          {{filter.label|translate|ucFirst}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card-content>
  <div *ngIf="(rides.length > 0)" class="ride-months-graph">
    <ngx-charts-area-chart
      *ngIf="(rides.length > 0)"
      [autoScale]="true"
      [legend]="true"
      [results]="breakdown"
      [scheme]="colorScheme"
      [showXAxisLabel]="true"
      [showYAxisLabel]="true"
      [xAxisLabel]="xAxisLabel|ucFirst"
      [xAxis]="true"
      [yAxisLabel]="yAxisLabel|ucFirst"
      [yAxis]="true">
    </ngx-charts-area-chart>
  </div>
  <mat-card-content *ngIf="(rides.length <= 0)">
    <p>{{'widget_no_data'|translate|ucFirst}}</p>
  </mat-card-content>
  <mat-divider *ngIf="(rides.length > 0)"></mat-divider>
  <mat-card-actions *ngIf="(rides.length > 0)">
    <a [routerLink]="[buttonLink]" class="text-upper" color="accent" mat-raised-button>
      <span>{{buttonText|translate|ucFirst}}</span>
    </a>
  </mat-card-actions>
</mat-card>
