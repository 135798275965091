<section class="mat-typography">
  <h1>{{ actionTitle | translate | ucFirst}}</h1>
  <mat-icon (click)="closeDialog()" *ngIf="allCompleted" class="dialog-top-right">close</mat-icon>
</section>
<div class="multi-action-pane">
  <table class="multi-action-table" td-data-table>
    <thead>
    <tr>
      <th colspan="2">{{(this.type === 'job' ? 'job' : 'description') | translate | ucFirst}}</th>
      <th>{{'status' | translate | ucFirst}}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of items;" class="pointer" td-data-table-row>
      <td *ngIf="item.reference && item.reference !== 'abc'">
        {{(item.reference !== 'abc' ? item.reference : '')}}
      </td>
      <td>
        <span *ngIf="this.type === 'job'" class="wrap-line">{{item.departure}}</span>
        <span *ngIf="this.type === 'generate_invoices'" class="wrap-line">{{item.contact}}</span>
        <span *ngIf="this.type !== 'job' && item.created"
              class="wrap-line">{{item.created | amDateFormat: 'DD-MM-YYYY HH:mm'}} -
          {{item.contact}}</span>
      </td>
      <td *ngIf="!['completed','error'].includes(item.updateStatus)" class="td-loader">
        <div class="snippet" data-title=".dot-pulse">
          <div class="stage">
            <div class="dot-pulse"></div>
          </div>
        </div>
      </td>
      <td *ngIf="item.reference === 'abc'">
        {{(item.reference !== 'abc' ? item.reference : '')}}
      </td>
      <td *ngIf="item.updateStatus === 'completed'" class="td-loader">
        <mat-icon>check</mat-icon>
      </td>
      <td *ngIf="item.updateStatus === 'error'" class="td-loader">
        <mat-icon class="error">error</mat-icon>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<div class="action-pane">
  <button (click)="this.closeDialog()" *ngIf="allCompleted" color="accent"
          flex
          mat-stroked-button>{{'ok'|translate|ucFirst}}</button>
</div>
