import {Component, Input, OnInit} from '@angular/core';
import {Company} from '../../../../../models/company';
import {DispatchpanelComponent} from '../../../dispatchpanel/dispatchpanel.component';
import {Ride} from '../../../../../models/ride';
import {TdDialogService} from '@covalent/core/dialogs';
import {RideService} from '../../../../../services/ride.service';
import {UtilityService} from '../../../../../services/utility.service';
import {TranslateService} from '@ngx-translate/core';
import {RideDetailsDialogComponent} from '../../../rides/parts/ride-details-dialog/ride-details-dialog.component';

@Component({
  selector: 'app-invoice-specification',
  templateUrl: './invoice-specification.component.html',
  styleUrls: ['./invoice-specification.component.scss']
})
export class InvoiceSpecificationComponent implements OnInit {
  @Input() jobDetails;
  @Input() context;
  @Input() company: Company;
  @Input() item;
  @Input() activeTab;
  @Input() companyId;
  @Input() translations;
  @Input() parent: DispatchpanelComponent;

  columns: any = [
    {name: 'reference', label: 'reference', sortable: true, active: false, fixedSize: 'w-80'},
    {name: 'foreignDispatchId', label: 'foreignDispatchId', sortable: true, active: false, fixedSize: 'w-80'},
    {name: 'requestedDate', label: 'requested_date', sortable: true, active: true},
    {name: 'departure', label: 'departure', sortable: true, active: false, maxWidth: 'max-width-300'},
    {name: 'paymentOrigin', label: 'payment_method', sortable: true, active: false, maxSize: 'maxSize1024', fixedSize: 'w-150'},
    {name: 'fareAmount', label: 'fare_amount', sortable: true, active: false},
    {name: 'commissionAmount', label: 'commission_amount', sortable: true, active: false},
    {name: 'driverSettlement', label: 'driver_settlement_amount', sortable: true, active: false}
  ];
  data = [];

  constructor(
    private _rideService: RideService,
    private _dialogService: TdDialogService,
    private _translateService: TranslateService
  ) {

  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    const self = this;
    const promiseArray = [];

    const ridesIds = this.item.specificationLines.map((line) => {
      return line.rideId;
    });

    const rideArray = [];
    self._rideService.getAll({where: {id: {inq: ridesIds}}}).subscribe((rides: Ride[]) => {
      rides.forEach((ride) => {
        const line = this.item.specificationLines.find((specLine) => specLine.rideId === ride.id);
        self.data.push(self.createTableRide(ride, line));
      });
    });
  }

  createTableRide(ride: Ride, line): any {
    // if (!this.rideStatuses.includes(ride.status)) {
    //   this.rideStatuses.push(ride.status);
    // }
    const id = (ride.foreignDispatchId ?
      ride.foreignDispatchId :
      (ride.foreignBookingId ?
        ride.foreignBookingId :
        ride.intId));

    const passenger = (ride.Passenger ? ride.Passenger[0] : ride.passenger);
    const reference = ride.reference;

    let owner = '';
    if (ride.Owner && ride.ownerType === 'Driver') {
      owner = `${ride.Owner.fname} ${ride.Owner.lname}`;
    } else if (ride.Owner && ride.ownerType === 'Company') {
      owner = `${ride.Owner.name}`;
    }

    let driver = '';
    if (ride.Driver && ride.Driver[0]) {
      driver = `${ride.Driver[0].fname} ${ride.Driver[0].lname}`;
    }

    const tableRide = {
      ...ride,
      owner,
      driver,
      reference,
      id: (ride.id ? ride.id : ride['_id']),
      intId: id,
      foreignDispatchId: ride.foreignDispatchId,
      requestedDate: new Date(ride.requestedDate),
      pickupDate: (typeof ride.pickupDate !== 'undefined' ? new Date(ride.pickupDate) : ''),
      passengerCount: ride.passengerCount,
      departure: UtilityService.getLocationString(ride.departure),
      destination: UtilityService.getLocationString(ride.destination),
      status: ride.status,
      passengerName: (passenger ? `${passenger.fname} ${passenger.lname}` : ''),
      passengerPhone: (passenger ? passenger.phoneNumber : ''),
      paymentMethod: (ride.paymentMeta ? ride.paymentMeta.origin : ''),
      paymentDescription: (ride.paymentMeta ? ride.paymentMeta.description : ''),
      paymentOrigin: (ride.paymentMeta ? this._translateService.instant(`payment_origin_${ride.paymentMeta.origin}`) : ''),
      calculationMethod: ride.priceMethod || '',
      fareAmount: (ride.price ? ride.price : 0),
      exportFareAmount: `${(ride.price ? ride.price.total / 100 : 0)}`.replace('.', ','),
      currency: (ride.price ? ride.price.currency : 'EUR'),
      exportFareCurrency: (ride.price ? ride.price.currency : 'EUR'),
      commissionAmount: (ride.commission ? ride.commission : 0),
      exportCommissionAmount: `${(ride.commission ? ride.commission.total / 100 : 0)}`.replace('.', ','),
      commissionCurrency: (ride.commission ? ride.commission.currency : 0),
      exportRoutePrice: `${(ride.price && ride.price.breakdown && ride.price.breakdown.route ?
        ride.price.breakdown.route.total / 100 :
        0)}`.replace('.', ','),
      exportAdditionalPrice: `${(ride.price && ride.price.breakdown && ride.price.breakdown.additional ?
        ride.price.breakdown.additional.total / 100 :
        0) + (ride.price && ride.price.breakdown && ride.price.breakdown.cleaning ?
        ride.price.breakdown.cleaning.total / 100 :
        0)}`.replace('.', ','),
      exportTotalPrice: `${(ride.price ? ride.price.total / 100 : 0)}`.replace('.', ','),
      exportDriverPrice: `${(ride.driverPrice ? ride.driverPrice.total / 100 : 0)}`.replace('.', ','),
      ownerDriverSettlement: `${{
        ...ride.driverSettlement, total: (ride.driverSettlement ?
          (0 - ride.driverSettlement.total) / 100 :
          0)
      }}`.replace('.', ','),
      exportOwnerDriverSettlement: `${(ride.driverSettlement ?
        (0 - ride.driverSettlement.total) / 100 :
        0)}`.replace('.', ','),
      exportDriverSettlement: `${(ride.driverSettlement ?
        ride.driverSettlement.total / 100 :
        0)}`.replace('.', ','),
      debtorCode: (ride.Debtor ? ride.Debtor.code : 0),
      debtorName: (ride.Debtor ?
        `${ride.Debtor.firstName} ${ride.Debtor.lastName} ${(ride.Debtor.companyName ? `(${ride.Debtor.companyName}` : '')}` :
        ''),
      debtorEmail: (ride.Debtor && ride.Debtor[0] ? ride.Debtor[0].emailAddress : ''),
      debtorPhone: (ride.Debtor && ride.Debtor[0] ? ride.Debtor[0].phoneNumber : ''),
      driverSettlement: line.total
    };
    return tableRide;
  }

  openJobDetails(id) {
    const self = this;
    this._rideService.get(id, {}).subscribe((ride: Ride) => {
      this._dialogService.open(RideDetailsDialogComponent, {
        maxHeight: '80%',
        width: (window.innerWidth < 600 ? '100%' : '90%'),
        maxWidth: (window.innerWidth < 600 ? '100%' : '90%'),
        data: {
          rideId: id,
          parent: this,
        },
      });
    });
  }
}
