<mat-list-item>
  <mat-icon mat-list-icon>place</mat-icon>
  <h4 mat-line>{{'location'|translate|ucFirst}}
    <a href="{{googleMapsLink}}" target="_blank" class="yda-link-blue" *ngIf="googleMapsLink">
      {{'review_route'|translate|ucFirst}}
    </a>
  </h4>
  <p mat-line>{{'ride_location_subtitle'|translate|ucFirst}}</p>
  <mat-icon (click)="this.expanded = (!this.expanded)"
            class="expand">{{(this.expanded ? 'expand_more' : 'expand_less')}}</mat-icon>
</mat-list-item>
<div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm"
     tdMediaToggle="gt-xs">
  <mat-list-item class="value" flex-gt-sm="50" layout-gt-sm="row">
    <p mat-line>{{'requested_departure'|translate|ucFirst}}
    </p>
    <p [innerHTML]="departureString || departure.internationalAlias || '-' | keepHtml" mat-line></p>
  </mat-list-item>

  <div *ngIf="stopOvers && stopOvers.length > 0">
    <mat-list-item class="value" flex-gt-sm="50" layout-gt-sm="row" *ngFor="let c of stopOvers; let i = index">
      <p mat-line>{{'stopover'|translate|ucFirst}}</p>
      <p [innerHTML]="UtilityService.getLocationString(c) || '-' | keepHtml" mat-line></p>
    </mat-list-item>
  </div>

  <mat-list-item *ngIf="(destinationString) || this.expanded" class="value" flex-gt-sm="50" layout-gt-sm="row">
    <p mat-line>{{'requested_destination'|translate|ucFirst}}</p>
    <p [innerHTML]="destinationString || '-' | keepHtml" mat-line></p>
  </mat-list-item>
</div>
<div *ngIf="(actualDepartureString && actualDestinationString) || this.expanded" [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row"
     tdMediaToggle="gt-xs">
  <mat-list-item *ngIf="actualDepartureString || this.expanded" class="value" flex-gt-sm="50">
    <p mat-line>{{'actual_departure'|translate|ucFirst}}</p>
    <p [innerHTML]="actualDepartureString || '-' | keepHtml" mat-line></p>
  </mat-list-item>
  <mat-list-item *ngIf="actualDepartureString || this.expanded" class="value" flex-gt-sm="50">
    <p mat-line>{{'actual_destination'|translate|ucFirst}}</p>
    <p [innerHTML]="actualDestinationString || '-' | keepHtml" mat-line></p>
  </mat-list-item>
</div>
<div *ngIf="((metrics && (metrics.distance || metrics.distance === 0)) && (!metrics || !metrics.distance && metrics.distance !== 0)) || this.expanded" [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row"
     tdMediaToggle="gt-xs">
  <mat-list-item *ngIf="metrics && (metrics.distance || metrics.distance === 0)" class="value" flex-gt-sm="50">
    <p mat-line>{{'actual_distance'|translate|ucFirst}}</p>
    <p mat-line>{{metrics.distance / 1000 | number:'1.1-1'}} {{'km'|distanceFormat:company:false}}.</p>
  </mat-list-item>
  <mat-list-item *ngIf="!metrics || !metrics.distance && metrics.distance !== 0" class="value" flex-gt-sm="50">
    <p mat-line>{{'actual_distance'|translate|ucFirst}}</p>
    <p mat-line>-</p>
  </mat-list-item>
</div>
