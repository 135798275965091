<div *ngIf="parent.rule" [ngClass]="{'dynamic-columns': ruleType === 'dynamic'||ruleType === 'hourly', 'fixed-columns':
ruleType === 'fixed'}" class="grid ">
  <!-- Header of the table (first = true) -->
  <div>
    <app-column [first]="true"
                [form]="form"
                [parent]="this"
                [price]=prices.controls[0]
                [ruleType]="ruleType"
                mat-grid-tile>
    </app-column>
  </div>

  <!-- Location pair of Fixed Rules -->
  <div *ngIf="ruleType === 'fixed'">
    <app-location-column [first]="false"
                         [form]="form"
                         [ruleType]="ruleType"
                         [rule]="parent.rule"
                         mat-grid-tile>
    </app-location-column>
  </div>

  <!-- A column for each Product (Price) -->
  <div class="scrollable">
    <div class="nonselect grid column">
      <app-column *ngFor="let price of prices.controls; let i = index"
                  [colIndex]="i"
                  [company]="parent.company"
                  [first]="false"
                  [form]="form"
                  [origin]="origin"
                  [parent]="this"
                  [price]="price"
                  [ruleType]="ruleType"
                  mat-grid-tile>
      </app-column>
    </div>
  </div>

</div>
