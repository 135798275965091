<mat-card>
  <div class="customer-type-title push-top">{{'select_customer_type'|translate|ucFirst}}</div>
  {{(parent ? parent.type : '')}}
  <mat-card-subtitle>{{'customer_create_subtitle' | translate}}</mat-card-subtitle>
  <mat-tab-group (selectedTabChange)="onTabChange($event)"
                 [selectedIndex]="selectedTabIndex" animationDuration="300ms">
    <mat-tab [disabled]="(loadedType === 'contact')">
      <ng-template mat-tab-label>
        <mat-icon class="icon-right">business</mat-icon>
        {{('account')|translate|ucFirst}}
      </ng-template>
      <app-company-debtor-upsert [company]="company" [parent]="parent"></app-company-debtor-upsert>
    </mat-tab>
    <mat-tab [disabled]="(loadedType !== 'contact' && loadedType != '')">
      <ng-template mat-tab-label>
        <mat-icon class="icon-right">group</mat-icon>
        {{('contact')|translate|ucFirst}}
      </ng-template>
      <app-contact-upsert-pane [company]="company" [parent]="parent"></app-contact-upsert-pane>
    </mat-tab>
  </mat-tab-group>
</mat-card>
