<div class="dialog-pane">
  <mat-card-title class="push-bottom">{{'close_profile_dialog_title'|translate|ucFirst}}</mat-card-title>
  <a (click)="dialogRef.close()" class="dialog-close">
    <svg height="32" width="32">
      <path
        d="M21.657 11.757l-4.242 4.24 4.243 4.245-1.415 1.415-4.242-4.243-4.243 4.243-1.415-1.415L14.586 16l-4.243-4.242 1.415-1.414L16 14.584l4.242-4.241 1.415 1.414z"
        fill="currentColor"></path>
    </svg>
  </a>
  <mat-divider></mat-divider>

  <div class="warning-infobox">
    <div class="warning-label">{{'warning' | translate | ucFirst}}</div>
    <p [innerHTML]="helpText|keepHtml"></p>
  </div>

  <mat-form-field class="confirmation-field {{(formErrors.confirm ? 'ng-invalid' : '')}}" flex>
    <mat-label>{{'confirmation'|translate|ucFirst}}</mat-label>
    <input [(ngModel)]="confirmInput" class="{{(formErrors.confirm ? 'input-warning' : '')}}" flex="100" matInput placeholder="{{'confirmation'|translate|ucFirst}}"
           type="text" value="">
    <mat-hint align="start" class="warning">
      <span [hidden]="!formErrors.confirm" class="tc-red-600">
        <span>{{ formErrors.confirm }}</span>
      </span>
    </mat-hint>
  </mat-form-field>

  <mat-divider></mat-divider>

  <div class="full-width" flex="100">
    <button (click)="deleteWebbooker()" class="push-top push-right" color="warn" flex
            mat-raised-button>
      {{'understand_close_profile'|translate|ucFirst}}</button>

    <button (click)="this.dialogRef.close()" class="push-top push-right" color="accent" flex
            mat-raised-button>
      {{'cancel'|translate|ucFirst}}</button>
  </div>
</div>
