import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';
import {ContractService} from '../../../../../services/contract.service';
import {Company} from '../../../../../models/company';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TdDialogService} from '@covalent/core/dialogs';
import {TdLoadingService} from '@covalent/core/loading';
import {UtilityService} from '../../../../../services/utility.service';

@Component({
  selector: 'app-order-now',
  templateUrl: './order-now.component.html',
  styleUrls: ['./order-now.component.scss']
})
export class OrderNowComponent implements OnInit {
  @Input() paymentResult: string;
  @Input() companyId: string;
  translations: any[] = [];
  company: Company;
  sessionId: string;
  licenseKey: string;
  trialAllowed = true;
  enterKey = false;

  constructor(
    private _contractService: ContractService,
    private _vault: CoolLocalStorage,
    private _loadingService: TdLoadingService,
    private _translateService: TranslateService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _dialogService: TdDialogService,
  ) {
    this._route.params.subscribe(params => {
      this.paymentResult = params['action'] ? params['action'] : params['id'];
    });

    const {company} = this._route.parent.snapshot.data;
    UtilityService.setBrowserTimeStamp(this.company);
    this.company = company;

    this.trialAllowed = this._vault.getObject(`${environment.vaultPrefix}.trialAllowed`);
    _translateService.get([
      'cloud_connect_licensekey_error',
      'cloud_connect_convert',
      'cloud_connect_dialog_success',
      'cloud_connect_purchase',
      'free_trial_started_title',
      'free_trial_started_text',
      'ok',
    ]).subscribe((translations: string[]) => {
      this.translations = translations;
    });
  }

  ngOnInit(): void {
  }

  startFreeTrial() {
    const self = this;
    this._loadingService.register('ordernow_loader');
    this._contractService.startFreeTrial(self.company.id).subscribe((result) => {
      this._dialogService.openAlert({
        title: self.translations['free_trial_started_title'],
        message: self.translations['free_trial_started_text'],
        disableClose: true,
        closeButton: self.translations['ok'],
      }).afterClosed().subscribe(() => {
        window.location.href = `/${(self.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${self.companyId}/contract`;
      });
    }, (error) => {
      this._loadingService.resolve('ordernow_loader');
      console.log(`[createPaymentSession.onSubmit]: error`, error);
    })
  }

  convertKey() {
    const self = this;
    // @ts-ignore
    // tslint:disable-next-line:max-line-length
    // const user = this._vault.getObject(`${environment.vaultPrefix}.user`);
    // @ts-ignore
    this._contractService.convertLicenseKey(this.companyId, this.licenseKey)
      .subscribe(() => {
        self.openConvertDialog(self.translations['cloud_connect_dialog_success']);
      }, (error) => {
        if (error) {
          if (error.error.error.name === 'LICENSE_KEY_NOT_FOUND') {
            self.openConvertDialog(self.translations['cloud_connect_licensekey_error']);
          }
        }
      })
  }

  /**
   * Open Pricing Rule Dialog.
   */
  openConvertDialog(msg) {
    const self = this;
    self._dialogService.openConfirm({
      message: msg,
      disableClose: false,
      'title': self.translations['cloud_connect_convert'],
      'acceptButton': self.translations['ok'],
    }).afterClosed()
      .subscribe((accept: boolean) => {
        self._router.navigate([`/${(self.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${self.companyId}/contract`]);
      });
  }
}
