<app-no-contract *ngIf="(this.context==='company' && (!this.company.ActiveContract ||
!['active','past_due', null].includes(this.company.ActiveContract.foreignStatus)))"
                 [companyId]="this.companyId" [contract]="this.company.ActiveContract"></app-no-contract>
<mat-card [class]="(this.context==='company' &&
(!this.company.ActiveContract ||
(this.company.hasDispatcherLicense && !this.company.ActiveContract.foreignStatus)) ? 'blurText' :'')" [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <td-search-box (searchDebounce)="search($event)" [alwaysVisible]="true" class="push-left push-right"
                 placeholder="{{'search_here'|translate}}"></td-search-box>
  <mat-divider></mat-divider>
  <ng-template tdLoading="apps.list">
    <table class="mat-clickable" td-data-table>
      <thead>
      <tr td-data-table-column-row>
        <th (sortChange)="sort($event)"
            *ngFor="let c of columns"
            [active]="(sortBy == c.name)"
            [name]="c.name"
            [sortOrder]="( sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
            [sortable]="c.sortable"
            td-data-table-column>
          {{ c.label | translate | ucFirst}}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr (click)="click(item.id)"
          *ngFor="let item of filteredData"
          class="noselect"
          td-data-table-row>
        <td *ngFor="let c of columns"
            td-data-table-cell>
              <span [ngSwitch]="c.name">
                <span *ngSwitchDefault>
                  <span *ngIf="item[c.name]">{{item[c.name]}}</span>
                </span>
              </span>
        </td>
      </tr>
      </tbody>
    </table>
    <td-paging-bar #pagingBar (change)="page($event)" [pageSize]="pageSize" [total]="filteredData.length">
      <span hide-xs>{{'rows_p_page'|translate}}:</span>
      {{pagingBar.range}}
      <span hide-xs>of {{pagingBar.total}}</span>
    </td-paging-bar>
  </ng-template>
</mat-card>
