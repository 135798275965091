<div *ngIf="!this.data.inTrial" style="width:700px">
  <mat-card-title class="push-bottom">{{'cloud_connect_webbooker_title'|translate|ucFirst}}</mat-card-title>
  <mat-card-subtitle
    [innerHTML]="'cloud_connect_webbooker_subtitle'|translate|keepHtml" class="push-bottom"></mat-card-subtitle>
  <mat-divider *ngIf="false"></mat-divider>
  <div *ngIf="false" class="table">
    <div class="table-header" layout="row">
      <div class="left-column" layout="column">
        {{'product_name'|translate|ucFirst}}
      </div>
      <div class="right-column" layout="column">
        {{'total_extra_monthly'|translate|ucFirst}}
      </div>
    </div>
    <div layout="row">
      <div class="right-column" layout="column">
        {{'webbooker_product'|translate|ucFirst}}
      </div>
      <div class="left-column" layout="column">
        {{(this.webbookerAmount ? this.webbookerAmount : 0) | localizedCurrency}}
      </div>
    </div>
  </div>

  <mat-divider></mat-divider>

  <div class="full-width" flex="100">
    <button (click)="this.addWebbooker()" class="push-top push-right" color="accent" flex
            mat-raised-button>
      {{'create_new_webbooker'|translate|ucFirst}}</button>
  </div>
</div>

<div *ngIf="this.data.inTrial" style="width:700px">
  <mat-card-title class="push-bottom">{{'cloud_connect_webbooker_title_intrial'|translate|ucFirst}}</mat-card-title>
  <mat-card-subtitle
    [innerHTML]="'cloud_connect_webbooker_subtitle'|translate|keepHtml" class="push-bottom"></mat-card-subtitle>
  <mat-divider></mat-divider>
  <div class="full-width" flex="100">
    <button (click)="this.dialogRef.close()" class="push-top push-right" color="accent" flex
            mat-raised-button routerLink="/groups/{{this.company.id}}/contract">
      {{'cloud_connect_buy'|translate|ucFirst}}</button>
  </div>
</div>
