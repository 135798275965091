<mat-list-item>
  <mat-icon mat-list-icon>person_pin</mat-icon>
  <h4 mat-line>{{'passenger'|translate|ucFirst}}</h4>
  <p mat-line>{{'ride_passenger_subtitle'|translate|ucFirst}}</p>
  <mat-icon (click)="this.expanded = (!this.expanded)" *ngIf="flightNumber"
            class="expand">{{(this.expanded ? 'expand_more' : 'expand_less')}}</mat-icon>
</mat-list-item>
<div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'passenger_count'|translate|ucFirst}}</p>
    <p mat-line>{{passengerCount}}</p>
  </mat-list-item>
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'name'|translate|ucFirst}}</p>
    <p mat-line>{{this.passenger.fname}} {{this.passenger.lname}}</p>
  </mat-list-item>
</div>
<div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'email'|translate|ucFirst}}</p>
    <p mat-line>{{passenger.email || '-'}} <a (click)="resendConfirmation()" *ngIf="passenger.email"
                                              class="yda-link-blue resend-link">{{'resend'|translate}}</a></p>
  </mat-list-item>
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'phone_number'|translate|ucFirst}}</p>
    <p mat-line>{{passenger.phoneNumber || '-'}}</p>
  </mat-list-item>
</div>
<div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <mat-list-item *ngIf="flightNumber || this.expanded" class="value small" flex-gt-sm="50">
    <p mat-line>{{'flight_number'|translate|ucFirst}}</p>
    <p mat-line>{{flightNumber || '-'}}</p>
  </mat-list-item>
</div>

