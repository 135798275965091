import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {environment} from '../../../../environments/environment';
import {TdLoadingService} from '@covalent/core/loading';
import {UserService} from '../../../services/user.service';
import {AuthService} from '../../../services/auth.service';
import {TranslateService} from '@ngx-translate/core';
import {DriverService} from '../../../services/driver.service';
import {Company} from '../../../models/company';
import {CoolLocalStorage} from '@angular-cool/storage';
import {ucFirst} from '../../../pipes/uc-first.pipe';
import {TdDialogService} from '@covalent/core/dialogs';
import {ActivatedRoute, Router} from '@angular/router';
import {CompanyService} from '../../../services/company.service';
import {ContractService} from '../../../services/contract.service';
import {User} from '../../../models/user';
import {IntegrationService} from '../../../services/integration.service';
import {phoneNumberValidator} from '../../../pipes/phonenumber-validator.pipe';
import {CountryISO, SearchCountryField} from 'ngx-intl-tel-input';
import moment from 'moment-timezone';
import {NavigationService} from '../../../services/navigation.service';
import {Title} from '@angular/platform-browser';
import {TdMediaService} from '@covalent/core/media';
import {WorkspaceSelectionComponent} from '../workspace-selection/workspace-selection.component';
import {MergeEmailComponent} from '../merge-email/merge-email.component';
import {GlobalFunctions} from '../../../functions/functions';
import {ValidateAllFormFields} from '../../../functions/validateAllFormFields';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent extends ValidateAllFormFields implements OnInit {
  form: UntypedFormGroup;
  form2: UntypedFormGroup;
  form3: UntypedFormGroup;
  form4: UntypedFormGroup;
  form5: UntypedFormGroup;
  verifyToken: string;
  SearchCountryField = SearchCountryField;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom
  ];
  user: User;
  prefill: any;
  emailAddress: string;
  phoneNumber: string;
  fname: string;
  lname: string;
  companyName: string;
  password: string;
  companies: Company[];
  company: Company;
  loginData: any;
  selected: any;
  googlePayload: any;
  googleProfile: any;

  layoutTitle = 'signup_welcome_title';
  layoutSubTitle = 'signup_subtitle';

  validationMessages = {
    'fname': {
      'fieldName': 'First name',
      'required': 'form_validation_required',
    },
    'lname': {
      'fieldName': 'Last name',
      'required': 'form_validation_required',
    },
    'phoneNumber': {
      'fieldName': 'phone number',
      'required': 'form_validation_required',
      'validatePhoneNumber': 'phonenumber_pattern_invalid',
    },
    'emailAddress': {
      'fieldName': 'Email Address',
      'required': 'form_validation_required',
    },
    'companyName': {
      'fieldName': 'Company Name',
      'required': 'form_validation_required',
    },
    'website': {
      'fieldName': 'Website',
      'required': 'form_validation_required',
    }
  }

  showError = false;
  error = false;
  trialAllowed = true;
  currentStep = 'step1';
  errorLabel: string;
  errorText: string;
  directLogin: string;
  formErrors: any = {
    fname: '',
    lname: '',
    emailAddress: '',
    phoneNumber: '',
    licenseKey: '',
    companyName: ''
  };
  translations: string[];

  currentDispatchMethods = [{
    label: 'signup_dispatchmethod_whatsapp',
    value: 'whatsapp'
  },
    {
      label: 'signup_dispatchmethod_telegram',
      value: 'telegram'
    },
    {
      label: 'signup_dispatchmethod_email',
      value: 'email'
    },
    {
      label: 'dispatch_software',
      value: 'dispatchoption'
    }
  ];

  contactRefferer = [{
    label: 'signup_contact_referrer_searchengine',
    value: 'searchengine'
  },
    {
      label: 'signup_contact_referrer_internet_ad',
      value: 'internet_ad'
    },
    {
      label: 'signup_contact_referrer_referral_from_driver',
      value: 'referral_from_driver'
    },
    {
      label: 'signup_contact_referrer_yda_email_fleet_solutions',
      value: 'yda_email_fleet_solutions'
    },
    {
      label: 'other',
      value: 'other'
    }
  ];

  interestedIn = [{
    label: 'signup_interest_webbooker',
    value: 'webbooker'
  },
    {
      label: 'signup_interest_whitelabel_passengerapp',
      value: 'passengerapp'
    },
    {
      label: 'signup_interest_driverapp',
      value: 'driverapp'
    },
    {
      label: 'signup_interest_dispatchpanel',
      value: 'dispatchpanel'
    },
    {
      label: 'signup_interest_mobile_dispatchapp',
      value: 'mobile_dispatching'
    },
    {
      label: 'signup_interest_whatsapp_sharing',
      value: 'whatsapp_sharing'
    },
    {
      label: 'signup_interest_google_calendar',
      value: 'google_calendar'
    }
  ];
  mySelections: string[];

  constructor(
    private media: TdMediaService,
    private _fb: UntypedFormBuilder,
    private _loadingService: TdLoadingService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _vault: CoolLocalStorage,
    private _userService: UserService,
    private _contractService: ContractService,
    private _authService: AuthService,
    private _translateService: TranslateService,
    private _driverService: DriverService,
    private _companyService: CompanyService,
    private _integrationService: IntegrationService,
    private _dialogService: TdDialogService,
    private _titleService: Title,
    private _globalFunctions: GlobalFunctions,
    private _navigationService: NavigationService
  ) {
    super();
    this.loadErrorTranslations(_translateService);

    const self = this;
    this._route.params.subscribe(params => {
      // if (!params['signupStep']) {
      //   this._vault.setObject(`${environment.vaultPrefix}.accessToken`, null);
      //   this._vault.setObject(`${environment.vaultPrefix}.driver`, null);
      //   this._vault.setObject(`${environment.vaultPrefix}.user`, null);
      // } else {
      //   self.currentStep = params['signupStep'];
      //   self.layoutSubTitle = 'signup_create_company_subtitle';
      //   self.directLogin = params['signupStep'];
      //   self.user = this._vault.getObject(`${environment.vaultPrefix}.user`);
      // }

      _translateService.get([
        'welcome',
        'signup_success_connected_message',
        'signup_success_connected_title',
        'signup_success_free_trial_message',
        'signup_success_free_trial_title',
        'signup_success_new_group_message',
        'signup_success_new_group_title',
        'signup_connect_invite_send_title',
        'signup_connect_invite_send_text',
        'license_key_not_found',
        'signup_success_title',
        'signup_success_message',
        'signup_error_already_contract_title',
        'signup_error_already_contract_message',
        'signup_user_hadtrial_title',
        'signup_user_hadtrial_message',
        'signup_user_hadtrial_button',
        'signup_validationcode_failed',
        'login',
        'ok',
        'upgrade'
      ]).subscribe((translations: any) => {
        this.translations = translations;
        this._titleService.setTitle(ucFirst(this.translations['welcome'] + environment.windowTitleSuffix));
        this._navigationService.setBodyTitle(ucFirst(this.translations['welcome']));
      });

      // const MOBILE_PATTERN = /[0-9+\- ]/;
      this.form = this._fb.group({
        emailAddress: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        password: ['', [Validators.required]],
        licenseKey: ['', [Validators.required]],
        companyName: ['', []],
        newCompanyName: ['', []],
      });

      this.form2 = this._fb.group({
        fname: ['', [Validators.required]],
        lname: ['', [Validators.required]],
        timezone: [Intl.DateTimeFormat().resolvedOptions().timeZone, [Validators.required]],
        emailAddress: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
        phoneNumber: ['', {
          validators: [Validators.required, Validators.minLength(8), phoneNumberValidator()],
          updateOn: 'blur'
        }],
      });

      this.form3 = this._fb.group({
        companyName: ['', [Validators.required]],
        estimatedJobs: ['', [Validators.required]],
        fleetSize: ['', [Validators.required]],
        currentDispatchMethod: ['', [Validators.required]],
        currentDispatchMethodOther: ['', []],
        howDidYouHear: ['', [Validators.required]],
        interestedin: ['', [Validators.required]],
        howDidYouHearOther: ['', []],
        website: ['', [Validators.required]],
        marketingInfo: ['', [Validators.required]],
        licenseKey: ['', []],
      });

      this.form4 = this._fb.group({
        validationCode: ['', [Validators.required]],
      });

      this.form5 = this._fb.group({
        password: ['', [Validators.required]],
        passwordConfirm: ['', [Validators.required]],
      });

      this._route.params.subscribe((params2) => {
        if (params2['token']) {
          self._vault.setObject(`${environment.vaultPrefix}.signupToken`, params2['token']);

          this._contractService.getPrefill(params2['token'])
            .subscribe((prefill) => {
              self.prefill = prefill;
              if (prefill && prefill.customer && prefill.customer.email) {
                self.form.controls.emailAddress.setValue(prefill.customer.email);
                self.form.controls.licenseKey.setValue(prefill.licenseKey);
              }
              if (prefill && prefill.customer && prefill.customer.email) {
                self.form2.controls.emailAddress.setValue(prefill.customer.email);
                self.form3.controls.licenseKey.setValue(prefill.licenseKey);
              }
            }, () => {
              self.currentStep = 'usedToken';
            });
        }
      });
    });
  }

  ngOnInit(): void {
    this.conditionalFormatting();
    this.gtag('event', 'signup');
    this._globalFunctions.initGoogleSignup(this);
  }

  googleLogin(resp): void {
    const self = this;
    self.googlePayload = resp;
    self._userService.googleProfile(resp)
      .subscribe((result) => {
        this.form.controls.emailAddress.setValue(result.email);
        self.googleProfile = result;
        this.form2.controls.fname.setValue(result.given_name);
        this.form2.controls.emailAddress.setValue(result.email);
        this.form2.controls.lname.setValue(result.family_name);
        this.form2.controls.phoneNumber.setValue(result.phoneNumber);

        self._userService.validateCredentials({
          'email': this.form.controls.emailAddress.value,
          'realm': `${environment.companyId}`,
          'flow': 'googleSignup'
        })
          .subscribe(() => {
            self.currentStep = 'step3';
            self.layoutTitle = 'signup_new_title';
            self.layoutSubTitle = 'signup_new_subtitle';
            self.form2.controls.emailAddress.setValue(self.form.controls.emailAddress.value);
          }, (error) => {
            resp.email = result.email;
            self.login(result.email, '', btoa(JSON.stringify(resp)))
          });
      });
  }

  conditionalFormatting() {
    this.form.get('companyName').valueChanges.subscribe(
      (mode: string) => {
        if (mode === 'createGroup') {
          this.form.get('licenseKey').setValidators([]);
          this.form.get('newCompanyName').setValidators([Validators.required]);
          this.form.get('licenseKey').updateValueAndValidity();
          this.form.get('newCompanyName').updateValueAndValidity();
        } else {
          this.form.get('licenseKey').setValidators([Validators.required]);
          this.form.get('newCompanyName').setValidators([]);
          this.form.get('licenseKey').updateValueAndValidity();
          this.form.get('newCompanyName').updateValueAndValidity();
        }
      });
  }

  login(email?: string, password?: string, googlePayLoad?: any): void {
    this.showError = false;
    this._loadingService.register();

    const self = this;
    const credentials: any = {
      'password': (password ? password : this.form.controls.password.value)
    };

    credentials.realm = `${environment.companyId}`;
    if (googlePayLoad) {
      credentials.email = email.toLowerCase();
      credentials.googlePayLoad = googlePayLoad;
      if (environment.location === 'localhost') {
        credentials.isLocalhost = true;
      }
    } else {
      credentials.email = (email ? email : this.form.controls.emailAddress.value.toLowerCase());
    }

    this._authService[(googlePayLoad ? 'googleLogin' : 'login')](credentials, function (err) {
      if (err) {
        self._translateService.get(`${err}`).subscribe((translation: string) => {
          self.error = true;
          self.showError = true;
          self.errorLabel = 'Login failed';
          self.errorText = translation;
          self._loadingService.resolve();
        });
      } else {
        self.user = self._vault.getObject(`${environment.vaultPrefix}.user`);
        self._userService.findUserMerge(self.user).subscribe((drivers) => {
          if (drivers.length > 0) {
            self._dialogService.open(MergeEmailComponent, {
              maxHeight: '80%',
              minWidth: '330px',
              disableClose: true,
              width: '520px',
              data: {
                drivers: drivers,
                user: self.user,
                credentials: credentials,
                parent: self
              },
            });
          }

          if (self.user.hadTrial) {
            self._loadingService.resolve();
            /**
             * Check if we have multiple companies, then redirect to login selection
             */
            self._companyService.getCompanies({'include': 'ActiveContract'}).subscribe((companies: any[]) => {
              let closeTxt, redirect;
              self._vault.setObject(`${environment.vaultPrefix}.allowedCompanies`, companies.map((c) => {
                return (c.id);
              }));

              companies = companies.filter((c) => {
                return (c.type === 'business' && c.hadTrial)
              });

              if (companies[0] && companies[0].ActiveContract) {
                closeTxt = ucFirst(self.translations['signup_user_hadtrial_button']);
                redirect = `/groups/${companies[0].id}/contract`;
              } else if (companies[0]) {
                closeTxt = ucFirst(self.translations['upgrade']);
                redirect = `/groups/${companies[0].id}/contract`;
              } else {
                closeTxt = ucFirst(self.translations['login']);
                redirect = `switchWorkspace`;
              }

              self._dialogService.openAlert({
                message: ucFirst(self.translations['signup_user_hadtrial_message']),
                disableClose: true,
                title: ucFirst(self.translations['signup_user_hadtrial_title']),
                closeButton: closeTxt
              }).afterClosed().subscribe(() => {
                console.log(redirect);
                if (redirect === 'switchWorkspace') {
                  self._dialogService.open(WorkspaceSelectionComponent, {
                    maxHeight: '80%',
                    maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
                    minWidth: '500px',
                    data: {
                      openFromDialog: true,
                      parent: this
                    },
                  });
                } else {
                  self._router.navigate([redirect]);
                }
              });
            });
          } else {
            /**
             * Check if we have multiple companies, then redirect to login selection
             */
            self._companyService.getCompanies({
              'where': {'type': 'business', 'hadTrial': false},
              'include': 'ActiveContract'
            }).subscribe((companies: any[]) => {
              self._companyService.getCompaniesWhereOwner({
                'where': {'hadTrial': false},
                'include': 'ActiveContract'
              }).subscribe((ownerCompanies: any[]) => {
                const myOwnerCompanies = ownerCompanies.map((o) => {
                  return o.Company;
                });

                if (myOwnerCompanies) {
                  self.companies = [...myOwnerCompanies];
                }

                self.trialAllowed = (companies.filter((c) => {
                  return (c.hadTrial && !c.ActiveContract)
                }).length <= 0);

                if (companies) {
                  self.companies = [...companies, ...self.companies]
                }
                self._loadingService.resolve();
                self.currentStep = 'step2_loggedin';

                self.layoutTitle = 'signup_convert_licensekey_title';
                self.layoutSubTitle = 'signup_convert_licensekey_subtitle';

                self.form.controls.emailAddress.setValue(self.user.email);
                self.form.controls.password.setValue(credentials.password);

                if (self.companies.length > 0) {
                  self.form.controls.companyName.setValue(self.companies[0].id);
                  self.selectGroup({
                    value: self.companies[0].id
                  });
                } else {
                  self.layoutSubTitle = 'signup_create_company_subtitle';
                  self.form.controls.companyName.setValue('createGroup');
                  self.selectGroup({
                    value: 'createGroup'
                  });
                }
              });
            });
          }
        });
      }
    });
    this.gtag('event', '/signup/login');
  }

  step1(): void {
    this.showError = false;
    const self = this;

    if (self.form.controls.emailAddress.valid) {
      self._userService.validateCredentials({
        'email': this.form.controls.emailAddress.value,
        'realm': `${environment.companyId}`,
        'flow': 'signup'
      })
        .subscribe(() => {
          self.currentStep = 'step3';
          self.layoutTitle = 'signup_new_title';
          self.layoutSubTitle = 'signup_new_subtitle';
          self.form2.controls.emailAddress.setValue(self.form.controls.emailAddress.value);
        }, (error) => {
          if (error.error.error.name === 'NOT_VERIFIED') {
            self.currentStep = 'step2_confirm_email';
            self.layoutTitle = 'signup_confirm_email';
            self.layoutSubTitle = 'signup_confirm_email_subtitle';
          } else if (error.error.error.name === 'CONNECT_STATUS') {
            self.currentStep = 'step2';
            self.layoutTitle = 'signup_confirm_login';
            self.layoutSubTitle = 'signup_confirm_sublogin';
          } else if (error.error.error.name === 'DUPLICATE_EMAIL') {
            self.currentStep = 'step2';
            self.layoutTitle = 'signup_confirm_login';
            self.layoutSubTitle = 'signup_confirm_sublogin';
          }
        });
    } else {
      self.form.controls.emailAddress.markAsDirty();
      self.form.controls.emailAddress.markAsTouched();
    }
    this.gtag('event', '/signup/step1');
  }

  confirmEmail(): void {
    this.showError = false;
    const self = this;
    if (self.form4.valid) {
      self._userService.confirmWebPortalUserEmail({
        'validationCode': this.form4.controls.validationCode.value,
        'username': `${this.form.controls.emailAddress.value}_${environment.companyId}`,
      })
        .subscribe((user) => {
          self.verifyToken = user.resetToken;

          self.currentStep = 'step2_confirm_password';
          self.layoutTitle = 'signup_new_password_title';
          self.layoutSubTitle = 'signup_new_password_subtitle';
          self.user = user;
        }, () => {
          self._dialogService.openAlert({
            message: ucFirst(self.translations['signup_validationcode_failed']),
            disableClose: true,
            closeButton: self.translations['ok']
          }).afterClosed().subscribe(() => {
          });
        });
    }
  }

  confirmPassword(): void {
    const passwordCtrl: UntypedFormControl = (<any>this.form5).controls.password;
    const passwordConfirmCtrl: UntypedFormControl = (<any>this.form5).controls.passwordConfirm;

    if (passwordCtrl.value === '' || passwordConfirmCtrl.value === '') {
      return null;
    }

    const valid = (passwordCtrl.value === passwordConfirmCtrl.value);

    if (!valid) {
      // passwordCtrl.setErrors({'equals': true});
      passwordConfirmCtrl.setErrors({'equals': true});
    } else {
      // passwordCtrl.markAsPristine();
      passwordConfirmCtrl.markAsPristine();
    }
  }

  setPassword(): void {
    const self = this;
    this._loadingService.register('setPassword');
    const data = this.form5.value;
    delete data.passwordConfirm;
    data.token = self.verifyToken;

    this._userService.reset(data).subscribe(() => {
      self.loginData = data;
      this.login(data.email, data.password);
    }, error => {
      // this._translateService.get('password_reset_update_failed').subscribe((translation) => {
      //
      // });
      console.error(error);
      this._loadingService.resolve('setPassword');
    });
  }

  step2(): void {
    this.showError = false;
    this.currentStep = 'step3';
    this.gtag('event', '/signup/step2');
  }

  step3(): void {
    if (this.form2.valid) {
      this.showError = false;
      this.currentStep = 'step4';
      this.gtag('event', '/signup/step3');
    } else {
      this.validateAllFormFields(this.form2);
      console.log(this.formErrors);
    }
  }

  connectLicenseKey(createTrial = true): void {
    this.showError = false;
    const self = this;
    const newUser = this.form.value;
    if (newUser.phoneNumber && newUser.phoneNumber.e164Number) {
      newUser.phoneNumber = newUser.phoneNumber.e164Number;
    }

    self._loadingService.register();
    if (self.form.valid || (self.user && self.directLogin)) {
      if (this.company && newUser.companyName !== 'createGroup') {
        self._contractService.convertLicenseKey(this.company.id, newUser.licenseKey).subscribe(() => {
          self._dialogService.openAlert({
            message: ucFirst(self.translations['signup_success_connected_message']),
            disableClose: true,
            title: ucFirst(self.translations['signup_success_connected_title']),
            closeButton: self.translations['ok']
          }).afterClosed().subscribe(() => {
            self._router.navigate([`/groups/${this.company.id}/`]);
          });
          this.gtag('event', '/signup/success');
        }, (error) => {
          if (error.error.error.name === 'LICENSE_KEY_NOT_FOUND') {
            self.form.controls.licenseKey.setErrors({
              'licenseKey': self.translations['license_key_not_found'],
            });
            self.formErrors.licenseKey = self.translations['license_key_not_found'];
          } else if (error.error.error.name === 'COMPANY_HAS_CONTRACT') {
            self._loadingService.resolve('signup_step_4');
            self._dialogService.openAlert({
              message: ucFirst(self.translations['signup_error_already_contract_message']),
              disableClose: true,
              title: ucFirst(self.translations['signup_error_already_contract_title']),
              closeButton: self.translations['ok']
            }).afterClosed().subscribe(() => {
            });
          }
        });
      } else {
        self._integrationService.insert({
          'company': {
            'name': (this.form.controls.newCompanyName.value ? this.form.controls.newCompanyName.value : this.form3.controls.companyName.value),
            'country': self.user['country'],
            'email': self.user['email'],
            'timezone': moment.tz.guess(),
            'ownerId': self.user.id,
            'type': 'business',
            'ownerType': 'WebPortalUser',
            'settings': {
              'calendar': self.googlePayload
            }
          },
          'driver': {
            'fname': self.user['firstName'],
            'lname': self.user['lastName'],
            'emailAddress': self.user['email'],
            'phoneNumber': self.user['phoneNumber'],
            'country': self.user['country'],
            'type': 'admin',
            'isOwner': true
          }
        }, 'company').subscribe((result) => {
          if (newUser.companyName === 'createGroup' || (self.user && self.directLogin)) {
            if (createTrial) {
              this._contractService.startFreeTrial(result.companyId).subscribe(() => {
                self._loadingService.resolve();

                self._dialogService.openAlert({
                  message: ucFirst(self.translations['signup_success_free_trial_message']),
                  disableClose: true,
                  title: ucFirst(self.translations['signup_success_free_trial_title']),
                  closeButton: self.translations['login']
                }).afterClosed().subscribe(() => {
                  const redirect = this._vault.getObject(`${environment.vaultPrefix}.redirectAfterSignup`);
                  self._router.navigate([`/groups/${result.companyId}/${(redirect ? redirect : 'cloudConnect')}`]);
                });
              });
            } else {
              self._dialogService.openAlert({
                message: ucFirst(self.translations['signup_success_new_group_message']),
                disableClose: true,
                title: ucFirst(self.translations['signup_success_new_group_title']),
                closeButton: self.translations['login']
              }).afterClosed().subscribe(() => {
                self._router.navigate([`/groups/${result.companyId}/dispatchpanel`]);
              });
            }
          } else {
            self._contractService.convertLicenseKey(result.companyId, newUser.licenseKey).subscribe(() => {
              self._dialogService.openAlert({
                message: ucFirst(self.translations['signup_success_connected_message']),
                disableClose: true,
                title: ucFirst(self.translations['signup_success_connected_title']),
                closeButton: self.translations['login']
              }).afterClosed().subscribe(() => {
                self._router.navigate([`/groups/${result.companyId}/contract`]);
              });
            }, (error) => {
              this._loadingService.resolve();
              if (error.error.error.name === 'LICENSE_KEY_NOT_FOUND') {
                self.form.controls.licenseKey.setErrors({
                  'licenseKey': self.translations['license_key_not_found'],
                });
                self.formErrors.licenseKey = self.translations['license_key_not_found'];
              }
            });
          }
          this.gtag('event', '/signup/success');
        });
      }
    } else {
      this.validateAllFormFields(self.form);
    }
  }

  loginToGroup(): void {
    this._router.navigate([`/groups/${this.company.id}/contract`]);
  }

  startTrialForGroup(): void {
    this._loadingService.register();
    this._contractService.startFreeTrial(this.company.id).subscribe(() => {
      this._loadingService.resolve();

      this._dialogService.openAlert({
        message: ucFirst(this.translations['signup_success_free_trial_message']),
        disableClose: true,
        title: ucFirst(this.translations['signup_success_free_trial_title']),
        closeButton: this.translations['login']
      }).afterClosed().subscribe(() => {
        this._router.navigate([`/groups/${this.company.id}/contract`]);
      });
    });
  }

  getLang(): string {
    if (navigator.languages) {
      return navigator.languages[0];
    }
    return navigator.language;
  }

  guessCountry(): CountryISO {
    const lang = this.getLang();
    let countryCode = lang.toLowerCase();
    let country;
    if (lang.indexOf('-') > -1) {
      countryCode = lang.split('-')[1].toLowerCase();
    }
    for (const c in CountryISO) {
      if (CountryISO[c] === countryCode) {
        country = CountryISO[c];
      }
    }
    return country;
  }

  signupNewUser(): void {
    this.showError = false;
    const self = this;
    const newUser = this.form2.value;
    const newCompany = this.form3.value;
    newUser.type = 'portal';
    newUser.locale = this.getLang();

    if (newUser.phoneNumber && newUser.phoneNumber.e164Number) {
      newUser.phoneNumber = newUser.phoneNumber.e164Number;
    }

    if (this.trialAllowed) {
      newUser.createFreeTrial = 'true';
    }
    this.validateAllFormFields(self.form2);
    this.validateAllFormFields(self.form3);

    if (self.form2.valid && self.form3.valid) {
      self._loadingService.register();
      self._userService.createPortalUser({...newUser, ...newCompany})
        .subscribe(() => {
          self._vault.setObject(`${environment.vaultPrefix}.newUser`, newUser);
          self._loadingService.resolveAll();
          self._dialogService.openAlert({
            message: ucFirst(self.translations['signup_success_message'].replace('{email}', newUser.emailAddress)),
            disableClose: true,
            title: ucFirst(self.translations['signup_success_title']),
            closeButton: self.translations['ok']
          }).afterClosed().subscribe(() => {
            self._router.navigate([`/signup-successful`]);
          });
        }, (error) => {
          self._loadingService.resolveAll();
          if (error.error.error.name === 'DUPLICATE_EMAIL') {
            self.currentStep = 'step2';
          } else if (error.error.error.name === 'LICENSE_KEY_NOT_FOUND') {
            self.form3.controls.licenseKey.setErrors({
              'licenseKey': self.translations['license_key_not_found'],
            });
            self.formErrors.licenseKey = self.translations['license_key_not_found'];
          } else if (error.error.error.name === 'FORBIDDEN' || error.error.error.message === 'User already had trial') {
            self._router.navigate([`/signup-successful`]);
          }
        });
    }
  }

  redirectToLogin(): void {
    if (this.companies.length > 1) {
      this._dialogService.open(WorkspaceSelectionComponent, {
        maxHeight: '80%',
        maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
        minWidth: '500px',
        data: {
          openFromDialog: true,
          parent: this
        },
      });
    } else if (this.companies.length === 1) {
      this._router.navigate([`/groups/${this.companies[0].id}/dispatchpanel`]);
    }
  }

  getSelectedIcon() {
    return (this.company ? 'business' : (this.selected === 'createGroup' ? 'add' : 'create_new_group'));
  }

  getCompanyName() {
    return (this.company ? this.company.name : (this.selected === 'createGroup' ? 'create_new_group' : 'personal'));
  }

  selectGroup(event) {
    if (event.value) {
      const selected = this.companies.filter((c) => {
        return (c.id === event.value)
      });
      if (selected.length > 0) {
        this.company = {
          id: selected[0].id,
          name: selected[0].name
        };
        this.form.controls.companyName.setValue(selected[0].id);
      } else {
        this.selected = 'createGroup';
        this.company = null;
      }
    } else {
      this.selected = 'createGroup';
      this.company = null;
    }
  }

  gtag(event, data): void {
    (window as any).dataLayer.push(arguments);
  }

  interestedinChanged({value}): void {
    if (value.length >= 3) {
      this.form3.patchValue({
        interestedin: value.slice(0, 3)
      });
    }
  }
}
