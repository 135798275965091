import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

interface FilterData {
  searchTerm: string;
  filterDates: {
    from: any,
    to: any
  }
  origin: string;
}

@Component({
  selector: 'app-rides-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

  @Output() filterChanged = new EventEmitter<FilterData>();
  @Output() export = new EventEmitter<boolean>();
  @Input() rideOrigins: any[];

  data: FilterData = {
    searchTerm: '',
    filterDates: {
      from: '',
      to: ''
    },
    origin: ''
  };

  constructor() {
  }

  ngOnInit() {
  }

  doExport() {
    this.export.emit(true);
  }

  searchTerm(searchTerm: string): void {
    console.log(`[FilterComponent.searchTerm]: searchTerm`, searchTerm);
    this.data.searchTerm = searchTerm;
    this.filterChanged.emit(this.data);
  };

  onFromFilterChanged(date: any): void {
    console.log(`[FilterComponent.onFromFilterChanged]: date`, date);
    this.data.filterDates.from = date;
    this.filterChanged.emit(this.data);
  }

  onToFilterChanged(date: any): void {
    console.log(`[FilterComponent.onToFilterChanged]: date`, date);
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);

    this.data.filterDates.to = date;
    this.filterChanged.emit(this.data);
  }

  onOriginChanged(event: any): void {
    const value = event.value;
    console.log(`[FilterComponent.onToFilterChanged]: onOriginChanged`, value);
    this.data.origin = (value === 'all' ? '' : value);
    this.filterChanged.emit(this.data);
  }

}
