import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CompanyService} from '../../../../services/company.service';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'app-install-tps',
  templateUrl: './install-tps.component.html',
  styleUrls: ['./install-tps.component.scss']
})
export class InstallTpsComponent implements OnInit, OnDestroy {

  companyId: string;
  daAppInstallId: string;
  routeSubscription: Subscription;

  constructor(private _translateService: TranslateService,
              private _route: ActivatedRoute,
              private _companyService: CompanyService) {
    this.daAppInstallId = environment.driverAppInstallationId;
  }

  ngOnInit() {
    this.routeSubscription = this._route.parent.params.subscribe(params => {
      this.companyId = params['id'];
    });
  }

  install() {
    this._companyService.get(this.companyId)
      .subscribe((company) => {
        this._companyService.installTps(company, this.daAppInstallId).subscribe(() => {
          window.location.reload();
        }, error => {
          console.log(`[InstallTpsComponent.error]:`, error);
        });
      })
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

}
