import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {DriverService} from '../../services/driver.service';
import {CompanyResolver} from '../../resolvers/company-resolver';
import {CanDeactivateGuard} from '../../guards/can-deactivate-guard';
import {AuthGuard} from '../../guards/auth.guard';
import {NavigationService} from '../../services/navigation.service';
import {DriversComponent} from '../shared/drivers/drivers.component';
import {DriverDetailsComponent} from '../company/drivers/driver-details/driver-details.component';
import {DriverUpsertComponent} from '../company/drivers/driver-upsert/driver-upsert.component';
import {ShiftsComponent} from '../shared/shifts/shifts.component';
import {ShiftDetailsComponent} from '../shared/shifts/parts/shift-details/shift-details.component';
import {SplitLayoutComponent} from '../layout/split-layout/split-layout.component';

const navigationRoutes: any[] = [
  {
    path: 'dispatchgroup/:id',
    icon: 'assignment',
    group: 'send',
    label: 'dashboard',
    canActivate: [AuthGuard],
    sort: 2,
    showInMenu: false,
    component: SplitLayoutComponent,
    children: [
      {
        path: '', component: DriversComponent, showInMenu: false, label: 'drivers', icon: 'group', context: 'company'
      },
      {path: 'drivers', component: DriversComponent, showInMenu: true, label: 'drivers', icon: 'group', context: 'company'},
      {path: 'drivers/:id/details', component: DriverDetailsComponent, showInMenu: false, context: 'company'},
      {path: 'drivers/:action', component: DriverUpsertComponent, showInMenu: false, context: 'company'},
      {path: 'drivers/:id/shifts', component: ShiftsComponent, showInMenu: false, context: 'company'},
      {path: 'drivers/:id/shifts/:shiftId', component: ShiftDetailsComponent, showInMenu: false, context: 'company'},
      {path: 'drivers/:id/:action', component: DriverUpsertComponent, showInMenu: false, context: 'company'},
      // {path: 'jobs', component: RidesComponent, showInMenu: true, label: 'jobs', icon: 'assignment_ind', context: 'company'},
      // {path: 'jobs/:id/details', component: RideDetailsComponent, showInMenu: false, context: 'company'},
      // {path: 'jobs/:action', component: RideEditComponent, showInMenu: false, context: 'company'},
      // {path: 'jobs/:id/log', component: RideLogComponent, showInMenu: false, context: 'company'},
      // {path: 'jobs/:id/edit', component: RideEditComponent, showInMenu: false, context: 'company'},
      // {path: 'jobs/:id/dispatch-log', component: RideDispatchLogComponent, showInMenu: false, context: 'company'}
    ],
    resolve: {
      currentRoute: NavigationService,
      company: CompanyResolver
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(navigationRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    DriverService,
    CompanyResolver,
    CanDeactivateGuard,
  ]
})
export class DispatchgroupRoutingModule {
}
