import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {TdLoadingService} from '@covalent/core/loading';
import {TdMediaService} from '@covalent/core/media';
import {TranslateService} from '@ngx-translate/core';
import {DriverService} from '../../../../services/driver.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../../../environments/environment';
import {Driver} from '../../../../models/driver';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {NavigationService} from '../../../../services/navigation.service';
import {DriverScore} from '../../../../models/driver-score';
import {RatingService} from '../../../../services/rating.service';
import {Rating} from '../../../../models/rating';
import {Vehicle} from '../../../../models/vehicle';
import {Company} from '../../../../models/company';
import {UtilityService} from '../../../../services/utility.service';

@Component({
  selector: 'app-driver-details',
  templateUrl: './driver-details.component.html',
  styleUrls: ['./driver-details.component.scss'],
  providers: [RatingService]
})
export class DriverDetailsComponent implements OnInit, OnDestroy, AfterViewInit {
  driver: Driver = new Driver;
  driverScore: DriverScore = new DriverScore;
  ratings: Rating[] = [];
  vehicles: Vehicle[] = [];

  company: Company;
  driverId: string;
  translations: string[];

  sub: Subscription;

  constructor(public media: TdMediaService,
              private _translateService: TranslateService,
              private _loadingService: TdLoadingService,
              private _ratingService: RatingService,
              private _navigationService: NavigationService,
              private _route: ActivatedRoute,
              private _driverService: DriverService,
              private _titleService: Title) {
    const self = this;
    /**
     * Subscribe to the route params
     */
    const {company} = this._route.parent.snapshot.data;
    UtilityService.setBrowserTimeStamp(this.company);
    this.company = company;

    this.sub = this._route.params.subscribe(params => {
      this.driverId = params['id'];

      _translateService.get(['driver_details', 'drivers', 'edit']).subscribe((translations: string[]) => {
        self.translations = translations;
        self._titleService.setTitle(translations['driver_details'] + environment.windowTitleSuffix);
        self._navigationService.setBodyTitle(translations['driver_details']);

        _navigationService.setSplitLayoutButtons([
          {
            'icon': 'list',
            'tooltip': self.translations['drivers'],
            'link': `/groups/${self.company.id}/driverlist/`
          },
          {
            'icon': 'edit',
            'tooltip': self.translations['edit'],
            'link': `/groups/${self.company.id}/drivers/${this.driverId}/edit`
          }
        ]);
      });


      if (this.driverId) {
        this.loadDriver();
        this.loadRatings();
      }
    });
  }

  ngOnInit() {
    this._loadingService.register('driver');
    this._loadingService.register('driver.score');
    this._loadingService.register('ratings');
  }

  ngAfterViewInit() {
    this.media.broadcast();
  }

  ngOnDestroy() {
    this._navigationService.setActionLink('');
  }

  loadDriver() {
    const filter = {
      'include': [
        'DriverScore',
      ]
    };

    this._driverService.get(this.driverId, filter, 'company').subscribe((driver: Driver) => {
      this._driverService.getAll({
        where: {
          phoneNumber: encodeURIComponent(driver.phoneNumber),
          companyId: null,
        },
        include: [
          'Vehicles'
        ]
      }, 'company').subscribe(ydaDrivers => {
        this.driver = driver;
        this.driverScore = driver.DriverScore;
        this.vehicles = ydaDrivers[0].Vehicles;
        this._loadingService.resolve('driver');
        this._loadingService.resolve('driver.score');
      }, (error) => {
        console.error(error);
        this._loadingService.resolve('driver');
        this._loadingService.resolve('driver.score');
      });
    }, (error) => {
      console.error(error);
      this._loadingService.resolve('driver');
      this._loadingService.resolve('driver.score');
    });
  }

  loadRatings() {
    const filter = {
      where: {
        driverId: this.driverId,
        companyId: this._route.parent.snapshot.params.id,
      },
      limit: 10,
      include: [
        'Ride'
      ],
    };

    this._ratingService.getAll(filter, 'company').subscribe((ratings: Rating[]) => {
      this.ratings = ratings;
      this._loadingService.resolve('ratings');
    }, (error) => {
      console.error(error);
      this._loadingService.resolve('ratings');
    })
  }

}
