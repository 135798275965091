import {Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild} from '@angular/core';
import {DaAppInstall} from '../../../../../../../../models/da-app-install';
import {ValidateAllFormFields} from '../../../../../../../../functions/validateAllFormFields';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TdLoadingService} from '@covalent/core/loading';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../../../../../../../services/navigation.service';
import {Title} from '@angular/platform-browser';
import {TdDialogService} from '@covalent/core/dialogs';
import {WebbookerService} from '../../../../../../../../services/webbooker.service';
import {Company} from '../../../../../../../../models/company';
import {environment} from '../../../../../../../../../environments/environment';
import {ucFirst} from '../../../../../../../../pipes/uc-first.pipe';
import {DaAppInstallService} from '../../../../../../../../services/da-app-install.service';
import {CountryService} from '../../../../../../../../services/country.service';
import {ProductService} from '../../../../../../../../services/tps/product.service';
import {Product} from '../../../../../../../../models/product';
import {CoolLocalStorage} from '@angular-cool/storage';
import {PricingRuleService} from '../../../../../../../../services/tps/pricing-rule.service';
import {GlobalFunctions} from '../../../../../../../../functions/functions';
import * as tpsDaAppInstallService from '../../../../../../../../services/tps/da-app-install.service';

@Component({
  selector: 'app-webbooker-tab',
  templateUrl: './webbooker-tab.component.html',
  styleUrls: ['./webbooker-tab.component.scss'],
  providers: [ProductService, PricingRuleService, tpsDaAppInstallService.DaAppInstallService]
})
export class WebbookerTabComponent extends ValidateAllFormFields implements OnInit, OnDestroy {
  @Input() daAppInstall: DaAppInstall;
  @Input() booker: any;
  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;
  user: any;
  priceError: string;
  removeEventListener: any;

  files = [];
  countries = [];
  mySelections = [];
  loaderName = 'webbooker';
  modelName = 'webbooker';
  list = 'webbookers';
  id: string;
  companyId: string;
  company: Company;
  myProducts: Product[];
  color: string;
  maxPassengers = 0;
  validationMessages = {
    'name': {
      'fieldName': 'name',
      'required': 'form_validation_required',
    },
    'pageTitle': {
      'fieldName': 'name',
      'required': 'form_validation_required',
    },
    'passengerCount': {
      'fieldName': 'passengerCount',
      'max': 'passengercount_to_high',
    },
    'webbookerUrl': {
      'fieldName': 'webbookerUrl',
      'required': 'form_validation_required',
    },
    'title': {
      'fieldName': 'title',
      'required': 'form_validation_required',
    },
    'text': {
      'fieldName': 'text',
      'required': 'form_validation_required',
    },
    'startDate': {
      'fieldName': 'startDate',
      'required': 'form_validation_required',
    },
    'endDate': {
      'fieldName': 'startDate',
      'required': 'form_validation_required',
      'error_start_and_end_date_same': 'error_start_and_end_date_same'
    },
    'successUrl': {
      'fieldName': 'successUrl',
      'pattern': 'form_url_not_valid',
    },
  };
  form: UntypedFormGroup;
  formErrors: any = {
    name: '',
  };
  paymentMethods: any[] = [
    {
      'id': 'CASHTODRIVER',
      'description': 'payment_method_field_value_cash',
      'directProcess': true
    },
    {
      'id': 'CREDITCARDTODRIVER',
      'description': 'payment_method_field_value_credit_card',
      'directProcess': true
    },
    {
      'id': 'DEBITCARDTODRIVER',
      'description': 'payment_method_field_value_debit_card',
      'directProcess': true
    },
    {
      'id': 'PAYMENTLINK',
      'description': 'payment_method_field_value_payment_link',
      'directProcess': true
    },
    {
      'id': 'ONACCOUNT',
      'description': 'payment_method_field_value_onaccount',
      'directProcess': true
    },
    {
      'id': 'STRIPE',
      'description': 'payment_method_field_value_stripe',
      'directProcess': false,
      'type': 'online'
    },
    {
      'id': 'MULTISAFEPAY',
      'description': 'payment_method_field_value_msp',
      'directProcess': false,
      'type': 'online'
    },
    {
      'id': 'WIPAY',
      'description': 'payment_method_field_value_wipay',
      'directProcess': false,
      'type': 'online'
    }
  ];
  checkArray = [];
  environment = environment;
  protected readonly ValidateAllFormFields = ValidateAllFormFields;

  constructor(public snackBar: MatSnackBar,
              private _vault: CoolLocalStorage,
              private _loadingService: TdLoadingService,
              private _formBuilder: UntypedFormBuilder,
              private _route: ActivatedRoute,
              private _router: Router,
              private _snackbar: MatSnackBar,
              private _translateService: TranslateService,
              private _navigationService: NavigationService,
              private _titleService: Title,
              private _productService: ProductService,
              private _dialogService: TdDialogService,
              private _webbookerService: WebbookerService,
              private _countryService: CountryService,
              private _daAppInstallService: DaAppInstallService,
              private _tpsDaAppInstallService: tpsDaAppInstallService.DaAppInstallService,
              private _pricingRuleService: PricingRuleService,
              private renderer: Renderer2,
              private elRef: ElementRef
  ) {
    super();
    this.loadErrorTranslations(_translateService);
    const {company} = this._route.parent.snapshot.data;
    this.company = company;

    this.user = this._vault.getObject(`${environment.vaultPrefix}.user`);
    this._route.parent.params.subscribe(parentParams => {
      this.companyId = parentParams['id'];
      this._route.params.subscribe(params => {
        this.id = params['id'];
        _translateService.get([
          'webbooker_updated_message',
          'invalid_image_extention',
          'delete_x',
          'message_delete_x',
          'x_deleted',
          'ok',
          'bookingtype_error',
          'stripe_not_connected_alert_title',
          'stripe_not_connected_alert_text',
          'stripe_not_connected_alert_confirm',
          'confirm_remove_logo',
          'web_booker_settings_no_gateways_invalid',
          'web_booker_settings_invalid'
        ]).subscribe((translations: any) => {
          this.translations = translations;
        });
      });
    });

    this._countryService.getAll().subscribe((countries) => {
      this.countries = countries;
      this.countries.sort((a, b) => {
        return (a['name'] > b['name']) ? 1 : (a['name'] < b['name']) ? -1 : 0;
      });
    });

    /**
     * Remove online paymentmethods type
     * If we do not have the propper PSP installation
     */
    this.paymentMethods = this.paymentMethods.filter((m) => {
      if (m.type !== 'online') {
        return m;
      } else {
        if (m.id === 'STRIPE' && this.company.stripeAccountId) {
          return m;
        } else if (m.id === 'WIPAY' && this.company.wipayAccountId) {
          return m;
        } else if (m.id === 'MULTISAFEPAY' && this.company.mspAccountId) {
          return m;
        }
      }
    });
  }

  ngOnInit(): void {
    this._productService.getAll({where: {companyId: this.companyId}})
      .subscribe((products) => {
        this.myProducts = products;
        if (this.myProducts.length > 0) {
          this.myProducts.forEach((p) => {
            if (this.maxPassengers < p.maxPassengers) {
              this.maxPassengers = p.maxPassengers;
            }
          })
        } else {
          this.maxPassengers = 8;
        }

        /**
         * Set up our form
         */
        if (this.booker.form && this.booker.form.config && this.booker.form.config.payment && this.booker.form.config.payment.gateways) {
          if (this.booker.form.config.payment.gateways.length > 0) {
            this.booker.form.config.payment.gateways.forEach((gateway: any) => {
              if (gateway && gateway.id) {
                this.checkArray.push(gateway.id);
              }
            });
          }

          // if (this.booker.form.config.payment.PSP === 'Stripe') {
          //   this.checkArray.push('STRIPE');
          // }
        }
        let mandatoryDestination = false;
        let allowBrowserLocation = false;
        let allowReturnBookings = false;
        let departureHouseNumberRequired = false;
        let destinationHouseNumberRequired = false;
        let departureWorkareaCheck = false;
        let destinationWorkareaCheck = false;
        let luggageOption = false;
        let productSummery = false;
        let termsUrl = '';
        let privacyUrl = '';
        let successUrl = '';
        let returnUrl = '';
        let documentField: any;

        if (this.booker.form.config && this.booker.form.config.parts && this.booker.form.config.parts[0]
          && this.booker.form.config.parts[0].fields) {
          // tslint:disable-next-line:no-shadowed-variable
          const departureSettings = this.booker.form.config.parts[0].fields.filter((f) => {
            return (f.property === 'departure');
          })[0];
          const destinationSettings = this.booker.form.config.parts[0].fields.filter((f) => {
            return (f.property === 'destination');
          })[0];
          mandatoryDestination = destinationSettings.required;
          allowBrowserLocation = destinationSettings.allowBrowserLocation;
          destinationHouseNumberRequired = destinationSettings.houseNumberRequired;
          departureHouseNumberRequired = departureSettings.houseNumberRequired;
          departureWorkareaCheck = departureSettings.workareaCheck;
          destinationWorkareaCheck = destinationSettings.workareaCheck;

          luggageOption = !!(this.booker.form.config.parts[0].fields.filter((f) => {
            return (f.property === 'luggage');
          })[0]);
        }

        if (this.booker.form.config.payment.gateways) {
          this.paymentMethods.forEach((gateway, index) => {
            const isSelected = this.booker.form.config.payment.gateways.filter((p) => {
              return p.id === gateway.id;
            })[0];
            if (isSelected) {
              this.paymentMethods[index] = isSelected;
            }
          });
        }


        if (this.booker.form.config && this.booker.form.config.successUrl) {
          successUrl = this.booker.form.config.successUrl;
        }
        if (this.booker.form.config && this.booker.form.config.returnUrl) {
          returnUrl = this.booker.form.config.returnUrl;
        }
        if (this.booker.form.config && this.booker.form.config.parts && this.booker.form.config.parts[1]) {
          allowReturnBookings = this.booker.form.config.parts[1].showReturn;
        }
        if (this.booker.form.config && this.booker.form.config.parts && this.booker.form.config.parts[3]) {
          documentField = this.booker.form.config.parts[3].fields.filter((f) => {
            return (f.property === 'acceptTermsAndConditions');
          })[0];
        }
        if (this.booker.form.config && this.booker.form.config.parts && this.booker.form.config.parts[1]) {
          productSummery = this.booker.form.config.parts[1].productSummery;
        }

        if (this.booker.form.config && this.booker.form.config.parts && this.booker.form.config.parts[3]) {
          termsUrl = documentField.documentUrls[0];
          this.booker.termsUrl = termsUrl;
        }
        if (this.booker.form.config && this.booker.form.config.parts && this.booker.form.config.parts[3]) {
          privacyUrl = documentField.documentUrls[1];
          this.booker.privacyUrl = privacyUrl;
        }
        const departureSettings = this.booker.form.config.parts[0].fields.filter((f) => {
          return (f.property === 'departure');
        })[0];

        const passengers = this.booker.form.config.parts[0].fields.filter((f) => {
          return (f.property === 'passengerCount');
        })[0].values;

        let mandatoryFlightNumber = this.booker.form.config.parts[0].fields.filter((f) => {
          return (f.property === 'flightNumber');
        })[0];

        if (mandatoryFlightNumber.requiredAirportPickup && mandatoryFlightNumber.requiredAirportDestination) {
          mandatoryFlightNumber = 'mandatory_airport';
        } else if (mandatoryFlightNumber.requiredAirportPickup) {
          mandatoryFlightNumber = 'mandatory_airport_pickup';
        } else if (mandatoryFlightNumber.requiredAirportDestination) {
          mandatoryFlightNumber = 'mandatory_airport_dropoff';
        } else {
          mandatoryFlightNumber = 'optional';
        }
        const urlRegEx = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
        this.form = this._formBuilder.group({
          name: [this.booker.name, [Validators.required]],
          webbookerUrl: [this.booker.form.config['customUrl'], [Validators.required, Validators.pattern(/^[a-zA-Z0-9_-]+$/)]],
          pageTitle: [(this.booker.form && this.booker.form.config && this.booker.form.config.pageTitle ? this.booker.form.config.pageTitle : this.booker.name), [Validators.required]],
          mainColor: [(this.booker.form && this.booker.form.config ? this.booker.form.config.styles.colors['global-title-color'] : ''), [Validators.required]],
          gtmId: [this.booker.form.config['gtmId'], []],
          countries: [(departureSettings['googleComponentRestrictions'][0] ? departureSettings['googleComponentRestrictions'] : ['all']), []],
          minimumOrderTime: [this.booker.form.config['minimumOrderTime'], [Validators.required]],
          passengerLogin: [this.booker.form.config['passengerLogin'], []],
          allowBrowserLocation: [allowBrowserLocation, []],
          manditoryDestination: [mandatoryDestination, []],
          showRouteMap: [this.booker.form.config['showRouteMap'], []],
          requestedDestinationDateOffset: [this.booker.form.config['requestedDestinationDateOffset'], []],
          airportDates: [(this.booker.form.config['airportDates'] ? this.booker.form.config['airportDates'] : 'none'), []],
          mandatoryFlightNumber: [mandatoryFlightNumber, []],
          luggageOption: [luggageOption, []],
          passengerCount: [(passengers && passengers[passengers.length - 1] && passengers[passengers.length - 1].value ? passengers[passengers.length - 1].value : 8), [Validators.required, Validators.max]],
          bookingTypesRoute: [this.booker.form.config['routeBookings'], []],
          bookingTypesHourly: [this.booker.form.config['hourlyBookings'], []],
          bookingHourlyDestination: [this.booker.form.config['bookingHourlyDestination'], []],
          hourlyMinTime: [(this.booker.form.config['hourlyMinTime'] ? this.booker.form.config['hourlyMinTime'] : 1), []],
          hourlyMaxTime: [(this.booker.form.config['hourlyMaxTime'] ? this.booker.form.config['hourlyMaxTime'] : 24), []],
          orderTimesHandler: [(this.booker.form.config['orderTimesHandler'] ? this.booker.form.config['orderTimesHandler'] : 'availability'), []],
          departureHouseNumberRequired: [departureHouseNumberRequired, []],
          destinationHouseNumberRequired: [destinationHouseNumberRequired, []],
          departureWorkareaCheck: [departureWorkareaCheck, []],
          destinationWorkareaCheck: [destinationWorkareaCheck, []],
          productSummery: [productSummery, []],
          allowReturnBookings: [allowReturnBookings, []],
          allowStopOvers: [this.booker.form.config['allowStopOvers'], []],
          paymentmethods: [null, []],
          termsUrl: [termsUrl, []],
          privacyUrl: [privacyUrl, []],
          successUrl: [successUrl, [Validators.pattern(urlRegEx)]],
          returnUrl: [returnUrl, [Validators.pattern(urlRegEx)]],
          conversionCode: [this.booker.form.config.conversionCode, []],
          image: [null, []],
          logo: [this.booker.logo, []],
        }, {validators: []});

        // console.log(this.form.controls.countries.value);

        this.conditionalFormatting();
        GlobalFunctions.checkWebBookerForError(this._daAppInstallService, this._tpsDaAppInstallService, this.booker, this.company).then((errors) => {
          this.priceError = errors;
        });
      });
  }

  changed() {
    const hasAll = this.form.controls.countries.value.filter((d) => {
      return (d === 'all');
    })[0];
    if (hasAll) {
      this.mySelections = [];
      this.form.controls.countries.setValue(['all'])
    } else if (this.form.controls.countries.value.length < 6) {
      this.mySelections = this.form.controls.countries.value;
    } else {
      this.form.controls.countries.setValue(this.mySelections);
    }
  }

  onCheckboxChange(e) {
    if (e.target.checked) {
      this.checkArray.push(e.target.value);
    } else {
      this.checkArray.forEach((item: string, i: number) => {
        if (item === e.target.value) {
          delete this.checkArray[i];
          return;
        }
      });
    }
  }

  updateMainColor(color) {
    this.form.controls['mainColor'].setValue(color);
  }

  conditionalFormatting() {

  }

  save(): void {
    const self = this;
    const data = this.form.value;
    const webbooker = this.booker;
    webbooker.form.config.styles.colors['global-title-color'] = data.mainColor;
    webbooker.form.name = data.name;
    webbooker.form.companyName = this.company.name;
    webbooker.form.config.customUrl = data.webbookerUrl;
    webbooker.form.config.gtmId = data.gtmId;
    webbooker.form.config.minimumOrderTime = data.minimumOrderTime;
    webbooker.form.config.passengerLogin = data.passengerLogin;
    webbooker.form.config.pageTitle = data.pageTitle;

    if (data.successUrl) {
      webbooker.form.config.successUrl = data.successUrl;
    } else {
      webbooker.form.config.successUrl = null;
    }

    let index, indexDeparture, indexluggage, indexPassengerCnt, indexFlightNumber, indexFlightNumberReturn;
    if (webbooker.form.config && webbooker.form.config.parts && webbooker.form.config.parts[0]
      && webbooker.form.config.parts[0].fields) {
      // Loop through each field to find the index of specific properties
      webbooker.form.config.parts[0].fields.forEach(function (obj, idx) {
        if (obj.property === 'departure') {
          // Set the index of the departure field
          indexDeparture = idx;
          return true;
        }
        if (obj.property === 'destination') {
          // Set the index of the destination field
          index = idx;
          return true;
        }
        if (obj.property === 'luggage') {
          // Set the index of the luggage field
          indexluggage = idx;
          return true;
        }
        if (obj.property === 'passengerCount') {
          // Set the index of the passenger count field
          indexPassengerCnt = idx;
          return true;
        }
        if (obj.property === 'flightNumber') {
          // Set the index of the passenger count field
          indexFlightNumber = idx;
          return true;
        }
      });
    }

    if (webbooker.form.config && webbooker.form.config.parts && webbooker.form.config.parts[1]
      && webbooker.form.config.parts[1].fields) {
      // Loop through each field to find the index of specific properties
      webbooker.form.config.parts[1].fields.forEach(function (obj, idx) {
        if (obj.property === 'returnFlightNumber') {
          // Set the index of the passenger count field
          indexFlightNumberReturn = idx;
          return true;
        }
      });
    }

    if (index || indexDeparture) {
      const hasAll = this.form.controls.countries.value.filter((d) => {
        return (d === 'all');
      })
      if (hasAll.length > 0) {
        // Clear the countries array if 'all' is selected
        data.countries = [];
      }

      webbooker.form.config.parts[0].fields[indexDeparture].allowBrowserLocation = data.allowBrowserLocation;
      webbooker.form.config.parts[0].fields[indexDeparture].houseNumberRequired = data.departureHouseNumberRequired;
      webbooker.form.config.parts[0].fields[indexDeparture].googleComponentRestrictions = data.countries;
      webbooker.form.config.parts[0].fields[indexDeparture].workareaCheck = data.departureWorkareaCheck;

      webbooker.form.config.parts[0].fields[index].required = data.manditoryDestination;
      webbooker.form.config.parts[0].fields[index].allowBrowserLocation = data.allowBrowserLocation;
      webbooker.form.config.parts[0].fields[index].workareaCheck = data.destinationWorkareaCheck;
      webbooker.form.config.parts[0].fields[index].googleComponentRestrictions = data.countries;
      webbooker.form.config.parts[0].fields[index].houseNumberRequired = data.destinationHouseNumberRequired;
    }

    if (data.mandatoryFlightNumber === 'mandatory_airport') {
      webbooker.form.config.parts[0].fields[indexFlightNumber].requiredAirportPickup = true;
      webbooker.form.config.parts[0].fields[indexFlightNumber].requiredAirportDestination = true;

      webbooker.form.config.parts[1].fields[indexFlightNumberReturn].requiredAirportPickup = true;
      webbooker.form.config.parts[1].fields[indexFlightNumberReturn].requiredAirportDestination = true;
    } else if (data.mandatoryFlightNumber === 'mandatory_airport_pickup') {
      webbooker.form.config.parts[0].fields[indexFlightNumber].requiredAirportPickup = true;
      webbooker.form.config.parts[0].fields[indexFlightNumber].requiredAirportDestination = false;

      webbooker.form.config.parts[1].fields[indexFlightNumberReturn].requiredAirportPickup = true;
      webbooker.form.config.parts[1].fields[indexFlightNumberReturn].requiredAirportDestination = false;
    } else if (data.mandatoryFlightNumber === 'mandatory_airport_dropoff') {
      webbooker.form.config.parts[0].fields[indexFlightNumber].requiredAirportDestination = true;
      webbooker.form.config.parts[0].fields[indexFlightNumber].requiredAirportPickup = false;

      webbooker.form.config.parts[1].fields[indexFlightNumberReturn].requiredAirportDestination = true;
      webbooker.form.config.parts[1].fields[indexFlightNumberReturn].requiredAirportPickup = false;
    } else if (data.mandatoryFlightNumber === 'optional') {
      webbooker.form.config.parts[0].fields[indexFlightNumber].requiredAirportDestination = false;
      webbooker.form.config.parts[0].fields[indexFlightNumber].requiredAirportPickup = false;

      webbooker.form.config.parts[1].fields[indexFlightNumberReturn].requiredAirportDestination = false;
      webbooker.form.config.parts[1].fields[indexFlightNumberReturn].requiredAirportPickup = false;
    }

    if (data.passengerCount) {
      webbooker.form.config.parts[0].fields[indexPassengerCnt].values = [];
      for (let x = 1; x <= data.passengerCount; x++) {
        webbooker.form.config.parts[0].fields[indexPassengerCnt].values.push({
          'value': x,
          'label': (x === 1 ? 'passengers_field_value_singular' : 'passengers_field_value_plural')
        });
      }
    }

    if (data.luggageOption) {
      const configLuggageOption = environment.webBookerDefault.luggage.values.splice(0, 2)
      const maxLuggage = data.passengerCount;
      for (let x = 1; x <= maxLuggage; x++) {
        configLuggageOption.push({
          'value': x,
          'label': (x === 1 ? 'luggage_field_value_singular' : 'luggage_field_value_plural')
        });
      }
      environment.webBookerDefault.luggage.values = configLuggageOption;
      if (!indexluggage) {
        webbooker.form.config.parts[0].fields.push(environment.webBookerDefault.luggage);
      } else {
        webbooker.form.config.parts[0].fields[indexluggage] = environment.webBookerDefault.luggage;
      }
    } else if (indexluggage) {
      webbooker.form.config.parts[0].fields.splice(indexluggage, 1);
    }

    webbooker.form.config.routeBookings = data.bookingTypesRoute;
    webbooker.form.config.hourlyBookings = data.bookingTypesHourly;
    webbooker.form.config.allowStopOvers = data.allowStopOvers;
    webbooker.form.config.showRouteMap = data.showRouteMap;
    webbooker.form.config.airportDates = data.airportDates;
    webbooker.form.config.hourlyMinTime = data.hourlyMinTime;
    webbooker.form.config.hourlyMaxTime = data.hourlyMaxTime;
    webbooker.form.config.orderTimesHandler = data.orderTimesHandler;

    if (data.bookingTypesHourly) {
      webbooker.form.config.bookingHourlyDestination = data.bookingHourlyDestination;
    } else {
      webbooker.form.config.bookingHourlyDestination = false;
    }

    webbooker.form.config.parts[1].showReturn = data.allowReturnBookings;
    webbooker.form.config.parts[1].productSummery = data.productSummery;
    if (webbooker.form.config.parts[3].fields[2]) {
      webbooker.form.config.parts[3].fields[2].documentUrls = [
        data.termsUrl, data.privacyUrl
      ];
    } else {
      if (webbooker.form.config.parts[3].fields[1]) {
        webbooker.form.config.parts[3].fields[1].documentUrls = [
          data.termsUrl, data.privacyUrl
        ];
      }
    }
    this.booker.termsUrl = data.termsUrl;
    this.booker.privacyUrl = data.privacyUrl;

    webbooker.form.config.payment.gateways = [];
    webbooker.form.config.payment.PSP = '';

    if (this.company.stripeAccountId) {
      webbooker.form.config.payment.PSP = 'Stripe';
    } else if (this.company.stripeAccountId) {
      webbooker.form.config.payment.PSP = 'Multisafepay';
    } else if (this.company.stripeAccountId) {
      webbooker.form.config.payment.PSP = 'Wipay';
    }

    this.checkArray.forEach((method) => {
      if (method === 'STRIPE') {
        webbooker.form.config.payment.stripeAccountId = this.company.stripeAccountId;
        if (this.company.country === 'NL') {
          webbooker.form.config.payment.stripeMethods = ['card', 'ideal'];
        } else {
          webbooker.form.config.payment.stripeMethods = ['card'];
        }
      }

      const myMethod = self.paymentMethods.filter((f) => {
        return (f.id === method)
      })[0];

      if (this.company && this.company.settings && this.company.settings.preAuthInTaxi && ['CASHTODRIVER', 'CREDITCARDTODRIVER', 'DEBITCARDTODRIVER'].includes(myMethod.id)) {
        myMethod.preAuth = true;
        myMethod.psp = 'STRIPE';
      }
      webbooker.form.config.payment.gateways.push(myMethod);
    });

    if (webbooker.form.config.payment.PSP === '') {
      webbooker.form.config.payment.stripeMethods = [];
    }

    if (!data.bookingTypesRoute && !data.bookingTypesHourly) {
      this.formErrors['bookingType'] = this.translations['bookingtype_error'];
      this.form.controls['bookingTypesRoute'].markAsDirty();
      this.form.controls['bookingTypesRoute'].markAsTouched();

      self.snackBar.open(ucFirst(self.translations['bookingtype_error']), self.translations['ok'], {
        duration: 3000
      });
      return;
    } else {
      this.formErrors['bookingType'] = '';
    }

    if (this.checkArray.length === 0) {
      this._dialogService.openAlert({
        message: self.translations['web_booker_settings_no_gateways_invalid'],
        disableClose: true,
        closeButton: self.translations['ok'],
      }).afterClosed().subscribe(() => {
      });
    } else if (!this.form.valid) {
      this._dialogService.openAlert({
        message: self.translations['web_booker_settings_invalid'],
        disableClose: true,
        closeButton: self.translations['ok'],
      }).afterClosed().subscribe(() => {
      });
      return this.validateAllFormFields(this.form);
    }
    /**
     * Call the API
     */
    const settings = self.daAppInstall.settings;
    settings.name = webbooker.form.name;

    if (this.form.get('image').value) {
      self._loadingService.register(this.loaderName);
      const formData = new FormData();
      if (this.form.get('image').value) {
        const metaData = new URLSearchParams({
          containerUrl: `https://aaf600a31c6b995d2515-92f7b59857183e247c1f0ac789a030e0.ssl.cf3.rackcdn.com/${webbooker.form.id}`,
          container: `order-form/${webbooker.form.id}`,
          filename: `logo`,
        }).toString();
        formData.append('file', this.form.get('image').value);
        self._webbookerService.upload(webbooker.form.id, formData, metaData).subscribe((result) => {
          webbooker.form.logo = `${result.imageSrc}?${self.randString()}`;
          self.booker.form.config.logo = webbooker.form.logo;
          self._daAppInstallService.update(self.daAppInstall.id, {
            settings
          }, 'company').subscribe(() => {
            self._webbookerService.update(webbooker.form.id, webbooker.form).subscribe(() => {
              setTimeout(function () {
                self._loadingService.resolve(self.loaderName);
                self.snackBar.open(ucFirst(self.translations['webbooker_updated_message']), 'OK', {
                  duration: 3000
                });
              }, 500);
            }, error => {
              self._loadingService.resolve(this.loaderName);
            });
          });
        }, () => {
          self._loadingService.resolve(self.loaderName);
          self.snackBar.open(ucFirst(self.translations['invalid_image_extention']), 'OK', {
            duration: 3000
          });
        });
      } else {
        self._webbookerService.update(webbooker.form.id, webbooker.form, {'Authorization': `Bearer ${webbooker.JWTtoken}`}).subscribe(() => {
          setTimeout(function () {
            self._loadingService.resolve(self.loaderName);
            self.snackBar.open(ucFirst(self.translations['webbooker_updated_message']), 'OK', {
              duration: 3000
            });
          }, 500);
        }, error => {
          console.error(error);
          this._loadingService.resolve(this.loaderName);
        });
      }
    } else {
      self._loadingService.register(this.loaderName);
      self._webbookerService.update(webbooker.form.id, webbooker.form, {'Authorization': `Bearer ${webbooker.JWTtoken}`}).subscribe(() => {
        self._daAppInstallService.update(self.daAppInstall.id, {
          settings
        }, 'company').subscribe(() => {
          setTimeout(function () {
            self._loadingService.resolve(self.loaderName);
            self.snackBar.open(ucFirst(self.translations['webbooker_updated_message']), 'OK', {
              duration: 3000
            });
          }, 500);
        });
      }, error => {
        console.error(error);
        this._loadingService.resolve(this.loaderName);
      });
    }
  }

  makeRandom(lengthOfCode: number, possible: string) {
    let text = '';
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  randString() {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    const lengthOfCode = 40;
    return this.makeRandom(lengthOfCode, possible)
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.patchValue({
        image: file
      });
    }
  }

  connectStripe() {
    this._dialogService.openConfirm({
      title: this.translations['stripe_not_connected_alert_title'],
      message: this.translations['stripe_not_connected_alert_text'],
      acceptButton: this.translations['stripe_not_connected_alert_confirm'],
      disableClose: true,
      cancelButton: this.translations['cancel']
    }).afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          this._router.navigate([`/${(this.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${this.companyId}/general/startStripe`]);
        }
      });
  }

  checkUniqueUrl() {
    this._webbookerService.getAll({'where': {'config.customUrl': this.form.controls['webbookerUrl'].value}}).subscribe((webbooker) => {
      if (webbooker && webbooker.length > 0 && !(webbooker.length === 1 && webbooker[0].id === this.booker.form.id)) {
        this.formErrors.webbookerUrl = 'webbooker_url_no_unique';
      }
    });
  }

  removeLogo() {
    this._dialogService.openConfirm({
      message: this.translations['confirm_remove_logo'],
      acceptButton: this.translations['ok'],
      disableClose: true,
      cancelButton: this.translations['cancel']
    }).afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          console.log(this.booker.form.config.logo);
          let filename = this.booker.form.config.logo.split('/');
          filename = filename[filename.length - 1].split('?')[0];
          this._webbookerService.deleteLogo(this.booker.form.id, {}, new URLSearchParams({
            url: this.booker.form.config.logo,
            container: `order-form/${this.booker.form.id}`,
            filename: `${filename}`
          })).subscribe(() => {
          })
          this.booker.form.config.logo = '';
        }
      });
  }

  ngAfterViewInit() {
    const self = this;
    setTimeout(function () {
      self.removeEventListener = self.renderer.listen(self.elRef.nativeElement, 'click', (event) => {
        if (event && event.target && event.target && event.target.attributes && event.target.attributes.link) {
          if (event.target instanceof HTMLAnchorElement) {
            // Prevent opening anchors the default way
            event.preventDefault();
            // Your custom anchor click event handler
            self.openLink(event);
          }
        }
      });
    }, 1000);
  }

  openLink(event) {
    this._router.navigate([
      `/groups/${this.companyId}/${event.target.attributes.link.nodeValue}`
    ]);
  }

  ngOnDestroy() {
    this._navigationService.setActionLink('');
    if(self.removeEventListener) {
      this.removeEventListener();
    }
  }

  protected readonly Math = Math;
}
