import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-ride-debtor-data',
  templateUrl: './ride-debtor-data.component.html',
  styleUrls: ['./ride-debtor-data.component.scss']
})
export class RideDebtorDataComponent implements OnInit {

  @Input() debtor: any;
  name: string;

  constructor() {
  }

  ngOnInit() {
    this.name = `${this.debtor.firstName} ${this.debtor.lastName} ${(this.debtor.companyName ? `(${this.debtor.companyName}` : '')}`;
  }

}
