import {Component, Input, OnInit} from '@angular/core';
import {Company} from '../../../../../models/company';
import {TranslateService} from '@ngx-translate/core';
import {TdDialogService} from '@covalent/core/dialogs';
import {UpdateGoogleKeyDialogComponent} from '../dialog/update-google-key-dialog/update-google-key-dialog.component';

@Component({
  selector: 'app-google-api-key',
  templateUrl: './google-api-key.component.html',
  styleUrls: ['./google-api-key.component.scss']
})
export class GoogleApiKeyComponent implements OnInit {
  @Input() company: Company;
  googleClientSecret: string;
  backendKey: string;
  googleClientId: string;
  active: boolean;
  translations: string[] = [];

  constructor(
    private _translateService: TranslateService,
    private _dialogService: TdDialogService,
  ) {
    _translateService.get([
      'cloud_connect_googlekey_updated',
      'cloud_connect_googlekey_error',
      'cloud_connect_googlekey_active',
      'cloud_connect_googlekey_inactive',
      'cloud_connect_googlekey_moreinfo',
    ]).subscribe((translations: string[]) => {
      this.translations = translations;
    });
  }

  loadData(): void{
    this.googleClientId = (this.company && this.company.googleKey ? this.company.googleKey['clientId'] : '');
    this.googleClientSecret = (this.company && this.company.googleKey ? this.company.googleKey['clientSecret'] : '');
    this.backendKey = (this.company && this.company.googleKey ? this.company.googleKey['backendKey'] : '');
    this.active = (this.company && this.company.googleKey ? this.company.googleKey['active'] : false);
  }

  ngOnInit(): void {
    this.loadData();
  }

  openGoogleKey(): void {
    this._dialogService.open(UpdateGoogleKeyDialogComponent, {
      maxHeight: '80%',
      maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
      data: {parent: this},
    });
  }
}
