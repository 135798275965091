import {Component, OnInit} from '@angular/core';
import {ITdDataTableSortChangeEvent, TdDataTableService, TdDataTableSortingOrder} from '@covalent/core/data-table';
import {IPageChangeEvent} from '@covalent/core/paging';
import {TdLoadingService} from '@covalent/core/loading';
import {TdMediaService} from '@covalent/core/media';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';
import {RatingService} from '../../../services/rating.service';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Rating} from '../../../models/rating';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilityService} from '../../../services/utility.service';
import {NavigationService} from '../../../services/navigation.service';

@Component({
  selector: 'app-ratings',
  templateUrl: './ratings.component.html',
  styleUrls: ['./ratings.component.scss'],
  providers: [RatingService]
})
export class RatingsComponent implements OnInit {

  driver: any;
  commentMaxLength = 40;
  activeTab = 0;
  maxScore: any[] = new Array(5);
  driverIds: string[];

  driverData: any[] = [];
  driverFilteredData: any[] = [];
  driverFilteredTotal: number;

  driverSearchTerm = '';
  driverFromRow = 1;
  driverCurrentPage = 1;
  driverPageSize = 20;
  driverSortBy = 'created';
  driverSortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;

  operatorData: any[] = [];
  operatorFilteredData: any[] = [];
  operatorFilteredTotal: number;

  operatorSearchTerm = '';
  operatorFromRow = 1;
  operatorCurrentPage = 1;
  operatorPageSize = 20;
  operatorSortBy = 'created';
  operatorSortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;

  driverColumns = [
    {name: 'created', label: 'created', sortable: true, active: true},
    {name: 'score', label: 'score', sortable: false, active: false},
    {name: 'comment', label: 'comment', sortable: false, active: false},
    {name: 'ratedBy', label: 'rated_by', sortable: false, active: false},
    {name: 'jobOwner', label: 'job_owner', sortable: false, active: false},
    {name: 'reference', label: 'reference', sortable: false, active: true},
  ];

  operatorColumns = [
    {name: 'created', label: 'created', sortable: true, active: true},
    {name: 'score', label: 'score', sortable: false, active: false},
    {name: 'comment', label: 'comment', sortable: false, active: false},
    {name: 'driverName', label: 'driver', sortable: false, active: false},
    {name: 'ratedBy', label: 'rated_by', sortable: false, active: false},
    {name: 'jobOwner', label: 'job_owner', sortable: false, active: false},
    {name: 'reference', label: 'reference', sortable: false, active: false}
  ];

  constructor(public media: TdMediaService,
              private loadingService: TdLoadingService,
              private dataTableService: TdDataTableService,
              private ratingService: RatingService,
              private vault: CoolLocalStorage,
              private _navigationService: NavigationService,
              private route: ActivatedRoute,
              private router: Router,
              private translateService: TranslateService,
              private titleService: Title) {
    translateService.get('my_ratings').subscribe((translation: string) => {
      this.titleService.setTitle(translation + environment.windowTitleSuffix);
      this._navigationService.setBodyTitle(translation);

    });
    const tab = this.route.snapshot.params.tab;
    this.activeTab = (typeof tab === 'undefined' ? 0 : (tab === 'passenger' ? 0 : 1));
  }

  ngOnInit() {
    this.driverIds = this.route.snapshot.data.driverIds;
    this.driver = this.vault.getObject(`${environment.vaultPrefix}.driver`);
    this.loadingService.register('ratings.list');
    this.loadData();
  }

  onTabChange(event: MatTabChangeEvent): void {
  }

  driverSort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.driverSortBy = sortEvent.name;
    this.driverSortOrder = sortEvent.order;

    if (sortEvent.name === 'created') {
      this.driverFilteredData = UtilityService.tableDateSort(this.driverFilteredData, 'created', sortEvent.order);
    } else {
      this.driverFilter();
    }
  }

  driverPage(pagingEvent: IPageChangeEvent): void {
    this.driverFromRow = pagingEvent.fromRow;
    this.driverCurrentPage = pagingEvent.page;
    this.driverPageSize = pagingEvent.pageSize;
    this.driverFilter();
  }

  driverFilter(): void {
    let newData: any[] = [...this.driverData];
    newData = this.dataTableService.filterData(newData, this.driverSearchTerm, true);
    this.driverFilteredTotal = newData.length;
    if (this.driverSortBy === 'created') {
      newData = UtilityService.tableDateSort(newData, 'created', this.driverSortOrder);
    } else {
      newData = this.dataTableService.sortData(newData, this.driverSortBy, this.driverSortOrder);
    }
    newData = this.dataTableService.pageData(newData, this.driverFromRow, this.driverCurrentPage * this.driverPageSize);
    this.driverFilteredData = newData;
  }

  operatorSort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.operatorSortBy = sortEvent.name;
    this.operatorSortOrder = sortEvent.order;

    if (sortEvent.name === 'created') {
      this.operatorFilteredData = UtilityService.tableDateSort(this.operatorFilteredData, 'created', sortEvent.order);
    } else {
      this.operatorFilter();
    }
  }

  operatorPage(pagingEvent: IPageChangeEvent): void {
    this.operatorFromRow = pagingEvent.fromRow;
    this.operatorCurrentPage = pagingEvent.page;
    this.operatorPageSize = pagingEvent.pageSize;
    this.operatorFilter();
  }

  operatorFilter(): void {
    let newData: any[] = this.operatorData;
    newData = this.dataTableService.filterData(newData, this.operatorSearchTerm, true);
    this.operatorFilteredTotal = newData.length;
    if (this.operatorSortBy === 'created') {
      newData = UtilityService.tableDateSort(newData, 'created', this.operatorSortOrder);
    } else {
      newData = this.dataTableService.sortData(newData, this.operatorSortBy, this.operatorSortOrder);
    }
    newData = this.dataTableService.pageData(newData, this.operatorFromRow, this.operatorCurrentPage * this.operatorPageSize);
    this.operatorFilteredData = newData;
  }

  search(searchTerm: string): void {
    this.driverSearchTerm = searchTerm;
    this.driverFilter();
    this.operatorSearchTerm = searchTerm;
    this.operatorFilter();
  }

  loadData() {
    const queryArray = [];
    queryArray.push({
      where: {
        driverId: {
          inq: this.driverIds
        }
      },
      include: [
        {'Ride': 'Owner'},
        'Passenger',
        'Owner',
        'Creator',
        'Driver'
      ]
    });
    queryArray.push({
      where: {
        ownerId: {
          inq: this.driverIds
        }
      },
      include: [
        {'Ride': 'Owner'},
        'Passenger',
        'Owner',
        'Creator',
        'Driver'
      ]
    });
    queryArray.push({
      where: {
        creatorId: {
          inq: this.driverIds
        }
      },
      include: [
        {'Ride': 'Owner'},
        'Passenger',
        'Owner',
        'Creator',
        'Driver'
      ]
    });

    queryArray.forEach((query, x) => {
      this.ratingService.getAll(query).subscribe((ratings: Rating[]) => {
        const self = this;
        console.log('RATINGS', ratings);
        if (!x) {
          this.driverData = [];
          this.driverFilteredData = [];
        }
        if (x === 1) {
          this.operatorData = [];
          this.operatorFilteredData = [];
        }

        ratings.forEach((rating, i) => {
          if (!rating.Ride) {
            return;
          }
          let jobOwner = '';
          if (rating.Ride.Owner) {
            if (rating.Ride.Owner.type === 'driver') {
              jobOwner = `${rating.Ride.Owner.fname} ${rating.Ride.Owner.lname}`;
            } else {
              jobOwner = `${rating.Ride.Owner.name}`;
            }
          }
          let ratedBy = 'Passenger';
          if (rating.Creator) {
            if (rating.creatorType === 'Driver') {
              ratedBy = `${rating.Creator.fname} ${rating.Creator.lname}`;
            } else {
              ratedBy = `${rating.Creator.name}`;
            }
          }
          const r = {
            jobOwner,
            ratedBy,
            reference: rating.Ride.reference,
            rideId: rating.Ride.id,
            score: rating.score,
            commentShort: rating.comment ? rating.comment.substr(0, self.commentMaxLength) : '',
            comment: (rating.comment ? rating.comment : ''),
            driverName: rating.Driver ? `${rating.Driver.fname || ''} ${rating.Driver.lname || ''}` : '',
            passenger: (typeof rating.Passenger !== 'undefined' ? rating.Passenger.fname + ' ' + rating.Passenger.lname : ''),
            company: (typeof rating.Company !== 'undefined' ? rating.Company.name : ''),
            created: rating.created,
            type: (rating.type ? rating.type : rating.creatorType.toLowerCase()),
            Ride: rating.Ride,
          };
          if (this.driverIds && (this.driverIds.includes(rating.creatorId) || this.driverIds.includes(rating.ownerId))) {
            this.operatorData.push(r);
          }
          if (this.driverIds && this.driverIds.includes(rating.driverId)) {
            this.driverData.push(r);
          }
        });

        if (!x) {
          this.driverFilter();
        }

        if (x === 2) {
          this.operatorFilter();
        }

        if (x === (queryArray.length - 1)) {
          this.loadingService.resolve('ratings.list');
          console.log('this.driverFilteredData:', this.driverFilteredData);
        }
      }, (error => {
        console.error(error);
        this.loadingService.resolve('ratings.list');
      }));
    });
  }

  openRide(id: string) {
    this.router.navigate([`/jobs/${id}`]);
  }

}
