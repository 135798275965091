import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Company} from 'app/models/company';
import {Passenger} from '../../../../../../../../../models/passenger';
import {environment} from '../../../../../../../../../../environments/environment';
import {PassengerService} from '../../../../../../../../../services/passenger.service';
import {TranslateService} from '@ngx-translate/core';
import {FormGroup} from '@angular/forms';
import {TdDialogService} from '@covalent/core/dialogs';
import {PassengerAddPaymentmethodComponent} from '../passenger-add-paymentmethod/passenger-add-paymentmethod.component';
import {ActivatedRoute} from '@angular/router';
import {PaymentMeta} from '../../../../../../../../../models/payment-meta';
import {Ride} from '../../../../../../../../../models/ride';
import {GlobalFunctions} from '../../../../../../../../../functions/functions';
// Before the component
declare var Stripe: any;

@Component({
  selector: 'app-passenger-paymentmethods',
  templateUrl: './passenger-paymentmethods.component.html',
  styleUrls: ['./passenger-paymentmethods.component.scss'],
  providers: [PassengerService]
})
export class PassengerPaymentmethodsComponent implements OnInit, OnChanges {
  @Input() ride: Ride;
  @Input() form: FormGroup;
  @Input() passenger: Passenger;
  @Input() company: Company;
  @Input() formErrors: any;
  @Input() paymentMethod: any;
  @Output() conditionalFormatting = new EventEmitter<string>();

  translations: string[] = [];
  stripe: any;
  paymentMethods: any = [];
  selectedMethod: any;
  lPassenger: Passenger;
  lPaymentMeta: PaymentMeta;

  constructor(
    private _passengerService: PassengerService,
    private _translateService: TranslateService,
    private _dialogService: TdDialogService,
    private _route: ActivatedRoute,
    public _globalFunctions: GlobalFunctions,
  ) {
    this._globalFunctions.loadScript(`https://js.stripe.com/v3/`, this, 'initStripe');
    _translateService.get([
      'select_passenger_paymentmethod', 'select_passenger_create_new', 'payment_status_pickup_at'
    ]).subscribe((translations: string[]) => {
      this.translations = translations;
    });
  }

  ngOnInit(): void {
    if (this.passenger) {
      if (!this.passenger.id) {
        this.passenger.id = this.form.controls['passengerId'].value;
      }
      this.lPassenger = this.passenger;
      this.retrieveContactPaymentMethods(this.passenger, (this.passenger, (this.ride.paymentMeta.paymentMethodId ? this.ride.paymentMeta.paymentMethodId : (this.form.controls['paymentMeta']['controls']['origin'].value === 'creditcardOnline' ? true : false))));
    }
  }

  initStripe() {
    this.stripe = Stripe(`${environment.stripeKey}`);
  }

  ngOnChanges(): void {
    if (JSON.stringify(this.passenger) !== JSON.stringify(this.lPassenger) || JSON.stringify(this.paymentMethod) !== JSON.stringify(this.lPaymentMeta)) {
      this.lPassenger = this.passenger;
      this.lPaymentMeta = this.paymentMethod;
      if (this.form.controls['paymentMeta']['controls']['origin'].value === 'creditcardOnline') {
        this.retrieveContactPaymentMethods(this.passenger, (this.ride.paymentMeta.paymentMethodId ? this.ride.paymentMeta.paymentMethodId : true));
      }
    }
  }

  retrieveContactPaymentMethods(passenger: Passenger, selectPaymentMethodId: any = false) {
    const self = this;
    let hasCards;
    if (!passenger.id) {
      return;
    }
    this._passengerService.paymentMethods(passenger.id)
      .subscribe((result: any) => {
        self.paymentMethods = result.filter((p) => {
          return (p.type === 'card')
        });

        self.paymentMethods.forEach((p) => {
          if (p.default) {
            self.selectedMethod = p;
          }
        })
        if (selectPaymentMethodId && (this.form.controls['paymentMeta']['controls']['origin'].value === 'creditcardOnline' || typeof (selectPaymentMethodId) === 'boolean')) {
          let firstDebtor;
          if (typeof (selectPaymentMethodId) === 'boolean') {
            firstDebtor = self.paymentMethods.filter((p) => {
              return (p.type === 'card');
            })[0];
            if (firstDebtor) {
              this.form.controls['paymentMeta']['controls']['origin'].setValue('creditcardOnline');
            } else {
              firstDebtor = self.paymentMethods.filter((p) => {
                return (p.type === 'invoice');
              })[0];
              if (firstDebtor) {
                this.form.controls['paymentMeta']['controls']['origin'].setValue('invoice');
              }
            }
          } else {
            firstDebtor = self.paymentMethods.filter((p) => {
              return (p.id === selectPaymentMethodId);
            })[0];
          }
          if (firstDebtor) {
            this.form.controls['paymentMeta']['controls']['paymentMethodId'].setValue((firstDebtor.id ? firstDebtor.id : firstDebtor.debtorId));
            this.changeSelection();
          }
        } else if (selectPaymentMethodId) {
          let firstDebtor = self.paymentMethods.filter((p) => {
            return (p.type === 'invoice');
          })[0];

          if (firstDebtor) {
            this.form.controls['paymentMeta']['controls']['paymentMethodId'].setValue(firstDebtor.debtorId);
            this.changeSelection();
          }
        }
      });
  }

  changeSelection(): void {
    if (this.form.controls['paymentMeta']['controls']['paymentMethodId'].value === 'new') {
      this.selectedMethod = null;
      this.conditionalFormatting.emit();

      const self = this;
      const {company} = this._route.parent.snapshot.data
      self._dialogService.open(PassengerAddPaymentmethodComponent, {
        maxHeight: '80%',
        maxWidth: '500px',
        minWidth: '500px',
        minHeight: '250px',
        data: {
          openFromDialog: true,
          company: company,
          parent: self,
        },
      });
      this.form.controls['paymentMeta']['controls']['paymentMethodId'].setValue('');
    } else if (this.form.controls['paymentMeta']['controls']['paymentMethodId'].value === 'select') {
      this.selectedMethod = null;
      this.form.controls['paymentMeta']['controls']['origin'].setValue('inTaxi');
      this.conditionalFormatting.emit();
    } else {
      this.selectedMethod = this.paymentMethods.filter((p) => {
        return (p.id === this.form.controls['paymentMeta']['controls']['paymentMethodId'].value || p.debtorId === this.form.controls['paymentMeta']['controls']['paymentMethodId'].value);
      })[0];

      if (this.selectedMethod) {
        if (this.selectedMethod.type === 'card') {
          this.form.controls['paymentMeta']['controls']['origin'].setValue('creditcardOnline');
        } else {
          this.form.controls['paymentMeta']['controls']['origin'].setValue('invoice');
          this.form.controls['debtorId'].setValue('debtor-' + this.form.controls['paymentMeta']['controls']['paymentMethodId'].value)
          this.form.controls['passengerIsDebtor'].setValue(true)
        }
        this.conditionalFormatting.emit();
      }
    }
  }

  getSelectedMethodIcon(): string {
    if (this.form.controls['paymentMeta']['controls']['paymentMethodId'].value === 'select') {
      return '';
    }
  }

  getSelectedMethod(): string {
    if (this.form.controls['paymentMeta']['controls']['paymentMethodId'].value === 'select') {
      return this.translations['select_passenger_paymentmethod'];
    } else if (this.form.controls['paymentMeta']['controls']['paymentMethodId'].value === 'new') {
      return this.translations['select_passenger_create_new'];
    } else if (this.selectedMethod) {
      return `**** **** **** ${(this.selectedMethod.card ? this.selectedMethod.card.last4 + ' ' + this.selectedMethod.card.exp_month + '/' + ('' + this.selectedMethod.card.exp_year + '').substring(2, 4) : '')}`;
    }
  }

  setDefaultPaymentProcess() {
    // @ts-ignore
    this.form.controls['paymentMeta']['controls']['paymentMoment'].setValue(null);
    // @ts-ignore
    this.form.controls['paymentMeta']['controls']['hoursBeforePickup'].setValue(null);
  }
}
