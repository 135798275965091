import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Driver} from '../../../../models/driver';
import {Subscription} from 'rxjs';
import {Passenger} from '../../../../models/passenger';
import {IPageChangeEvent} from '@covalent/core/paging';
import {ITdDataTableSortChangeEvent, TdDataTableService, TdDataTableSortingOrder} from '@covalent/core/data-table';
import {TdLoadingService} from '@covalent/core/loading';
import {environment} from '../../../../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';
import {ActivatedRoute, Router} from '@angular/router';
import {PassengerService} from '../../../../services/passenger.service';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {DebtorPassenger} from '../../../../models/debtor-passenger';
import {TdDialogService} from '@covalent/core/dialogs';
import {DebtorAddPassengerComponent} from '../debtor-add-passenger/debtor-add-passenger.component';
import {DebtorUpdatePassengerComponent} from '../debtor-update-passenger/debtor-update-passenger.component';
import {TranslateService} from '@ngx-translate/core';
import {UtilityService} from '../../../../services/utility.service';
import {Company} from '../../../../models/company';

@Component({
  selector: 'app-passenger-list',
  templateUrl: './passenger-list.component.html',
  styleUrls: ['./passenger-list.component.scss'],
  providers: [PassengerService]
})
export class PassengerListComponent implements OnInit, OnDestroy {
  @Input() debtorId = '';
  @Input() companyId = '';
  context: string;
  translations: string[];
  driver: Driver;
  company: Company;
  activeLoaderName = 'active-passenger-list';
  inactiveLoaderName = 'inactive-passenger-list';

  activeTab = 'active';
  selectedTabIndex = 0;

  dataSubscription: Subscription;
  activePassengersDebtor: DebtorPassenger[] = [];
  inactivePassengersDebtor: DebtorPassenger[] = [];
  activePassengers: Passenger[] = [];
  inactivePassengers: Passenger[] = [];
  activeFilteredPassengers: Passenger[] = [];
  inactiveFilteredPassengers: Passenger[] = [];
  activeFilteredPassengerCount = 0;
  inactiveFilteredPassengerCount = 0;

  searchTerm = '';
  fromRow = 1;
  currentPage = 1;
  pageSize = 20;
  sortBy = 'fname';
  sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;

  columns = [
    {name: 'fname', label: 'first_name', sortable: true, active: true},
    {name: 'lname', label: 'last_name', sortable: true, active: false},
    {name: 'email', label: 'email', sortable: true, active: false},
    {name: 'phoneNumber', label: 'phone_number', sortable: true, active: false},
    {name: 'debtorType', label: 'role', sortable: true, active: false},
    {name: 'action', label: 'action', sortable: true, active: false},
  ];

  constructor(private vault: CoolLocalStorage,
              private passengerService: PassengerService,
              private route: ActivatedRoute,
              private router: Router,
              private _translateService: TranslateService,
              private dataTableService: TdDataTableService,
              private _dialogService: TdDialogService,
              private loadingService: TdLoadingService) {
    this.context = this.route.routeConfig['context'] || 'driver';
    this.driver = this.vault.getObject(`${environment.vaultPrefix}.driver`);

    const {company} = this.route.parent.snapshot.data;
    UtilityService.setBrowserTimeStamp(this.company);
    this.company = company;
  }

  ngOnInit() {
    this.loadingService.register(this.activeLoaderName);

    this._translateService.get([
      'ok', 'confirm_remove_passenger', 'contact_type_passenger', 'contact_type_admin', 'contact_type_owner'
    ]).subscribe((translations: any) => {
      this.translations = translations;
      this.loadData();
    });
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
  }

  sort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.sortBy = sortEvent.name;
    this.sortOrder = sortEvent.order;
    this.filter();
  }

  search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.filter();
  }

  page(pagingEvent: IPageChangeEvent): void {
    this.fromRow = pagingEvent.fromRow;
    this.currentPage = pagingEvent.page;
    this.pageSize = pagingEvent.pageSize;
    this.filter();
  }

  filter(): void {
    let activeFilteredData: Passenger[] = this.activePassengers;
    activeFilteredData = this.dataTableService.filterData(activeFilteredData, this.searchTerm, true);
    activeFilteredData = this.dataTableService.sortData(activeFilteredData, this.sortBy, this.sortOrder);
    activeFilteredData = this.dataTableService.pageData(activeFilteredData, this.fromRow, this.currentPage * this.pageSize);
    this.activeFilteredPassengerCount = activeFilteredData.length;
    this.activeFilteredPassengers = activeFilteredData;
  }

  //noinspection JSMethodCanBeStatic
  onTabChange(event: MatTabChangeEvent): void {
    if (event.index === 0) {
      this.activeTab = 'active';
    }
    if (event.index === 1) {
      this.activeTab = 'inactive';
    }
  }

  addContact(): void {
    this._dialogService.open(DebtorAddPassengerComponent, {
      maxHeight: '80%',
      maxWidth: (window.innerWidth < 1000 ? '95%' : '80%'),
      data: {
        parent: this,
      },
    });
  }

  updatePassenger(contact: Passenger): void {
    this._dialogService.open(DebtorUpdatePassengerComponent, {
      maxHeight: '80%',
      maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
      width: '400px',
      data: {
        parent: this,
        contact: contact
      },
    });
  }

  removePassenger(id: string): void {
    const self = this;
    self._dialogService.openConfirm({
      message: self.translations['confirm_remove_passenger'].formatUnicorn(name),
      disableClose: true,
      acceptButton: self.translations['ok'],
    }).afterClosed().subscribe((accept) => {
      if (accept) {
        this.passengerService.debtorDelete(id)
          .subscribe(() => {

            self.loadData();

          }, (error) => {
            console.log(error);
          });
      }
    });
  }

  open(id: string): void {
    if (this.context === 'driver') {
      this.router.navigate([`/debtors/${this.debtorId}/passengers/${id}/edit`]);
    } else {
      this.router.navigate([`/groups/${this.companyId}/debtors/${this.debtorId}/passengers/${id}/edit`]);
    }
  }

  loadData(): void {
    const self = this;
    const query = {where: {debtorId: self.debtorId}, include: 'Passenger'};
    self.dataSubscription = self.passengerService.getPassengersByDebtor(self.debtorId, query, self.context).subscribe((passengers: DebtorPassenger[]) => {
      self.activePassengersDebtor = passengers.filter((p: DebtorPassenger) => p.Passenger && !p.Passenger.isDeleted);
      self.activePassengers = self.activePassengersDebtor.map((p: DebtorPassenger) => {
        p.Passenger.debtorType = self.translations[`contact_type_${p.type}`];
        p.Passenger.type = p.type;
        p.Passenger.connectionId = p.id;
        return p.Passenger;
      });
      self.activeFilteredPassengerCount = self.activePassengers.length;
      self.filter();
      setTimeout(function () {
        self.loadingService.resolve(self.activeLoaderName);
      }, 500);
    }, error => {
      console.error(error);
      self.loadingService.resolve(self.activeLoaderName);
    })
  }
}
