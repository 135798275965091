import {RuleLink} from './rule';

export class DaAppInstall {
  id: string;
  accessToken: string;
  created: string;
  modified: string;
  settings: any;
  name?: string;
  daAppId?: string;
  numeric?: string;
  userId?: string;
  type?: string;
  DaApp?: any;
  rules?: RuleLink[];

  constructor() {
  }
}
