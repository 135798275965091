import moment from 'moment';

export class Timeframe {
  _id?: string;
  startDate: string;
  endDate: string;
  weekSchedule: string;
  isSpecificWeekDays: boolean;

  constructor() {
    const yesterday = moment().subtract(1, 'days');
    this.startDate = yesterday.toISOString();

    this.endDate = new Date(
      new Date().getFullYear() + 100,
      0, 0, 23, 59, 59, 59
    ).toISOString();

    this.weekSchedule =
      '000000011111111111000000' +
      '000000011111111111000000' +
      '000000011111111111000000' +
      '000000011111111111000000' +
      '000000011111111111000000' +
      '000000011111111111000000' +
      '000000011111111111000000';
    this.isSpecificWeekDays = false;
  }
}
