import {Component, Injector} from '@angular/core';
import {CollectionComponent} from '../../../global/collection/collection.component';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../../../environments/environment';
import {CouponService} from '../../../../services/coupon.service';
import {Coupon} from '../../../../models/coupon';
import {RideService} from '../../../../services/ride.service';
import {TdDialogService} from '@covalent/core/dialogs';
import {ucFirst} from '../../../../pipes/uc-first.pipe';

@Component({
  selector: 'app-coupons',
  templateUrl: './coupons.component.html',
  styleUrls: ['./coupons.component.scss'],
  providers: [CouponService, RideService]
})
export class CouponsComponent extends CollectionComponent {
  loaderName = 'coupon';
  modelName = 'coupons';
  filteredTotals = [];
  translations = [];
  pageSize = 100;

  columns = [
    {name: 'code', label: 'code', sortable: true, active: true},
    {name: 'name', label: 'name', sortable: true, active: false},
    {name: 'discount', label: 'discount', sortable: true, active: false},
    {name: 'redemptions', label: 'redemption_count', sortable: true, active: false},
    {name: 'tools', label: 'tools', sortable: true, active: false}
  ];

  constructor(private _translateService: TranslateService,
              protected injector: Injector,
              private _couponService: CouponService,
              private _dialogService: TdDialogService,
              private _rideService: RideService,
              private _titleService: Title) {
    super(injector);

    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);

    this._route.parent.params.subscribe(params => {
      this.companyId = params['id'];
      _translateService.get(['coupons',
        'x_deleted',
        'delete_x',
        'cancel',
        'delete',
        'coupons_page_title',
        'message_delete_x']).subscribe((translations: any) => {
        this.translations = translations;
        this._titleService.setTitle(translations['coupons_page_title'] + environment.windowTitleSuffix);
        this._navigationService.setBodyTitle(translations['coupons_page_title']);

        this._navigationService.setSplitLayoutButtons([]);
      });
    });

    this.originalData = {
      all: [],
      default: [],
      referer: [],
    };
  }

  loadData = () => {
    const self = this;
    const query = {
      'where': {
        'companyId': self.companyId
      },
      'order': 'created ASC'
    };
    const promiseArray = [];
    self._couponService.getAll(query).subscribe((coupons: Coupon[]) => {
      // const self = this;
      self.collectionData = [];
      self.filteredData = [];

      coupons.forEach((coupon, i) => {
        promiseArray.push(new Promise<void>((resolve) => {
          self._rideService.count({
            'couponId': coupon.id,
            or: [{'status': 'completed', 'paymentMeta.moment': 'afterRide'}, {
              'paymentMeta.moment': 'beforeRide',
              'status': {'nin': ['canceled']}
            }]
          }).subscribe((result: any) => {
            coupon.redemptions = (result.count ? result.count : '0');
            coupon.created = new Date(coupon['created']);
            self.collectionData.push(coupon);
            return resolve();
          }, error => {
            console.error(error);
          })
        }));
      });
      Promise.all(promiseArray).then(() => {
        self.collectionData = self.collectionData.sort(function (a, b) {
          return a['created'] - b['created'];
        });
        self.originalData['all'] = self.collectionData;

        this.collectionData.forEach((record) => {
          let sortType = 'default';

          if (record['type'] !== 'default') {
            sortType = 'referer';
          }
          if (!self.originalData[sortType]) {
            self.originalData[sortType] = [];
          }
          self.originalData[sortType].push(record);
        });

        Object.keys(this.originalData).forEach((type) => {
          if (!self.filteredTotals[type]) {
            self.filteredTotals[type] = [];
          }
          self.filteredTotals[type].push(self.originalData[type].length);
        });
        self.filter('default');
        self.filter('referer');

        console.log(self.filteredTotal);
        console.log(self.originalData);
        self._loadingService.resolve('coupons.list');
      });
    }, (error => {
      console.error(error);
      this._loadingService.resolve('coupons.list');
    }));
  }

  openToolsEdit($event, itemId) {
    this._router.navigate([`/groups/${this.companyId}/${this.modelName}/${itemId}/edit`]);
  }

  deleteCoupon($event, itemId, name): void {
    const self = this;
    const message = self.translations['message_delete_x'].formatUnicorn(name);
    const title = self.translations['delete_x'].formatUnicorn(name);

    self._dialogService.openConfirm({
      message: message,
      disableClose: false,
      title: title,
      cancelButton: ucFirst(self.translations['cancel']),
      acceptButton: ucFirst(self.translations['delete']),
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        self._couponService.delete(itemId, 'company').subscribe(() => {
          self._dialogService.openConfirm({
            message: self.translations['x_deleted'].formatUnicorn(name),
            disableClose: true,
            acceptButton: 'Ok',
          }).afterClosed().subscribe(() => {
            self.loadData();
          });
        });
      }
    });
  }
}
