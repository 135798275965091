import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {environment} from '../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../services/auth.service';
import {DeviceDetectorService} from 'ngx-device-detector';
import {CoolLocalStorage} from '@angular-cool/storage';

function getWindow(): any {
  return window;
}

@Component({
  selector: 'app-zendesk-widget',
  templateUrl: './zendesk-widget.component.html',
  styleUrls: ['./zendesk-widget.component.scss']
})
export class ZendeskWidgetComponent implements AfterViewInit, OnInit, OnDestroy {
  private window;
  private widgetKey = 'bb3f2918-80b6-4c0e-b70e-5f673341a329';
  private config: any = {
    webWidget: {
      color: {
        theme: '#03a9f4',
        launcher: '#03a9f4',
        launcherText: '#FFF',
        button: '#455a64',
        resultLists: '#03a9f4',
        header: '#455a64',
        articleLinks: '#03a9f4'
      },
      launcher: {
        label: {'*': ''}
      },
      helpCenter: {
        title: {'*': ''},
        messageButton: {'*': ''}
      },
      contactForm: {
        title: {'*': ''},
        ticketForms: [{id: 360000160759, fields: []}]
      },
      chat: {
        hideWhenOffline: true,
        emailTranscript: true,
        connectOnPageLoad: true,
        title: {
          '*': 'Chat',
        }
      },
    }
  };
  private driver: any;
  private user: any;
  private translations: any;
  private authStateSub: Subscription;

  constructor(private _translate: TranslateService,
              private _authService: AuthService,
              private _deviceService: DeviceDetectorService,
              private _vault: CoolLocalStorage) {
    // Get the `window`
    this.window = getWindow();

    // Get the current driver
    this.driver = this._vault.getObject(`${environment.vaultPrefix}.driver`);
    this.user = this._vault.getObject(`${environment.vaultPrefix}.user`);
    if (!this.user) {
      this.setWidgetUserContext();
    } else {
      // Get translations
      this._translate.get(['zendesk_start_button', 'zendesk_ticket_button', 'zendesk_form_title']).subscribe(translations => {
        this.translations = translations;

        // Translate Zendesk Widget
        this.config.webWidget.launcher.label['*'] = this.translations.zendesk_start_button;
        this.config.webWidget.helpCenter.title['*'] = this.translations.zendesk_form_title;
        this.config.webWidget.helpCenter.messageButton['*'] = this.translations.zendesk_ticket_button;
        this.config.webWidget.contactForm.title['*'] = this.translations.zendesk_ticket_button;
      });

      this._authService.getAuthState().subscribe(state => {
        this.setWidgetUserContext();
      });
    }
  }

  setWidgetUserContext(): void {
    let name = '', email = '', phone = '', locale = 'en';

    // Update the current driver and user in case auth state was changed
    this.driver = this._vault.getObject(`${environment.vaultPrefix}.driver`);
    this.user = this._vault.getObject(`${environment.vaultPrefix}.user`);

    if (this.driver && this.driver.fname) {
      name = `${this.driver.fname} ${this.driver.lname}`;
      email = this.driver.emailAddress;
      phone = this.driver.phoneNumber;
    } else if (this.user) {
      name = `${this.user.firstName} ${this.user.lastName}`;
      email = this.user.email;
      phone = this.user.phoneNumber;
      locale = this.user.locale;
    }

    // @ts-ignore
    if (typeof zE !== 'undefined') {
      // @ts-ignore
      zE('webWidget', 'hide');
    }

    // @ts-ignore
    if (typeof zE !== 'undefined' && name && email && phone) {
      // @ts-ignore
      zE('webWidget', 'setLocale', locale);
      if (name && email) {
        // @ts-ignore
        zE('webWidget', 'identify', {
          name,
          email
        });
      }
      // @ts-ignore
      zE('webWidget', 'prefill', {
        name: {
          value: name,
          readOnly: true
        },
        email: {
          value: email,
          readOnly: true
        },
        phone: {
          value: phone,
          readOnly: true
        }
      });
    }
  }

  setWidgetFieldValue(id: number, value: any): void {
    this.config.webWidget.contactForm.ticketForms[0].fields.push({
      id,
      prefill: {'*': value}
    });
  }

  createZendeskWidget(): void {
    const self = this;
    const script = document.createElement('script');

    // Create the script element
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${this.widgetKey}`;
    script.id = 'ze-snippet';
    script.type = 'text/javascript';

    // Add an onload function to set up some features
    script.onload = function () {
      self.setWidgetUserContext();
    };

    // Append the script to head
    document.getElementsByTagName('head')[0].appendChild(script);

    // Prefill fields
    const deviceInfo = this._deviceService.getDeviceInfo();
    this.setWidgetFieldValue(360001093739, JSON.stringify(deviceInfo, null, 2));
    this.setWidgetFieldValue(45315469, `${deviceInfo.os} (${deviceInfo.os_version})`);
    this.setWidgetFieldValue(45346105, deviceInfo.browser_version);
    this.setWidgetFieldValue(360001106100, deviceInfo.browser);
    if (this.driver) {
      this.setWidgetFieldValue(45343825, this.driver.phoneNumber);
    }

    // Set the widget config
    this.window.zESettings = this.config;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (environment.showSupportButton) {
      this.createZendeskWidget();
    }
  }

  ngOnDestroy(): void {
    if (this.authStateSub) {
      this.authStateSub.unsubscribe();
    }
  }
}
