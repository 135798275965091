import {Observable, of as observableOf} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {CoolLocalStorage} from '@angular-cool/storage';
import {environment} from '../../environments/environment';
import {AuthService} from '../services/auth.service';
import {CompanyService} from '../services/company.service';
import jwt_decode from 'jwt-decode';

// The vault prefix depending on environment
const vaultPrefix = environment.vaultPrefix;

// See if value is defined
const defined = (item: any) =>
  typeof item !== 'undefined' && item !== null;

// yda.portal.5a37959f468791219bc08c0f.jwt
const jwtKeyName = companyId => [
  environment.vaultPrefix,
  companyId,
  'jwt',
].join('.');

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _vault: CoolLocalStorage,
    private _auth: AuthService,
    private _companyService: CompanyService,
  ) {
  }

  /**
   * Get value from localStorage.
   */
  getFromStorage = (key: string, valueName?: string): any => {
    const prop = this._vault.tryGetObject(key);
    if (!prop) {
      return '';
    }
    return valueName ? prop[valueName] : prop;
  };

  /**
   * See if storage has key.
   */
  itemIsSet = (key: string): boolean =>
    defined(this._vault.getItem(key));

  /**
   * Request new JWT.
   */
  requestJWT = (companyId, daAppInstallId, name) => {
    this._companyService.getNewJWT(companyId, daAppInstallId).pipe(
      map((response) => {
        console.log('response', response);
        this._vault.setItem(name, 'Bearer ' + response.jwt);
        return this.itemIsSet(name);
      }));
  }

  /**
   * See if route can be activated.
   */
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // Identity information from url and local storage
    const companyId = route.params.id;
    let daAppInstallId = this.getFromStorage(
      vaultPrefix + '.driver',
      'daAppInstallId'
    );

    if (!daAppInstallId) {
      daAppInstallId = environment.driverAppInstallationId;
    }

    // See if accessToken exists
    const atName = vaultPrefix + '.accessToken';

    if (!this.itemIsSet(atName)) {
      this._auth.logout();
      this._router.navigate(['/login']);
      return false;
    }
    // If no companyId is present in the url, the url is safe to visit
    // tslint:disable-next-line:typeof-compare
    if (!companyId || typeof companyId === undefined) {
      return true;
    }

    // See if JWT exists, or request a new one
    const jwtName = jwtKeyName(companyId);

    if (this.itemIsSet(jwtName)) {
      const currentJWT = this._vault.getItem(jwtName);
      const decoded: any = jwt_decode(currentJWT);
      if (decoded.companyId === companyId) {
        // console.log('using current JWT for company:', companyId);
        // console.log('using current JWT for company:', daAppInstallId);
        // console.log('using current JWT for company:', currentJWT);
        // console.log('using current JWT for company:', decoded);
        return true;
      }
    }
    // Request new JWT and return the status as a boolean
    return this._companyService.getNewJWT(companyId, daAppInstallId).pipe(
      map((response) => {
        this._vault.setItem(jwtName, 'Bearer ' + response.jwt);
        return this.itemIsSet(jwtName);
      }),
      catchError(() => observableOf(false)),);
  }

  decodingJWT(token: string) {
    console.log(`decoding JWT token`);
    if (token) {
      const base64String = token.split(`.`)[1];
      const decodedValue = JSON.parse(Buffer.from(base64String, `base64`).toString(`ascii`));
      console.log(decodedValue);
      return decodedValue;
    }
    return null;
  }
}
