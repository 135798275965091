<mat-card [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs" *ngIf="wizardCompleted || action != 'add'">
  <mat-card-content class="push-bottom-none max-w-700">
    <ng-template tdLoading="{{loaderName}}">
      <form *ngIf="form" [formGroup]="form">
        <div layout="row"><strong
          class="push-top-none">{{ (data.name ? data.name : 'message_automation_rule'|translate|ucFirst) }}</strong>
        </div>
        <div flex="50" layout="row" class="push-top">
          <mat-form-field [style.width.px]="325">
            <input (change)="(formErrors.name ? this.validateFormField(this.form,
                    'name') : '')" formControlName="name" matInput
                   placeholder="{{'name'|translate|ucFirst}}" type="text" value="">
            <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.name" class="tc-red-600">
                  <span>{{ formErrors.name }}</span>
                </span>
            </mat-hint>
          </mat-form-field>
        </div>
        <div flex="50" layout="row">
          <mat-form-field [style.width.px]="325">
            <mat-select
              class="row-select"
              flex="100"
              placeholder="{{'recipient_type'|translate|ucFirst}}"
              formControlName="recipientType" required label="">
              <mat-option *ngFor="let options of recipientTypes index as i" [value]="options">
                {{ options|translate|ucFirst }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div flex="50" layout="row">
          <mat-checkbox formControlName="dateLimit" class="push-bottom">
            <span>{{ 'no_date_limitation'|translate|ucFirst }}</span>
          </mat-checkbox>
        </div>
        <div flex="50" layout="row" class="push-top" *ngIf="form.controls['dateLimit'].value !== true">
          <mat-form-field [ngStyle]="{'width': '100%'}" class="date-input push-right">
            <input (change)="(formErrors.startDate ? this.validateFormField(this.form,'startDate') : '')"
                   [max]="maxStartDate"
                   [min]="minDate"
                   formControlName="startDate"
                   matInput
                   placeholder="{{ 'start_date' | translate | ucFirst }}"
                   type="datetime-local">
            <mat-hint align="start" class="warning">
                          <span [hidden]="!formErrors.startDate" class="tc-red-600">
                            <span>{{ formErrors.startDate }}</span>
                          </span>
            </mat-hint>
          </mat-form-field>

          <mat-form-field [ngStyle]="{'width': '100%'}" class="date-input">
            <input (change)="(formErrors.endDate ? this.validateFormField(this.form,'endDate') : '')"
                   [min]="maxDate"
                   formControlName="endDate"
                   matInput
                   placeholder="{{ 'end_date' | translate | ucFirst }}"
                   type="datetime-local">

            <mat-hint align="start" class="warning">
              <span [hidden]="!formErrors.endDate" class="tc-red-600">
                <span>{{ formErrors.endDate }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
        </div>

        <mat-divider></mat-divider>

        <app-message-rule-conditions-upsert-type-dialog #messageRuleConditionsUpsertComponent layout="row"
                                                        class="push-bottom"
                                                        *ngIf="form" [form]="form" [company]="company"
                                                        [requirements]="data._requirements"></app-message-rule-conditions-upsert-type-dialog>

        <mat-divider></mat-divider>

        <app-message-rule-actions-upsert #messageRuleActionsUpsertComponent layout="row" flex="100" class="push-bottom"
                                         *ngIf="form" [form]="form" [actions]="data._actions"
                                         [languages]="languages" [company]="company"></app-message-rule-actions-upsert>
      </form>
    </ng-template>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions class="action-pane-max-width">
    <button (click)="save()"
            color="accent"
            flex
            mat-raised-button>{{ 'save'|translate|ucFirst }}
    </button>

    <button (click)="cancel()"
            flex
            mat-flat-button>{{ 'cancel'|translate|ucFirst }}
    </button>
  </mat-card-actions>
</mat-card>

