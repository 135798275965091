<agm-map #map
         (click)="click($event)"
         (contextmenu)="click($event)"
         (dblclick)="dblclick($event)"
         (keydown)="onKeydown($event)"
         (mapReady)="initMap($event)"
         [usePanning]="true"
         [zoom]="10"
         style="height: inherit">
</agm-map>
<p class="warning" *ngIf="invalidShape">{{'invalid_shape' | translate}}</p>
