export class InvoiceSettings {
  // companyName: string;
  // name: string;
  // cocNr: string;
  // vatNr: string;
  // address1: string;
  // address2: string;
  // city: string;
  // country: string;
  // phoneNumber: string;
  // email: string;

  constructor(public companyName: string = '',
              public name: string = '',
              public cocNr: string = '',
              public vatNr: string = '',
              public logo: string = '',
              public address1: string = '',
              public address2: string = '',
              public city: string = '',
              public country: string = '',
              public phoneNumber: string = '',
              public email: string = '',
              public invoiceText: string = '',
              public processFlow: string = '',
              public groupFlow: string = '',
              public bank: string = '',
              public bankaccount: string = '',
              public id?: string,
              public driverId?: string,
              public companyId?: string) {

  }
}
