import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CoolLocalStorage} from '@angular-cool/storage';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from './user.service';
import {CompanyService} from './company.service';
import {UtilityService} from './utility.service';
import {DriverService} from './driver.service';
import moment from 'moment';

@Injectable()
export class AuthService {
  public currentAuthState;
  private authState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private companyId;

  constructor(private _http: HttpClient,
              private _router: Router,
              private _route: ActivatedRoute,
              private _userService: UserService,
              private _companyService: CompanyService,
              private _driverService: DriverService,
              private _vault: CoolLocalStorage) {
  }


  getJWTHeaders = (): HttpHeaders => {
    const routeCompanyId = this._router.routerState.snapshot.root.firstChild.params['id'];
    const companyId = UtilityService.notNullOrUndefined(routeCompanyId) ? routeCompanyId : environment.companyId;
    const bearer = this._vault.getItem([
      environment.vaultPrefix,
      companyId,
      'jwt',
    ].join('.') || 'Bearer ');
    return new HttpHeaders()
      .set('Accept', 'application/json')
      .set('Authorization', bearer);
  };

  setAuthState(state: boolean) {
    this.currentAuthState = state;
    this.authState.next(state);
  }

  getAuthState(): Observable<any> {
    return this.authState.asObservable();
  }

  login(credentials, cb): void {
    this._authenticate(credentials).subscribe((accessToken: any) => {
      const user = accessToken.user;
      delete accessToken.user;
      this._vault.setObject(`${environment.vaultPrefix}.user`, user);
      this._vault.setObject(`${environment.vaultPrefix}.accessToken`, accessToken);
      /**
       * Check if the user is either a driver or an admin
       */
      if (user) {
        if (user.activeCampaignId) {
          this._userService.update(user.id, {lastPortalLogin: moment().format('YYYY-MM-DD')}).subscribe(() => {
          });
        }
        /**
         * Get the YDA driver which the user can edit in the portal
         */
        this._userService.getYdaDriver(user.id, {
          'include': [{
            'relation': 'Installation',
            'scope': {
              'include': {
                'relation': 'Subscription',
                'scope': {
                  'where': {
                    'expire': {
                      'gt': new Date()
                    }
                  }
                }
              },
            }
          }]
        }).subscribe(ydaDrivers => {
          const ydaDriver = ydaDrivers[0];
          if (ydaDriver) {
            this._vault.setObject(`${environment.vaultPrefix}.driver`, ydaDriver);
            /**
             * Get the driver JWT
             */
            this._companyService.getNewJWT(environment.companyId, ydaDriver.daAppInstallId).subscribe((response: any) => {
              this._vault.setItem(`${environment.vaultPrefix}.${environment.companyId}.jwt`, `Bearer ${response.jwt}`);
              if (ydaDriver.activeCampaignId) {
                this._driverService.update(ydaDriver.id, {lastPortalLogin: moment().format('YYYY-MM-DD')}).subscribe(() => {
                });
              }
            }, (error) => {
              console.error(error);
            });
          } else {
            /**
             * Get the driver JWT
             */
            this._companyService.getNewJWT(environment.companyId, environment.driverAppInstallationId).subscribe((response: any) => {
              // console.log(`${environment.vaultPrefix}.${environment.companyId}.jwt`, response);
              this._vault.setItem(`${environment.vaultPrefix}.${environment.companyId}.jwt`, `Bearer ${response.jwt}`);
            }, (error) => {
              console.error(error);
            });
          }

          /**
           * Check if this portal is a company specific build or not (different login rules apply)
           */
          if (environment.companyBuild) {
            /**
             * Get the company driver for this user so we can check its status
             */
            this._userService.getCompanyDriver(user.phoneNumber, environment.companyId).subscribe((drivers: any) => {
              /**
               * Check if we've found a driver or not
               */
              if (drivers.length > 0) {
                const driver = drivers[0];
                this._vault.setItem(`${environment.vaultPrefix}.companyDriverId`, driver.id);

                /**
                 * Return a proper response for each possible status
                 */
                if (typeof driver.status === 'undefined') {
                  this.setAuthState(false);
                  return cb('login_failed_driver_no_status');
                } else if (driver.status === 'accepted') {
                  accessToken.user = user;
                  this.setAuthState(true);
                  return cb(null, accessToken);
                } else if (driver.status === 'pending') {
                  this.setAuthState(false);
                  return cb('login_failed_driver_pending');
                } else if (driver.status === 'blocked') {
                  this.setAuthState(false);
                  return cb('login_failed_driver_blocked');
                } else if (driver.status === 'removed') {
                  this.setAuthState(false);
                  return cb('login_failed_driver_removed');
                }
              } else {
                this.setAuthState(false);
                return cb(new Error('login_failed_driver_not_found'));
              }
            }, error => {
              console.error(error);
              this.setAuthState(false);
              return cb(error);
            });
          } else {
            accessToken.user = user;
            this.setAuthState(true);
            return cb(null, accessToken);
          }
        }, error => {
          console.error(error);
          this.setAuthState(false);
          return cb('login_failed');
        });
      } else if (user.type === 'operator') {
        /**
         * Get the driver JWT
         */
        this._companyService.getNewJWT(environment.companyId, environment.driverAppInstallationId).subscribe((response: any) => {
          this._vault.setItem(`${environment.vaultPrefix}.${environment.companyId}.jwt`, `Bearer ${response.jwt}`);
          this._vault.setObject(`${environment.vaultPrefix}.operator`, {
            userId: user.id,
            companyId: user.companyId,
            type: user.type,
            username: user.username
          });

          this.setAuthState(true);
          accessToken.user = user;
          return cb(null, accessToken);
        }, (error) => {
          console.error(error);
        });
      } else {
        this.setAuthState(true);
        return cb(null, accessToken);
      }
    }, () => {
      this.setAuthState(false);
      return cb('login_failed');
    });
  }

  googleLogin(credentials, cb) {
    this._authenticateGoogle(credentials).subscribe((accessToken: any) => {
      const user = accessToken.user;
      delete accessToken.user;
      this._vault.setObject(`${environment.vaultPrefix}.user`, user);
      this._vault.setObject(`${environment.vaultPrefix}.accessToken`, accessToken);
      /**
       * Check if the user is either a driver or an admin
       */
      if (user) {
        if (user.activeCampaignId) {
          this._userService.update(user.id, {lastPortalLogin: moment().format('YYYY-MM-DD')}).subscribe(() => {
          });
        }
        /**
         * Get the YDA driver which the user can edit in the portal
         */
        this._userService.getYdaDriver(user.id, {
          'include': [{
            'relation': 'Installation',
            'scope': {
              'include': {
                'relation': 'Subscription',
                'scope': {
                  'where': {
                    'expire': {
                      'gt': new Date()
                    }
                  }
                }
              },
            }
          }]
        }).subscribe(ydaDrivers => {
          const ydaDriver = ydaDrivers[0];
          if (ydaDriver) {
            this._vault.setObject(`${environment.vaultPrefix}.driver`, ydaDriver);
            /**
             * Get the driver JWT
             */
            this._companyService.getNewJWT(environment.companyId, ydaDriver.daAppInstallId).subscribe((response: any) => {
              this._vault.setItem(`${environment.vaultPrefix}.${environment.companyId}.jwt`, `Bearer ${response.jwt}`);
              if (ydaDriver.activeCampaignId) {
                this._driverService.update(ydaDriver.id, {lastPortalLogin: moment().format('YYYY-MM-DD')}).subscribe(() => {
                });
              }
            }, (error) => {
              console.error(error);
            });
          } else {
            /**
             * Get the driver JWT
             */
            this._companyService.getNewJWT(environment.companyId, environment.driverAppInstallationId).subscribe((response: any) => {
              // console.log(`${environment.vaultPrefix}.${environment.companyId}.jwt`, response);
              this._vault.setItem(`${environment.vaultPrefix}.${environment.companyId}.jwt`, `Bearer ${response.jwt}`);
            }, (error) => {
              console.error(error);
            });
          }

          /**
           * Check if this portal is a company specific build or not (different login rules apply)
           */
          if (environment.companyBuild) {
            /**
             * Get the company driver for this user so we can check its status
             */
            this._userService.getCompanyDriver(user.phoneNumber, environment.companyId).subscribe((drivers: any) => {
              /**
               * Check if we've found a driver or not
               */
              if (drivers.length > 0) {
                const driver = drivers[0];
                this._vault.setItem(`${environment.vaultPrefix}.companyDriverId`, driver.id);

                /**
                 * Return a proper response for each possible status
                 */
                if (typeof driver.status === 'undefined') {
                  this.setAuthState(false);
                  return cb('login_failed_driver_no_status');
                } else if (driver.status === 'accepted') {
                  accessToken.user = user;
                  this.setAuthState(true);
                  return cb(null, accessToken);
                } else if (driver.status === 'pending') {
                  this.setAuthState(false);
                  return cb('login_failed_driver_pending');
                } else if (driver.status === 'blocked') {
                  this.setAuthState(false);
                  return cb('login_failed_driver_blocked');
                } else if (driver.status === 'removed') {
                  this.setAuthState(false);
                  return cb('login_failed_driver_removed');
                }
              } else {
                this.setAuthState(false);
                return cb(new Error('login_failed_driver_not_found'));
              }
            }, error => {
              console.error(error);
              this.setAuthState(false);
              return cb(error);
            });
          } else {
            accessToken.user = user;
            this.setAuthState(true);
            return cb(null, accessToken);
          }
        }, error => {
          console.error(error);
          this.setAuthState(false);
          return cb('login_failed');
        });
      } else if (user.type === 'operator') {
        /**
         * Get the driver JWT
         */
        this._companyService.getNewJWT(environment.companyId, environment.driverAppInstallationId).subscribe((response: any) => {
          this._vault.setItem(`${environment.vaultPrefix}.${environment.companyId}.jwt`, `Bearer ${response.jwt}`);
          this._vault.setObject(`${environment.vaultPrefix}.operator`, {
            userId: user.id,
            companyId: user.companyId,
            type: user.type,
            username: user.username
          });

          this.setAuthState(true);
          accessToken.user = user;
          return cb(null, accessToken);
        }, (error) => {
          console.error(error);
        });
      } else {
        this.setAuthState(true);
        return cb(null, accessToken);
      }
    }, () => {
      this.setAuthState(false);
      return cb('login_failed');
    });
  }

  logout(): void {
    this._vault.removeItem(`${environment.vaultPrefix}.user`);
    this._vault.removeItem(`${environment.vaultPrefix}.accessToken`);
    this._vault.removeItem(`${environment.vaultPrefix}.redirectAfterSignup`);
    this._vault.removeItem(`${environment.vaultPrefix}.trialAllowed`);
    this._vault.removeItem(`${environment.vaultPrefix}.loginJob`);
    this._vault.removeItem(`${environment.vaultPrefix}.driver`);
    this._vault.removeItem(`${environment.vaultPrefix}.companyDriverId`);
    this._vault.removeItem(`${environment.vaultPrefix}.operator`);
    this._vault.clear();
    this.setAuthState(false);
    this._router.navigate(['/login']);
    // window.location.reload();
  }

  checkUsernameExistence(username: string, companyId: string): Observable<any> {
    username = encodeURIComponent(username);
    const filter = {
      or: [
        {
          email: username
        },
        {
          username: `${username}_${environment.companyId}`
        }
      ]
    };
    return this._http.get(
      `${environment.apiBaseUrl}/WebPortalUsers/count?where=${JSON.stringify(filter)}`,
      {headers: this.getHeaders()}
    );
  }

  private getHeaders(context?: string) {
    const userAccessToken: any = this._vault.getObject(`${environment.vaultPrefix}.accessToken`);

    if (userAccessToken !== null && context !== 'company') {
      return new HttpHeaders().set('Accept', 'application/json').set('Authorization', userAccessToken.id);
    } else {
      return new HttpHeaders().set('Accept', 'application/json').set('Authorization', environment.apiAccessToken);
    }
  }

  private _authenticate(credentials): Observable<any> {
    return this._http.post(`${environment.apiBaseUrl}/WebPortalUsers/login?include=user`, credentials, {headers: this.getHeaders()});
  }

  private _authenticateGoogle(credentials): Observable<any> {
    return this._http.post(`${environment.apiBaseUrl}/WebPortalUsers/googleLogin?include=user`, credentials, {headers: this.getHeaders()});
  }
}
