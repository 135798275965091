import {Component, Input, OnInit} from '@angular/core';
import {DriverScore} from '../../models/driver-score';
import {Router} from '@angular/router';

@Component({
  selector: 'driver-score-widget',
  templateUrl: './driver-score-widget.component.html',
  styleUrls: ['./driver-score-widget.component.scss']
})
export class DriverScoreWidgetComponent implements OnInit {

  @Input() title = '';
  @Input() subTitle = '';
  @Input() cssClasses = '';

  @Input() driverScore: DriverScore;

  defaultCssClasses = 'mat-card';

  constructor(private _router: Router) {
  }

  ngOnInit() {
  }

  showRatings(tab: string) {
    this._router.navigate([`/ratings`]);
  }

}
