import {Component, Inject, Injector, OnInit} from '@angular/core';
import {CollectionComponent} from '../../../global/collection/collection.component';
import {TranslateService} from '@ngx-translate/core';
import {TdDialogService} from '@covalent/core/dialogs';
import {Title} from '@angular/platform-browser';
import {PassengerService} from '../../../../services/passenger.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PassengerUpsertComponent} from '../passenger-upsert/passenger-upsert.component';

@Component({
  selector: 'app-debtor-add-passenger',
  templateUrl: './debtor-add-passenger.component.html',
  styleUrls: ['./debtor-add-passenger.component.scss'],
  providers: [PassengerService]
})
export class DebtorAddPassengerComponent extends CollectionComponent implements OnInit {
  columns = [
    {name: 'fname', label: 'first_name', sortable: true, active: true, maxSize: 'maxSize600'},
    {name: 'lname', label: 'last_name', sortable: true, active: false},
    {name: 'email', label: 'email', sortable: true, active: false, maxSize: 'maxSize600'},
    {name: 'phoneNumber', label: 'phone_number', sortable: true, active: false, maxSize: 'maxSize600', class: 'minSize175'},
    // { name: 'action',  label: 'select',    sortable: true,   active: false, maxSize: 'minSize600'}
  ];
  context: string;
  loaderName = 'debtor-passenger';
  modelName = 'debtor-passenger';
  filteredTotals = {
    'all': 0
  };
  isDialog = true;
  translations = [];
  pageSize = 10;
  connectedPassengers: string[];

  constructor(private _translateService: TranslateService,
              protected injector: Injector,
              private _passengerService: PassengerService,
              private _dialogService: TdDialogService,
              private _titleService: Title,
              public dialogRef: MatDialogRef<DebtorAddPassengerComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    super(injector);
    this.modelService = _passengerService;

    this.selectFilter['where'] = {
      realm: this.data.parent.company.id
    }

    this.loadData();
  }

  addPassengerToDebtor(id: string): void {
    this._passengerService.debtorInsert({
      debtorId: this.data.parent.debtorId,
      passengerId: id,
    }, this.context).subscribe(() => {
      this.dialogRef.close();
      this.data.parent.loadData();
    });
  }

  addNewPassenger(): void {
    this.dialogRef.close();
    this._dialogService.open(PassengerUpsertComponent, {
      maxHeight: '80%',
      maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
      data: {
        parent: this,
        company: this.data.parent.company,
      },
    });
  }

  afterFilter() {
    this.connectedPassengers = this.data.parent.activePassengers.map((p) => {
      return p.id;
    });

    this.originalData['all'] = this.originalData['all'].filter((data) => {
      return !(this.connectedPassengers.includes(data.id));
    });
    this.filteredData['all'] = this.filteredData['all'].filter((data) => {
      return !(this.connectedPassengers.includes(data.id));
    });

    if (this.filteredTotals) {
      Object.keys(this.filteredTotals).forEach((type) => {
        if (!this.filteredTotals[type]) {
          this.filteredTotals[type] = [];
        }
        this.filteredTotals[type] = this.originalData[type].length;
      });
    }
  }
}
