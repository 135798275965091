<td-layout #layout>
  <td-navigation-drawer email="{{user.email}}" logo="" name="{{user.firstName}} {{user.lastName}}"
                        sidenavTitle="{{portalName}}">
    <app-main-menu [layout]="layout"></app-main-menu>
    <mat-nav-list td-navigation-drawer-menu>
      <a (click)="logout()" mat-list-item>
        <mat-icon>exit_to_app</mat-icon>
        {{'signout'|translate|ucFirst}}</a>
    </mat-nav-list>
  </td-navigation-drawer>
  <td-layout-nav logo="">
    <div flex layout="row" layout-align="center center" td-toolbar-content>
      <div (click)="switchWorkspace()" class="top-logo">
        <mat-icon class="mat-icon-logo"
                  svgIcon="assets:logo"></mat-icon>
      </div>

      <div class="workspace-switch">
        <div (click)="switchWorkspace()" class="text-truncate">{{'select_workspace'|translate|ucFirst}}</div>
        <a (click)="switchWorkspace()" class="workspace workspace-arrow-down">
          <mat-icon>keyboard_arrow_down</mat-icon>
        </a>
      </div>

      <!--<span>{{portalName}}</span>-->
      <span flex></span>
      <button [matMenuTriggerFor]="toolbarMenu1" mat-icon-button>
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #toolbarMenu1="matMenu">
        <a mat-menu-item routerLink="/profile">
          <mat-icon>person</mat-icon>
          <span>{{'your_profile'|translate}}</span>
        </a>
        <a mat-menu-item routerLink="/profile/password">
          <mat-icon>vpn_key</mat-icon>
          <span>{{'change_password'|translate}}</span>
        </a>
        <mat-divider class="push-bottom-sm push-top-sm"></mat-divider>
        <button (click)="logout()" mat-menu-item>
          <mat-icon>exit_to_app</mat-icon>
          <span>{{'menu_sign_out'|translate}}</span>
        </button>
      </mat-menu>
    </div>
    <div class="mat-content pad-bottom-for-zendesk" flex mat-scroll-y>
      <router-outlet></router-outlet>
    </div>
    <td-layout-footer>
      <div layout="row" layout-align="start center">
        <span class="mat-caption">Copyright &copy; 2016 - {{currentYear}} {{portalName}}. All rights reserved
          <span *ngIf="(media.registerQuery('gt-xs') | async)">- Angular {{pjson.dependencies["@angular/common"]}} -
            Build v{{pjson.version}}.{{deployNumber.buildnumber}} - {{deployDate.date}}</span>
        </span>
        <span flex></span>
      </div>
    </td-layout-footer>
  </td-layout-nav>
</td-layout>
<a *ngIf="(hasActionLink)" [routerLink]="[''+actionLink]" class="mat-fab-position-bottom-right z-3" color="primary"
   mat-fab>
  <mat-icon>add</mat-icon>
</a>

