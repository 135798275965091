<mat-list-item>
  <mat-icon mat-list-icon>record_voice_over</mat-icon>
  <h4 mat-line>{{'dispatcher'|translate|ucFirst}}</h4>
  <p mat-line>{{'ride_dispatcher_subtitle'|translate|ucFirst}}</p>
</mat-list-item>
<div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'name'|translate|ucFirst}}</p>
    <p mat-line>{{name}}</p>
  </mat-list-item>
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'email'|translate|ucFirst}}</p>
    <p mat-line>{{dispatcher.emailAddress || '-'}}</p>
  </mat-list-item>
</div>
<div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'phone_number'|translate|ucFirst}}</p>
    <p mat-line>{{dispatcher.phoneNumber || '-'}}</p>
  </mat-list-item>
</div>
<div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <mat-list-item class="value small" flex-gt-sm="100">
    <p mat-line>{{'note'|translate|ucFirst}}</p>
    <p mat-line>{{operatorNote || '-'}}</p>
  </mat-list-item>
</div>
