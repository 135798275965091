<div class="dialog-pane">
  <mat-card-title class="push-bottom">{{'update_payment_method'|translate|ucFirst}}</mat-card-title>
  <mat-divider class="push-bottom"></mat-divider>
  <app-passenger-paymentmethods *ngIf="ride" [company]="company" [formErrors]="formErrors" [form]="form"
                                [passenger]="ride.passenger"
                                class="value"
                                flex-gt-sm="50"></app-passenger-paymentmethods>
  <button (click)="save()" [className]="'mat-raised-button mat-primary tool export-button'" color="primary"
          mat-stroked-button>
    {{'save'|translate|ucFirst}}
  </button>
</div>
