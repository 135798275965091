import {Component, Input, OnInit} from '@angular/core';
import {Contract} from '../../../../../models/contract';
import {TranslateService} from '@ngx-translate/core';
import {Company} from '../../../../../models/company';
import {ActivatedRoute} from '@angular/router';
import {CompanyService} from '../../../../../services/company.service';
import {UtilityService} from '../../../../../services/utility.service';

@Component({
  selector: 'app-no-contract-admin',
  templateUrl: './no-contract-admin.component.html',
  styleUrls: ['../order-now/order-now.component.scss']
})
export class NoContractAdminComponent implements OnInit {
  @Input() contract: Contract;
  noAdminText: string
  company: Company;

  constructor(
    private _translateService: TranslateService,
    private _route: ActivatedRoute,
    private _companyService: CompanyService,
  ) {

    const {company} = this._route.parent.snapshot.data;
    UtilityService.setBrowserTimeStamp(this.company);
    this.company = company;

    _companyService.get(this.company.id, {include: ['Owner']})
      .subscribe((c) => {
        _translateService.get(['cloud_connect_no_admin_text']).subscribe((translations: any) => {
          if (!c.Owner) {
            c.Owner = {
              email: '',
              name: ''
            };
          } else if (c.ownerType === 'CompanyWebUser') {
            c.Owner = {
              email: c.Owner.emailAddress,
              name: `${c.Owner.fname} ${c.Owner.lname}`
            };
          } else if (c.ownerType === 'driver') {
            c.Owner = {
              email: c.Owner.emailAddress,
              name: `${c.Owner.fname} ${c.Owner.lname}`
            };
          } else if (c.ownerType === 'WebPortalUser') {
            c.Owner.name = `${c.Owner.firstName} ${c.Owner.lastName}`;
          }
          this.noAdminText = translations['cloud_connect_no_admin_text'].replace('{owner_email}', c.Owner.email).replace('{owner_name}', c.Owner.name);
        });
      })
  }

  ngOnInit(): void {
  }

}
