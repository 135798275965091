import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TdDialogService} from '@covalent/core/dialogs';
import {MessageRuleActionsUpsertComponent} from '../../message-rule-actions-upsert.component';

@Component({
  selector: 'app-message-rule-action-add-new-dialog',
  templateUrl: './message-rule-action-add-new-dialog.component.html',
  styleUrls: ['./message-rule-action-add-new-dialog.component.scss']
})
export class MessageRuleActionAddNewDialogComponent implements OnInit {
  parent: MessageRuleActionsUpsertComponent;
  type: string;
  constructor(
    public _dialogRef: MatDialogRef<MessageRuleActionAddNewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogService: TdDialogService) {
    this.parent = data.parent;
  }

  ngOnInit(): void {
  }

  startNew() {
    this._dialogRef.close();
    this.parent.addActionRow(this.type)
  }

  setType(type: string) {
    this.type = type;
  }

  close() {
    this._dialogRef.close();
  }
}
