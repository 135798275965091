<app-no-contract *ngIf="(this.context==='company' && (!this.company.ActiveContract ||
!['active','past_due', null].includes(this.company.ActiveContract.foreignStatus)))"
                 [companyId]="this.company.id" [contract]="this.company.ActiveContract"></app-no-contract>

<div flex="100">
  <div *ngFor="let item of this.webbookers">
    <mat-card class="webbooker-card">
      <mat-card-title layout="row">
        <div class="webbooker-card-title">
          {{ (item.settings ? item.settings.name : '') }}
        </div>
        <div class="context-menu">
          <button [matMenuTriggerFor]="menu" mat-icon-button>
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #menu="matMenu">
            <button
              (click)="openPanel(item)"
              mat-menu-item>
              <mat-icon>support</mat-icon>
              <span>{{'support_panel' | translate | ucFirst}}</span>
            </button>

            <button
              (click)="openWebbookerDelete(item)"
              mat-menu-item>
              <mat-icon>delete</mat-icon>
              <span>{{'delete_webbooker' | translate | ucFirst}}</span>
            </button>
          </mat-menu>
        </div>
      </mat-card-title>

      <mat-divider *ngIf="item.priceError"></mat-divider>
      <div class="warning-pane" *ngIf="item.priceError">
        <mat-icon>warning</mat-icon>
        <span [innerHTML]="item.priceError|translate|ucFirst|keepHtml"></span>
      </div>

      <mat-divider></mat-divider>

      <mat-card-content>
        <app-share-webbooker [page]="'settings'" [webbookerId]="item.formId" [webbookerUrl]="item.url"
                             showIntegrationButton="false"></app-share-webbooker>

      </mat-card-content>

      <mat-divider></mat-divider>

      <mat-card-actions>
        <button (click)="integrationInstructions=false" *ngIf="integrationInstructions !== false" color="primary"
                mat-raised-button>{{ 'back' | translate | ucFirst }}
        </button>

        <button (click)="navigate(item.id)"
                color="primary"
                flex
                mat-stroked-button>{{ 'settings'|translate|ucFirst }}
        </button>

        <button (click)="openWebbookerIntegrationCode(item.formId, item.url, item)" class="middle-button"
                color="primary"
                flex
                mat-raised-button>{{ 'show_integration_code'|translate|ucFirst }}
        </button>
      </mat-card-actions>
    </mat-card>
  </div>

  <ng-template tdLoading="newwebbooker">
    <div (click)="addNewWizard()" class="webbooker-card new-webbooker">
      <mat-icon>add_circle_outline</mat-icon>
      <span>{{ (this.webbookers.length < 1 ? 'add_first_webbooker' : 'create_new_webbooker')|translate|ucFirst }}</span>
    </div>
  </ng-template>
</div>
