import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import moment from 'moment';
import {environment} from '../../../../../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';
import {TdDataTableSortingOrder} from '@covalent/core/data-table';
import {TdLoadingService} from '@covalent/core/loading';

@Component({
  selector: 'app-upcomming-invoice',
  templateUrl: './upcomming-invoice.component.html',
  styleUrls: ['./upcomming-invoice.component.scss']
})
export class UpcommingInvoiceComponent implements OnInit, OnChanges {
  @Input() upcommingInvoice: any = {};
  loading = true;
  searchTerm = '';
  fromRow = 1;
  currentPage = 1;
  pageSize = 20;
  filteredData = [];
  loaderName = 'upcommingInvoiceLoader';

  sortBy = 'identifier';
  sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;

  columns = [
    {name: 'description', label: 'product_name', sortable: false, active: false},
    {name: 'period', label: 'product_period', sortable: false, active: false, type: 'period'},
    {name: 'unit_price', label: 'unit_price', sortable: false, active: false, type: 'currency'},
    {name: 'quantity', label: 'quantity', sortable: false, active: true},
    {name: 'amount', label: 'amount', sortable: false, active: false, type: 'currency'},
  ];

  constructor(
    private _vault: CoolLocalStorage,
    private _loadingService: TdLoadingService
  ) {
    const user: any = this._vault.getObject(`${environment.vaultPrefix}.user`);
    moment.locale(user.locale);
    this.startLoader();
    this.loadData();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!this.loading) {
      this.loadData();
      // console.log('ngOnChanges');
    }
  }

  /**
   * Start the spinning loader.
   */
  startLoader = () => this._loadingService.register(this.loaderName);

  /**
   * Stop the spinning loader.
   */
  stopLoader = () => {
    this._loadingService.resolve(this.loaderName);
  }

  loadData(): void {
    const self = this;
    if (self.upcommingInvoice && self.upcommingInvoice['subscription']) {
      self.upcommingInvoice['due_date'] = moment((self.upcommingInvoice['period_end'] * 1000)).format('D MMM.');

      self.upcommingInvoice['subtotal'] = {
        total: self.upcommingInvoice['subtotal'],
        currency: self.upcommingInvoice['currency'].toUpperCase(),
      };

      if (self.upcommingInvoice && self.upcommingInvoice['discount'] && self.upcommingInvoice['discount'].coupon && self.upcommingInvoice['discount'].coupon.name) {
        self.upcommingInvoice['coupon'] = self.upcommingInvoice['discount'].coupon;
        if (self.upcommingInvoice['total'] === 0) {
          self.upcommingInvoice['discount'] = {
            total: (0 - self.upcommingInvoice['subtotal'].total),
            currency: self.upcommingInvoice['currency'].toUpperCase(),
          };
        } else if (self.upcommingInvoice['discount'].coupon['amount_off']) {
          self.upcommingInvoice['discount'] = {
            total: self.upcommingInvoice['discount'].coupon['amount_off'],
            currency: self.upcommingInvoice['currency'].toUpperCase(),
          };
        } else {
          self.upcommingInvoice['coupon'].name = `${self.upcommingInvoice['coupon'].name} -${self.upcommingInvoice['coupon']['percent_off']}%`;
          self.upcommingInvoice['discount'] = {
            total: (0 - self.upcommingInvoice['total_discount_amounts'][0].amount),
            currency: self.upcommingInvoice['currency'].toUpperCase(),
          };
        }
      }

      self.upcommingInvoice['tax'] = {
        total: self.upcommingInvoice['tax'],
        currency: self.upcommingInvoice['currency'].toUpperCase(),
      };
      self.upcommingInvoice['total'] = {
        total: self.upcommingInvoice['total'],
        currency: self.upcommingInvoice['currency'].toUpperCase(),
      };

      self.filteredData = self.upcommingInvoice.lines.data.map((line) => {
        return {
          'description': line['description'],
          'quantity': line['quantity'],
          'period': {
            'start': (line['period']['start'] * 1000),
            'end': (line['period']['end'] * 1000),
          },
          'unit_price': {
            'total': (line['price']['unit_amount'] ? line['price']['unit_amount'] : (line['amount'] / line['quantity'])),
            'currency': line['currency'].toUpperCase(),
          },
          'proration': line['proration'],
          'amount': {
            'total': line['amount'],
            'currency': line['currency'].toUpperCase(),
          }
        }
      });
      self.filteredData = self.filteredData.filter((line) => {
        return (line.amount.total !== 0 ? line : false);
      });
      self.loading = false;
      self.stopLoader();
    } else {
      setTimeout(function () {
        self.loadData();
      }, 3000);
    }
  }
}
