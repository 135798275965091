import {Component, OnInit} from '@angular/core';
import {Field} from '../../models/field.interface';
import {FieldConfig} from '../../models/field-config.interface';
import {AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ucFirst} from '../../../../../pipes/uc-first.pipe';

interface CommissionRule {
  value: number;
  type: string;
}

interface UiCommissionRule {
  value: number;
  rawValue: number;
  type: string;
}

interface CommissionRuleType {
  value: string;
  label: string;
}

export const commissionRuleValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    let error = null;
    if (!control.value) {
      return null;
    }
    control.value.map(rule => {
      if (rule.type === '') {
        error = {'commissionRuleValidator-input': 'Type is null'}
      }
    });
    return error;
  }
};

@Component({
  selector: 'app-commission-input',
  templateUrl: './commission-input.component.html',
  styleUrls: ['./commission-input.component.scss']
})
export class CommissionInputComponent implements Field, OnInit {
  config: FieldConfig;
  group: UntypedFormGroup;
  debug = false;

  defaultRule: UiCommissionRule = {
    value: 1,
    rawValue: 1,
    type: ''
  };
  rules: UiCommissionRule[] = [];
  ruleTypes: CommissionRuleType[] = [];
  translations: string[] = [];
  allowedTypes: any = {
    '': true,
    percentage: true,
    fixed: true
  };

  constructor(private _translateService: TranslateService) {
    _translateService.get([
      'select_commission_rule_type',
      'commission_rule_type_percentage',
      'commission_rule_type_fixed',
    ]).subscribe(translations => {
      this.translations = translations;
      this.ruleTypes = [
        {
          value: '',
          label: ucFirst(translations['select_commission_rule_type'])
        },
        {
          value: 'percentage',
          label: ucFirst(translations['commission_rule_type_percentage'])
        },
        {
          value: 'fixed',
          label: ucFirst(translations['commission_rule_type_fixed'])
        },
      ];
    })
  }

  prepareExistingValues(): UiCommissionRule[] {
    return this.config.value.map(rule => ({
      value: (rule.type === 'fixed' ? rule.value / 100 : rule.value),
      rawValue: rule.value,
      type: rule.type
    }));
  }

  prepareCleanValues(): CommissionRule[] {
    return this.rules.map(rule => ({
      value: rule.rawValue,
      type: rule.type
    }));
  }

  ngOnInit(): void {
    if (this.config.value) {
      this.rules = this.prepareExistingValues();
    }
    this.reportChanges();
  }

  addRow(): void {
    this.rules.push({...this.defaultRule});
    this.reportChanges();
  }

  deleteRow(i: number): void {
    this.rules = this.rules.filter((rule: CommissionRule, index: number) => {
      return index !== i;
    });
    this.reportChanges();
  }

  determineAllowedRules(): void {
    Object.keys(this.allowedTypes).map(type => {
      let allowed = true;
      this.rules.map(rule => {
        if (rule.type === type && type !== '') {
          allowed = false;
        }
      });
      this.allowedTypes[type] = allowed;
    });
  }

  valuesChanged(index: number): void {
    console.log(this.rules[index].value);
    const value = this.rules[index].value;
    // if (!value) {
    //   value = this.rules[index].value;
    // }
    this.rules[index].rawValue = (this.rules[index].type === 'fixed' ? value * 100 : value);
    this.reportChanges();
  }

  reportChanges(): void {
    this.determineAllowedRules();
    this.group.get(this.config.name).setValue(this.prepareCleanValues());
    this.group.get(this.config.name).updateValueAndValidity();
  }
}
