import {Component, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {TdDialogService} from '@covalent/core/dialogs';
import {TdLoadingService} from '@covalent/core/loading';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CoolLocalStorage} from '@angular-cool/storage';
import {DebtorService} from '../../../../services/debtor.service';
import {Debtor} from '../../../../models/debtor';
import {Driver} from '../../../../models/driver';
import {NavigationService} from '../../../../services/navigation.service';
import {environment} from '../../../../../environments/environment';
import {ucFirst} from '../../../../pipes/uc-first.pipe';
import {UtilityService} from '../../../../services/utility.service';
import {Company} from '../../../../models/company';
import {CountryISO, SearchCountryField,} from 'ngx-intl-tel-input';

@Component({
  selector: 'app-debtor-upsert',
  templateUrl: './debtor-upsert.component.html',
  styleUrls: ['./debtor-upsert.component.scss'],
  providers: [DebtorService]
})
export class DebtorUpsertComponent implements OnInit {
  context: string;
  driver: Driver;
  companyId: string;
  company: Company;

  loaderName = 'debtor';
  routeSubscription: Subscription;
  translations: string[] = [];

  id: string;
  action: string;
  debtor: Debtor = new Debtor();
  addressString = '';

  form: UntypedFormGroup;
  formErrors: any = {};
  SearchCountryField = SearchCountryField;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom
  ];

  validationMessages = {
    'phoneNumber': {
      'phoneNumberValidator': 'Phone number is invalid',
    }
  };

  constructor(public snackBar: MatSnackBar,
              private loadingService: TdLoadingService,
              private formBuilder: UntypedFormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private vault: CoolLocalStorage,
              private translateService: TranslateService,
              private titleService: Title,
              private dialogService: TdDialogService,
              private navigationService: NavigationService,
              private debtorService: DebtorService) {
    this.context = this.route.routeConfig['context'] || 'driver';
    this.companyId = this.route.parent.snapshot.paramMap.get('id');
    this.routeSubscription = this.route.params.subscribe(params => {
      this.id = params['id'];
      this.action = params['action'] ? params['action'] : params['id'];
      this.driver = this.vault.getObject(`${environment.vaultPrefix}.driver`);

      translateService.get([
        `${this.action}_debtor`,
        'debtor_added_message',
        'debtor_added_title',
        'debtor_updated_message',
        'delete_x',
        'message_delete_x',
        'x_deleted'
      ]).subscribe((translations: any) => {
        this.translations = translations;
        this.titleService.setTitle(ucFirst(translations[`${this.action}_debtor`]) + environment.windowTitleSuffix);
        this.navigationService.setBodyTitle(ucFirst(translations[`${this.action}_debtor`]));
      });

      if (this.action === 'edit') {
        this.loadData();
      }
    });
  }

  ngOnInit(): void {
    if (this.action !== 'add') {
      this.loadingService.register(this.loaderName);
    }

    /**
     * Set up our form
     */
    this.form = this.formBuilder.group({
      firstName: [null, []],
      lastName: [null, []],
      companyName: [null, []],
      note: [null, []],
      emailAddress: [null, []],
      phoneNumber: [null, []],
      address: this.formBuilder.group({
        city: [null, []],
        streetName: [null, []],
        postalCode: [null, []],
        houseNumber: [null, []],
        description: [null, []],
        internationalAlias: [null, []],
        countryCode: [null, []],
      })
    }, {validators: []});

    /**
     * Subscribe to value changes in the form
     */
    this.form.valueChanges.subscribe(() => this.onValueChanged());

    this.onValueChanged();
  }

  validateAllFormFields = (formGroup: UntypedFormGroup) => {
    Object.keys(formGroup.controls).forEach(field => {
      this.formErrors[field] = '';
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsDirty();
        if (control && !control.valid) {
          const messages = this.validationMessages[field];
          Object.keys(control.errors).forEach(key => {
            this.formErrors[field] += messages[key] + ' ';
          });
        }
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  };

  onValueChanged() {
    this.validateAllFormFields(this.form);
  }

  loadData(): void {
    console.log(`[DebtorUpsertComponent.loadData]: `);
    this.debtorService.get(this.id, {}, this.context).subscribe((debtor: Debtor) => {
      this.debtor = debtor;
      this.form.patchValue({
        firstName: debtor.firstName,
        lastName: debtor.lastName,
        companyName: debtor.companyName,
        note: debtor.note,
        emailAddress: debtor.emailAddress,
        phoneNumber: debtor.phoneNumber,
        address: {
          city: debtor.address ? debtor.address.city : '',
          streetName: debtor.address ? debtor.address.streetName : '',
          postalCode: debtor.address ? debtor.address.postalCode : '',
          houseNumber: debtor.address ? debtor.address.houseNumber : '',
          description: debtor.address ? debtor.address.description : '',
          internationalAlias: debtor.address ? debtor.address.internationalAlias : '',
          countryCode: debtor.address ? debtor.address.countryCode : '',
        }
      });
      this.addressString = UtilityService.getLocationString(this.debtor.address);
      this.onValueChanged();
      this.loadingService.resolve(this.loaderName);
    }, error => {
      console.error(error);
      this.loadingService.resolve(this.loaderName);
    })
  }

  delete(): void {
    const self = this;
    const name = `${this.debtor.firstName} ${this.debtor.lastName} (${this.debtor.companyName})`;
    const message = self.translations['message_delete_x'].formatUnicorn(name);
    const title = self.translations['delete_x'].formatUnicorn(name);

    self.dialogService.openConfirm({
      message: message,
      disableClose: false,
      title: title,
      cancelButton: 'Cancel',
      acceptButton: 'Delete',
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        self.debtorService.delete(self.debtor.id, this.context).subscribe(() => {
          self.dialogService.openConfirm({
            message: self.translations['x_deleted'].formatUnicorn(name),
            disableClose: true,
            acceptButton: 'Ok',
          }).afterClosed().subscribe(() => {
            if (self.context === 'driver') {
              self.router.navigate([`/debtors`]);
            } else {
              self.router.navigate([`/groups/${self.companyId}/debtors/`]);
            }
          });
        });
      }
    });
  }

  save(): void {
    const self = this;
    const data = this.form.value;
    const debtor = this.debtor;

    delete debtor.id;
    delete debtor.modified;
    delete debtor.created;

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        debtor[key] = data[key];
      }
    }

    debtor.ownerId = this.context === 'driver' ? this.driver.id : this.companyId;
    debtor.ownerType = ucFirst(this.context);

    console.log('DEBTOR', debtor);

    /**
     * Call the API
     */
    if (this.action.toLowerCase() === 'add') {
      this.loadingService.register(this.loaderName);
      this.debtorService.insert(debtor, this.context).subscribe(() => {
        setTimeout(function () {
          self.dialogService.openAlert({
            message: ucFirst(self.translations['debtor_added_message']),
            disableClose: true,
            title: ucFirst(self.translations['debtor_added_title']),
            closeButton: self.translations['ok']
          }).afterClosed().subscribe(() => {
            if (self.context === 'driver') {
              self.router.navigate([`/debtors`]);
            } else {
              self.router.navigate([`/groups/${self.companyId}/debtors`]);
            }
          });
        }, 500);
      }, error => {
        console.error(error);
        this.loadingService.resolve(this.loaderName);
      })
    } else {
      this.debtorService.update(this.id, debtor, this.context).subscribe(() => {
        setTimeout(function () {
          self.loadingService.resolve(self.loaderName);
          const notification = self.snackBar.open(ucFirst(self.translations['debtor_updated_message']), self.translations['ok'], {
            duration: 3000
          });
        }, 500);
      }, error => {
        console.error(error);
        this.loadingService.resolve(this.loaderName);
      })
    }
  }

}
