import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {AddConditionWizardDialogComponent} from '../add-condition-wizard-dialog/add-condition-wizard-dialog.component';
import {TdDialogService} from '@covalent/core/dialogs';

@Component({
  selector: 'app-message-rule-conditions-upsert-type-dialog',
  templateUrl: './message-rule-conditions-upsert-type-dialog.component.html',
  styleUrls: ['./message-rule-conditions-upsert-type-dialog.component.scss']
})
export class MessageRuleConditionsUpsertTypeDialogComponent implements OnInit, OnChanges {
  @Input() form;
  @Input() company;
  @Input() requirements = [];
  _localRequirements = [];
  constructor(
    private _dialogService: TdDialogService,
  ) { }

  ngOnInit(): void {
  }

  loadData(): void {
    this.form.controls['requirements'].updateValueAndValidity();
  }

  ngOnChanges() {
    // if (JSON.stringify(this._localRequirements) !== JSON.stringify(this.requirements)) {
    //   this.form.controls['requirements'].setValue([]);
    //   this.loadData();
    //   this._localRequirements = this.requirements;
    // }
  }

  conditionControls() {
    return this.form.controls['requirements'].controls;
  }

  addNewCondition() {
    const self = this;
    self._dialogService.open(AddConditionWizardDialogComponent, {
      maxHeight: '90%',
      height: 'auto',
      data: {
        form: this.form
      }
    });
  }

  updateCondition(index: any) {
    const self = this;
    self._dialogService.open(AddConditionWizardDialogComponent, {
      maxHeight: '90%',
      height: 'auto',
      data: {
        index,
        form: this.form,
        company: this.company,
      }
    });
  }
}
