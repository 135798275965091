<iframe
  [src]="getYoutubeUrl() | safe: 'resourceUrl'"
  allow="accelerometer;
              autoplay; clipboard-write;
              encrypted-media; gyroscope;
              picture-in-picture"
  allowfullscreen
  frameborder="0">
</iframe>
<a (click)="dialogRef.close()" class="yda-link">{{'close'|translate}}</a>
