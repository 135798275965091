import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {DriverService} from '../../services/driver.service';
import {CompanyResolver} from '../../resolvers/company-resolver';
import {CanDeactivateGuard} from '../../guards/can-deactivate-guard';
import {AuthGuard} from '../../guards/auth.guard';
import {MainComponent} from '../layout/main/main.component';
import {NavigationService} from '../../services/navigation.service';
import {DispatchpanelComponent} from '../shared/dispatchpanel/dispatchpanel.component';
import {RideDetailsComponent} from '../company/administration/rides/ride-details/ride-details.component';
import {RideEditComponent} from '../shared/dispatchpanel/parts/ride-edit/ride-edit.component';
import {RideLogComponent} from '../company/administration/rides/ride-log/ride-log.component';
import {RideDispatchLogComponent} from '../company/administration/rides/ride-dispatch-log/ride-dispatch-log.component';

const navigationRoutes: any[] = [
  {
    path: 'operator/:id',
    icon: 'assignment',
    group: 'person',
    label: 'DispatchPanel',
    canActivate: [AuthGuard],
    sort: 2,
    showInMenu: false,
    component: MainComponent,
    children: [
      {
        path: '', component: DispatchpanelComponent, showInMenu: true,
        label: 'menu_dispatchpanel', icon: 'assignment_ind', context: 'company'
      },
      {
        path: 'dispatchpanel', component: DispatchpanelComponent, showInMenu: true,
        label: 'menu_dispatchpanel', icon: 'assignment_ind', context: 'company'
      },
      {path: 'dispatchpanel/:id/details', component: RideDetailsComponent, showInMenu: false, context: 'company'},
      {path: 'dispatchpanel/:action', component: RideEditComponent, showInMenu: false, context: 'company'},
      {path: 'dispatchpanel/:id/log', component: RideLogComponent, showInMenu: false, context: 'company'},
      {path: 'dispatchpanel/:id/edit', component: RideEditComponent, showInMenu: false, context: 'company'},
      {path: 'dispatchpanel/:id/dispatch-log', component: RideDispatchLogComponent, showInMenu: false, context: 'company'},
    ],
    resolve: {
      currentRoute: NavigationService,
      company: CompanyResolver
    }
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(navigationRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    DriverService,
    CompanyResolver,
    CanDeactivateGuard,
  ]
})
export class OperatorRoutingModule {
}
