<div [formGroup]="form" class="condition-pane" *ngIf="form">
  <span>{{'cancel_purchase_title' | translate | ucFirst}}</span>
  <div class="textarea-wrapper push-bottom push-top">
    <mat-form-field class="full-width">
      <mat-label>{{'cancellation_reason'|translate|ucFirst}}</mat-label>
      <textarea class="inputTextArea" matInput formControlName="reason"></textarea>
    </mat-form-field>
  </div>
</div>
<mat-divider></mat-divider>
<mat-card-actions>
  <button (click)="cancelContract()" mat-raised-button color="warn" class="td-menu-button">
    {{ 'cancel_contract' | translate | ucFirst }}
  </button>
  <button (click)="_dialogRef.close()" mat-raised-button class="td-menu-button no-right-padding">
    {{ 'close' | translate | ucFirst}}
  </button>
</mat-card-actions>
