import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Location} from '../../models/location';
import {AuthService} from './../auth.service';
import {CachedHttpClient} from '../osm/cached-http-client.service';

const locationModel = 'locations';
const base = environment.tpsBaseUrl;

@Injectable()
export class LocationService {

  constructor(
    private _http: HttpClient,
    private _cachedHttp: CachedHttpClient,
    private _auth: AuthService,
  ) {
  }

  getAllCached = (filter = {}): Observable<any[]> => {
    return this._cachedHttp.getCached(
        `${base}/${locationModel}?filter=${JSON.stringify(filter)}`,
        {headers: this.getHeaders()},
    );
  }

  getAll = (filter = {}): Observable<any[]> => {
    return this._http.get<any[]>(
        `${base}/${locationModel}?filter=${JSON.stringify(filter)}`,
        {headers: this.getHeaders()},
    );
  }

  get = (id: string, filter?: Object): Observable<Location> =>
    this._http.get<Location>(
      `${base}/${locationModel}/${id}?filter=${JSON.stringify(filter)}`,
      {headers: this.getHeaders()},
    );

  insert = (location: Location): Observable<Location> =>
    this._http.post<Location>(
      `${base}/${locationModel}`, location,
      {headers: this.getHeaders()},
    );

  update = (id: string, location: {}): Observable<Location> =>
    this._http.patch<Location>(
      `${base}/${locationModel}/${id}`, location,
      {headers: this.getHeaders()},
    );

  delete = (id: string): Observable<{ count: boolean }> =>
    this._http.delete<{ count: boolean }>(
      `${base}/${locationModel}/${id}`,
      {headers: this.getHeaders()},
    );

  clone = (id: string): Observable<Location> =>
    this._http.post<Location>(
      `${base}/${locationModel}/clone`, {_id: id},
      {headers: this.getHeaders()}
    );

  private getHeaders = () => this._auth.getJWTHeaders();
}
