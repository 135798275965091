import {Component, Input, OnInit} from '@angular/core';
import {Ride} from '../../models/ride';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../environments/environment';
import {Globals} from '../../../globals';
import {RideService} from '../../services/ride.service';

// import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'ride-count-widget',
  templateUrl: './ride-count-widget.component.html',
  styleUrls: ['./ride-count-widget.component.scss']
})
export class RideCountWidgetComponent implements OnInit {

  @Input() type = 'driver';
  @Input() companyId: string;
  @Input() driverId: string;

  @Input() buttonLink: string;
  @Input() buttonText: string;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() cssClasses: string;

  @Input() colorScheme: any;

  @Input() loading = true;

  breakdown: any[] = [];

  defaultCssClasses = 'mat-card';

  xAxisLabel: string;
  yAxisLabel: string;
  filterValues: any[];
  filterRange: number;

  rides: Ride[] = [];

  constructor(private _translateService: TranslateService,
              private _globals: Globals,
              private _rideService: RideService) {
    this.filterValues = _globals.widgetFilterValues;
    this.filterRange = this.filterValues[0].value;
    _translateService.get('rides').subscribe((translation: string) => {
      this.yAxisLabel = translation;
    });
    if (typeof this.colorScheme === 'undefined' || this.colorScheme === '') {
      this.colorScheme = {
        domain: [environment.colors.primary]
      };
    }
  }

  ngOnInit() {
    this.loadData();
  }

  loadData(event?: any) {
    const now = new Date();
    const from = new Date(now.getTime() - this.filterRange);

    from.setSeconds(1);
    from.setMinutes(0);
    from.setHours(0);

    const filter: any = {
      'where': {
        'requestedDate': {
          'between': [
            from,
            new Date()
          ]
        }
      },
      'order': 'requestedDate ASC'
    };

    if (this.type === 'company') {
      filter.where.ownerId = this.companyId;
      filter.where.ownerType = 'Company';
    } else {
      filter.where.ownerId = this.driverId;
      filter.where.ownerType = 'Driver';
    }

    this._rideService.getAll(filter, this.type).subscribe((rides) => {
      this.rides = rides;
      this.formatData();
    }, (error) => {
      console.log('Error:', error);
    });
  }

  formatData() {
    if (this.filterRange <= 86400000) {
      this._translateService.get('hours').subscribe((translation: string) => {
        this.xAxisLabel = translation;
      });
    } else {
      this._translateService.get('days').subscribe((translation: string) => {
        this.xAxisLabel = translation;
      });
    }
    if (this.rides.length > 0) {
      this.breakdown = [];
      let currentDay = '';
      let currentData = {
        name: '',
        value: 0
      };

      for (let i = 0; i < this.rides.length; i++) {
        /**
         * Figure out how many rides we have per month for the last twelve months
         */
        const rideDate = new Date(this.rides[i].requestedDate);
        const monthNumber = rideDate.getMonth() + 1;
        const dayNumber = rideDate.getDate();
        const day = '' + (dayNumber < 10 ? '0' + dayNumber : dayNumber);
        const month = '' + (monthNumber < 10 ? '0' + monthNumber : monthNumber);

        /**
         * Set up the first month
         */
        if (i === 0) {
          currentData.name = day + '-' + month;
          currentDay = day;
        }

        /**
         * Check if we're in the same month or not and push data if not
         */
        if (day === currentDay) {
          currentData.value = currentData.value + 1;
        } else {
          this.breakdown.push(currentData);
          currentData = {
            name: day + '-' + month,
            value: 1
          };
          currentDay = day;
        }

        /**
         * Push the last data to the array
         */
        if (i >= this.rides.length - 1) {
          this.breakdown.push(currentData);
        }
      }
    }
  }

}
