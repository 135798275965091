export class Coupon {
  id: string;
  name: string;
  code: string;
  discountType: string;
  usageLimit: number;
  usageLimitPerCustomer: number;
  discountPercentage: number;
  startDate: Date;
  endDate: Date;
  companyId?: string;
  customers?: string[];
  redemptions?: any[];
  modified?: string;
  created?: Date;
  limitByUsage?: boolean;
  limitByDate?: boolean;
  limitByCustomer?: boolean;
  LimitByBalanceByCustomer?: boolean;
  discountFixedAmount: number;
  balanceLimitByCustomer: number;

  constructor() {

  }
}
