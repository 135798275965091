import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {InvoiceService} from '../../../../../../services/pas/invoice.service';
import {MultiActionComponent} from '../../../../dispatchpanel/parts/multi-action/multi-action.component';
import {TdDialogService} from '@covalent/core/dialogs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-create-draft-invoices',
  templateUrl: './create-draft-invoices.component.html',
  styleUrls: ['./create-draft-invoices.component.scss'],
  providers: [InvoiceService]
})
export class CreateDraftInvoicesComponent implements OnInit {
  merge = true;
  translations = [];

  constructor(
    private _invoiceService: InvoiceService,
    private _dialogService: TdDialogService,
    private _translateService: TranslateService,
    public dialogRef: MatDialogRef<CreateDraftInvoicesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this._translateService.get([
      `confirm`,
      'payment_origin_inTaxi', 'payment_origin_inApp', 'payment_origin_online', 'payment_origin_invoice'
    ]).subscribe((translations: string[]) => {
      this.translations = translations;
    });
  }

  ngOnInit(): void {
  }

  close(): void {
    this.dialogRef.close();
  }

  generateInvoices(): void {
    this.close();
    this._dialogService.open(MultiActionComponent, {
      maxHeight: '80vw',
      maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
      disableClose: true,
      data: {
        parent: this.data.parent,
        context: this.data.context,
        action: 'generate_draft',
        items: this.data.items,
        debtors: this.data.debtors,
        groupProcess: (this.merge ? 'group' : 'seperate'),
        invoiceType: this.data.invoiceType
      },
    });
  }

  switchTabEmit(tab) {
    this.data.parent.switchTab.emit(tab);
  }
}
