import {DriverScore} from './driver-score';
import {Vehicle} from './vehicle';
import {Company} from './company';

class Avatar {
  small: string;
  smallRounded: string;
  medium: string;
  mediumRounded: string;
  large: string;
  largeRounded: string;
}

export class Driver {
  fname?: string;
  lname?: string;
  phoneNumber?: string;
  emailAddress?: string;
  avatar?: Avatar;
  id?: string;
  type?: string;
  status?: string;
  licenseNumber?: string;
  activeVehicleId?: string;
  companyId?: string;
  userId?: string;
  created?: string;
  modified?: string;
  shadow?: boolean;
  daAppInstallId?: string;
  connections?: string[];
  DriverScore?: DriverScore;
  Vehicles?: Vehicle[];
  Company?: Company;
  meta?: any;
  Installation?: any;
  country?: string;

  constructor() {
    this.id = '';
    this.phoneNumber = '';
    this.fname = '';
    this.lname = '';
    this.type = '';
    this.avatar = new Avatar;
  }
}
