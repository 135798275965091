<section class="mat-typography">
  <h1>{{ 'toggle_special_rates' | translate | ucFirst }}</h1>
  <mat-card-subtitle *ngIf="data.parent.discounts.length > 0">
    {{ 'apps_special_rates_dialog' | translate | ucFirst }}
  </mat-card-subtitle>
</section>

<div class="td-dialog-content">
  <table class="mat-clickable modal-table" td-data-table>
    <thead *ngIf="data.parent.discounts.length > 0">
    <tr td-data-table-column-row>
      <th *ngFor="let c of data.parent.discountColumns"
          [name]="c.name"
          td-data-table-column>
        {{ c.label }}
      </th>
    </tr>
    </thead>

    <tbody *ngIf="data.parent.discounts.length > 0">
    <tr *ngFor="let item of data.parent.discounts" td-data-table-row>
      <td *ngFor="let c of data.parent.discountColumns"
          [numeric]="item.numeric"
          td-data-table-cell>
          <span [ngSwitch]="c.name">
            <span *ngSwitchDefault>
              <span *ngIf="item[c.name] && c.name !== 'isEnabledForApp'">
                {{item[c.name] | ucFirst}}
              </span>
              <mat-checkbox (change)="data.parent.toggleDiscount($event, item)"
                            *ngIf="c.name === 'isEnabledForApp'"
                            [checked]="item[c.name]">
              </mat-checkbox>
            </span>
          </span>
      </td>
    </tr>
    </tbody>
    <tbody *ngIf="data.parent.discounts.length === 0">
    <tr>
      <td [colSpan]="data.parent.discountColumns.length"
          class="table-placeholder">{{'no_discount_rules_found'|translate}}</td>
    </tr>
    </tbody>
  </table>
</div>

<div class="td-dialog-actions">
  <span class="td-dialog-spacer"></span>
  <button (click)="close()" mat-raised-button>
    {{ (data.parent.discounts.length > 0 ? 'save' : 'close') | translate }}
  </button>
</div>
