<table border="0" cellpadding="0" cellspacing="0" class="full-width">
  <tr>
    <td class="{{(hasPassenger ? 'left_column' : 'left_column_small')}}">
      <div class="job-details {{jobDetails[item.id]}}">
        <div class="inner-details">
          <table cellpadding="0" cellspacing="0">
            <tr>
              <td>{{'passenger_name'|translate|ucFirst}}:</td>
              <td>{{(item.passengerName ? item.passengerName : '-')}}</td>
            </tr>
            <tr>
              <td>{{'passenger_phonenumber'|translate|ucFirst}}:</td>
              <td
                *ngIf="item.passengerPhoneNumber && item.passengerPhoneNumber !== 'null' && item.passengerPhoneNumber !== null">
                <a
                  class="yda-link-blue"
                  href="tel:{{item.passengerPhoneNumber}}">{{item.passengerPhoneNumber}}</a></td>
              <td *ngIf="!item.passengerPhoneNumber || item.passengerPhoneNumber === 'null' ||
              item.passengerPhoneNumber === null">-
              </td>
            </tr>
            <tr>
              <td>{{'passenger_email'|translate|ucFirst}}:</td>
              <td *ngIf="item.passengerEmail"><a class="yda-link-blue" href="mailto:{{item.passengerEmail}}"
                                                 target="_new">{{item.passengerEmail}}</a></td>
              <td *ngIf="!item.passengerEmail">-</td>
            </tr>
            <tr *ngIf="item.debtorNumber">
              <td>{{'debtor'|translate|ucFirst}}:</td>
              <td class="debtor-table"><span class="wrap-line">{{item.debtorNumber}} {{item.debtorName}}</span></td>
            </tr>
          </table>
        </div>
      </div>
    </td>
    <td class="center_column">
      <div class="job-details">
        <div class="inner-details">
          <table cellpadding="0" cellspacing="0">
            <tr>
              <td>{{'pricing_type'|translate|ucFirst}}:</td>
              <td>
                {{item.priceMethod.toLowerCase() | translate | ucFirst}}
                <span *ngIf="item.priceMeta && item.priceMeta.bookedDuration">
                  ({{item.priceMeta.bookedDuration}})
                </span>
              </td>
            </tr>
            <tr *ngIf="!item.cancelation">
              <td>{{'calculation_method'|translate|ucFirst}}:</td>
              <td>{{item.calculationMethod|translate|ucFirst}}
                <span *ngIf="item.calculationMethodExtra"> - {{item.calculationMethodExtra|translate|ucFirst}}</span>
              </td>
            </tr>
            <tr *ngIf="item.paymentMethod">
              <td style="vertical-align: top">{{'payment_method'|translate|ucFirst}}:</td>
              <td>
                {{('payment_origin_' + item.paymentMethod.toLowerCase()|translate|ucFirst)}}
                {{(item.Debtor && item.Debtor.name ? item.Debtor.name : '')}}
                {{(item.paymentMeta && item.paymentMeta.method ? ' - ' : '')}}
                {{(item.paymentMeta && item.paymentMeta.method ? item.paymentMeta.method : '') | translate | ucFirst}}
              </td>
            </tr>
            <tr>
              <td style="vertical-align: top">{{'payment_status'|translate|ucFirst}}:</td>
              <td>
                <span *ngIf="item.paymentMethod === 'creditcardOnline'"
                      class="{{item.paymentStatusColor}}">{{item.paymentStatus | translate | ucFirst}}
                </span>
                <span *ngIf="item.paymentMethod === 'inTaxi'"
                      class="{{item.paymentStatusColor}}">{{item.paymentStatus | translate | ucFirst}}
                </span>
              </td>
            </tr>
            <tr>
              <td>{{'price'|translate|ucFirst}}:</td>
              <td>{{(item.price ? item.price : '')|localizedCurrency}} {{item.cancellationReason}}
            </tr>
            <tr *ngIf="item.cancelation">
              <td>{{'status' | translate | ucFirst}} </td>
              <td class="red">{{item.cancellationReason|translate|ucFirst}}{{(item.cancelation.note ? ' - ' + item.cancelation.note: '')|localizedCurrency}}
            </tr>
          </table>
        </div>
      </div>
    </td>
    <td class="right_column">
      <div class="job-details">
        <div class="inner-details">
          <table cellpadding="0" cellspacing="0">
            <tr>
              <td>{{'driver_name'|translate|ucFirst}}:</td>
              <td>{{(item.driver ? item.driver : '-')}}</td>
            </tr>
            <tr>
              <td>{{'driver_phonenumber'|translate|ucFirst}}:</td>
              <td *ngIf="item.Driver"><a class="yda-link-blue"
                                         href="tel:{{item.Driver.phoneNumber}}">{{item.Driver.phoneNumber}}</a></td>
              <td *ngIf="!item.Driver">-</td>
            </tr>
            <tr>
              <td>{{'driver_email'|translate|ucFirst}}:</td>
              <td *ngIf="item.Driver"><a class="yda-link-blue" href="mailto:{{item.Driver.emailAddress}}"
                                         target="_new">{{item.Driver.emailAddress}}</a></td>
              <td *ngIf="!item.Driver">-</td>
            </tr>
            <tr *ngIf="item.flightNumber">
              <td>{{'flightnumber'|translate|ucFirst}}:</td>
              <td>{{item.flightNumber}}</td>
            </tr>
            <tr>
              <td class="note-coll"
                  colspan="2"><span [matTooltip]="getTooltipText(item)" class="wrap-line"
                                          matTooltipClass="new-lines">{{item.note}}</span></td>
            </tr>
          </table>
        </div>
      </div>
    </td>
  </tr>
</table>
