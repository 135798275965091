<mat-card [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <td-search-box (searchDebounce)="search($event)" [alwaysVisible]="true" class="push-left push-right"
                 placeholder="{{'search_here'|translate}}"></td-search-box>
  <mat-divider></mat-divider>
  <ng-template tdLoading="{{loaderName}}">
    <table class="mat-clickable" td-data-table>
      <thead>
      <tr td-data-table-column-row>
        <th (sortChange)="sort($event)"
            *ngFor="let c of columns"
            [active]="(sortBy == c.name)"
            [name]="c.name"
            [numeric]="c.numeric"
            [sortOrder]="( sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
            [sortable]="c.sortable"
            td-data-table-column>
          {{((c.name !== 'tools' ? c.label : '')|translate|ucFirst)}}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of filteredDebtors" td-data-table-row>
        <td (click)="(c.name!='tools' ? open(item.id) : false)" *ngFor="let c of columns" class="{{(c.name=='tools'?'text-right':'')}}"
            td-data-table-cell>
          <span [ngSwitch]="c.name">
            <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span><span
              *ngIf="!item[c.name]">-</span></span>
          </span>
        </td>
      </tr>
      </tbody>
    </table>
    <td-paging-bar #pagingBar (change)="page($event)" [pageSize]="pageSize" [total]="filteredDebtorCount">
      <span hide-xs td-paging-bar-label>Row per page:</span>
      {{pagingBar.range}} <span hide-xs>of {{pagingBar.total}}</span>
    </td-paging-bar>
  </ng-template>
</mat-card>
