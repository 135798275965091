import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TdDialogService} from '@covalent/core/dialogs';
import {TranslateService} from '@ngx-translate/core';
import {Company} from '../../../../../../../../../../models/company';

@Component({
  selector: 'app-message-rule-action-edit-dialog-sms',
  templateUrl: './message-rule-action-edit-dialog-sms.component.html',
  styleUrls: ['./message-rule-action-edit-dialog-sms.component.scss']
})
export class MessageRuleActionEditDialogSmsComponent implements OnInit {
  parent: any
  form: any
  action = 'edit';
  index = 0;
  translationIndex = 0;
  i = 0;
  maxText = 160;
  initMaxText = 160;
  remainingText = [];
  currentLength = [];
  languages = [];
  translations = [];
  company: Company;
  hasError= false;
  items: string[] = [
    'company.name|8',
    'company.email',
    'company.phone_number',
    'company.owner_name|8',
    'passenger.first_name|8',
    'passenger.last_name',
    'passenger.phone_number',
    'passenger.email',
    'driver.first_name|8',
    'driver.last_name|8',
    'driver.phone_number|14',
    'driver.email',
    'vehicle.brand|8',
    'vehicle.model|8',
    'vehicle.type|8',
    'vehicle.licensePlate|8',
    'ride.reference|6',
    'ride.requestedDate|16',
    'ride.departure|14',
    'ride.destination|14',
    'ride.publicUrl|46',
    // 'ride.rating_url',
    'ride.rebook_url',
  ];

  protected readonly Math = Math;

  // Constructor for the component
  constructor(
    private _formBuilder: UntypedFormBuilder,
    public _dialogRef: MatDialogRef<MessageRuleActionEditDialogSmsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialogService: TdDialogService,
    private _translationService: TranslateService,
  ) {
    // Initialize component properties with data from the injected dependencies
    this.i = data.index;
    this.action = data.action;
    this.company = data.company;
    this.parent = data.parent;

    // Set form
    this.form = data.form;

    // Filter languages based on existing messages
    this.languages = data.languages.filter((l) => {
      return (!this.form.controls.actions.controls[this.i].controls.messages.controls.map((c) => {
        return c.controls.language.value
      }).includes(l.short));
    });

    let myLanguage = this.languages.filter((l) => {
      return l.short === this.company.language;
    })[0];

    if (!myLanguage) {
      // Default language already taken? select english "en"
      myLanguage = this.languages.filter((l) => {
        return l.short === 'en';
      })[0];
    }

    // If no translation index is provided, set action to 'addnew' and add a new message control to the form
    if (!this.translationIndex && this.action === 'add') {
      this.form.controls.actions.controls[this.i].controls.messages.controls.push((this._formBuilder.group({
        language: [(myLanguage ? myLanguage.short : this.languages[0].short), [Validators.required]],
        message: ['', [Validators.required]],
      })));
      this.translationIndex = (this.form.controls.actions.controls[this.i].controls.messages.controls.length - 1);
      this.updateValidity();
    } else {
      // Set action
      this.translationIndex = (data.translationIndex ? data.translationIndex : 0);
    }

    // Load translations for confirmation dialog
    _translationService.get(['confirm_delete_translation', 'ok']).subscribe((translations) => {
      this.translations = translations;
    });
  }

  ngOnInit(): void {
    this.currentLength[this.translationIndex] = this.form.controls.actions.controls[this.i].controls.messages.controls[this.translationIndex].value.message.length;
    this.remainingText[this.translationIndex] = this.form.controls.actions.controls[this.i].controls.messages.controls[this.translationIndex].value.message.length;
  }

  formatMention(data) {
    return `{${data.name}}`;
  }

  changeSelectionMatchKey($event, i) {
  }

  valueChange(index, value: any) {
    this.remainingText[index] = this.calculateRemainingText(value.target.value);
    this.currentLength[index] = this.calculateRemainingText(value.target.value);
    return true;
  }

  calculateRemainingText(text) {
    let currentLength = text.length;
    const foundTags = text.match(/({.*?})/g);
    (foundTags ? foundTags : []).forEach((tag) => {
      const splitTag = tag.split('|');
      if (splitTag[1]) {
        // tslint:disable-next-line:radix
        const parsedInt = parseInt(splitTag[1].replace('}', ''));
        if (parsedInt > 0) {
          currentLength = currentLength - tag.length + parsedInt;
        }
      }
    });
    return currentLength;
  }
  close() {
    this._dialogRef.close();
    if (this.form.controls.actions.controls[this.i].controls.messages.controls.length < 1) {
      this.form.controls.actions.controls[this.i].controls.messages.controls.splice(this.translationIndex, 1);
      this.form.controls.actions.controls.splice(this.i, 1);
      this.parent.hasSmsAction = false;
    }
    this.updateValidity();
  }

  save() {
    if (this.form.controls.actions.controls[this.i].controls.messages.controls[this.translationIndex].value.message !== '') {
      this._dialogRef.close();
    } else {
      this.hasError = true;
    }
    this.form.controls.actions.controls[this.i].controls.messages.controls[this.translationIndex].updateValueAndValidity();
  }

  delete() {
    const self = this;
    self._dialogService.openConfirm({
      message: self.translations['confirm_delete_translation'],
      disableClose: false,
      'acceptButton': self.translations['ok'],
    }).afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          self.form.controls.actions.controls[self.i].controls.messages.controls.splice(self.translationIndex, 1);
          self.updateValidity();
        }
        self.close();
      });
  }

  updateValidity() {
    this.form.controls['actions'].updateValueAndValidity();
    this.form.controls.actions.controls[this.i].updateValueAndValidity();
    if(this.form.controls.actions.controls[this.i].controls.messages) {
      this.form.controls.actions.controls[this.i].controls.messages.updateValueAndValidity();
    }
  }
}
