import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {Discount} from '../../models/discount';
import {AuthService} from '../auth.service';

const deleteId = (model: any): any => {
  const copy = Object.assign({}, model);
  delete copy._id;
  return copy;
}

const removeCircular = (obj: any) => {
  Object.keys(obj).forEach(p => {
    if (typeof obj[p] === 'object' && obj[p] !== null) {
      delete obj[p];
    }
  });
  return obj;
}

const objToArr = (obj: {} | null) => obj
  ? [...Object.keys(obj).map(k => obj[k])]
  : [];

const getPropAsArray = (model: any) => {
  if (typeof model === 'object') {
    return objToArr(model);
  } else {
    return [];
  }
}

const discountModel = 'discounts';
const base = environment.tpsBaseUrl;

@Injectable()
export class DiscountService {

  constructor(
    private _http: HttpClient,
    private _auth: AuthService,
  ) {
  }

  getAll = (filter?: Object): Observable<Discount[]> =>
    this._http.get<Discount[]>(
      `${base}/${discountModel}?filter=${JSON.stringify(filter)}`,
      {headers: this.getHeaders()},
    );

  get = (id: string, filter?: Object): Observable<Discount> =>
    this._http.get<Discount>(
      `${base}/${discountModel}/${id}?filter=${JSON.stringify(filter)}`,
      {headers: this.getHeaders()},
    );

  getDiscountLinks = (filter?: Object): Observable<any> =>
    this._http.get<any>(
      `${base}/discountlinks?filter=${JSON.stringify(filter)}`,
      {headers: this.getHeaders()},
    );

  deleteDiscountLink = (id: string): Observable<{ count: boolean }> =>
    this._http.delete<{ count: boolean }>(
      `${base}/discountlinks/${id}`,
      {headers: this.getHeaders()},
    );

  insert = (discount: Discount): Observable<Discount> => {
    if (discount['timeframes']) {
      discount['timeframes'].forEach(t => delete t._id);
    }
    ;
    return this._http.post<Discount>(
      `${base}/${discountModel}`, deleteId(discount),
      {headers: this.getHeaders()},
    );
  }

  update = (discount: any): Observable<any> => {
    const executeRequests = [];
    const timeframes = objToArr(discount['timeframes']);

    timeframes.map(t => executeRequests.push(
      this.updateTimeframe(discount._id, t._id, t)
    ));

    executeRequests.push(this._http.patch<Discount>(
      `${base}/${discountModel}/${discount._id}`,
      deleteId(removeCircular(discount)),
      {headers: this.getHeaders()},
    ));

    return forkJoin(executeRequests);
  }

  updateTimeframe = (discountId: string, timeframeId: string, timeframe: {}): Observable<any> =>
    this._http.put<any>(
      `${base}/${discountModel}/${discountId}/timeframe/${timeframeId}`,
      deleteId(timeframe),
      {headers: this.getHeaders()},
    );

  delete = (id: string): Observable<{ count: boolean }> =>
    this._http.delete<{ count: boolean }>(
      `${base}/${discountModel}/${id}`,
      {headers: this.getHeaders()},
    );

  private getHeaders = () => this._auth.getJWTHeaders();
}
