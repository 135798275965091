export const environment = {
  location: 'production',
  buildName: 'yda',
  portalName: 'YourDriverPortal',
  gtmId: 'G-44E58KT2LZ',
  companyBuild: false,
  companyId: '57b428af02c2ce30c4fb614a',
  daAppId: '599ab313eea012253a008786',
  googleLoginClientId: '56407099630-l9skotlsdf8cbm8h0tij53kuj5c95khj.apps.googleusercontent.com',
  googleCalendarClientId: '56407099630-64q8be4pcn5clgsb92kckikgn6qc9aq0.apps.googleusercontent.com',
  driverAppInstallationId: '59dcbacd7b5621a4b9e8ef4a',
  webbookerDaAppId: '5d9318e22c82763b30779e58',
  webbookerPriceId: 'price_1KHUd4AAAqH6BJkpNsQ722OD',
  passengerDaAppId: '599ae38266608bd6d04e4f13',
  production: true,
  showSupportButton: true,
  showCompanyInvoices: true,
  modules: {
    DriverModule: true,
    CompanyModule: true
  },
  vaultPrefix: 'yda.production.portal',
  apiBaseUrl: 'https://api.dispatchapi.io/api',
  apiAccessToken: '7POlpetsd4gVhVop2X6wXypXHE1O3i5J',
  orderFormApiUrl: 'https://order.dispatchapi.io',
  orderFormFrontEndUrl: 'https://yourwebbooker.com',
  tpsBaseUrl: 'https://tps.dispatchapi.io/api',
  pasBaseUrl: 'https://pas.dispatchapi.io/api',
  translationId: '591afd8a17f51db67fc57968',
  portalUrl: 'https://portal.yourdriverapp.com',
  googleApiKey: 'AIzaSyBrd7sl74-EU7tqtWYK_jyd0i0quamBbQU',
  mapLineColor: '#03A9F4',
  windowTitleSuffix: ' - YourDriverPortal',
  colors: {
    primary: '#03A9F4',
    accent: '#455A64'
  },
  speedUnit: 'kmh',
  speedUnitMultiplier: 3.6,
  stripeKey: 'pk_live_51Hk3p7AAAqH6BJkpr4o8GHGaYD7zzvZlSr0xl5smFMSQ1SDoQLH00QnUMYH2beMFfKSaTP9DwPgGTP22KmNu0J7u00xpQW27hQ',
  stripeCloudConnectId: 'price_1HlfQ5AAAqH6BJkp6zhfmE6I',
  stripeOauthClientId: 'ca_IL9aWQnykMIYAe2TsrExnHPxY7WXyKRw',
  extendedDispatcherPriceId: 'price_1HlfSxAAAqH6BJkpJ18t51eS',
  products: {
    webbooker: {
      'priceId': 'price_1LJH9lAAAqH6BJkpCgrW1fBL',
      'features': {
        'responsive_layout': 'yes',
        'widget': 'yes',
        'customer_email_confirmations': 'yes',
        'fare_calculations': 'yes',
        'stripe_integration': 'yes',
        'custom_products': 'yes',
        'customer_ratings': 'yes',
        'customer_coupons': 'yes',
        'customer_portal': 'no',
        'operator_notifications': 'yes',
        'manage_customers': 'no',
        'assign_bookings': 'no',
        'whatsapp_telegram_integrations': 'no',
        'gps_tracking_assigned': 'no',
        'dispatchpanel_create_jobs': 'no',
        'gps_tracking_fleet': 'no',
        'free_updates': 'yes',
        'sms_integrations': 'no',
        'api': 'no',
        'mobile_dispatch_app': 'no',
        'driver_app': 'no',
        'price_per_month': '€5,-',
        'billing_cycle': 'yearly',
        'setup_costs': '€0,-',
        'bookings_included': '600_year',
        'extra_bookings': '€0,10',
      }
    },
    webbookerPlus: {
      'priceId': 'price_1LJHAJAAAqH6BJkppYvtYrLV',
      'upgradeId': '922ChLTt',
      'features': {
        'responsive_layout': 'yes',
        'widget': 'yes',
        'customer_email_confirmations': 'yes',
        'fare_calculations': 'yes',
        'stripe_integration': 'yes',
        'custom_products': 'yes',
        'customer_ratings': 'yes',
        'customer_coupons': 'yes',
        'customer_portal': 'yes',
        'operator_notifications': 'yes',
        'manage_customers': 'yes',
        'assign_bookings': 'yes',
        'whatsapp_telegram_integrations': 'yes',
        'gps_tracking_assigned': 'yes',
        'dispatchpanel_create_jobs': 'no',
        'gps_tracking_fleet': 'no',
        'free_updates': 'yes',
        'sms_integrations': 'yes',
        'api': 'yes',
        'mobile_dispatch_app': 'yes',
        'driver_app': 'yes',
        'price_per_month': '€15,-',
        'billing_cycle': 'yearly',
        'setup_costs': '€0,-',
        'bookings_included': '1800_year',
        'extra_bookings': '€0,10',
      }
    },
    fleetSolutions: {
      'priceId': 'price_1LJHBDAAAqH6BJkpJTuT1liq',
      'features': {
        'responsive_layout': 'yes',
        'widget': 'yes',
        'customer_email_confirmations': 'yes',
        'fare_calculations': 'yes',
        'stripe_integration': 'yes',
        'custom_products': 'yes',
        'customer_ratings': 'yes',
        'customer_coupons': 'yes',
        'customer_portal': 'yes',
        'operator_notifications': 'yes',
        'manage_customers': 'yes',
        'assign_bookings': 'yes',
        'whatsapp_telegram_integrations': 'yes',
        'gps_tracking_assigned': 'yes',
        'dispatchpanel_create_jobs': 'yes',
        'gps_tracking_fleet': 'yes',
        'free_updates': 'yes',
        'sms_integrations': 'yes',
        'api': 'yes',
        'mobile_dispatch_app': 'yes',
        'driver_app': 'yes',
        'price_per_month': '€45,-',
        'billing_cycle': 'monthly',
        'setup_costs': '€0,-',
        'bookings_included': '450_month',
        'extra_bookings': '€0,10',
      }
    }
  },
  webBookerDefault: {
    luggage: {
      'type': 'select',
      'property': 'luggage',
      'meta': true,
      'quotation': true,
      'numeric': true,
      'label': 'luggage_field_label',
      'helpText': 'luggage_field_help_text_pretax',
      'placeholder': 'luggage_field_placeholder',
      'default': '',
      'values': [
        {
          'value': '',
          'label': 'luggage_field_please_select'
        },
        {
          'value': 0,
          'label': 'luggage_field_value_zero'
        },
        {
          'value': 1,
          'label': 'luggage_field_value_singular'
        },
        {
          'value': 2,
          'label': 'luggage_field_value_plural'
        },
        {
          'value': 3,
          'label': 'luggage_field_value_plural'
        },
        {
          'value': 4,
          'label': 'luggage_field_value_plural'
        },
        {
          'value': 5,
          'label': 'luggage_field_value_plural'
        },
        {
          'value': 6,
          'label': 'luggage_field_value_plural'
        },
        {
          'value': 7,
          'label': 'luggage_field_value_plural'
        },
        {
          'value': 8,
          'label': 'luggage_field_value_plural'
        }
      ],
      'required': false
    }
  },
  'stripeTaxRates': [
    'txr_1Ig6S0AAAqH6BJkpgngzuty7'
  ],
  'paymentMethods': ['inTaxi', 'invoice', 'online', 'creditcardOnline'],
  'languages': ['en', 'de', 'fr', 'es', 'it', 'nl', 'ua', 'ar']
};
