import {Component, OnInit} from '@angular/core';
import {NavigationService} from '../../../../services/navigation.service';
import {ActivatedRoute} from '@angular/router';
import {Video} from '../../../../models/youtube.models';
import {YoutubeService} from '../../../../services/youtube.service';
import {Observable} from 'rxjs/Observable';
import {TdDialogService} from '@covalent/core/dialogs';
import {YoutubeVideoComponent} from './parts/youtube-video/youtube-video.component';

@Component({
  selector: 'app-instruction-videos',
  templateUrl: './instruction-videos.component.html',
  styleUrls: ['./instruction-videos.component.scss']
})
export class InstructionVideosComponent implements OnInit {
  public videos: Video[] = [];
  public channels: any[] = [];
  public channelVideos = {};

  constructor(
    public youtubeService: YoutubeService,
    private _navigationService: NavigationService,
    private _route: ActivatedRoute,
    private _dialogService: TdDialogService,
  ) {
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);
  }

  ngOnInit(): void {
    this.getChannels();
    this.getVideos();
  }

  getChannels() {
    this.youtubeService.getChannels()
      .subscribe(resp => {
        this.channels.push(...resp);
        this.channels.forEach((channel) => {
          channel.title = channel.snippet.title;
          this.getChannelVideos(channel.id).subscribe((result) => {
            result = result.filter((r) => {
              return (r.title !== 'Deleted video' ? r : false);
            })
            this.channelVideos[channel.id] = result;
          });
        });
      });
  }

  getVideos() {
    this.youtubeService.getVideos()
      .subscribe(resp => {
        this.videos.push(...resp);
      });
  }

  getChannelVideos(channelId): Observable<any> {
    return this.youtubeService.getChannelVideos(channelId);
  }

  openVideo(video: Video) {
    this._dialogService.open(YoutubeVideoComponent, {
      height: '95%',
      minWidth: '95%',
      disableClose: false,
      data: {
        openFromDialog: true,
        videoId: video.resourceId.videoId
      },
    });
  }
}
