<mat-card-subtitle>{{'overwrite_availability_subtitle' | translate | ucFirst}}</mat-card-subtitle>

<mat-divider></mat-divider>
<ng-template tdLoading="promotions.list">
  <table class="mat-clickable" td-data-table>
    <thead>
    <tr td-data-table-column-row>
      <th (sortChange)="sort($event)"
          *ngFor="let c of columns"
          [active]="(sortBy == c.name)"
          [name]="c.name"
          [numeric]="c.numeric"
          [sortOrder]="(sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
          [sortable]="c.sortable"
          td-data-table-column>
        {{c.label|translate|ucFirst}}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of this.filteredData[type]"
        class="pointer" td-data-table-row>
      <td *ngFor="let c of columns" [numeric]="item.numeric" td-data-table-cell>
          <span [ngSwitch]="c.name">
            <span *ngSwitchCase="'startDate'"><span
              *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>

            <span *ngSwitchCase="'endDate'"><span
              *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>

            <span *ngSwitchCase="'type'"><span *ngIf="item[c.name]">{{item[c.name]|translate|ucFirst}}</span></span>

            <span *ngSwitchCase="'lastPushDate'"><span
              *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>

            <span *ngSwitchCase="'tools'">
              <button [matMenuTriggerFor]="menu" mat-icon-button>
                <mat-icon>more_vert</mat-icon>
              </button>

              <mat-menu #menu="matMenu">
                <button (click)="edit(item.id)" mat-menu-item>
                  <mat-icon>edit</mat-icon>
                  <span>{{'edit'|translate|ucFirst}}</span>
                </button>
                <button (click)="delete(item.id)" mat-menu-item>
                  <mat-icon>delete</mat-icon>
                  <span>{{'delete'|translate|ucFirst}}</span>
                </button>
              </mat-menu>
            </span>

            <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span></span>
          </span>
      </td>
    </tr>
    </tbody>
  </table>
  <td-paging-bar #paging (change)="page($event)" [pageSize]="100" [total]="filteredTotal">
    <span hide-xs>{{'rows_p_page'|translate}}:</span>
    {{paging.range}} <span hide-xs>of {{paging.total}}</span>
  </td-paging-bar>
</ng-template>
