import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UtilityService} from './utility.service';
import {CoolLocalStorage} from '@angular-cool/storage';
import {environment} from '../../environments/environment';
import {Contract} from '../models/contract';
import {Observable} from 'rxjs/Observable';
import {GlobalService} from './global.service';

const appModel = 'contracts';
const base = environment.apiBaseUrl;

@Injectable({
  providedIn: 'root'
})
export class ContractService extends GlobalService {
  constructor(_http: HttpClient, _vault: CoolLocalStorage, _utilityService: UtilityService) {
    super(_http, _vault, _utilityService);
    this.setModel('contracts');
  }

  createPaymentSession = (data?: Object, context?: string): Observable<any> =>
    this._http.post<Contract>(
      `${base}/psp/stripeSession`, data,
      {headers: this.getHeaders(context)},
    );

  getPrefill = (token: string, filter?: Object, context?: string): Observable<any> =>
    this._http.get<Contract>(
      `${base}/${appModel}/prefill/${token}`,
      {headers: this.getHeaders(context)},
    );

  getCurrentUsage = (id: string, filter?: Object, context?: string): Observable<any> =>
    this._http.get<Contract>(
      `${base}/${appModel}/${id}/currentUsage?filter=${JSON.stringify(filter)}`,
      {headers: this.getHeaders(context)},
    );

  getUpcommingInvoice = (id: string, filter?: Object, context?: string): Observable<any> =>
    this._http.get<Contract>(
      `${base}/${appModel}/${id}/upcommingInvoice?filter=${JSON.stringify(filter)}`,
      {headers: this.getHeaders(context)},
    );

  billingHistory = (id: string, filter?: Object, context?: string): Observable<any> =>
    this._http.get<Contract>(
      `${base}/${appModel}/${id}/billingHistory?filter=${JSON.stringify(filter)}`,
      {headers: this.getHeaders(context)},
    );

  convertLicenseKey = (companyId: string, licenseKey: string, context?: string): Observable<any> =>
    this._http.post<Contract>(
      `${base}/${appModel}/convertLicenseKey/${companyId}/`, {'licenseKey': licenseKey},
      {headers: this.getHeaders(context)},
    );

  addProduct(id: string, product?: Object, context?: string): Observable<any> {
    return this._http
      .post<any>(`${environment.apiBaseUrl}/${appModel}/${id}/addProduct`, product, {headers: this.getHeaders(context)});
  }

  removeProduct(id: string, product?: Object, context?: string): Observable<any> {
    return this._http
      .post<any>(`${environment.apiBaseUrl}/${appModel}/${id}/removeProduct`, product, {headers: this.getHeaders(context)});
  }

  startFreeTrial(companyId: string, context?: string): Observable<any> {
    return this._http.post<Contract>(`${base}/${appModel}/freetrial/${companyId}/`, {}, {headers: this.getHeaders(context)});
  }

  getCustomerPortalSession(contractId: string, returnUrl: string, context?: string): Observable<any> {
    return this._http.post<Contract>(`${base}/${appModel}/${contractId}/stripeCustomerPortalSession`, {returnUrl}, {headers: this.getHeaders(context)});
  }

  cancelContract(contractId: string, reason: string, context?: string) {
    const body = {
      refund: true,
      reason
    };
    return this._http.post<Contract>(`${base}/${appModel}/${contractId}/cancelContract`, body, {headers: this.getHeaders(context)});
  }
}
