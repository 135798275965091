import {Injectable} from '@angular/core';
import {GlobalService} from './global.service';
import {HttpClient} from '@angular/common/http';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from './utility.service';
import {Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebbookerService extends GlobalService {
  constructor(_http: HttpClient, _vault: CoolLocalStorage, _utilityService: UtilityService, private _router: Router) {
    super(_http, _vault, _utilityService);
    this.setModel('forms');
    this.base = environment.orderFormApiUrl;
  }

  public upload(id: string, object: any, query: any, context?: string) {
    const uploadURL = `${environment.apiBaseUrl}/rackspace/upload?${query}`;
    const headers = this.getHeaders(context);
    headers['Content-type'] = 'application/x-www-form-urlencoded; charset=utf-8';

    return this._http.post<any>(uploadURL, object, {headers: headers});
  }

  public deleteLogo(id: string, object: any, query: any, context?: string) {
    const uploadURL = `${environment.apiBaseUrl}/rackspace/delete?${query}`;
    const headers = this.getHeaders(context);
    headers['Content-type'] = 'application/x-www-form-urlencoded; charset=utf-8';

    return this._http.post<any>(uploadURL, object, {headers: headers});
  }

  get(id: string, filter?: Object, headers?: any): Observable<any> {
    return this._http
      .get<any>(`${this.base}/${this.model}/${id}${(filter ? `?filter=${JSON.stringify(filter)}` : '')}`,
        {headers});
  }

  update(id: string, object: any, headers?: any): Observable<any> {
    return this._http.patch<any>(`${this.base}/${this.model}/${id}`, object, {headers});
  }

  deleteAccount(id: string, context: string): Observable<any> {
    return this._http.delete(`${this.base}/accounts/${id}`, {headers: this.getHeaders(context)});
  }
}
