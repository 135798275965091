<div *ngIf="(!this.contract
      || (this.contract && this.contract.foreignStatus !== 'incomplete'))" class="{{((!this.contract || (this.contract && this.contract.foreignStatus !== 'active'))
      && this.company.ActiveContract && this.company.ActiveContract.reference ? 'suspendedContractOverlay' :
      'noContractOverlay')}}">
  <div *ngIf="this.isOwner && (
        (!this.contract || (!this.company.ActiveContract.foreignStatus || !this.company.ActiveContract.reference)) ||
        ['webbooker_license','webbooker_plus_license'].includes(this.contract.type) ||
        (this.company.hasDispatcherLicense &&
          (!this.company.ActiveContract.foreignStatus || !this.company.ActiveContract.reference)
        )
      ) && this.step === 1" [@fade]="this.state" class="noContractModal_step1">
    <div
      class="step1NoContract">
      <mat-card-title>{{'fleet_solution_license_required_title'|translate|ucFirst}}</mat-card-title>
      <mat-card-subtitle>{{translations['feature_not_included']}}</mat-card-subtitle>
      <div class="action-pane">
        <button (click)="this.openUpgradeContract()" color="accent"
                flex
                mat-stroked-button>{{'upgrade_license'|translate|ucFirst}}</button>
      </div>
    </div>
  </div>

  <div *ngIf="this.contract
      && (this.contract.foreignStatus !== 'active' && this.contract.foreignStatus !== 'incomplete' &&
      (this.company.ActiveContract.reference || this.contract.reference))
      && (!this.company.hasDispatcherLicense || this.contract.foreignStatus !== 'active')" [@fade]="this.state" class="noContractModal">
    <app-suspended [contract]="this.contract"></app-suspended>
  </div>
  <div *ngIf="!this.isOwner && ( !this.contract ||
           (((!this.company.ActiveContract.foreignStatus || !this.company.ActiveContract.reference)) ||
            this.contract.type === 'webbooker_license' ||
            ( this.company.hasDispatcherLicense && (!this.company.ActiveContract.foreignStatus ||
             !this.company.ActiveContract.reference) ))
          )" [@fade]="this.state" class="noContractModal">
    <app-no-contract-admin [contract]="this.contract"></app-no-contract-admin>
  </div>
</div>
