import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {TdDialogService} from '@covalent/core/dialogs';

@Component({
  selector: 'app-ob-completed',
  templateUrl: './ob-completed.component.html',
  styleUrls: ['./ob-completed.component.scss']
})
export class ObCompletedComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() webbooker: any;
  @Input() step: string;
  @Output() nextStep = new EventEmitter<string>();
  onboardCompletedText: string;
  integrationInstructions = false;

  constructor(
    private _translateService: TranslateService,
    private _dialogService: TdDialogService,
  ) {
    _translateService.get(['onboard_completed_subtitle'])
      .subscribe((translations: any) => {
        this.onboardCompletedText = translations['onboard_completed_subtitle'];
      });
  }

  ngOnInit(): void {
    console.log(this.webbooker);
  }

  setStep(step) {
    this.nextStep.emit(step);
  }

  openIntegrationInstructions(type): void {
    this.integrationInstructions = type;
  }
}
