<table border="0" cellpadding="0" cellspacing="0" class="full-width">
  <table class="mat-clickable" td-data-table>
    <thead>
    <tr td-data-table-column-row>
      <th
          *ngFor="let c of columns"
          [name]="c.name"
          class="{{c.maxSize}} {{c.maxWidth}} {{c.fixedSize}}"
          td-data-table-column>
        {{c.label|translate|ucFirst}}
      </th>
    </tr>
    </thead>
    <tbody>
    <tr (click)="openJobDetails(item.id)" *ngFor="let item of data" td-data-table-row>
      <td *ngFor="let c of columns" [numeric]="c.numeric" class="{{c.maxSize}} {{c.maxWidth}} {{c.fixedSize}}"
          td-data-table-cell>
              <span [ngSwitch]="c.name">
                <span *ngSwitchCase="'requestedDate'"><span
                  *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>
                <span *ngSwitchCase="'departure'"><span *ngIf="item[c.name]" class="wrap-line"
                                                        title="{{item.departure}}">{{item.departure}}</span><span
                  *ngIf="!item[c.name]">-</span></span>
                <span *ngSwitchCase="'destination'"><span *ngIf="item[c.name]" class="wrap-line"
                                                          title="{{item.destination}}">{{item.destination}}</span><span
                  *ngIf="!item[c.name]">-</span></span>
                <span *ngSwitchCase="'status'"><span
                  *ngIf="item[c.name]">{{'ride_status_' + item[c.name] | translate |ucFirst}}</span><span
                  *ngIf="!item[c.name]">-</span></span>
                <span *ngSwitchCase="'fareAmount'"><span
                  *ngIf="item[c.name]">{{(item[c.name]) | localizedCurrency}}</span><span *ngIf="!item[c.name]">-</span></span>
                <span *ngSwitchCase="'driverPrice'"><span
                  *ngIf="item[c.name]">{{(item[c.name]) | localizedCurrency}}</span><span *ngIf="!item[c.name]">-</span></span>
                <span *ngSwitchCase="'driverSettlement'">
                    <span *ngIf="item[c.name]"
                          [ngClass]="(item[c.name].total >= 0 ? 'debit' : 'credit')">{{(item[c.name]) | localizedCurrency}}</span>
                  <span *ngIf="!item[c.name]">-</span></span>
                <span *ngSwitchCase="'commissionAmount'"><span
                  *ngIf="item[c.name]">{{(item[c.name]) | localizedCurrency}}</span><span *ngIf="!item[c.name]">-</span></span>
                <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name] | ucFirst}}</span><span
                  *ngIf="!item[c.name]">-</span>
                </span>
              </span>
      </td>
    </tr>
    </tbody>
  </table>
</table>
