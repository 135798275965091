import {Component, Inject, OnInit} from '@angular/core';
import {Company} from '../../../models/company';
import {DriverService} from '../../../services/driver.service';
import {CompanyWebUserService} from '../../../services/companyWebUser.service';
import {CompanyService} from '../../../services/company.service';
import {Driver} from '../../../models/driver';
import {CoolLocalStorage} from '@angular-cool/storage';
import {AuthService} from '../../../services/auth.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TdLoadingService} from '@covalent/core/loading';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {User} from '../../../models/user';
import {SupportWorkspaceSelectionComponent} from '../support-workspace-selection/support-workspace-selection.component';
import {TdDialogService} from '@covalent/core/dialogs';
import {TranslateService} from '@ngx-translate/core';
import {ucFirst} from '../../../pipes/uc-first.pipe';
import {CloseProfileComponent} from '../../company/settings/general/parts/close-profile/close-profile.component';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-login-selection',
  templateUrl: './workspace-selection.component.html',
  styleUrls: ['./workspace-selection.component.scss'],
})
export class WorkspaceSelectionComponent implements OnInit {
  driver: Driver;
  user: User;
  company: Company = {};
  companies: Company[] = [];
  operatorCompanies: Company[] = [];
  ownerCompanies: Company[] = [];
  supportTrialCompanies: Company[];
  supportContractCompanies: Company[];
  loading = true;
  noRedirect = false;
  parent: any;
  target = 'dispatchpanel';
  translations: string[];

  constructor(
    private _route: ActivatedRoute,
    private _translateService: TranslateService,
    private _router: Router,
    private _driverService: DriverService,
    private _companyWebUserService: CompanyWebUserService,
    private _companyService: CompanyService,
    private _loadingService: TdLoadingService,
    private _vault: CoolLocalStorage,
    private _authService: AuthService,
    private _snackBar: MatSnackBar,
    private _dialogService: TdDialogService,
    public dialogRef: MatDialogRef<WorkspaceSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.driver = JSON.parse(this._vault.getItem(`${environment.vaultPrefix}.driver`));
    this.user = JSON.parse(this._vault.getItem(`${environment.vaultPrefix}.user`));

    if (data.parent && data.parent.company) {
      this.company = data.parent.company;
    }
    if (data) {
      this.parent = data;
    }
    if (data.redirect) {
      this.target = data.redirect;
    } else {
      this.noRedirect = true;
    }

    _translateService.get([
      'select_login_no_companies_subtitle',
      'confirm_leave_workspace_message',
      'confirm_leave_workspace_title', 'yes', 'no', 'workspace_left']).subscribe((translations: string[]) => {
      this.translations = translations
      if (this.user) {
        if (this.translations) {
          this.translations['select_login_no_companies_subtitle'] = this.translations['select_login_no_companies_subtitle'].replace('{email}', this.user.email);
        }
      }
    });
  }

  ngOnInit(): void {
    // this._loadingService.register('workspace');
    if (!this.user) {
      this._vault.clear();
      this._authService.setAuthState(false);
      window.location.reload();
      return;
    }
    this.loadData();
  }

  loadData(): void {
    const self = this;
    /**
     * Check if we have multiple companies, then redirect to login selection
     */
    self._companyWebUserService.getCompanies().subscribe((companyWebUser: any[]) => {
      if (companyWebUser.length > 0) {
        const operatorCompanies = companyWebUser.filter((user) => {
          return (user.Company && (user.type === 'operator'));
        });

        self.operatorCompanies = operatorCompanies.map((operator) => {
          if (operator.Company) {
            operator.Company.name = operator.Company.name.charAt(0).toUpperCase() + operator.Company.name.slice(1);
            operator.Company.type = operator.type;
            return operator.Company;
          }
        });

        self.operatorCompanies = self.operatorCompanies.sort(function (a, b) {
          if (a && b) {
            return (a['name'] > b['name']) ? 1 : (a['name'] < b['name']) ? -1 : 0;
          }
        });

        let adminCompanies = companyWebUser.filter((user) => {
          return (user.Company && (user.type === 'owner' || user.type === 'admin'));
        });

        const nAdminCompanies = {};
        adminCompanies = adminCompanies.filter(function (item) {
          if (!nAdminCompanies[item.companyId]) {
            nAdminCompanies[item.companyId] = true;
            return (item);
          } else {
            return false;
          }
        });

        console.log(companyWebUser);
        console.log(adminCompanies);

        self.companies = adminCompanies.map((company) => {
          if (company.Company) {
            company.Company.name = company.Company.name.charAt(0).toUpperCase() + company.Company.name.slice(1);
            company.Company.type = company.type;
            company.Company.companyWebUserId = company.id;

            if (company.Company && company.Company.ActiveContract && company.Company.ActiveContract.type === 'webbooker_license') {
              company.Company.target = 'jobs';
            } else if (company.Company && (!company.Company.ActiveContract || company.Company.ActiveContract.status !== 'active')) {
              company.Company.target = 'contract';
            }
            return company.Company;
          }
        });

        self.companies = self.companies.sort(function (a, b) {
          if (a && b) {
            return (a['name'] > b['name']) ? 1 : (a['name'] < b['name']) ? -1 : 0;
          }
        });


        if (self.companies.length === 1 && !this.noRedirect) {
          self.dialogRef.close();
          self._router.navigate([`/groups/${self.companies[0].id}/${this.target}`]);
        }
      } else {
        self.dialogRef.updateSize('700px', '300px');
      }
      this.loading = false;
      this._loadingService.resolve('workspace');
    }, (error) => {
      console.log(error);
      this.logout();
    });
  }

  logout(): void {
    this._authService.logout();
    this.dialogRef.close();
    window.location.reload();
  }

  openWorkSpace(url, companyId?): void {
    if (!companyId) {
      window.location.href = url;
      this.dialogRef.close();
    } else {
      this.checkOnboardingWizard(url, companyId)
        .then((redirectUrl: string) => {
          window.location.href = redirectUrl;
          this.dialogRef.close();
        });
    }
  }

  checkOnboardingWizard(url, companyId) {
    return new Promise((resolve) => {
      this._companyService.get(companyId)
        .subscribe((company) => {
          if (!company.settings.wizard || !['completed', 'done'].includes(company.settings.wizard) && this._vault.getItem('wizard_skipped') !== 'true') {
            if (company.settings.wizard === 'skipped') {
              company.settings.wizard = 'webbooker';
            }
            return resolve(`onboarding-wizard/${company.id}/${(company.settings.wizard ? company.settings.wizard : 'webbooker')}`);
          } else {
            return resolve(url);
          }
        })
    });
  }

  openSupportWorkSpace(): void {
    this.dialogRef.close();
    this._dialogService.open(SupportWorkspaceSelectionComponent, {
      maxHeight: '80%',
      minWidth: (window.innerWidth < 500 ? '95%' : (this.user.ydaSupportUser ? '700px' : '500px')),
      disableClose: true,
      data: {
        openFromDialog: true,
        openFromLogin: true,
        parent: this
      },
    });
  }

  deleteFleet(company): void {
    this.dialogRef.close();
    this._dialogService.open(CloseProfileComponent, {
      maxHeight: '80%',
      minWidth: (window.innerWidth < 500 ? '95%' : '500px'),
      disableClose: false,
      data: {
        openFromDialog: true,
        openFromLogin: true,
        parent: this,
        company: company
      },
    });
  }

  leaveWorkspace(id): void {
    const self = this;
    self._dialogService.openConfirm({
      message: self.translations['confirm_leave_workspace_message'],
      disableClose: false,
      width: '500px',
      title: self.translations['confirm_leave_workspace_title'],
      cancelButton: self.translations['no'],
      acceptButton: self.translations['yes'],
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        self._companyWebUserService.delete(id, 'company').subscribe(() => {
          setTimeout(function () {
            self._snackBar.open(ucFirst(self.translations['workspace_left']), self.translations['ok'], {
              duration: 3000
            });
            self.loadData();
          }, 500);
        }, error => {
          self.loadData();
        })
      }
    });
  }
}
