<div class="parent">
  <div class="integration-type-pane left-pane">
    <mat-card-content class="integration-pane">
      <mat-card-title>{{'add_new_message'|translate|ucFirst}}</mat-card-title>

      <div (click)="(!this.parent.hasSmsAction ? setType('sms') : '')" class="integration-type {{(this.parent.hasSmsAction ? 'disabled' : '')}} {{(type==='sms' ? 'activeOption' : '')}}"
           layout="row">
        <mat-icon>sms</mat-icon>
        <div>
          <h3 flex>{{('sms_message')|translate|ucFirst}}</h3>
        </div>
      </div>
      <div (click)="(!this.parent.hasEmailAction ? setType('email') : '')"
           class="integration-type {{(type==='email' ? 'activeOption' : '')}} {{(this.parent.hasEmailAction ? 'disabled' : '')}}"
           layout="row">
        <mat-icon>email</mat-icon>
        <div>
          <h3 flex>{{('email')|translate|ucFirst}}</h3>
        </div>
      </div>
    </mat-card-content>
  </div>
</div>
<mat-divider></mat-divider>
<mat-card-actions>

  <button (click)="startNew()" mat-raised-button color="accent" class="and-button">
    {{ 'add_action' | translate | ucFirst }}
  </button>
  <button (click)="close()" mat-flat-button>
    {{ 'close' | translate | ucFirst }}
  </button>
</mat-card-actions>
