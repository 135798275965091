<ng-template *ngIf="this.driver && this.driver.Installation &&
this.driver.Installation.Subscription" [ngForOf]="mainItems" let-item let-last="last" ngFor>
  <mat-nav-list class="push-bottom">
    <ng-template [ngForOf]="item.routes" let-item let-last="last" ngFor>
      <a (click)="layout.close()" *ngIf="item.showInMenu" [routerLink]="['/'+item.path]" mat-list-item>
        <mat-icon>{{item.icon}}</mat-icon>
        {{item.label}}</a>
    </ng-template>
  </mat-nav-list>
</ng-template>

<ng-template [ngForOf]="companyItems" let-i="index" let-item let-last="last" ngFor>
  <mat-nav-list class="push-bottom bottom">
    <h3 mat-subheader>{{item.name|ucFirst}}</h3>
    <ng-template [ngForOf]="item.routes" let-item let-last="last" ngFor>
      <a (click)="layout.close()" *ngIf="item.showInMenu" [routerLink]="['/'+item.path]"
         [skipLocationChange]="false"
         mat-list-item
         routerLinkActive="mat-accent">
        <mat-icon class="avatar-icon">people</mat-icon>
        {{item.label}}
      </a>
    </ng-template>
  </mat-nav-list>
</ng-template>
