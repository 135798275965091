<mat-list-item>
  <mat-icon mat-list-icon>pie_chart</mat-icon>
  <h4 mat-line>{{'commission_and_settlement'|translate|ucFirst}}</h4>
  <p mat-line>{{'ride_commission_and_settlement_subtitle'|translate|ucFirst}}</p>
</mat-list-item>
<div *ngIf="isOwner" [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row"
     tdMediaToggle="gt-xs">
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'commission_rules'|translate|ucFirst}}</p>
    <p *ngIf="commissionRules.length > 0" mat-line>
      <ng-container *ngFor="let rule of commissionRules;  let i = index;">
        <span *ngIf="i > 0"> + </span>
        <span *ngIf="rule.type === 'percentage'">{{rule.value}}%</span>
        <span *ngIf="rule.type === 'driverPrice'">{{'fixed_driver_price'|translate|ucFirst}}</span>
        <span *ngIf="rule.type === 'fixed'">{{'fixed_commission'|translate|ucFirst}}</span>
      </ng-container>
    </p>
    <p *ngIf="commissionRules.length == 0" mat-line>
      {{'no_commission_rules'|translate|ucFirst}}
    </p>
  </mat-list-item>
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'commission_amount'|translate|ucFirst}}</p>
    <p mat-line>{{commission | localizedCurrency}}</p>
  </mat-list-item>
</div>
<div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'driver_price'|translate|ucFirst}}</p>
    <p *ngIf="driverSettlement" mat-line>{{driverPrice | localizedCurrency}}</p>
    <p *ngIf="!driverSettlement" mat-line>-</p>
  </mat-list-item>

  <mat-list-item *ngIf="driverSettlement && !(isOwner && isDriver)" class="value small" flex-gt-sm="50">
    <p *ngIf="!this.driverSettlement.toPay && !isOwner"
       mat-line>{{'driver_settlement_to_receive'|translate|ucFirst}}</p>

    <p *ngIf="this.driverSettlement.toPay && !isOwner" mat-line>{{'driver_settlement_to_pay'|translate|ucFirst}}</p>

    <p *ngIf="!this.driverSettlement.toPay && isOwner"
       mat-line>{{'owner_driver_settlement_to_receive'|translate|ucFirst}}</p>
    <p *ngIf="this.driverSettlement.toPay && isOwner"
       mat-line>{{'owner_driver_settlement_to_pay'|translate|ucFirst}}</p>

    <p *ngIf="driverSettlement.amount === 0" mat-line>{{'nothing_to_settle'|translate|ucFirst}}</p>
    <p [className]="(this.driverSettlement.toPay ? 'negative' : 'positive')"
       mat-line>{{driverSettlement | localizedCurrency:':amount'}}</p>
  </mat-list-item>
  <mat-list-item *ngIf="!driverSettlement" class="value small" flex-gt-sm="50">
    <p mat-line>{{'driver_settlement'|translate|ucFirst}}</p>
    <p mat-line>-</p>
  </mat-list-item>
</div>
