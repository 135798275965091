<div [formGroup]="form" flex="100" class="condition-pane">
  <div formArrayName="actions">
    <div [formGroupName]="i">
      <div formArrayName="messages">
        <div [formGroupName]="translationIndex" class="edit-pane">
          <mat-card-title>{{ 'message_center_action_email_title' | translate | ucFirst }}</mat-card-title>
          <div flex="75" layout="row">
            <mat-form-field class="email-subject">
              <input formControlName="subject" matInput
                     placeholder="{{'email_subject'|translate|ucFirst}}" type="text">
            </mat-form-field>
          </div>

          <div flex="75" layout="row">
            <div layout="column" class="push-right" flex="30" *ngIf="action === 'add'">
              <mat-form-field>
                <mat-select (selectionChange)="changeSelectionMatchKey($event, i)"
                            class="row-select"
                            flex="100"
                            formControlName="language" required placeholder="{{'language'|translate}}">
                  <mat-option *ngFor="let language of languages"
                              [value]="language.short">{{ language.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div layout="column" flex="70">
              <mat-form-field>
                <mat-select
                  class="row-select"
                  flex="100"
                  placeholder="{{'select_email_template'|translate|ucFirst}}"
                  formControlName="template" required label="">
                  <mat-option *ngFor="let options of templates index as i" [value]="options">
                    {{ options + '_email_template' |translate|ucFirst }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="textarea-wrapper push-bottom push-top {{(hasError? 'input-warning-text' : '')}}">
            <mat-label>{{ 'email_text'|translate|ucFirst }} *</mat-label>
            <quill-editor class="push-top" matInput
                          cdkTextareaAutosize
                          [modules]="modules"
                          [mention]="items"
                          (keyup)="hasError=false"
                          [mentionConfig]="{triggerChar:'{',maxItems:20,labelKey:'name', 'mentionSelect': formatMention, disableSearch:false}"
                          formControlName="message"
                          rows="8" type="text"></quill-editor>

            <div class="help-text">
              <mat-icon>help</mat-icon>
              {{ 'email_message_helptext'|translate|ucFirst }}
            </div>
          </div>


          <div formGroupName="blocks" *ngIf="false">
            <strong class="strong-header-margin">
              {{ 'select_standard_email_blocks'|translate|ucFirst }}
            </strong>

            <div flex="100" layout="row">
              <div flex="50" layout="column">
                <mat-checkbox
                  formControlName="jobdetails">{{ 'job_details'|translate|ucFirst }}
                </mat-checkbox>
              </div>
              <div flex="50" layout="column">
                <mat-checkbox
                  formControlName="passenger">{{ 'passenger'|translate|ucFirst }}
                </mat-checkbox>
              </div>
            </div>
            <div flex="100" layout="row">
              <div flex="50" layout="column">
                <mat-checkbox
                  formControlName="driverblock">{{ 'moreinformation'|translate|ucFirst }}
                </mat-checkbox>
              </div>
              <div flex="50" layout="column">
                <mat-checkbox
                  formControlName="vehicle">{{ 'bookagain'|translate|ucFirst }}
                </mat-checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mat-divider class="push-top"></mat-divider>
    <mat-card-actions>
      <button (click)="save()" mat-raised-button color="accent" class="td-menu-button">
        {{ 'save' | translate | ucFirst }}
      </button>
      <button (click)="close()" mat-flat-button class="td-menu-button">
        {{ 'cancel' | translate | ucFirst }}
      </button>
      <button (click)="delete()" *ngIf="action==='edit'" mat-flat-button class="td-menu-button no-right-padding">
        {{ 'remove_translation' | translate | ucFirst }}
      </button>
    </mat-card-actions>
  </div>
</div>
