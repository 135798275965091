<div [formGroup]="form" [style.width.%]="100">
  <mat-form-field [style.width.%]="100" flex="100">
    <mat-label>{{'product'|translate|ucFirst}}</mat-label>
    <mat-select (selectionChange)="this.productChanged.emit();"
                class="row-select"
                formControlName="product"
                id="product" placeholder="{{'product'|translate|ucFirst}}" required>
      <mat-option *ngFor="let product of products" [value]="product">{{ product.name | ucFirst}}
        <span *ngIf="product.isFixed"> - {{product.price|localizedCurrency}}</span>
      </mat-option>
      <mat-select-trigger class="pull-top">
        {{ (this.form.controls['product'].value ? this.form.controls['product'].value.name : '-') | ucFirst}}
      </mat-select-trigger>
    </mat-select>
  </mat-form-field>
</div>
