import {AfterViewChecked, ChangeDetectorRef, Component} from '@angular/core';
import {Field} from '../../models/field.interface';
import {FieldConfig} from '../../models/field-config.interface';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-material-button',
  templateUrl: './material-button.component.html',
  styleUrls: ['./material-button.component.scss']
})
export class MaterialButtonComponent implements Field, AfterViewChecked {
  config: FieldConfig;
  group: UntypedFormGroup;

  constructor(private _changeDetector: ChangeDetectorRef) {
  }

  ngAfterViewChecked() {
    this._changeDetector.detectChanges();
  }
}
