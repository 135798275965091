import {Component, OnInit} from '@angular/core';
import {SettingsService} from '../../../../services/pas/settings.service';
import {InvoiceSettings} from '../../../../models/pas/invoice-settings';
import {environment} from '../../../../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Driver} from '../../../../models/driver';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
  providers: [SettingsService]
})
export class SettingsComponent implements OnInit {

  driver: Driver;
  settings: InvoiceSettings = new InvoiceSettings();
  savedMessage: string;

  constructor(
    private _settingsService: SettingsService,
    private _snackbar: MatSnackBar,
    private _translateService: TranslateService,
    private _vault: CoolLocalStorage
  ) {
    _translateService.get(['invoice_settings_saved']).subscribe((translations: any) => {
      this.savedMessage = translations.invoice_settings_saved;
    });
  }

  ngOnInit() {
    this.driver = this._vault.getObject(`${environment.vaultPrefix}.driver`);
    this.loadData();
  }

  loadData(): void {
    this._settingsService.getDriverDetails(this.driver.id).subscribe((settings: InvoiceSettings) => {
      this.settings = settings;
    }, (error) => {
      console.error(error);
    })
  }

  onClickSave(): void {
    this._settingsService.updateDriverDetails(this.driver.id, this.settings).subscribe((result) => {
      this._snackbar.open(this.savedMessage, '', {duration: 3000});
      this.settings = result;
    }, (error) => {
      console.error(error);
    });
  }

}
