import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ContractService} from '../../../services/contract.service';
import {environment} from '../../../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';
import {TdDialogService} from '@covalent/core/dialogs';
import {Company} from '../../../models/company';
import {ContractProduct} from '../../../models/contract-product';
import {TdLoadingService} from '@covalent/core/loading';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {NavigationService} from '../../../services/navigation.service';
import {ContractEditDialogComponent} from './parts/dialog/contract-edit-dialog/contract-edit-dialog.component';
import {ExtendDispatcherDialogComponent} from './parts/dialog/extend-dispatcher-dialog/extend-dispatcher-dialog.component';
import {Contract} from '../../../models/contract';
import {CompanyWebUserService} from '../../../services/companyWebUser.service';
import {CompanyService} from '../../../services/company.service';
import {LicensesComponent} from '../licenses/licenses.component';
import {GlobalFunctions} from '../../../functions/functions';
import moment from 'moment';
import {CancelPurchaseDialogComponent} from './parts/cancel-purchase-dialog/cancel-purchase-dialog.component';

@Component({
  selector: 'app-cloud-connect',
  templateUrl: './cloud-connect.component.html',
  styleUrls: ['./cloud-connect.component.scss']
})
export class CloudConnectComponent implements OnInit {
  context = 'company';
  public activeContract = new Contract();
  translations: any[] = [];
  company: Company;
  isOwner = false;
  companyId: string;
  paymentResult: string;
  sessionId: string;
  cloudConnectMonthly: ContractProduct[];
  cloudConnectUsage: ContractProduct[];
  upcommingInvoice: any;
  billingHistory: any;
  extendedDispatcherSubId: string;

  constructor(
    private route: ActivatedRoute,
    private _contractService: ContractService,
    public loadingService: TdLoadingService,
    private _vault: CoolLocalStorage,
    private _dialogService: TdDialogService,
    private _translateService: TranslateService,
    private _titleService: Title,
    private _navigationService: NavigationService,
    private _companyWebUserService: CompanyWebUserService,
    private _companyService: CompanyService,
    public _globalFunctions: GlobalFunctions
  ) {
    const {company} = this.route.parent.snapshot.data;
    this.company = company;

    this._globalFunctions.loadScript(`https://js.stripe.com/v3/`, this);

    _translateService.get([
      'ok',
      'cloud_connect_title',
      'cloud_connect_convert',
      'cloud_connect_dialog_success',
      'cloud_connect_licensekey_error',
      'cloud_connect_extended_dispatcher_activated',
      'cloud_connect_extended_dispatcher_deactivated',
      'cloud_connect_googlekey_error',
      'cloud_connect_cancel_at_period_end_message',
      'cloud_connect_auto_renew_contract_message',
      'cloud_connect_purchased_title',
      'cloud_connect_purchased_text'
    ]).subscribe((translations: string[]) => {
      this.translations = translations;
      this._titleService.setTitle(translations['cloud_connect_title'] + environment.windowTitleSuffix);
      this._navigationService.setBodyTitle(translations['cloud_connect_title']);

      this.route.params.subscribe(() => {
        if (this.route.snapshot.queryParams.token) {
          this.showNewContract();
        }
      });
    });
    this.context = 'company';
    this.setOwner();
    this.loadData();
  }

  setOwner() {
    const self = this;
    const user: any = this._vault.getObject(`${environment.vaultPrefix}.user`);
    const driver: any = this._vault.getObject(`${environment.vaultPrefix}.driver`);

    if (user.ydaSupportUser) {
      self.isOwner = true;
    } else if (this.company.ownerType === 'WebPortalUser' && self.company.ownerId === user.id) {
      self.isOwner = true;
    } else if (this.company.ownerType === 'CompanyWebUser') {
      self._companyWebUserService.getAll({where: {'companyId': self.company.id, type: 'owner'}})
        .subscribe((users) => {
          if (users.filter((u) => {
            return (u.userId === user.id);
          }).length > 0) {
            self.isOwner = true;
          }
        }, (error) => {
          console.log(error);
        });
    } else if (this.company.ownerType === 'Driver') {
      self._companyService.get(self.company.id, {include: ['Owner']})
        .subscribe((c) => {
          if (c.Owner.phoneNumber === driver.phoneNumber) {
            self.isOwner = true;
          }
        });
    }
  }

  initPayment() {
    if (!this.companyId) {
      throw Error('No companyId provided');
      return false;
    }

    // @ts-ignore
    // tslint:disable-next-line:max-line-length
    const user = this._vault.getObject(`${environment.vaultPrefix}.user`);
    // @ts-ignore
    this._contractService.createPaymentSession({
        'companyId': this.companyId,
        'customer_email': user['email'],
        'client_reference_id': `Cloud connect for company `,
        'locale': 'auto',
        'line_items': [{
          'price': `${environment.stripeCloudConnectId}`,
          'quantity': 1,
          'dynamic_tax_rates': environment.stripeTaxRates
        }
        ],
        'mode': 'subscription',
        'success_url': `${environment.portalUrl}/groups/${this.companyId}/contract`,
        'cancel_url': `${environment.portalUrl}/groups/${this.companyId}/contract/cancel`,
      }
    )
      .subscribe((result) => {
        this.sessionId = result.sessionId;
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        const stripe = Stripe(`${environment.stripeKey}`);
        stripe.redirectToCheckout({sessionId: this.sessionId});
      }, (error) => {
        console.log(`[createPaymentSession.onSubmit]: error`, error);
      })
  }

  loadData(): void {
    const self = this;
    this.loadingService.register('upcommingInvoice');
    this.loadingService.register('billingHistory');
    this.loadingService.register('contract.products');
    this.loadingService.register('contract.cloudConnectUsage');
    if (self.company.ActiveContract && self.company.ActiveContract.reference) {
      self._contractService.get(self.company.ActiveContract.id, {}).subscribe((contract) => {
        self.company.ActiveContract = contract;
        this.activeContract = contract;
        if (contract.type !== 'free-trial') {
          self._contractService.getCurrentUsage(self.company.ActiveContract.id).subscribe((usage) => {
            if (usage.ContractProducts) {
              self.company.ActiveContract.ContractProducts = usage.ContractProducts;
            }

            self.cloudConnectMonthly = self.company.ActiveContract.ContractProducts.filter(item => {
              return (item['usageType'] !== 'metered' || item.sku === 'extended_dispatcher')
            });

            self.cloudConnectUsage = self.company.ActiveContract.ContractProducts.filter(item => {
              return (item['usageType'] === 'metered' && item.sku !== 'extended_dispatcher' &&
                (item.sku !== 'google_api' || !this.company.googleKey || !this.company.googleKey['active']))
            });

            self.cloudConnectUsage = self.cloudConnectUsage.sort(function (a, b) {
              return (a['sku'] > b['sku']) ? 1 : (a['sku'] < b['sku']) ? -1 : 0;
            });
          })

          const extendedDispatcher = self.company.ActiveContract.ContractProducts.filter((item) => {
            return (item.sku === 'extended_dispatcher');
          })[0];

          if (extendedDispatcher) {
            self.extendedDispatcherSubId = extendedDispatcher.subscriptionItemId;
          }

          self._contractService.getUpcommingInvoice(self.company.ActiveContract.id).subscribe((invoice) => {
            self.upcommingInvoice = invoice;
            self.loadingService.resolveAll('upcommingInvoice');
          })

          self._contractService.billingHistory(self.company.ActiveContract.id).subscribe((history) => {
            self.billingHistory = history;
            self.loadingService.resolveAll('billingHistory');
          });
        }

        self.loadingService.resolveAll('contract.products');
        self.loadingService.resolveAll('contract.cloudConnectUsage');
      });
    } else {
      // @ts-ignore
      this.activeContract = new Contract({
        companyId: '',
        created: '',
        currentPeriodEnd: '',
        currentPeriodStart: '',
        foreignId: '',
        id: '',
        licenseKey: '',
        modified: '',
        status: '',
        'reference': 'XXXXXX',
        'startDate': 'xx-xx-xxxx',
        'cancelAtPeriodEnd': false
      });
      self.loadingService.resolveAll('upcommingInvoice');
      self.loadingService.resolveAll('billingHistory');
      self.loadingService.resolveAll('contract.products');
      self.loadingService.resolveAll('contract.cloudConnectUsage');
    }
  }

  ngOnInit(): void {
    this._navigationService.setActionLink(``);

    this.companyId = this.route.parent.snapshot.paramMap.get('id');
  }

  openContractStatus(): void {
    this._dialogService.open(ContractEditDialogComponent, {
      maxHeight: '80%',
      maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
      data: {parent: this},
    });
  }

  openUpgradeContract(): void {
    this._dialogService.open(LicensesComponent, {
      maxHeight: '80%',
      maxWidth: (window.innerWidth < 600 ? '100%' : '750px'),
      data: {
        parent: this,
        company: this.company,
      },
    });
  }

  showNewContract(): void {
    this._dialogService.openAlert({
      title: this.translations['cloud_connect_purchased_title'],
      message: this.translations['cloud_connect_purchased_text'],
      disableClose: true,
      closeButton: this.translations['ok'],
    });
  }

  openExtendedDispatcher(): void {
    this._dialogService.open(ExtendDispatcherDialogComponent, {
      maxHeight: '80%',
      maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
      data: {parent: this},
    });
  }

  setSubscriptionItemId(itemId): void {
    this.extendedDispatcherSubId = itemId;
  }

  openBillingDetails(): void {
    const self = this;
    self._contractService.getCustomerPortalSession(self.activeContract.id, `${environment.portalUrl}/groups/${this.companyId}/contract`).subscribe((history) => {
      window.open(history.url, '_blank')
    });
  }

  cancelPurchase(): void {
    this._dialogService.open(CancelPurchaseDialogComponent, {
      maxHeight: '80%',
      maxWidth: (window.innerWidth < 600 ? '100%' : '600px'),
      data: {parent: this},
    });
  }

  protected readonly moment = moment;
}
