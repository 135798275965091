import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CoolLocalStorage} from '@angular-cool/storage';
import {environment} from '../../../../environments/environment';
import {AuthService} from '../../../services/auth.service';
import {Subscription} from 'rxjs';
import {NavigationService} from '../../../services/navigation.service';
// @ts-ignore
import pjson from '../../../../../package.json';
import {WorkspaceSelectionComponent} from '../../portal/workspace-selection/workspace-selection.component';
import {TdDialogService} from '@covalent/core/dialogs';
import {Company} from '../../../models/company';
import {TdMediaService} from '@covalent/core/media';
import {UtilityService} from '../../../services/utility.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  pjson = require('../../../../../package.json');
  deployDate = require('../../../../../deploy.date.json');
  deployNumber = require('../../../../../deploy.buildnumber.json');
  user: any = {};
  portalName: string;
  currentYear: number;
  portalVersion: string;
  actionLink = '';
  hasActionLink = false;
  company: Company;
  actionLinkSub: Subscription;

  constructor(public media: TdMediaService,
              private _router: Router,
              private _route: ActivatedRoute,
              private _vault: CoolLocalStorage,
              private _navigationService: NavigationService,
              private _changeDetectorRef: ChangeDetectorRef,
              private _dialogService: TdDialogService,
              private _authService: AuthService) {
    this.portalName = environment.portalName;
    const today = new Date();
    this.currentYear = today.getUTCFullYear();
    this.portalVersion = pjson.version;

    this.user = this._vault.getObject(`${environment.vaultPrefix}.user`);

    if (!this.user) {
      console.log(`[MainComponent.constructor]: NO USER`);
      this._router.navigate(['/login']);
    }
  }

  ngOnInit(): void {
    this.actionLinkSub = this._navigationService.getActionLink().subscribe(link => {
      this.hasActionLink = (link !== '');
      this.actionLink = link;
      this._changeDetectorRef.detectChanges();
    });
  }

  switchWorkspace(): void {
    const {company} = this._route.parent.snapshot.data;
    UtilityService.setBrowserTimeStamp(this.company);
    this.company = company;

    this._dialogService.open(WorkspaceSelectionComponent, {
      maxHeight: '80%',
      minWidth: '500px',
      data: {
        openFromDialog: true,
        parent: this
      },
    });
  }

  logout(): void {
    this._authService.logout();
  }

  ngOnDestroy(): void {
    if (this.actionLinkSub) {
      this.actionLinkSub.unsubscribe();
    }
  }
}
