import {Component, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {IntegrationService} from '../../../services/integration.service';
import {environment} from '../../../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';
import {ucFirst} from '../../../pipes/uc-first.pipe';
import {TranslateService} from '@ngx-translate/core';
import {TdDialogService} from '@covalent/core/dialogs';
import {Router} from '@angular/router';
import {Driver} from '../../../models/driver';
import {User} from '../../../models/user';
import googleLib from 'google-libphonenumber';

@Component({
  selector: 'app-company-create',
  templateUrl: './company-create.component.html',
  styleUrls: ['./company-create.component.scss']
})
export class CompanyCreateComponent implements OnInit {
  form: UntypedFormGroup;
  companyName: string;
  companyType: string = 'business';
  translations: string[];

  constructor(
    private _vault: CoolLocalStorage,
    private _integrationService: IntegrationService,
    private _translateService: TranslateService,
    private _dialogService: TdDialogService,
    private _router: Router
  ) {
    _translateService.get([
      'ok',
      'company_created_message',
      'company_created_message_title',
    ]).subscribe((translations: any) => {
      this.translations = translations;
    });

    this.companyName = '';
  }

  ngOnInit(): void {
  }

  createGroup(): void {
    const self = this;
    const user: User = this._vault.getObject(`${environment.vaultPrefix}.user`);
    const driver: Driver = this._vault.getObject(`${environment.vaultPrefix}.driver`);
    let country;
    if (driver && driver['country']) {
      country = driver['country'];
    } else if (user.phoneNumber) {
      // @ts-ignore
      const PNF = googleLib.PhoneNumberFormat;
      // @ts-ignore
      const phoneUtil = googleLib.PhoneNumberUtil.getInstance();
      try {
        const number = phoneUtil.parse(user.phoneNumber);
        country = phoneUtil.getRegionCodeForNumber(number);
        if (country === 'UNKNOWN') {
          country = 'en';
        }
      } catch (err) {
      }
    }

    const integration = {
      'company': {
        'name': self.companyName,
        'type': (self.companyType ? 'business' : 'dispatch'),
        'email': user['email'],
        'country': country,
        'ownerId': user['id'],
        'ownerType': 'WebPortalUser',
      }
    };

    if (driver) {
      const nDriver = JSON.parse(JSON.stringify(driver));
      integration['driver'] = nDriver;
      delete nDriver.id;
    }

    self._integrationService.insert(integration, 'company').subscribe((company) => {
      self._dialogService.openAlert({
        message: ucFirst(self.translations['company_created_message']),
        disableClose: true,
        title: ucFirst(self.translations['company_created_message_title']),
        closeButton: self.translations['ok']
      }).afterClosed().subscribe(() => {
        const allowedCompanies: any = this._vault.getObject(`${environment.vaultPrefix}.allowedCompanies`);
        allowedCompanies.push(company.companyId);
        self._vault.setObject(`${environment.vaultPrefix}.allowedCompanies`, allowedCompanies);
        if (self.companyType) {
          console.log(`/groups/${company.companyId}/`);
          self._router.navigate([`/groups/${company.companyId}/`], {skipLocationChange: false});
        } else {
          self._router.navigate([`/dispatchgroup/${company.companyId}/`], {skipLocationChange: false});
        }
      });
    });
  }
}
