<mat-card *ngIf="daAppInstall"
          [mediaClasses]="['push-sm']"
          tdMediaToggle="gt-xs">
  <ng-template tdLoading="app">
    <mat-card-title class="text-truncate">
      {{ daAppInstall.name | ucFirst }}
    </mat-card-title>
    <mat-card-subtitle>
      {{ 'apps_subtitle' | translate | ucFirst }}
    </mat-card-subtitle>
    <td-message #savedMessage *ngIf="saved" class="pad-sm" color="green" icon="check"
                label="{{'settings_saved'|translate|ucFirst}}"
                sublabel="{{'settings_saved_message'|translate|ucFirst}}">
      <button (click)="savedMessage.close()" mat-icon-button td-message-actions>
        <mat-icon>cancel</mat-icon>
      </button>
    </td-message>
    <mat-divider></mat-divider>

    <mat-tab-group tabindex="0" *ngIf="daAppInstallLoaded">
      <mat-tab label="dispatch-settings" *ngIf="daAppInstallLoaded && daAppInstall.daAppId === this.webbookerDaAppId">
        <ng-template mat-tab-label>
          <mat-icon>settings</mat-icon>
          {{'settings'|translate|ucFirst}}
        </ng-template>

        <app-webbooker-tab *ngIf="daAppInstallLoaded && daAppInstall.daAppId === this.webbookerDaAppId"
                           [booker]="booker" [daAppInstall]="daAppInstall"></app-webbooker-tab>
      </mat-tab>

      <mat-tab label="advanced_settings">
        <ng-template mat-tab-label>
          <mat-icon>settings</mat-icon>
          {{'advanced_settings'|translate|ucFirst}}
        </ng-template>
        <app-settings-tab *ngIf="daAppInstallLoaded" [advancedSettings]="(daAppInstall.daAppId === this.webbookerDaAppId ? 'expanded' : 'expanded')"
                          [daAppInstall]="daAppInstall"
                          [appType]="appType"></app-settings-tab>
      </mat-tab>

      <mat-tab label="calculate">
        <ng-template mat-tab-label>
          <mat-icon>euro_symbol</mat-icon>
          {{'pricing'|translate|ucFirst}}
        </ng-template>
        <app-pricing-tab></app-pricing-tab>
      </mat-tab>

      <mat-tab label="commission">
        <ng-template mat-tab-label>
          <mat-icon>pie_chart</mat-icon>
          {{'commission_rules'|translate|ucFirst}}
        </ng-template>
        <app-commission-tab *ngIf="daAppInstall" [daAppInstall]="daAppInstall"></app-commission-tab>
      </mat-tab>

      <mat-tab *ngIf="daAppInstall.daAppId === passengerDaAppId" label="location-policies">
        <ng-template mat-tab-label>
          <mat-icon>beenhere</mat-icon>
          {{'location_policies'|translate|ucFirst}}
        </ng-template>
        <app-location-policy-tab [companyId]="companyId" [daAppInstallId]="daAppInstall.id"></app-location-policy-tab>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</mat-card>
