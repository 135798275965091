import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {InvoiceFilter} from '../invoice-filter';
import {ActivatedRoute} from '@angular/router';
import {DebtorService} from '../../../../services/debtor.service';
import {Debtor} from '../../../../models/debtor';
import {Company} from '../../../../models/company';

@Component({
  selector: 'app-invoice-filter',
  templateUrl: './invoice-filter.component.html',
  styleUrls: ['./invoice-filter.component.scss']
})
export class InvoiceFilterComponent implements OnInit {
  @Output() filterChanged = new EventEmitter<InvoiceFilter>();
  @Output() searchChanged = new EventEmitter<string>();
  @Input() senders: any[];
  @Input() context: string;
  @Input() type: string;
  @Input() companyId: string;
  @Input() filterData: InvoiceFilter;
  @Output() export = new EventEmitter<boolean>();

  invoiceStatusses = ['all', 'final', 'paid'];
  company: Company;
  currentYear: number;
  yearFilterValues: number[] = [];
  debtors: Debtor[] = [];
  monthFilterValues: string[];

  constructor(
    private _translateService: TranslateService,
    private _debtorService: DebtorService,
    private route: ActivatedRoute,
  ) {
    const {company} = this.route.parent.snapshot.data;
    this.company = company;
    _translateService.get([
      'january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'
    ]).subscribe(translations => {
      this.currentYear = new Date().getFullYear();
      let startYear = 2016;
      if (!this.filterData) {
        this.filterData = {
          year: this.currentYear,
          from: new Date().getFullYear(),
          to: (new Date().getFullYear() + 1),
          status: ['all'],
          paymentMethods: ['invoice'],
          debtor: 'all',
          context: this.context,
        };
      } else {
        this.filterData.year = this.currentYear;
      }

      while (startYear <= this.currentYear) {
        this.yearFilterValues.push(startYear);
        startYear++;
      }

      this.monthFilterValues = [
        translations.january,
        translations.february,
        translations.march,
        translations.april,
        translations.may,
        translations.june,
        translations.july,
        translations.august,
        translations.september,
        translations.october,
        translations.november,
        translations.december
      ];
    });

    this.context = this.route.routeConfig['context'] || 'driver';
  }

  ngOnInit() {
  }

  doExport() {
    this.export.emit(true);
  }

  onYearChanged(event: any): void {
    this.filterData.year = event.value;
    this.filterChanged.emit(this.filterData);
  }

  onStatusChanged(event: any): void {
    this.filterData.status = [event.value];
    console.log('onStatusChanged');
    this.filterChanged.emit(this.filterData);
  }

  onFromChanged(event: any): void {
    this.filterData.from = event.value;
    this.filterChanged.emit(this.filterData);
  }

  onToChanged(event: any): void {
    this.filterData.to = event.value;
    this.filterChanged.emit(this.filterData);
  }

  onPaymentMethodChange(event: any): void {
    this.filterChanged.emit(this.filterData);
  }

  onSearchChanged(searchTerm: string): void {
    this.searchChanged.emit(searchTerm);
  }

  resetFilter(): void {
    console.log('resetFilter');
    this.currentYear = new Date().getFullYear();
    this.filterData = {
      year: new Date().getFullYear(),
      from: 1,
      to: 12,
      status: ['all'],
      paymentMethods: ['invoice'],
      debtor: 'all',
      context: this.context
    };
  }
}
