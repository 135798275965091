import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Passenger} from '../../../../models/passenger';
import {PassengerService} from '../../../../services/passenger.service';
import {ucFirst} from '../../../../pipes/uc-first.pipe';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-debtor-update-passenger',
  templateUrl: './debtor-update-passenger.component.html',
  styleUrls: ['./debtor-update-passenger.component.scss'],
  providers: [PassengerService]
})
export class DebtorUpdatePassengerComponent implements OnInit {
  passengerTypes = [
    'passenger',
    'admin',
    'owner'
  ];
  contact: Passenger;
  translations: string[];

  constructor(
    private _passengerService: PassengerService,
    private _snackBar: MatSnackBar,
    private _translateService: TranslateService,
    public dialogRef: MatDialogRef<DebtorUpdatePassengerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.contact = this.data.contact;
    this._translateService.get(['passenger_debtor_updated', 'ok']).subscribe((translations: string[]) => {
      this.translations = translations;
    });
  }

  ngOnInit(): void {
  }

  save(): void {
    const self = this;
    this._passengerService.updateDebtorPassenger(this.contact.connectionId, {type: this.contact.type})
      .subscribe((result) => {
          self._snackBar.open(ucFirst(self.translations['passenger_debtor_updated']), self.translations['ok'], {
            duration: 3000
          });
          self.data.parent.loadData();
          self.data.parent.loadData();
          self.dialogRef.close();
        },
        (error) => {
          console.log(error);
        });
  }
}
