<mat-card [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <td-search-box (searchDebounce)="search($event)" [alwaysVisible]="true" class="push-left push-right"
                 placeholder="{{'search_here'|translate}}"></td-search-box>
  <mat-divider></mat-divider>
  <ng-template tdLoading="{{ actionName }}">
    <mat-tab-group (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedTabIndex">
      <mat-tab *ngFor="let type of types">
        <ng-template mat-tab-label>
          <mat-icon>euro_symbol</mat-icon>
          {{ type }} ({{ filteredData[type].length }})
        </ng-template>

        <table class="mat-clickable" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="sort($event)"
                *ngFor="let c of columns"
                [active]="(sortBy == c.name)"
                [name]="c.name"
                [numeric]="c.numeric"
                [sortOrder]="( sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
                [sortable]="c.sortable"
                td-data-table-column>
              {{ c.label }}
            </th>
          </tr>
          </thead>

          <tbody>
          <tr (click)="click(item._id)"
              *ngFor="let item of filteredData[type]"
              td-data-table-row>
            <td *ngFor="let c of columns"
                [numeric]="item.numeric"
                td-data-table-cell>
                <span [ngSwitch]="c.name">
                  <span *ngSwitchDefault>
                    <span *ngIf="item[c.name]">
                      {{ item[c.name] }}
                    </span>
                  </span>
                </span>
            </td>
          </tr>
          </tbody>
        </table>

        <td-paging-bar #pagingBar (change)="page($event)" [pageSize]="100" [total]="paging[type].total">
          <span hide-xs td-paging-bar-label>{{ 'rows_p_page'|translate }}:</span>
          {{ pagingBar.range }}
          <span hide-xs>of {{ pagingBar.total }}</span>
        </td-paging-bar>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</mat-card>
