import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CoolLocalStorage} from '@angular-cool/storage';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable()
export class ErrorService {

  constructor(private _http: HttpClient,
              private _vault: CoolLocalStorage) {
  }

  reportError(error: any, note?: string): Observable<object> {
    const user: any = this._vault.getObject(`${environment.vaultPrefix}.user`);
    const data: object = {
      userId: user.id,
      error: error,
      note: note
    };

    return this._http.post<object>(`${environment.apiBaseUrl}/ExternalLogs/`, data, {
      headers: new HttpHeaders().set('Accept', 'application/json').set('Authorization', environment.apiAccessToken)
    });
  }

}
