import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import {DriverService} from '../../../../../../../../../services/driver.service';
import {Offer} from '../../../../../../../../../models/offer';
import {OfferService} from '../../../../../../../../../services/offer.service';
import {CompanyWebUserService} from '../../../../../../../../../services/companyWebUser.service';
import {TranslateService} from '@ngx-translate/core';
import {TdDialogService} from '@covalent/core/dialogs';
import {ShareOfferComponent} from '../share-offer/share-offer.component';
import {ucFirst} from '../../../../../../../../../pipes/uc-first.pipe';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Ride} from '../../../../../../../../../models/ride';
import {ActivatedRoute, Router} from '@angular/router';
import {CompanyWebUser} from '../../../../../../../../../models/company-web-user';

@Component({
  selector: 'app-automatic-dispatch',
  templateUrl: './automatic-dispatch.component.html',
  styleUrls: ['./automatic-dispatch.component.scss']
})
export class AutomaticDispatchComponent implements OnInit {
  @Input() parent: any;
  @Input() ride: Ride;
  @Input() company: any;
  @Output() reloadData = new EventEmitter<string>();
  @Output() stopDispatch = new EventEmitter<string>();
  @Output() closeDialog = new EventEmitter<string>();

  modus = '';
  myCompanies = [];
  selected: any;
  companyWebUser: CompanyWebUser;
  myData: any;
  translations: String[];
  parentData: any;
  context = 'context';

  constructor(
    private clipboard: Clipboard,
    private _route: ActivatedRoute,
    private _driverService: DriverService,
    private _companyWebUserService: CompanyWebUserService,
    private _offerService: OfferService,
    private _dialogService: TdDialogService,
    private _translateService: TranslateService,
    private _snackBar: MatSnackBar,
    private _router: Router
  ) {

    _translateService.get([
      'job_dispatched',
      'edit_pickup_time',
      'ok',
      'driver',
    ]).subscribe((translations: string[]) => {
      this.translations = translations;
    });
  }

  ngOnInit(): void {
    if (this.parent) {
      this.context = this.parent.context;
    } else {
      this.context = this._route.routeConfig['context'] || 'driver';
    }

    this.loadCompanies();

    if (this.company) {
      this.selected = this.company.id;
    }
  }

  loadCompanies(): void {
    const self = this;
    this.myCompanies = [];
    this._companyWebUserService.getCompanies().subscribe((companyWebUser: any[]) => {
      if (companyWebUser.length > 0) {
        companyWebUser.forEach((c) => {
          if (c.Company) {
            self.myCompanies.push(c.Company);
          }
        });
      }
      self.companyWebUser = companyWebUser[0];

      if (self.context === 'driver' && companyWebUser[0]) {
        self.selected = companyWebUser[0].Company.id;
        self.selectGroup(companyWebUser[0].Company.id);
        self.company = {
          id: companyWebUser[0].Company.id,
          name: companyWebUser[0].Company.name
        };
      }
    });
  }

  startShare(): void {
    let windowNavigator: any;
    windowNavigator = window.navigator

    const self = this;
    const offer: any = {
      productId: this.ride.id,
      productType: 'ride',
      companyId: (this.company ? this.company.id : null),
      preAssigned: false,
      type: 'sharedToGroup',
      origin: {
        'type': 'portal',
        'companyWebUserId': self.companyWebUser.id
      }
    };

    self._offerService.insert(offer, this.context).subscribe((o: Offer) => {
      self.reloadData.next();
      self._dialogService.open(ShareOfferComponent, {
        maxHeight: '80%',
        width: '570px',
        data: {
          parent: this,
          shareLink: o.deepLinkUrl
        }
      });
    }, (error) => {
      self.offerError(error);
    });
  }

  shareOfferLink(url): void {
    const self = this;
    self._dialogService.open(ShareOfferComponent, {
      maxHeight: '80%',
      width: '520px',
      data: {
        parent: this,
        shareLink: url
      },
    });
  }

  startAutomaticDispatch(): void {
    const self = this;
    const offer: any = {
      productId: this.ride.id,
      productType: 'ride',
      companyId: (this.company ? this.company.id : null),
      preAssigned: false,
      type: 'company',
      origin: {
        'type': 'portal',
        'companyWebUserId': self.companyWebUser.id
      }
    };
    delete self.ride.LastOffer;
    self._offerService.insert(offer, this.context).subscribe((o: Offer[]) => {
      self.reloadData.next();
    }, (error) => {
      self.offerError(error);
    });
  }

  getSelectedIcon() {
    return (this.company ? 'business' : 'personal');
  }

  getCompanyName() {
    return (this.company ? this.company.name : '');
  }

  selectGroup(event) {
    if (event.value) {
      const selected = this.myCompanies.filter((c) => {
        return (c.id === event.value)
      });
      if (selected.length > 0) {
        this.company = {
          id: selected[0].id,
          name: selected[0].name
        };
      } else {
        this.company = null;
      }
    } else {
      this.company = null;
    }
  }

  setAutoDispatchSelection(option): void {
    this.modus = option;
  }

  reloadDriverData(): void {
    this.reloadData.next();
  }

  _stopDispatch(): void {
    this.stopDispatch.next();
  }

  offerError(error): void {
    const self = this;
    self._translateService.get([
      `dispatch_error_${error.error.error.name.toLowerCase()}`
    ]).subscribe((translation: string) => {
      self._dialogService.openConfirm({
        message: ucFirst(translation[`dispatch_error_${error.error.error.name.toLowerCase()}`]),
        disableClose: true,
        cancelButton: self.translations['ok'],
        acceptButton: self.translations['edit_pickup_time'],
      }).afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          if (self.closeDialog) {
            self.closeDialog.next();
          }
          self._router.navigate([`/groups/${self.company.id}/jobs/${self.ride.id}/edit`]);
        }
      });
    });
  }
}
