<mat-card *ngIf="form" [formGroup]="form">
  <mat-card-title>{{'onboard_availability_title' | translate | ucFirst}}</mat-card-title>
  <mat-card-subtitle>{{'onboard_availability_subtitle' | translate | ucFirst}}</mat-card-subtitle>

  <mat-divider></mat-divider>

  <mat-card-content>
    <strong class="strong-header-margin">{{'available_hours' | translate | ucFirst}}</strong>

    <div *ngIf="!form.controls['allDay'].value" class="main-pane" flex="100">
      <mat-form-field class="left-input" flex="45" flex flex-gt-xs="50">
        <mat-select class="time_select" flex="100" formControlName="availabilityStart"
                    placeholder="{{'from'|translate|ucFirst}}"
                    required>
          <mat-option *ngFor="let value of times"
                      [value]="value">{{ value | translate | ucFirst }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field flex flex="45" flex-gt-xs="50">
        <mat-select class="time_select" flex="100" formControlName="availabilityEnd"
                    placeholder="{{'to'|translate|ucFirst}}"
                    required>
          <mat-option *ngFor="let value of times2"
                      [value]="value">{{ value | translate | ucFirst }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="main-pane all-day" flex="100">
      <mat-checkbox [formControlName]="'allDay'" value="y">
        <span>{{'all_day' | translate | ucFirst}}</span>
      </mat-checkbox>
    </div>

    <div class="main-pane" flex="100" formGroupName="availableDays">
      <strong flex="100">{{'available_days' | translate | ucFirst}}</strong>
      <div class="checkbox-pane push-top push-bottom" flex="100">
        <mat-checkbox *ngFor="let day of days" [formControlName]="day" class="daily_checkbox" value="y">
          <span>{{day + '_short' | translate | ucFirst}}</span>
        </mat-checkbox>
      </div>
    </div>

    <strong flex="100">{{'wizard_minimum_order_time_minutes' | translate | ucFirst}}</strong>
    <div flex flex="100" flex-gt-xs="45" layout="row">
      <mat-form-field class="medium-input number-input" flex="15">
        <input formControlName="minimumOrderTime"
               matInput
               type="number">
      </mat-form-field>

      <mat-form-field class="medium-input minimum-format" flex="25">
        <mat-select class="minimumOrderTime"
                    flex="100"
                    formControlName="minimumOrderTimeFormat"
                    required>
          <mat-option *ngFor="let value of minimumOrderTimeFormat"
                      [value]="value">{{ value | translate | ucFirst }}</mat-option>
        </mat-select>
      </mat-form-field>

      <span class="subtext">{{'wizard_minimum_order_time_minutes_extra'|translate}}</span>


    </div>

    <div class="push-bottom push-top" layout="row">
      {{'availability_subtext'|translate|ucFirst}}
    </div>

    <mat-divider></mat-divider>

    <mat-card-actions class="push-top push-bottom">
      <div class="progress">2/5 {{'completed'|translate|ucFirst}}</div>

      <button (click)="setStep('skipped')" class="link-button"
              mat-button>{{'setup_later' | translate | ucFirst}}</button>

      <button (click)="setStep('calendar')" color="primary"
              mat-raised-button>{{'continue' | translate | ucFirst}}</button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
