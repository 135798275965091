<mat-list-item>
  <mat-icon mat-list-icon>person_pin</mat-icon>
  <h4 mat-line>{{'job_owner'|translate|ucFirst}}</h4>
  <mat-icon matTooltip="{{'job_owner_tooltip'|translate|ucFirst}}">help</mat-icon>
</mat-list-item>

<div [formGroup]="form"
     [mediaClasses]="['push-sm']"
     class="push-top-sm push-bottom-sm" flex="100"
     layout-gt-sm="row"
     tdMediaToggle="gt-xs" tdMediaToggle="gt-xs">
  <mat-list-item>
    <mat-form-field flex="50">
      <mat-label>{{'edit_owner_select'|translate|ucFirst}}</mat-label>
      <mat-select (selectionChange)="onChanged($event)"
                  class="row-select"
                  formControlName="ownerId" id="ownerId" required>
        <mat-option value="{{myDriver.id}}">{{'me'|translate|ucFirst}}</mat-option>
        <mat-option *ngFor="let company of companies" [value]="company.id">{{'business_group'|translate|ucFirst}}:
          {{ company.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-list-item>
</div>
