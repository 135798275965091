import {Component, Input, OnInit} from '@angular/core';
import {DispatchpanelComponent} from '../../dispatchpanel.component';
import {Company} from '../../../../../models/company';
import {TdMediaService} from '@covalent/core/media';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ExporterService} from '../../../../../services/exporter.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CoolLocalStorage} from '@angular-cool/storage';
import {TdLoadingService} from '@covalent/core/loading';
import {TdDataTableService} from '@covalent/core/data-table';
import {RideService} from '../../../../../services/ride.service';
import {TdDialogService} from '@covalent/core/dialogs';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../../../../services/navigation.service';
import {OfferService} from '../../../../../services/offer.service';
import {Title} from '@angular/platform-browser';
import {PaymentOverviewComponent} from '../../../../company/administration/payment-overview/payment-overview.component';

export function pad(number, length) {
  let str = '' + number;
  while (str.length < length) {
    str = '0' + str;
  }
  return str;
};

@Component({
  selector: 'app-dispatchpanel-details',
  templateUrl: './dispatchpanel-details.component.html',
  styleUrls: ['./dispatchpanel-details.component.scss']
})
export class DispatchpanelDetailsComponent implements OnInit {
  @Input() jobDetails;
  @Input() context;
  @Input() company: Company;
  @Input() item;
  @Input() activeTab;
  @Input() companyId;
  @Input() translations;
  @Input() parent: DispatchpanelComponent;
  departureGps: any;
  reference: string;
  rideId: string;
  hasPassenger = true;

  constructor(public media: TdMediaService,
              public snackBar: MatSnackBar,
              private _exporter: ExporterService,
              private _router: Router,
              private _vault: CoolLocalStorage,
              private _loadingService: TdLoadingService,
              private _dataTableService: TdDataTableService,
              private _rideService: RideService,
              private _dialogService: TdDialogService,
              private _translateService: TranslateService,
              private _navigationService: NavigationService,
              private _route: ActivatedRoute,
              private _offerService: OfferService,
              private _titleService: Title) {
  }

  ngOnInit(): void {
    if (this.item.priceMeta && this.item.priceMeta.bookedDuration) {
      this.item.priceMeta.bookedDuration = `${(this.item.priceMeta.bookedDuration / 3600)} ${this.translations['hr']} ${pad((this.item.priceMeta.bookedDuration % 3600), 2)}`;
    }

    this.item.allowDispatch = true;

    if (this.item.paymentMethod === 'creditcardOnline') {
      this._rideService.get(this.item.id, {include: ['Payments', 'PaymentBalance']})
        .subscribe((p) => {
          p.payment = p.Payments[p.Payments.length - 1];
          let details = PaymentOverviewComponent.showPaymentStatus(this.translations, this.company, p.payment, p);
          this.item.paymentStatus = details.paymentStatus;
          this.item.paymentStatusColor = details.paymentStatusColor;
          if (['payment_status_pending', 'payment_status_failed', 'action_required'].includes(details.paymentStatus)) {
            this.item.allowDispatch = 'open_payment_error_message';
          }
        })
    } else if (this.item.paymentMethod === 'inTaxi') {
      if (this.item.status === 'completed') {
        this.item.paymentStatus = 'completed';
        this.item.paymentStatusColor = 'green';
      } else if (this.item.status === 'canceled') {
        this.item.paymentStatus = 'no_payment_required';
        this.item.paymentStatusColor = 'black';
      } else {
        this.item.paymentStatusColor = 'black';
        this.item.paymentStatus = 'payment_status_none';
      }
    }

    if (!this.item.passengerName && !(this.item.passengerPhoneNumber && this.item.passengerPhoneNumber !== null) && !this.item.passengerEmail && !this.item.debtorNumber) {
      this.hasPassenger = false;
    }
  }

  getTooltipText(item) {
    if (item.note) {
      return item.note;
    }
  }
}
