import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import moment from 'moment-timezone';
import {UntypedFormGroup} from '@angular/forms';
import {WebbookerService} from '../../../../../services/webbooker.service';
import {ValidateAllFormFields} from '../../../../../functions/validateAllFormFields';

@Component({
  selector: 'app-ob-webbooker',
  templateUrl: './ob-webbooker.component.html',
  styleUrls: ['./ob-webbooker.component.scss']
})
export class ObWebbookerComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() step: string;
  @Input() currentWebbookerId: string;
  @Output() nextStep = new EventEmitter<string>();
  environment = environment;
  confirmInput: string;
  formErrors: any = {};
  timeZones = moment.tz.names();
  frontEndUrl = environment.orderFormFrontEndUrl;
  protected readonly ValidateAllFormFields = ValidateAllFormFields;

  constructor(
    private _webbookerService: WebbookerService,
  ) {
    const split = this.frontEndUrl.split('https://');
    this.frontEndUrl = split[1];
  }

  ngOnInit(): void {
    // console.log(this.timeZones);
  }

  setStep(step) {
    if (step === 'skipped') {
      this.nextStep.emit(step);
      return;
    }
    /**
     * Check if the domain is still free
     */
    if (!this.form.controls['webbookerUrl'].value) {
      this.formErrors.webbookerUrl = 'webbooker_url_empty';
    } else {
      this._webbookerService.getAll({'where': {'config.customUrl': this.form.controls['webbookerUrl'].value}}).subscribe((webbooker) => {
        if (!webbooker || webbooker.length === 0 || (webbooker.length === 1 && webbooker[0].id === this.currentWebbookerId)) {
          this.nextStep.emit(step);
        } else {
          this.formErrors.webbookerUrl = 'webbooker_url_no_unique';
        }
      });
    }
  }
}
