<!-- Required to make google places search work -->
<div #searchDisplayer></div>

<mat-card [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <ng-template tdLoading="location">

    <mat-card-title *ngIf=form class="text-truncate">
      {{ 'your_service_area' | translate | ucFirst }}
    </mat-card-title>
    <mat-card-subtitle>
      {{ 'your_service_area_subtitle' | translate | ucFirst }}
    </mat-card-subtitle>

    <mat-divider></mat-divider>
    <form *ngIf="form" [formGroup]="form">
      <mat-card-content class="push-bottom-none">
        <mat-checkbox class="row-select"
                      flex="33"
                      value="true"
                      (click)="this.form.markAsTouched();alertCleanup()"
                      [ngModelOptions]="{standalone: true}"
                      [(ngModel)]="serviceAreaLimit">
          {{ 'no_service_area' | translate }}
        </mat-checkbox>

        <mat-label class="label_title push-top" layout="row" flex="100" *ngIf="!hasShape() && serviceAreaLimit">
          {{ 'use_address_as_service_area' | translate | ucFirst }}
        </mat-label>
        <div *ngIf="!hasShape() && serviceAreaLimit" class="flex">
          <!-- Search -->
          <mat-form-field flex="80">
            <input #autocomplete [formControl]="autoComplete"
                   [matAutocomplete]="auto"
                   matInput
                   placeholder="{{ 'search' | translate | ucFirst }}"
                   type="text">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                (onSelectionChange)="import(res)"
                *ngFor="let res of results | async"
                [value]="res.description">
                  <span *ngIf="res.description">
                    {{ res.description }}
                  </span>
                <span *ngIf="res.isShared">
                    <mat-icon>{{ getIcon(res.descriptor) }}</mat-icon>
                  {{ res.name | ucFirst }}
                  </span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field flex="20" class="radius">
            <mat-select class="row-select"
                        flex="100"
                        formControlName="radius"
                        placeholder="{{ 'radius' | translate | ucFirst }}" required>
              <mat-option *ngFor="let type of kmOptions" [value]="type">
                {{ type | ucFirst }} {{ 'km'|distanceFormat:company:false | translate | ucFirst }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Address -->
        <mat-form-field *ngIf="hasShape() && isPoint()" flex
                        style="width: 100%">
          <input [readonly]="true"
                 formControlName="address"
                 matInput
                 placeholder="{{ 'address' | translate | ucFirst }}"
                 style="color: gray"
                 type="text">
        </mat-form-field>
        <!-- Map -->
        <mat-card-content class="map-container {{(!serviceAreaLimit || !hasShape() ? 'hidden-visibility' : '')}}">
          <app-map
            (mapReady)="onMapReady($event)"
            (nrShapes)="onNrShapes($event)"
            [allowDrawingMode]="false"
            [location]="form"
            [style.height]="hasShape() || !isNew() ? '50vh' : 0">
          </app-map>
        </mat-card-content>

        <!-- Actions -->
        <mat-card-actions>
          <button (click)="saveServiceArea()" *ngIf="form"
                  [disabled]="form.pristine"
                  color="accent"
                  flex
                  mat-raised-button>
            {{ 'save'|translate|ucFirst }}
          </button>

          <button (click)="clearLocation()" *ngIf="hasShape() && serviceAreaLimit"
                  flex
                  class="push-right-small"
                  mat-stroked-button>
            {{ 'clear_location'|translate|ucFirst }}
          </button>

          <button (click)="this.importArea(this.currentPlace)" *ngIf="form && !hasShape()"
                  [disabled]="!serviceAreaLimit || !this.currentPlace"
                  color="accent"
                  flex
                  mat-stroked-button>
            {{ 'import_area'|translate|ucFirst }}
          </button>

        </mat-card-actions>
      </mat-card-content>
    </form>
  </ng-template>
</mat-card>
