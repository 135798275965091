<div style="width:700px">
  <mat-card-title class="push-bottom">{{'add_passenger_as_contact_title'|translate|ucFirst}}</mat-card-title>
  <mat-card-subtitle
    class="push-bottom">{{'add_passenger_as_contact_subtitle'|translate|ucFirst}}</mat-card-subtitle>
  <mat-divider></mat-divider>

  <mat-card-actions class="action-pane">
    <button (click)="addPassengerAsContact(this.job, true)"
            class="push-top"
            color="accent"
            flex
            mat-stroked-button>
      {{'create_contact_sent_login_details'|translate|ucFirst}}</button>
    <button (click)="addPassengerAsContact(this.job, false)"
            class="push-top"
            color="accent"
            flex
            mat-stroked-button>
      {{'add_as_contact'|translate|ucFirst}}</button>

    <a (click)="_dialogRef.close()" class="yda-link close-link">
      {{'close'|translate|ucFirst}}</a>
  </mat-card-actions>
</div>
