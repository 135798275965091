import {Component, Input, NgZone, OnInit} from '@angular/core';
import {CanDeactivateGuard} from 'app/guards/can-deactivate-guard';
import {NavigationService} from '../../../../services/navigation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LocationUpsertComponent} from '../locations/parts/location-upsert/location-upsert.component';
import {LocationService} from '../../../../services/tps/location.service';
import {TdLoadingService} from '@covalent/core/loading';
import {TdDialogService} from '@covalent/core/dialogs';
import {LocationPolicyService} from '../../../../services/tps/location-policy.service';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UntypedFormBuilder} from '@angular/forms';
import {DaAppInstallService} from '../../../../services/da-app-install.service';
import {GoogleMapsAPIWrapper, LAZY_MAPS_API_CONFIG, MapsAPILoader} from '@agm/core';
import {CustomLazyAPIKeyLoader} from '../../../../services/CustomLazyAPIKeyLoader';
import {GoogleMapConfig} from '../../../googleMapConfig';
import {Company} from '../../../../models/company';
import {Location, LocationType} from '../../../../models/location';
import {CompanyService} from '../../../../services/company.service';
import {ucFirst} from '../../../../pipes/uc-first.pipe';
import {Observable} from 'rxjs/Observable';
import {GlobalFunctions} from '../../../../functions/functions';

enum Action {
  add,
  details,
}

@Component({
  selector: 'app-service-area',
  templateUrl: './service-area.component.html',
  styleUrls: ['./service-area.component.scss'],
  providers: [LocationService, LocationPolicyService,
    GoogleMapsAPIWrapper,
    {provide: MapsAPILoader, useClass: CustomLazyAPIKeyLoader},
    {provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapConfig}
  ],
})
export class ServiceAreaComponent extends LocationUpsertComponent implements OnInit, CanDeactivateGuard {
  @Input() company: Company;
  updateType = 'serviceArea';
  serviceArea = true;
  serviceAreaId: string;
  type = LocationType.area;
  kmOptions = [10, 25, 50, 75, 100, 150, 200, 250, 300, 500];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private locationService: LocationService,
    private loadingService: TdLoadingService,
    private dialogService: TdDialogService,
    private locationPolicyService: LocationPolicyService,
    private translateService: TranslateService,
    private snackbar: MatSnackBar,
    private fb: UntypedFormBuilder,
    private ngZone: NgZone,
    private navigationService: NavigationService,
    private daAppInstallService: DaAppInstallService,
    private companyService: CompanyService,
  ) {
    super(route, router, locationService, loadingService, dialogService, locationPolicyService, translateService, snackbar, fb, ngZone, navigationService, daAppInstallService, companyService);

    this.navigationService.setActiveSubmenu(this.route.routeConfig['submenu']);
  }

  _ngOnInit() {
    if (!this.company) {
      const {company} = this.route.parent.snapshot.data;
      this.company = company;
    }

    if (this.company.settings.serviceArea) {
      this.serviceAreaLimit = true;
    }
    this.locationId = this.company.settings.serviceAreaId;
    this.action = (this.company.settings.serviceAreaId ? 'edit' : 'add');
  }

  saveServiceArea(): boolean {
    this.mapComponent.persist();
    this.mapComponent.markAsPristine();
    const data = this.form.value;
    delete data.radius;
    if ((!data.area || (data.area && !data.area.coordinates) || (data.area && data.area.coordinates && !data.area.coordinates[0]) || (data.area && data.area.coordinates && data.area.coordinates[0] && data.area.coordinates[0].length < 3) ) && this.serviceAreaLimit) {
      this.dialogService.openAlert({
        title: this.translateService.instant('service_area_not_set_title'),
        message: this.translateService.instant('service_area_not_set_message'),
        disableClose: true,
        closeButton: this.translateService.instant('ok'),
      });
      return false;
    }

    if (Action[this.action] === Action['add']) {
      this.insertServiceArea(data);
    } else {
      this.updateServiceArea(data);
    }
    return true;
  }

  updateServiceArea(data: any) {
    this.startLoader();
    data.serviceArea = this.serviceArea;
    delete data.radius;
    if (!this.serviceAreaLimit) {
      this.company.settings.serviceArea = false;
    }

    this.locationService.update(this.locationId, data)
      .subscribe(result => {
        this.location = result;
        this.snackbar.open(this.UPDATED_MSG, '', {duration: 3000});

        if (this.updateType === 'serviceArea') {
          this.company.settings.serviceArea = true;
        }
        this.companyService.update(this.companyId, {settings: this.company.settings}).subscribe();
        this.stopLoader();
      });
  }

  /**
   * Guard checks whether component can be deactivated.
   */
  canDeactivate(): Observable<boolean> | boolean {
    return GlobalFunctions.canDeactivate(this.dialogService, this.translateService, this.form);
  }

  alertCleanup() {
    if (this.serviceAreaLimit) {
      this.dialogService.openConfirm({
        message: this.translateService.instant('location_confirm_clear'),
        title: this.translateService.instant('location_confirm_clear_title'),
        disableClose: false,
        cancelButton: ucFirst(this.translations['cancel']),
        acceptButton: ucFirst(this.translations['yes']),
      }).afterClosed().subscribe((accept: boolean) => {
        if (accept) {
          this.form.controls.area.setValue({});
          this.autoComplete.setValue(null);
          this.mapComponent.clearGeometry()
          this.form.markAsDirty();
          this.numberOfShapes = 0;
        } else {
          this.serviceAreaLimit = true;
        }
      });
    }
  }

  private insertServiceArea(data: any) {
    this.startLoader();
    data.companyId = this.companyId;
    data.serviceArea = this.serviceArea;
    delete data.radius;
    this.locationService.insert(data)
      .subscribe((result: Location) => {
        this.snackbar.open(this.ADDED_MSG, '', {duration: 3000});
        this.locationId = result._id;
        this.company.settings.serviceAreaId = result._id;
        /**
         * Update company setting
         */
        if (this.serviceAreaLimit) {
          this.company.settings.serviceArea = true;
        } else {
          this.company.settings.serviceArea = false;
        }
        this.companyService.update(this.companyId, {settings: this.company.settings}).subscribe();
        this.stopLoader();
      });
  }
}
