import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {DaAppInstall} from '../../../../../../../../models/da-app-install';
import {DaAppInstallService} from '../../../../../../../../services/da-app-install.service';
import {Company} from '../../../../../../../../models/company';
import {UtilityService} from '../../../../../../../../services/utility.service';
import {ActivatedRoute} from '@angular/router';
import {PricingRuleService} from 'app/services/tps/pricing-rule.service';
import {RuleLink} from '../../../../../../../../models/rule';
import {environment} from '../../../../../../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {DiscountService} from '../../../../../../../../services/tps/discount.service';

@Component({
  selector: 'app-pricerule-connected-daapps',
  templateUrl: './pricerule-connected-daapps.component.html',
  styleUrls: ['./pricerule-connected-daapps.component.scss'],
  providers: [DaAppInstallService, PricingRuleService, DiscountService],
})
export class PriceruleConnectedDaappsComponent implements OnInit, OnChanges {
  @Input() form: UntypedFormGroup;
  @Input() ruleId: string;
  @Input() discountId: string;
  @Input() taxiMeter: string;
  @Input() translations: string[];
  @Output() updateCheckArrayMeter = new EventEmitter<any>();
  @Output() updateCheckArrayFixed = new EventEmitter<any>();
  daAppInstalls: DaAppInstall[] = [];
  company: Company;
  checkArrayMeter = [];
  checkArrayFixed = [];
  loading = true;
  _taxiMeter;

  constructor(
    private _daAppInstallService: DaAppInstallService,
    private _pricingRuleService: PricingRuleService,
    private _discountService: DiscountService,
    private _translationService: TranslateService,
    private _route: ActivatedRoute
  ) {
    const {company} = this._route.parent.snapshot.data;
    UtilityService.setBrowserTimeStamp(company);
    this.company = company;
  }

  ngOnInit(): void {
    const self = this;
    if (this.company) {
      this._daAppInstallService.getAll({'where': {companyId: this.company.id}, 'include': ['DaApp']}, 'company')
        .subscribe((daApps) => {
          /**
           * Sort by type Dispatch, booking
           */
          self.daAppInstalls = daApps.sort((a, b) => {
            if (a.DaApp.type < b.DaApp.type) {
              return -1;
            }
            if (a.DaApp.name > b.DaApp.type) {
              return 1;
            }
            return 0;
          })
          if (this.ruleId || this.discountId) {
            self.daAppInstalls.forEach((d, i) => {
              let sub;
              if (this.ruleId) {
                sub = this._pricingRuleService.getRuleLinks({
                  where: {
                    ruleableType: 'DaAppInstall',
                    ruleableId: d.id,
                    ruleId: this.ruleId
                  },
                  include: {
                    relation: 'rule',
                    scope: {where: {companyId: this.company.id}}
                  }
                });
              } else if (this.discountId) {
                sub = this._discountService.getDiscountLinks({
                  where: {
                    discountableType: 'DaAppInstall',
                    discountableId: d.id,
                    discountId: this.discountId
                  }
                });
              }

              sub.subscribe((data: any) => {
                let idField = 'ruleableId';
                if (this.ruleId) {
                  data = data.filter((mapData) => {
                    return (mapData.rule);
                  });
                } else {
                  idField = 'discountableId'
                }
                if (`${self.daAppInstalls[i].daAppId}` === `${environment.daAppId}`) {
                  self.daAppInstalls[i].settings.name = this.translations['dispach_panel'];
                }

                self.daAppInstalls[i].rules = data;

                self.daAppInstalls[i].rules.forEach((ruleLink: RuleLink) => {
                  if (ruleLink.isAllowedOnMeter) {
                    self.checkArrayMeter.push(ruleLink.ruleableId);
                    self.updateCheckArrayMeter.emit({checkArrayMeter: self.checkArrayMeter, loading: self.loading});
                  }
                  if (ruleLink.isFixed || true) {
                    self.checkArrayFixed.push(ruleLink[idField]);
                    self.updateCheckArrayFixed.emit({checkArrayFixed: self.checkArrayFixed, loading: self.loading});
                  }
                });
                if (!self.daAppInstalls[i + 1]) {
                  self.loading = false;
                  this.form.markAsPristine();
                }
              });
            });
          } else {
            self.loading = false;
          }
        });
    }
  }

  ngOnChanges() {
    if (this.taxiMeter !== this._taxiMeter) {
      if (this.taxiMeter) {
        this.daAppInstalls.forEach((d, i) => {
          this.daAppInstalls[i].rules.forEach((ruleLink: RuleLink) => {
            this.onCheckboxChangeMeter({
              checked: true,
              source: {
                value: ruleLink.ruleableId
              }
            });
          });
        });
      } else {
        this.daAppInstalls.forEach((d, i) => {
          this.daAppInstalls[i].rules.forEach((ruleLink: RuleLink) => {
            this.checkArrayMeter.push(ruleLink.ruleableId);
          });
        });
      }
      this._taxiMeter = this.taxiMeter;
    }
  }

  onCheckboxChangeMeter(e) {
    if (e.checked) {
      this.checkArrayMeter.push(e.source.value);
    } else {
      this.checkArrayMeter.forEach((item: string, i: number) => {
        if (item === e.source.value) {
          delete this.checkArrayMeter[i];
          return;
        }
      });
    }
    if (!this.loading) {
      this.updateCheckArrayMeter.emit({checkArrayMeter: this.checkArrayMeter, loading: this.loading});
    }
  }

  onCheckboxChangeFixed(e) {
    if (e.checked) {
      this.checkArrayFixed.push(e.source.value);
    } else {
      this.checkArrayFixed.forEach((item: string, i: number) => {
        if (item === e.source.value) {
          delete this.checkArrayFixed[i];
          return;
        }
      });
    }
    if (!this.loading) {
      this.updateCheckArrayFixed.emit({checkArrayFixed: this.checkArrayFixed, loading: this.loading});
    }
  }

  getDaAppName(daApp): string {
    const translation = this._translationService.instant(`daapp_name_${daApp.name}`);
    return (translation !== `daapp_name_${daApp.name}` ? translation : daApp.name);
  }
}
