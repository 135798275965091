import {ContractProduct} from './contract-product';

export class Contract {
  'reference': string;
  'hasDispatcherLicense'?: boolean;
  'startDate': string;
  'cancelAtPeriodEnd': boolean;
  'currentPeriodStart': string;
  'currentPeriodEnd': string;
  'foreignId': string;
  'status': string;
  'type': string;
  'foreignStatus': string;
  'licenseKey': string;
  'id': string;
  'created': string;
  'modified': string;
  'companyId': string;
  'taxiIDContract'?: boolean;
  'ContractProducts'?: ContractProduct[]
}
