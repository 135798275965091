import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {TdLoadingService} from '@covalent/core/loading';
import {Observable, Subscription} from 'rxjs';
import {DaAppInstall} from '../../../../../../models/da-app-install';
import {DaAppInstallService} from '../../../../../../services/da-app-install.service';
import {UntypedFormGroup} from '@angular/forms';
import {NavigationService} from '../../../../../../services/navigation.service';
import {environment} from '../../../../../../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';
import {WebbookerService} from '../../../../../../services/webbooker.service';
import {User} from '../../../../../../models/user';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {ucFirst} from '../../../../../../pipes/uc-first.pipe';

@Component({
  selector: 'app-apps-upsert',
  templateUrl: './apps-upsert.component.html',
  styleUrls: ['./apps-upsert.component.scss'],
  providers: [DaAppInstallService]
})
export class AppsUpsertComponent implements OnInit, OnDestroy {
  user: User;
  parentRouteSub: Subscription;
  currentRouteSub: Subscription;
  queryParamSub: Subscription;
  data: Observable<DaAppInstall>;
  daAppInstallId: string;
  companyId: string;
  daAppInstallLoaded = false;
  saved = false;
  translations: string[];
  form: UntypedFormGroup;
  daAppInstall: DaAppInstall = new DaAppInstall();
  booker: any;
  webbookerDaAppId = environment.webbookerDaAppId;
  passengerDaAppId = environment.passengerDaAppId;
  appType: string;

  constructor(
    private _route: ActivatedRoute,
    private _navigationService: NavigationService,
    private _router: Router,
    private _vault: CoolLocalStorage,
    private _daAppInstallService: DaAppInstallService,
    private _webbookerService: WebbookerService,
    private _loadingService: TdLoadingService,
    private _translateService: TranslateService,
    private _titleService: Title
  ) {
    const user: any = this._vault.getObject(`${environment.vaultPrefix}.user`);
    this.user = user;
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);

    this.queryParamSub = this._route.queryParams.subscribe(params => {
      this.saved = (params.saved === 'true');
    });
    this.parentRouteSub = this._route.parent.params.subscribe(first => {
      this.companyId = first['id'];
      this.currentRouteSub = this._route.params.subscribe(second => {
        this.daAppInstallId = second['id'];
        this.data = this._daAppInstallService.get(this.daAppInstallId, {include: 'DaApp'}, 'company');
      });
    });

    _translateService.get(['apps', 'settings']).subscribe((translations: any) => {
      this.translations = translations;
      this._titleService.setTitle(ucFirst(this.translations['settings'] + environment.windowTitleSuffix));
      this._navigationService.setBodyTitle(this.translations['settings']);
    });
  }

  /**
   * On component initialize.
   */
  ngOnInit() {
    this.startLoader();
    this.loadData();
  }

  /**
   * Load data from the api, sorting into different types.
   */
  loadData = () => {
    const self = this;
    this.data.subscribe((install) => {
      install.name = (install.settings && install.settings.name ? install.settings.name : install.DaApp.name);
      this.daAppInstall = install;
      this.daAppInstallLoaded = true;
      this.appType = 'app';
      if (this.daAppInstall.daAppId === this.webbookerDaAppId) {
        this.appType = 'webbooker';
        self.booker = this.daAppInstall;
        if (self.booker.formId) {
          self.booker.JWTtoken = self._vault.getItem(`${environment.vaultPrefix}.${self.booker.formId}.jwtToken`);
          if (!self.booker.JWTtoken) {
            self._daAppInstallService.refreshToken(self.booker.formId)
              .then((token) => {
                self.booker.JWTtoken = token;
                self._vault.setItem(`${environment.vaultPrefix}.${self.booker.formId}.jwtToken`, token);
              });
          }
          self.booker.url = `${environment.orderFormFrontEndUrl}/${self.user.locale.toLowerCase()}/${self.booker.formId}`;
          self._webbookerService.get(self.booker.formId, {}, {'Authorization': `Bearer ${self.booker.JWTtoken}`})
            .subscribe((formDetails) => {
              self.booker.form = formDetails;
              if (formDetails.config) {
                if (formDetails.config.logo) {
                  self.booker.logoUrl = formDetails.config.logo;
                }
              }
              this.stopLoader();
            });
        }
      } else {
        this.stopLoader();
      }
    }, (error) => {
      console.error(`[AppsUpsertComponent.loadData]: error`, error);
    })
  };

  /**
   * Start the spinning loader.
   */
  startLoader = () => this._loadingService.register('app');

  /**
   * Stop the spinning loader.
   */
  stopLoader = () => this._loadingService.resolve('app');

  ngOnDestroy(): void {
    this.currentRouteSub.unsubscribe();
    this.parentRouteSub.unsubscribe();
    this.queryParamSub.unsubscribe();
  }
}
