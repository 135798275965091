<section class="mat-typography">
  <mat-icon (click)="close()" class="dialog-top-right">close</mat-icon>
  <h1>{{ 'generate_draft_invoices' | translate | ucFirst}}</h1>
  <mat-card-subtitle>
    {{ 'generate_draft_invoices_subtext' | translate | ucFirst }}
  </mat-card-subtitle>
</section>

<mat-divider></mat-divider>

<div class="td-dialog-actions push-top row">
  <span class="td-dialog-spacer"></span>

  <div class="push-bottom">
    <mat-checkbox [(ngModel)]="merge" class="push-right"></mat-checkbox>
    {{ 'confirm_merge_invoice' | translate | ucFirst }}
  </div>

  <span class="td-dialog-spacer"></span>
  <button (click)="generateInvoices()" color="accent" mat-raised-button>
    {{ 'generate_invoices' | translate | ucFirst }}
  </button>
</div>
