export class User {
  id: string;
  email: string;
  username: string;
  firstName: string;
  lastName: string;
  type: string;
  password?: string;
  passwordConfirm?: string;
  driver?: Object;
  resetToken?: string;
  lastLogin?: string;
  locale?: string;
  country?: string;
  phoneNumber?: string;
  created?: string;
  hadTrial?: boolean;
  ydaSupportUser?: boolean;
}
