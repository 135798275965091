import {Component, Inject, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RideService} from '../../../../../services/ride.service';
import {ActivatedRoute} from '@angular/router';
import {ExporterService} from '../../../../../services/exporter.service';
import {DriverService} from '../../../../../services/driver.service';
import {Offer} from '../../../../../models/offer';
import {OfferService} from '../../../../../services/offer.service';
import {InvoiceService} from 'app/services/pas/invoice.service';

@Component({
  selector: 'app-multi-action',
  templateUrl: './multi-action.component.html',
  styleUrls: ['./multi-action.component.scss'],
  providers: [
    RideService,
    ExporterService,
    InvoiceService
  ]
})
export class MultiActionComponent implements OnInit {
  items: any[] = [];
  type: string;
  parent: any;
  driverId: string;
  intervalDelay = 250;
  allCompleted: boolean;
  context: string;
  sendByEmail: boolean;
  sendCopy: string;
  action: string;
  actionTitle: string;
  translations: string[];

  constructor(
    private _invoiceService: InvoiceService,
    private _translateService: TranslateService,
    private _route: ActivatedRoute,
    private _dialogRef: MatDialogRef<MultiActionComponent>,
    private _rideService: RideService,
    private _offerService: OfferService,
    private _driverService: DriverService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      if (data.jobs) {
        this.items = data.jobs;
        this.type = 'job';
      } else {
        this.items = data.items;
      }
      this.action = data.action;
      this.sendByEmail = data.sendByEmail;
      this.sendCopy = data.sendCopy;
      this.parent = data.parent;
      this.driverId = data.driverId;
      this.context = data.context;
    }

    _translateService.get(['job_multi_action_assign_title']).subscribe((translations: any) => {
      if (this.action === 'complete') {
        this.actionTitle = 'job_multi_action_complete_title';
        this.updateStatus('completed');
      } else if (this.action === 'cancel') {
        this.actionTitle = 'job_multi_action_cancel_title';
        this.updateStatus('canceled');
      } else if (this.action === 'assign') {
        this._driverService.get(this.driverId).subscribe((driver) => {
          this.actionTitle = translations['job_multi_action_assign_title'].replace('{driver_name}', `${driver.fname} ${driver.lname}`);
          this.assignJobs();
        })
      } else if (this.action === 'unassign') {
        this.actionTitle = 'job_multi_action_unassign_title';
        this.unassignJobs('unassigned');
      } else if (this.action === 'invoice_process') {
        this.actionTitle = 'invoice_update_invoice_process';
        this.intervalDelay = 50;
        this.updateInvoice('final');
      } else if (this.action === 'invoice_unpaid') {
        this.actionTitle = 'invoice_update_unpaid';
        this.intervalDelay = 50;
        this.updateInvoice('final');
      } else if (this.action === 'invoice_create_credit') {
        this.actionTitle = 'invoice_creating_credit';
        this.intervalDelay = 250;
        this.generateCreditInvoices();
      } else if (this.action === 'delete_draft') {
        this.actionTitle = 'invoice_deleting_draft_invoices';
        this.intervalDelay = 250;
        this.deleteDraftInvoices();
      } else if (this.action === 'invoice_paid') {
        this.actionTitle = 'invoice_update_paid';
        this.intervalDelay = 50;
        this.updateInvoice('paid');
      } else if (this.action === 'invoices_send_email') {
        this.actionTitle = 'invoice_invoices_send_email';
        this.intervalDelay = 0;
        this.sendInvoiceEmail();
      } else if (this.action === 'generate_draft') {
        this.actionTitle = 'invoice_generating_drafts';
        this.intervalDelay = 0;
        this.type = 'generate_invoices';
        this.generateDraftInvoices();
      }
    });
  }

  ngOnInit(): void {
  }

  updateStatus(status): void {
    const self = this;
    const length = self.items.length;
    let done = 0;

    self.items.forEach((ride, i) => {
      setTimeout(function () {
        self._rideService.update(ride.id, {'status': status, 'sendByEmail': self.sendByEmail})
          .subscribe(() => {
            self.items[i].updateStatus = 'completed';
            done = done + 1;

            if (done === self.items.length) {
              self.allCompleted = true;
            }

            if (i === self.items.length - 1) {
              self.parent.reloadAllData();
            }
          }, error => {
            console.error(error);
          });
      }, (i * self.intervalDelay));
    });
  }

  unassignJobs(status): void {
    const self = this;
    const length = self.items.length;
    let done = 0;

    self.items.forEach((ride, i) => {
      setTimeout(function () {
        self._rideService.update(ride.id, {'status': status, 'driverId': null, vehicleId: null}, this.context)
          .subscribe(() => {
            self.items[i].updateStatus = 'completed';
            done = done + 1;

            if (done === self.items.length) {
              self.allCompleted = true;
            }

            if (i === self.items.length - 1) {
              self.parent.reloadAllData();
            }
          }, error => {
            console.error(error);
          });
      }, (i * self.intervalDelay));
    });
  }

  closeDialog(): void {
    const self = this;
    self.parent.reloadAllData();

    if (self.action === 'invoice_create_credit' || self.action === 'generate_draft') {
      self.parent.switchTabEmit('draft');
    } else if (self.action === 'invoice_process') {
      self.parent.switchTabEmit('history');
    }

    self._dialogRef.close()
  }

  assignJobs(): void {
    const self = this;
    this.items.forEach((item, i: any) => {
      this._driverService.get(this.driverId, {}).subscribe((driver) => {
        const offer: any = {
          productId: item.id,
          productType: 'ride',
          driverId: this.driverId,
          preAssigned: true,
          status: 'success',
          type: 'driver'
        };
        if (this.context === 'company') {
          offer.companyId = item.ownerId;
        }

        self._offerService.insert(offer, self.parent.context).subscribe((o: Offer[]) => {
          if (i === (this.items.length - 1)) {
            self.parent.reloadAllData();
            self.allCompleted = true;
          }
          self.items[i].updateStatus = 'completed';
        });
      });
    });
  }

  updateInvoice(status): void {
    const self = this;
    const length = self.items.length;
    let done = 0;

    self.items.forEach((invoice, i) => {
      setTimeout(function () {
        self._invoiceService.update(invoice.id, {'status': status, 'sendByEmail': self.sendByEmail, 'sendCopy': self.sendCopy})
          .subscribe(() => {
            self.items[i].updateStatus = 'completed';
            done = done + 1;

            if (done === self.items.length) {
              self.allCompleted = true;
            }

            if (i === self.items.length - 1) {
              self.parent.reloadAllData();
            }
          }, error => {
            console.error(error);
          });
      }, (i * self.intervalDelay));
    });
  }

  sendInvoiceEmail(): void {
    const self = this;
    const length = self.items.length;
    let done = 0;

    self.items.forEach((invoice, i) => {
      setTimeout(function () {
        self._invoiceService.sendEmailInvoices(invoice.id, self.sendCopy)
          .subscribe(() => {
            self.items[i].updateStatus = 'completed';
            done = done + 1;

            if (done === self.items.length) {
              self.allCompleted = true;
            }
            if (i === self.items.length - 1) {
              self.parent.reloadAllData();
            }
          }, error => {
            console.error(error);
          });
      }, (i * self.intervalDelay));
    });
  }

  generateCreditInvoices(): void {
    const self = this;
    const length = self.items.length;
    let done = 0;

    self.items.forEach((invoice, i) => {
      setTimeout(function () {
        self._invoiceService.generateCreditInvoices(invoice.id)
          .subscribe(() => {
            self.items[i].updateStatus = 'completed';
            done = done + 1;

            if (done === self.items.length) {
              self.allCompleted = true;
            }
          }, error => {
            console.error(error);
          });
      }, (i * self.intervalDelay));
    });
  }

  generateDraftInvoices(): void {
    const self = this;
    // const length = self.items.length;
    let done = 0;
    const organizedItems = self.items;
    self.items = [];
    Object.keys(organizedItems).forEach((i, x) => {
      self.items.push(organizedItems[i]);
    });

    Object.keys(organizedItems).forEach((i, x) => {
      const debtor = organizedItems[i];
      self.items[x].updateStatus = 'pending';
      setTimeout(function () {
        const rideIds = debtor.jobs.map((r) => {
          return r.id;
        });
        self._invoiceService.generateDraftInvoices(self.data.invoiceType, rideIds, self.data.groupProcess)
          .subscribe(() => {
            self.items[x].updateStatus = 'completed';
            done = done + 1;

            if (done === self.items.length) {
              self.allCompleted = true;
            }
          }, error => {
            self.items[x].updateStatus = 'error';
            done = done + 1;
            if (done === self.items.length) {
              self.allCompleted = true;
            }
          });
      }, (x * self.intervalDelay));
    });
  }

  deleteDraftInvoices(): void {
    const self = this;
    const length = self.items.length;
    let done = 0;

    self.items.forEach((invoice, i) => {
      setTimeout(function () {
        self._invoiceService.delete(invoice.id, 'company')
          .subscribe(() => {
            self.items[i].updateStatus = 'completed';
            done = done + 1;

            if (done === self.items.length) {
              self.allCompleted = true;
            }

            if (i === self.items.length - 1) {
              self.parent.reloadAllData();
            }
          }, error => {
            console.error(error);
          });
      }, (i * self.intervalDelay));
    });
  }
}
