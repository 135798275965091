<app-no-contract *ngIf="(this.context==='company' && (!this.company.ActiveContract ||
!['active','past_due', null].includes(this.company.ActiveContract.foreignStatus)))"
                 [companyId]="this.companyId" [contract]="this.company.ActiveContract"></app-no-contract>
<mat-card tdMediaToggle="gt-xs">
  <td-search-box (searchDebounce)="search($event)" [alwaysVisible]="true" class="push-left-sm"
                 placeholder="{{'search_here'|translate|ucFirst}}"></td-search-box>
  <mat-divider></mat-divider>
  <ng-template tdLoading="ratings.list">
    <table class="mat-clickable" td-data-table>
      <thead>
      <tr td-data-table-column-row>
        <th (sortChange)="ratingSort($event)"
            *ngFor="let c of columns"
            [active]="(ratingSortBy == c.name)"
            [name]="c.name"
            [numeric]="c.numeric"
            [sortOrder]="(ratingSortOrder == 'ASC' ? 'DESC' : 'ASC' )"
            [sortable]="c.sortable"
            class="{{c.maxSize}} {{c.fixedSize}}"
            td-data-table-column>
          {{c.label|translate|ucFirst}}
        </th>
      </tr>
      </thead>
      <tbody *ngIf="ratingFilteredData.length > 0">
      <tr *ngFor="let item of ratingFilteredData" td-data-table-row>
        <td *ngFor="let c of columns" [numeric]="item.numeric" class="{{c.maxSize}} {{c.fixedSize}}" td-data-table-cell>
          <span [ngSwitch]="c.name">
            <span *ngSwitchCase="'score'">
              <mat-icon *ngIf="item.type === 'Passenger'">person_pin_circle</mat-icon>
              <mat-icon *ngIf="item.type === 'Driver'">headset_mic</mat-icon>
              &nbsp;&nbsp;
              <span *ngFor="let score of [1,2,3,4,5]; let i = index;">
                <mat-icon *ngIf="score <= item[c.name]">star</mat-icon>
                <mat-icon *ngIf="score > item[c.name]">star_border</mat-icon>
              </span>
            </span>

            <span *ngSwitchCase="'comment'"><a class="comment-line"
                                               title="{{item[c.name]}}">{{item.commentShort}}{{(item.comment
            && item.comment.length > commentMaxLength ? '...' : '')}}</a></span>
            <span *ngSwitchCase="'created'"><span
              *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>
            <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span></span>
          </span>
        </td>
      </tr>
      </tbody>
      <tbody *ngIf="ratingFilteredData.length === 0">
      <tr>
        <td [colSpan]="columns.length"
            class="table-placeholder">
          {{'no_ratings_in_overview'|translate|ucFirst}}
        </td>
      </tr>
      </tbody>
    </table>
    <td-paging-bar #ratingPagingBar (change)="ratingPage($event)" [pageSize]="100" [total]="ratingFilteredTotal">
      <span hide-xs>{{'rows_p_page'|translate}}:</span>
      {{ratingPagingBar.range}} <span hide-xs>of {{ratingPagingBar.total}}</span>
    </td-paging-bar>
  </ng-template>
</mat-card>
