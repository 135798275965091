import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Driver} from '../../../../../models/driver';
import {Ride} from '../../../../../models/ride';
import {TdDialogService} from '@covalent/core/dialogs';
import {TranslateService} from '@ngx-translate/core';
import {RideService} from '../../../../../services/ride.service';

@Component({
  selector: 'app-ride-driver-data',
  templateUrl: './ride-driver-data.component.html',
  styleUrls: ['./ride-driver-data.component.scss']
})
export class RideDriverDataComponent implements OnInit {
  @Output() reloadData = new EventEmitter<string>();
  @Input() receiptUrl: string;
  @Input() driver: Driver;
  @Input() ride: Ride;
  @Input() context: string;
  @Input() driverNote: string;
  translations: string[];
  name = '-';

  constructor(
    private _dialogService: TdDialogService,
    private _rideService: RideService,
    private _translateService: TranslateService,
  ) {
    _translateService.get(['return', 'unassign_job', 'confirm_unassign_driver', 'confirm_unassign_driver_title']).subscribe((translations: string[]) => {
      this.translations = translations;
    });
  }

  ngOnInit() {
    if (this.driver) {
      this.name = `${this.driver.fname} ${this.driver.lname}`;
    }
  }

  reloadDriverData() {
    this.reloadData.next();
  }

  unassignDriver(rideId): void {
    const self = this;
    self._dialogService.openConfirm({
      message: self.translations['confirm_unassign_driver'],
      disableClose: false,
      title: self.translations['confirm_unassign_driver_title'],
      cancelButton: self.translations['return'],
      acceptButton: self.translations['unassign_job'],
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        self._rideService.update(rideId, {'status': 'unassigned', 'driverId': null, 'vehicleId': null}, this.context).subscribe(() => {
          self.reloadDriverData();
        }, error => {
          console.error(error);
        })
      }
    });
  }
}
