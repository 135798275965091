<div class="button-pane">
  <button (click)="open(item.id)" color="accent" mat-raised-button>
    <mat-icon class="icon-right">assignment</mat-icon>
    <span>{{'open'|translate|ucFirst}}</span>
  </button>
  <button (click)="openEdit(item.id, 'edit')" *ngIf="!['completed','canceled','canceled_passenger'].includes(item.status)" [routerLink]="'groups/'+item.companyId+'/dispatchpanel/'+item.id+'/edit'"
          color="accent"
          mat-raised-button>
    <mat-icon class="icon-right">edit</mat-icon>
    <span>{{ 'edit' | translate | ucFirst }}</span>
  </button>

  <button (click)="openQuickDispatchDialog(item.id, item.allowDispatch)" *ngIf="!['completed','canceled','canceled_passenger'].includes(item.status) && (!item.LastOffer || ['success', 'expired', 'failed',
         'canceled'].includes(item.LastOffer.status)) && !item['driverId']" [disabled]="!hasContract"
          color="accent" mat-raised-button>
    <mat-icon class="icon-right">send</mat-icon>
    <span>{{(!item['driverId'] ? 'assign_to_driver' : 'reassign_to_driver')|translate|ucFirst}}</span>
  </button>

  <button (click)="unassignDriver(item.id)" *ngIf="!['completed','canceled','canceled_passenger'].includes(item.status) && item['driverId']" [disabled]="!hasContract"
          color="accent"
          mat-raised-button>
    <mat-icon class="icon-right">person</mat-icon>
    <span>{{'unassign_job'|translate|ucFirst}}</span>
  </button>

  <button (click)="openQuickDispatchDialog(item.id, item.allowDispatch)" *ngIf="!['completed','canceled','canceled_passenger'].includes(item.status) && (item.LastOffer && !['success', 'expired', 'failed',
         'canceled'].includes(item.LastOffer.status))" [disabled]="!hasContract"
          color="accent" mat-raised-button>
    <mat-icon class="icon-right">send</mat-icon>
    <span>{{'current_dispatch'|translate|ucFirst}}</span>
  </button>

  <button (click)="openDispatchDialog(item.id)" *ngIf="!['completed','canceled','canceled_passenger'].includes(item.status) && !item.driverId && false" [disabled]="!hasContract"
          color="accent"
          mat-raised-button>
    <mat-icon class="icon-right">send</mat-icon>
    <span>{{'dispatch'|translate|ucFirst}}</span>
  </button>
  <button (click)="openShareDispatchDialog(item.id)" *ngIf="false" [disabled]="!hasContract" color="accent"
          mat-raised-button>
    <mat-icon class="icon-right">send</mat-icon>
    <span>{{ 'automatic_dispatch_start_share' | translate|ucFirst}}</span>
  </button>
  <button (click)="cancelJob(item.id)" *ngIf="!['completed','canceled','canceled_passenger'].includes(item.status)" color="warn"
          mat-raised-button>
    <mat-icon class="icon-right">cancel</mat-icon>
    <span>{{ 'cancel' | translate | ucFirst}}</span>
  </button>
</div>
