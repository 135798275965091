<div *ngIf="['creditcardOnline'].includes(this.form.controls['paymentMeta']['controls']['origin'].value)"
     [formGroup]="form"
     [style.width.%]="100" class="push-bottom-sm value" flex="100" layout-gt-sm="row"
     tdMediaToggle="gt-xs">
  <div
    [style.width.%]="100" flex="100" formGroupName="paymentMeta">
    <mat-form-field [style.width.%]="100" class="form-right" flex="100">
      <mat-label>{{'card_details'|translate|ucFirst}}</mat-label>
      <mat-select (selectionChange)="changeSelection()"
                  class="row-select"
                  formControlName="paymentMethodId">
        <mat-select-trigger class="pull-top">
          <span
            *ngIf="['select','new'].includes(this.form.controls['paymentMeta']['controls']['paymentMethodId'].value)">
            {{'select_passenger_paymentmethod' | translate}}
          </span>
          <span *ngIf="selectedMethod && selectedMethod.type==='card'">
            <mat-icon *ngIf="!['select','new'].includes(this.form.controls['paymentMeta']['controls']['paymentMethodId'].value) &&
                      (selectedMethod && selectedMethod.card &&
                      !['visa','mastercard', 'amex'].includes(selectedMethod.card.brand) || !selectedMethod)"
                      class="push-right">{{
              getSelectedMethodIcon() }}</mat-icon>

            <img *ngIf="(selectedMethod && selectedMethod.card)" src="assets/yda/{{selectedMethod.card.brand}}.svg">

            <span *ngIf="(selectedMethod && selectedMethod.card)" class="star_position">**** **** ****</span>
            <span *ngIf="(selectedMethod && selectedMethod.card)"
                  class="position">{{(selectedMethod && selectedMethod.card ?
              selectedMethod.card.last4 : '')}}</span>

            <span *ngIf="!(selectedMethod && selectedMethod.card)">{{getSelectedMethod()}}</span>
             <span *ngIf="selectedMethod && selectedMethod.type==='invoice'">
                <mat-icon class="debtor-icon">person</mat-icon>
               {{selectedMethod.name}}
              </span>
          </span>
        </mat-select-trigger>
        <mat-option *ngFor="let paymentMethod of paymentMethods" [value]="(paymentMethod.id ? paymentMethod.id:
        paymentMethod.debtorId)">
          <span *ngIf="paymentMethod.type==='invoice'">
            <mat-icon>person</mat-icon>
            {{paymentMethod.name}}
          </span>
          <span *ngIf="paymentMethod.type==='card'">
            <mat-icon *ngIf="(paymentMethod.card && !['visa','mastercard', 'amex'].includes(paymentMethod.card.brand))">
              credit_card</mat-icon>

            <img *ngIf="(paymentMethod.card && paymentMethod.card.brand)"
                 src="assets/yda/{{paymentMethod.card.brand}}.svg">

            <span class="star_position">**** **** ****</span>
            <span class="position">{{(paymentMethod.card ?
              paymentMethod.card.last4 : '')}} {{'expires' | translate}} {{(paymentMethod.card ?
              paymentMethod.card.exp_month + '/' +
              ('' + paymentMethod.card.exp_year + '') : '')}}</span>
          </span>
        </mat-option>
        <mat-option [value]="'new'">{{'select_passenger_create_new' | translate}}</mat-option>
      </mat-select>
      <mat-hint *ngIf="formErrors" align="start" class="warning">
        <span [hidden]="!formErrors.paymentMethodId" class="tc-red-600">
          <span>{{ formErrors.paymentMethodId }}</span>
        </span>
      </mat-hint>
    </mat-form-field>
  </div>
</div>

