<mat-card-title>{{'promotion_send_push_title'|translate|ucFirst}}</mat-card-title>
<mat-card-subtitle>{{'promotion_send_push_subtitle'|translate|ucFirst}}</mat-card-subtitle>
<mat-card-content class="push-dialog-pane">
  <ng-template tdLoading="promotionPushDialog">
    <form *ngIf="form" [formGroup]="form">
      <div flex="100" layout="row">
        <mat-form-field class="" flex>
          <input formControlName="title" matInput placeholder="{{'title'|translate|ucFirst}}" type="text"
                 value="{{data.title}}">
          <mat-hint align="start">
              <span [hidden]="!formErrors.title" class="tc-red-600">
                <span>{{ formErrors.title }}</span>
              </span>
          </mat-hint>
        </mat-form-field>
      </div>

      <div flex="100" layout="row">
        <mat-form-field flex>
            <textarea [maxLength]="140" class="inputTextArea" formControlName="text" matInput ng-trim="false"
                      placeholder="{{'text'|translate|ucFirst}}"
                      rows="4"
                      type="text" value="{{data.text}}"></textarea>
          <div class="chars-left">{{140 - this.form.controls.text.value.length}}
            {{'chars_left'|translate|ucFirst}}</div>
        </mat-form-field>
      </div>

      <div flex="100" layout="row" class="push-top">
        <mat-radio-group formControlName="sendAll" (change)="setPhoneValidators()">
          <mat-radio-button value="phoneNumber">{{'send_to_test_number'|translate|ucFirst}}</mat-radio-button>
          <mat-radio-button value="all">{{'send_to_all_customers'|translate|ucFirst}}</mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="phoneNumberField {{(formErrors.phoneNumber ? 'phoneNumberInvalid' : '')}}" flex *ngIf="this.form.controls.sendAll.value === 'phoneNumber'">
        <ngx-intl-tel-input
          (keyup)="clearError($event, 'phoneNumber')"
          [cssClass]="'phoneNumberInput'"
          [enableAutoCountrySelect]="true"
          [enablePlaceholder]="true"
          [maxLength]="15"
          [phoneValidation]="true"
          [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
          [searchCountryFlag]="true"
          [selectFirstCountry]="false"
          [selectedCountryISO]="this.company.country"
          flex
          formControlName="phoneNumber"
          name="phoneNumber">
        </ngx-intl-tel-input>
        <div class="phonenumber-warning warning">
                <span [hidden]="!formErrors['phoneNumber']" class="tc-red-600">
                  <span>{{ formErrors['phoneNumber'] }}</span>
                </span>
        </div>
      </div>
    </form>
  </ng-template>

  <mat-divider class="push-top"></mat-divider>

  <mat-card-actions>
    <button (click)="sendNotification()"
            [disabled]="!form.valid"
            color="accent"
            flex
            mat-raised-button>{{'send_now'|translate|ucFirst}}</button>

    <button (click)="closeNotification()"
            flex
            mat-raised-button>{{'close'|translate|ucFirst}}</button>
  </mat-card-actions>
</mat-card-content>
