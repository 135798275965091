import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Product} from '../../models/product';
import {AuthService} from '../auth.service';

const productModel = 'products';
const base = environment.tpsBaseUrl;

@Injectable()
export class ProductService {

  constructor(
    private _http: HttpClient,
    private _auth: AuthService,
  ) {
  }

  getAll = (filter?: Object): Observable<Product[]> =>
    this._http.get<Product[]>(
      `${base}/${productModel}?filter=${JSON.stringify(filter)}`,
      {headers: this.getHeaders()},
    );

  get = (id: string, filter?: Object): Observable<Product> =>
    this._http.get<Product>(
      `${base}/${productModel}/${id}?filter=${JSON.stringify(filter)}`,
      {headers: this.getHeaders()},
    );

  insert = (product: Product): Observable<Product> =>
    this._http.post<Product>(
      `${base}/${productModel}`, product,
      {headers: this.getHeaders()},
    );

  update = (id: string, product: {}): Observable<Product> =>
    this._http.patch<Product>(
      `${base}/${productModel}/${id}`, product,
      {headers: this.getHeaders()},
    );

  delete = (id: string): Observable<{ count: boolean }> =>
    this._http.delete<{ count: boolean }>(
      `${base}/${productModel}/${id}`,
      {headers: this.getHeaders()},
    );

  public upload(id: string, object: any) {
    const uploadURL = `${base}/${productModel}/${id}/upload`;
    return this._http.post<any>(uploadURL, object, {headers: this.getHeaders()});
  }

  private getHeaders = () => this._auth.getJWTHeaders();
}
