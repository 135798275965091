<mat-card>
  <mat-tab-group (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedTabIndex" animationDuration="100ms">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="icon-right">access_time</mat-icon>
        {{'open'|translate|ucFirst}} {{originalData['openPayments'].length > 0 ?
        '(' + originalData['openPayments'].length + ')' : ''}}
      </ng-template>
      <div class="push-top">
        <app-driver-rides-filter (export)="export()" (filterChanged)="rideFilterChanged($event)"
                                 [defaultFromDate]="defaultFilterFromDate"
                                 [defaultStatus]="defaultFilterStatus"
                                 [rideStatuses]="rideStatuses"
                                 [showExport]="true"
                                 [showStatusFilter]="false"
                                 [title]="'my_jobs'"
                                 class="push-top"></app-driver-rides-filter>
      </div>
      <table *tdLoading="actionName" class="mat-clickable" td-data-table>
        <thead>
        <tr td-data-table-column-row>
          <th (sortChange)="sort($event)"
              *ngFor="let c of columns"
              [active]="(sortBy == c.name)"
              [name]="c.name"
              [numeric]="c.numeric"
              [sortOrder]="(sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
              [sortable]="c.sortable"
              td-data-table-column>
            {{c.label|translate|ucFirst}}
          </th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let item of filteredData['openPayments'];" class="todo-assign">
          <tr class="pointer {{item.colorClass}}" td-data-table-row>
            <td (click)="(c.name !==
                  'actions' ? open(item.id, $event) : '')" *ngFor="let c of columns" [numeric]="c.numeric"
                class="{{c.maxSize}} {{c.fixedSize}} {{c.maxWidth}} checkbox-td" td-data-table-cell>
                <span [ngSwitch]="c.name">
                 <span *ngSwitchCase="'requestedDate'">
                    <span style="display:none;">{{item['timestamp']}}</span>
                    <span
                      *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span>
                  </span>
                  <span *ngSwitchCase="'paymentDate'">
                    <span
                      *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span>
                  </span>

                  <span *ngSwitchCase="'departure'" class="wrap-line">
                    <span *ngIf="item[c.name]" class="wrap-line"
                          title="{{item.departure}}">{{item.departure}}</span><span
                    *ngIf="!item[c.name]">-</span>
                  </span>

                  <span *ngSwitchCase="'paymentMethod'" class="wrap-line">
                  <span *ngIf="item[c.name]"
                        class="wrap-line"><img *ngIf="item.paymentDescription" src="assets/img/icons/{{item.paymentDescription.toLowerCase()}}_logo.png" title="{{item.paymentDescription}}"/>
                    {{'payment_method_' + item.paymentMethod|translate}}
                  </span><span *ngIf="!item[c.name]">-</span>
                </span>

                  <span *ngSwitchCase="'balance'">
                    <span *ngIf="item[c.name]" [ngClass]="(item[c.name].total == 0 ? 'credit' : (item[c.name].total < 0 ?
                    'debit' : 'credit'))" matTooltip="{{(item[c.name].total < 0 ? 'to_receive' : 'to_refund') |
                    translate | ucFirst}}">
                      {{(item.credit ? '- ' : '')}}{{item[c.name] | localizedCurrency}}
                    </span>
                    <span *ngIf="!item[c.name]">-</span>
                  </span>

                  <span *ngSwitchCase="'originName'" class="wrap-line">
                    <span *ngIf="item[c.name]" class="wrap-line"
                          title="{{item[c.name]}}">{{item[c.name]}}</span><span
                    *ngIf="!item[c.name]">-</span>
                  </span>
                  <span *ngSwitchCase="'destination'" class="wrap-line">
                    <span *ngIf="item[c.name]" title="{{item.destination}}">{{item.destination}}</span>
                    <span *ngIf="!item[c.name]">-</span></span>

                  <span *ngSwitchCase="'paymentStatus'" [matTooltip]="(item.error ? item.error.message: null)"
                        class="{{item.paymentStatusColor}}">
                    <span *ngIf="item[c.name]">{{item[c.name] | translate}}</span><span
                    *ngIf="!item[c.name]">-</span>
                    <mat-icon *ngIf="item.error" class="warning-icon">warning</mat-icon>
                  </span>

                  <span *ngSwitchCase="'status'" class="{{item.rideStatusColor}}">
                    {{'ride_status_' + item.status | translate | ucFirst}}
                </span>

                  <span *ngSwitchCase="'actions'" class="wrap-line">
                    <button [matMenuTriggerFor]="menu" mat-icon-button>
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button (click)="updateDetails(item.id)" *ngIf="!['payment_status_success','payment_status_captured'].includes(item.paymentStatus)"
                              mat-menu-item>
                        <mat-icon class="icon-right">edit</mat-icon>
                        <span>{{ 'update_payment_method' | translate | ucFirst}}</span>
                      </button>
                      <button (click)="authorizePayment(item.id)" *ngIf="['payment_status_none','payment_status_failed'].includes(item.paymentStatus) && item.balance.total < 0"
                              mat-menu-item>
                        <mat-icon class="icon-right">credit_card</mat-icon>
                        <span>{{ 'pre_authorize_amount' | translate | ucFirst}}</span>
                      </button>
                      <button (click)="processPayment(item.id)" *ngIf="!['payment_status_success','payment_status_captured'].includes(item.paymentStatus) && item.balance.total < 0"
                              mat-menu-item>
                        <mat-icon class="icon-right">euro_symbol</mat-icon>
                        <span>{{ 'capture_amount' | translate | ucFirst}}</span>
                      </button>
                      <button (click)="processRefund(item.id)" *ngIf="!['payment_status_success','payment_status_captured'].includes(item.paymentStatus) && item.balance.total > 0"
                              mat-menu-item>
                        <mat-icon class="icon-right">euro_symbol</mat-icon>
                        <span>{{ 'refund_amount' | translate | ucFirst}}</span>
                      </button>
                      <button (click)="closePaymentStatus(item.id)" mat-menu-item>
                        <mat-icon class="icon-right">close</mat-icon>
                        <span>{{ 'close_payment' | translate | ucFirst}}</span>
                      </button>
                    </mat-menu>
                  </span>

                  <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span><span
                    *ngIf="!item[c.name]">-</span></span>
                </span>
            </td>
          </tr>
        </ng-container>
        <tr *ngIf="this.totalOpen.total !== 0 && filteredData['openPayments'].length > 0" class="total-row"
            td-data-table-column-row>
          <td colspan="5"></td>
          <td class="total-title" colspan="1" td-data-table-cell><span>{{(this.totalOpen.total < 0 ?
            'total_to_be_received' : 'total_to_refund') | translate | ucFirst}}</span></td>
          <td class="total-row-value"
              colspan="1" td-data-table-cell>
            <span>{{this.totalOpen | localizedCurrency}}</span>
          </td>
        </tr>
        </tbody>
        <tbody *ngIf="filteredData['openPayments'].length === 0">
        <tr>
          <td [colSpan]="columns.length"
              class="table-placeholder">
            {{'no_jobs_in_overview'|translate|ucFirst}}
          </td>
        </tr>
        </tbody>
      </table>
      <td-paging-bar #openpaging (change)="page($event)" [pageSize]="this.paging['openPayments'].pageSize"
                     [total]="originalData['openPayments'].length">
        <span hide-xs>{{'rows_p_page'|translate}}:</span>
        {{openpaging.range}} <span hide-xs>{{'of'|translate}} {{openpaging.total}}</span>
      </td-paging-bar>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="icon-right">access_time</mat-icon>
        {{'refunded'|translate|ucFirst}}  {{originalData['refunded'].length > 0 ?
        '(' + originalData['refunded'].length + ')' : ''}}
      </ng-template>

      <div class="push-top">
        <app-driver-rides-filter (filterChanged)="rideFilterChanged($event)"
                                 [defaultFromDate]="defaultFilterFromDate"
                                 [defaultStatus]="defaultFilterStatus"
                                 [rideStatuses]="rideStatuses"
                                 [showExport]="false"
                                 [showStatusFilter]="false"
                                 [title]="'refunded'"></app-driver-rides-filter>
      </div>

      <table *tdLoading="actionName+'_3'" class="mat-clickable" td-data-table>
        <thead>
        <tr td-data-table-column-row>
          <th (sortChange)="sort($event)"
              *ngFor="let c of columnsRefunded"
              [active]="(sortBy == c.name)"
              [name]="c.name"
              [numeric]="c.numeric"
              [sortOrder]="(sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
              [sortable]="c.sortable"
              td-data-table-column>
            {{c.label|translate|ucFirst}}
          </th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let item of filteredData['refunded'];" class="todo-assign">
          <tr class="pointer {{item.colorClass}}" td-data-table-row>
            <td (click)="(c.name !==
                'actions' ? open(item.id, $event) : '')" *ngFor="let c of columnsRefunded" [numeric]="c.numeric"
                class="{{c.maxSize}} {{c.fixedSize}} {{c.maxWidth}} checkbox-td" td-data-table-cell>
              <span [ngSwitch]="c.name">
                <span *ngSwitchCase="'requestedDate'">
                  <span style="display:none;">{{item['timestamp']}}</span>
                  <span
                    *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span>
                </span>
                <span *ngSwitchCase="'paymentDate'">
                  <span
                    *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span>
                </span>
                <span *ngSwitchCase="'departure'" class="wrap-line">
                  <span *ngIf="item[c.name]" class="wrap-line"
                        title="{{item.departure}}">{{item.departure}}</span><span
                  *ngIf="!item[c.name]">-</span>
                </span>

                <span *ngSwitchCase="'paymentMethod'" class="wrap-line">
                  <span *ngIf="item[c.name]"
                        class="wrap-line"><img *ngIf="item.paymentDescription" src="assets/img/icons/{{item.paymentDescription.toLowerCase()}}_logo.png" title="{{item.paymentDescription}}"/>
                    {{'payment_method_' + item.paymentMethod|translate}}
                  </span><span *ngIf="!item[c.name]">-</span>
                </span>

                <span *ngSwitchCase="'balance'">
                  <span *ngIf="item[c.name]">
                    {{(item.credit ? '- ' : '')}}{{item[c.name] | localizedCurrency}}
                  </span>
                  <span *ngIf="!item[c.name]">-</span>
                </span>

                <span *ngSwitchCase="'originName'" class="wrap-line">
                  <span *ngIf="item[c.name]" class="wrap-line"
                        title="{{item[c.name]}}">{{item[c.name]}}</span><span
                  *ngIf="!item[c.name]">-</span>
                </span>
                <span *ngSwitchCase="'destination'" class="wrap-line">
                  <span *ngIf="item[c.name]" title="{{item.destination}}">{{item.destination}}</span>
                  <span *ngIf="!item[c.name]">-</span></span>

                 <span *ngSwitchCase="'paymentStatus'" [matTooltip]="(item.error ? item.error.message: null)"
                       class="{{item.paymentStatusColor}}">
                    <span *ngIf="item[c.name]">{{item[c.name] | translate}}</span><span
                   *ngIf="!item[c.name]">-</span>
                  <mat-icon *ngIf="item.error" class="warning-icon">warning</mat-icon>
                  </span>

                <span *ngSwitchCase="'status'" class="{{item.rideStatusColor}}">
                    {{'ride_status_' + item.status | translate | ucFirst}}
                </span>

                <span *ngSwitchCase="'actions'" class="wrap-line">
                  <button *ngIf="item.balance && (item.balance.total < 0 || item.balance.total > 0)" [matMenuTriggerFor]="menu"
                          mat-icon-button>
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button (click)="authorizePayment(item.id)" *ngIf="['payment_status_none','payment_status_failed'].includes(item.paymentStatus) && item.balance.total < 0"
                            mat-menu-item>
                      <mat-icon class="icon-right">credit_card</mat-icon>
                      <span>{{ 'pre_authorize_amount' | translate | ucFirst}}</span>
                    </button>
                    <button (click)="processPayment(item.id)" *ngIf="!['success'].includes(item.status) && item.balance.total < 0"
                            mat-menu-item>
                      <mat-icon class="icon-right">euro_symbol</mat-icon>
                      <span>{{ 'capture_amount' | translate | ucFirst}}</span>
                    </button>
                    <button (click)="processRefund(item.id)" *ngIf="!['success'].includes(item.status) && item.balance.total > 0"
                            mat-menu-item>
                      <mat-icon class="icon-right">euro_symbol</mat-icon>
                      <span>{{ 'refund_amount' | translate | ucFirst}}</span>
                    </button>
                  </mat-menu>
                </span>

                <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span><span
                  *ngIf="!item[c.name]">-</span></span>
              </span>
            </td>
          </tr>
        </ng-container>
        </tbody>
        <tbody *ngIf="originalData['refunded'].length === 0">
        <tr>
          <td [colSpan]="columns.length"
              class="table-placeholder">
            {{'no_jobs_in_overview'|translate|ucFirst}}
          </td>
        </tr>
        </tbody>
      </table>
      <td-paging-bar #refundedPaging (change)="page($event)" [pageSize]="this.paging['refunded'].pageSize"
                     [total]="originalData['refunded'].length">
        <span hide-xs>{{'rows_p_page'|translate}}:</span> {{refundedPaging.range}} <span hide-xs>{{'of'|translate}}
        {{refundedPaging.total}}</span>
      </td-paging-bar>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="icon-right">access_time</mat-icon>
        {{'settled'|translate|ucFirst}}
      </ng-template>

      <div class="push-top">
        <app-driver-rides-filter (filterChanged)="rideFilterChanged($event)"
                                 [defaultFromDate]="defaultFilterFromDate"
                                 [defaultStatus]="defaultFilterStatus"
                                 [rideStatuses]="rideStatuses"
                                 [showExport]="false"
                                 [showStatusFilter]="false"
                                 [title]="'my_jobs'"></app-driver-rides-filter>
      </div>
      <table *tdLoading="actionName+'_2'" class="mat-clickable" td-data-table>
        <thead>
        <tr td-data-table-column-row>
          <th (sortChange)="sort($event)"
              *ngFor="let c of columnsSettled"
              [active]="(sortBy == c.name)"
              [name]="c.name"
              [numeric]="c.numeric"
              [sortOrder]="(sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
              [sortable]="c.sortable"
              td-data-table-column>
            {{c.label|translate|ucFirst}}
          </th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let item of filteredData['closed'];" class="todo-assign">
          <tr class="pointer {{item.colorClass}}" td-data-table-row>
            <td (click)="(c.name !==
                'actions' ? open(item.id, $event) : '')" *ngFor="let c of columnsSettled" [numeric]="c.numeric"
                class="{{c.maxSize}} {{c.fixedSize}} {{c.maxWidth}} checkbox-td" td-data-table-cell>
              <span [ngSwitch]="c.name">
                <span *ngSwitchCase="'requestedDate'">
                  <span style="display:none;">{{item['timestamp']}}</span>
                  <span
                    *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span>
                </span>
                <span *ngSwitchCase="'paymentDate'">
                  <span
                    *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span>
                </span>
                <span *ngSwitchCase="'departure'" class="wrap-line">
                  <span *ngIf="item[c.name]" class="wrap-line"
                        title="{{item.departure}}">{{item.departure}}</span><span
                  *ngIf="!item[c.name]">-</span>
                </span>

                <span *ngSwitchCase="'paymentMethod'" class="wrap-line">
                  <span *ngIf="item[c.name]"
                        class="wrap-line"><img *ngIf="item.paymentDescription" src="assets/img/icons/{{item.paymentDescription.toLowerCase()}}_logo.png" title="{{item.paymentDescription}}"/>
                    {{'payment_method_' + item.paymentMethod|translate}}
                  </span><span *ngIf="!item[c.name]">-</span>
                </span>

                <span *ngSwitchCase="'balance'">
                  <span *ngIf="item[c.name]">
                    {{(item.credit ? '- ' : '')}}{{item[c.name] | localizedCurrency}}
                  </span>
                  <span *ngIf="!item[c.name]">-</span>
                </span>

                <span *ngSwitchCase="'originName'" class="wrap-line">
                  <span *ngIf="item[c.name]" class="wrap-line"
                        title="{{item[c.name]}}">{{item[c.name]}}</span><span
                  *ngIf="!item[c.name]">-</span>
                </span>
                <span *ngSwitchCase="'destination'" class="wrap-line">
                  <span *ngIf="item[c.name]" title="{{item.destination}}">{{item.destination}}</span>
                  <span *ngIf="!item[c.name]">-</span></span>

                 <span *ngSwitchCase="'paymentStatus'" [matTooltip]="(item.error ? item.error.message: null)"
                       class="{{item.paymentStatusColor}}">
                    <span *ngIf="item[c.name]">{{item[c.name] | translate}}</span><span
                   *ngIf="!item[c.name]">-</span>
                  <mat-icon *ngIf="item.error" class="warning-icon">warning</mat-icon>
                  </span>

                <span *ngSwitchCase="'status'" class="{{item.rideStatusColor}}">
                    {{'ride_status_' + item.status | translate | ucFirst}}
                </span>

                <span *ngSwitchCase="'actions'" class="wrap-line">
                  <button *ngIf="item.balance && (item.balance.total < 0 || item.balance.total > 0)" [matMenuTriggerFor]="menu"
                          mat-icon-button>
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button (click)="authorizePayment(item.id)" *ngIf="['payment_status_none','payment_status_failed'].includes(item.paymentStatus) && item.balance && item.balance.total < 0"
                            mat-menu-item>
                      <mat-icon class="icon-right">credit_card</mat-icon>
                      <span>{{ 'pre_authorize_amount' | translate | ucFirst}}</span>
                    </button>
                    <button (click)="processPayment(item.id)" *ngIf="!['success'].includes(item.status) && item.balance && item.balance.total < 0"
                            mat-menu-item>
                      <mat-icon class="icon-right">euro_symbol</mat-icon>
                      <span>{{ 'capture_amount' | translate | ucFirst}}</span>
                    </button>
                    <button (click)="processRefund(item.id)" *ngIf="!['success'].includes(item.status) && item.balance && item.balance.total > 0"
                            mat-menu-item>
                      <mat-icon class="icon-right">euro_symbol</mat-icon>
                      <span>{{ 'refund_amount' | translate | ucFirst}}</span>
                    </button>
                  </mat-menu>
                </span>

                <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span><span
                  *ngIf="!item[c.name]">-</span></span>
              </span>
            </td>
          </tr>
        </ng-container>
        </tbody>
        <tbody *ngIf="filteredData['closed'].length === 0">
        <tr>
          <td [colSpan]="columns.length"
              class="table-placeholder">
            {{'no_jobs_in_overview'|translate|ucFirst}}
          </td>
        </tr>
        </tbody>
      </table>
      <td-paging-bar #closedPaging (change)="page($event)" [pageSize]="this.paging['closed'].pageSize"
                     [total]="filteredData['closed'].length">
        <span hide-xs>{{'rows_p_page'|translate}}:</span>
        {{closedPaging.range}} <span hide-xs>{{'of'|translate}} {{closedPaging.total}}</span>
      </td-paging-bar>
    </mat-tab>
  </mat-tab-group>
</mat-card>
