import {Component, Input, OnInit} from '@angular/core';
import {Ride} from '../../../../../models/ride';
import {Shift} from '../../../../../models/shift';
import {GpsLogEntry} from '../../../../../models/gps-log-entry';
import {GoogleMapsAPIWrapper} from '@agm/core';
import {environment} from '../../../../../../environments/environment';

declare var google: any;

@Component({
  selector: 'app-ride-map',
  templateUrl: './ride-map.component.html',
  styleUrls: ['./ride-map.component.scss']
})
export class RideMapComponent implements OnInit {
  @Input() ride: Ride;
  @Input() shift: Shift;
  @Input() gpsLogEntries: GpsLogEntry[] = [];

  route: any[] = [];
  mapRoute: any;
  bounds: any;
  boundsDefined = false;
  defaultMarkerIconUrl: string;
  departureMarkerIconUrl: string;
  departureMarkerIcon: any;
  destinationMarkerIconUrl: string;
  destinationMarkerIcon: any;
  lineColor: string;

  constructor(private _gMapsApi: GoogleMapsAPIWrapper) {
    this.defaultMarkerIconUrl = `/assets/${environment.buildName}/icon-marker.png`;
    this.departureMarkerIconUrl = `/assets/${environment.buildName}/icon-departure-marker.png`;
    this.destinationMarkerIconUrl = `/assets/${environment.buildName}/icon-destination-marker.png`;
    this.lineColor = `${environment.mapLineColor}`;
  }

  ngOnInit() {
    this._gMapsApi.getNativeMap().then((map) => {
      // console.log('[---RideMapComponent---]');
      // console.log('shift:', this.shift);
      // console.log('gpsLogEntries:', this.gpsLogEntries);
      map.addListener('tilesloaded', () => {
        // console.log('RIDE MAP -> tilesloaded()');
        this.initMap();
      });
    });
  }

  initMap(): void {
    this._gMapsApi.getNativeMap().then((map) => {
      const self = this;
      // console.log('ride:', this.ride);
      // console.log('this.boundsDefined:', this.boundsDefined);
      /**
       * Check if we have a ride and whether we've already defined the bounds or not
       */
      if (this.ride && this.ride.id && !this.boundsDefined) {
        this.bounds = new google.maps.LatLngBounds();

        /**
         * Create the departure marker and add its location to the bounds
         */
        if (this.ride.departure) {
          const departureLocation = new google.maps.LatLng(this.ride.departure.gps.lat, this.ride.departure.gps.lng);
          const departureMarker = new google.maps.Marker({
            position: departureLocation,
            map: map,
            icon: this.departureMarkerIconUrl
          });
          this.bounds.extend(departureLocation);
        }

        /**
         * Create the destination marker and add its location to the bounds
         */
        if (this.ride.destination && this.ride.destination.gps && this.ride.destination.gps.lat) {
          const destinationLocation = new google.maps.LatLng(this.ride.destination.gps.lat, this.ride.destination.gps.lng);
          const destinationMarker = new google.maps.Marker({
            position: destinationLocation,
            map: map,
            icon: this.destinationMarkerIconUrl
          });
          this.bounds.extend(destinationLocation);
        }

        /**
         * Check if we have gps log entries and draw a route on the map using markers and a polyline
         */
        if (this.gpsLogEntries && this.gpsLogEntries.length > 0) {
          this.gpsLogEntries.forEach((entry, index) => {
            const location = new google.maps.LatLng(entry.location.gps.lat, entry.location.gps.lng);
            const marker = new google.maps.Marker({
              position: location,
              map: map,
              icon: this.defaultMarkerIconUrl
            });
            this.route.push(location);
            this.bounds.extend(location);
          });

          this.mapRoute = new google.maps.Polyline({
            path: this.route,
            geodesic: true,
            strokeColor: this.lineColor,
            strokeOpacity: 1.0,
            strokeWeight: 2
          });

          this.mapRoute.setMap(map);
        }

        /**
         * Force the map to fit its new bounds and pan to them so it fits all markers and polylines
         */
        this._gMapsApi.fitBounds(this.bounds)
          .then(() => {
            return this._gMapsApi.panToBounds(this.bounds);
          }).then(() => {
          if (!this.ride.departure || !this.ride.destination) {
            this._gMapsApi.setZoom(15);
          }
          this.boundsDefined = true;
        });
      } else if (!this.boundsDefined) {
        setTimeout(function () {
          self.initMap();
        }, 200);
      }
    });
  }
}
