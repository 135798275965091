<div [mediaClasses]="['push-sm']" class="push-bottom-sm push-top-sm push-left-sm push-right-sm" layout-gt-sm="row"
     tdMediaToggle="gt-xs">
  <div flex-gt-sm="100">
    <mat-card class="details-card">
      <mat-card-title>{{coupon.name |translate|ucFirst}}</mat-card-title>
      <mat-divider></mat-divider>

      <ng-template tdLoading="coupon-details">
        <mat-list>
          <mat-list-item>
            <mat-icon mat-list-icon>description</mat-icon>
            <h4 mat-line>{{'details'|translate|ucFirst}}</h4>
          </mat-list-item>

          <div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" flex="100" layout-gt-sm="row"
               tdMediaToggle="gt-xs">
            <mat-list-item class="value small" flex-gt-sm="50">
              <p mat-line>{{'coupon_code'|translate|ucFirst}}</p>
              <p mat-line>{{coupon.code}}</p>
            </mat-list-item>

            <mat-list-item class="value small" flex-gt-sm="50">
              <p mat-line>{{'coupon_discount_type'|translate|ucFirst}}</p>
              <p mat-line>{{'coupon_type_' + coupon.discountType | translate | ucFirst}}</p>
            </mat-list-item>
            <mat-list-item *ngIf="coupon.discountType === 'percentage'" class="value small" flex-gt-sm="50">
              <p mat-line>{{'radio_coupon_percentage_radio'|translate|ucFirst}}</p>
              <p mat-line>{{coupon.discountPercentage || '-'}}%</p>
            </mat-list-item>

            <mat-list-item *ngIf="coupon.discountType === 'fixed'" class="value small" flex-gt-sm="50">
              <p mat-line>{{'radio_coupon_fixed_radio'|translate|ucFirst}}</p>
              <p mat-line>{{{total: coupon.discountFixedAmount, currency: currency} | localizedCurrency}}</p>
            </mat-list-item>
          </div>

          <mat-divider></mat-divider>

          <mat-list-item>
            <mat-icon mat-list-icon>list</mat-icon>
            <h4 mat-line>{{'coupon_redeem_title'|translate|ucFirst}}</h4>
          </mat-list-item>

          <div *ngIf="coupon.limitByDate" [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" flex="75"
               layout-gt-sm="row" tdMediaToggle="gt-xs">
            <mat-list-item class="value small" flex-gt-sm="50">
              <p mat-line>{{'start_date'|translate|ucFirst}}</p>
              <p
                mat-line>{{coupon.startDate |amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</p>
            </mat-list-item>
            <mat-list-item class="value small" flex-gt-sm="50">
              <p mat-line>{{'end_date'|translate|ucFirst}}</p>
              <p
                mat-line>{{coupon.endDate |amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</p>
            </mat-list-item>
          </div>

          <div *ngIf="coupon.customerEmail" [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" flex="75"
               layout-gt-sm="row" tdMediaToggle="gt-xs">
            <mat-list-item class="value small" flex-gt-sm="50">
              <p mat-line>{{'coupon_redeem_only_by_email'|translate|ucFirst}}</p>
              <p mat-line>{{coupon.customerEmail}}</p>
            </mat-list-item>
          </div>

          <div *ngIf="coupon.limitByUsage || coupon.limitByCustomer || coupon.LimitByBalanceByCustomer"
               [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom" flex="100"
               layout-gt-sm="row" tdMediaToggle="gt-xs">
            <mat-list-item *ngIf="coupon.limitByUsage" class="value small" flex-gt-sm="50">
              <p mat-line>{{'checkbox_usage_count_type'|translate|ucFirst}}</p>
              <p mat-line>{{coupon.usageLimit || '-'}}</p>
            </mat-list-item>
            <mat-list-item *ngIf="coupon.limitByCustomer" class="value small" flex-gt-sm="50">
              <p mat-line>{{'checkbox_coupon_limit_usage_count_per_customer'|translate|ucFirst}}</p>
              <p mat-line>{{coupon.usageLimitPerCustomer || '-'}}</p>
            </mat-list-item>
            <mat-list-item *ngIf="coupon.LimitByBalanceByCustomer" class="value small" flex-gt-sm="50">
              <p mat-line>{{'checkbox_coupon_limit_by_balance_per_customer'|translate|ucFirst}}</p>
              <p mat-line>{{({total: coupon.balanceLimitByCustomer, currency: currency}) | localizedCurrency}}</p>
            </mat-list-item>
          </div>

          <mat-divider></mat-divider>

          <mat-list-item>
            <mat-icon mat-list-icon>list</mat-icon>
            <h4 mat-line>{{'redemptions'|translate|ucFirst}}</h4>
          </mat-list-item>

          <ng-template *ngIf="this.filteredData.length > 0" class="pull-top" tdLoading="redemption.list">
            <table class="mat-clickable" td-data-table>
              <thead>
              <tr td-data-table-column-row>
                <th (sortChange)="sort($event)"
                    *ngFor="let c of columns"
                    [active]="(sortBy == c.name)"
                    [name]="c.name"
                    [numeric]="c.numeric"
                    [sortOrder]="(sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
                    [sortable]="c.sortable"
                    td-data-table-column>
                  {{c.label|translate|ucFirst}}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let item of this.filteredData" class="pointer" td-data-table-row>
                <td *ngFor="let c of columns" [numeric]="item.numeric" td-data-table-cell>
                      <span [ngSwitch]="c.name">
                        <span *ngSwitchCase="'score'">
                          <mat-icon *ngIf="item.type === 'passenger'">local_taxi</mat-icon>
                          <mat-icon *ngIf="item.type === 'operator'">headset_mic</mat-icon>
                          &nbsp;&nbsp;
                          <span *ngFor="let score of [1,2,3,4,5]; let i = index;">
                            <mat-icon *ngIf="score <= item[c.name]">star</mat-icon>
                            <mat-icon *ngIf="score > item[c.name]">star_border</mat-icon>
                          </span>
                        </span>
                        <span *ngSwitchCase="'discount'">
                            <span><span *ngIf="item[c.name] > 0">-</span>
                              {{({total: (item[c.name]), currency: currency}) | localizedCurrency}}</span>
                        </span>
                        <span *ngSwitchCase="'balance'">
                            <span>{{({total: (item[c.name]), currency: currency}) | localizedCurrency}}</span>
                        </span>
                        <span *ngSwitchCase="'requestedDate'"><span *ngIf="item[c.name]">{{item[c.name] |
                          amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>
                        <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span></span>
                      </span>
                </td>
              </tr>
              </tbody>
            </table>
            <td-paging-bar #paging (change)="page($event)" [pageSize]="100" [total]="filteredTotal">
              <span hide-xs>{{'rows_p_page'|translate}}:</span>
              {{paging.range}} <span hide-xs>of {{paging.total}}</span>
            </td-paging-bar>
          </ng-template>
          <ng-template *ngIf="this.filteredData.length === 0" tdLoading="redemption.list">
            <div class="redemption-icon">
              <span>{{'waiting_for_redemptions'|translate|ucFirst}}</span>
            </div>
          </ng-template>
        </mat-list>
      </ng-template>
    </mat-card>
  </div>
</div>
