import {Component, OnDestroy} from '@angular/core';
import {UpsertComponent} from '../../../global/upsert/upsert.component';
import {TranslateService} from '@ngx-translate/core';
import {CompanyService} from '../../../../services/company.service';
import {Company} from '../../../../models/company';
import {ActivatedRoute, Router} from '@angular/router';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import moment from 'moment-timezone';
import {environment} from '../../../../../environments/environment';
import {TdLoadingService} from '@covalent/core/loading';
import {TdDialogService} from '@covalent/core/dialogs';
import {MatSnackBar} from '@angular/material/snack-bar';
// @ts-ignore
import timezones from '../../../../../assets/timezones.json';
import {CountryService} from '../../../../services/country.service';
import {CurrencyService} from '../../../../services/currency.service';
import {ucFirst} from '../../../../pipes/uc-first.pipe';
import {NavigationService} from '../../../../services/navigation.service';
import {CoolLocalStorage} from '@angular-cool/storage';
import {WebbookerService} from '../../../../services/webbooker.service';
import {DaAppInstallService} from '../../../../services/da-app-install.service';
import {DomSanitizer} from '@angular/platform-browser';
import {CloseProfileComponent} from './parts/close-profile/close-profile.component';
import {CompanyWebUser} from '../../../../models/company-web-user';
import {User} from '../../../../models/user';
import {CountryISO, SearchCountryField} from 'ngx-intl-tel-input';
import {GlobalFunctions} from '../../../../functions/functions';
import {ObCalendarSelectComponent} from '../../onboarding-wizard/parts/ob-calendar/parts/ob-calendar-select/ob-calendar-select.component';
import {LanguageService} from '../../../../services/language.service';

@Component({
  selector: 'app-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss']
})
export class GeneralComponent extends UpsertComponent implements OnDestroy {
  formCalendar: UntypedFormGroup
  modelName = 'company';
  action = 'save';
  loaderName = 'company-loader';
  webbookers = [];
  dateFormats = ['DD-MM-YYYY', 'DD/MM/YYYY', 'YYYY-MM-DD', 'YYYY/MM/DD', 'MMM Do YYYY', 'MM/DD/YYYY'];
  clockFormats = ['12', '24'];
  distanceFormats = ['km', 'mi'];
  googleConnected = false;
  connectedCalendar = '';
  connectedGoogleAccount = '';
  SearchCountryField = SearchCountryField;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom
  ];
  data: Company = new Company();
  companyWebUser: CompanyWebUser;
  validationMessages = {
    'name': {
      'fieldName': 'name',
      'required': 'form_validation_required',
    },
    'country': {
      'fieldName': 'title',
      'required': 'form_validation_required',
    },
    'timezone': {
      'fieldName': 'text',
      'required': 'form_validation_required',
    },
    'currency': {
      'fieldName': 'startDate',
      'required': 'form_validation_required',
    },
    'vatNumber': {
      'fieldName': 'text',
      'required': '',
    },
    'phoneNumber': {
      'fieldName': 'phonenumber',
      'required': 'form_validation_required',
      'validatePhoneNumber': 'phonenumber_pattern_invalid',
    },
  };
  countries: any[];
  languages: any[];
  userIsOwner = false;
  user: User;
  stripeOauthConnectUrl = `https://connect.stripe.com/oauth/authorize?response_type=code&client_id=${environment.stripeOauthClientId}&scope=read_write`;
  timezones: string[];
  loadVatTypes = ['ae_trn', 'au_abn', 'au_arn', 'bg_uic', 'br_cnpj', 'br_cpf', 'ca_bn', 'ca_gst_hst', 'ca_pst_bc', 'ca_pst_mb', 'ca_pst_sk', 'ca_qst', 'ch_vat', '' +
  'cl_tin', 'es_cif', 'eu_vat', 'ph_tin', 'gb_vat', 'ge_vat', 'hk_br', 'hu_tin', 'ke_pin', 'id_npwp', 'il_vat', 'in_gst', 'is_vat', 'jp_cn', 'jp_rn', 'kr_brn', 'li_uid', 'mx_rfc', 'my_frp', 'my_itn', 'my_sst', 'no_vat', 'nz_gst', 'ru_inn', 'ru_kpp', 'sa_vat', 'sg_gst', 'sg_uen', 'si_tin', 'th_vat', 'tw_vat', 'eg_tin', 'tr_tin', 'ua_vat', 'us_ein', 'za_vat'];
  vatTypes: any[];
  currencies: any[];
  localTranslation: string[] = [];
  origTranslation: string[] = [];

  constructor(
    private _loadingService: TdLoadingService,
    private _dialogService: TdDialogService,
    private _navigationService: NavigationService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _translateService: TranslateService,
    private _companyService: CompanyService,
    private _formBuilder: UntypedFormBuilder,
    private _route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
    private _countryService: CountryService,
    private _languageService: LanguageService,
    private _vault: CoolLocalStorage,
    private _webbookerService: WebbookerService,
    private _daAppInstallService: DaAppInstallService,
    private _currencyService: CurrencyService,
    public _globalFunctions: GlobalFunctions
  ) {
    super(_loadingService, _dialogService, _navigationService, _router, _snackBar, _companyService, _translateService, _route);
    const self = this;
    const {company} = this._route.parent.snapshot.data;
    this.data = company;

    this.id = company.id;
    this.timezones = timezones;
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);

    const user = JSON.parse(this._vault.getItem(`${environment.vaultPrefix}.user`));
    this.user = user;
    if (user.id === company.ownerId && company.ownerType === 'WebPortalUser') {
      this.userIsOwner = true;
    } else if (company.ownerType === 'CompanyWebUser') {
      _companyService.get(company.id, {include: ['CompanyWebUsers']})
        .subscribe((c) => {
          if (c.CompanyWebUsers) {
            const owner = c.CompanyWebUsers.filter((w) => {
              return (w.type === 'owner' && w.phoneNumber === user.phoneNumber)
            })[0];
            if (owner) {
              this.userIsOwner = true;
            }
          }
        })
    }

    _translateService.get([
      `${this.modelName}_page_title`,
      `${this.modelName}_added_message`,
      `${this.modelName}_updated_message`,
      `ok`,
      'confirm_disconnect_stripe_account',
      'disconnect_stripe_account_confirmed',
      `confirm_disconnect_google_calendar`,
      `company_settings_form_invalid`,
      `disconnect`,
      `settings_create_new_calendar`,
      'onboard_calendar_option_1', 'onboard_calendar_option_2', 'onboard_calendar_option_3'
    ]).subscribe((translations: any) => {
      self.origTranslation = JSON.parse(JSON.stringify(translations));
      self.localTranslation = translations;
      if (self.localTranslation[`${self.modelName}_added_message`]) {
        self.translations[`added_message`] = self.localTranslation[`${self.modelName}_added_message`];
      }
      if (self.localTranslation[`${self.modelName}_updated_message`]) {
        self.translations[`updated_message`] = self.localTranslation[`${self.modelName}_updated_message`];
      }

      if (self.localTranslation[`${self.modelName}_page_title`]) {
        self.translations[`page_title`] = self.localTranslation[`${self.modelName}_page_title`];
      }
      self.__navigationService.setBodyTitle(self.translations[`page_title`]);
    });
    self._loadingService.register(self.loaderName);
    self._loadingService.register('stripe-integration');
    self._countryService.getAll({'order': 'name ASC'}).subscribe((countries) => {
      self.countries = countries;
      self.vatTypes = self.loadVatTypes.map((t) => {
        const split = t.split('_');
        const myCountry = self.countries.filter((c) => {
          return (c.alpha2Code.toLowerCase() === split[0].toLowerCase())
        })[0];
        if (!myCountry && split[0] === 'eu') {
          return {
            country: {
              flag: 'https://flagcdn.com/w320/eu.png',
              name: 'European Union',
              alpha2Code: 'EU',
              value: t
            },
            type: `${split[1]}${(split[2] ? split[2] : '')}`,
            value: t
          };
        } else {
          split.splice(0, 1);
          return {
            country: myCountry,
            type: split.join('/'),
            value: t
          };
        }
      });
      const myCountry = this.countries.filter((c) => {
        return (c.alpha2Code.toLowerCase() === this.data.country.toLowerCase())
      })[0];
      self._currencyService.getAll({'order': 'name ASC'}).subscribe((currencies) => {
        self.currencies = currencies;
        self._languageService.getAll({'order': 'name ASC'}).subscribe((languages) => {
          self.languages = languages;
          if (!self.data.language) {
            const foundLanguage = self.languages.filter((l) => {
              return (l.short.toUpperCase() === myCountry.alpha2Code);
            });
            if (!foundLanguage || foundLanguage.length < 1) {
              self.data.language = 'en';
            } else {
              self.data.language = foundLanguage[0].short;
            }
          }
          this.initForm();
        });
      });
    });
  }

  initForm() {
    const self = this;
    if (this.data.country === 'UNKNOWN') {
      this.data.country = 'GB';
    }

    const myCountry = this.countries.filter((c) => {
      return (c.alpha2Code.toLowerCase() === this.data.country.toLowerCase())
    })[0];
    /**
     * Set up our form
     */
    this.form = this._formBuilder.group({
      'name': [this.data.name, [Validators.required]],
      'website': [this.data.website, []],
      'email': [this.data.email, [Validators.required]],
      'phoneNumber': [(this.data.phoneNumber ? this.data.phoneNumber : ''), []],
      'country': [this.data.country, [Validators.required]],
      'language': [this.data.language, [Validators.required]],
      'timezone': [(this.data.timezone ? this.data.timezone : moment.tz.guess()), [Validators.required]],
      'currency': [this.data.currency, [Validators.required]],
      'vatPercentage': [(this.data.vatPercentage ? this.data.vatPercentage : myCountry.taxLow), [Validators.required]],
      'settlementVatPercentage': [(this.data.settlementVatPercentage ? this.data.settlementVatPercentage : myCountry.taxHigh), [Validators.required]],
      'city': [this.data.city, [Validators.required]],
      'address': [this.data.address, [Validators.required]],
      'addressExtra': [this.data.addressExtra, []],
      'vatType': [(this.data.vatType ? this.data.vatType : myCountry.vatType), []],
      'vatNumber': [this.data.vatNumber, []],
      'settings': this._formBuilder.group({
        'clock': [(this.data && this.data.settings.clock ? this.data.settings.clock : '24'), [Validators.required]],
        'dateFormat': [(this.data.settings.dateFormat ? this.data.settings.dateFormat : 'DD-MM-YYYY'), [Validators.required]],
        'distanceFormat': [(this.data && this.data.settings.distanceFormat ? this.data.settings.distanceFormat : 'km'), [Validators.required]],
      })
    }, {validators: []});

    this.formCalendar = this._formBuilder.group({
      'checkConflicts': [(this.data.settings.calendar && typeof (this.data.settings.calendar.checkConflicts) !== 'undefined' ? this.data.settings.calendar.checkConflicts : false), [Validators.required]],
      'addEvents': [(this.data.settings.calendar && typeof (this.data.settings.calendar.addEvents) !== 'undefined' ? this.data.settings.calendar.addEvents : false), [Validators.required]],
      'googleAuthorization': [(this.data.settings.calendar && typeof (this.data.settings.calendar.authorization) !== 'undefined' ? this.data.settings.calendar.authorization : false), [Validators.required]],
      'primary': [(this.data.settings.calendar && typeof (this.data.settings.calendar.primary) !== 'undefined' ? this.data.settings.calendar.primary : false), [Validators.required]],
    }, {validators: []});

    this.__loadingService.resolve(this.loaderName);
    this.__loadingService.resolve('stripe-integration');

    this._route.params.subscribe(params => {
      if (params['result'] === 'startStripe') {
        self.startStripeIntegration();
      }
    });

    if (this.data && this.data.settings && this.data.settings.calendar && this.data.settings.calendar.authorization && this.data.settings.calendar.authorization.access_token) {
      this.googleConnected = true;
      const primary = this.data.settings.calendar.calendars.filter((c) => {
        return (c.id === this.data.settings.calendar.primary)
      })[0];

      if (primary) {
        this.connectedCalendar = primary.summary;
      }
      if (!this.connectedCalendar) {
        this.openSelectCalendar();
      }

      this.connectedGoogleAccount = this.data.settings.calendar.authorization.email;

      Object.keys(self.localTranslation).forEach((t) => {
        self.localTranslation[t] = self.origTranslation[t].replace('{user_email}', this.connectedCalendar);
      });
    } else {
      this._globalFunctions.initGoogleSignup(this);
    }
  }

  saveCalendar(): void {
    const self = this;
    const data = this.formCalendar.value;
    const saveData = JSON.parse(JSON.stringify(self.data));
    const settings = saveData.settings;
    if (!settings.calendar) {
      settings.calendar = {};
    }
    settings.calendar.checkConflicts = data.checkConflicts;
    settings.calendar.addEvents = data.addEvents;
    settings.calendar.primary = data.primary;
    settings.calendar.authorization = data.googleAuthorization;
    this._service.update(self.id, {settings: settings}).subscribe(() => {
      if (settings.calendar.authorization) {
        self._companyService.synchronizeCalendar(self.id).subscribe((sync) => {
          self._companyService.get(self.id, {'include': ['ActiveContract']}).subscribe((c) => {
            self.data = c;
            if (!self.connectedCalendar) {
              self.connectedGoogleAccount = self.data.settings.calendar.authorization.email;
              self.connectedCalendar = self.data.settings.calendar.authorization.email;
              self.openSelectCalendar();
            } else {
              Object.keys(self.localTranslation).forEach((t) => {
                self.localTranslation[t] = self.origTranslation[t].replace('{user_email}', self.connectedCalendar);
              });
            }
          });
        });
      }

      setTimeout(function () {
        self.__loadingService.resolve(self.loaderName);
        self.__snackBar.open(ucFirst(self.translations['updated_message']), 'OK', {
          duration: 3000
        });
      }, 500);
      self.currentAction = null;
    }, error => {
      if (error.error.code !== 'resource_already_exists') {
        self.currentAction = null;
        self.error = error;
        self.showError(error.error);
      }
      self.__loadingService.resolve(this.loaderName);
    });
  }

  selectCalendar(id) {
    const self = this;
    self.formCalendar.controls['primary'].setValue(id);

    const found = self.data.settings.calendar.calendars.filter((c) => {
      return (c.id === id)
    })[0];

    if (found) {
      self.connectedCalendar = found.summary;
    } else {
      self.connectedCalendar = self.localTranslation['settings_create_new_calendar'];
    }

    Object.keys(self.localTranslation).forEach((t) => {
      self.localTranslation[t] = self.origTranslation[t].replace('{user_email}', this.connectedCalendar);
    });
  }

  openSelectCalendar(): void {
    this._dialogService.open(ObCalendarSelectComponent, {
      maxHeight: '80%',
      maxWidth: '500px',
      minWidth: '500px',
      minHeight: '250px',
      data: {
        openFromDialog: true,
        parent: this,
        calendars: this.data.settings.calendar.calendars,
        calendar: this.data.settings.calendar,
      },
    });
  }

  disconnectGoogleAccount(): void {
    const self = this;
    self._dialogService.openConfirm({
      message: self.localTranslation['confirm_disconnect_google_calendar'],
      disableClose: false,
      cancelButton: self.localTranslation['cancel'],
      acceptButton: self.localTranslation['disconnect'],
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        self._globalFunctions.initGoogleSignup(self);
        self.formCalendar.controls['googleAuthorization'].setValue(null);
        self.data.settings.calendars = null
        self.googleConnected = false;
        self.saveCalendar();
      } else {
        self.form.controls['paymentMeta']['controls']['origin'].setValue('inTaxi');
      }
    });
  }

  googleLogin(resp): void {
    if (environment.location === 'localhost') {
      resp.isLocalhost = true;
    }
    this.formCalendar.controls['googleAuthorization'].setValue(resp);
    this.googleConnected = true;
    this.saveCalendar();
  }

  startStripeIntegration(): void {
    this._vault.setObject(`${environment.vaultPrefix}.stripeConnect`, this.data);
    const redirectUrl = `${environment.portalUrl}/stripeConnect`;
    window.location.href = `${this.stripeOauthConnectUrl}&redirect_uri=${redirectUrl}`;
  }

  startCloseProfile(): void {
    this._dialogService.open(CloseProfileComponent, {
      maxHeight: '80%',
      minWidth: (window.innerWidth < 500 ? '95%' : '500px'),
      disableClose: false,
      data: {
        openFromDialog: true,
        openFromLogin: true,
        parent: this,
        company: this.data
      },
    });
  }

  openStripeDashboard(): void {
    window.open('https://dashboard.stripe.com', '_blank');
  }

  saveCompany(): void {
    const self = this;
    this._loadingService.register();
    if (this.form.value.phoneNumber && this.form.value.phoneNumber.e164Number) {
      this.form.value.phoneNumber = this.form.value.phoneNumber.e164Number;
    }

    /**
     * Remove spaces from vatNumber
     */
    if (this.form.value.vatNumber) {
      this.form.value.vatNumber = this.form.value.vatNumber.replace(/\s/g, '');
    }

    this.save();
    /**
     * Update webbooker config to sync currency setting and timezone
     */
    this.updateWebbookers();

    if (!this.form.valid) {
      self._snackBar.open(ucFirst(self.origTranslation['company_settings_form_invalid']), self.localTranslation['ok'], {
        duration: 3000
      });
    }

    this._loadingService.resolve();
  }

  saveCompleted(result) {
    // super.saveCompleted(result);
    const {company} = this._route.parent.snapshot.data;
    Object.keys(result).forEach((key) => {
      company[key] = result[key];
    });
    /**
     * Save new company in route snapshot that is an observable
     */
    this._route.parent.snapshot.data.company = company;
  }

  updateWebbookers(): void {
    const self = this;
    self._daAppInstallService.getAll({
      where: {
        daAppId: environment.webbookerDaAppId,
        companyId: self.id,
      }
    }).subscribe((bookers) => {
      self.webbookers = bookers;
      self.webbookers = self.webbookers.map((booker) => {
        if (booker.formId) {
          booker.JWTtoken = self._vault.getItem(`${environment.vaultPrefix}.${booker.formId}.jwtToken`);
          if (!booker.JWTtoken) {
            self._daAppInstallService.refreshToken(booker.formId)
              .then((token) => {
                booker.JWTtoken = token;
                self._vault.setItem(`${environment.vaultPrefix}.${booker.formId}.jwtToken`, token);
              });
          }
          self._webbookerService.get(booker.formId, {}, {'Authorization': `Bearer ${booker.JWTtoken}`})
            .subscribe((formDetails) => {
              formDetails.config.currency = self.form.controls['currency'].value;
              formDetails.config.timeZone = self.form.controls['timezone'].value;

              if (!self.data.stripeAccountId) {
                /**
                 * Filter stripe from payment gateways
                 */
                if (formDetails.config.payment && formDetails.config.payment.gateways) {
                  formDetails.config.payment.gateways = formDetails.config.payment.gateways.filter((gateway) => {
                    return gateway.id !== 'STRIPE';
                  });

                  if (formDetails.config.payment.gateways.length === 0) {
                    formDetails.config.payment.gateways.push({
                      'id' : 'CASHTODRIVER',
                      'description' : 'payment_method_field_value_cash',
                      'directProcess' : true,
                    });
                  }
                }
              }

              /**
               * Search for phonenumber field to set the correct defaultCountry
               */
              formDetails.config.parts.forEach((part, i) => {
                part.fields.forEach((field, x) => {
                  if (field.type === 'phone') {
                    formDetails.config.parts[i].fields[x].defaultCountry = self.form.controls['country'].value;
                  }
                  if (field.type === 'address') {
                    if (formDetails.config.parts[i].fields[x].googleComponentRestrictions.length === 1) {
                      formDetails.config.parts[i].fields[x].googleComponentRestrictions = [self.form.controls['country'].value];
                    }
                  }
                });
              });
              booker.form = formDetails;
              return self._webbookerService.update(booker.form.id, booker.form).subscribe((r) => {
              });
            });
        } else {
          self._loadingService.resolve();
        }
        return booker;
      });
    });
  }

  updateType(type: string): void {
    const self = this;
    this._service.update(this.id, {type: type}).subscribe((result) => {
      this.data = result;
      setTimeout(function () {
        self._loadingService.resolve(self.loaderName);
        self._snackBar.open(ucFirst(self.translations['updated_message']), self.localTranslation['ok'], {
          duration: 3000
        });
      }, 500);
    }, error => {
      console.error(error);
      this.__loadingService.resolve(this.loaderName);
    })
  }

  getSelectedVAT(returnValue): string {
    if (this.form.controls['vatType'].value) {
      const split = this.form.controls['vatType'].value.split('_');
      const myCountry = this.countries.filter((c) => {
        return (c.alpha2Code.toLowerCase() === split[0].toLowerCase())
      })[0];

      let selected;
      if (!myCountry && split[0] === 'eu') {
        selected = {
          country: {
            flag: 'https://flagcdn.com/w320/eu.png',
            name: 'European Union',
            alpha2Code: 'EU',
            value: this.form.controls['vatType'].value
          },
          type: split[1],
          value: this.form.controls['vatType'].value
        };
      } else {
        selected = {
          country: myCountry,
          type: split[1],
          value: this.form.controls['vatType'].value
        };
      }

      if (returnValue === 'flag') {
        return (selected.country ? selected.country.flag : '');
      } else {
        return `${(selected.country ? selected.country.alpha2Code : '')} ${selected.type.toUpperCase()} - ${(selected.country ? selected.country.name : '')}`;
      }
    }
  }

  ngOnDestroy() {
    this._navigationService.setSplitLayoutButtons([]);
  }

  disconnectStripeAccount() {
    const self = this;
    self._dialogService.openConfirm({
      message: self.localTranslation['confirm_disconnect_stripe_account'],
      disableClose: false,
      cancelButton: self.localTranslation['cancel'],
      acceptButton: self.localTranslation['disconnect'],
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        const saveData = {
          stripeAccountId: null
        }
        this.data.stripeAccountId = null;
        this._service.update(this.id, saveData).subscribe((result) => {
          /**
           * Update webbooker config to sync currency setting and timezone
           */
          this.updateWebbookers();
          self._snackBar.open(ucFirst(self.origTranslation['disconnect_stripe_account_confirmed']), self.localTranslation['ok'], {
            duration: 3000
          });
        });
      }
    });
    return false;
  }
}
