import {Injectable} from '@angular/core';
import {GlobalService} from '../global.service';
import {HttpClient} from '@angular/common/http';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from '../utility.service';
import {AuthService} from '../auth.service';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReceiptService extends GlobalService {
  constructor(_http: HttpClient,
              _vault: CoolLocalStorage,
              _utilityService: UtilityService,
              private _authService: AuthService) {
    super(_http, _vault, _utilityService);
    this.base = environment.pasBaseUrl;
    this.setModel('receipts');
  }

  public getHeaders(context?: string) {
    return this._authService.getJWTHeaders();
  }
}
