<app-no-contract *ngIf="(this.context==='company' && (!this.company.ActiveContract ||
!['active','past_due', null].includes(this.company.ActiveContract.foreignStatus)))"
                 [companyId]="this.companyId" [contract]="this.company.ActiveContract"></app-no-contract>
<mat-card tdMediaToggle="gt-xs">
  <td-search-box (searchDebounce)="search($event)" [alwaysVisible]="true" class="push-left-sm"
                 placeholder="{{'search_here'|translate|ucFirst}}"></td-search-box>
  <mat-divider></mat-divider>
  <ng-template tdLoading="{{this.actionName}}">
    <mat-tab-group
      (selectedTabChange)="onTabChange($event)">
      <mat-tab *ngFor="let type of types" [label]="type">
        <ng-template mat-tab-label>
          <mat-icon class="icon-right">email</mat-icon>
          {{ type | translate | ucFirst }}
        </ng-template>

        <table class="mat-clickable" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="sort($event)"
                *ngFor="let c of columns"
                [active]="(sortBy == c.name)"
                [name]="c.name"
                [numeric]="c.numeric"
                [sortOrder]="(sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
                [sortable]="c.sortable"
                td-data-table-column>
              {{ c.label|translate|ucFirst }}
            </th>
          </tr>
          </thead>
          <tbody class="redemption-icon" *ngIf="this.filteredData[type] && this.filteredData[type].length > 0">
          <tr (click)="openEdit($event, item.id)" *ngFor="let item of this.filteredData[type]"
              class="pointer" td-data-table-row>
            <td *ngFor="let c of columns" [numeric]="item.numeric" td-data-table-cell>
              <span [ngSwitch]="c.name">

                <span *ngSwitchCase="'startDate'"><span
                  *ngIf="item[c.name]">{{ item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm') }}</span></span>
                <span *ngSwitchCase="'endDate'"><span
                  *ngIf="item[c.name]">{{ item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm') }}</span></span>
                <span *ngSwitchCase="'created'"><span
                  *ngIf="item[c.name]">{{ item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm') }}</span></span>
                <span *ngSwitchCase="'tools'">
                  <button [matMenuTriggerFor]="menu" mat-icon-button>
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button (click)="archive(item.id)" *ngIf="type==='published'" mat-menu-item>
                      <mat-icon>archive</mat-icon>
                      <span>{{ 'archive'|translate|ucFirst }}</span>
                    </button>
                    <button (click)="publish(item.id)" *ngIf="type==='archived'" mat-menu-item>
                      <mat-icon>publish</mat-icon>
                      <span>{{ 'publish'|translate|ucFirst }}</span>
                    </button>
                      <button (click)="delete(item.id)" mat-menu-item>
                      <mat-icon>delete</mat-icon>
                      <span>{{ 'delete'|translate|ucFirst }}</span>
                    </button>
                  </mat-menu>
                </span>

                <span *ngSwitchDefault><span *ngIf="item[c.name]">{{ item[c.name]|ucFirst }}</span></span>
              </span>
            </td>
          </tr>
          </tbody>
          <tbody class="empty" *ngIf="this.filteredData[type] && this.filteredData[type].length === 0">
            <tr>
              <td colspan="10">{{'no_workflow_messages_configured'|translate|ucFirst}}</td>
            </tr>
          </tbody>
        </table>
        <td-paging-bar #paging (change)="page($event)" [pageSize]="100"
                       [total]="(this.filteredData[type] ? this.filteredData[type].length : 0)" *ngIf="this.filteredData[type] && this.filteredData[type].length > 0">
          <span hide-xs>{{ 'rows_p_page'|translate }}:</span>
          {{ paging.range }} <span hide-xs>of {{ paging.total }}</span>
        </td-paging-bar>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="icon-right">settings</mat-icon>
          {{ 'settings' | translate | ucFirst }}
        </ng-template>

        <form *ngIf="settingsForm" [formGroup]="settingsForm">
          <mat-card-content class="push-bottom-none">
            <h4>{{'email_settings'|translate|ucFirst}}</h4>
            <div class="row">
              <div class="inline-flex-pane first-match-condition">
                <mat-checkbox
                  class="row-select"
                  flex="100"
                  value="true"
                  formControlName="sentDefaultDriverAssigned">
                  {{ 'default_driver_assignment_email' | translate | ucFirst }}
                </mat-checkbox>

                <button class="row-button button-dynamic"
                        mat-icon-button
                        matTooltip="{{ 'disable_default_driver_assignment_email_helptext'|translate|ucFirst }}"
                        matTooltipPosition="right">
                  <mat-icon aria-label="{{ 'disable_default_driver_assignment_email_helptext'|translate|ucFirst }}">help</mat-icon>
                </button>
              </div>

              <div class="inline-flex-pane first-match-condition">
                <mat-checkbox
                  class="row-select"
                  flex="100"
                  value="true"
                  formControlName="sentDefaultConfirmation">
                  {{ 'disable_default_conformation_email' | translate | ucFirst }}
                </mat-checkbox>

                <button class="row-button button-dynamic"
                        mat-icon-button
                        matTooltip="{{ 'disable_default_conformation_email_helptext'|translate|ucFirst }}"
                        matTooltipPosition="right">
                  <mat-icon aria-label="{{ 'disable_default_conformation_email_helptext'|translate|ucFirst }}">help</mat-icon>
                </button>
              </div>

              <div class="inline-flex-pane first-match-condition">
                <mat-checkbox
                  class="row-select"
                  flex="100"
                  value="true"
                  formControlName="sentDefaultCancelation">
                  {{ 'disable_default_cancelation_email' | translate | ucFirst }}
                </mat-checkbox>

                <button class="row-button button-dynamic"
                        mat-icon-button
                        matTooltip="{{ 'disable_default_cancelation_email_helptext'|translate|ucFirst }}"
                        matTooltipPosition="right">
                  <mat-icon aria-label="{{ 'disable_default_cancelation_email_helptext'|translate|ucFirst }}">help</mat-icon>
                </button>
              </div>

              <div class="inline-flex-pane first-match-condition">
                <mat-checkbox
                  class="row-select"
                  flex="100"
                  value="true"
                  formControlName="sentDefaultCompletion">
                  {{ 'disable_default_completion_email' | translate | ucFirst }}
                </mat-checkbox>

                <button class="row-button button-dynamic"
                        mat-icon-button
                        matTooltip="{{ 'disable_default_completion_email_helptext'|translate|ucFirst }}"
                        matTooltipPosition="right">
                  <mat-icon aria-label="{{ 'disable_default_completion_email_helptext'|translate|ucFirst }}">help</mat-icon>
                </button>
              </div>
            </div>

            <h4>{{'sms_settings'|translate|ucFirst}}</h4>
            <div class="row push-top">
              <mat-form-field flex>
                <input formControlName="smsSender" matInput
                       placeholder="{{'sms_sender'|translate|ucFirst}}" type="text" maxlength="10">
              </mat-form-field>

              <button class="row-button button-dynamic"
                      mat-icon-button
                      matTooltip="{{ 'sms_sender_helptext'|translate|ucFirst }}"
                      matTooltipPosition="right">
                <mat-icon aria-label="{{ 'sms_sender_helptext'|translate|ucFirst }}">help</mat-icon>
              </button>
            </div>

            <div class="row push-top">
              <button (click)="saveSettings()"
                      color="accent"
                      flex
                      mat-raised-button>{{ 'save'|translate|ucFirst }}
              </button>
            </div>
          </mat-card-content>
        </form>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</mat-card>
