<div class="dispatch-pane">
  <mat-card-subtitle
    class="push-bottom push-top">{{'forward_job_to_connected_business'|translate|ucFirst}}</mat-card-subtitle>
  <div class="table">
    <div flex="100" layout="row">
      <div class="table dispatch_option">
        <div class="dispatch-option-button" flex="100" layout="row">
          <div
            *ngIf="myCompanies.length > 0">
            <mat-select (selectionChange)="selectGroup($event)"
                        [(value)]="selected"
                        [style.width.px]="300"
                        class="selectCompany row-select push-top"
                        id="activeCompany" required>
              <mat-select-trigger>
                <mat-icon class="push-right">{{ getSelectedIcon() }}</mat-icon>
                <span class="select-text">{{ getCompanyName() | translate | ucFirst}}</span>
              </mat-select-trigger>
              <mat-option *ngFor="let company of myCompanies" [value]="(company ? company.id : null )">
                <mat-icon>business</mat-icon>
                <span>{{ company.name | ucFirst }}</span>
              </mat-option>
            </mat-select>
          </div>
        </div>
      </div>
    </div>
    <mat-card-actions>
      <button (click)="forwardRide()" mat-raised-button>{{'forward_to_group'| translate | ucFirst}}</button>
    </mat-card-actions>
  </div>
</div>
