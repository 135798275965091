import {Component} from '@angular/core';
import {Field} from '../../models/field.interface';
import {FieldConfig} from '../../models/field-config.interface';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-material-input',
  templateUrl: './material-input.component.html',
  styleUrls: ['./material-input.component.scss']
})
export class MaterialInputComponent implements Field {
  config: FieldConfig;
  group: UntypedFormGroup;
}
