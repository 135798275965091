<app-no-contract *ngIf="(this.context==='company' && (!this.company.ActiveContract ||
!['active','past_due'].includes(this.company.ActiveContract.foreignStatus)))"
                 [companyId]="this.companyId" [contract]="this.company.ActiveContract"></app-no-contract>
<mat-card *ngIf="!hasError" [class]="(this.context==='company' &&
(!this.company.ActiveContract ||
(this.company.hasDispatcherLicense && !this.company.ActiveContract.foreignStatus)) ? 'blurText' :'')" [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <ng-template tdLoading="pricing">

    <mat-tab-group (selectedTabChange)="onTabChange($event)" *ngIf="this.data">
      <mat-tab label="rules">
        <ng-template mat-tab-label>
          <mat-icon>format_line_spacing</mat-icon>
          Rules ({{this.data['rules'].length}})
        </ng-template>
        <div class="warning-pane" *ngIf="priceError">
          <mat-icon>warning</mat-icon>
          <span [innerHTML]="priceError|translate|ucFirst|keepHtml"></span>
        </div>
        <table class="mat-clickable" td-data-table>
          <thead *ngIf="data['rules'].length > 0">
          <tr td-data-table-column-row>
            <th *ngFor="let c of columns" [active]="(sortBy == c.name)" [sortOrder]="'ASC'"
                class="{{c.maxSize}} {{c.maxWidth}} {{c.fixedSize}}" td-data-table-column>
              {{ c.label | translate | ucFirst }}
            </th>
          </tr>
          </thead>

          <tbody (mouseleave)="drop($event, this)">
          <tr (mousedown)="pick($event, item)"
              (mousemove)="move($event, item)"
              (mouseup)="drop($event, item)"
              *ngFor="let item of data['rules']"
              [ngClass]="{'dragging': getPos(item._id) }"
              [ngStyle]="{'transform': 'translateY(' + getPos(item._id) + 'px)'}"
              class="draggable"
              td-data-table-row>

            <td *ngFor="let c of columns"
                [ngSwitch]="c.name"
                [numeric]="item.numeric"
                class="{{c.maxSize}} {{c.maxWidth}} {{c.fixedSize}} {{c.maxWidth}}"
                td-data-table-cell>
                <span *ngSwitchCase="'type'">
                  {{ ruleFrontendName(item[c.name]) | ucFirst }}
                </span>

              <span *ngSwitchCase="'startDate'"><span
                *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>
              <span *ngSwitchCase="'endDate'"><span
                *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>

              <span *ngSwitchCase="'taxiMeter'">
                  {{ (item[c.name] ? 'yes' : 'no') | ucFirst }}
                </span>

              <span *ngSwitchCase="'apps'">
                  <span *ngIf="item[c.name]" class="wrap-line">
                    <span matTooltip="{{item['tooltip']}}">{{item[c.name]}}</span>
                    </span>
                  <span
                    *ngIf="!item[c.name]">-</span>
                </span>

              <span *ngSwitchDefault>
                  {{ item[c.name] | ucFirst }}
                </span>

            </td>

            <td class="handle-cell noselect">
              <mat-icon class="handle">drag_handle</mat-icon>
            </td>
          </tr>
          </tbody>
          <tbody *ngIf="data['rules'].length === 0">
          <tr>
            <td [colSpan]="data['rules'].length"
                class="table-placeholder">{{'no_pricing_rules'|translate}}</td>
          </tr>
          </tbody>
        </table>
      </mat-tab>

      <mat-tab *ngIf="data['discounts']" label="discounts">
        <ng-template mat-tab-label>
          <mat-icon>tune</mat-icon>
          {{'special_rates' | translate | ucFirst}} ({{data['discounts'].length}})
        </ng-template>

        <table class="mat-clickable" td-data-table>
          <thead *ngIf="data['discounts'].length > 0">
          <tr td-data-table-column-row>
            <th *ngFor="let c of columns" td-data-table-column>
              {{ c.label | ucFirst }}
            </th>
          </tr>
          </thead>

          <tbody (mouseleave)="drop($event, null)">
          <tr (click)="click(item._id)"
              (mousedown)="pick($event, item)"
              (mousemove)="move($event, item)"
              (mouseup)="drop($event, null)"
              *ngFor="let item of data['discounts']"
              [ngClass]="{'dragging': getPos(item._id) }"
              [ngStyle]="{'transform': 'translateY(' + getPos(item._id) + 'px)'}"
              class="draggable"
              td-data-table-row>

            <td *ngFor="let c of columns"
                [numeric]="item.numeric"
                td-data-table-cell>

                <span [ngSwitch]="c.name">
                  <span *ngSwitchDefault>
                    <span *ngIf="item[c.name] && c.name != 'isEnabled'">{{item[c.name] | ucFirst}}</span>
                    <mat-icon *ngIf="c.name == 'isEnabled' && item[c.name]">done</mat-icon>
                  </span>
                </span>
            </td>

            <td class="handle-cell">
              <mat-icon class="handle">drag_handle</mat-icon>
            </td>

          </tr>
          </tbody>
          <tbody *ngIf="data['discounts'].length === 0">
          <tr>
            <td [colSpan]="data['discounts'].length"
                class="table-placeholder">{{'no_discount_rules'|translate}}</td>
          </tr>
          </tbody>
        </table>
      </mat-tab>
    </mat-tab-group>

  </ng-template>

</mat-card>
<app-install-tps *ngIf="hasError"></app-install-tps>
