import {Component, Input, OnInit} from '@angular/core';
import {TdDialogService} from '@covalent/core/dialogs';

@Component({
  selector: 'app-contract-edit',
  templateUrl: './contract-edit.component.html',
  styleUrls: ['./contract-edit.component.scss']
})
export class ContractEditComponent implements OnInit {
  @Input() company: any;

  constructor(
    private _dialogService: TdDialogService,
  ) {
  }

  ngOnInit(): void {
  }
}
