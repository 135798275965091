<mat-list-item class="pull-bottom" layout="row">
  <mat-icon mat-list-icon>note</mat-icon>
  <h4 mat-line>{{'extra'|translate|ucFirst}}</h4>
</mat-list-item>

<div [formGroup]="form"
     [mediaClasses]="['push-sm']"
     class="push-top" flex="100"
     layout-gt-sm="row"
     tdMediaToggle="gt-xs" tdMediaToggle="gt-xs">
  <mat-list-item flex-gt-sm="100">
    <mat-form-field class="full-width">
      <input [value]="( this.ride.flightNumber ? this.ride.flightNumber: '')" formControlName="flightNumber"
             id="flightNumber"
             matInput
             name="flightNumber"
             placeholder="{{ 'flightnumber' | translate | ucFirst }}"
             type="text">
    </mat-form-field>
  </mat-list-item>
</div>
