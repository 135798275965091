<div>
  <mat-card-title>{{'passenger_confirm_add_title'|translate|ucFirst}}</mat-card-title>
  <div>
    <div class="push-bottom">
      {{'passenger_confirm_add_body'|translate}}
    </div>
    <div class="push-top">
      <mat-checkbox [(ngModel)]="sendMail">{{'passenger_send_login_email'|translate|ucFirst}}</mat-checkbox>
    </div>

    <mat-card-actions class="action-pane">
      <button (click)="parent.createContact(sendMail);dialogRef.close();"
              mat-stroked-button>{{'add_contact'|translate|ucFirst}}</button>

      <button (click)="dialogRef.close();" mat-stroked-button>{{'close'|translate|ucFirst}}</button>
    </mat-card-actions>
  </div>
</div>
