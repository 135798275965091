<div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <mat-list-item *ngIf="paymentMeta.origin" class="value small" flex-gt-sm="25">
    <p mat-line>{{'payment_method'|translate|ucFirst}}</p>
    <p mat-line>
      {{('payment_origin_' + paymentMeta.origin.toLowerCase()|translate|ucFirst)}}
      <span *ngIf="this.priceMeta && this.priceMeta.hourlyRate">, {{'hourly_booking'|translate}}</span>
    </p>
  </mat-list-item>

  <mat-list-item *ngIf="paymentMeta.psp" class="value small" flex-gt-sm="25">
    <p mat-line>{{'payment_method_psp'|translate|ucFirst}}</p>
    <p mat-line>
      {{paymentMeta.psp|ucFirst}}
    </p>
  </mat-list-item>

  <mat-list-item *ngIf="ride.passenger && ride.passengerId && ride.passenger.paymentMethod" class="value small"
                 flex-gt-sm="25">
    <p mat-line>{{'card_details'|translate|ucFirst}}</p>

    <p mat-line>
      <img *ngIf="(ride.passenger.paymentMethod.card && ride.passenger.paymentMethod.card.brand)"
           src="assets/yda/{{ride.passenger.paymentMethod.card.brand}}.svg">
      <span class="star_position"> **** **** **** </span>
      <span class="position">{{(ride.passenger.paymentMethod.card ?
        ride.passenger.paymentMethod.card.last4 : '')}}</span>
    </p>
  </mat-list-item>

  <mat-list-item *ngIf="ride.passenger && ride.payment && ride.payment.paymentStatus" class="value small"
                 flex-gt-sm="40">
    <p mat-line>{{'payment_status'|translate|ucFirst}}</p>

    <p mat-line>
       <span class="{{ride.payment.paymentStatusColor}}">
                    <span
                      *ngIf="ride.payment['paymentStatus']">{{ride.payment['paymentStatus'] | translate}}</span><span
         *ngIf="!ride.payment['paymentStatus']">-</span></span>
    </p>
  </mat-list-item>

  <mat-list-item *ngIf="ride.Debtor" class="value small" flex-gt-sm="40">
    <p mat-line>{{'debtor'|translate|ucFirst}}</p>
    <p mat-line>
      <a class="yda-link-blue" href="groups/{{ride.ownerId}}/debtors/{{ride.Debtor.id}}/view">{{ride.Debtor.code}} -
        {{ride.Debtor.companyName}}</a>
    </p>
  </mat-list-item>

  <mat-list-item *ngIf="this.priceMeta && this.priceMeta.hourlyRate &&
  this.priceMeta.hourlyRate > 0" class="value small" flex-gt-sm="17">
    <p mat-line>{{'hourlymeter'|translate|ucFirst}}</p>
    <p mat-line>
      <span>
        {{this.hourlyRate | localizedCurrency}}
      </span>
    </p>
  </mat-list-item>

  <mat-list-item *ngIf="this.priceMeta && this.priceMeta.bookedDuration &&
  this.priceMeta.bookedDuration > 0" class="value small" flex-gt-sm="17">
    <p mat-line>{{'booked_duration'|translate|ucFirst}}</p>
    <p mat-line>
      <span>
         {{this.bookedFormat}}
      </span>
    </p>
  </mat-list-item>
</div>
