import {AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {TdMediaService} from '@covalent/core/media';
import {CoolLocalStorage} from '@angular-cool/storage';
import {environment} from '../../../../environments/environment';
import {Subscription} from 'rxjs';
import {NavigationService} from '../../../services/navigation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {User} from '../../../models/user';
import {Company} from '../../../models/company';
import {TdDialogService} from '@covalent/core/dialogs';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {WorkspaceSelectionComponent} from '../../portal/workspace-selection/workspace-selection.component';
import {DaAppInstallService} from '../../../services/da-app-install.service';

@Component({
  selector: 'app-split-layout',
  templateUrl: './split-layout.component.html',
  styleUrls: ['./split-layout.component.scss'],
  providers: [UserService],
  animations: [
    trigger('menuSlide', [
      state('hidden', style({
        width: '0px',
        display: 'none',
      })),
      state('collapsed', style({
        width: '54px',
      })),
      state('expanded', style({
        width: '240px',
      })),
      transition('void => *', animate(0)),
      transition('collapsed => expanded', animate(300)),
      transition('expanded => collapsed', animate(300)),
    ]),
    trigger('mainMenuButton', [
      state('hidden', style({
        width: '0px',
        display: 'none',
      })),
      state('collapsed', style({
        right: '-7px',
      })),
      state('expanded', style({
        right: '12px',
      })),
      transition('void => *', animate(0)),
      transition('collapsed => expanded', animate(300)),
      transition('expanded => collapsed', animate(300)),
    ]),
    trigger('menuSlideText', [
      state('hidden', style({
        width: '0px',
        display: 'none',
      })),
      state('collapsed', style({
        display: 'none',
      })),
      state('expanded', style({
        display: 'block',
      })),
      transition('void => *', animate(0)),
      transition('* => hidden', animate(300)),
      transition('* => expanded', animate(0)),
      transition('* => collapsed', animate(0)),
    ]),
    trigger('mainContent', [
      state('hidden', style({
        marginLeft: '0px',
      })),
      state('collapsed', style({
        marginLeft: '54px',
      })),
      state('expanded', style({
        marginLeft: '240px',
      })),
      transition('void => *', animate(0)),
      transition('hidden => expanded', animate(300)),
      transition('collapsed => expanded', animate(300)),
      transition('expanded => collapsed', animate(300)),
    ]),
    trigger('subMenuSlide', [
      state('hidden', style({
        marginLeft: '0px',
      })),
      state('collapsed', style({
        width: '0px',
        marginLeft: '54px',
        marginRight: '0px'
      })),
      state('expanded', style({
        width: '186px',
        marginLeft: '54px',
        marginRight: '0px'
      })),
      transition('hidden => expanded', animate(300)),
      transition('collapsed => expanded', animate(300)),
      transition('expanded => collapsed', animate(300)),
    ])
  ]
})
export class SplitLayoutComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentChecked {
  pjson = require('../../../../../package.json');
  deployDate = require('../../../../../deploy.date.json');
  deployNumber = require('../../../../../deploy.buildnumber.json');
  routes: any;
  subRoutes: any;
  user: User = new User;
  company: Company;
  portalName = '';
  currentYear: number;
  navigationSub: Subscription;
  subNavigationSub: Subscription;
  subMenuTitleSub: Subscription;
  bodyTitleSub: Subscription;
  layoutButtonsSub: Subscription;
  actionLinkSub: Subscription;
  actionFnSub: Subscription;
  infoActionSub: Subscription;
  submenuSub: Subscription;
  currentRoute = '';
  subMenuTitle = '';
  companyMenuTitle = '';
  bodyTitle = '';
  actionLink = '';
  hasInfoAction = false;
  infoAction: any;
  actionFn: any;
  layoutButtons: any[] = [];
  submenuItems: any[] = [];
  hasActionLink = false;
  hasActionFn = false;
  mobileMenu = false;
  menuState = '';
  subMenuState = '';
  contentState = '';
  isExpanded = true;
  subIsExpanded = false;
  subIsManExpanded = false;
  prevMenuState = '';
  pageHelpTextTitle = '';
  pageHelpText = '';

  isOpen=false;

  constructor(
    public media: TdMediaService,
    private _vault: CoolLocalStorage,
    private _changeDetectorRef: ChangeDetectorRef,
    private _userService: UserService,
    private _dialogService: TdDialogService,
    private _navigationService: NavigationService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _daAppInstallService: DaAppInstallService,
  ) {
    const today = new Date();
    this.portalName = environment.portalName;
    this.currentYear = today.getUTCFullYear();
    this.user = this._vault.getObject(`${environment.vaultPrefix}.user`);

    this._router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    }

    this.navigationSub = this._navigationService.getMainMenuItems().subscribe(routes => {
      this.routes = routes;
    });

    this.subNavigationSub = this._navigationService.getSubMenuItems().subscribe(routes => {
      this.subRoutes = routes;
    });

    this._navigationService.setActionLink('');
    this._navigationService.setBodyTitle('-');
    this._navigationService.setActiveSubmenu(null);
    this.pageHelpText = '';

    this.subMenuTitleSub = this._navigationService.getSubMenuTitle().subscribe(title => {
      this.companyMenuTitle = title;
    });

    this.subMenuTitleSub = this._navigationService.getHelpText().subscribe(text => {
      this.pageHelpText = text;
    });
    this.subMenuTitleSub = this._navigationService.getHelpTextTitle().subscribe(text => {
      this.pageHelpTextTitle = text;
    });

    this.bodyTitleSub = this._navigationService.getBodyTitle().subscribe(title => {
      this.bodyTitle = title;
    });
  }

  ngOnInit() {
    const self = this;
    self.mobileMenu = (window.innerWidth < 600);

    this._route.data.subscribe((data: { currentRoute: string, company: Company }) => {
      // if you need to scroll back to top, here is the right place
      window.scrollTo(0, 0);
      self.currentRoute = data.currentRoute;
      if (data.company) {
        if (data.currentRoute.indexOf('operator') !== -1) {
          data.company.type = 'operator';
        }
        self._vault.setObject(`${environment.vaultPrefix}.workspace`, data.company);
      }
      this.company = data.company;

      if (!this.company.apps) {
        this._daAppInstallService.getAll({where: {companyId: this.company.id}}, 'company')
          .subscribe((apps) => {
            self.company.apps = apps.map((a) => {
              return a.daAppId;
            })
          });
      }

      if (data.company) {
        this._navigationService.setSubMenuTitle(data.company.name);
      } else {
        this._navigationService.setSubMenuTitle('personal');
      }

      this.media.broadcast();
    });

    this.actionLinkSub = this._navigationService.getActionLink().subscribe(link => {
      this.hasActionLink = (link !== '');
      this.actionLink = link;
      this._changeDetectorRef.detectChanges();
    });

    this.actionFnSub = this._navigationService.getActionFn().subscribe(link => {
      this.hasActionFn = (link ? true : false);
      this.actionFn = link;
      this._changeDetectorRef.detectChanges();
    });

    this.infoActionSub = this._navigationService.getInfoAction().subscribe(fn => {
      this.hasInfoAction = (!!fn);
      this.infoAction = fn;
      this._changeDetectorRef.detectChanges();
    });

    this.submenuSub = this._navigationService.getActiveSubmenu().subscribe(menu => {
      const activeMenu = this._route.routeConfig.children.filter((r: any) => {
        return (r.submenuName === menu)
      })[0];
      setTimeout(() => {
        if (activeMenu && !self.mobileMenu) {
          self.subMenuTitle = menu;
          self.submenuItems = activeMenu.children;
          self.menuState = 'collapsed';
          self.isExpanded = false;
          self.subMenuState = 'expanded';
          self.subIsExpanded = true;
          self.contentState = 'expanded';
        } else {
          if (self.mobileMenu) {
            self.menuState = 'hidden';
            self.subIsExpanded = false;
            self.subMenuState = 'hidden';
            if (self.menuState === 'hidden') {
              self.menuState = 'hidden';
              self.contentState = 'hidden';
              self.isExpanded = false
            } else {
              self.isExpanded = true;
              self.contentState = 'expanded';
              self.menuState = 'expanded';
            }
          } else {
            self.menuState = 'collapsed';
            self.subIsExpanded = false;
            self.subMenuState = 'collapsed';
            if (self.menuState === 'collapsed') {
              self.menuState = 'collapsed';
              self.contentState = 'collapsed';
              self.isExpanded = false
            } else {
              self.isExpanded = true;
              self.contentState = 'expanded';
              self.menuState = 'expanded';
            }
          }
        }
        self._changeDetectorRef.markForCheck();
      });
    });

    this.layoutButtonsSub = this._navigationService.getSplitLayoutButtons().subscribe(buttons => {
      this.layoutButtons = buttons
    });
  }

  ngAfterViewInit() {
    this.media.broadcast();
    this._changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.subNavigationSub.unsubscribe();
    this.navigationSub.unsubscribe();
    this.subMenuTitleSub.unsubscribe();
    this.bodyTitleSub.unsubscribe();
    this.actionLinkSub.unsubscribe();
    this.actionFnSub.unsubscribe();
    this.infoActionSub.unsubscribe();
    this.submenuSub.unsubscribe();
    this.layoutButtonsSub.unsubscribe();
  }

  logout(): void {
    this._vault.removeItem(`${environment.vaultPrefix}.user`);
    this._vault.removeItem(`${environment.vaultPrefix}.accessToken`);
    this._vault.removeItem(`${environment.vaultPrefix}.driver`);
    this._vault.removeItem(`${environment.vaultPrefix}.companyDriverId`);
    this._vault.removeItem(`${environment.vaultPrefix}.operator`);
    this._vault.removeItem(`${environment.vaultPrefix}.toBeInvoiced-Month-Filter`);
    this._vault.removeItem(`${environment.vaultPrefix}.toBeInvoiced-paymentMethod-Filter`);
    this._userService.logout();
  }

  toggleMenu(): void {
    const self = this;
    if (self.mobileMenu) {
      self.menuState = (this.menuState === 'hidden' ? 'expanded' : 'hidden');
    } else {
      self.menuState = (this.menuState === 'collapsed' ? 'expanded' : 'collapsed');
    }

    if (self.menuState === 'collapsed') {
      self.contentState = 'collapsed';
      self.isExpanded = (!self.isExpanded);
      self._changeDetectorRef.detectChanges();
    } else if (self.menuState === 'hidden') {
      self.contentState = 'hidden';
      self.isExpanded = (!self.isExpanded);
      self._changeDetectorRef.detectChanges();
    } else {
      self.contentState = 'expanded';

      setTimeout(function () {
        self.isExpanded = (!self.isExpanded);
        self._changeDetectorRef.detectChanges();
      }, 300);
    }
    self._vault.setObject(`${environment.vaultPrefix}.menu`, self.menuState);
    self._changeDetectorRef.detectChanges();
  }

  openWidget(): void {
    // @ts-ignore
    if (typeof zE !== 'undefined') {
      // @ts-ignore
      zE('webWidget', 'show');
      // @ts-ignore
      zE('webWidget', 'open');
      // @ts-ignore
      zE('webWidget:on', 'close', function () {
        // @ts-ignore
        zE('webWidget', 'hide');
      })
    }
  }

  toggleSubmenu(submenuItems: any, title: string, manual = false): void {
    const self = this;
    if (self.subIsExpanded) {
      setTimeout(() => {
        self.subMenuState = 'collapsed';
        self.contentState = 'collapsed';
        self.menuState = 'collapsed';
      }, 0);
      setTimeout(function () {
        self.isExpanded = false;
        self.subIsExpanded = false;
        if (self.subMenuTitle !== title) {
          self.toggleSubmenu(submenuItems, title, manual)
        }
      }, 300);
    } else {
      if (manual && self.subMenuTitle !== title) {
        self.subIsManExpanded = manual;
      }
      setTimeout(() => {
        self._changeDetectorRef.markForCheck();
        self.subIsExpanded = true;
      });

      setTimeout(function () {
        self.submenuItems = submenuItems;
        self.prevMenuState = self.menuState;
        self.subMenuTitle = title;
        self.contentState = 'expanded';
        self.subMenuState = 'expanded';
        setTimeout(() => {
          self.menuState = 'collapsed';
          self.isExpanded = false;
        }, 0);
        self._changeDetectorRef.detectChanges();
      }, 10);
    }
  }
  switchWorkspace(): void {
    const self = this;
    if (!self.isOpen) {
      self.isOpen = true;
      self._dialogService.open(WorkspaceSelectionComponent, {
        maxHeight: '80%',
        minWidth: (window.innerWidth < 500 ? '95%' : '500px'),
        data: {
          openFromDialog: true,
          parent: this
        },
      }).afterClosed().subscribe(() => {
        self.isOpen = false;
      });
    }
  }

  ngAfterContentChecked(): void {
    this._changeDetectorRef.detectChanges();
  }
}
