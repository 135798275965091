import {Component, OnInit} from '@angular/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {CompanyService} from '../../../services/company.service';
import {Company} from '../../../models/company';

@Component({
  selector: 'app-stripe-connect',
  templateUrl: './stripe-connect.component.html',
  styleUrls: ['./stripe-connect.component.scss']
})
export class StripeConnectComponent implements OnInit {

  constructor(
    private _vault: CoolLocalStorage,
    private _http: HttpClient,
    private _route: ActivatedRoute,
    private _companyService: CompanyService,
  ) {
  }

  ngOnInit(): void {
    const company: Company = this._vault.getObject(`${environment.vaultPrefix}.stripeConnect`);
    this._route.queryParamMap.subscribe(paramsMap => {
      console.log('Company:', company);
      console.log('params:', paramsMap['code']);
      this._companyService.connectStripeAccount({
        'code': paramsMap['params']['code'],
        'companyId': company.id
      }).subscribe(() => {
        window.location.href = `${environment.portalUrl}/groups/${company.id}/general`;
      }, (error) => {
        console.log(error);
      })
    });
  }
}
