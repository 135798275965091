<div [formGroup]="form">
  <div formGroupName="paymentMeta">
    <mat-form-field class="value form-left" flex="100">
      <mat-label>
        {{'passenger_payment_moment_select'|translate|ucFirst}}
        <span *ngIf="openbalance">({{{total: (openbalance < 0 ? (0 - openbalance) : openbalance)}|localizedCurrency}}
          {{(openbalance > 0 ? 'to_refund' : 'to_be_payed')|translate}})</span>
      </mat-label>
      <mat-select class="selectCompany" formControlName="paymentMoment" required>
        <mat-option [disabled]="this.form.controls['price']['controls']['total'].value === 0" value="now"><span>{{
          'now' | translate | ucFirst }}</span></mat-option>
        <mat-option [disabled]="this.form.controls['price']['controls']['total'].value === 0" value="pickuptime">
          <span>{{ 'before_pickup_time' | translate | ucFirst }}</span></mat-option>
        <mat-option disabled
                    value="started"><span>{{ 'at_pickup' | translate | ucFirst }} ({{'future_feature'|translate}})</span>
        </mat-option>
        <mat-option [disabled]="this.form.controls['price']['controls']['total'].value > 0" value="completed">
          <span>{{ 'at_dropoff' | translate | ucFirst }}</span></mat-option>
      </mat-select>
    </mat-form-field>

    <div *ngIf="this.form.controls['paymentMeta']['controls']['paymentMoment'].value ===
'pickuptime'" class="value form-right" flex="100">
      <mat-form-field flex="100">
        <input autocomplete="off" formControlName="hoursBeforePickup"
               matInput
               max="1000"
               min="1"
               placeholder="{{ 'hours_before_pickup' | translate | ucFirst }}"
               type="number">
      </mat-form-field>
    </div>
  </div>
</div>
