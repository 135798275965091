<mat-card ngClass="pull-over push">
  <mat-card-title>{{'company_invoice_settings'|translate}}</mat-card-title>
  <mat-card-subtitle>{{'company_invoice_settings_subtitle'|translate}}</mat-card-subtitle>

  <mat-divider></mat-divider>

  <form #settingsForm="ngForm">
    <div class="push-left-sm push-right-sm first-row" layout="row" layout-xs="column">
      <mat-form-field class="push-left-sm push-right-sm" flex-gt-xs="50" flex-xs="100">
        <input [(ngModel)]="settings.name" matInput name="name"
               placeholder="{{'company_legal_name'|translate|ucFirst}}">
      </mat-form-field>
    </div>

    <div class="push-left-sm push-right-sm first-row" layout="row" layout-xs="column">
      <mat-form-field class="push-left-sm push-right-sm" flex-gt-xs="50" flex-xs="100">
        <input [(ngModel)]="settings.bank" matInput name="bank"
               placeholder="{{'bank'|translate|ucFirst}}">
      </mat-form-field>
      <mat-form-field class="push-left-sm push-right-sm" flex-gt-xs="50" flex-xs="100">
        <input [(ngModel)]="settings.bankaccount" matInput name="bankaccount"
               placeholder="{{'bankaccount'|translate|ucFirst}}">
      </mat-form-field>
    </div>

    <div class="push-left-sm push-right-sm" flex="100" layout="row">
      <mat-form-field class="push-left-sm push-right-sm" flex-gt-xs="100" flex-xs="100">
          <textarea (change)="(formErrors.invoiceText ? this.validateFormField(this.form,'text') : '')"
                    [(ngModel)]="settings.invoiceText" class="inputTextArea" matInput
                    name="invoiceText"
                    placeholder="{{'invoice_text'|translate|ucFirst}}"
                    rows="5" type="text"></textarea>

        <mat-hint align="start" class="warning">
          <span [hidden]="!formErrors.invoiceText" class="tc-red-600">
            <span>{{ formErrors.invoiceText }}</span>
          </span>
        </mat-hint>
      </mat-form-field>
    </div>

    <mat-divider></mat-divider>

    <mat-card-title>{{'invoice_processing_settings'|translate}}</mat-card-title>
    <mat-card-subtitle>{{'invoice_processing_subsettings'|translate}}</mat-card-subtitle>

    <div class="push-left-sm push-right-sm" layout="row" layout-xs="column">
      <mat-form-field class="push-left-sm push-right-sm" flex-gt-xs="25" flex-xs="100">
        <mat-select [(ngModel)]="settings.groupFlow" class="row-select" flex="100" name="groupFlow"
                    placeholder="{{'group_flow'|translate|ucFirst}}">
          <mat-option *ngFor="let format of groupFlow"
                      [value]="format">{{'invoice_setting_' + format|translate|ucFirst}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="push-left-sm push-right-sm" flex-gt-xs="25" flex-xs="100">
        <mat-select [(ngModel)]="settings.processFlow" class="row-select" flex="100" name="processFlow"
                    placeholder="{{'process_flow'|translate|ucFirst}}">
          <mat-option *ngFor="let format of processFlow"
                      [value]="format">{{'invoice_setting_' + format|translate|ucFirst}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <mat-divider></mat-divider>

    <div class="push-left-sm push-right-sm push-bottom push-top" layout="column" layout-xs="column">
      <div *ngIf="settings.logo" class="push-left-sm push-right-sm" flex-gt-xs="100" flex-xs="100" push-left-sm>
        <img src="{{settings.logo}}" style="max-height:200px">
      </div>

      <div class="push-top push-left-sm invoice-file-text" flex="50"
           layout="row">{{'invoice_file_upload'|translate|ucFirst}}</div>
      <div class="push-left-sm" flex="50" layout="row">
        <input (change)="onFileChange($event)" [(ngModel)]="image" id="singleFile" name="image" type="file">

        <mat-file-upload-queue #fileUploadQueue
                               [fileAlias]="'image'"
                               [httpUrl]="settings.logo">
          <mat-file-upload *ngFor="let file of fileUploadQueue.files; let i = index" [file]="file"
                           [id]="i"></mat-file-upload>
        </mat-file-upload-queue>
      </div>
    </div>
  </form>

  <mat-divider></mat-divider>

  <mat-card-actions>
    <button (click)="onClickSave()" [disabled]="!settingsForm.valid" color="accent"
            mat-raised-button>{{'save_settings'|translate}}</button>
  </mat-card-actions>
</mat-card>
