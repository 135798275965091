<div [formGroup]="form" [style.width.%]="100" flex="100">
  <div formGroupName="paymentMeta">
    <mat-form-field [style.width.%]="100" flex="100">
      <mat-label>{{'payment_method'|translate|ucFirst}}</mat-label>
      <mat-select (selectionChange)="changeSelection($event)"
                  [value]="( paymentMeta && paymentMeta.origin ? paymentMeta.origin: 'inTaxi')"
                  class="row-select"
                  formControlName="origin"
                  id="paymentMeta"
                  placeholder="{{'paymentmethod'|translate|ucFirst}}" required>
        <mat-option value="inTaxi">{{'pay_driver'|translate|ucFirst}}</mat-option>
        <mat-option value="invoice">{{'onaccount'|translate|ucFirst}}</mat-option>
        <mat-option disabled value="online">{{'online'|translate|ucFirst}}</mat-option>
        <mat-option value="creditcardOnline">{{'creditcard_online'|translate|ucFirst}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
