import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Passenger} from '../../../../../../../models/passenger';
import {UntypedFormGroup} from '@angular/forms';
import {VehicleTypes} from '../../../../../../../models/product';
import {Debtor} from '../../../../../../../models/debtor';
import {Company} from '../../../../../../../models/company';
import {CountryISO, SearchCountryField,} from 'ngx-intl-tel-input';
import {ActivatedRoute} from '@angular/router';
import {environment} from '../../../../../../../../environments/environment';
import {Driver} from '../../../../../../../models/driver';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from '../../../../../../../services/utility.service';
import {PassengerService} from '../../../../../../../services/passenger.service';
import {debounceTime} from 'rxjs/operators';
import {ucFirst} from '../../../../../../../pipes/uc-first.pipe';
import {TdDialogService} from '@covalent/core/dialogs';
import {DaAppInstallService} from '../../../../../../../services/da-app-install.service';
import {WebbookerService} from 'app/services/webbooker.service';
import {TranslateService} from '@ngx-translate/core';
import {TdLoadingService} from '@covalent/core/loading';
import {PassengerConfirmAddContactDialogComponent} from './parts/passenger-confirm-add-contact-dialog/passenger-confirm-add-contact-dialog.component';
import {Ride} from '../../../../../../../models/ride';

@Component({
  selector: 'app-ride-edit-passenger',
  templateUrl: './ride-edit-passenger.component.html',
  styleUrls: ['./ride-edit-passenger.component.scss'],
  providers: [PassengerService]
})
export class RideEditPassengerComponent implements OnInit {
  passenger: Passenger;
  @Input() passengerCount: number;
  @Input() form: UntypedFormGroup;
  @Input() ride: Ride;
  @Input() debtor: Debtor;
  @Input() context: string;
  @Input() vehicleType: string;
  @Output() conditionalFormatting = new EventEmitter<string>();
  @Output() passengerSelected = new EventEmitter<Passenger>();

  company: Company;
  driver: Driver;
  originalContacts: Passenger[];
  contacts: Passenger[];
  country: string;
  loading: boolean = false;
  translations: string[];
  languages: string[];
  SearchCountryField = SearchCountryField;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom
  ];
  vehicleTypes: string[];
  formErrors: any = {};

  constructor(
    private _route: ActivatedRoute,
    private _vault: CoolLocalStorage,
    private _passengerService: PassengerService,
    private _dialogService: TdDialogService,
    private _daAppInstallService: DaAppInstallService,
    private _webbookerService: WebbookerService,
    private _loadingService: TdLoadingService,
    private _translateService: TranslateService,
  ) {
    const {company} = this._route.parent.snapshot.data;
    UtilityService.setBrowserTimeStamp(this.company);
    this.company = company;
    this.context = this._route.routeConfig['context'] || 'driver';
    this.driver = this._vault.getObject(`${environment.vaultPrefix}.driver`);

    this.languages = environment.languages;

    if (this.context === 'driver') {
      if (this.driver) {
        this.country = this.driver.country;
      }
    } else if (this.company) {
      this.country = this.company.country;
    }

    if (this.country === 'EN') {
      this.country = 'GB';
    }
    if (!this.country) {
      this.country = 'GB';
    }

    this.vehicleTypes = Object.keys(VehicleTypes)
      .map(k => VehicleTypes[k])
      .filter(v => typeof v !== 'number');

    _translateService.get([
      'yes', 'no',
      'passenger_added_message',
      'passenger_added_title',
      'passenger_updated_message',
      'deactivate_x',
      'activate_x',
      'message_deactivate_x',
      'message_activate_x',
      'x_deactivated',
      'x_activated',
      'deactivate',
      'activate',
      'contact_added_message',
      'contact_added_title',
      'passenger_duplicate_phone_error_message',
      'passenger_duplicate_phone_error_title',
      'contact_duplicate_error_message',
      'contact_duplicate_error_title',
      'no_webbooker_found',
      'no_webbooker_found_title',
      'change_contact_confirm',
      'contact_duplicate_select',
      'select_contact', 'cancel',
      'ok'
    ]).subscribe((translations: any) => {
      this.translations = translations;
    });
  }

  ngOnInit() {
    const self = this;
    if (this.company) {
      this._passengerService.getAll({where: {realm: this.company.id}}).subscribe((contacts) => {
        self.originalContacts = contacts;
      });
    }
  }

  passengerPhoneInput(event) {
    let phoneNumber = this.form.value.passenger.phoneNumber;
    if (phoneNumber && phoneNumber.number) {
      phoneNumber = phoneNumber.number;
      if (phoneNumber.indexOf('00') > -1 && phoneNumber.indexOf('00') < 1) {
        // @ts-ignore
        this.form.controls['passenger'].controls['phoneNumber'].setValue(phoneNumber.replace('00', '+'));
      }
    }
  }

  selectPassenger(event, contact: Passenger) {
    if (!event || !event.isUserInput) {
      return false;
    }
    // @ts-ignore
    this.form.controls['passenger'].controls['fname'].setValue(contact.fname);
    // @ts-ignore
    this.form.controls['passenger'].controls['lname'].setValue(contact.lname);
    // @ts-ignore
    this.form.controls['passenger'].controls['email'].setValue(contact.email);
    // @ts-ignore
    this.form.controls['passenger'].controls['phoneNumber'].setValue(contact.phoneNumber);

    this.passenger = contact;
    this.form.controls['passengerId'].setValue(contact.id);
    this.passengerSelected.emit(this.passenger);

    // @ts-ignore
    this.form.controls['passenger'].controls['email'].disable();
    // @ts-ignore
    this.form.controls['passenger'].controls['lname'].disable();
    // @ts-ignore
    this.form.controls['passenger'].controls['fname'].disable();
    // @ts-ignore
    this.form.controls['passenger'].controls['phoneNumber'].disable();
  }

  ngAfterViewInit(): void {
    const self = this;
    const setSearch = ['fname', 'lname', 'email'];
    setSearch.forEach((s) => {
      const input = document.querySelector(`#${s} input[type=text]`);
      if (input) {
        input.setAttribute('type', 'search');
      }
      // @ts-ignore
      self.form.controls['passenger'].controls[s].valueChanges.pipe(
        debounceTime(150))
        .subscribe(q => {
          self.search(q);
        });
    });
    if (self.ride && self.ride.passenger) {
      self.passenger = self.ride.passenger;
    }
    if (self.ride && self.ride.Passenger) {
      self.passenger = self.ride.Passenger;
    }
  }

  search(term: string) {
    const self = this;
    if (self.loading === false) {
      self.loading = true;
      self.contacts = [];
      self.searchContacts(term)
        .then(results => {
          self.contacts = self.contacts.concat(results);
          self.loading = false;
        })
    }
  }

  searchContacts(term: string): Promise<any[]> {
    const self = this;
    return new Promise((resolve) => {
      const results = [];
      if (term && self.originalContacts) {
        const regex = new RegExp(term.trim(), 'gi');
        self.originalContacts.forEach(driver => {
          const nameMatch = driver.fname.match(regex);
          const codeMatch = driver.lname.match(regex);
          const fullMatch = `${driver.fname} ${driver.lname}`.match(regex);
          let phoneMatch
          if (driver.email) {
            phoneMatch = driver.email.match(regex);
          }
          if (nameMatch) {
            results.push(driver);
          } else if (codeMatch) {
            results.push(driver);
          } else if (fullMatch) {
            results.push(driver);
          } else if (phoneMatch) {
            results.push(driver);
          }
        });
      }
      return resolve(results);
    });
  }

  confirmCreateContact(): void {
    /**
     * Is this a duplicate contact?
     */
    // @ts-ignore
    this.searchContacts(this.form.controls['passenger'].controls['email'].value)
      .then((results) => {
        console.log(results);
        if (results.length === 0) {
          const self = this;
          self._dialogService.open(PassengerConfirmAddContactDialogComponent, {
            width: '400px',
            disableClose: false,
            data: {
              openFromDialog: true,
              parent: self
            },
          });
        } else {
          this._dialogService.openConfirm({
            message: this.translations['contact_duplicate_select'],
            disableClose: false,
            cancelButton: this.translations['cancel'],
            acceptButton: this.translations['select_contact'],
          }).afterClosed().subscribe((accept: boolean) => {
            if (accept) {
              this.selectPassenger({isUserInput: true}, results[0]);
            }
          });
        }
      })
  }

  changeContact(): void {
    const self = this;
    self._dialogService.openConfirm({
      message: self.translations['change_contact_confirm'],
      disableClose: false,
      cancelButton: self.translations['no'],
      acceptButton: self.translations['yes'],
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        // @ts-ignore
        this.form.controls['passenger'].controls['email'].enable();
        // @ts-ignore
        this.form.controls['passenger'].controls['lname'].enable();
        // @ts-ignore
        this.form.controls['passenger'].controls['fname'].enable();
        // @ts-ignore
        this.form.controls['passenger'].controls['phoneNumber'].enable();

        // @ts-ignore
        this.form.controls['passenger'].controls['email'].setValue('');
        // @ts-ignore
        this.form.controls['passenger'].controls['lname'].setValue('');
        // @ts-ignore
        this.form.controls['passenger'].controls['fname'].setValue('');
        // @ts-ignore
        this.form.controls['passenger'].controls['phoneNumber'].setValue('');
        // @ts-ignore
        this.form.controls['paymentMeta'].controls['paymentMethodId'].setValue('');

        this.passenger = null;
        this.ride.passengerId = null;
        this.form.controls['passengerId'].setValue(null);
      }
    });
  }

  createContact(sendMail = false): void {
    const self = this;
    self._loadingService.register();
    const user: any = this._vault.getObject(`${environment.vaultPrefix}.user`);
    const data = this.form.controls['passenger'].value;
    delete data.paymentMethodId;
    let passenger: Passenger = new Passenger();

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        passenger[key] = data[key];
      }
    }
    passenger.realm = this.company.id;
    if (data.phoneNumber && data.phoneNumber.e164Number) {
      passenger.phoneNumber = data.phoneNumber.e164Number;
    } else {
      passenger.phoneNumber = '';
    }
    /**
     * Call the API
     */
    delete passenger.id;
    self._daAppInstallService.getAll({
      where: {
        daAppId: environment.webbookerDaAppId,
        companyId: self.company.id,
      }
    }).subscribe((bookers) => {
      if (bookers.length === 0) {
        self._dialogService.openAlert({
          message: ucFirst(self.translations['no_webbooker_found']),
          disableClose: true,
          title: ucFirst(self.translations['no_webbooker_found_title']),
          closeButton: self.translations['ok']
        }).afterClosed().subscribe(() => {

        });
      } else {
        bookers.map((booker) => {
          if (booker.formId) {
            booker.JWTtoken = self._vault.getItem(`${environment.vaultPrefix}.${booker.formId}.jwtToken`);
            if (!booker.JWTtoken) {
              self._daAppInstallService.refreshToken(booker.formId)
                .then((token) => {
                  booker.JWTtoken = token;
                  self._vault.setItem(`${environment.vaultPrefix}.${booker.formId}.jwtToken`, token);
                });
            }
            booker.url = `${environment.orderFormFrontEndUrl}/dashboard/${user.locale.toLowerCase()}/${booker.formId}`;
            return self._webbookerService.get(booker.formId, {}, {'Authorization': `Bearer ${booker.JWTtoken}`})
              .subscribe((b) => {
                passenger.branding = {
                  name: self.company.name,
                  logo: b.config.logo,
                  sendAccountEmail: (sendMail),
                  url: booker.url
                };

                self._passengerService.insert(passenger, self.context).subscribe((nPass: Passenger) => {
                  setTimeout(function () {
                    self.selectPassenger(null, nPass);
                    self._loadingService.resolve();
                    self._dialogService.openAlert({
                      message: ucFirst(self.translations['contact_added_message']),
                      disableClose: true,
                      title: ucFirst(self.translations['contact_added_title']),
                      closeButton: self.translations['ok']
                    }).afterClosed().subscribe(() => {
                    });
                  }, 500);
                }, error => {
                  self._loadingService.resolve();
                  if (error.status === 409 && error.error.error.name === 'DUPLICATE_PHONENUMBER') {
                    self._dialogService.openAlert({
                      message: ucFirst(self.translations['passenger_duplicate_phone_error_message']),
                      disableClose: false,
                      title: ucFirst(self.translations['passenger_duplicate_phone_error_title']),
                      closeButton: self.translations['ok']
                    });
                  } else if (error.status === 422 && error.error.error.name === 'ValidationError') {
                    if (error.error.error.details && error.error.error.details.codes && error.error.error.details.codes.email) {
                      self._dialogService.openAlert({
                        message: ucFirst(self.translations['contact_duplicate_error_title']),
                        disableClose: false,
                        title: ucFirst(self.translations['contact_duplicate_error_message']),
                        closeButton: self.translations['ok']
                      });
                    }
                  }
                })
              })
          }
        });
      }
    });
  }
}
