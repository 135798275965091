<mat-card-title>{{'calendar_select_title'|translate|ucFirst}}</mat-card-title>
<mat-card-subtitle>{{'calendar_select_subtext'|translate|ucFirst}}</mat-card-subtitle>
<mat-card-content>
  <div (click)="selected=calendar.id" *ngFor="let calendar of calendars" class="connected-calendar {{(selected===calendar.id?
    'selected-calendar' : '')}}" flex="100" layout="row">
    <img class="google-calendar-logo" src="assets/img/google-calendar-logo.png">
    <div flex="100">{{calendar.summary}}</div>
  </div>
  <div (click)="selected='new'" class="connected-calendar {{(selected==='new'? 'selected-calendar' : '')}}" flex="100"
       layout="row">
    <mat-icon class="add-icon">add</mat-icon>
    <div flex="100">{{'calendar_select_create_sub'|translate|ucFirst}}</div>
  </div>
</mat-card-content>
<mat-divider></mat-divider>
<mat-card-actions class="dialog-buttons pull-bottom">
  <button (click)="save()" color="accent" mat-button>
    {{'select'|translate|ucFirst}}
  </button>
  <button (click)="dialogRef.close()" *ngIf="!forceSelect" class="push-right-small" color="accent" mat-button>
    {{'close'|translate|ucFirst}}
  </button>
</mat-card-actions>
