import {Component} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {environment} from '../../../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UserService} from '../../../services/user.service';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-signup-successful',
  templateUrl: './signup-successful.component.html',
  styleUrls: ['./signup-successful.component.scss']
})
export class SignupSuccessfulComponent {
  translations: string[];
  signupSuccessFullStep1: string = '';
  signupSuccessFullStep2: string = '';
  sending = false;

  constructor(
    private _vault: CoolLocalStorage,
    private _userService: UserService,
    private _snackbar: MatSnackBar,
    private _translateService: TranslateService,
  ) {
    const self = this;
    _translateService.get([
      'signup_success_text_1',
      'signup_success_text_2',
      'resend_confirmation',
    ]).subscribe((translations: any) => {
      self.translations = translations;

      const user: any = self._vault.getObject(`${environment.vaultPrefix}.newUser`);
      self.signupSuccessFullStep1 = self.translations['signup_success_text_1'].replace('{email}', (user ? (user.email ? user.email : user.emailAddress) : 'email'));
      self.signupSuccessFullStep2 = self.translations['signup_success_text_2'].replace('{email}', (user ? (user.email ? user.email : user.emailAddress) : 'email'));
    });
  }

  resendEmail(): void {
    if (!this.sending) {
      const self = this;
      self.sending = true;
      const user: any = self._vault.getObject(`${environment.vaultPrefix}.newUser`);
      if (!user) {
        self.sending = false;
        return;
      }
      self._userService.resendVerificationEmail({
        email: (user.email ? user.email : user.emailAddress),
        origin: 'resend'
      }).subscribe(() => {
        self._snackbar.open(self.translations['resend_confirmation'], '', {duration: 3000});
        setTimeout(function () {
          self.sending = false;
        })
      });
    }
  }
}
