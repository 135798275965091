<mat-card *ngIf="form && company" [formGroup]="form">
  <mat-card-title>{{ 'onboard_servicearea_title' | translate | ucFirst }}</mat-card-title>
  <mat-card-subtitle>{{ 'onboard_servicearea_subtitle' | translate | ucFirst }}</mat-card-subtitle>

  <mat-divider></mat-divider>

  <mat-card-content>
    <div #searchDisplayer></div>

    <mat-checkbox class="row-select"
                  flex="33"
                  value="true"
                  (click)="this.form.markAsTouched();alertCleanup()"
                  [ngModelOptions]="{standalone: true}"
                  [(ngModel)]="serviceAreaLimit">
      {{ 'no_service_area' | translate }}
    </mat-checkbox>

    <mat-label class="label_title push-top" layout="row" flex="100" *ngIf="!hasShape() && serviceAreaLimit">
      {{ 'use_address_as_service_area' | translate | ucFirst }}
    </mat-label>
    <div *ngIf="!hasShape() && serviceAreaLimit" class="flex">
      <!-- Search -->
      <mat-form-field flex="80">
        <input #autocomplete [formControl]="autoComplete"
               [matAutocomplete]="auto"
               matInput
               placeholder="{{ 'search' | translate | ucFirst }}"
               type="text">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option
            (onSelectionChange)="import(res)"
            *ngFor="let res of results | async"
            [value]="res.description">
                  <span *ngIf="res.description">
                    {{ res.description }}
                  </span>
            <span *ngIf="res.isShared">
                    <mat-icon>{{ getIcon(res.descriptor) }}</mat-icon>
              {{ res.name | ucFirst }}
                  </span>
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field flex="20" class="radius">
        <mat-select class="row-select"
                    flex="100"
                    formControlName="radius"
                    placeholder="{{ 'radius' | translate | ucFirst }}" required>
          <mat-option *ngFor="let type of kmOptions" [value]="type">
            {{ type | ucFirst }} {{ 'km'|distanceFormat:company:false | translate | ucFirst }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Address -->
    <mat-form-field *ngIf="hasShape() && isPoint()" flex
                    style="width: 100%">
      <input [readonly]="true"
             formControlName="address"
             matInput
             placeholder="{{ 'address' | translate | ucFirst }}"
             style="color: gray"
             type="text">
    </mat-form-field>

    <!-- Map -->
    <mat-card-content class="map-container {{(!serviceAreaLimit || !hasShape() ? 'hidden-visibility' : '')}}">
      <app-map
        (mapReady)="onMapReady($event)"
        (nrShapes)="onNrShapes($event)"
        [allowDrawingMode]="false"
        [location]="form"
        [style.height]="!serviceAreaLimit || !hasShape() ? 0 : '50vh'">
      </app-map>
    </mat-card-content>

    <mat-divider></mat-divider>

    <mat-card-actions>
      <div class="progress">1/5 {{ 'completed'|translate|ucFirst }}</div>

      <div class="button-div">
        <button (click)="setStep('skipped')" class="link-button"
                mat-button>{{ 'setup_later' | translate | ucFirst }}
        </button>

        <button (click)="setStep('availability')" color="primary"
                mat-raised-button>{{ 'continue' | translate | ucFirst }}
        </button>

        <button  *ngIf="form && !hasShape()"
                 (click)="this.importArea(this.currentPlace)"
                 [disabled]="!serviceAreaLimit || !this.currentPlace"
                 flex
                 mat-stroked-button>
          {{ 'import_area'|translate|ucFirst }}
        </button>

        <button (click)="clearLocation()" *ngIf="hasShape() && serviceAreaLimit"
                flex
                class="push-right-small push-bottom"
                mat-stroked-button>
          {{ 'clear_location'|translate|ucFirst }}
        </button>
      </div>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
