import {Injectable} from '@angular/core';
import {GlobalService} from './global.service';
import {HttpClient} from '@angular/common/http';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from './utility.service';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountryService extends GlobalService {
  constructor(_http: HttpClient, _vault: CoolLocalStorage, _utilityService: UtilityService) {
    super(_http, _vault, _utilityService);
    this.setModel('countries');
  }

  getAll(filter?: Object, context?: string): Observable<any[]> {
    filter = JSON.stringify(this._utilityService.restrictCompanyFilter(filter, (context === 'company')));
    return this._http
      .get<any[]>(`https://library.dispatchapi.io/${this.model}?filter=${filter}`, {headers: this.getHeaders(context)});
  }

  update(id: string, object: any, context?: string): Observable<any> {
    return this._http.patch<any>(`https://library.dispatchapi.io/${this.model}/${id}`, object, {headers: this.getHeaders(context)});
  }
}
