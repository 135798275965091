import {Component, Inject, OnInit} from '@angular/core';
import {ProductTemplate} from '../../../../../../models/product-template.model';
import {ProductTemplateService} from '../../../../../../services/product-template.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-product-template-select',
  templateUrl: './product-template-select.component.html',
  styleUrls: ['./product-template-select.component.scss']
})
export class ProductTemplateSelectComponent implements OnInit {
  templates: ProductTemplate[];
  innerWidth: 1024;

  constructor(
    private _ProductTemplateService: ProductTemplateService,
    public dialogRef: MatDialogRef<ProductTemplateSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this._ProductTemplateService.getAll({'order': 'priority asc'}).subscribe((templates) => {
      this.templates = templates;
    });
    this.innerWidth = data.innerWidth;
  }

  ngOnInit(): void {
  }

  selectTemplate(template): void {
    this.data.parent.addService(template)
    this.dialogRef.close();
  }
}
