import {Component, Input, OnInit} from '@angular/core';
import {AgmMarker, GoogleMapsAPIWrapper, MarkerManager} from '@agm/core';
import {environment} from '../../../../../../environments/environment';
import {Ride} from '../../../../../models/ride';
import {ActivatedRoute} from '@angular/router';
import {NavigationService} from '../../../../../services/navigation.service';

declare const google: any;

@Component({
  selector: 'app-ride-dispatch-log-map',
  templateUrl: './ride-dispatch-log-map.component.html',
  styleUrls: ['./ride-dispatch-log-map.component.scss']
})
export class RideDispatchLogMapComponent implements OnInit {

  @Input() dataSet: any;
  @Input() ride: Ride = new Ride();
  mapBounds: any;
  markers: any[] = [];
  boundsDefined = false;

  constructor(private _gMapsApi: GoogleMapsAPIWrapper, private _markerManager: MarkerManager,
              private _navigationService: NavigationService,
              private activatedRoute: ActivatedRoute,
  ) {
    this._navigationService.setActiveSubmenu(this.activatedRoute.routeConfig['submenu']);
  }

  ngOnInit() {
    const self = this;
    this._gMapsApi.getNativeMap().then((map) => {
      map.addListener('tilesloaded', () => {
        if (!this.boundsDefined) {
          self.drawMap();
        }
      });
    });
  }

  drawMap() {
    this.mapBounds = new google.maps.LatLngBounds();

    /**
     * Add pickup marker
     */
    const pickupMarker = new AgmMarker(this._markerManager);
    pickupMarker.latitude = this.ride.departure.gps.lat;
    pickupMarker.longitude = this.ride.departure.gps.lng;
    pickupMarker.iconUrl = `/assets/${environment.buildName}/icon-departure-marker.png`;
    this.markers.push(pickupMarker);
    this._markerManager.addMarker(pickupMarker);

    /**
     * Add all vehicle markers
     */
    this.dataSet.selectedDrivers.forEach((driver) => {
      const marker = new AgmMarker(this._markerManager);
      marker.latitude = driver.Vehicle.location.gps.lat;
      marker.longitude = driver.Vehicle.location.gps.lng;
      marker.iconUrl = `/assets/${environment.buildName}/icon-vehicle-marker.png`;
      marker.label = driver.fname + ' ' + driver.lname;
      this.markers.push(marker);
      this._markerManager.addMarker(marker);
    });

    /**
     * Add all markers to the map bounds
     */
    this.markers.forEach((marker) => {
      this.mapBounds.extend(new google.maps.LatLng(marker.latitude, marker.longitude));
    });

    /**
     * Fit and pan to bounds
     */
    this._gMapsApi.fitBounds(this.mapBounds);
    this._gMapsApi.panToBounds(this.mapBounds);

    this.boundsDefined = true;
  }

}
