<app-invoice-filter (filterChanged)="invoiceFilterChanged($event)"
                    (searchChanged)="search($event)"
                    [context]="context"
                    [type]="'history'">
</app-invoice-filter>
<mat-divider></mat-divider>
<table class="mat-clickable" td-data-table>
  <thead>
  <tr td-data-table-column-row>
    <th (sortChange)="sort($event)"
        *ngFor="let c of columns"
        [active]="(sortBy == c.name)"
        [name]="c.name"
        [numeric]="(!!c.numeric)"
        [sortOrder]="sortOrder"
        [sortable]="(!!c.sortable)"
        td-data-table-column>
      {{c.label|translate|ucFirst}}
    </th>
  </tr>
  </thead>
  <tbody>
  <tr (click)="open(item.id)" *ngFor="let item of filteredData" ngClass="invoice-row" td-data-table-row>
    <td *ngFor="let c of columns" [numeric]="c.numeric" td-data-table-cell>
      <span [ngSwitch]="c.name">
        <span *ngSwitchCase="'created'"><span
          *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? this.company.dateFormat : 'DD-MM-YYYY')}}</span></span>
        <span *ngSwitchCase="'amount'">
          <span *ngIf="item[c.name]" [ngClass]="(item.debit ? 'debit' : 'credit')">
            {{(item.credit ? '- ' : '')}}{{item[c.name] | localizedCurrency}}
          </span>
          <span *ngIf="!item[c.name]">-</span>
        </span>
        <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span><span
          *ngIf="!item[c.name]">-</span></span>
      </span>
    </td>
  </tr>
  </tbody>
</table>
<td-paging-bar #pagingBar (change)="page($event)" [pageSize]="pageSize" [total]="filteredTotal">
  <span hide-xs td-paging-bar-label>{{'rows_p_page'|translate}}:</span>
  {{pagingBar.range}} <span hide-xs>of {{pagingBar.total}}</span>
</td-paging-bar>

