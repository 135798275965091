import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Company} from '../../../../../models/company';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../../../../environments/environment';
import {ucFirst} from '../../../../../pipes/uc-first.pipe';
import {Passenger} from '../../../../../models/passenger';
import {CoolLocalStorage} from '@angular-cool/storage';
import {DaAppInstallService} from '../../../../../services/da-app-install.service';
import {TdDialogService} from '@covalent/core/dialogs';
import {WebbookerService} from '../../../../../services/webbooker.service';
import {PassengerService} from '../../../../../services/passenger.service';

@Component({
  selector: 'app-save-passenger-as-contact-dialog',
  templateUrl: './save-passenger-as-contact-dialog.component.html',
  styleUrls: ['./save-passenger-as-contact-dialog.component.scss']
})
export class SavePassengerAsContactDialogComponent {
  context = 'company';
  parent: any;
  company: Company;
  translations: string[];
  job: any;

  constructor(
    public _dialogRef: MatDialogRef<SavePassengerAsContactDialogComponent>,
    private _snackBar: MatSnackBar,
    private _vault: CoolLocalStorage,
    private _dialogService: TdDialogService,
    private _daAppInstallService: DaAppInstallService,
    private _webbookerService: WebbookerService,
    private _passengerService: PassengerService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.parent = data.parent;
    this.company = data.parent.company;
    this.job = data.job;
    this.translations = data.parent.translations;
  }

  addPassengerAsContact(job, sendContactEmail) {
    const self = this;
    const user: any = this._vault.getObject(`${environment.vaultPrefix}.user`);
    console.log(this.job);
    const passenger: any = {
      fname: this.job.passengerFname,
      lname: this.job.passengerLname,
      email: this.job.passengerEmail,
      phoneNumber: this.job.passengerPhoneNumber,
      language: this.job.language,
      realm: this.company.id
    }

    if (sendContactEmail) {
      self._daAppInstallService.getAll({
        where: {
          daAppId: environment.webbookerDaAppId,
          companyId: self.company.id,
        }
      }).subscribe((bookers) => {
        if (bookers.length === 0) {
          self._dialogService.openAlert({
            message: ucFirst(self.translations['no_webbooker_found']),
            disableClose: true,
            title: ucFirst(self.translations['no_webbooker_found_title']),
            closeButton: self.translations['ok']
          }).afterClosed().subscribe(() => {

          });
        } else {
          bookers.map((booker) => {
            if (booker.formId) {
              booker.JWTtoken = self._vault.getItem(`${environment.vaultPrefix}.${booker.formId}.jwtToken`);
              if (!booker.JWTtoken) {
                self._daAppInstallService.refreshToken(booker.formId)
                  .then((token) => {
                    booker.JWTtoken = token;
                    self._vault.setItem(`${environment.vaultPrefix}.${booker.formId}.jwtToken`, token);
                  });
              }
              booker.url = `${environment.orderFormFrontEndUrl}/dashboard/${user.locale.toLowerCase()}/${booker.formId}`;
              return self._webbookerService.get(booker.formId, {}, {'Authorization': `Bearer ${booker.JWTtoken}`})
                .subscribe((b) => {
                  passenger.branding = {
                    name: self.company.name,
                    logo: b.config.logo,
                    url: booker.url,
                    sendAccountEmail: true,
                  };

                  self._passengerService.insert(passenger, self.context).subscribe((nPass: Passenger) => {
                    setTimeout(function () {
                      self._dialogService.openAlert({
                        message: ucFirst(self.translations['contact_added_message']),
                        disableClose: true,
                        title: ucFirst(self.translations['contact_added_title']),
                        closeButton: self.translations['ok']
                      }).afterClosed().subscribe(() => {
                        self._dialogRef.close();
                      });
                    }, 500);
                  }, error => {
                    if (error.status === 409 && error.error.error.name === 'DUPLICATE_PHONENUMBER') {
                      self._dialogService.openAlert({
                        message: ucFirst(self.translations['passenger_duplicate_phone_error_message']),
                        disableClose: false,
                        title: ucFirst(self.translations['passenger_duplicate_phone_error_title']),
                        closeButton: self.translations['ok']
                      });
                    } else if (error.status === 422 && error.error.error.name === 'ValidationError') {
                      if (error.error.error.details && error.error.error.details.codes && error.error.error.details.codes.email) {
                        self._dialogService.openAlert({
                          message: ucFirst(self.translations['contact_duplicate_error_title']),
                          disableClose: false,
                          title: ucFirst(self.translations['contact_duplicate_error_message']),
                          closeButton: self.translations['ok']
                        });
                      }
                    }
                  })
                })
            }
          });
        }
      });
    } else {
      console.log(passenger);
      self._passengerService.insert(passenger, self.context).subscribe((nPass: Passenger) => {
        self._dialogService.openAlert({
          message: ucFirst(self.translations['contact_added_message']),
          disableClose: true,
          title: ucFirst(self.translations['contact_added_title']),
          closeButton: self.translations['ok']
        }).afterClosed().subscribe(() => {
          self._dialogRef.close();
        });
      }, error => {
        if (error.status === 409 && error.error.error.name === 'DUPLICATE_PHONENUMBER') {
          self._dialogService.openAlert({
            message: ucFirst(self.translations['passenger_duplicate_phone_error_message']),
            disableClose: false,
            title: ucFirst(self.translations['passenger_duplicate_phone_error_title']),
            closeButton: self.translations['ok']
          });
        } else if (error.status === 422 && error.error.error.name === 'ValidationError') {
          if (error.error.error.details && error.error.error.details.codes && error.error.error.details.codes.email) {
            self._dialogService.openAlert({
              message: ucFirst(self.translations['contact_duplicate_error_title']),
              disableClose: false,
              title: ucFirst(self.translations['contact_duplicate_error_message']),
              closeButton: self.translations['ok']
            });
          }
        }
      });
    }
  }

}
