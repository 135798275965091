<td-layout #layout>
  <td-navigation-drawer email="{{user.email}}" logo="" name="{{user.firstName}} {{user.lastName}}"
                        sidenavTitle="{{portalName}}">
    <app-main-menu [layout]="layout"></app-main-menu>
    <mat-nav-list td-navigation-drawer-menu>
      <a (click)="logout()" mat-list-item>
        <mat-icon>exit_to_app</mat-icon>
        {{'signout'|translate|ucFirst}}</a>
    </mat-nav-list>
  </td-navigation-drawer>

  <td-layout-nav logo="">
    <div class="top-bar" flex layout="row" layout-align="center center" td-toolbar-content>
      <button (click)="toggleMenu()" class="toggleMenu workspace" mat-icon-button
              td-menu-button>
        <mat-icon>menu</mat-icon>
      </button>

      <a (click)="switchWorkspace()" class="top-logo">
        <mat-icon class="mat-icon-logo" svgIcon="assets:logo"></mat-icon>
      </a>

      <div class="workspace-switch">
        <div (click)="switchWorkspace()" class="text-truncate">{{companyMenuTitle|translate|ucFirst}}</div>
        <a (click)="switchWorkspace()" class="workspace workspace-arrow-down">
          <mat-icon>keyboard_arrow_down</mat-icon>
        </a>
      </div>

      <span *ngIf="!(media.registerQuery('gt-md'))"
            class="mobile-header">{{subMenuTitle|translate|ucFirst}}</span>

      <button [matMenuTriggerFor]="toolbarMenu1" class="profile-button" mat-icon-button>
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #toolbarMenu1="matMenu">
        <a mat-menu-item routerLink="/profile">
          <mat-icon>person</mat-icon>
          <span>{{'your_profile'|translate|ucFirst}}</span>
        </a>
        <a mat-menu-item routerLink="/profile/password">
          <mat-icon>vpn_key</mat-icon>
          <span>{{'menu_change_password'|translate|ucFirst}}</span>
        </a>

        <mat-divider class="push-bottom-sm push-top-sm"></mat-divider>
        <button (click)="logout()" mat-menu-item>
          <mat-icon>exit_to_app</mat-icon>
          <span>{{'menu_sign_out'|translate|ucFirst}}</span>
        </button>
      </mat-menu>
    </div>

    <mat-sidenav-container>
      <mat-sidenav #companyMenu [@menuSlide]="menuState" [disableClose]="true" [mode]="'side'"
                   [opened]="true"
                   [style.width.px]="!isExpanded ? 54 : 240"
                   class="custom-side-nav"
                   position="start">
        <mat-nav-list>
          <ng-template [ngForOf]="subRoutes" let-item let-last="last" ngFor>
            <a (click)="(!item.component ? toggleSubmenu(item.children, item.label, true): layout.close() &&
                 (!media.registerQuery('gt-md') || mobileMenu ? companyMenu.close() : null))"
               *ngIf="item.showInMenu &&
                  (item.showMobile || !mobileMenu) &&
                  (!item.requiredProduct ||
                  (this.company &&
                  this.company.products &&
                  this.company.products[item.path]) && (
                  !item.requiredLicenseLevel ||
                  (this.company.ActiveContract && item.requiredLicenseLevel.includes(this.company.ActiveContract.type))
                  )
                )"
               [routerLinkActive]="(item.component ? 'mat-accent' : '')"
               [routerLink]="(item.component ? [(item.path===''?currentRoute:item.path)] : null)"
               class="menu-item {{(!item.component && this.subMenuTitle === item.label && !subIsManExpanded ?
                 'mat-accent' : '')}}"
               mat-list-item>
              <mat-icon>{{item.icon}}</mat-icon>
              <span *ngIf="menuState==='expanded'">{{item.label|translate|ucFirst}}</span>
              <span *ngIf="!isExpanded" class="menu-tooltip">{{item.label|translate|ucFirst}}</span>
            </a>
          </ng-template>

          <div class="bottom-menu">
            <ng-template [ngForOf]="subRoutes" let-item let-last="last" ngFor>
              <a (click)="(!item.component ? toggleSubmenu(item.children, item.label, true): layout.close() &&
                 (!media.registerQuery('gt-md') || mobileMenu ? companyMenu.close() : null))"
                 *ngIf="item.showBottomInMenu &&
                  (item.showMobile || !mobileMenu) &&
                  (!item.requiredProduct ||
                  (this.company &&
                  this.company.products &&
                  this.company.products[item.path]) && (
                  !item.requiredLicenseLevel ||
                  (this.company.ActiveContract && item.requiredLicenseLevel.includes(this.company.ActiveContract.type))
                  )
                )"
                 [routerLinkActive]="(item.component ? 'mat-accent' : '')"
                 [routerLink]="(item.component ? [(item.path===''?currentRoute:item.path)] : null)"
                 class="menu-item {{(!item.component && this.subMenuTitle === item.label && !subIsManExpanded ?
                 'mat-accent' : '')}}"
                 mat-list-item>
                <mat-icon>{{item.icon}}</mat-icon>
                <span *ngIf="menuState==='expanded'">{{item.label|translate|ucFirst}}</span>
                <span *ngIf="!isExpanded" class="menu-tooltip">{{item.label|translate|ucFirst}}</span>
              </a>
            </ng-template>
          </div>
        </mat-nav-list>
      </mat-sidenav>
      <mat-sidenav-content *ngIf="subIsExpanded &&
      (!mobileMenu || subIsManExpanded)" [@subMenuSlide]="subMenuState" class="custom-subside-nav">
        <mat-sidenav-container class="sidenav-full">
          <mat-sidenav #subMenu
                       [disableClose]="true"
                       [mode]="'side'"
                       [opened]="true">
            <mat-nav-list>
              <ng-template [ngForOf]="submenuItems" let-item let-last="last" ngFor>
                <a (click)="layout.close() && (!media.registerQuery('gt-md') || mobileMenu ? subMenu.close() : null)"
                   *ngIf="item.path !== 'zendesk' &&
                item.path !== 'zendesk' &&
                !item.newTabUrl &&
                item.showInMenu &&
                (item.showMobile || !mobileMenu) &&
                (!item.requiredApp || ( item.requiredApp && this.company && this.company.apps &&
                this.company.apps.includes(item.requiredApp) )) &&
                (!item.requiredProduct || (this.company &&
                this.company.products &&
                this.company.products[item.path]))"
                   [routerLink]="[(item.path===''?currentRoute:item.path)]"
                   mat-list-item
                   routerLinkActive="mat-accent">
                  <mat-icon>{{item.icon}}</mat-icon>
                  <span>{{item.label|translate|ucFirst}}</span></a>

                <a *ngIf="item.path === 'url' &&
                item.showInMenu &&
                (item.showMobile || !mobileMenu) &&
                (!item.requiredProduct || (this.company &&
                this.company.products &&
                this.company.products[item.path]))"
                   [href]="item.newTabUrl" [target]="item.target"
                   mat-list-item routerLinkActive="mat-accent">
                  <mat-icon>{{item.icon}}</mat-icon>
                  <span>{{item.label|translate|ucFirst}}</span></a>

                <a (click)="openWidget();"
                   *ngIf="item.path === 'zendesk' &&
                !item.newTabUrl &&
                item.showInMenu &&
                (item.showMobile || !mobileMenu) &&
                (!item.requiredProduct || (this.company &&
                this.company.products &&
                this.company.products[item.path]))"
                   mat-list-item routerLinkActive="mat-accent">
                  <mat-icon>{{item.icon}}</mat-icon>
                  <span>{{item.label|translate|ucFirst}}</span></a>
              </ng-template>
            </mat-nav-list>
          </mat-sidenav>
        </mat-sidenav-container>
      </mat-sidenav-content>

      <mat-sidenav-content [@mainContent]="contentState">
        <mat-toolbar *ngIf="layoutButtons && layoutButtons.length > 0" class="content-toolbar" td-toolbar-content>
          <span>{{bodyTitle|ucFirst}}</span>
          <span flex></span>
          <ng-template [ngForOf]="layoutButtons" let-button let-last="last" ngFor>
            <a *ngIf="!button.fn" [routerLink]="button.link" mat-icon-button matTooltip="{{button.tooltip}}">
              <mat-icon class="mat-24">{{button.icon}}</mat-icon>
            </a>
            <a (click)="button.fn(button.param)" *ngIf="button.fn" mat-icon-button matTooltip="{{button.tooltip}}">
              <mat-icon class="mat-24">{{button.icon}}</mat-icon>
            </a>
          </ng-template>
        </mat-toolbar>

        <div class="mat-list-base" flex mat-scroll-y>
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>

      <mat-sidenav #contextInfo *ngIf="this.pageHelpText" [disableClose]="false" [mode]="'side'"
                   [opened]="true"
                   class="page-help-text"
                   position="end">
        <mat-toolbar td-sidenav-content>
          <span [innerHTML]="this.pageHelpTextTitle"></span>
        </mat-toolbar>

        <mat-nav-list>
          <div [innerHTML]="this.pageHelpText" class="helptext"></div>
        </mat-nav-list>
      </mat-sidenav>
    </mat-sidenav-container>

    <td-layout-footer>
      <div layout="row" layout-align="start center">
        <span class="mat-caption">Copyright &copy; 2016 - {{currentYear}} {{portalName}}. All rights reserved
          <span *ngIf="(media.registerQuery('gt-xs') | async)">- Angular {{pjson.dependencies["@angular/common"]}} -
            Build v{{pjson.version}}.{{deployNumber.buildnumber}} - {{deployDate.date}}</span>
        </span>
        <span flex></span>
      </div>
    </td-layout-footer>
  </td-layout-nav>
</td-layout>
<a *ngIf="(hasActionLink)" [routerLink]="[''+actionLink]" class="mat-fab-position-bottom-right z-3" color="primary"
   mat-fab>
  <mat-icon>add</mat-icon>
</a>

<a (click)="actionFn();" *ngIf="(hasActionFn)" class="mat-fab-position-bottom-right z-3" color="primary"
   mat-fab>
  <mat-icon>add</mat-icon>
</a>

<a (click)="infoAction();" *ngIf="(hasInfoAction)" class="mat-fab-position-bottom-right z-3" color="primary"
   mat-fab>
  <mat-icon>help</mat-icon>
</a>
