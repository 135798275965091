// @ts-ignore
import {} from '@types/googlemaps';
import {Pair} from 'app/models/location';

const isNumber = (n: any) => typeof n === 'number';
const isPoint = (c: any[]) => c.length === 2 && c.every(isNumber);
const isPolygon = (c: any[]) => c.some(isPoint);
const isMultiPolygon = (c: any[]) => c.some(isPolygon);
const toNumArr = (xs: Function[]) =>
  typeof xs[0] !== 'function' ? xs : xs.map((x) => x())

const transformCoords = (xs: Function[]) => {
  if (!Array.isArray(xs)) {
    throw Error('Provide array instead');
  }
  return toNumArr(xs);
}

export class Shape {

  public static defaultShapeStyle = {
    fillColor: '#03a9f4',
    fillOpacity: 0.35,
    strokeColor: '#03a9f4',
    strokeOpacity: 0.8,
    strokeWeight: 2,
    editable: true,
    draggable: true,
    geodesic: false,
  }

  /**
   * https://en.wikipedia.org/wiki/Haversine_formula
   */
  public static polarToMeters(lat1, lon1, lat2, lon2) {
    const R = 6378.137; // Radius of earth in KM
    const dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
    const dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
    const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return d * 1000;
  }

  /**
   * https://www.unitconverters.net/length/earth-s-polar-radius-to-meter.htm
   */
  public static metersToPolarRadius(meters: number) {
    return meters * 1.573124242049 * 10 ** -7;
  }

  public static point(coordinates: any[], radius: number) {

    coordinates = transformCoords(coordinates);

    const compensate = x => (x * 6) ** 1.1;
    const r = Shape.metersToPolarRadius(compensate(radius));
    const t = 0.8;

    if (isPoint(coordinates)) {
      const lat = parseFloat(coordinates[0]);
      const lng = parseFloat(coordinates[1]);
      return [Shape.fromPoint(lat, lng, t, r)];
    }
  }

  public static autoDetect(coordinates: any[]): google.maps.Polygon[] {

    coordinates = transformCoords(coordinates);
    if (isPoint(coordinates)) {
      const r = 0.0001;
      const t = 0.8;
      const lat = parseFloat(coordinates[0]);
      const lng = parseFloat(coordinates[1]);
      return [Shape.fromPoint(lat, lng, t, r)];
    }

    if (isPolygon(coordinates[0])) {
      return coordinates.map(polygon =>
        Shape.fromPolygon(polygon));
    }

    if (isMultiPolygon(coordinates[0])) {
      return coordinates.map(polygon =>
        Shape.fromPolygon(polygon[0]));
    }
  }

  private static fromPolygon(
    coordinates: Pair[],
  ): google.maps.Polygon {
    return new google.maps.Polygon(Object.assign({}, {
      paths: coordinates.map(latLng => ({lat: latLng[0], lng: latLng[1]}))
    }, Shape.defaultShapeStyle))
  }

  private static fromPoint(
    lat: number,
    lng: number,
    theta: number,
    radius: number,
  ) {
    const points = [];
    // tslint:disable-next-line:no-shadowed-variable
    const p = (lat, lng, x, y, r) =>
      [lat + (r * x), lng + (r * y) * 1.5];

    let x, y;
    let angle = 0.0;

    while (angle < 2 * Math.PI) {
      x = length * Math.cos(angle);
      y = length * Math.sin(angle);
      points.push(p(lat, lng, x, y, radius));
      angle += Math.abs(theta);
    }

    // Closing the polygon.
    points.push(points[0]);
    return Shape.fromPolygon(points);
  }
}
