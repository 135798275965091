<mat-card-content class="integration-pane" *ngIf="!selectFromTemplate">
  <mat-card-title>{{'webbooker_integration_select_option_title'|translate|ucFirst}}</mat-card-title>
  <div (click)="selectFromTemplate=true" class="integration-type"
       layout="row">
    <img src="../../../../../../assets/img/onboarding-wizard/widget.JPG">
    <div>
      <h3 flex>{{('default_template_option_title')|translate|ucFirst}}</h3>
      <div>{{('default_template_option_text')|translate|ucFirst}}</div>
    </div>
  </div>

  <div (click)="addNewMessage('custom')" class="integration-type"
       layout="row">
    <img src="../../../../../../assets/img/onboarding-wizard/iframe-removebg-preview.png">
    <div>
      <h3 flex>{{('add_custom_message')|translate|ucFirst}}</h3>
      <div>{{('add_custom_message_explain')|translate|ucFirst}}</div>
    </div>
  </div>
</mat-card-content>

<mat-card-content class="integration-pane" *ngIf="selectFromTemplate">
  <mat-card-title>{{'webbooker_integration_select_template'|translate|ucFirst}}</mat-card-title>

  <div *ngIf="messageTemplates.length === 0">{{'all_templates_added'|translate|ucFirst}}</div>
  <div (click)="addNewMessageFromTemplate(messageTemplate)" class="integration-type"
       layout="row" *ngFor="let messageTemplate of messageTemplates; let i = index;">
    <img src="../../../../../../assets/img/onboarding-wizard/iframe-removebg-preview.png">
    <div>
      <h3 flex>{{messageTemplate.name}}</h3>
      <div>{{'sms_template_'+messageTemplate.name.replace(RegExp(' ', "g"), "_").toLowerCase()|translate|ucFirst}}</div>
    </div>
  </div>
</mat-card-content>
