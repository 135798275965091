import {Component, Input, OnInit} from '@angular/core';
import {LocationPolicy} from '../../../../../../../../models/tps/location-policy';
import {LocationPolicyService} from '../../../../../../../../services/tps/location-policy.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-location-policy-tab',
  templateUrl: './location-policy-tab.component.html',
  styleUrls: ['./location-policy-tab.component.scss'],
  providers: [LocationPolicyService]
})
export class LocationPolicyTabComponent implements OnInit {
  @Input() companyId: string;
  @Input() daAppInstallId: string;
  policies: LocationPolicy[] = [];
  policyStates: any = {};

  constructor(
    private _locationPolicyService: LocationPolicyService,
    private _router: Router,
  ) {
  }

  ngOnInit() {
    this._locationPolicyService.getAll({where: {companyId: this.companyId}}).subscribe((policies: LocationPolicy[]) => {
      this.policies = policies;
      policies.forEach(policy => {
        this.policyStates[policy._id] = policy.daAppInstallIds.includes(this.daAppInstallId);
      });
    }, error => console.error(error));
  }

  goToPolicy(): void {
    this._router.navigate([`/groups/${this.companyId}/location-policies`])
  }

  togglePolicy(id: string): void {
    if (this.policyStates[id]) {
      this._locationPolicyService.addToDaAppInstall(id, this.daAppInstallId).subscribe(
        () => {
        },
        error => console.error(error)
      );
    } else {
      this._locationPolicyService.removeFromDaAppInstall(id, this.daAppInstallId).subscribe(
        () => {
        },
        error => console.error(error)
      );
    }
  }

}
