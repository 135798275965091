import {tap} from 'rxjs/operators';
import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {Observable} from 'rxjs';

import {TdDialogService} from '@covalent/core/dialogs';
import {ErrorService} from '../services/error.service';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Globals} from '../../globals';
import {DaAppInstallService} from '../services/da-app-install.service';
import StackTrace from 'stacktrace-js';
import {AuthService} from '../services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  translations: any;

  constructor(private _dialogService: TdDialogService,
              private _globals: Globals,
              private _authService: AuthService,
              private _vault: CoolLocalStorage,
              private _router: Router,
              private _injector: Injector) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const _errorService = this._injector.get(ErrorService);
    const _translateService = this._injector.get(TranslateService);

    /**
     * Get translations for errors
     */
    if (typeof _translateService !== 'undefined') {
      _translateService.get([
        'close',
        'unknown_error',
        'unknown_error_text'
      ]).subscribe((translations: object) => {
        this.translations = translations
      });
    }

    return next.handle(req).pipe(tap(event => {
    }, async err => {
      /**
       * Handle 500 responses
       */
      if (err instanceof HttpErrorResponse && (err.status === 500 || err.status === 400)) {
        _translateService.get([
          `error_code_${err.error.code}`
        ]).subscribe((translationCode: object) => {
          if (!translationCode[`error_code_${err.error.code}`]) {
            _errorService.reportError(err, `Current URL: ${this._router.url}`).subscribe((reportedError: any) => {
              this._dialogService.openAlert({
                message: `${this.translations.unknown_error_text} ${reportedError.guid}`,
                disableClose: false,
                title: this.translations.unknown_error,
                closeButton: this.translations['close']
              });
            });
          }
        });
      }

      /**
       * Handle 401 responses
       */
      if (err instanceof HttpErrorResponse && err.status === 401) {
        // Check if we're on the login page
        if (err.url.indexOf('/login') === -1) {
          const _daAppInstallService = this._injector.get(DaAppInstallService);
          const userAgent = {
            language: navigator.language,
            platform: navigator.platform,
            userAgent: navigator.userAgent,
            // connectionDownlink: navigator['connection'].downlink,
            // connectionEffectiveType: navigator['connection'].effectiveType
          };
          const stackTrace = await StackTrace.fromError(err, {offline: true});
          const body = {ts: Date.now(), userAgent, stackTrace};
          _daAppInstallService.reportError({message: body}, 'company')
            .subscribe(() => {
            });
          console.log('401 Auth lost redirect to login');
          this._vault.clear();
          this._authService.setAuthState(false);
          this._router.navigate(['/login'], {queryParams: {session_expired: true}});
        } else {
          console.log('401 Auth lost redirect to login');
          this._vault.clear();
          this._authService.setAuthState(false);
          this._router.navigate(['/login']);
        }
      }
    }));
  }
}
