import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';

@Component({
  selector: 'app-ob-availability',
  templateUrl: './ob-availability.component.html',
  styleUrls: ['./ob-availability.component.scss']
})
export class ObAvailabilityComponent implements OnInit {
  @Input() step: string;
  @Input() form: UntypedFormGroup;
  @Output() nextStep = new EventEmitter<string>();
  times = [];
  times2 = [];
  days = [
    'mon', 'thu', 'wed', 'thr', 'fri', 'sat', 'sun'
  ];
  minimumOrderTimeFormat = ['minutes', 'hours'];

  constructor() {
    for (let x = 0; x < 24; x++) {
      this.times.push(`${x < 10 ? '0' + x : x}:00`);
      this.times.push(`${x < 10 ? '0' + x : x}:30`);

      if (x > 0) {
        this.times2.push(`${x < 10 ? '0' + x : x}:00`);
      }
      this.times2.push(`${x < 10 ? '0' + x : x}:30`);
    }
    this.times2.push(`24:00`);
  }

  ngOnInit(): void {
  }

  setStep(step) {
    this.nextStep.emit(step);
  }
}
