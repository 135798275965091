import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Ride} from '../../../../../models/ride';
import {InvoiceService} from '../../../../../services/pas/invoice.service';
import {ReceiptService} from '../../../../../services/pas/receipt.service';

@Component({
  selector: 'app-ride-documents',
  templateUrl: './ride-documents.component.html',
  providers: [InvoiceService],
  styleUrls: ['./ride-documents.component.scss']
})
export class RideDocumentsComponent implements OnInit {
  @Output() reloadData = new EventEmitter<string>();
  @Input() ride: Ride;
  invoices: any[];
  receipt: any;
  hasSettlement = false;
  hasDebtor = false;
  shouldDebtor = false;
  columns: any[] = [
    {name: 'created', label: 'date', sortable: true, active: false, maxSize: null},
    {name: 'description', label: 'description', sortable: true, active: false, maxSize: null},
    {name: 'debit', label: 'id', sortable: true, active: false},
    {name: 'amount', label: 'amount', sortable: true, active: false},
  ];

  constructor(
    private _invoiceService: InvoiceService,
    private _recieptService: ReceiptService,
  ) {

  }

  ngOnInit(): void {
    if (this.ride && this.ride.resources) {
      if (this.ride.debtorId || this.ride.Passenger) {
        this.shouldDebtor = true;
      }

      let invoiceIds;
      if (this.ride.resources.invoices) {
        invoiceIds = this.ride.resources.invoices.map((i) => {
          return i.invoiceId;
        });
      }
      this._recieptService.getAll({where: {jobReference: this.ride.reference}}).subscribe((receipt) => {
        this.receipt = receipt[0];
        if (invoiceIds) {
          this._invoiceService.getAll({where: {id: {inq: invoiceIds}}, include: ['Issuer', 'Billable']}).subscribe((invoices) => {
            this.invoices = invoices;
            this.hasDebtor = this.invoices.filter((i) => {
              return (i.billableType === 'Debtor' || i.billableType === 'Passenger')
            })[0];
            this.hasSettlement = this.invoices.filter((i) => {
              return (i.billableType === 'Company' || i.billableType === 'Driver')
            })[0];
          });
        }
      });
    }
  }

  forwardReload() {
    this.reloadData.emit();
  }
}
