<table class="mat-clickable" td-data-table>
  <thead>
  <tr *ngIf="originalData['all'].length > 0" td-data-table-column-row>
    <th (sortChange)="sort($event)"
        *ngFor="let c of columns"
        [active]="(sortBy == c.name)"
        [name]="c.name"
        [numeric]="c.numeric"
        [sortOrder]="(sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
        [sortable]="c.sortable"
        td-data-table-column>
      {{c.label|translate|ucFirst}}
    </th>
  </tr>
  </thead>
  <tbody *ngIf="originalData['all'].length > 0">
  <ng-container *ngFor="let item of originalData['all'];" class="todo-assign">
    <tr class="pointer {{item.colorClass}} {{item.trColor}}"
        td-data-table-row>
      <td *ngFor="let c of columns" [numeric]="c.numeric" class="{{c.maxSize}} {{c.fixedSize}} {{c.maxWidth}} code_{{item['code'].toLowerCase()}}"
          td-data-table-cell>
        <span [ngSwitch]="c.name">
         <span *ngSwitchCase="'created'">
            <span
              *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY HH:mm')}}</span>
          </span>
          <span *ngSwitchCase="'authorized'">
            <span *ngIf="item[c.name]">
              {{{total: 0, currency: this.ride.price.currency} | localizedCurrency}}
              ({{item[c.name] | localizedCurrency}})
            </span>
            <span *ngIf="!item[c.name]">-</span>
          </span>
          <span *ngSwitchCase="'debit'">
            <span *ngIf="item[c.name]">
              {{item[c.name] | localizedCurrency}}
            </span>
            <span *ngIf="!item[c.name]">-</span>
          </span>
          <span *ngSwitchCase="'description'" class="description"><span *ngIf="item[c.name]">
            {{item[c.name] | translate}}
            <mat-icon *ngIf="item[c.name] && item['note']" [matTooltip]="item['note']" class="info-icon">info</mat-icon>
          </span>
            <span *ngIf="item[c.name] && item['data']">
              ({{item['data'].old | localizedCurrency}} >
              {{item['data'].new | localizedCurrency}})
            </span>

            <span
              *ngIf="!item[c.name]">-</span></span>

          <span *ngSwitchCase="'credit'">
            <span *ngIf="item[c.name]">
              {{item[c.name] | localizedCurrency}}
            </span>
            <span *ngIf="!item[c.name]">-</span>
          </span>

          <span *ngSwitchCase="'actions'" class="wrap-line">
            <mat-icon (click)="deletePaymentBalanceRecord(item.id)" *ngIf="['CUSTOM',
            'REFUND','REFUND_PROCESSED', 'JOB_CANCELED', 'CANCELLATION_CHARGE'].includes(item['code'])" class="delete-icon icon-right">delete
            </mat-icon>
          </span>

          <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name] | translate}}</span><span
            *ngIf="!item[c.name]">-</span></span>
        </span>
      </td>
    </tr>
  </ng-container>
  <tr class="pointer" td-data-table-row>
    <td class="total"
        colspan="2" td-data-table-cell>
      <button (click)="addPaymentBalanceRecord()" class="add_record_button" color="primary"
              mat-raised-button>{{'add_record' | translate | ucFirst}}</button>
    </td>
    <td td-data-table-cell class="to-be-paid">
      {{(this.total.total < 0 ? 'to_be_payed' : (this.total.total > 0 ? 'to_be_refunded' : 'open_amount'))|translate|ucFirst}}:
    </td>
    <td *ngIf="this.total && ride.paymentMeta" class="open-amount" colspan="2" td-data-table-cell>
      <span class="total-amount">{{(this.total.total < 0 ? this.cTotal : this.total) | localizedCurrency}}</span>
      <span [matTooltipDisabled]="ride.paymentMeta && (!ride.paymentMeta.psp || ride.paymentMeta.psp.toLowerCase() !== 'paypall')"
            class="tooltip-bottom"
            matTooltip="{{'payment_method_not_support_for_refund'|translate|ucFirst}}">
        <button (click)="captureAmount()" *ngIf="this.total.total < 0 && ride.paymentMeta.origin !== 'inTaxi'" [disabled]="ride.paymentMeta.psp && ride.paymentMeta.psp.toLowerCase() === 'paypall'"
                color="primary"
                mat-raised-button>{{'capture_amount' | translate | ucFirst}}</button>
        <button (click)="refundAmount()" *ngIf="this.total.total > 0"
                [disabled]="ride.paymentMeta.psp && ride.paymentMeta.psp.toLowerCase() === 'paypall'"
                color="primary"
                mat-raised-button>{{'refund_amount' | translate | ucFirst}}</button>
      </span>
    </td>
  </tr>
  </tbody>
  <tbody *ngIf="originalData['all'].length === 0">
  <tr>
    <td [colSpan]="columns.length"
        class="table-placeholder">
      {{'no_payment_balance'|translate|ucFirst}}
    </td>
  </tr>
  </tbody>
</table>
