<mat-card>
  <mat-list-item>
    <mat-icon mat-list-icon>payments</mat-icon>
    <h4 mat-line>{{'financial'|translate|ucFirst}}</h4>
    <p mat-line>{{'financial_subtitle'|translate|ucFirst}}</p>
  </mat-list-item>

  <mat-divider></mat-divider>

  <mat-tab-group *tdLoading="'attempt'">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>list</mat-icon>
        {{'payment_balance'|translate|ucFirst}}
      </ng-template>
      <app-payment-balance (reloadData)="forwardReload()" [ride]="ride"></app-payment-balance>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>list</mat-icon>
        {{'documents'|translate|ucFirst}}
      </ng-template>
      <table class="mat-clickable" td-data-table>
        <thead>
        <tr *ngIf="invoices || receipt" td-data-table-column-row>
          <th
            *ngFor="let c of columns"
            [name]="c.name"
            [numeric]="c.numeric"
            [sortable]="c.sortable"
            td-data-table-column>
            {{c.label|translate|ucFirst}}
          </th>
        </tr>
        </thead>
        <tbody>
        <ng-container class="todo-assign">

          <tr *ngFor="let invoice of invoices" td-data-table-row>
            <td td-data-table-cell>{{invoice.created | amDateFormat: 'DD-MM-YYYY'}}</td>
            <td
              td-data-table-cell>{{(!['Company', 'Driver'].includes(invoice.BillableType) ? 'invoice' : 'settlement') | ucFirst}}</td>
            <td td-data-table-cell>{{invoice.reference}}</td>
            <!--          <td>{{(invoice.Billable.companyName ? invoice.Billable.companyName : invoice.Billable.name)}}</td>-->
            <td td-data-table-cell><span
              [ngClass]="(invoice.debit && invoice.total.total >= 0 ? 'debit' : 'credit')">{{invoice.total |
              localizedCurrency}} ({{(invoice.status === 'final' ? 'open' : invoice.status) | translate | ucFirst}}
              )</span></td>
            <td class="button-column"><a color="primary" flex href="{{invoice.files.pdf}}" mat-raised-button
                                         target="_blank">{{'pdf' | translate | ucFirst}}</a></td>
          </tr>

          <tr *ngIf="receipt" td-data-table-row>
            <td td-data-table-cell>{{receipt.created | amDateFormat: 'DD-MM-YYYY'}}</td>
            <td td-data-table-cell>{{'receipt' | ucFirst}}</td>
            <td td-data-table-cell>{{receipt.reference}}</td>
            <td td-data-table-cell><span
              [ngClass]="(receipt.total.total >= 0 ? 'debit' : 'credit')">{{receipt.total |
              localizedCurrency}}</span></td>
            <td class="button-column"><a color="primary" flex href="{{receipt.files.pdf}}"
                                         mat-raised-button
                                         target="_blank">{{'pdf' |
              translate | ucFirst}}</a></td>
          </tr>
        </ng-container>
        </tbody>
        <tbody *ngIf="!invoices && !receipt">
        <tr>
          <td [colSpan]="columns.length"
              class="table-placeholder">
            {{'no_documents'|translate|ucFirst}}
          </td>
        </tr>
        </tbody>
      </table>
    </mat-tab>
  </mat-tab-group>
</mat-card>
