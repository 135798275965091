export class AvailabilityOverride {
  id: string;
  name: string;
  type: string;
  startDate: string;
  endDate: string;
  description: string;

  constructor() {

  }
}
