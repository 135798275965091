import {NgModule, Pipe, PipeTransform} from '@angular/core';
import {Company} from '../models/company';

@Pipe({
  name: 'distanceFormat'
})
export class DistanceFormat implements PipeTransform {
  transform(value: string, company: Company, short = false): string {

    if (!short) {
      return (company && company.settings && company.settings.distanceFormat === 'mi' ? 'mile' : 'kilometer');
    } else {
      return (company && company.settings && company.settings.distanceFormat === 'mi' ? 'mi' : 'km');
    }
  }
}

@NgModule({
  declarations: [DistanceFormat],
  exports: [DistanceFormat]
})
export class DistanceFormatModule {
}
