<div [formGroup]="form" [style.width.%]="100" flex="100">
  <mat-form-field [style.width.%]="100" class="select-debtor" flex="100">
    <mat-label>{{'customer'|translate|ucFirst}}</mat-label>
    <mat-select (selectionChange)="selectDebtor()"
                [value]="(this.debtor ? this.debtor.id : 0)"
                class="row-select"
                flex="100" formControlName="debtorId" id="debtorId">
      <mat-option (click)="addNewDebtor()">
        <mat-icon>add</mat-icon>
        {{'create_new_account'|translate|ucFirst}}</mat-option>

      <mat-option *ngFor="let debtor of debtors" value="debtor-{{debtor.id}}">
        <mat-icon>business</mat-icon>
        {{ debtor.code}} - {{debtor.companyName | ucFirst}}
        - {{debtor.firstName | ucFirst}} {{ debtor.lastName | ucFirst}}</mat-option>

      <mat-option *ngFor="let contact of contacts" value="contact-{{contact.id}}">
        <mat-icon>person</mat-icon>
        {{contact.fname | ucFirst}} {{ contact.lname | ucFirst}} - {{ contact.email | ucFirst}}</mat-option>

      <mat-select-trigger class="pull-top">
        {{currentDisplayValue}}
      </mat-select-trigger>
    </mat-select>
  </mat-form-field>
</div>
