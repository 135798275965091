import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Promotion} from '../../../../../../models/promotion';
import {ucFirst} from '../../../../../../pipes/uc-first.pipe';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TdLoadingService} from '@covalent/core/loading';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {TdDialogService} from '@covalent/core/dialogs';
import {NavigationService} from '../../../../../../services/navigation.service';
import {PromotionService} from '../../../../../../services/promotion.service';
import {environment} from '../../../../../../../environments/environment';
import {PromotionPushDialogComponent} from '../promotion-push-dialog/promotion-push-dialog.component';
import moment from 'moment';
import {Coupon} from '../../../../../../models/coupon';
import {CouponService} from '../../../../../../services/coupon.service';
import {ValidateAllFormFields} from '../../../../../../functions/validateAllFormFields';
import {Company} from '../../../../../../models/company';
import {UtilityService} from '../../../../../../services/utility.service';
import {UpsertComponent} from '../../../../../global/upsert/upsert.component';

@Component({
  selector: 'app-promotion-upsert',
  templateUrl: './promotion-upsert.component.html',
  styleUrls: ['./promotion-upsert.component.scss']
})
export class PromotionUpsertComponent extends UpsertComponent implements OnInit {
  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;
  files = [];
  loaderName = 'promotion';
  modelName = 'promotion';
  list = 'promotions';
  translations: string[] = [];
  currentAction;
  id: string;
  companyId: string;
  currency: string;
  action: string;
  data: Promotion = new Promotion();
  coupons: Coupon[];
  company: Company;

  minDate = moment().format('YYYY-MM-DDTHH:mm');
  maxDate = moment().format('YYYY-MM-DDTHH:mm');
  maxStartDate = moment().add('1', 'month').format('YYYY-MM-DDTHH:mm');

  form: UntypedFormGroup;
  formErrors: any = {
    name: '',
  };
  link = true;
  validationMessages = {
    'name': {
      'fieldName': 'name',
      'required': 'form_validation_required',
    },
    'title': {
      'fieldName': 'title',
      'required': 'form_validation_required',
    },
    'text': {
      'fieldName': 'text',
      'required': 'form_validation_required',
    },
    'startDate': {
      'fieldName': 'startDate',
      'required': 'form_validation_required',
    },
    'endDate': {
      'fieldName': 'startDate',
      'required': 'form_validation_required',
      'error_start_and_end_date_same': 'error_start_and_end_date_same'
    },
  };

  constructor(public snackBar: MatSnackBar,
              private _loadingService: TdLoadingService,
              private _formBuilder: UntypedFormBuilder,
              private _route: ActivatedRoute,
              private _router: Router,
              private _snackbar: MatSnackBar,
              private _translateService: TranslateService,
              private _navigationService: NavigationService,
              private _titleService: Title,
              private _dialogService: TdDialogService,
              private _couponService: CouponService,
              private _promotionService: PromotionService) {
    super(_loadingService, _dialogService, _navigationService, _router, _snackbar, _promotionService, _translateService, _route);
    this.loadErrorTranslations(_translateService);
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);

    const self = this;
    const {company} = this._route.parent.snapshot.data;
    this.currency = company.currency;
    this.company = company;
    UtilityService.setBrowserTimeStamp(this.company);

    this._route.parent.params.subscribe(parentParams => {
      this.companyId = parentParams['id'];
      this._route.params.subscribe(params => {
        this.id = params['id'];
        this.action = params['action'] ? params['action'] : params['id'];

        _translateService.get([
          `${this.action}_promotion`,
          'promotion_archived',
          'promotion_published',
          'promotion_added_message',
          'promotion_added_title',
          'promotion_updated_message',
          'invalid_image_extention',
          'delete_x',
          'message_delete_x',
          'x_deleted'
        ]).subscribe((translations: any) => {
          self._navigationService.setSplitLayoutButtons([
            {
              'icon': 'list',
              'tooltip': self.translations['promotions'],
              'link': `/groups/${self.companyId}/${self.list}/`
            }
          ]);

          this.translations = translations;
          this._titleService.setTitle(ucFirst(translations[`${self.action}_promotion`]) + environment.windowTitleSuffix);
          this._navigationService.setBodyTitle(ucFirst(translations[`${self.action}_promotion`]));
        });

        if (this.action === 'edit') {
          this.loadData();
        }
      });

      this._couponService.getAll({'where': {'companyId': this.companyId}})
        .subscribe((coupons) => {
          self.coupons = coupons;
        });
    });
  }

  ngOnInit(): void {
    /**
     * Set up our form
     */
    this.form = this._formBuilder.group({
      name: [null, [Validators.required]],
      title: [null, [Validators.required]],
      text: [null, [Validators.required]],
      couponId: ['', []],
      startDate: [moment().format('YYYY-MM-DDTHH:mm'), [Validators.required]],
      endDate: [this.maxStartDate, [Validators.required]],
      image: [null, []],
      imageSrc: [null, []],
    }, {validators: []});

    this.conditionalFormatting();
  }

  conditionalFormatting() {
    this.form.get('endDate').setValidators([Validators.required, this.dateComparison(this.form)]);

    this.form.get('startDate').valueChanges.subscribe(
      (mode: string) => {
        this.minDate = moment(mode).format('YYYY-MM-DDTHH:mm');
      });

    this.form.get('endDate').valueChanges.subscribe(
      (mode: string) => {
        this.maxStartDate = moment(mode).format('YYYY-MM-DDTHH:mm');
      });
  }

  loadData(): void {
    // console.log(`[promotionUpsertComponent.loadData]: `);
    this._promotionService.get(this.id, {}).subscribe((promotion: Promotion) => {
      this.data = promotion;
      console.log(promotion.imageSrc);
      this.form.patchValue({
        name: promotion.name,
        title: promotion.title,
        text: promotion.text,
        couponId: (promotion.couponId ? promotion.couponId : ''),
        startDate: moment(promotion.startDate).format('YYYY-MM-DDTHH:mm'),
        endDate: moment(promotion.endDate).format('YYYY-MM-DDTHH:mm'),
        imageSrc: (promotion.imageSrc ? `${promotion.imageSrc}?${this.randString()}` : null),
      });
      this._loadingService.resolve(this.loaderName);
    }, error => {
      console.error(error);
      this._loadingService.resolve(this.loaderName);
    })
  }

  delete(): void {
    const self = this;
    const name = self.data.name;
    const message = self.translations['message_delete_x'].formatUnicorn(name);
    const title = self.translations['delete_x'].formatUnicorn(name);

    self._dialogService.openConfirm({
      message: message,
      disableClose: false,
      title: title,
      cancelButton: 'Cancel',
      acceptButton: 'Delete',
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        self._promotionService.delete(self.data.id, 'company').subscribe(() => {
          self._dialogService.openConfirm({
            message: self.translations['x_deleted'].formatUnicorn(name),
            disableClose: true,
            acceptButton: 'Ok',
          }).afterClosed().subscribe(() => {
            // alert('/groups/${self.companyId}/promotions');
            this._router.navigate([`/groups/${self.companyId}/promotions`]);
          });
        });
      }
    });
  }

  archive(id: string) {
    const self = this;
    self._promotionService.update(id, {status: 'archived'}).subscribe(() => {
      self._snackbar.open(this.translations['promotion_archived'], '', {duration: 3000});
      this.data.status = 'archived';
    });
  }

  publish(id: string) {
    const self = this;
    self._promotionService.update(id, {status: 'published'}).subscribe(() => {
      self._snackbar.open(this.translations['promotion_published'], '', {duration: 3000});
      this.data.status = 'published';
    });
  }

  save(): void {
    if (!this.currentAction) {
      const self = this;
      const data = this.form.value;
      let promo = self.data;

      delete promo.id;
      delete promo.modified;
      delete promo.created;
      promo.companyId = this.companyId;

      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          promo[key] = data[key];
        }
      }

      if (!this.form.valid) {
        return this.validateAllFormFields(this.form);
      }

      promo.language = 'en';
      promo = this.saveDataMapping(promo);
      /**
       * Call the API
       */
      if (this.action.toLowerCase() === 'add') {
        promo.status = 'published';

        this._loadingService.register(this.loaderName);
        this._promotionService.insert(promo).subscribe((result) => {
          const formData = new FormData();
          formData.append('file', this.form.get('image').value);
          if (this.form.get('image').value) {
            self._promotionService.upload(result.id, formData).subscribe(() => {
              setTimeout(function () {
                self._dialogService.openAlert({
                  message: ucFirst(self.translations['promotion_added_message']),
                  disableClose: true,
                  title: ucFirst(self.translations['promotion_added_title']),
                  closeButton: 'OK'
                }).afterClosed().subscribe(() => {
                  self._router.navigate([`/${(self.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${self.companyId}/promotions`]);
                  self.currentAction = null;
                });
              }, 500);
            }, () => {
              self._loadingService.resolve(self.loaderName);
              self._dialogService.openAlert({
                message: ucFirst(self.translations['created_invalid_image_extention']),
                disableClose: true,
                closeButton: 'OK'
              }).afterClosed().subscribe(() => {
                self._router.navigate([`/${(self.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${self.companyId}/promotions/${result.id}/edit`]);
                self.currentAction = null;
              });
            });
          } else {
            self._dialogService.openAlert({
              message: ucFirst(self.translations['promotion_added_message']),
              disableClose: true,
              title: ucFirst(self.translations['promotion_added_title']),
              closeButton: 'OK'
            }).afterClosed().subscribe(() => {
              self._router.navigate([`/${(self.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${self.companyId}/promotions`]);
              self.currentAction = null;
            });
          }
        }, error => {
          console.error(error);
          this._loadingService.resolve(this.loaderName);
        })
      } else if (this.form.get('image').value) {
        self._loadingService.register(this.loaderName);
        const formData = new FormData();
        if (this.form.get('image').value) {
          formData.append('file', this.form.get('image').value);
          self._promotionService.upload(this.id, formData).subscribe((result) => {
            promo.imageSrc = `${result.imageSrc}?${self.randString()}`;
            self._promotionService.update(this.id, promo).subscribe(() => {
              self.loadData();
              setTimeout(function () {
                self._loadingService.resolve(self.loaderName);
                self.snackBar.open(ucFirst(self.translations['promotion_updated_message']), 'OK', {
                  duration: 3000
                });
              }, 500);
            }, error => {
              self._loadingService.resolve(this.loaderName);
            });
          }, () => {
            self._loadingService.resolve(self.loaderName);
            self.snackBar.open(ucFirst(self.translations['invalid_image_extention']), 'OK', {
              duration: 3000
            });
          });
        } else {
          self._promotionService.update(this.id, promo).subscribe(() => {
            self.loadData();
            setTimeout(function () {
              self._loadingService.resolve(self.loaderName);
              self.snackBar.open(ucFirst(self.translations['promotion_updated_message']), 'OK', {
                duration: 3000
              });
            }, 500);
          }, error => {
            console.error(error);
            this._loadingService.resolve(this.loaderName);
          });
        }
      } else {
        self._loadingService.register(this.loaderName);
        self._promotionService.update(this.id, promo).subscribe(() => {
          self.loadData();
          setTimeout(function () {
            self._loadingService.resolve(self.loaderName);
            self.snackBar.open(ucFirst(self.translations['promotion_updated_message']), 'OK', {
              duration: 3000
            });
          }, 500);
        }, error => {
          console.error(error);
          this._loadingService.resolve(this.loaderName);
        });
      }
    }
  }

  makeRandom(lengthOfCode: number, possible: string) {
    let text = '';
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  randString() {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
    const lengthOfCode = 40;
    return this.makeRandom(lengthOfCode, possible)
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.patchValue({
        image: file
      });
    }
  }

  openPushNotificationDialog() {
    this._dialogService.open(PromotionPushDialogComponent, {
      maxHeight: '80%',
      width: '500px',
      data: {parent: this},
    });
  }
}
