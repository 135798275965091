<div layout="column" layout-fill>
  <mat-toolbar class="mat-whiteframe-z1" color="primary">
    <a class="top-logo" routerLink="/login">
      <mat-icon class="mat-icon-logo" svgIcon="assets:logo"></mat-icon>
    </a>
    <span> | {{'signup_successful_title'|translate}}</span>
  </mat-toolbar>
  <div class="mat-content" flex layout-padding>
    <div class="margin" layout-align-gt-xs="center start" layout-gt-xs="row">
      <div flex-gt-xs="75">
        <mat-card [mediaClasses]="['push-top-lg']" tdMediaToggle="gt-xs">
          <mat-card-title>{{'signup_successful_title'|translate}}</mat-card-title>
          <mat-divider></mat-divider>

          <mat-card-content>
            <h3 class="sub-title">
              <mat-icon>email</mat-icon>
              <span>{{'one_last_step'|translate}}</span>
              <mat-divider></mat-divider>
            </h3>
            <div [innerHTML]="signupSuccessFullStep1|keepHtml">

            </div>

            <h3 class="sub-title">
              <mat-icon>help</mat-icon>
              <span>{{'not_seeing_email'|translate|ucFirst}}</span>
              <mat-divider></mat-divider>
            </h3>
            <div [innerHTML]="signupSuccessFullStep2|keepHtml">

            </div>

            <button (click)="resendEmail()" [hideText]="true" [mtBasicSpinner]="sending" mat-button>
              {{'resend_email'|translate|ucFirst}}
            </button>
          </mat-card-content>

        </mat-card>
      </div>
    </div>
  </div>
</div>
