import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Driver} from '../../../../../../../models/driver';
import {Company} from '../../../../../../../models/company';
import {UntypedFormGroup} from '@angular/forms';
import {DriverService} from '../../../../../../../services/driver.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-ride-edit-owner',
  templateUrl: './ride-edit-owner.component.html',
  styleUrls: ['./ride-edit-owner.component.scss']
})
export class RideEditOwnerComponent implements OnInit {
  @Output() ownerNameChanged: EventEmitter<any> = new EventEmitter();
  @Input() context: string;
  @Input() driver: Driver;
  @Input() ownerName: string;
  @Input() form: UntypedFormGroup;
  @Input() myDriver: Driver;
  @Input() company: Company;
  @Input() rideId: string;

  companies: Company[] = [];
  translations: string[] = [];

  constructor(
    private _driverService: DriverService,
    private translateService: TranslateService,
  ) {
    this._driverService.getCompanies().subscribe((companies: any) => {
      companies.forEach((c) => {
        if (c.Company) {
          this.companies.push(c.Company);
        }
      });
    }, error => {
      console.error(error);
    });

    translateService.get(['me'])
      .subscribe((translations: any) => {
        this.translations = translations;
      });
  }

  onChanged(event) {
    if (event.value !== this.myDriver.id) {
      this.form.controls['ownerType'].setValue('Company', {emitEvent: true});
      this.companies.forEach((c) => {
        if (c.id === event.value) {
          this.ownerName = c.name;
        }
      })
    } else {
      this.ownerName = this.translations['me'];
      this.form.controls['ownerType'].setValue('Driver', {emitEvent: true});
    }
    this.ownerNameChanged.emit(this.ownerName);
  }

  ngOnInit(): void {
  }

}
