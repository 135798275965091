<mat-card>
  <mat-card-title>{{'recent_shifts_title' | translate | ucFirst}}</mat-card-title>
  <table class="mat-clickable" td-data-table>
    <thead>
    <tr td-data-table-column-row>
      <th (sortChange)="sort($event)"
          *ngFor="let c of columns"
          [active]="(sortBy == c.name)"
          [name]="c.name"
          [numeric]="c.numeric"
          [sortOrder]="(sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
          [sortable]="c.sortable"
          td-data-table-column>
        {{c.label|translate|ucFirst}}
      </th>
    </tr>
    </thead>
    <tbody>
    <ng-container *ngFor="let item of originalData['all'];" class="todo-assign">
      <tr class="pointer" td-data-table-row>
        <td *ngFor="let c of columns" [numeric]="c.numeric" td-data-table-cell>
            <span [ngSwitch]="c.name">
              <span *ngSwitchCase="'vehicle'"><span
                *ngIf="item.Vehicle">{{item['Vehicle']['brand']}} {{item['Vehicle']['model']}}
                ({{item['Vehicle']['licensePlate']}})</span></span>
              <span *ngSwitchCase="'startTime'"><span
                *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>
              <span *ngSwitchCase="'endTime'"><span
                *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span><span
                *ngIf="!item[c.name]">-</span></span>
              <span *ngSwitchCase="'modified'"><span *ngIf="item[c.name]">{{item[c.name] | amTimeAgo}}</span></span>
              <span *ngSwitchCase="'duration'"><span
                *ngIf="item[c.name]">{{item[c.name] | millisecondsToHours}}</span></span>
              <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span></span>
            </span>
        </td>
      </tr>
    </ng-container>
    </tbody>
  </table>
  <td-paging-bar #paging (change)="page($event)" [pageSize]="5" [total]="originalData['all'].length">
    <span hide-xs>{{'rows_p_page'|translate}}:</span>
    {{paging.range}} <span hide-xs>of {{paging.total}}</span>
  </td-paging-bar>
</mat-card>
