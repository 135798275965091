<app-zendesk-widget></app-zendesk-widget>
<div layout="column" layout-fill>
  <mat-toolbar class="mat-whiteframe-z1" color="primary">
    <a class="top-logo" routerLink="/login">
      <mat-icon class="mat-icon-logo" svgIcon="assets:logo"></mat-icon>
    </a>
    <span> | {{'login'|translate}}</span>
  </mat-toolbar>

  <div *ngIf="!clearBg" class="mat-content" flex layout-padding>
    <div class="margin" layout-align-gt-xs="center start" layout-gt-xs="row">
      <div flex-gt-xs="75">
        <mat-card [mediaClasses]="['push-top-lg']" class="login-card" tdMediaToggle="gt-xs">
          <mat-card-title>{{'login'|translate}}</mat-card-title>
          <mat-card-subtitle>{{'login_subtitle'|translate}}</mat-card-subtitle>
          <mat-card-content>
            <td-message *ngIf="showError" class="push-bottom" color="{{(error?'warn':'primary')}}"
                        icon="error" label="{{errorLabel}}" sublabel="{{errorText}}"></td-message>

            <div
              data-logo_alignment="left"
              data-shape="pill"
              data-text="continue_with"
              id="google-button">
            </div>

            <div class="container">
              <div class="line">
                <mat-divider></mat-divider>
              </div>
              <div class="text mat-typography">{{'or'|translate}}</div>
              <div class="line">
                <mat-divider></mat-divider>
              </div>
            </div>

            <form #loginForm="ngForm" (keyup.enter)="login()">
              <div layout="row">
                <mat-form-field flex>
                  <input #userControl="ngModel" #userElement [(ngModel)]="username" matInput
                         name="username" placeholder="{{'email'|translate}}" required type="text">
                  <span matPrefix><mat-icon>person</mat-icon></span>
                  <mat-hint align="start">
                    <span [hidden]="!userControl.errors?.required || userControl.pristine"
                          class="tc-red-600">{{'required'|translate}}</span>
                  </mat-hint>
                </mat-form-field>
              </div>
              <div layout="row">
                <mat-form-field flex>
                  <input #passControl="ngModel" #passElement [(ngModel)]="password" matInput
                         name="password" placeholder="{{'password'|translate}}" required type="password">
                  <span matPrefix><mat-icon>lock</mat-icon></span>
                  <mat-hint align="start">
                    <span [hidden]="!passControl.errors?.required || passControl.pristine"
                          class="tc-red-600">{{'required'|translate}}</span>
                  </mat-hint>
                </mat-form-field>
              </div>
              <a class="yda-link forgot" flex href="#"
                 routerLink="/reset">{{'forgot_password'|translate}}</a>
            </form>
          </mat-card-content>

          <mat-divider></mat-divider>

          <mat-card-actions layout="row">
            <button (click)="login();" [disabled]="!loginForm.form.valid &&
            !loginForm.pristine" color="accent" flex mat-raised-button
                    type="submit">{{'login_button'|translate}}</button>

            <button (click)="_globalFunctions.handleAuthClick();" *ngIf="false" color="accent" flex mat-raised-button
                    type="submit">{{'login_to_google'|translate}}</button>

            <span *ngIf="false" class="signup_text">
              {{'signup_button_no_account'|translate|ucFirst}}
              <a class="yda-link-blue" flex
                 routerLink="/signup">{{'signup_button'|translate|ucFirst}}</a>
              </span>
            <div class="version">
              Build v{{pjson.version}}.{{deployNumber.buildnumber}} - {{deployDate.date}}
            </div>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>
</div>
