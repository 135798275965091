<ng-template tdLoading="debtor-passenger.list">
  <td-search-box (searchDebounce)="search($event)" [alwaysVisible]="true" class="push-left-sm"
                 placeholder="{{'search_here'|translate|ucFirst}}"></td-search-box>
  <mat-divider></mat-divider>
  <table class="mat-clickable" flex="100" td-data-table>
    <thead>
    <tr td-data-table-column-row>
      <th (sortChange)="sort($event)"
          *ngFor="let c of columns"
          [active]="(sortBy == c.name)"
          [class]="c.class"
          [name]="c.name"
          [numeric]="c.numeric"
          [sortOrder]="(sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
          [sortable]="c.sortable"
          td-data-table-column>
        {{c.label|translate|ucFirst}}
      </th>
    </tr>
    </thead>
    <tbody *ngIf="this.filteredData['all'].length > 0">
    <tr *ngFor="let item of this.filteredData['all']" class="show-on-hover-row" td-data-table-row>
      <td *ngFor="let c of columns" [class]="c.class" [numeric]="item.numeric" class="{{(c.name=='tools'?'text-right':'')}}"
          td-data-table-cell>
            <span [ngSwitch]="c.name">
              <span *ngSwitchCase="'phoneNumber'" class="show-on-hover">
                <div class="hide-mouse-over">{{item[c.name]}}</div>
                <button (click)="this.addPassengerToDebtor(item.id)" class="push-top push-right" color="primary" flex
                        mat-raised-button>{{'add_passenger_to_debtor'|translate|ucFirst}}</button>
              </span>
              <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span><span
                *ngIf="!item[c.name]">-</span></span>
            </span>
      </td>
    </tr>
    </tbody>
  </table>
  <td-paging-bar #paging (change)="page($event)" [pageSize]="10" [total]="filteredTotals['all']">
    <span hide-xs>{{'rows_p_page'|translate}}:</span>
    {{paging.range}} <span hide-xs>{{'of' | translate}} {{paging.total}}</span>
  </td-paging-bar>
</ng-template>
<mat-divider></mat-divider>
<mat-card-actions>
<button (click)="this.addNewPassenger()" class="push-top push-right" color="accent" flex
        mat-raised-button>{{'create_new_contact'|translate|ucFirst}}</button>
</mat-card-actions>
