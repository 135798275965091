import {PostAddress} from './post-address';

export class Debtor {
  id: string;
  code: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
  note?: string;
  emailAddress?: string;
  phoneNumber?: string;
  address?: PostAddress;
  companyId?: string;
  created?: string;
  modified?: string;
  ownerId?: string;
  ownerType?: string;

  constructor() {
    this.id = '';
    this.code = '';
    this.address = {};
  }
}
