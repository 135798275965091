<mat-card [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <mat-card-title>{{'title_install_tps_error'|translate|ucFirst}}</mat-card-title>
  <!--<mat-card-subtitle>{{'subtitle_install_tps_error'|translate|ucFirst}}</mat-card-subtitle>-->
  <mat-divider></mat-divider>
  <mat-card-content>{{'text_install_tps_error'|translate|ucFirst}}</mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <a (click)="install()" color="accent" mat-raised-button>
      <span>{{'install_tps'|translate|ucFirst}}</span>
    </a>
  </mat-card-actions>
</mat-card>
