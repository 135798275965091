<app-no-contract *ngIf="(this.context==='company' && (!this.company.ActiveContract ||
!['active','past_due', null].includes(this.company.ActiveContract.foreignStatus)))"
                 [companyId]="this.companyId" [contract]="this.company.ActiveContract"></app-no-contract>
<mat-card tdMediaToggle="gt-xs">
  <ng-template tdLoading="exports.list">
    <table class="mat-clickable" td-data-table>
      <thead>
      <tr td-data-table-column-row>
        <th (sortChange)="sort($event)"
            *ngFor="let c of columns"
            [active]="(sortBy == c.name)"
            [class]="''+c.maxSize"
            [name]="c.name"
            [numeric]="c.numeric"
            [sortOrder]="(sortOrder == 'ASC' ? 'DESC' : 'ASC' )"
            [sortable]="c.sortable"
            td-data-table-column>
          {{ c.label|translate|ucFirst }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr (click)="openEdit($event, item.id)" *ngFor="let item of this.filteredData['all']"
          class="pointer" td-data-table-row>
        <td *ngFor="let c of columns" [numeric]="item.numeric" td-data-table-cell>
            <span [ngSwitch]="c.name">
              <span *ngSwitchCase="'startDate'"><span
                *ngIf="item[c.name]">{{ item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm') }}</span></span>
              <span *ngSwitchCase="'endDate'"><span
                *ngIf="item[c.name]">{{ item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm') }}</span></span>

              <span *ngSwitchCase="'lastPushDate'"><span
                *ngIf="item[c.name]">{{ item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm') }}</span></span>

              <span *ngSwitchDefault><span *ngIf="item[c.name]">{{ item[c.name] }}</span></span>
            </span>
        </td>
      </tr>
      </tbody>
    </table>
  </ng-template>
</mat-card>
