import {AfterViewInit, Component, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Debtor} from '../../../../../models/debtor';
import {TdDialogService} from '@covalent/core/dialogs';
import {TdLoadingService} from '@covalent/core/loading';
import {DebtorService} from '../../../../../services/debtor.service';
import {environment} from '../../../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {NavigationService} from '../../../../../services/navigation.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ucFirst} from '../../../../../pipes/uc-first.pipe';
import {ValidateAllFormFields} from '../../../../../functions/validateAllFormFields';
import {CountryISO, SearchCountryField,} from 'ngx-intl-tel-input';
import {Company} from '../../../../../models/company';
import {UtilityService} from '../../../../../services/utility.service';
import {Passenger} from '../../../../../models/passenger';
import {PassengerService} from '../../../../../services/passenger.service';
import {debounceTime} from 'rxjs/operators';
import {Subject} from 'rxjs/Rx';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DaAppInstallService} from '../../../../../services/da-app-install.service';
import {WebbookerService} from '../../../../../services/webbooker.service';
import {CoolLocalStorage} from '@angular-cool/storage';

@Component({
  selector: 'app-company-debtor-upsert',
  templateUrl: './company-debtor-upsert.component.html',
  styleUrls: ['./company-debtor-upsert.component.scss'],
  providers: [DebtorService, PassengerService]
})
export class CompanyDebtorUpsertComponent extends ValidateAllFormFields implements AfterViewInit, OnInit, OnDestroy {
  @Input() parent;
  @Input() company: Company;
  @ViewChild('autoCompleteServiceHolder') autoCompleteServiceHolder;
  loaderName = 'debtor';
  routeSubscription: Subscription;
  translations: string[] = [];
  localTranslations: string[] = [];
  SearchCountryField = SearchCountryField;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom
  ];
  id: string;
  companyId: string;
  type: string;
  createCheckbox: boolean;

  action: string;
  createNewContact = false;
  debtor: Debtor = new Debtor();
  form: UntypedFormGroup;
  contacts: any[] = [];
  filteredContacts: any[] = [];
  formErrors: any = {};
  myControl: UntypedFormControl = new UntypedFormControl();

  validationMessages = {
    'firstName': {
      'fieldName': 'first_name',
      'required': 'form_validation_required',
    },
    'lastName': {
      'fieldName': 'last_name',
      'required': 'form_validation_required',
    },
    'phoneNumber': {
      'fieldName': 'phonenumber',
      'required': 'form_validation_required',
      'validatePhoneNumber': 'phonenumber_pattern_invalid',
    },
  };
  input = new Subject<string>();

  constructor(public snackBar: MatSnackBar,
              private _loadingService: TdLoadingService,
              private _formBuilder: UntypedFormBuilder,
              private _route: ActivatedRoute,
              private _router: Router,
              private _translateService: TranslateService,
              private _daAppInstallService: DaAppInstallService,
              private _webbookerService: WebbookerService,
              private _titleService: Title,
              private _dialogService: TdDialogService,
              private _vault: CoolLocalStorage,
              private _navigationService: NavigationService,
              private _passengerService: PassengerService,
              private _debtorService: DebtorService,
              public dialogRef: MatDialogRef<CompanyDebtorUpsertComponent>,
              @Inject(MAT_DIALOG_DATA) public dialogData: any) {
    super();
    this.loadErrorTranslations(_translateService);
    const self = this;
    if (!self.dialogData || self.dialogData.length === 0) {
      const {company} = this._route.parent.snapshot.data;
      UtilityService.setBrowserTimeStamp(this.company);
      this.company = company;
      this.routeSubscription = this._route.parent.params.subscribe(parentParams => {

        this.companyId = parentParams['id'];
        this.routeSubscription = this._route.params.subscribe(params => {
          this.id = params['id'];
          this.type = params['type'];
          this.action = params['action'] ? params['action'] : params['id'];
          if (this.action === 'edit') {
            this.loadData();
          }
        });
      });
    }

    _translateService.get([
      'new_account_select_main_contact_error_title',
      'new_account_select_main_contact_error_message',
      'passenger_duplicate_phone_error_message',
      'passenger_duplicate_email_error_message',
      'passenger_duplicate_phone_error_title',
      'passenger_duplicate_email_error_title',
      'contact_duplicate_error_message',
      'contact_duplicate_error_title',
      `${this.action}_debtor`,
      'debtor_account_added_message',
      'debtor_account_added_title',
      'debtor_added_message',
      'debtor_added_title',
      'debtor_updated_message',
      'delete_x',
      'message_delete_x',
      'x_deleted',
      'cancel',
      'ok',
      'delete'
    ]).subscribe((translations: any) => {
      this.translations = translations;
      this.localTranslations = translations;
      this._titleService.setTitle(ucFirst(translations[`${this.action}_debtor`]) + environment.windowTitleSuffix);
      this._navigationService.setBodyTitle(ucFirst(translations[`${this.action}_debtor`]));
    });
    _navigationService.setSplitLayoutButtons([
      {
        'icon': 'list',
        'tooltip': this.translations['debtors'],
        'link': `/groups/${this.companyId}/debtors/tab/accounts`
      }
    ]);
  }

  ngOnInit(): void {
    const self = this;
    if (!self.company && self.parent) {
      self.company = self.parent.company;
      self.companyId = self.parent.company.id;
    }

    if (self.company && !self.companyId) {
      self.companyId = self.company.id;
    }

    self._passengerService.getAll({where: {realm: self.companyId}, 'order': 'fname asc'}).subscribe((contacts: Passenger[]) => {
      self.contacts = contacts.filter((c) => {
        return (c.email)
      });

      self.contacts = self.contacts.map((c) => {
        c.displayName = `${c.fname} ${c.lname} - ${c.email}`;
        return (c)
      });
    })

    if (!this.action) {
      this.action = 'add';
    }

    if (this.action === 'add') {
      this._loadingService.register(this.loaderName);
    }
    this.initForm();
  }

  ngAfterViewInit(): void {
    const setSearch = ['contactName'];
    setSearch.forEach((s) => {
      const input = document.querySelector(`#${s} input[type=text]`);
      if (input) {
        input.setAttribute('type', 'search');
      }
    });
  }

  initForm() {
    const self = this;
    /**
     * Set up our form
     */
    self.form = this._formBuilder.group({
      firstName: [this.debtor.firstName, []],
      lastName: [this.debtor.lastName, []],
      companyName: [this.debtor.companyName, [Validators.required]],
      contact: ['', []],
      createNewContact: [null, []],
      note: [this.debtor.note, []],
      emailAddress: [this.debtor.emailAddress, []],
      phoneNumber: [this.debtor.phoneNumber, []],
      address: this._formBuilder.group({
        city: [(this.debtor.address ? this.debtor.address.city : ''), []],
        streetName: [(this.debtor.address ? this.debtor.address.streetName : ''), []],
        postalCode: [(this.debtor.address ? this.debtor.address.postalCode : ''), []],
        houseNumber: [(this.debtor.address ? this.debtor.address.houseNumber : ''), []],
        description: [(this.debtor.address ? this.debtor.address.description : ''), []],
      })
    }, {validators: []});

    self.form.controls['contact'].valueChanges.pipe(
      debounceTime(150))
      .subscribe(q => {
        self.search(q);
      });
  }

  search(term: string) {
    const self = this;
    self.filteredContacts = [];
    self.searchContacts(term)
      .then(results => {
        self.filteredContacts = self.filteredContacts.concat(results);
      })
  }

  searchContacts(term: string): Promise<any[]> {
    return new Promise((resolve) => {
      const results = [];
      const regex = new RegExp(term, 'gi');
      this.contacts.forEach(driver => {
        const nameMatch = driver.fname.match(regex);
        const codeMatch = driver.lname.match(regex);
        const fullMatch = `${driver.fname} ${driver.lname}`.match(regex);
        let phoneMatch
        if (driver.email) {
          phoneMatch = driver.email.match(regex);
        }
        if (nameMatch) {
          results.push(driver);
        } else if (codeMatch) {
          results.push(driver);
        } else if (fullMatch) {
          results.push(driver);
        } else if (phoneMatch) {
          results.push(driver);
        }
      });
      return resolve(results);
    });
  }

  displayFn(option: Passenger): string {
    if (option && option.fname && option.email) {
      return `${option.fname} ${option.lname} - ${option.email}`;
    } else {
      return '';
    }
  }

  loadData(): void {
    this._debtorService.get(this.id, {}).subscribe((debtor: Debtor) => {
      this.debtor = debtor;
      this.initForm();
      this._loadingService.resolve(this.loaderName);
    }, error => {
      console.error(error);
      this._loadingService.resolve(this.loaderName);
    })
  }

  deleteDebtor(): void {
    const self = this;
    const name = `${this.debtor.firstName} ${this.debtor.lastName} (${this.debtor.companyName})`;
    const message = self.translations['message_delete_x'].formatUnicorn(name);
    const title = self.translations['delete_x'].formatUnicorn(name);

    self._dialogService.openConfirm({
      message: message,
      disableClose: false,
      title: title,
      cancelButton: this.translations['cancel'],
      acceptButton: this.translations['delete']
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        self._debtorService.delete(self.debtor.id, 'company').subscribe(() => {
          self._dialogService.openConfirm({
            message: self.translations['x_deleted'].formatUnicorn(name),
            disableClose: true,
            acceptButton: self.translations['ok'],
          }).afterClosed().subscribe(() => {
            alert('/groups/${self.companyId}/debtors');
            this._router.navigate([`/groups/${self.companyId}/debtors`]);
          });
        });
      }
    });
  }

  save(): void {
    const self = this;
    this._loadingService.register();
    const data = JSON.parse(JSON.stringify(this.form.value));
    const debtor = this.debtor;
    let createNewContact = false;
    let currentContact;

    delete debtor.id;
    delete debtor.modified;
    delete debtor.created;

    if (data.contact) {
      currentContact = data.contact;
    }
    createNewContact = data.createNewContact;

    delete data.contact;
    delete data.createNewContact;

    Object.keys(data).forEach(key => {
      if (data.hasOwnProperty(key)) {
        debtor[key] = data[key];
      }
    });

    if (data.phoneNumber && data.phoneNumber.e164Number) {
      debtor.phoneNumber = data.phoneNumber.e164Number;
    }

    this.validateAllFormFields(this.form);

    if (!this.form.valid && false) {
      this._loadingService.resolve();
      return;
    }

    debtor.ownerId = this.companyId;
    debtor.ownerType = 'Company';

    /**
     * Call the API
     */

    if (this.action.toLowerCase() === 'add') {
      console.log(createNewContact);
      console.log(currentContact);
      if (!createNewContact && (!currentContact || !currentContact.id)) {
        self._dialogService.openAlert({
          title: ucFirst(self.localTranslations['new_account_select_main_contact_error_title']),
          message: ucFirst(self.localTranslations['new_account_select_main_contact_error_message']),
          disableClose: true,
          closeButton: self.translations['ok']
        }).afterClosed().subscribe(() => {
          self._loadingService.resolve();
        });
        return;
      } else {
        self._passengerService.getAll({'where': { 'or': [{companyId: self.companyId, phoneNumber: debtor.phoneNumber}, {companyId: self.companyId, email: debtor.emailAddress}, {username: `${debtor.emailAddress}_${self.companyId}`}]}})
          .subscribe((existingDriver: any) => {

            if (existingDriver && createNewContact && existingDriver.length > 0) {
              self._loadingService.resolve();
              self.passengerError({
                status: 409,
                error: {
                  error: {
                    name: (existingDriver[0].username && existingDriver[0].username === `${debtor.emailAddress}_${self.companyId}` ? 'DUPLICATE_EMAIL' : 'DUPLICATE_PHONENUMBER')
                  }
                }
              });
            } else {
              self._debtorService.insert(debtor).subscribe((d) => {
                if (!createNewContact) {
                  self._passengerService.debtorInsert({
                    type: 'owner',
                    debtorId: d.id,
                    passengerId: currentContact.id
                  }).subscribe(() => {
                    self.addedPassenger(d);
                  });
                } else {
                  self.createNewPassenger(d, {
                    companyId: self.companyId,
                    firstName: debtor.firstName,
                    lastName: debtor.lastName,
                    emailAddress: debtor.emailAddress,
                    phoneNumber: debtor.phoneNumber
                  })
                }

                self._dialogService.openAlert({
                  message: ucFirst(self.translations['debtor_account_added_message']),
                  disableClose: true,
                  title: ucFirst(self.translations['debtor_account_added_title']),
                  closeButton: self.translations['ok']
                }).afterClosed().subscribe(() => {
                  self._router.navigate([`/${(self.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${self.companyId}/debtors/${d.id}/view`]);
                });
              })
            }
          }, error => {
            console.error(error);
            this._loadingService.resolve();
          })
      }
    } else {
      this._debtorService.update(this.id, debtor).subscribe(() => {
        setTimeout(function () {
          self._loadingService.resolve();
          const notification = self.snackBar.open(ucFirst(self.translations['debtor_updated_message']), 'OK', {
            duration: 3000
          });
          self._router.navigate([`/${(self.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${self.companyId}/debtors`]);
        }, 500);
      }, error => {
        console.error(error);
        self._loadingService.resolve();
      })
    }
  }

  createNewPassenger(debtor, passenger) {
    const self = this;
    const user: any = this._vault.getObject(`${environment.vaultPrefix}.user`);

    self._daAppInstallService.getAll({
      where: {
        daAppId: environment.webbookerDaAppId,
        companyId: self.company.id,
      }
    }).subscribe((bookers) => {
      if (bookers.length === 0) {
        self._loadingService.resolve();

        // Open an alert dialog if no webbooker is found
        self._dialogService.openAlert({
          message: ucFirst(self.translations['no_webbooker_found']),
          disableClose: true,
          title: ucFirst(self.translations['no_webbooker_found_title']),
          closeButton: self.translations['ok']
        }).afterClosed().subscribe(() => {

        });
      } else {
        bookers.map((booker) => {
          if (booker.formId) {
            // Get the JWT token for the webbooker
            booker.JWTtoken = self._vault.getItem(`${environment.vaultPrefix}.${booker.formId}.jwtToken`);
            if (!booker.JWTtoken) {
              // Refresh the token if it doesn't exist
              self._daAppInstallService.refreshToken(booker.formId)
                .then((token) => {
                  booker.JWTtoken = token;
                  self._vault.setItem(`${environment.vaultPrefix}.${booker.formId}.jwtToken`, token);
                });
            }

            // Set the URL for the webbooker
            booker.url = `${environment.orderFormFrontEndUrl}/dashboard/${user.locale.toLowerCase()}/${booker.formId}`;

            // Make an API call to retrieve the webbooker data
            return self._webbookerService.get(booker.formId, {}, {'Authorization': `Bearer ${booker.JWTtoken}`})
              .subscribe((b) => {
                // Set the branding information for the passenger
                passenger.branding = {
                  name: self.company.name,
                  logo: b.config.logo,
                  url: booker.url,
                  sendAccountEmail: true,
                };

                // Insert the passenger into the database
                self._passengerService.insert({
                  companyId: self.companyId,
                  fname: passenger.firstName,
                  lname: passenger.lastName,
                  email: passenger.emailAddress,
                  phoneNumber: passenger.phoneNumber
                }).subscribe((p) => {
                  // Insert the debtor into the database
                  self._passengerService.debtorInsert({
                    type: 'owner',
                    debtorId: debtor.id,
                    passengerId: p.id
                  }).subscribe(() => {
                    if(self.parent) {
                      // Refresh the debtor list
                      self.parent.loadDebtors();
                      // Set the debtor ID in the form
                      self.parent.form.controls['debtorId'].setValue('debtor-' + debtor.id);
                      // Select the debtor in the list
                      self.parent.selectDebtor();
                      // Close the dialog
                      self.parent.dialog.close();
                    }
                    // Resolve the loading service
                    self._loadingService.resolve();
                  }, error => {
                    console.error(error);
                    self._loadingService.resolve();
                  });
                }, error => {
                  console.log(error);
                  if (error && error.error && error.error.error.message) {
                    self._dialogService.openAlert({
                      message: error.error.error.message,
                      disableClose: true,
                      title: ucFirst(self.translations['error']),
                      closeButton: self.translations['ok']
                    }).afterClosed().subscribe(() => {
                      self._loadingService.resolve();
                    });
                  }
                });
              }, error => {
                console.log(error);
                if (error && error.error && error.error.error.message) {
                  self._dialogService.openAlert({
                    message: error.error.error.message,
                    disableClose: true,
                    title: ucFirst(self.translations['error']),
                    closeButton: self.translations['ok']
                  }).afterClosed().subscribe(() => {
                    self._loadingService.resolve();
                  });
                }
              });
          }
        });
      }
    });
  }

  navigate(direction) {

  }

  addedPassenger(d): void {
    const self = this;
    setTimeout(function () {
      self._dialogService.openAlert({
        message: ucFirst(self.translations['debtor_added_message']),
        disableClose: true,
        title: ucFirst(self.translations['debtor_added_title']),
        closeButton: self.translations['ok']
      }).afterClosed().subscribe(() => {
        if (self.parent && self.parent.dialog) {
          self.parent.loadDebtors();
          self.parent.form.controls['debtorId'].setValue('debtor-' + d.id);
          self.parent.selectDebtor();
          self.parent.dialog.close();
        } else {
          self._router.navigate([`/${(self.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${self.companyId}/debtors`]);
        }
        self._loadingService.resolve();
      }, error => {
        console.error(error);
        self._loadingService.resolve();
      });
    }, 500);
  }

  passengerError(error): void {
    const self = this;
    if (error.status === 409 && error.error.error.name === 'DUPLICATE_PHONENUMBER') {
      self._dialogService.openAlert({
        message: ucFirst(self.translations['passenger_duplicate_phone_error_message']),
        disableClose: false,
        title: ucFirst(self.translations['passenger_duplicate_phone_error_title']),
        closeButton: self.translations['ok']
      });
    } else if (error.status === 409 && error.error.error.name === 'DUPLICATE_EMAIL') {
      self._dialogService.openAlert({
        message: ucFirst(self.translations['passenger_duplicate_email_error_message']),
        disableClose: false,
        title: ucFirst(self.translations['passenger_duplicate_email_error_title']),
        closeButton: self.translations['ok']
      });
    } else if (error.status === 422 && error.error.error.name === 'ValidationError') {
      if (error.error.error.details && error.error.error.details.codes && error.error.error.details.codes.email) {
        self._dialogService.openAlert({
          message: ucFirst(self.translations['contact_duplicate_error_title']),
          disableClose: false,
          title: ucFirst(self.translations['contact_duplicate_error_message']),
          closeButton: self.translations['ok']
        });
      }
    }
  }

  updateValidators() {
    const self = this;
    if (self.form) {
      if (self.form.controls['createNewContact'].value) {
        /**
         * Make firstName, lastName, Email, phoneNumber required
         */
        self.form.controls['firstName'].setValidators([Validators.required]);
        self.form.controls['lastName'].setValidators([Validators.required]);
        self.form.controls['emailAddress'].setValidators([Validators.required]);
        self.form.controls['phoneNumber'].setValidators([Validators.required]);
      } else {
        /**
         * Remove validators
         */
        self.form.controls['firstName'].setValidators(null);
        self.form.controls['lastName'].setValidators(null);
        self.form.controls['emailAddress'].setValidators(null);
        self.form.controls['phoneNumber'].setValidators(null);
      }
    }
  }

  ngOnDestroy() {
    this._navigationService.setSplitLayoutButtons([]);
  }
}
