import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../../../environments/environment';
import {Company} from '../../../../../models/company';
import {Contract} from '../../../../../models/contract';
import {ActivatedRoute} from '@angular/router';
import {ContractService} from '../../../../../services/contract.service';
import {TdLoadingService} from '@covalent/core/loading';
import {CoolLocalStorage} from '@angular-cool/storage';
import {TdDialogService} from '@covalent/core/dialogs';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {NavigationService} from '../../../../../services/navigation.service';
import {CompanyWebUserService} from '../../../../../services/companyWebUser.service';
import {CompanyService} from '../../../../../services/company.service';
import {UtilityService} from '../../../../../services/utility.service';
import {GlobalFunctions} from '../../../../../functions/functions';
import moment from 'moment';

@Component({
  selector: 'app-license-overview',
  templateUrl: './license-overview.component.html',
  styleUrls: ['./license-overview.component.scss']
})
export class LicenseOverviewComponent implements OnInit {
  context = 'company';
  @Input() company: Company;
  paymentResult: string;
  sessionId: string;
  translations: any[] = [];
  public activeContract = new Contract();
  products = environment.products;
  productArray = ['webbooker', 'webbookerPlus', 'fleetSolutions'];
  featureArray = [
    'title|webbooker',
    'responsive_layout',
    'widget',
    'customer_email_confirmations',
    'fare_calculations',
    'stripe_integration',
    'custom_products',
    'customer_ratings',
    'customer_coupons',
    'customer_portal',
    '',
    'title|dispatchpanel',
    'operator_notifications',
    'manage_customers',
    'assign_bookings',
    'whatsapp_telegram_integrations',
    'gps_tracking_assigned',
    'dispatchpanel_create_jobs',
    'gps_tracking_fleet',
    'free_updates',
    'sms_integrations',
    'api',
    'mobile_dispatch_app',
    'driver_app',
    '',
    'title|price_per_month',
    'bold|billing_cycle',
    'bold|setup_costs',
    'bold|bookings_included',
    'bold|extra_bookings',
  ];

  constructor(
    private route: ActivatedRoute,
    private _contractService: ContractService,
    public loadingService: TdLoadingService,
    private _vault: CoolLocalStorage,
    private _dialogService: TdDialogService,
    private _translateService: TranslateService,
    private _titleService: Title,
    private _navigationService: NavigationService,
    private _companyWebUserService: CompanyWebUserService,
    private _companyService: CompanyService,
    public _globalFunctions: GlobalFunctions
  ) {
    this._globalFunctions.loadScript(`https://js.stripe.com/v3/`, this);

    if (this.route && this.route.parent && this.route.parent.snapshot) {
      const {company} = this.route.parent.snapshot.data;
      UtilityService.setBrowserTimeStamp(company);
      this.company = company;
    }

    _translateService.get([
      'ok',
      'cloud_connect_title',
      'product_price_description_',
      'product_price_description_',
      'product_price_description_',
      'cloud_connect_convert',
      'cloud_connect_dialog_success',
      'cloud_connect_licensekey_error',
      'cloud_connect_extended_dispatcher_activated',
      'cloud_connect_extended_dispatcher_deactivated',
      'cloud_connect_googlekey_error',
      'cloud_connect_cancel_at_period_end_message',
      'cloud_connect_auto_renew_contract_message',
      'cloud_connect_purchased_title',
      'cloud_connect_purchased_text'
    ]).subscribe((translations: string[]) => {
      this.translations = translations;
      this._titleService.setTitle(translations['cloud_connect_title'] + environment.windowTitleSuffix);
      this._navigationService.setBodyTitle(translations['cloud_connect_title']);
    });
    this.context = 'company';
  }

  ngOnInit(): void {
  }

  initPayment(productPriceId, productName) {
    if (!this.company.id) {
      throw Error('No companyId provided');
      return;
    }

    // @ts-ignore
    // tslint:disable-next-line:max-line-length
    const user = this._vault.getObject(`${environment.vaultPrefix}.user`);
    const paymentObject: any = {
      'companyId': this.company.id,
      'customer_email': user['email'],
      'client_reference_id': `${this.translations[`product_price_description_${productName}`]}`,
      'locale': 'auto',
      'line_items': [{
        'price': `${productPriceId}`,
        'quantity': 1,
        'dynamic_tax_rates': environment.stripeTaxRates
      }
      ],
      'mode': 'subscription',
      'success_url': `${environment.portalUrl}/groups/${this.company.id}/contract`,
      'cancel_url': `${environment.portalUrl}/groups/${this.company.id}/license/cancel`,
    };

    if (productName === 'webbookerPlus' && this.company && this.company.ActiveContract && this.company.ActiveContract.type === 'webbooker_license' &&
      moment(this.company.ActiveContract.currentPeriodStart).isAfter(moment().subtract('2', 'months'))
    ) {
      paymentObject.discounts = [
        {
          coupon: environment.products[productName].upgradeId,
        },
      ];
    } else {
      paymentObject.allow_promotion_codes = true;
    }

    // @ts-ignore
    this._contractService.createPaymentSession(paymentObject)
      .subscribe((result) => {
        this.sessionId = result.sessionId;
        // @ts-ignore
        // tslint:disable-next-line:max-line-length
        const stripe = Stripe(`${environment.stripeKey}`);
        stripe.redirectToCheckout({sessionId: this.sessionId});
      }, (error) => {
        console.log(`[createPaymentSession.onSubmit]: error`, error);
      })
  }
}
