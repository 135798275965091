import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {IPageChangeEvent} from '@covalent/core/paging';
import {ITdDataTableSortChangeEvent, TdDataTableSortingOrder} from '@covalent/core/data-table';
import {TdLoadingService} from '@covalent/core/loading';
import {TdMediaService} from '@covalent/core/media';
import {RatingService} from '../../../services/rating.service';
import {CoolLocalStorage} from '@angular-cool/storage';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';
import {Rating} from '../../../models/rating';
import {UtilityService} from '../../../services/utility.service';
import {CollectionComponent} from '../../global/collection/collection.component';
import {ucFirst} from '../../../pipes/uc-first.pipe';
import {TdDialogService} from '@covalent/core/dialogs';

@Component({
  selector: 'app-ratings',
  templateUrl: './ratings.component.html',
  styleUrls: ['./ratings.component.scss'],
  providers: [RatingService]
})
export class RatingsComponent extends CollectionComponent implements OnInit, OnDestroy {
  static translations: string[];
  driver: any;
  commentMaxLength = 40;
  activeTab = 0;
  maxScore: any[] = new Array(5);
  companyId: string;

  ratingData: any[] = [];
  ratingFilteredData: any[] = [];
  ratingFilteredTotal: number;

  ratingSearchTerm = '';
  ratingFromRow = 1;
  ratingCurrentPage = 1;
  ratingPageSize = 20;
  ratingSortBy = 'created';
  ratingSortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;

  columns = [
    {name: 'created', label: 'created', sortable: true, active: true},
    {name: 'score', label: 'score', sortable: false, active: false, fixedSize: 'w-216'},
    {name: 'comment', label: 'comment', sortable: false, active: false, maxSize: 'maxSize1440'},
    {name: 'driverName', label: 'driver', sortable: false, active: false},
    {name: 'ratedBy', label: 'rated_by', sortable: false, active: false, maxSize: 'maxSize1024'},
    {name: 'reference', label: 'reference', sortable: false, active: false},
  ];

  constructor(public media: TdMediaService,
              public _dialogService: TdDialogService,
              public _translateService: TranslateService,
              protected injector: Injector,
              private loadingService: TdLoadingService,
              private ratingService: RatingService,
              private vault: CoolLocalStorage,
              private _titleService: Title) {
    super(injector);

    _translateService.get(['ratings', 'ratings_popup_add_title', 'ratings_popup_add_text', 'ok']).subscribe((translations: any) => {
      this.translations = translations;
      RatingsComponent.translations = translations;
      _titleService.setTitle(ucFirst(translations['ratings'] + environment.windowTitleSuffix));
      this._navigationService.setBodyTitle(ucFirst(translations['ratings']));
      this._navigationService.setInfoAction(this.showAlert);
    });
    const tab = this._route.snapshot.params.tab;
    this.activeTab = (!tab ? 0 : (tab === 'passenger' ? 0 : 1));
    this.companyId = this._route.parent.snapshot.paramMap.get('id');

    this.context = this._route.routeConfig['context'] || 'driver';
    if (this.context) {
      const {company} = this._route.parent.snapshot.data;
      UtilityService.setBrowserTimeStamp(company);
      this.company = company;
      this.setBusinessContract(vault);
    }
  }

  ngOnDestroy() {
    this._navigationService.setActionLink('');
    this._navigationService.setInfoAction('');
  }

  ngOnInit() {
    this.driver = this.vault.getObject(`${environment.vaultPrefix}.driver`);
    this.loadingService.register('ratings.list');

    this.loadData();
  }

  showAlert() {
    this._dialogService.openAlert({
      title: RatingsComponent.translations['ratings_popup_add_title'],
      message: RatingsComponent.translations['ratings_popup_add_text'],
      disableClose: true,
      closeButton: RatingsComponent.translations['ok'],
    }).afterClosed();
  }

  search(searchTerm: string): void {
    this.ratingSearchTerm = searchTerm;
    this.ratingFilter();
  }

  ratingSort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.ratingSortBy = sortEvent.name;
    this.ratingSortOrder = sortEvent.order;

    if (sortEvent.name === 'created') {
      this.ratingFilteredData = UtilityService.tableDateSort(this.ratingFilteredData, 'created', sortEvent.order);
    } else {
      this.ratingFilter();
    }
  }

  ratingPage(pagingEvent: IPageChangeEvent): void {
    this.ratingFromRow = pagingEvent.fromRow;
    this.ratingCurrentPage = pagingEvent.page;
    this.ratingPageSize = pagingEvent.pageSize;
    this.ratingFilter();
  }

  ratingFilter(): void {
    let newData: any[] = this.ratingData;
    newData = this._dataTableService.filterData(newData, this.ratingSearchTerm, true);
    this.ratingFilteredTotal = newData.length;
    if (this.ratingSortBy === 'created') {
      newData = UtilityService.tableDateSort(newData, 'created', this.ratingSortOrder);
    } else {
      newData = this._dataTableService.sortData(newData, this.ratingSortBy, this.ratingSortOrder);
    }
    newData = this._dataTableService.pageData(newData, this.ratingFromRow, this.ratingCurrentPage * this.ratingPageSize);
    this.ratingFilteredData = newData;
  }

  loadData = () => {
    const query = {
      where: {
        or: [
          {
            ownerId: this.companyId
          },
          {
            creatorId: this.companyId
          }
        ]
      },
      include: [
        {'Ride': 'Owner'},
        'Passenger',
        'Owner',
        'Creator',
        'Driver'
      ]
    };

    this.ratingService.getAll(query).subscribe((ratings: Rating[]) => {
      const self = this;
      this.ratingData = [];
      this.ratingFilteredData = [];

      ratings.forEach((rating, i) => {
        let jobOwner = '';
        if (rating.Ride.Owner) {
          if (rating.Ride.Owner.type === 'driver') {
            jobOwner = `${rating.Ride.Owner.fname} ${rating.Ride.Owner.lname}`;
          } else {
            jobOwner = `${rating.Ride.Owner.name}`;
          }
        }
        let ratedBy = 'Passenger';
        if (rating.Creator) {
          if (rating.creatorType === 'Driver' || rating.creatorType === 'Passenger') {
            ratedBy = `${rating.Creator.fname} ${rating.Creator.lname}`;
          } else {
            ratedBy = `${rating.Creator.name}`;
          }
        }
        const r = {
          jobOwner,
          ratedBy,
          reference: rating.Ride.reference,
          rideId: rating.Ride.id,
          score: rating.score,
          commentShort: (rating.comment ? rating.comment.substr(0, self.commentMaxLength) : ''),
          comment: rating.comment,
          driverName: rating.Driver ? `${rating.Driver.fname || ''} ${rating.Driver.lname || ''}` : '',
          passenger: (rating.Passenger ? rating.Passenger.fname + ' ' + rating.Passenger.lname : ''),
          company: (rating.Company ? rating.Company.name : ''),
          created: rating.created,
          type: rating.creatorType,
        };
        this.ratingData.push(r);
      });

      this.ratingFilteredData = this.ratingData;
      this.ratingFilter();
      this.loadingService.resolve('ratings.list');
    }, (error => {
      console.error(error);
      this.loadingService.resolve('ratings.list');
    }));
  }

  openRide(id: string) {
    this._router.navigate([`/groups/${this.companyId}/jobs/${id}`]);
  }
}
