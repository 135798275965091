<div [formGroup]="form">
  {{ 'percentage_of_amount_explain' | translate }}
  <div flex="100" layout="row" formArrayName="passengerPercentage">
    <div class="column">
      <div *ngFor="let x of [1,2,3,4]; let i = index">
        {{ 'passenger' | translate | ucFirst }} {{ ((i + 1)) }}:
        <mat-form-field class="push-right price-input"
                        flex
                        floatLabel="never">
          <input
            [formControlName]="x-1"
            autocomplete="off"
            matInput
            min="0"
            max="100"
            placeholder="{{'percentage' | translate | ucFirst}}"
            step='1' type="number">
        </mat-form-field>
        %
      </div>
    </div>

    <div class="column">
      <div *ngFor="let x of [5,6,7,8]; let i = index">
        {{ 'passenger' | translate | ucFirst }} {{ ((x)) }}:
        <mat-form-field class="push-right price-input"
                        flex
                        floatLabel="never">
          <input
            [formControlName]="x-1"
            autocomplete="off"
            matInput
            min="0"
            max="100"
            placeholder="{{'percentage' | translate | ucFirst}}"
            step='1' type="number">
        </mat-form-field>
        %
      </div>
    </div>
  </div>
</div>
