import {Component} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-personal-license',
  templateUrl: './personal-license.component.html',
  styleUrls: ['./personal-license.component.scss']
})
export class PersonalLicenseComponent {

  constructor() {
  }

  validatePersonalLicense(_userService, _vault, translations, _dialogService, router) {
    const user = _vault.getObject(`${environment.vaultPrefix}.user`);
    _userService.getYdaDriver(user.id, {
      'include': [{
        'relation': 'Installation',
        'scope': {
          'include': {
            'relation': 'Subscription',
            'scope': {
              'where': {
                'expire': {
                  'gt': new Date()
                }
              }
            }
          },
        }
      }]
    }).subscribe((ydaDrivers) => {
      const ydaDriver = ydaDrivers[0];
      if (!ydaDriver.Installation || !ydaDriver.Installation.Subscription) {
        _vault.setObject(`${environment.vaultPrefix}.driver`, ydaDriver);
        _dialogService.openAlert({
          message: translations['personal_license_expired'],
          disableClose: false,
          title: translations['personal_license_expired_title'],
          closeButton: translations['close']
        }).afterClosed().subscribe(() => {
          router.navigate([`/profile`]);
        });
      }
    });
  }
}
