<mat-card class="{{defaultCssClasses}} {{cssClasses}}">
  <mat-card-title>{{title|translate}}</mat-card-title>
  <mat-card-subtitle>{{subTitle|translate}}</mat-card-subtitle>
  <mat-divider></mat-divider>
  <mat-card-content class="widget-filter">
    <span class="label">{{'filter'|translate|ucFirst}}</span>
    <mat-form-field>
      <mat-select (selectionChange)="loadData($event)" [(value)]="filterRange">
        <mat-option *ngFor="let filter of filterValues" [value]="filter.value">
          {{filter.label|translate|ucFirst}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </mat-card-content>
  <!--<div class="ride-months-graph" *ngIf="(rides.length > 0)">-->
  <!--<ngx-charts-bar-vertical-->
  <!--*ngIf="(rides.length > 0)"-->
  <!--[scheme]="colorScheme"-->
  <!--[results]="breakdown"-->
  <!--[roundDomains]="true"-->
  <!--[xAxis]="true"-->
  <!--[yAxis]="true"-->
  <!--[showXAxisLabel]="true"-->
  <!--[showYAxisLabel]="true"-->
  <!--[xAxisLabel]="xAxisLabel|ucFirst"-->
  <!--[yAxisLabel]="yAxisLabel|ucFirst">-->
  <!--</ngx-charts-bar-vertical>-->
  <!--</div>-->
  <mat-card-content *ngIf="(rides.length <= 0)">
    <p>{{'widget_no_data'|translate|ucFirst}}</p>
  </mat-card-content>
  <mat-divider *ngIf="(rides.length > 0)"></mat-divider>
  <mat-card-actions *ngIf="(rides.length > 0)">
    <a [routerLink]="[buttonLink]" class="text-upper" color="accent" mat-raised-button>
      <span>{{buttonText|translate}}</span>
    </a>
  </mat-card-actions>
</mat-card>
