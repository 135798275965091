<form
  (submit)="handleSubmit($event)"
  [formGroup]="form"
  class="dynamic-form">
  <ng-container
    *ngFor="let field of config;"
    [config]="field"
    [group]="form"
    appDynamicField>
  </ng-container>
</form>
