<mat-card *ngIf="form && webbooker" [formGroup]="form">
  <mat-card-title>{{'onboard_completed_title' | translate | ucFirst}}</mat-card-title>
  <mat-divider></mat-divider>

  <mat-card-content>

    <div [innerHTML]="onboardCompletedText|keepHtml">

    </div>

    <app-share-webbooker [webbookerId]="webbooker.id" [webbookerUrl]="webbooker.url" [webbooker]="webbooker"
                         class="app-share-webbooker"></app-share-webbooker>

    <mat-divider></mat-divider>

    <mat-card-actions>
      <div class="progress">5/5 {{'completed'|translate|ucFirst}}</div>


      <button (click)="integrationInstructions=false" *ngIf="integrationInstructions !== false" color="primary"
              mat-raised-button>{{'back' | translate | ucFirst}}</button>

      <button (click)="setStep('done')" color="primary"
              mat-raised-button>{{'close' | translate | ucFirst}}</button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
