import {Component, Injector, OnDestroy, OnInit} from '@angular/core';
import {Product} from '../../../../models/product';
import {ProductService} from '../../../../services/tps/product.service';
import {CollectionComponent} from '../../../global/collection/collection.component';
import {environment} from '../../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {NavigationService} from '../../../../services/navigation.service';
import {ucFirst} from '../../../../pipes/uc-first.pipe';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TdDataTableSortingOrder} from '@covalent/core/data-table';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';

interface ProductsCollection {
  all: Product[];
  saloon: Product[];
  estate: Product[];
  minivan: Product[];
  bus: Product[];
  limo: Product[];
};

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  providers: [ProductService]
})
export class ProductsComponent
  extends CollectionComponent
  implements OnInit, OnDestroy {

  // The columns that will be displayed
  columns = [
    {name: 'priority', label: 'Priority', sortable: true, active: true},
    {name: 'name', label: 'Name', sortable: false},
    {name: 'maxPassengers', label: 'Passenger Capacity', sortable: false},
    // { name: 'type', label: 'Type', sortable: false },
  ]
  hasError = false;
  modelName = 'products';
  sortBy = 'priority';
  sortOrder = TdDataTableSortingOrder.Ascending;
  protected defaultPriority = true;
  private drag: any;
  private yPos: number;
  private yOrigin: number;
  private rowHeight = 48;
  private tab = 'all';

  // Passes the model name and required dependencies
  constructor(
    protected _productService: ProductService,
    protected injector: Injector,
    protected _snackbar: MatSnackBar,
    _translateService: TranslateService,
    _titleService: Title,
    _navigationService: NavigationService
  ) {
    super(injector);

    // Used to set the action link, title etc..
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);

    this.setAddRoute('products');
    _translateService.get(['products', 'update_product_sort']).subscribe((translations: any) => {
      this.translations = translations;
      _titleService.setTitle(ucFirst(translations['products'] + environment.windowTitleSuffix));
      _navigationService.setBodyTitle(translations['products']);
    });

    // Service that speaks with the api, must have a function
    // called getAll, that fetches all rows
    this.modelService = _productService;

    // The columns of the table which will be filtered based
    // on a type property ('all' column will contain all types)
    // Currently all vehicles are shown. If more types are to be
    // shown, look at the collection component as an example to
    // display them as a tabbed group.
    this.originalData = {
      all: [],
      // saloon: [],
      // estate: [],
      // minivan: [],
      // bus: [],
      // limo: [],
    }
  }

  onError = (error: any) => {
    console.log(`[ProductsComponent.onError]: error`, error);
    this.hasError = true;
    this.stopLoader();
  };

  setAddRoute(context) {
    this._route.parent.params.subscribe(params => {
      this.companyId = params['id'];
      if (this.context !== 'driver') {
        this._navigationService.setActionLink(`/groups/${this.companyId}/${context}/add`);
      }
    });
  }

  getPos = (id: string) => this.drag && id === this.drag._id ? this.yPos : 0;
  drop = (event: any, item: any) => {
    if (!this.yPos && item && item._id) {
      this.click(item._id);
    }
    const change = Math.round(this.yPos / this.rowHeight);
    this.updatePrio(change);
    this.filterAllTabs();
    this.drag = null;
    this.yPos = 0;
  }

  /**
   * When mouse is pressed, define the origin from which to calculate
   * the change in direction for the element to be dragged
   */
  pick(event: any, item: any) {
    this.yOrigin = event.y;
    this.drag = item;
  }

  /**
   * Update priority of all items at once.
   */
  updatePrio(amount: number) {
    const self = this;
    if (!this.drag) {
      return;
    }

    // Define what the update is all about
    const oldPrio = this.drag.priority;
    const newPrio = this.drag.priority + amount;

    // Prevent update if data is out of bounds or hasn't changed
    if (newPrio < 1) {
      return;
    }
    if (newPrio === oldPrio) {
      return;
    }
    if (newPrio > this.originalData[this.tab].length) {
      return;
    }

    // Update the dragged object, defining the operation
    this.drag.priority = newPrio;
    // Filters, to make it easy to reason about changes
    const notDragged = (x) => x._id !== this.drag._id;
    const increased = (x) => x.priority > oldPrio && x.priority <= newPrio;
    const decreased = (x) => x.priority < oldPrio && x.priority >= newPrio;
    const decrement = (x) => x.priority--;
    const increment = (x) => x.priority++;

    const priorityWentUp = oldPrio < newPrio;
    const filter = priorityWentUp ? increased : decreased;
    const operation = priorityWentUp ? decrement : increment;

    this.originalData[this.tab]
      .filter(notDragged)
      .filter(filter)
      .map(operation);

    const requests = [];
    this.originalData[this.tab].forEach((p) => {
      requests.push(
        this._productService.update(p._id, p)
      );
    });

    forkJoin(requests)
      .subscribe(() =>
        this._snackbar.open(self.translations['update_product_sort'], '', {duration: 3000}));
  }

  /**
   * When the mouse moves, and while drag._id has a value, calculate
   * the change of position relative to the origin
   */
  move = (event: any, item: any) => {
    if (this.drag) {
      this.yPos = event.y - this.yOrigin;
    } else {
      this.yPos = 0;
    }
  }
}
