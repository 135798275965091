<mat-list-item>
  <mat-icon mat-list-icon>credit_card</mat-icon>
  <h4 mat-line>{{'price'|translate|ucFirst}}</h4>
  <p mat-line>{{'ride_price_subtitle'|translate|ucFirst}}</p>
</mat-list-item>
<ng-container *ngIf="isOwner || paymentMeta.origin === 'inTaxi'">
  <div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout="row" tdMediaToggle="gt-xs">
    <mat-list-item class="value small" flex-gt-sm="30">
      <p mat-line>{{'total_amount'|translate|ucFirst}}</p>
      <p mat-line>{{price | localizedCurrency}}</p>
    </mat-list-item>

    <mat-list-item
      *ngIf="price && price.breakdown &&
      (
        (price.breakdown.additional && price.breakdown.additional.total > 0) ||
        (price.breakdown.cleaning && price.breakdown.cleaning.total > 0) ||
        (price.breakdown.discount && price.breakdown.discount.total)
      )"
      class="value small"
      flex-gt-sm="30">
      <p mat-line>{{'breakdown'|translate|ucFirst}}</p>
      <p *ngIf="price.breakdown && price.breakdown.route && price.breakdown.route.total" mat-line>
        <span>
          {{'fare'|translate|ucFirst}}: {{price | localizedCurrency:'route'}}
        </span>
      </p>
      <p *ngIf="price.breakdown && price.breakdown.additional && price.breakdown.additional.total > 0" mat-line>
        <span>
          {{'additional'|translate|ucFirst}}: +{{price | localizedCurrency:'additional'}}
        </span>
      </p>
      <p *ngIf="price.breakdown && price.breakdown.cleaning && price.breakdown.cleaning.total > 0" mat-line>
        <span>
          {{'additional'|translate|ucFirst}}: +{{price | localizedCurrency:'cleaning'}}
        </span>
      </p>
      <p *ngIf="price.breakdown && price.breakdown.discount && price.breakdown.discount.total" mat-line>
        <span *ngIf="price.breakdown.discount.total < 0">
          {{'discount'|translate|ucFirst}}: {{price | localizedCurrency:'discount'}}
        </span>
        <span *ngIf="price.breakdown.discount.total > 0">
          {{'additional'|translate|ucFirst}}: {{price | localizedCurrency:'discount'}}
        </span>
      </p>
    </mat-list-item>

    <mat-list-item
      *ngIf="coupon"
      class="value small" flex-gt-sm="30">
      <p mat-line>{{'coupon'|translate|ucFirst}}</p>
      <p mat-line>
        <span>
          {{coupon.name}}:
          <span *ngIf="coupon.discountType ==='percentage'">-{{coupon.discountPercentage}}%</span>
          <span *ngIf="coupon.discountType ==='fixed'">-
            {{({total: coupon.discountFixedAmount}) | localizedCurrency}}</span>
        </span>
      </p>
    </mat-list-item>
  </div>

  <div *ngIf="vehicleProduct" [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row"
       tdMediaToggle="gt-xs">
    <mat-list-item
      class="value small"
      flex="30">
      <p mat-line>{{'product'|translate|ucFirst}}</p>
      <div mat-line>
        {{vehicleProduct.description | translate | ucFirst}}
      </div>
    </mat-list-item>

    <mat-list-item
      *ngIf="priceMethod"
      class="value small" flex="30">
      <p mat-line>{{'pricing_type'|translate|ucFirst}}</p>
      <p mat-line>
        {{priceMethod.toLowerCase() | translate | ucFirst}}
      </p>
    </mat-list-item>
    <mat-list-item
      *ngIf="priceMethod === 'onMeter'"
      class="value small" flex="30">
      <p mat-line>{{'calculation_method'|translate|ucFirst}}</p>
      <p mat-line>
        {{this.priceCalculationMethod | translate | ucFirst}}
      </p>
    </mat-list-item>
  </div>
</ng-container>
