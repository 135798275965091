import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {PricingRuleService} from '../../../../../services/tps/pricing-rule.service';
import {Price, Rule} from '../../../../../models/rule';
import {Product} from '../../../../../models/product';
import {Company} from '../../../../../models/company';
import {getCurrencySymbol} from '@angular/common';
import {TdDialogService} from '@covalent/core/dialogs';
import {ProductUpsertComponent} from '../../../settings/products/parts/product-upsert/product-upsert.component';
import {ProductTemplateSelectComponent} from '../../../settings/products/parts/product-template-select/product-template-select.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {ProductService} from '../../../../../services/tps/product.service';
import {TranslateService} from '@ngx-translate/core';
import {DaAppInstallService} from '../../../../../services/da-app-install.service';
import {environment} from '../../../../../../environments/environment';
import {PricingRuleLinkService} from '../../../../../services/tps/pricing-rule-link.service';

const fromCents = (value: number) => (value / 100).toFixed(2);

@Component({
  selector: 'app-ob-service',
  templateUrl: './ob-service.component.html',
  styleUrls: ['./ob-service.component.scss'],
  providers: [PricingRuleService, ProductService],
  animations: [
    trigger('slideUpDown', [
      state('hide', style({
        transform: 'translateY(-100vh)',
        display: 'none'
      })),
      state('show', style({
        transform: 'translateY(0)'
      })),
      transition('hide => show', animate('300ms ease-out')),
      transition('show => hide', animate('300ms ease-in'))
    ]),
  ]
})
export class ObServiceComponent implements OnInit {
  @Input() form: UntypedFormGroup;
  @Input() step: string;
  @Input() company: Company;
  @Output() nextStep = new EventEmitter<string>();
  rule: Rule;
  currencySymbol: string;
  productList: any[];
  prices: any[];
  enabledProducts: any[] = [];
  allProducts: any[] = [];
  notSelectedProducts: any[] = [];
  serviceCount = [1];

  mode = 'select';
  setMinimumPrice = {};
  loading = true;
  addServiceSelector = false;
  removedProducts = [];
  deleteProducts = [];
  translations = [];

  constructor(
    private _pricingRuleService: PricingRuleService,
    private _productService: ProductService,
    private _dialogService: TdDialogService,
    private _daAppInstallService: DaAppInstallService,
    private _ruleLinkService: PricingRuleLinkService,
    private _formBuilder: FormBuilder,
    private _translateService: TranslateService
  ) {
    _translateService.get(['confirm_remove_product', 'cancel', 'remove', 'confirm_price_left_empty', 'back', 'continue', 'wizard_no_products_selected', 'continue_without_price'])
      .subscribe((translations: any) => {
        this.translations = translations;
      });
  }

  valOrZero(formGroup, input) {
    if (!input.value) {
      input.value = 0.00;
    }
    input.value = parseFloat(input.value).toFixed(2);
    // @ts-ignore
    this.form.controls[formGroup].controls[input.getAttribute('formControlName')].setValue(input.value);
  }

  ngOnInit(): void {
    const self = this;
    if (!this.company) {
      setTimeout(function () {
        self.ngOnInit();
      }, 500);
    } else {
      this.loadData();
      this.currencySymbol = getCurrencySymbol(this.company.currency, 'narrow');
    }
  }

  setStep(step) {
    if (step === 'skipped') {
      this.nextStep.emit((step.step ? (step.step) : step));
    } else {
      const self = this;
      let hasEmpty = false;

      if (this.allProducts.length === 0) {
        self._dialogService.openConfirm({
          message: self.translations['wizard_no_products_selected'],
          disableClose: false,
          acceptButton: self.translations['back']
        }).afterClosed().subscribe((accept: boolean) => {

        });
      } else {
        Object.keys(this.form.value).forEach((key) => {
          const split = key.split('productId_');
          if (split[1]) {
            if ((this.form.controls[key]['controls'].dynamicDistancePrice.value * 100) === 0 && (this.form.controls[key]['controls'].dynamicMinutePrice.value * 100) === 0) {
              hasEmpty = true;
            }
          }
        });

        if (hasEmpty) {
          self._dialogService.openConfirm({
            message: self.translations['confirm_price_left_empty'],
            disableClose: true,
            cancelButton: self.translations['back'],
            acceptButton: self.translations['continue_without_price'],
          }).afterClosed().subscribe((accept: boolean) => {
            if (accept) {
              self.saveValuesAndContinue(step);
            }
          });
        } else {
          self.saveValuesAndContinue(step);
        }
      }
    }
  }

  saveValuesAndContinue(step): void {
    Object.keys(this.form.value).forEach((key) => {
      const split = key.split('productId_');
      if (split[1]) {
        this._pricingRuleService.getAllDynamicPrice({where: {priceId: split[1]}})
          .subscribe((price) => {
            this._pricingRuleService.updatePrice(split[1], {'isEnabled': true})
              .subscribe(() => {
                if (price[0]) {
                  this._pricingRuleService.updateDynamicPrice(price[0]._id, {
                      dynamicMinimumPrice: this.form.controls[key]['controls'].dynamicMinimumPrice.value * 100,
                      dynamicStartPrice: this.form.controls[key]['controls'].dynamicStartPrice.value * 100,
                      dynamicDistancePrice: this.form.controls[key]['controls'].dynamicDistancePrice.value * 100,
                      dynamicMinutePrice: this.form.controls[key]['controls'].dynamicMinutePrice.value * 100,
                    }).subscribe(() => {
                  });
                } else {
                  this._pricingRuleService.insertDynamicPrice({
                      priceId: split[1],
                      dynamicMinimumPrice: this.form.controls[key]['controls'].dynamicMinimumPrice.value * 100,
                      dynamicStartPrice: this.form.controls[key]['controls'].dynamicStartPrice.value * 100,
                      dynamicDistancePrice: this.form.controls[key]['controls'].dynamicDistancePrice.value * 100,
                      dynamicMinutePrice: this.form.controls[key]['controls'].dynamicMinutePrice.value * 100,
                    }).subscribe(() => {
                  });
                }
              });
          })
      }
    });
    this.nextStep.emit((step.step ? (step.step) : step));
  }

  loadData = () => {
    const self = this;
    this.loading = true;
    const minZeroValidator = [Validators.required, Validators.min(0)];

    this._pricingRuleService
      .getAll({
        where: {
          companyId: this.company.id,
          type: 'dynamic'
        },
        include: [{
          relation: 'products',
          scope: {
            include: [{
              'relation': 'prices',
              scope: {
                include: [{
                  'relation': 'priceDynamic',
                }]
              }
            }]
          }
        },
          {
            relation: 'childRules',
            scope: {
              include: [
                {
                  relation: 'products',
                  scope: {
                    include: [{
                      'relation': 'prices',
                      scope: {
                        include: [
                          {
                            'relation': 'priceDynamic',
                          }
                        ]
                      }
                    }]
                  }
                },
                {
                  relation: 'destination',
                },
                {
                  relation: 'departure',
                }
              ]
            }
          }]
      })
      .subscribe(
        (rule) => {
          if (rule && rule[0]) {
            this.rule = rule[0];
            this.rule.childRules = this.rule.childRules.filter((r: Rule) => {
              r.products = r.products.filter((p: Product) => {
                p.prices = p.prices.filter((a: Price) => {
                  if (r._id === a.ruleId) {
                    return r;
                  }
                });
                return p;
              });
              return r;
            });

            this.rule.products = this.productSort(this.rule.products);

            let prices = this.rule.products.map((p) => {
              const ruleProduct = p;
              const price = p.prices.filter((_p) => {
                return (_p.ruleId === this.rule._id)
              })[0];
              const product = this._formBuilder.group({
                _id: [ruleProduct._id],
                name: [ruleProduct.name],
                type: [ruleProduct.type],
                imagePath: [ruleProduct.imagePath],
                extraInfo: ruleProduct.extraInfo,
                extraDescription: ruleProduct.description,
                maxPassengers: [ruleProduct.maxPassengers],
              });
              let priceDynamic;
              if (price && price.priceDynamic) {
                priceDynamic = this._formBuilder.group({
                  dynamicStartPrice:
                    [fromCents(price.priceDynamic.dynamicStartPrice), minZeroValidator],
                  dynamicMinimumPrice:
                    [fromCents(price.priceDynamic.dynamicMinimumPrice), minZeroValidator],
                  dynamicMinutePrice:
                    [fromCents(price.priceDynamic.dynamicMinutePrice), minZeroValidator],
                  dynamicDistancePrice:
                    [fromCents(price.priceDynamic.dynamicDistancePrice), minZeroValidator],
                });
              } else if (price) {
                priceDynamic = this._formBuilder.group({
                  dynamicStartPrice:
                    [fromCents(0), minZeroValidator],
                  dynamicMinimumPrice:
                    [fromCents(0), minZeroValidator],
                  dynamicMinutePrice:
                    [fromCents(0), minZeroValidator],
                  dynamicDistancePrice:
                    [fromCents(0), minZeroValidator],
                });
              }

              if (price.productId && priceDynamic) {
                this.form.addControl('productId_' + price._id, priceDynamic);
              }

              if (!this.removedProducts.includes(price._id)) {
                return this._formBuilder.group({
                  '_id': [price._id],
                  'isEnabled': [price.isEnabled],
                  'minuteWaitingPrice': [fromCents(price.minuteWaitingPrice), Validators.required],
                  'cascadingThresholdCalculation': [price.cascadingThresholdCalculation, [Validators.required]],
                  'priceDynamic': (priceDynamic ? priceDynamic : null),
                  'productId': [price.productId],
                  product,
                });
              }
            });
            this.allProducts = this.rule.products.map((p) => {
              const price = p.prices.filter((_p) => {
                return (_p.ruleId === this.rule._id)
              })[0];
              p.price = price;
              return p;
            });

            prices = prices.filter((p) => {
              return !!(p)
            });

            const pricesIdArray = prices.map((p) => {
              return p.value.productId;
            });

            this.notSelectedProducts = this.rule.products.filter((p) => {
              return pricesIdArray.includes(p._id);
            });

            this.notSelectedProducts = this.notSelectedProducts.map((p) => {
              return p._id;
            });

            price: this._formBuilder.group({
              dynamicMinimumPrice: ['', [Validators.required]],
              dynamicStartPrice: ['', [Validators.required]],
              dynamicDistancePrice: ['', [Validators.required]],
              dynamicMinutePrice: ['', [Validators.required]],
            }),

            this.loading = false;
            this.prices = prices;
            this.enabledProducts = prices.map((p) => {
              return (p.value)
            });

            if (this.enabledProducts.length < 1) {
              this.selectProductTemplate();
            }
          } else {
            this._pricingRuleService.insert({
              'companyId': this.company.id,
              'name': 'Default Rule',
              'type': 'dynamic',
              'priority': 1,
              'timeframes': [],
              'prices': [{
                'priceDynamic': {
                  thresholds: [],
                },
                'priceFixed': {
                  thresholds: [],
                },
                'priceHourly': {
                  thresholds: [],
                },
              }]
            }).subscribe((_rule) => {
              /**
               * Activate this rule for the DispatchApps
               */
              self._daAppInstallService.getAll({
                where: {
                  daAppId: environment.webbookerDaAppId,
                  companyId: self.company.id,
                }
              }).subscribe((bookers) => {
                bookers.forEach((daAppInstall) => {
                  self._ruleLinkService.insert({
                    'isAllowedOnMeter': true,
                    'isFixed': true,
                    'ruleId': _rule._id,
                    'companyId': self.company.id,
                    'ruleableId': daAppInstall.id,
                    'ruleableType': 'DaAppInstall'
                  }).subscribe(() => {

                  });
                });
                self.loadData();
              });
            })
          }
        },
        (err) => {
          console.log(err);
        },
        () => {
        }
      );
  }

  productChanged = ($event) => {
    const selectedProduct = this.prices.filter((s) => {
      return (s._id === $event.value)
    })[0];
    this.form.controls['product'].setValue({
      productId: selectedProduct._id,
      product: selectedProduct.name,
      name: selectedProduct.name,
      maxPassengers: selectedProduct.maxPassengers,
      extraInfo: (selectedProduct.extraInfo ? selectedProduct.extraInfo : ''),
      description: (selectedProduct.description ? selectedProduct.description : ''),
      image: '',
      imagePath: selectedProduct.imagePath,
    });
  }

  addService(templateProduct) {
    templateProduct.companyId = this.company.id;
    delete templateProduct._id;
    this._productService.insert(templateProduct)
      .subscribe(() => {
        this.loadData();
      });
  }

  selectProductTemplate() {
    this._dialogService.open(ProductTemplateSelectComponent, {
      panelClass: 'no-dialog-padding',
      width: (window.innerWidth < 600 ? '95%' : '80%'),
      maxWidth: (window.innerWidth < 600 ? '95%' : '80%'),
      height: (window.innerWidth < 600 ? '100%' : '100%'),
      maxHeight: (window.innerWidth < 600 ? '100%' : '100%'),
      disableClose: false,
      data: {
        openFromDialog: true,
        companyId: this.company.id,
        parent: this,
        innerWidth: window.innerWidth,
      },
    });
  }

  addServiceFromNewProduct(productId) {
    const self = this;
    if (this.loading) {
      setTimeout(function () {
        self.addServiceFromNewProduct(productId);
      }, 100);
    } else {
      const priceId = this.allProducts.filter((p) => {
        return (p._id === productId)
      })[0].price._id;

      this._pricingRuleService.insertDynamicPrice({
        dynamicMinimumPrice: 0,
        dynamicStartPrice: 0,
        dynamicDistancePrice: 0,
        dynamicMinutePrice: 0,
        priceId: priceId,
      }).subscribe(() => {
        this._pricingRuleService.updatePrice(priceId, {
          isEnabled: true,
        }).subscribe(() => {
          this.addServiceSelector = false;
          this.loadData();
        });
      });
    }
  }

  productSort = (products) => {
    return products.sort(function (a, b) {
      if (a.priority) {
        if (a.priority === b.priority) {
          return a.priority > b.priority ? 1 : -1;
        } else {
          return a.priority - b.priority;
        }
      } else if (a.maxPassengers === b.maxPassengers) {
        if (b.name.toLowerCase() === 'saloon') {
          return 1;
        } else {
          return a.name > b.name ? 1 : -1;
        }
      } else {
        return a.maxPassengers - b.maxPassengers;
      }
    });
  }

  editProduct(productId) {
    this._dialogService.open(ProductUpsertComponent, {
      panelClass: 'no-dialog-padding',
      width: '75%',
      disableClose: false,
      data: {
        openFromDialog: true,
        action: (productId ? 'edit' : 'add'),
        productId: productId,
        companyId: this.company.id,
        parent: this,
      },
    });
  }

  deleteProduct(id, productId) {
    const self = this;
    self._dialogService.openConfirm({
      message: self.translations['confirm_remove_product'],
      disableClose: false,
      cancelButton: self.translations['cancel'],
      acceptButton: self.translations['remove'],
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        // this.removedProducts.push(priceId);
        // this.deleteProducts.push(productId);
        self.form.removeControl(`productId_${id}`);
        self.form.updateValueAndValidity();
        console.log(self.form);
        console.log(`productId_${id}`);
        // if(this.deleteProducts && this.deleteProducts.length > 0) {
        //   this.deleteProducts.forEach((p) => {
        this._productService.delete(productId).subscribe(() => {
          this.loadData();
        })
        // })
        // }

      }
    });
  }
}
