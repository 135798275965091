import {AfterViewInit, Component, isDevMode, OnDestroy, OnInit} from '@angular/core';
import {IPageChangeEvent} from '@covalent/core/paging';
import {ITdDataTableSortChangeEvent, TdDataTableService, TdDataTableSortingOrder} from '@covalent/core/data-table';
import {TdDialogService} from '@covalent/core/dialogs';
import {TdLoadingService} from '@covalent/core/loading';
import {TdMediaService} from '@covalent/core/media';

import {DriverService} from '../../../services/driver.service';
import {CompanyWebUserService} from '../../../services/companyWebUser.service';
import {TranslateService} from '@ngx-translate/core';
import {NavigationService} from '../../../services/navigation.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {GoogleMapsAPIWrapper, LAZY_MAPS_API_CONFIG, MapsAPILoader, MarkerManager} from '@agm/core';
import {ContactService} from '../../../services/contact.service';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Company} from '../../../models/company';
import {CustomLazyAPIKeyLoader} from '../../../services/CustomLazyAPIKeyLoader';
import {GoogleMapConfig} from '../../googleMapConfig';
import {CountryService} from '../../../services/country.service';
import {CompanyInvitationService} from '../../../services/company-invitation.service';
import {BusinessLicenseComponent} from '../../global/business-license/business-license.component';
import {ChangeOwnerComponent} from '../../company/drivers/webuser-upsert/parts/change-owner/change-owner.component';
import {UserService} from '../../../services/user.service';
import moment from 'moment-timezone';
import {UtilityService} from '../../../services/utility.service';
import {ExporterService} from '../../../services/exporter.service';
import {EditInviteDialogComponent} from './parts/edit-invite-dialog/edit-invite-dialog.component';

@Component({
  selector: 'app-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss'],
  providers: [
    MarkerManager,
    GoogleMapsAPIWrapper,
    {provide: MapsAPILoader, useClass: CustomLazyAPIKeyLoader},
    {provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapConfig}
  ],
})
export class DriversComponent extends BusinessLicenseComponent implements OnInit, OnDestroy, AfterViewInit {
  sub: any;
  company: Company;
  driver: any;
  translations: string[] = [];
  context: string;
  mapInstance: any;
  mapControlled = false;
  latlngBounds: any;
  interval: any;
  mapDrivers: any = [];
  filteredMapDrivers: any = [];
  dataSub: Subscription;
  activeTab = 'all';
  selectedTabIndex: any = 0;
  pendingMemberCount = 0;
  allMemberCount = 0;
  companyId: string;
  drivers: any[];
  extendedDispatcher: any;
  deleteX: string;
  messageDeleteX: string;
  xDeleted: string;
  columns = [
    {name: 'name', label: 'name', sortable: true, active: true},
    {name: 'phoneNumber', label: 'phone_number', sortable: true, active: false, fixedSize: 'w-120'},
    {name: 'emailAddress', label: 'email', sortable: true, active: false, maxSize: 'maxSize600'},
    {name: 'type', label: 'type', sortable: true, active: false, maxSize: 'maxSize600'},
    {name: 'companyStatusTxt', label: 'driver_status', sortable: false, active: false},
    {name: 'tools', label: 'tools', sortable: false, active: false}
  ];
  meAsDriverExportColumns: any = [
    {name: 'fname', label: 'first_name'},
    {name: 'lname', label: 'last_name'},
    {name: 'phoneNumber', label: 'phone_number'},
    {name: 'emailaddress', label: 'emailaddress'},
    {name: 'ssn', label: 'ssn'},
    {name: 'meta.licenseNumber', label: 'license_number'},
  ];
  allData: any[] = [];
  allFilteredData: any[] = [];
  allFilteredTotal: number;
  pendingData: any[] = [];
  pendingFilteredData: any[] = [];
  pendingFilteredTotal: number;
  blockedMemberCount = 0;
  blockedData: any[] = [];
  blockedFilteredData: any[] = [];
  blockedFilteredTotal: number;
  operatorCount = 0;
  operatorData: any[] = [];
  operatorFilteredData: any[] = [];
  operatorFilteredTotal: number;
  searchTerm = '';
  fromRow = 1;
  currentPage = 1;
  pageSize = 20;
  sortBy = 'name';
  sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Ascending;
  operatorSortBy = 'name';
  operatorSortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Ascending;
  user: any;
  limitColumns = [
    {name: 'name', label: 'name', sortable: true, active: true},
    {name: 'phoneNumber', label: 'phone_number', sortable: true, active: false},
    {name: 'emailAddress', label: 'email', sortable: true, active: false},
    {name: 'is_driver', label: 'admin_is_driver', sortable: true, active: false},
    {name: 'type', label: 'type', sortable: true, active: false},
    {name: 'tools', label: 'tools', sortable: false, active: false}
  ];
  private defaultMarkerIconUrl = `/assets/${environment.buildName}/icon-marker.png`;

  constructor(public media: TdMediaService,
              private _router: Router,
              private _loadingService: TdLoadingService,
              private _dialogService: TdDialogService,
              private _snackbar: MatSnackBar,
              private _vault: CoolLocalStorage,
              private _dataTableService: TdDataTableService,
              private _driverService: DriverService,
              private _webPortalUserService: UserService,
              private _companyInvitationService: CompanyInvitationService,
              private _companyWebUserService: CompanyWebUserService,
              private _countryService: CountryService,
              private _contactService: ContactService,
              private _exporter: ExporterService,
              private _translateService: TranslateService,
              private _navigationService: NavigationService,
              private _route: ActivatedRoute,
              private mapsAPILoader: MapsAPILoader,
              private _titleService: Title) {
    super();
    this.context = this._route.routeConfig['context'] || 'driver';
    this._navigationService.setActionLink('');
    this.user = this._vault.getObject(`${environment.vaultPrefix}.user`);

    if (this.context === 'company') {
      const {company} = this._route.parent.snapshot.data;
      UtilityService.setBrowserTimeStamp(company);
      this.company = company;
      this.setBusinessContract(_vault);
    } else if (this.context === 'driver') {
      this.columns = this.limitColumns;
    }

    _translateService.get(['request_confirmation', 'duplicate_webuser_warning_title', 'duplicate_webuser_warning_text', 'company_drivers', 'personal_drivers', 'delete_x', 'message_delete_x', 'x_deleted', 'invite', 'confirm_delete_operator_message', 'confirm_delete_operator_driver_message', 'confirm_delete_operator_title', 'cancel', 'confirm_delete_driver', 'confirm_delete_operator', 'driver_invited', 'driver_invited_title', 'driver', 'driver_delete_error', 'webuser', 'ok', 'confirm_delete_operator_button', 'last_seen', 'status_199', 'today', 'first_name', 'last_name', 'phone_number', 'emailaddress', 'ssn', 'license_number'
    ])
      .subscribe((translations: string[]) => {
        this.translations = translations;
        this._titleService.setTitle(translations['company_drivers'] + environment.windowTitleSuffix);

        if (this.context === 'company') {
          this._titleService.setTitle(translations['company_drivers']);
          this._navigationService.setBodyTitle(translations['company_drivers']);
        } else {
          this._titleService.setTitle(translations['personal_drivers']);
          this._navigationService.setBodyTitle(translations['personal_drivers']);
        }

        this.deleteX = translations['delete_x'];
        this.messageDeleteX = translations['message_delete_x'];
        this.xDeleted = translations['x_deleted'];
      });

    if (this._route.routeConfig['path'] === 'driverlist') {
      this._vault.removeItem(`${environment.vaultPrefix}.members.tab`);
      this.setAddRoute('drivers');
    } else {
      const index = this._vault.getObject(`${environment.vaultPrefix}.members.tab`);
      if (index) {
        this.selectedTabIndex = index;
        this.activeTab = 'companyWebUsers';
        this.setAddRoute('companyWebUsers');
      } else {
        this.setAddRoute('drivers');
      }
    }
  }

  startLoaders() {
    this._loadingService.register('drivers.list');
  }

  resolveLoaders() {
    this._loadingService.resolve('drivers.list');
  }

  ngOnInit() {
    if (this.context === 'driver') {
      this.driver = this._vault.getObject(`${environment.vaultPrefix}.driver`);
      this._navigationService.setActionLink('');
      this.startLoaders();
      this.loadData();
    } else {
      this.sub = this._route.parent.params.subscribe(params => {
        this.companyId = params['id'];
      });
    }
  }

  setAddRoute(context = 'drivers') {
    this.sub = this._route.parent.params.subscribe(params => {
      this.companyId = params['id'];
      if (this.context !== 'driver') {
        this._navigationService.setActionLink(`/${(this.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${this.companyId}/${context}/add`);
      }
      this.startLoaders();
      this.loadData();
    });
  }

  ngAfterViewInit() {
    this.media.broadcast();
  }

  ngOnDestroy() {
    this._navigationService.setActionLink('');
    /**
     * Stop the interval
     */
    clearInterval(this.interval);
    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }
  }

  onTabChange(event: MatTabChangeEvent): void {
    let offset = 0;
    if (!(this.company.ActiveContract
      && ['CloudConnect', 'fleet_solutions', 'free-trial'].includes(this.company.ActiveContract.type))) {
      offset = 1;
    }
    if (event.index === 0) {
      this.activeTab = 'all';
      this.setAddRoute('drivers');
    } else if (event.index === 1 && !offset) {
      this.activeTab = 'map';
      this.setAddRoute('drivers');
    } else if (event.index === (2 - offset)) {
      this.activeTab = 'companyWebUsers';
      this.setAddRoute('companyWebUsers');
    } else if (event.index === (3 - offset)) {
      this.activeTab = 'blocked';
      this.setAddRoute('drivers');
    }
    this._vault.setObject(`${environment.vaultPrefix}.members.tab`, event.index);
  }

  sort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.sortBy = sortEvent.name;
    this.sortOrder = sortEvent.order;
    this.filter();
  }

  operatorSort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.operatorSortBy = sortEvent.name;
    this.operatorSortOrder = sortEvent.order;
    this.operatorFilter();
  }

  search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.filter();
    this.operatorFilter();
  }

  page(pagingEvent: IPageChangeEvent): void {
    this.fromRow = pagingEvent.fromRow;
    this.currentPage = pagingEvent.page;
    this.pageSize = pagingEvent.pageSize;
    this.filter();
    this.operatorFilter();
  }

  operatorFilter(): void {
    let newAllData: any[] = this.operatorData;
    newAllData = this._dataTableService.filterData(newAllData, this.searchTerm, true);
    this.operatorFilteredTotal = newAllData.length;

    this.allFilteredTotal = newAllData.length;
    newAllData = this._dataTableService.sortData(newAllData, this.operatorSortBy, this.operatorSortOrder);
    newAllData = this._dataTableService.pageData(newAllData, this.fromRow, this.currentPage * this.pageSize);
    this.operatorFilteredData = newAllData;
  }

  filter(): void {
    let newAllData: any[] = this.allData;
    let newPendingData: any[] = this.pendingData;
    let newBlockedData: any[] = this.blockedData;
    let newMapDrivers: any[] = this.mapDrivers;

    newAllData = this._dataTableService.filterData(newAllData, this.searchTerm, true);
    newPendingData = this._dataTableService.filterData(newPendingData, this.searchTerm, true);
    newBlockedData = this._dataTableService.filterData(newBlockedData, this.searchTerm, true);
    newMapDrivers = this._dataTableService.filterData(newMapDrivers, this.searchTerm, true);

    this.allFilteredTotal = newAllData.length;
    this.pendingFilteredTotal = newPendingData.length;
    this.blockedFilteredTotal = newBlockedData.length;

    newAllData = this._dataTableService.sortData(newAllData, this.sortBy, this.sortOrder);
    newPendingData = this._dataTableService.sortData(newPendingData, this.sortBy, this.sortOrder);
    newBlockedData = this._dataTableService.sortData(newBlockedData, this.sortBy, this.sortOrder);

    newAllData = this._dataTableService.pageData(newAllData, this.fromRow, this.currentPage * this.pageSize);
    newPendingData = this._dataTableService.pageData(newPendingData, this.fromRow, this.currentPage * this.pageSize);
    newBlockedData = this._dataTableService.pageData(newBlockedData, this.fromRow, this.currentPage * this.pageSize);
    this.operatorFilteredTotal = newAllData.length;

    this.allFilteredData = newAllData;
    this.pendingFilteredData = newPendingData;
    this.blockedFilteredData = newBlockedData;
    this.filteredMapDrivers = newMapDrivers;
  }

  open(id: string): void {
    this._router.navigate([`/${(this.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${this.companyId}/drivers/${id}/details/`]);
  }

  openCompanyWebUser(id: string): void {
    this._router.navigate([`/groups/${this.companyId}/companyWebUsers/${id}/edit/`]);
  }

  findDriver(driver) {
    return (driver.id ? driver : false);
  }

  addDriverToArray(driver, invitedBlockedDriver: any = []) {
    driver.name = driver.fname + ' ' + (driver.lname && driver.lname !== '-' ? driver.lname : '');
    driver.companyStatusTxt = driver.status;

    if (this.company && this.company.ownerId === driver.id) {
      driver.driverType = 'owner';
      driver.type = 'owner';
    }
    if (driver.licenseNumber && driver.meta.licenseNumber) {
        driver.licenseNumber = driver.meta.licenseNumber;
    }

    if (driver.ActiveVehicle && driver.ActiveVehicle.status.connected === false && driver.ActiveVehicle.status.state !== 'offline') {
      driver.statusTxt = `status_not_connected`;
      driver.ActiveVehicle.status.state = 'offline';
      driver.companyStatusTxt = driver.statusTxt;
    } else if (driver.status === 'created' || (invitedBlockedDriver.includes(driver.phoneNumber) && driver.status === 'blocked')) {
      driver.companyStatusTxt = `status_invited`;
      driver.extendedDispatcherLicense = 'status_invited';
      driver.driverType = (driver.driverType ? driver.driverType : 'driver');
      driver.type = driver.driverType;
    } else {
      if (driver.ActiveVehicle && driver.ActiveVehicle.status && driver.ActiveVehicle.status.code) {
        driver.statusTxt = `status_${(driver.ActiveVehicle ? driver.ActiveVehicle.status.code : 199)}`;
      } else if (!driver.ActiveVehicle) {
        driver.statusTxt = `status_${(driver.ActiveVehicle ? driver.ActiveVehicle.status.code : 199)}`;
      } else if (driver.ActiveVehicle && driver.ActiveVehicle.status && driver.ActiveVehicle.status.state) {
        if (driver.ActiveVehicle.status.state === 'available') {
          driver.statusTxt = `status_100`
        }
      } else if (driver.ActiveVehicle && driver.ActiveVehicle.status) {
        driver.statusTxt = `status_${driver.ActiveVehicle.status}`
      }
      driver.companyStatusTxt = driver.statusTxt;

      if (driver.ActiveVehicle && driver.ActiveVehicle.status && driver.ActiveVehicle.status.code === 199) {
        driver.companyStatusTxt = `${this.translations[driver.statusTxt]}`;
        if (moment(driver.ActiveVehicle.modified).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY')) {
          driver.companyStatusTxtSub = `${this.translations['today']}: ${moment(driver.ActiveVehicle.modified).format('DD-MM-YYYY HH:mm')}`;
        } else {
          driver.companyStatusTxtSub = `${this.translations['last_seen']}: ${moment(driver.ActiveVehicle.modified).format('DD-MM-YYYY HH:mm')}`;
        }
      } else if (driver.ActiveVehicle && driver.ActiveVehicle.status && driver.ActiveVehicle.status.state) {
        driver.state = driver.ActiveVehicle.status.state;
      }
    }

    if (driver.status === 'created' || driver.status === 'accepted') {
      if ((this.driver && this.driver.id !== driver.id) || this.context === 'company') {
        this.allData.push(driver);
      }
    } else if (driver.status === 'blocked') {
      this.blockedData.push(driver);
    }

    if (this.extendedDispatcher && ['admin', 'operator', 'owner'].includes(driver.type) && driver.Installation && driver.Installation.Subscription && driver.status !== 'created') {
      driver.type = `${driver.type} (ED)`;
    } else if (this.extendedDispatcher && ['admin', 'operator', 'owner'].includes(driver.type) && driver.status !== 'created') {
      driver.type = `${driver.type} (ED)`;
    } else if (this.extendedDispatcher && driver.status !== 'created') {
      driver.extendedDispatcherLicense = '-';
    }

    if (driver.ActiveVehicle && driver.ActiveVehicle.status.state !== 'offline') {
      const foundIndex = this.mapDrivers.findIndex(d => d.id === driver.id);
      if (this.mapDrivers[foundIndex]) {
        const newGps = `${this.mapDrivers[foundIndex].ActiveVehicle.location.gps.lat}${this.mapDrivers[foundIndex].ActiveVehicle.location.gps.lng}`;
        const newStatus = this.mapDrivers[foundIndex].status.state;
        const currentGps = `${driver.ActiveVehicle.location.gps.lat}${driver.ActiveVehicle.location.gps.lng}`;
        const currentStatus = driver.status.state;
        if (newGps !== currentGps || newStatus !== currentStatus) {
          this.mapDrivers[foundIndex] = driver;
        }
      } else {
        this.mapDrivers.push(driver);
      }
    }
  }

  prepareTableData() {
    this.pendingMemberCount = this.pendingData.length;
    this.allMemberCount = this.allData.length;
    this.blockedMemberCount = this.blockedData.length;

    this.allFilteredData = this.allData;
    this.allFilteredTotal = this.allFilteredData.length;

    this.pendingFilteredData = this.pendingData;
    this.pendingFilteredTotal = this.pendingFilteredData.length;

    this.blockedFilteredData = this.blockedData;
    this.blockedFilteredTotal = this.blockedFilteredData.length;

    this.filter();
    this.operatorFilter();

    const index = this._vault.getObject(`${environment.vaultPrefix}.members.tab`);
    if (index) {
      this.selectedTabIndex = index;
    } else {
      if (this.activeTab === 'all') {
        this.selectedTabIndex = 0;
      } else if (this.activeTab === 'pending') {
        this.selectedTabIndex = 1;
      }
    }
  }

  loadData(mapOnly = false): void {
    const self = this;
    if (self.translations.length < 1) {
      setTimeout(function(){
        self.loadData(mapOnly);
      }, 500);
      return;
    }
    let query = {};
    if (mapOnly && self.activeTab !== 'map') {
      return;
    }
    self.allData = [];
    self.pendingData = [];
    self.blockedData = [];
    self.mapDrivers = [];
    if (self.context === 'driver' && self.driver) {
      query = {
        'where': {
          'driverId': self.driver.id,
          'type': 'driver'
        },
        'include': [{
          'relation': 'ConnectedDriver'
        }]
      };

      this._contactService.getAll(query).subscribe((contacts: any[]) => {
        contacts.forEach(function (contact, i) {
          if (contact.ConnectedDriver) {
            self.addDriverToArray(contact.ConnectedDriver);
          }
        });

        const filter = {
          'include': [{
            'relation': 'ActiveVehicle'
          }]
        };
        this.dataSub = this._driverService.get(this.driver.id, filter).subscribe((driver: any[]) => {
          self.addDriverToArray(driver);
          self.setBounds();

          if (!mapOnly) {
            self.prepareTableData();
            setTimeout(function () {
              self.resolveLoaders();
            }, 100);
          }
        });
      });
    } else {
      query = {
        'where': {
          'companyId': self.companyId,
          'status': 'created'
        },
        'order': [
          'type', 'firstName'
        ]
      };
      this._companyInvitationService.getAll(query, 'company').subscribe((companyInvitations: any[]) => {
        query = {
          'where': {
            'companyId': self.companyId,
            'status': {
              'nin': ['pending', 'removed', 'blocked', 'created', 'left'],
            }
          },
          'include': [
            'ActiveVehicle',

          ],
          'order': [
            'type', 'firstName'
          ]
        };

        const invitedBlockedDriver = [];
        companyInvitations.forEach(function (invite, i) {
          if (invite.driverId && invite.status === 'created') {
            invitedBlockedDriver.push(invite.phoneNumber);
          }
          self.addDriverToArray(invite);
        });
        this.dataSub = this._driverService.getAll(query, 'company').subscribe((drivers: any[]) => {
          drivers.forEach(function (driver, i) {
            self.addDriverToArray(driver, invitedBlockedDriver);
          });
          self.setBounds();

          query = {
            'where': {
              companyId: self.companyId,
              status: {
                nin: ['pending', 'removed', 'blocked', 'created', 'left'],
              }
            }, include: [{
              'relation': 'User',
              'scope': {
                'include': 'Driver'
              }
            }]
          };

          let isDriver;
          this._companyWebUserService.getAll(query, 'company').subscribe((operators: any[]) => {
            this.operatorData = operators;
            this.operatorFilteredData = this._dataTableService.filterData(operators, this.searchTerm, true);

            this.operatorFilteredData.forEach((data, i) => {
              this.operatorFilteredData[i]['name'] = `${data['fname']} ${data['lname']}`;
              if (data['emailAddress'] === '-' && data['User'] && data['User']['Driver'] && data['User']['Driver']['emailAddress']) {
                data['originalEmail'] = data['User']['Driver']['emailAddress'];
                data['driverUserId'] = data['User']['Driver']['userId'];
                const found = this.operatorFilteredData.filter((u) => {
                  return (u.emailAddress === data['originalEmail']);
                });
                data['userId'] = found[0]['userId'];
              }

              isDriver = companyInvitations.filter((d) => {
                return (d.phoneNumber === data.phoneNumber)
              });

              if (!isDriver[0]) {
                isDriver = drivers.filter((d) => {
                  return (d.phoneNumber === data.phoneNumber)
                });
              }

              this.operatorFilteredData[i]['is_driver'] = (isDriver[0] ? 'yes' : 'no');
            });
            this.operatorCount = this.operatorFilteredData.length;

            self.filter();

            if (!mapOnly) {
              self.prepareTableData();
              setTimeout(function () {
                self.resolveLoaders();
              }, 100);
            }
          });
        }, error => {
          console.error(error);
          self.resolveLoaders();
          ;
        });
      });
    }
  }

  deleteDriver(id: string, name: string): void {
    const self = this;
    name = this.translations[name];
    const message = self.messageDeleteX.formatUnicorn(name);
    const title = self.deleteX.formatUnicorn(name);

    self._dialogService.openConfirm({
      message: message,
      disableClose: false,
      title: title,
      cancelButton: self.translations['cancel'],
      acceptButton: self.translations['confirm_delete_driver'],
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        self._driverService.update(id, {'status': 'removed'}, 'company')
          .subscribe((result) => {
            if (result.status && result.status === 'removed') {
              self._snackbar.open(self.xDeleted.formatUnicorn(name), '', {duration: 3000});
              self.loadData();
            }
          }, (error) => {
            self._dialogService.openAlert({
              message: self.translations['driver_delete_error'],
              disableClose: true,
              title: self.translations[`driver_delete_${error.error.error.name.toLowerCase()}`],
              closeButton: self.translations['ok']
            }).afterClosed().subscribe(() => {
              self.loadData();
            });
          });
      }
    });
  }

  changeOwner(ownerId: string): void {
    const self = this;
    self._dialogService.open(ChangeOwnerComponent, {
      maxHeight: '80%',
      minWidth: '500px',
      data: {
        ownerId,
        parent: self
      },
    });
  }

  deleteWebUser(id: string): void {
    const self = this;

    const d = this.operatorFilteredData.filter((f) => {
      return (f.id === id);
    })[0];

    let msg = '';
    let cancelButton = '';
    if (d['is_driver']) {
      msg = self.translations['confirm_delete_operator_driver_message']
      cancelButton = self.translations['confirm_delete_operator_button'];
    } else {
      msg = self.translations['confirm_delete_operator_message']
      cancelButton = self.translations['confirm_delete_operator'];
    }

    self._dialogService.openConfirm({
      message: msg,
      disableClose: false,
      title: self.translations['confirm_delete_operator_title'],
      cancelButton: self.translations['ok'],
      acceptButton: cancelButton,
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        if (d['is_driver']) {
          self._driverService.update(id, {'status': 'removed'}, 'company')
            .subscribe(() => {
            });

          self._companyInvitationService.getAll({where: {'phoneNumber': encodeURIComponent(d.phoneNumber), 'companyId': self.companyId}}, self.context).subscribe((invites) => {
            if (invites && invites[0]) {
              self.retractInvite(invites[0].id);
            }
          });

          self._companyWebUserService.delete(id, 'company').subscribe(() => {
            self._snackbar.open(self.xDeleted.formatUnicorn(self.translations['webuser']), '', {duration: 3000});
            self.loadData();
          }, (error) => {
            if (error.error && error.error.error) {
              self._dialogService.openAlert({
                message: self.translations['operator_delete_error'],
                disableClose: true,
                title: self.translations[`operator_delete_${error.error.error.name.toLowerCase()}`],
                closeButton: self.translations['ok'],
              }).afterClosed().subscribe(() => {
                self.loadData();
              });
            } else {
              self.loadData();
            }
          });
        } else {
          this._companyWebUserService.delete(id, 'company').subscribe((result) => {
            self._snackbar.open(self.xDeleted.formatUnicorn(self.translations['webuser']), '', {duration: 3000});
            self.loadData();
          }, (error) => {
            if (error.error && error.error.error) {
              self._dialogService.openAlert({
                message: self.translations['operator_delete_error'],
                disableClose: true,
                title: self.translations[`operator_delete_${error.error.error.name.toLowerCase()}`],
                closeButton: self.translations['ok'],
              }).afterClosed().subscribe(() => {
                self.loadData();
              });
            } else {
              self.loadData();
            }
          });
        }
      }
    });
  }

  allowMember(driver: any): void {
    const self = this;

    const invitation: any = driver;
    delete invitation.id;
    delete invitation.type;
    delete invitation.modified;
    invitation.admin = false;
    invitation.status = 'pending';

    this._companyInvitationService.sendInvite([invitation], 'company').subscribe(result => {
      self._dialogService.openAlert({
        message: self.translations['driver_invited'],
        disableClose: true,
        title: self.translations['driver_invited_title'],
        closeButton: 'OK'
      }).afterClosed().subscribe(() => {
        self.loadData();
      });
    }, error => {
      console.error(error);
      this._loadingService.resolve('driver');
    });
  }

  denyMember(id: string): void {
    const self = this;
    this._driverService.update(id, {'status': 'blocked'}, 'company').subscribe((result) => {
      if (result.status && result.status === 'blocked') {
        self.loadData();
      }
    });
  }

  mapReady(map): void {
    const self = this;
    self.mapInstance = map;
    this.setBounds();
    this.interval = setInterval(function () {
      self.loadData(true);
      self.setBounds();
    }, (isDevMode() ? 10000 : 30000));
  }

  setBounds(): void {
    const self = this;
    // console.log('setBounds:', self.mapDrivers.length);
    if (!self.mapInstance) {
      setTimeout(function () {
        return self.setBounds();
      }, 250);
      return;
    }

    if(this.mapControlled) {
      return;
    }

    this.mapsAPILoader.load().then(() => {
      self.latlngBounds = new window['google'].maps.LatLngBounds();
      if (self.mapDrivers.length > 0) {
        self.mapDrivers.forEach((marker, i) => {
          self.latlngBounds.extend(
            new window['google'].maps.LatLng(
              marker.ActiveVehicle.location.gps.lat,
              marker.ActiveVehicle.location.gps.lng)
          );
          if (!marker[i + 1]) {
            self.mapInstance.fitBounds(self.latlngBounds);
            self.mapInstance.panToBounds(self.latlngBounds);
          }
        });
      } else {
        let useCountry = false;
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            if (position && position.coords) {
              self.mapInstance.setCenter({lat: position.coords.latitude, lng: position.coords.longitude});
              self.mapInstance.zoom = 14;
            } else {
              useCountry = true;
            }
          });
        } else {
          useCountry = true;
        }

        if (useCountry && self.company.country) {
          self._countryService.getAll({'where': {'alpha2Code': self.company.country}})
            .subscribe((countries) => {
              const country = countries[0];
              if (country && country.location) {
                self.mapInstance.fitBounds(country.location.geometry.viewport);
              } else {
                const geocodeService = new google.maps.Geocoder();
                geocodeService.geocode({'address': self.company.country}, (results, status) => {
                  if (status === 'OK') {
                    const data = results[0];
                    self.mapInstance.fitBounds(data.geometry.viewport);

                    if (country) {
                      self._countryService.update(country.id, {location: results[0]})
                        .subscribe(() => {

                        });
                    }
                  }
                });
              }
            })
        }
      }
    })
  }

  tabChanged($event) {
    const self = this;
    if ($event === 1) {
      setTimeout(function () {
        self.setBounds();
      }, 1000);

      setTimeout(function () {
        self.setBounds();
      }, 2000);
    }
  }

  toggleSize(infoWindow, size) {
    infoWindow.large = size;
  }

  retractInvite(id) {
    const self = this;
    self._companyInvitationService.get(id, {}, this.context).subscribe((invite) => {
      self._companyInvitationService.delete(id, this.context).subscribe(() => {
        self._driverService.getAll({
          where: {
            companyId: this.company.id,
            phoneNumber: encodeURIComponent(invite.phoneNumber)
          }
        }).subscribe((drivers) => {
          if (drivers[0]) {
            self._driverService.update(drivers[0].id, {'status': 'removed'}, this.context)
              .subscribe(() => {
              });
          }
          self._snackbar.open(self.xDeleted.formatUnicorn(self.translations['invite']), '', {duration: 2500});
          self.loadData();
        });
      });
    });
  }

  openDuplicateWarning(user, driverUserId) {
    const self = this;
    let message = this.translations['duplicate_webuser_warning_text'].replace(`{emailAddress}`, user.email)
    message = message.replace(`{phoneNumber}`, user.email);
    this._dialogService.openConfirm({
      title: this.translations['duplicate_webuser_warning_title'],
      message,
      disableClose: true,
      cancelButton: this.translations['cancel'],
      acceptButton: this.translations['request_confirmation'],
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        self._webPortalUserService.inviteConnectPortalUser({
          emailAddress: user.originalEmail,
          fname: user.fname,
          lname: user.lname,
          phoneNumber: user.phoneNumber,
        }, 'company')
          .subscribe(() => {

          });
      }
    });
  }

  export() {
    const data = [ {
      title: 'drivers',
      headers: this.meAsDriverExportColumns,
      rows: this.allFilteredData
    } ];

    console.log(this.allFilteredData);
    console.log(data);

    // if (this.context === 'company') {
    //   delete data[0];
    //   data[1].title = 'drivers';
    // }
    this._exporter.generate(`${this.company.name.toLowerCase().replace(/\ /g, '_')}`, this.translations, data);
  }

  editInvite(id) {
    const self = this;
    self._dialogService.open(EditInviteDialogComponent, {
      maxHeight: '80%',
      minWidth: '500px',
      data: {
        parent: self,
        company: self.company,
        inviteId: id
      },
    });
  }
}
