import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-planned-rides-widget',
  templateUrl: './planned-rides-widget.component.html',
  styleUrls: ['./planned-rides-widget.component.scss']
})
export class PlannedRidesWidgetComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
