<mat-card *ngIf="form" [formGroup]="form">
  <mat-card-title>{{'onboard_service_title' | translate | ucFirst}}</mat-card-title>
  <mat-card-subtitle>{{'onboard_service_subtitle' | translate | ucFirst}}</mat-card-subtitle>

  <mat-divider></mat-divider>

  <mat-card-content flex="100" layout="table">
    <strong>{{'product_details' |translate | ucFirst}}</strong>

    <div *ngFor="let product of enabledProducts; let i=index"
         class="push-top flow-pane {{(!this.removedProducts.includes(product._id) ? 'show' : 'hide')}}">
      <div *ngIf="!this.removedProducts.includes(product._id) ">
        <div *ngIf="mode==='select'" class="flex-pane push-bottom" flex="100"
             layout="row">
          <div *ngIf="product.product.imagePath" class="image-pane" flex="25">
            <img src="{{product.product.imagePath}}">
          </div>
          <div *ngIf="!product.product.imagePath" class="image-pane" flex="25">
            <img src="assets/yda/saloon.png">
          </div>

          <div class="product-pane" flex="60" flex-gt-xs="30" layout="column">
            <h3>{{product.product.name | translate | ucFirst}}</h3>
            <p>{{'max' | translate | ucFirst}} {{product.product.maxPassengers}} {{'passengers' | translate | ucFirst}}</p>
            <p>{{product.product.extraInfo}}</p>
            <p>{{product.product.extraDescription}}</p>
          </div>

          <div class="product-pane dynamic-example" flex="60" flex-gt-xs="30" layout="column">
            <h3>{{'product_prices' |translate | ucFirst}}</h3>
            <p>
              <mat-label>{{'start' | translate | ucFirst}} {{ 'price' | translate }}</mat-label>
              {{currencySymbol}} {{product.priceDynamic.dynamicStartPrice}}
            </p>
            <p>
              <mat-label>{{'kilometer' | translate | ucFirst}} {{ 'price' | translate }}</mat-label>
              {{currencySymbol}} {{product.priceDynamic.dynamicDistancePrice}}
            </p>
            <p>
              <mat-label>{{'minute' | translate | ucFirst}} {{ 'price' | translate }}</mat-label>
              {{currencySymbol}} {{product.priceDynamic.dynamicMinutePrice}}
            </p>
          </div>
          <div class="product-pane" flex="15" layout="column">
            <mat-icon (click)="deleteProduct(product._id, product.product._id)">delete</mat-icon>
            <mat-icon (click)="editProduct(product.product._id)">edit</mat-icon>
          </div>
        </div>

        <div [formGroupName]="'productId_'+product._id" class="push-top push-bottom">
          <div flex layout="row">
            <mat-form-field class="push-right price-input"
                            flex="30"
                            flex>
              <span matPrefix>{{currencySymbol}}&thinsp;</span>
              <mat-label>{{'start' | translate | ucFirst}} {{ 'price' | translate }}</mat-label>
              <input #dynamicStartPrice (blur)="valOrZero('productId_'+product._id,$event.target)"
                     (click)="$event.target.select()"
                     autocomplete="off"
                     formControlName="dynamicStartPrice"
                     matInput min="0" placeholder="{{ 'start' | translate | ucFirst }} {{ 'price' | translate }}"
                     step='0.01'
                     type="number">
            </mat-form-field>

            <mat-form-field class="push-right price-input"
                            flex="30"
                            flex>
              <span matPrefix>{{currencySymbol}}&thinsp;</span>
              <mat-label>{{'km'|distanceFormat:company:false | translate | ucFirst}} {{ 'price' | translate }}</mat-label>
              <input #dynamicDistancePrice (blur)="valOrZero('productId_'+product._id,$event.target)"
                     (click)="$event.target.select()"
                     autocomplete="off" formControlName="dynamicDistancePrice"
                     matInput
                     min="0"
                     placeholder="{{ 'km'|distanceFormat:company:true | translate | ucFirst }} {{ 'price' | translate
                      }}"
                     step='0.01' type="number">
            </mat-form-field>

            <mat-form-field class="push-right price-input"
                            flex="30"
                            flex>
              <span matPrefix>{{currencySymbol}}&thinsp;</span>
              <mat-label>{{'minute' | translate | ucFirst}} {{ 'price' | translate }}</mat-label>
              <input #dynamicMinutePrice (blur)="valOrZero('productId_'+product._id,$event.target)"
                     (click)="$event.target.select()"
                     autocomplete="off" formControlName="dynamicMinutePrice"
                     matInput
                     min="0"
                     placeholder="{{ 'minute' | translate | ucFirst }} {{ 'price' | translate }}"
                     step='0.01' type="number">
            </mat-form-field>
          </div>

          <a (click)="setMinimumPrice[product.product._id]='set'"
             *ngIf="setMinimumPrice[product.product._id]!=='set'"
             class="yda-link">{{'set_min_price' | translate | ucFirst}}</a>

          <div *ngIf="setMinimumPrice[product.product._id] === 'set'" flex layout="row">
            <mat-form-field class="push-right price-input"
                            flex="30"
                            flex>
              <mat-label>{{'miminum_price' | translate | ucFirst}}</mat-label>
              <span matPrefix>{{currencySymbol}}&thinsp;</span>
              <input #dynamicMinimumPrice (blur)="valOrZero('productId_'+product.product._id,$event.target)"
                     autocomplete="off"
                     formControlName="dynamicMinimumPrice"
                     matInput
                     min="0" placeholder="{{'miminum_price' | translate | ucFirst}}"
                     step='0.01' type="number">
            </mat-form-field>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>
    </div>

    <div class="push-top push-bottom flow-pane">
      <div flex="100" layout="row">
        <button (click)="selectProductTemplate()" class="left-align-button push-right add-service-button" color="secondairy"
                mat-raised-button>{{'add_service' | translate | ucFirst}}</button>
      </div>
    </div>

    <mat-divider></mat-divider>

    <mat-card-actions class="push-top push-bottom">
      <div class="progress">4/5 {{'completed'|translate|ucFirst}}</div>

      <div class="help_text">
        {{'service_subtext'|translate|ucFirst}}
      </div>

      <button (click)="setStep('skipped')" class="link-button"
              mat-button>{{'setup_later' | translate | ucFirst}}</button>

      <button (click)="setStep({step:'completed', data:{removedProducts}})" color="primary"
              mat-raised-button>{{'continue' | translate | ucFirst}}</button>
    </mat-card-actions>
  </mat-card-content>
</mat-card>
