import {Component, Inject, OnInit} from '@angular/core';
import {Ride} from '../../../../../../models/ride';
import {Company} from '../../../../../../models/company';
import {Passenger} from '../../../../../../models/passenger';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {RideService} from '../../../../../../services/ride.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-update-card-details',
  templateUrl: './update-card-details.component.html',
  styleUrls: ['./update-card-details.component.scss'],
  providers: [RideService]
})
export class UpdateCardDetailsComponent implements OnInit {
  ride: Ride;
  company: Company;
  passenger: Passenger;
  parent: any;
  form: UntypedFormGroup;

  constructor(
    private _rideService: RideService,
    private formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<UpdateCardDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const self = this;

    self.parent = data.parent;
    self.company = data.parent.company;
    _rideService.get(data.rideId, {'include': 'Passenger'}).subscribe((r) => {
      self.ride = r;
      self.passenger = r.passenger;
      self.passenger.id = r.passengerId;
      self.form = this.formBuilder.group({
        paymentMeta: this.formBuilder.group({
          origin: [self.ride.paymentMeta.origin, Validators.required],
          paymentMethodId: [self.ride.passenger.paymentMethodId, Validators.required],
        }),
      });
    })
  }

  ngOnInit(): void {
  }

  save(): void {
    this.dialogRef.close()
    this.parent._loadingService.register();
    this._rideService.update(this.ride.id, {'paymentMeta.paymentMethodId': this.form.controls['paymentMeta'].value['paymentMethodId'], 'paymentMeta': this.form.controls['paymentMeta'].value})
      .subscribe(() => {
        this.parent.loadData();
        this.parent._loadingService.resolve();
      })
  }
}
