<h3>&ensp;</h3>
<p *ngFor="let skip of [0, 1, 2]" class="row"></p>

<p class="row">
  <app-location-picker
    [allowEmpty]="false"
    [entity]="rule"
    [form]="form"
    [locations]="locations"
    floatingLabel="never">
  </app-location-picker>
</p>

<p *ngFor="let childForm of childRules(); let i = index"
   class="row">
  <app-location-picker
    *ngIf="locations"
    [allowEmpty]="false"
    [entity]="rule.childRules[i]"
    [form]="childForm"
    [type]="'fixed'"
    [locations]="locations"
    floatingLabel="never">
  </app-location-picker>
</p>
