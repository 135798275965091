<mat-card [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <mat-card-content class="push-bottom-none">
    <ng-template tdLoading="location-policy">
      <form *ngIf="form" [formGroup]="form">
        <div layout="row"><h3 class="push-top-none">Personal data</h3></div>
        <div *ngIf="action !== 'add'" class="push-top-sm" layout="row">
          <mat-form-field flex>
            <input disabled matInput placeholder="{{'debtor_code'|translate|ucFirst}}" type="text"
                   value="{{debtor.code}}">
          </mat-form-field>
        </div>
        <div layout="row">
          <mat-form-field class="push-right" flex>
            <input formControlName="firstName" matInput placeholder="{{'first_name'|translate|ucFirst}}" type="text"
                   value="{{debtor.firstName}}">
            <mat-hint align="start">
              <span [hidden]="!formErrors.firstName" class="tc-red-600">
                <span>{{ formErrors.firstName }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
          <mat-form-field class="" flex>
            <input formControlName="lastName" matInput placeholder="{{'last_name'|translate|ucFirst}}" type="text"
                   value="{{debtor.lastName}}">
            <mat-hint align="start">
              <span [hidden]="!formErrors.lastName" class="tc-red-600">
                <span>{{ formErrors.lastName }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
        </div>
        <div layout="row">
          <mat-form-field class="push-right" flex>
            <input formControlName="emailAddress" matInput placeholder="{{'email'|translate|ucFirst}}" type="text"
                   value="{{debtor.emailAddress}}">
            <mat-hint align="start">
              <span [hidden]="!formErrors.emailAddress" class="tc-red-600">
                <span>{{ formErrors.emailAddress }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
          <mat-form-field class="" flex>
            <input formControlName="phoneNumber" matInput placeholder="{{'phone_number'|translate|ucFirst}}" type="text"
                   value="{{debtor.phoneNumber}}">
            <mat-hint align="start">
              <span [hidden]="!formErrors.phoneNumber" class="tc-red-600">
                <span>{{ formErrors.phoneNumber }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
        </div>
        <div class="pad-bottom-sm" layout="row">
          <mat-form-field class="push-right" flex>
            <input formControlName="companyName" matInput placeholder="{{'debtor_company'|translate|ucFirst}}"
                   type="text" value="{{debtor.companyName}}">
            <mat-hint align="start">
              <span [hidden]="!formErrors.companyName" class="tc-red-600">
                <span>{{ formErrors.companyName }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
          <mat-form-field class="" flex>
            <input formControlName="note" matInput placeholder="{{'note'|translate|ucFirst}}" type="text"
                   value="{{debtor.note}}">
            <mat-hint align="start">
              <span [hidden]="!formErrors.note" class="tc-red-600">
                <span>{{ formErrors.note }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
        </div>

        <ng-container *ngIf="debtor.id">
          <mat-divider></mat-divider>
          <div layout="row"><h3 class="push-top-md">Address</h3></div>
          <div class="row">
            {{debtor.address.internationalAlias}}<br/>
            <span class="description">({{'edit_address_in_app'|translate|ucFirst}})</span>
          </div>
        </ng-container>

        <!--        <div layout="row">-->
        <!--          <mat-form-field flex class="push-right" formGroupName="address">-->
        <!--            <input matInput formControlName="streetName" type="text" placeholder="{{'street_name'|translate|ucFirst}}" value="{{debtor.address ? debtor.address.streetName : ''}}">-->
        <!--            <mat-hint align="start">-->
        <!--              <span [hidden]="!formErrors.streetName" class="tc-red-600">-->
        <!--                <span>{{ formErrors.streetName }}</span>-->
        <!--              </span>-->
        <!--            </mat-hint>-->
        <!--          </mat-form-field>-->
        <!--          <mat-form-field flex class="" formGroupName="address">-->
        <!--            <input matInput formControlName="houseNumber" type="text" placeholder="{{'house_number'|translate|ucFirst}}" value="{{debtor.address ? debtor.address.houseNumber : ''}}">-->
        <!--            <mat-hint align="start">-->
        <!--              <span [hidden]="!formErrors.houseNumber" class="tc-red-600">-->
        <!--                <span>{{ formErrors.houseNumber }}</span>-->
        <!--              </span>-->
        <!--            </mat-hint>-->
        <!--          </mat-form-field>-->
        <!--        </div>-->
        <!--        <div layout="row">-->
        <!--          <mat-form-field flex class="push-right" formGroupName="address">-->
        <!--            <input matInput formControlName="city" type="text" placeholder="{{'city'|translate|ucFirst}}" value="{{debtor.address ? debtor.address.city : ''}}">-->
        <!--            <mat-hint align="start">-->
        <!--              <span [hidden]="!formErrors.city" class="tc-red-600">-->
        <!--                <span>{{ formErrors.city }}</span>-->
        <!--              </span>-->
        <!--            </mat-hint>-->
        <!--          </mat-form-field>-->
        <!--          <mat-form-field flex class="" formGroupName="address">-->
        <!--            <input matInput formControlName="postalCode" type="text" placeholder="{{'postal_code'|translate|ucFirst}}" value="{{debtor.address ? debtor.address.postalCode : ''}}">-->
        <!--            <mat-hint align="start">-->
        <!--              <span [hidden]="!formErrors.postalCode" class="tc-red-600">-->
        <!--                <span>{{ formErrors.postalCode }}</span>-->
        <!--              </span>-->
        <!--            </mat-hint>-->
        <!--          </mat-form-field>-->
        <!--        </div>-->
        <!--        <div layout="row">-->
        <!--          <mat-form-field flex class="push-right" formGroupName="address">-->
        <!--            <input matInput formControlName="description" type="text" placeholder="{{'description'|translate|ucFirst}}" value="{{debtor.address ? debtor.address.description : ''}}">-->
        <!--            <mat-hint align="start">-->
        <!--              <span [hidden]="!formErrors.description" class="tc-red-600">-->
        <!--                <span>{{ formErrors.description }}</span>-->
        <!--              </span>-->
        <!--            </mat-hint>-->
        <!--          </mat-form-field>-->
        <!--        </div>-->
      </form>
    </ng-template>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <button (click)="save()"
            [disabled]="!form.valid"
            color="accent"
            flex
            mat-raised-button>{{'save_debtor_button'|translate|ucFirst}}</button>
    <button (click)="delete()"
            *ngIf="action !== 'add'"
            class="float-right"
            color="warn"
            flex
            mat-raised-button>{{'delete_debtor_button'|translate|ucFirst}}</button>
  </mat-card-actions>
</mat-card>
