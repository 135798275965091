import {GoogleMapsAPIWrapper} from '@agm/core';
import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
// @ts-ignore
import {} from '@types/googlemaps'
import {Shape} from 'app/models/tps/shape';

const getBounds = (
  polygons: google.maps.Polygon[]
): google.maps.LatLngBounds => {
  const bounds = new google.maps.LatLngBounds();
  polygons.map(p => p.getPath()
    .forEach((el, i) => bounds.extend(el)));
  return bounds;
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
  providers: [GoogleMapsAPIWrapper]
})
export class MapComponent {
  @Input() height = 30;

  @Input() allowEditMode = true;
  @Input() allowDrawingMode = true;
  @Input() location: UntypedFormGroup;
  @Output() nrShapes: EventEmitter<number> = new EventEmitter();
  @Output() mapReady: EventEmitter<google.maps.Map> = new EventEmitter();
  @Output() mapHtmlReady: EventEmitter<HTMLInputElement> = new EventEmitter();
  @ViewChild('map') mapHtml: HTMLInputElement;

  map: google.maps.Map;
  drawingManager: google.maps.drawing.DrawingManager;
  shapes: google.maps.Polygon[] = [];
  selected: google.maps.Polygon;
  isDirty = false;
  protected invalidShape = false;

  constructor(private _gMapsApi: GoogleMapsAPIWrapper) {
}

  /**
   * Enable editing of existing shapes.
   */
  setEditMode = (edit: boolean, drag: boolean = false) => {
    console.log('setEditMode');
    const fillOpacity = drag ? 0.15 : 0.35;
    const makeEditable = (s: google.maps.Polygon) =>
      s.setOptions({editable: edit, draggable: drag, fillOpacity, geodesic: true});
    this.shapes.map(makeEditable);
  }

  public onKeydown = function (event) {
    // console.log(event);
  }
  /**
   * Allow creation of new shapes.
   */
  setDrawingMode = (enabled = true) =>
    (enabled)
      ? this.initDrawingManager(this.trackShape)
      : this.killDrawingManager();

  /**
   * GeoJSON geometry standard.
   * http://geojson.org/
   */
  addGeometry = (
    geometry: { type: string, coordinates: any }
  ): void => {
    const {type, coordinates} = geometry;
    Shape.autoDetect(coordinates)
      .map(shape => this.trackShape(shape, false));
    this.fitBounds();
    this.persist();
  }

  /**
   * Add point, radius must be provided if viewport is empty.
   */
  addPoint = (
    geometry: {
      type: string,
      coordinates: any,
      viewport?: any,
      radius?: number
    }
  ) => {
    const defaultRadius = 200;
    const {type, coordinates} = geometry;
    let {radius} = geometry;

    if (!radius) {
      const getRadius = geometry.viewport
        ? () =>
          google.maps.geometry.spherical.computeDistanceBetween(
            new google.maps.LatLng(
              geometry.viewport.getSouthWest().lat(),
              geometry.viewport.getSouthWest().lng()
            ),
            new google.maps.LatLng(
              geometry.viewport.getNorthEast().lat(),
              geometry.viewport.getNorthEast().lng()
            )
          )
        : () => geometry.radius
          || defaultRadius;

      /**
       * Devide by 2 we need the radius of the circle
       */
      radius = (getRadius() / 2);
    }

    Shape.point(coordinates, radius)
      .map(shape => this.trackShape(shape, false));
    const self = this;
    setTimeout(function() {
      self.fitBounds();
    }, 250);
    this.persist();
  }

  /**
   * Remove geometry from the map.
   */
  clearGeometry = (): void => {
    this.shapes.map(s => s.setMap(null));
    this.shapes = [];
  }

  /**
   * Persist shapes into location.
   */
  persist(): void {
    const points = this.shapes.map(p => p.getPath()
      .getArray()
      .concat(p.getPath().getAt(0))
      .map(point => [point.lat(), point.lng()]))

    if (points && !points[0]) {
      return;
    }

    const isMultiPolygon = points.length > 1;
    const polygonSelfIntersect  = require('polygon-selfintersect')


    if (!polygonSelfIntersect.findSelfIntersections(points[0])) {
      this.location.patchValue({
        area: {
          coordinates: isMultiPolygon ? points.map(p => [p]) : points,
          type: isMultiPolygon ? 'MultiPolygon' : 'Polygon',
        },
        point: getBounds(this.shapes).getCenter()
      })

      this.shapes.map(p => p.setMap(this.map))
    } else {
      this.invalidShape = true;
    }
  }

  /**
   * Marks the map as dirty, meaning that a shape has changed and hasn't
   * been persisted.
   */
  markAsDirty = () => (() => {
    this.isDirty = true;
    this.location.markAsTouched();
    this.location.markAsDirty();
  })();

  /**
   * Marks the map as pristine, meaning that a shape hasn't changed.
   */
  markAsPristine() {
    this.isDirty = false;
    this.location.markAsUntouched();
    this.location.markAsPristine();
  }

  /**
   * Enables drawing on double click, enables save button
   * if something was changed.
   */
  public dblclick(event) {
    if (event.which === 1) {
      this.setDrawingMode(true);
    }
  }

  /**
   * Cancel drawing when right mouse button is pressed.
   */
  public click(event) {
    if (event.which === 3) {
      this.setDrawingMode(false);
    }
  }

  public keydown(event) {
    // console.log(event);
  }

  /**
   * Initialize map.
   */
  public initMap(
    map: google.maps.Map,
    lat: number = 52.4,
    lng: number = 5.0
  ): void {
    this.map = map;
    this.mapReady.emit(this.map);
    this.mapHtmlReady.emit(this.mapHtml);
    map.setOptions({
      center: new google.maps.LatLng(lat, lng),
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDoubleClickZoom: true,
      disableDefaultUI: true,
      draggable: true,
      streetViewControl: false,
      scaleControl: true,
      zoomControl: true,
      minZoom: 3,
      maxZoom: 20,
      zoom: 8,
    });

    if (this.location.value.area && this.location.value.area.coordinates && this.location.value.area.coordinates.length > 0) {
      this.addGeometry(this.location.value.area)
    }
  }

  /**
   * Add polygon to map and update location centroid.
   */
  private trackShape = (
    polygon: google.maps.Polygon,
    persist = true
  ): void => {
    const self = this;
    if (polygon.getPath()) {
      // Only polygons with 3 or more indices are allowed
      const nrIndices = polygon.getPath().getLength();
      if (nrIndices < 3) {
        polygon.setMap(null);
        return;
      }

      // Track new shape on map, in array,
      // and in potential parent component
      polygon.setMap(this.map);
      polygon.setEditable(this.allowEditMode);
      polygon.addListener('mouseup', this.markAsDirty);
      polygon.addListener('dragend', this.markAsDirty);
      google.maps.event.addListener(polygon, 'click', function () {
        this.selected = polygon;
      });

      polygon.setDraggable(this.allowEditMode);

      this.shapes.push(polygon);
      this.nrShapes.emit(this.shapes.length);

      polygon.addListener('mouseup', function() {
        const points = self.shapes.map(p => p.getPath()
          .getArray()
          .concat(p.getPath().getAt(0))
          .map(point => [point.lat(), point.lng()]))

        const polygonSelfIntersect  = require('polygon-selfintersect')
        if (polygonSelfIntersect.findSelfIntersections(points[0])) {
          self.invalidShape = true;
        } else {
          self.invalidShape = false;
        }
      });

      // Update location form
      this.location.patchValue({
        point: getBounds(this.shapes).getCenter()
      });

      // This is a heavy operation, that can be disabled via the persist
      // flag to prevent heavy operations in loops for example
      if (persist) {
        this.markAsDirty();
        this.fitBounds();
        this.persist();
      }
    }
  }

  /**
   * Center map.
   */
  private center(lat: number, lng: number) {
    this.map.setCenter(new google.maps.LatLng(lat, lng));
  }

  /**
   * Fit map bounds.
   */
  public fitBounds() {
    this.map.fitBounds(getBounds(this.shapes));
   this.map.setZoom(this.map.getZoom())
  };

  /**
   * Initialize drawingManager.
   */
  private killDrawingManager() {
    console.log('killDrawingManager');
    if (this.drawingManager) {
      this.drawingManager.setMap(null);
      this.drawingManager = null;
    }
  }

  private initDrawingManager(handleNewShape: any): void {
    console.log('initDrawingManager');
    if (this.drawingManager) {
      return
    }
    if (!this.allowDrawingMode) {
      return
    }
    const Polygon = google.maps.drawing.OverlayType.POLYGON;
    const manager = new google.maps.drawing.DrawingManager({
      drawingMode: Polygon,
      drawingControl: true,
      drawingControlOptions: {
        drawingModes: [Polygon]
      },
      polygonOptions: Shape.defaultShapeStyle,
    });
    google.maps.event.addListener(
      manager,
      'polygoncomplete',
      handleNewShape
    );
    this.drawingManager = manager;
    manager.setMap(this.map);
  }
}


