<ng-template tdLoading="shift.details">
  <mat-card tdMediaToggle="gt-xs">
    <mat-card-title>{{'shift_details'|translate}}</mat-card-title>
    <mat-card-subtitle>{{'shift_details_subtitle'|translate}}</mat-card-subtitle>
    <mat-divider></mat-divider>
    <div class="inset" layout="column" layout-gt-sm="row">
      <div flex-gt-sm="50">
        <div layout="column">
          <mat-list>
            <mat-list-item>
              <mat-icon matListAvatar>assignment</mat-icon>
              <h4 matLine>{{shift.identifier}}</h4>
              <p matLine>ID</p>
            </mat-list-item>
            <mat-list-item *ngIf="shift.startTime" class="pull-top">
              <mat-icon matListAvatar>query_builder</mat-icon>
              <h4
                matLine>{{shift.startTime | amDateFormat:(this.company && this.company.dateFormat ? this.company.dateFormat : 'DD-MM-YYYY')}} {{'at'|translate}} {{shift.startTime | amDateFormat:'HH:mm'}}</h4>
              <p matLine>{{'start_time'|translate}}</p>
            </mat-list-item>
            <mat-list-item *ngIf="shift.endTime" class="pull-top">
              <mat-icon matListAvatar>query_builder</mat-icon>
              <h4
                matLine>{{shift.endTime | amDateFormat:(this.company && this.company.dateFormat ? this.company.dateFormat : 'DD-MM-YYYY')}}  {{'at'|translate}} {{shift.endTime | amDateFormat:'HH:mm'}}</h4>
              <p matLine>{{'end_time'|translate}}</p>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
      <div flex-gt-sm="50">
        <div layout="column">
          <mat-list>
            <mat-list-item>
              <mat-icon matListAvatar>local_taxi</mat-icon>
              <h4 matLine>{{shift.Vehicle.brand}} {{shift.Vehicle.model}} ({{shift.Vehicle.licensePlate}})</h4>
              <p matLine>{{'vehicle'|translate}}</p>
            </mat-list-item>
            <mat-list-item *ngIf="shift.deviceDetails" class="pull-top">
              <mat-icon matListAvatar>smartphone</mat-icon>
              <h4 matLine>{{shift.deviceDetails.deviceBrand}} {{shift.deviceDetails.deviceModel}}
                ({{shift.deviceDetails.deviceOs}} {{shift.deviceDetails.osVersion}} - {{'app_version'|translate}}
                : {{shift.deviceDetails.appVersion}})</h4>
              <p matLine>{{'device'|translate}}</p>
            </mat-list-item>
            <mat-list-item *ngIf="shift.startGpsLocation" class="pull-top">
              <mat-icon matListAvatar>location_on</mat-icon>
              <h4 matLine>{{shift.startGpsLocation}}</h4>
              <p matLine>{{'start_location'|translate}}</p>
            </mat-list-item>
            <mat-list-item *ngIf="shift.endGpsLocation" class="pull-top">
              <mat-icon matListAvatar>location_on</mat-icon>
              <h4 matLine>{{shift.endGpsLocation}}</h4>
              <p matLine>{{'end_location'|translate}}</p>
            </mat-list-item>
          </mat-list>
        </div>
      </div>
    </div>
  </mat-card>
  <mat-card tdMediaToggle="gt-xs">
    <!--<td-search-box class="push-left push-right" placeholder="Search here" [alwaysVisible]="true" (searchDebounce)="search($event)"></td-search-box>-->
    <!--<td-search-box #searchBox backIcon="arrow_back" class="push-right-sm" placeholder="Search here" (searchDebounce)="search($event)" flex></td-search-box>-->
    <mat-divider></mat-divider>
    <mat-tab-group (selectedTabChange)="onTabChange($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon matPrefix>list</mat-icon>
          {{'list_view'|translate}}
        </ng-template>
        <td-search-box (searchDebounce)="listViewSearch($event)" [alwaysVisible]="true" class="push-left push-right"
                       placeholder="{{'search_here'|translate}}"></td-search-box>
        <mat-divider></mat-divider>
        <table td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="listViewSort($event)"
                *ngFor="let c of listViewColumns"
                [active]="(listViewSortBy == c.name)"
                [name]="c.name"
                [numeric]="c.numeric"
                [sortOrder]="( listViewSortOrder == 'ASC' ? 'DESC' : 'ASC' )"
                [sortable]="c.sortable"
                td-data-table-column>
              {{c.label|translate}}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of listViewFilteredData" td-data-table-row>
            <td *ngFor="let c of listViewColumns" [numeric]="item.numeric" td-data-table-cell>
                <span [ngSwitch]="c.name">
                  <span *ngSwitchCase="'startTime'"><span
                    *ngIf="item[c.name] != 'null'">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>
                  <span *ngSwitchCase="'endTime'"><span
                    *ngIf="item[c.name] != 'null'">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>
                  <span *ngSwitchCase="'startLocation'"><span *ngIf="item[c.name]"><span class="wrap-line"
                                                                                         title="{{item[c.name]}}">{{item[c.name]}}</span></span></span>
                  <span *ngSwitchCase="'endLocation'"><span *ngIf="item[c.name]"><span class="wrap-line"
                                                                                       title="{{item[c.name]}}">{{item[c.name]}}</span></span></span>
                  <span *ngSwitchCase="'code'">{{'status_' + item.code|translate|ucFirst}}</span>
                  <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span><span
                    *ngIf="!item[c.name]">-</span></span>
                </span>
            </td>
          </tr>
          </tbody>
        </table>
        <td-paging-bar #listViewPagingBar (change)="listViewPage($event)" [initialPage]="1" [pageSize]="20"
                       [total]="listViewFilteredTotal">
          <span hide-xs td-paging-bar-label>{{'rows_p_page'|translate}}:</span>
          {{listViewPagingBar.range}} <span hide-xs>of {{listViewPagingBar.total}}</span>
        </td-paging-bar>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label tdLoading="gps.mapLoader">
          <mat-icon>map</mat-icon>
          {{'map_view'|translate}}
        </ng-template>
        <mat-card-content>
          <agm-map (mapReady)="mapReady($event)" *ngIf="shift.startLocation"
                   [latitude]="shift.startLocation.gps.lat" [longitude]="shift.startLocation.gps.lng" [usePanning]="true">
            <agm-marker
              *ngFor="let marker of markerGpsLogEntries; let iA = index; let last = last;"
              [iconUrl]="iconUrl"
              [latitude]="marker.location.gps.lat"
              [longitude]="marker.location.gps.lng">
              <agm-info-window #infoWindow *ngIf="(iA % 1 == 0 || last)"
                               [disableAutoPan]="true" [isOpen]="true">
                <div [class]="(last ? 'infoWindow lastWindow' : 'infoWindow')">
                  <!--                  {{marker.created | amDateFormat:(this.company&&this.company.dateFormat?''+this.company.dateFormat+' '+this.company.clockFormat:'DD-MM-YYYY  HH:mm')}}--> {{iA + 1}}
                </div>
              </agm-info-window>
            </agm-marker>
            <agm-polyline *ngFor="let item of markerGpsLogEntries; let iB = index; let last = last;"
                          [strokeColor]="(last ? '#FF0000' : mapLineColor)">
              <agm-polyline-point *ngIf="!last" [latitude]="markerGpsLogEntries[iB].location.gps.lat"
                                  [longitude]="markerGpsLogEntries[iB].location.gps.lng"></agm-polyline-point>
              <agm-polyline-point *ngIf="!last" [latitude]="markerGpsLogEntries[(iB + 1)].location.gps.lat"
                                  [longitude]="markerGpsLogEntries[(iB + 1)].location.gps.lng"></agm-polyline-point>
            </agm-polyline>
            <shift-map [markers]="markerGpsLogEntries"></shift-map>
          </agm-map>
          <ng-template *ngIf="!shift.startGps">{{'map_not_available'|translate}}</ng-template>
        </mat-card-content>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>local_taxi</mat-icon>
          {{'jobs'|translate}}
        </ng-template>
        <td-search-box (searchDebounce)="ridesSearch($event)" [alwaysVisible]="true" class="push-left push-right"
                       placeholder="{{'search_here'|translate}}"></td-search-box>
        <mat-divider></mat-divider>
        <table class="mat-clickable" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="ridesSort($event)"
                *ngFor="let c of ridesColumns"
                [active]="(ridesSortBy == c.name)"
                [name]="c.name"
                [numeric]="c.numeric"
                [sortOrder]="( ridesSortOrder == 'ASC' ? 'DESC' : 'ASC' )"
                [sortable]="c.sortable"
                td-data-table-column>
              {{c.label|translate}}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of ridesFilteredData" routerLink="/jobs/{{item.id}}" td-data-table-row>
            <td *ngFor="let c of ridesColumns" [numeric]="item.numeric" td-data-table-cell>
                <span [ngSwitch]="c.name">
                  <span *ngSwitchCase="'requestedDate'"><span
                    *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>
                  <span *ngSwitchCase="'departure'"><span *ngIf="item[c.name]"><span class="wrap-line"
                                                                                     title="{{item[c.name]}}">{{item[c.name]}}</span></span><span
                    *ngIf="!item[c.name]">-</span></span>
                  <span *ngSwitchCase="'destination'"><span *ngIf="item[c.name]"><span class="wrap-line"
                                                                                       title="{{item[c.name]}}">{{item[c.name]}}</span></span><span
                    *ngIf="!item[c.name]">-</span></span>
                  <span *ngSwitchCase="'status'"><span
                    *ngIf="item[c.name]">{{'ride_status_' + item[c.name] | translate}}</span><span
                    *ngIf="!item[c.name]">-</span></span>
                  <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span><span
                    *ngIf="!item[c.name]">-</span></span>
                </span>
            </td>
          </tr>
          </tbody>
        </table>
        <td-paging-bar #ridesPagingBar (change)="ridesPage($event)" [initialPage]="1" [pageSize]="20"
                       [total]="ridesFilteredTotal">
          <span hide-xs td-paging-bar-label>{{'rows_p_page'|translate}}:</span>
          {{ridesPagingBar.range}} <span hide-xs>of {{ridesPagingBar.total}}</span>
        </td-paging-bar>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>list</mat-icon>
          {{'gps_log'|translate}}
        </ng-template>
        <td-search-box (searchDebounce)="gpsLogSearch($event)" [alwaysVisible]="true" class="push-left push-right"
                       placeholder="{{'search_here'|translate}}"></td-search-box>
        <mat-divider></mat-divider>
        <table td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="gpsLogSort($event)"
                *ngFor="let c of gpsLogColumns"
                [active]="(gpsLogSortBy == c.name)"
                [name]="c.name"
                [numeric]="c.numeric"
                [sortOrder]="( gpsLogSortOrder == 'ASC' ? 'DESC' : 'ASC' )"
                [sortable]="c.sortable"
                td-data-table-column>
              {{c.label|translate|ucFirst}}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of gpsLogFilteredData" td-data-table-row>
            <td *ngFor="let c of gpsLogColumns" [numeric]="item.numeric" td-data-table-cell>
              <span [ngSwitch]="c.name">
                <span *ngSwitchCase="'created'"><span
                  *ngIf="item[c.name]">{{item[c.name] | amDateFormat:'DD-MM-YYYY HH:mm:ss'}}</span></span>
                <span *ngSwitchCase="'speed'">
                  <span *ngIf="item[c.name]">{{item[c.name] | speedPerHour}} {{speedUnit}}</span>
                  <span *ngIf="!item[c.name]">0 {{speedUnit}}</span>
                </span>
                <span *ngSwitchCase="'code'">{{'status_' + item.code|translate|ucFirst}}</span>
                <span *ngSwitchCase="'accuracy'">{{item[c.name]}} m.</span>
                <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span><span
                  *ngIf="!item[c.name]">-</span></span>
              </span>
            </td>
          </tr>
          </tbody>
        </table>
        <td-paging-bar #gpsLogPagingBar (change)="gpsLogPage($event)" [initialPage]="1" [pageSize]="20"
                       [total]="gpsLogFilteredTotal">
          <span hide-xs td-paging-bar-label>{{'rows_p_page'|translate}}:</span>
          {{gpsLogPagingBar.range}} <span hide-xs>of {{gpsLogPagingBar.total}}</span>
        </td-paging-bar>
      </mat-tab>
    </mat-tab-group>
  </mat-card>
</ng-template>

