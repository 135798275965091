<mat-card [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <mat-card-title>{{'select_dispatch_selection'|translate}}</mat-card-title>
  <mat-card-subtitle>{{'select_dispatch_selection_subtitle'|translate}}</mat-card-subtitle>
  <mat-divider></mat-divider>
  <div [mediaClasses]="[]" layout-gt-sm="row" tdMediaToggle="gt-xs">
    <mat-form-field class="push-left push-right push-top" flex>
      <mat-select (selectionChange)="selectAttempt($event)" [value]="selectedIndex"
                  placeholder="{{'dispatch_attempt'|translate}}">
        <mat-option *ngFor="let log of data; let i = index" [value]="i">
          {{ log.dispatchStartTime | amDateFormat:(this.company && this.company.dateFormat ? this.company.dateFormat : 'DD-MM-YYYY')}} {{'at'|translate}} {{log.dispatchStartTime | amDateFormat:'HH:mm:ss'}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</mat-card>
<mat-card *ngIf="dataSet" [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <mat-card-title>{{'settings'|translate|ucFirst}}</mat-card-title>
  <mat-divider></mat-divider>
  <div *ngIf="dataSet && dataSet.Offer" layout="row" layout-gt-sm="row">
    <div flex-gt-sm="33">
      <div *ngIf="dataSet.createdBy" layout="column">
        <mat-list>
          <mat-list-item>
            <mat-icon matListAvatar>settings</mat-icon>
            <h4 matLine>{{dataSet.createdByTxt|translate|ucFirst}}</h4>
            <p matLine>{{'dispatch_started_by'|translate|ucFirst}}</p>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
    <div flex-gt-sm="33">
      <div layout="column">
        <mat-list>
          <mat-list-item>
            <mat-icon matListAvatar>settings</mat-icon>
            <h4 matLine>{{dataSet.Offer.modified|amDateFormat:'HH:mm DD-MM-YYYY'}}</h4>
            <p matLine>{{'ended'|translate|ucFirst}}</p>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
    <div flex-gt-sm="33">
      <div layout="column">
        <mat-list>
          <mat-list-item>
            <mat-icon matListAvatar>settings</mat-icon>
            <h4 matLine>{{dataSet.Offer.status|translate|ucFirst}}</h4>
            <p matLine>{{'status'|translate|ucFirst}}</p>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </div>
  <div layout="row" layout-gt-sm="row">
    <div flex-gt-sm="33">
      <div *ngIf="dataSet && dataSet.Offer" layout="column">
        <mat-list>
          <mat-list-item>
            <mat-icon matListAvatar>settings</mat-icon>
            <h4 matLine>{{dataSet.Offer.mode|ucFirst}}</h4>
            <p matLine>{{'mode'|translate|ucFirst}}</p>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
    <div *ngIf="dataSet.dispatchSettings && dataSet.dispatchSettings.maxOfferTime" flex-gt-sm="33">
      <div layout="column">
        <mat-list>
          <mat-list-item>
            <mat-icon matListAvatar>timer</mat-icon>
            <h4 matLine>{{(dataSet.dispatchSettings.maxOfferTime / 60).toFixed(0)}} {{'minutes'|translate}}</h4>
            <p matLine>{{'max_offer_time'|translate|ucFirst}}</p>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
    <div *ngIf="dataSet.dispatchSettings && dataSet.dispatchSettings.MaxEtp" flex-gt-sm="33">
      <div layout="column">
        <mat-list>
          <mat-list-item>
            <mat-icon matListAvatar>timer</mat-icon>
            <h4 matLine>{{(dataSet.dispatchSettings.MaxEtp / 60).toFixed(0)}} {{'minutes'|translate}}</h4>
            <p matLine>{{'max_etp'|translate|ucFirst}}</p>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </div>
  <mat-divider></mat-divider>
  <ng-container *ngIf="dataSet">
    <mat-tab-group (selectChange)="onTabChange($event)" *tdLoading="'attempt'">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>list</mat-icon>
          {{'list_view'|translate}}
        </ng-template>
        <table class="mat-clickable" td-data-table>
          <thead>
          <tr td-data-table-column-row>
            <th (sortChange)="tableSort($event)"
                *ngFor="let c of columns"
                [active]="(tableSortBy == c.name)"
                [name]="c.name"
                [numeric]="c.numeric"
                [sortOrder]="(tableSortOrder == 'ASC' ? 'DESC' : 'ASC')"
                [sortable]="c.sortable"
                td-data-table-column>
              {{c.label|translate|ucFirst}}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor="let item of tableFilteredData" [ngClass]="(item.selected ? 'selected' : '')" td-data-table-row>
            <td *ngFor="let c of columns" [numeric]="item.numeric" td-data-table-cell>
                <span [ngSwitch]="c.name">
                  <span *ngSwitchCase="'jobOfferSent'"><span
                    *ngIf="item[c.name]">{{item[c.name] | amDateFormat:'HH:mm:ss'}}</span><span
                    *ngIf="!item[c.name]">-</span></span>
                  <span *ngSwitchCase="'jobOfferDelivered'"><span
                    *ngIf="item[c.name]">{{item[c.name] | amDateFormat:'HH:mm:ss'}}</span><span
                    *ngIf="!item[c.name]">-</span></span>
                  <span *ngSwitchCase="'jobOfferResponseTime'"><span
                    *ngIf="item[c.name]">{{item[c.name] | amDateFormat:'HH:mm:ss'}}</span><span
                    *ngIf="!item[c.name]">-</span></span>
                  <span *ngSwitchCase="'etp'"><span
                    *ngIf="item[c.name]">{{item[c.name] | amDateFormat:'HH:mm'}}</span><span
                    *ngIf="!item[c.name]">-</span></span>
                  <span *ngSwitchCase="'vehicleStatus'"><span
                    *ngIf="item[c.name]">{{'status_' + item[c.name] | translate | ucFirst}}</span><span
                    *ngIf="!item[c.name]">-</span></span>
                  <span *ngSwitchCase="'straightDistance'"><span
                    *ngIf="item[c.name]">{{item[c.name]}} {{'km'|translate}}</span><span *ngIf="!item[c.name]">-</span></span>
                  <span *ngSwitchCase="'travelDistance'"><span
                    *ngIf="item[c.name]">{{item[c.name]}} {{'km'|translate}}</span><span *ngIf="!item[c.name]">-</span></span>
                  <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]|ucFirst}}</span><span
                    *ngIf="!item[c.name]">-</span></span>
                </span>
            </td>
          </tr>
          </tbody>
        </table>
        <td-paging-bar #tablePagingBar (change)="tablePage($event)" [pageSize]="100" [total]="tableFilteredTotal">
          <span hide-xs td-paging-bar-label>{{'rows_p_page'|translate}}:</span>
          {{tablePagingBar.range}} <span hide-xs>of {{tablePagingBar.total}}</span>
        </td-paging-bar>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>map</mat-icon>
          {{'map_view'|translate}}
        </ng-template>
        <agm-map (mapReady)="mapReady($event)"
                 *ngIf="dataSet"
                 [gestureHandling]="cooperative"
                 [latitude]="ride.departure.gps.lat"
                 [longitude]="ride.departure.gps.lng"
                 [scrollwheel]="false"
                 [usePanning]="true">
          <app-ride-dispatch-log-map [dataSet]="dataSet" [ride]="ride"></app-ride-dispatch-log-map>
        </agm-map>
      </mat-tab>
    </mat-tab-group>
  </ng-container>
</mat-card>

