import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CoolLocalStorage} from '@angular-cool/storage';
import {UtilityService} from './utility.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {Debtor} from '../models/debtor';

@Injectable()
export class DebtorService {

  constructor(private _http: HttpClient, private _vault: CoolLocalStorage, private _utilityService: UtilityService) {
  }

  getAll(filter?: Object, context?: string): Observable<any[]> {
    filter = JSON.stringify(this._utilityService.restrictCompanyFilter(filter, (context === 'company')));
    return this._http
      .get<any[]>(`${environment.apiBaseUrl}/debtors?filter=${filter}`, {headers: this.getHeaders(context)});
  }

  get(id: string, filter?: Object, context?: string): Observable<any> {
    return this._http
      .get<any>(`${environment.apiBaseUrl}/debtors/${id}?filter=${JSON.stringify(filter || {})}`, {headers: this.getHeaders(context)});
  }

  insert(debtor: Debtor, context?: string): Observable<Debtor> {
    return this._http.post<Debtor>(`${environment.apiBaseUrl}/debtors/`, debtor, {headers: this.getHeaders(context)})
  }

  update(id: string, debtor: Debtor, context?: string): Observable<Debtor> {
    return this._http.patch<Debtor>(`${environment.apiBaseUrl}/debtors/${id}`, debtor, {headers: this.getHeaders(context)});
  }

  delete(id: string, context?: string): Observable<any> {
    return this._http.delete(`${environment.apiBaseUrl}/debtors/${id}`, {headers: this.getHeaders(context)});
  }

  private getHeaders(context?: string) {
    const userAccessToken: any = this._vault.getObject(`${environment.vaultPrefix}.accessToken`);

    if (userAccessToken !== null && context !== 'company') {
      return new HttpHeaders().set('Accept', 'application/json').set('Authorization', userAccessToken.id);
    } else {
      return new HttpHeaders().set('Accept', 'application/json').set('Authorization', environment.apiAccessToken);
    }
  }
}
