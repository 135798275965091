import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {ActivatedRoute} from '@angular/router';
import {NavigationService} from '../../../../../../../../../services/navigation.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PaymentBalanceService} from '../../../../../../../../../services/payment-balance.service';
import {getCurrencySymbol} from '@angular/common';

@Component({
  selector: 'app-payment-balance-record-add',
  templateUrl: './payment-balance-record-add.component.html',
  providers: [PaymentBalanceService],
  styleUrls: ['./payment-balance-record-add.component.scss']
})
export class PaymentBalanceRecordAddComponent implements OnInit {
  parentData: any;
  currencyDisplay: any;
  totalFormat: any;
  currencySymbol: any;
  translations: string[];
  form: UntypedFormGroup;
  formErrors: any = {
    name: '',
  };
  balanceRecordTypes = ['CUSTOM', 'REFUND_PROCESSED', 'JOB_CANCELED', 'CANCELLATION_CHARGE'];
  recordDirection = ['payment', 'refund'];

  constructor(public snackBar: MatSnackBar,
              private _translateService: TranslateService,
              private _formBuilder: UntypedFormBuilder,
              private _paymentBalanceService: PaymentBalanceService,
              private _route: ActivatedRoute,
              private _navigationService: NavigationService,
              private _dialogRef: MatDialogRef<PaymentBalanceRecordAddComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    _translateService.get([
      'job_dispatched',
      'payment_balance_code_custom',
      'payment_balance_code_refund_processed',
      'payment_balance_code_job_canceled',
      'payment_balance_code_cancellation_charge',
      'driver',
    ]).subscribe((translations: string[]) => {
      this.translations = translations;
    });
    this.data = data.parent;

    this.currencyDisplay = getCurrencySymbol(this.data.ride.price.currency, 'narrow');
    this.currencySymbol = getCurrencySymbol(this.data.ride.price.currency, 'narrow');
    this.totalFormat = '0.00';
  }

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      description: ['', [Validators.required]],
      recordType: [this.balanceRecordTypes[0], [Validators.required]],
      recordDirection: [this.recordDirection[0], [Validators.required]],
      amount: ['0.00', [Validators.required]],
    }, {validators: []});
  }

  typeChanged(): void {
    const formData = this.form.getRawValue();
    if (formData.recordType === 'JOB_CANCELED') {
      this.form.controls['amount'].setValue(`${(this.data.ride.price.total / 100).toFixed(2)}`);
      this.form.controls['recordDirection'].setValue('refund');
      if (this.form.controls['note'].value === '') {
        this.form.controls['note'].setValue(this.translations['payment_balance_code_' + formData.recordType]);
      }
    }
  }

  closeNotification(): void {
    this._dialogRef.close();
  }

  valOrZero(input) {
    if (!input.value) {
      input.value = 0.00;
    }
    input.value = parseFloat(input.value).toFixed(2);
    if (this.form.controls[input.getAttribute('formControlName')]) {
      this.form.controls[input.getAttribute('formControlName')].setValue(input.value);
    }
  }

  inputFocused(event) {
    event.target.select();
  }

  save() {
    const self = this;
    const formData = this.form.getRawValue();
    let total = parseFloat(formData.amount) * 100;
    if (formData.recordDirection === 'payment') {
      total = 0 - total;
    }

    this._paymentBalanceService.insert({
      'description': formData.description,
      'code': formData.recordType,
      'amount': {
        'total': total,
        'currency': this.data.ride.price.currency
      },
      'rideId': this.data.ride.id,
    }).subscribe(() => {
      self.data.loadData();
      self.data.afterFilter();
      self.data.reloadData.emit();
      self.data._loadingService.resolve();
      self._dialogRef.close();
    });
  }
}
