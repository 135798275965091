<mat-card-content>
  <mat-card-title class="push-bottom">{{'payment_balance_add_record_title'|translate|ucFirst}}</mat-card-title>
  <mat-card-subtitle
    class="push-bottom">{{'payment_balance_add_record_title_subtitle'|translate|ucFirst}}</mat-card-subtitle>
  <mat-divider></mat-divider>

  <form *ngIf="form" [formGroup]="form">
    <mat-list-item flex-gt-sm="100">
      <mat-form-field class="full-width">
        <mat-label>{{'description'|translate|ucFirst}}</mat-label>
        <textarea aria-multiline="true" formControlName="description" matInput></textarea>
      </mat-form-field>
    </mat-list-item>

    <mat-list-item flex-gt-sm="100">
      <mat-form-field class="form-right">
        <mat-label>{{'record_type'|translate|ucFirst}}</mat-label>
        <mat-select (selectionChange)="typeChanged()" class="row-select"
                    flex="100" formControlName="recordType" required>
          <mat-option *ngFor="let option of balanceRecordTypes" [value]="option">
            {{'payment_balance_code_' + option.toLowerCase() | translate | ucFirst}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-list-item>

    <mat-list-item flex-gt-sm="100">
      <mat-form-field class="form-left">
        <span matPrefix>{{(this.currencyDisplay ? this.currencyDisplay : this.currencySymbol)}}&thinsp;</span>
        <mat-label>{{'amount'|translate|ucFirst}}</mat-label>
        <input #jobPrice (blur)="valOrZero($event.target)"
               (focus)="valOrZero($event.target); inputFocused($event)"
               [placeholder]="(this.totalFormat ? this.totalFormat : '0,00')"
               autocomplete="off"
               formControlName="amount"
               matInput
               min="0"
               step='0.01' type="number">
      </mat-form-field>

      <mat-form-field class="form-right">
        <mat-select class="row-select"
                    flex="100" formControlName="recordDirection" required>
          <mat-option *ngFor="let option of recordDirection" [value]="option">
            {{'payment_balance_direction_' + option.toLowerCase() | translate | ucFirst}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </mat-list-item>
  </form>

  <mat-divider></mat-divider>
  <mat-card-actions class="action-pane">
    <button (click)="save()"
            color="accent"
            flex
            mat-stroked-button>{{'add_record'|translate|ucFirst}}</button>

    <button (click)="closeNotification()"
            color="accent"
            flex
            mat-stroked-button>{{'close'|translate|ucFirst}}</button>
  </mat-card-actions>
</mat-card-content>
