import {Component, Input, OnInit} from '@angular/core';
import {Contract} from '../../../../../models/contract';
import {ContractService} from '../../../../../services/contract.service';

@Component({
  selector: 'app-suspended',
  templateUrl: './suspended.component.html',
  styleUrls: ['./suspended.component.scss']
})
export class SuspendedComponent implements OnInit {
  @Input() contract: Contract;
  billingHistory: any;

  constructor(private _contractService: ContractService) {
  }

  ngOnInit(): void {
    const self = this

    if (self.contract.id) {
      self._contractService.billingHistory(self.contract.id).subscribe((history) => {
        history.data = history.data.filter((item) => {
          return (item.status !== 'draft')
        });
        self.billingHistory = history;
      });
    }
  }

}
