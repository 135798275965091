<app-no-contract *ngIf="(this.context==='company' && (!this.company.ActiveContract ||
!['active','past_due', null].includes(this.company.ActiveContract.foreignStatus)) ||
['webbooker_license','webbooker_plus_license'].includes(this.company.ActiveContract.type))"
                 [companyId]="this.companyId" [contract]="this.company.ActiveContract"
                 [type]="'debtor'"></app-no-contract>
<mat-card [class]="(this.context==='company' &&
(!this.company.ActiveContract ||
(this.company.hasDispatcherLicense && !this.company.ActiveContract.foreignStatus)) ? 'blurText' :'')" [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <ng-template tdLoading="customer-invoices">
    <mat-tab-group (selectedTabChange)="onTabChange($event)" [selectedIndex]="selectedTabIndex">
      <mat-tab label="{{'to_be_invoiced'|translate}}">
        <ng-template mat-tab-label>
          <mat-icon>search</mat-icon>
          {{'to_be_invoiced'|translate}}
        </ng-template>
        <ng-template *ngIf="dataLoaded" tdLoading="to-be-invoiced">
          <app-to-be-invoiced #toBeInvoicedComponent
                              (reloadAllDataEmitter)="reloadAllData()"
                              (switchTab)="switchTab($event)"
                              [companyId]="companyId"
                              [context]="'company'">
          </app-to-be-invoiced>
        </ng-template>
      </mat-tab>
      <mat-tab label="{{'open_customer_invoices'|translate}}">
        <ng-template mat-tab-label>
          <mat-icon>move_to_inbox</mat-icon>
          {{'open_customer_invoices'|translate}}
        </ng-template>
        <ng-template *ngIf="dataLoaded" tdLoading="open-customer">
          <app-open-invoices #draftComponent
                             (reloadAllDataEmitter)="reloadAllData()"
                             (switchTab)="switchTab($event)"
                             [companyId]="companyId"
                             [context]="'company'"
                             [invoiceView]="'Debtor'"
                             [searchIds]="searchIds"
                             [type]="'draft'">
          </app-open-invoices>
        </ng-template>
      </mat-tab>
      <mat-tab label="{{'customer_invoice_history'|translate}}">
        <ng-template mat-tab-label>
          <mat-icon>history</mat-icon>
          {{'customer_invoice_history'|translate}}
        </ng-template>
        <ng-template *ngIf="dataLoaded" tdLoading="data.history.loaded">
          <app-open-invoices #historyComponent
                             (reloadAllDataEmitter)="reloadAllData()"
                             (switchTab)="switchTab($event)"
                             [companyId]="companyId"
                             [context]="'company'"
                             [invoiceView]="'Debtor'"
                             [searchIds]="searchIds"
                             [type]="'history'">
          </app-open-invoices>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </ng-template>
</mat-card>
