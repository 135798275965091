import {Component, Input} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Rule} from '../../../../../../../models/rule';

@Component({
  selector: 'app-top',
  templateUrl: './top.component.html',
  styleUrls: ['./top.component.scss'],
})
export class TopComponent {

  @Input() form: UntypedFormGroup;

  types = ['dynamic', 'fixed', 'hourly', 'meter'];

  frontendName = (type: 'dynamic' | 'fixed' | 'hourly' | 'meter') =>
    Rule.ruleTypeNamesMapping(type);
}
