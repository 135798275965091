import {Directive, DoCheck, Input} from '@angular/core';
import {Ride} from '../models/ride';
import {GoogleMapsAPIWrapper} from '@agm/core';
import {UntypedFormGroup} from '@angular/forms';

declare var google: any;

@Directive({
  selector: 'app-agm-custom-waypoints'
})

export class DirectionsMapWaypointsDirective {
  @Input() ride: Ride;
  @Input() form: UntypedFormGroup;

  currentAddressArrayLength = 1;

  addressArrayHash = '';

  directionsService: any = undefined;
  directionsDisplay: any = undefined;

  static generateLocationHash(array: any) {
    return JSON.stringify(array);
  }

  constructor(private gmapsApi: GoogleMapsAPIWrapper) {
  }

  updateRide(departure, destination, stopOvers) {
    this.ride.departure = departure;
    this.ride.destination = destination;
    this.ride.stopOvers = stopOvers;
    this.drawRoute();
  }

  drawRoute() {
    const self = this;

    if (typeof (google) === 'undefined') {
      setTimeout(() => {
        this.drawRoute();
      }, 100);
      return false;
    }

    this.gmapsApi.getNativeMap().then(map => {
      if (!self.directionsService) {
        self.directionsService = new google.maps.DirectionsService;
      }
      if (!self.directionsDisplay) {
        self.directionsDisplay = new google.maps.DirectionsRenderer;
      }
      self.directionsDisplay.setMap(map);
      self.directionsDisplay.setOptions({suppressMarkers: true});

      const waypoints = [];
      if (self.ride.stopOvers) {
        self.ride.stopOvers.forEach((stop) => {
          if (stop && stop.gps && stop.gps.lat) {
            waypoints.push({
              location: {
                lat: stop.gps.lat,
                lng: stop.gps.lng
              }
            });
          }
        });
      }
      const route: google.maps.DirectionsRequest = {
        origin: {
          lat: self.ride.departure.gps.lat,
          lng: self.ride.departure.gps.lng
        },
        destination: {
          lat: self.ride.destination.gps.lat,
          lng: self.ride.destination.gps.lng
        },
        travelMode: google.maps.TravelMode.DRIVING
      };

      if (waypoints.length > 0) {
        route.waypoints = waypoints;
      }


      self.directionsService.route(route, function (response: any, status: any) {
        if (status === 'OK') {
          self.directionsDisplay.setDirections(response);
          self.ride.metrics = {
            distance: 0,
            duration: 0
          };
          const legs = response.routes[0].legs;
          for (let i = 0; i < legs.length; ++i) {
            self.ride.metrics.distance += legs[i].distance.value;
            self.ride.metrics.duration += legs[i].duration.value;
          }
        } else {
          console.log('Waypoint: Directions request failed due to ' + status);
        }
      });
    });
  }

  // ngDoCheck(): void {
  //   const newAddressArrayHash = DirectionsMapWaypointsDirective.generateLocationHash(
  //     {departure: this.ride.departure, destination: this.ride.destination, stopovers: this.ride.stopOvers});
  //   if (
  //     this.addressArrayHash !== newAddressArrayHash &&
  //     (this.ride.departure && this.ride.departure.gps && this.ride.departure.gps.lat) &&
  //     (this.ride.destination && this.ride.destination.gps && this.ride.destination.gps.lat)) {
  //     this.addressArrayHash = newAddressArrayHash;
  //     console.log(`[DirectionsMapWaypoints.ngDoCheck]: Address array changed, drawing new route`);
  //     this.drawRoute();
  //   }
  // }
}
