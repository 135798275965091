import {Component, Input, OnInit} from '@angular/core';
import {Vehicle} from '../../../../../models/vehicle';

@Component({
  selector: 'app-ride-vehicle-data',
  templateUrl: './ride-vehicle-data.component.html',
  styleUrls: ['./ride-vehicle-data.component.scss']
})
export class RideVehicleDataComponent implements OnInit {

  @Input() vehicle: Vehicle;

  constructor() {
  }

  ngOnInit() {
  }

}
