import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {ucFirst} from '../pipes/uc-first.pipe';

export class ValidateAllFormFields {
  formErrors = [];
  errorTranslations;
  translations;
  validationMessages;

  static noSpecialChars(event) {
    const e = <KeyboardEvent>event;
    const chr = String.fromCharCode(e.which);
    const regex = new RegExp('[a-zA-Z0-9½]');
    if (regex.test(chr) || ['Backspace', 'ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'Delete'].includes(e.key)) {
      return;
    } else {
      e.preventDefault();
    }
  }

  dateComparison(form: UntypedFormGroup): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!form) {
        return null;
      }
      return (form.controls['startDate'].value === value ? {'error_start_and_end_date_same': true} : null)
    };
  }

  loadErrorTranslations(_translateService: TranslateService) {
    const translationArray = [];
    if (this.validationMessages) {
      Object.keys(this.validationMessages).forEach((fields) => {
        if (fields) {
          Object.keys(this.validationMessages[fields]).forEach((key) => {
            translationArray.push(this.validationMessages[fields][key]);
          });
        }
      });

      _translateService.get(translationArray)
        .subscribe((translations: any) => {
          this.errorTranslations = translations;
        });
    }
    // else {
    //   console.log('[ValidateAllFormFields] no validationMessages configured in this component');
    // }
  }

  validateAllFormFields(formGroup: UntypedFormGroup) {
    if (formGroup.controls) {
      Object.keys(formGroup.controls).forEach(field => {
        this.formErrors[field] = '';
        this.validateFormField(formGroup, field);
      })
    }
  }

  validateFormField(formGroup: UntypedFormGroup, field: string) {
    let control;
    if (field.indexOf('-') > -1) {
      const splitName = field.split('-');
      control = formGroup.get(splitName[splitName.length - 1]);
    } else {
      control = formGroup.get(field);
    }

    this.formErrors[field] = '';

    if (field === 'phoneNumber' || field === 'driverPhoneNumber') {
      formGroup.get(field).valueChanges.subscribe(
        () => {
          if (document.getElementsByClassName('phoneNumberInvalid')[0]) {
            document.getElementsByClassName('phoneNumberInvalid')[0].classList.remove('phoneNumberInvalid');
          }
        });
    }

    if (control instanceof UntypedFormControl) {
      control.markAsDirty();
      control.markAsTouched();
      if (control && !control.valid && this.validationMessages[field]) {
        const messages = this.validationMessages[field];
        if (control.errors) {
          Object.keys(control.errors).forEach(key => {
            // if (this.errorTranslations) {
            //   console.log(this.errorTranslations[messages[key]]);
            // }
            if (this.formErrors && this.errorTranslations && this.errorTranslations[messages[key]]) {
              const fieldTranslation = (this.errorTranslations[this.validationMessages[field].fieldName] ? ucFirst(this.errorTranslations[this.validationMessages[field].fieldName]) :
                (this.translations[field] ? this.translations[field] : field));
              if (control.value && control.value.dialCode && key === 'validatePhoneNumber') {
                this.formErrors[field] += `${this.errorTranslations[messages[key]].replace('{countryCode}', control.value.dialCode)}`;
              } else {
                this.formErrors[field] += `${this.errorTranslations[messages[key]].replace('{field}', fieldTranslation)}`;
              }
            }
          });
        }
      }
    } else if (control instanceof UntypedFormGroup) {
      Object.keys(control.controls).forEach(subField => {
        const fieldName = `${field}-${subField}`;
        this.formErrors[fieldName] = '';
        this.validateFormField(control, fieldName);
      })
    }
  }

  clearError(e, name) {
    this.formErrors[name] = null;
  }
}
