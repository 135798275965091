<mat-list-item>
  <mat-icon mat-list-icon>place</mat-icon>
  <h4 mat-line>{{ 'location'|translate|ucFirst }}</h4>
</mat-list-item>

<div class="address-pane" [formGroup]="form" (mouseleave)="drop($event, this)"
     (mouseup)="drop($event, this)">
  <div *ngFor="let c of getAddressArray(); let i = index" layout="row"
       (mousedown)="pick($event, c.value, i)"
       (mousemove)="move($event, c.value)"
       (mouseup)="drop($event, c.value)"
       [ngClass]="{'dragging': getPos(c.value.internationalAlias) }"
       [ngStyle]="{'transform': 'translateY(' + getPos(c.value.internationalAlias) + 'px)'}"
       class="draggable">
    <div class="sort-icon" matTooltip="{{'drag_to_sort' | translate}}">
      <mat-icon>drag_indicator</mat-icon>
    </div>
    <app-ride-edit-address
      [address]="c.value"
      [direction]="(i !== 0 && i !== (addressArray.length-1) ? 'via-'+(i-1) : (i===0? 'departure' : 'destination'))"
      [form]="form"
      [label]="(i !== 0 && i !== (addressArray.length-1) ? 'stopover' : (i===0? 'departure' : 'destination'))"
      [placeholder]="(i !== 0 && i !== (addressArray.length-1) ? 'enter_address' : (i===0? 'departure' : 'destination'))"
      class="stopover">
    </app-ride-edit-address>
    <div (click)="removeDestination((i-1))" *ngIf="(i !== 0 && i !== (addressArray.length-1))" class="address-icon" matTooltip="{{'remove_stopover' | translate}}">
      <mat-icon>delete</mat-icon>
    </div>
    <div (click)="addDestination()" *ngIf="i===0" class="address-icon" matTooltip="{{'add_stopover' | translate}}">
      <mat-icon>add_circle_outline</mat-icon>
    </div>
  </div>
</div>
