import {Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DispatchDialogComponent} from '../../../../../dispatch-dialog/dispatch-dialog.component';
import {ucFirst} from '../../../../../../../../../pipes/uc-first.pipe';
import {TranslateService} from '@ngx-translate/core';
import {Ride} from '../../../../../../../../../models/ride';
import moment from 'moment-timezone';
import {UtilityService} from '../../../../../../../../../services/utility.service';

@Component({
  selector: 'app-share-offer',
  templateUrl: './share-offer.component.html',
  styleUrls: ['./share-offer.component.scss']
})
export class ShareOfferComponent implements OnInit {
  @Input() deepLinkUrl: string;
  @Input() ride: Ride;
  @Output() stopDispatch = new EventEmitter<string>();
  translations: string[];
  shareLink: string;
  emailBody: string;
  emailTitle: string;
  shareTitle: string;
  displayTitle: string;
  displayAddress: string;

  emailBodyEnc: string;
  emailTitleEnc: string;
  shareTitleEnc: string;

  constructor(
    private clipboard: Clipboard,
    private _translateService: TranslateService,
    private _dialogRef: MatDialogRef<DispatchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data) {
      this.shareLink = data.shareLink;
      if (data.parent && data.parent.ride) {
        this.ride = data.parent.ride;
      } else if (data.ride) {
        this.ride = data.ride;
      }
    } else {
      this.shareLink = this.deepLinkUrl;
    }
  }

  ngOnInit(): void {
    const self = this;
    self._translateService.get(['offer_share_new_offer_title', 'offer_share_new_offer_text', 'today']).subscribe((translations: any) => {
      this.translations = translations;
      const iscurrentDate = moment(self.ride.requestedDate).isSame(new Date(), 'day');
      const today = (iscurrentDate ? self.translations['today'] : moment(self.ride.requestedDate).format('DD-MM-YYYY'));
      const requestedDateTime = moment(self.ride.requestedDate).format('HH:mm');

      self.emailTitle = `${translations['offer_share_new_offer_title']}\n{address}`;
      self.shareTitle = `${self.emailTitle.replace('{today}', today).replace('{time}', requestedDateTime).replace('{vehicleType}', ucFirst(self.ride.vehicleType)).replace('{address}', UtilityService.getLocationString(self.ride.departure))}`;
      self.displayTitle = `${self.emailTitle.replace('{today}', today).replace('{time}', requestedDateTime).replace('{vehicleType}', ucFirst(self.ride.vehicleType)).replace('{address}', ``)}`;
      self.emailTitle = `${self.emailTitle.replace('{today}', today).replace('{time}', requestedDateTime).replace('{vehicleType}', ucFirst(self.ride.vehicleType)).replace('{address}', ` - ${UtilityService.getLocationString(self.ride.departure)}`)}`;
      self.displayAddress = UtilityService.getLocationString(self.ride.departure);

      self.emailBody = `${translations['offer_share_new_offer_text']}`;

      self.emailTitleEnc = encodeURIComponent(`${self.emailTitle}\n`);
      self.shareTitleEnc = encodeURIComponent(`${self.shareTitle}\n`);
      self.emailBodyEnc = encodeURIComponent(`${self.emailBody}\n`);
    });
  }

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['deepLinkUrl'] && changes['deepLinkUrl'].currentValue) {
      this.shareLink = changes['deepLinkUrl'].currentValue
    }
  }

  copyToClipboard(): void {
    this.clipboard.copy(`${this.displayTitle}\n${this.emailBody}\n${this.shareLink}`);
    if (this._dialogRef) {
      this._dialogRef.close();
    }
  }
}
