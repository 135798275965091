<mat-card [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <mat-card-title>{{'driver_calendar'|translate|ucFirst}}</mat-card-title>
  <mat-card-subtitle>{{(this.type === 'company' ? 'ics_calender_subtitle_company' : 'ics_calender_subtitle')|translate|ucFirst}}</mat-card-subtitle>
  <mat-divider></mat-divider>
  <mat-card-content class="push-bottom-none">
    <div class="table">
      <div class="table-header" layout="row">
        <div class="left-column share-link" layout="column">
          <a class="yda-link-blue" href="{{this.calenderUrl}}" target="_new">{{this.calenderUrl}}</a>
        </div>

        <button (click)="this.copyToClipboard()" class="push-left copy-button" flex
                mat-stroked-button>
          {{'copy_link'|translate|ucFirst}}</button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
