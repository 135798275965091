import {Component, Inject, Input, OnInit} from '@angular/core';
import {Driver} from '../../../../models/driver';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Passenger} from '../../../../models/passenger';
import {TdDialogService} from '@covalent/core/dialogs';
import {TdLoadingService} from '@covalent/core/loading';
import {ActivatedRoute, Router} from '@angular/router';
import {ucFirst} from '../../../../pipes/uc-first.pipe';
import {PassengerService} from '../../../../services/passenger.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {NavigationService} from '../../../../services/navigation.service';
import {phoneNumberValidator} from '../../../../pipes/phonenumber-validator.pipe';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CountryISO, SearchCountryField,} from 'ngx-intl-tel-input';
import {Company} from '../../../../models/company';
import {UtilityService} from '../../../../services/utility.service';
import {ValidateAllFormFields} from '../../../../functions/validateAllFormFields';
import {WebbookerService} from '../../../../services/webbooker.service';
import {DaAppInstallService} from '../../../../services/da-app-install.service';
import {Observable} from 'rxjs/Observable';

@Component({
  selector: 'app-passenger-upsert',
  templateUrl: './passenger-upsert.component.html',
  styleUrls: ['./passenger-upsert.component.scss'],
  providers: [PassengerService]
})
export class PassengerUpsertComponent extends ValidateAllFormFields implements OnInit {
  @Input() parent;

  context: string;
  driver: Driver;
  companyId: string;

  loaderName = 'debtor';
  translations: string[] = [];
  SearchCountryField = SearchCountryField;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom
  ];

  id: string;
  debtorId: string;
  action: string;
  type: string;
  passenger: Passenger = new Passenger();
  company: Company;

  form: UntypedFormGroup;
  formErrors: any = {
    fname: '',
    lname: '',
    email: '',
    phoneNumber: '',
  };
  validationMessages = {
    'phoneNumber': {
      'phoneNumberValidator': 'Phone number is invalid, please use the correct format (+31611223344)',
    }
  };
  activeTab = 'contacts';

  constructor(
    public snackBar: MatSnackBar,
    private dialogService: TdDialogService,
    private loadingService: TdLoadingService,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private _router: Router,
    private vault: CoolLocalStorage,
    private translateService: TranslateService,
    private titleService: Title,
    private navigationService: NavigationService,
    private _passengerService: PassengerService,
    private _daAppInstallService: DaAppInstallService,
    private _webbookerService: WebbookerService,
    public dialogRef: MatDialogRef<PassengerUpsertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    super();
    this.loadErrorTranslations(translateService);

    this.driver = this.vault.getObject(`${environment.vaultPrefix}.driver`);

    if (!this.data && this.parent) {
      this.data = {
        parent: this.parent
      }
    }

    if (this.data && !this.data.parent) {
      const {company} = this.route.parent.snapshot.data;
      UtilityService.setBrowserTimeStamp(company);
      this.company = company;
      this.company.country = (this.company.country === 'EN' ? 'GB' : this.company.country);
      this.data = null;
    } else {
      this.company = data.company;
    }

    if (this.data && this.data.parent.data && this.data.parent.data.parent) {
      this.debtorId = this.data.parent.data.parent.debtorId;
      this.action = 'add';
      this.type = 'contact';
    } else {
      this.id = route.snapshot.paramMap.get('id');
      this.debtorId = route.snapshot.paramMap.get('debtorId');
      this.action = (route.snapshot.paramMap.get('action') ? route.snapshot.paramMap.get('action') : (this.route.routeConfig ? this.route.routeConfig['action'] : 'add'));
      this.type = route.snapshot.paramMap.get('type');

      if (this.route.routeConfig) {
        this.context = this.route.routeConfig['context'] || 'driver';
        this.companyId = this.route.parent.snapshot.paramMap.get('id');
      }
    }

    translateService.get([
      `${this.action}_contact`,
      'passenger_added_message',
      'passenger_added_title',
      'passenger_updated_message',
      'deactivate_x',
      'activate_x',
      'message_deactivate_x',
      'message_activate_x',
      'x_deactivated',
      'x_activated',
      'deactivate',
      'activate',
      'contact_added_message',
      'contact_added_title',
      'passenger_duplicate_phone_error_message',
      'passenger_duplicate_phone_error_title',
      'contact_duplicate_error_message',
      'contact_duplicate_error_title',
      'no_webbooker_found',
      'no_webbooker_found_title',
      'ok'
    ]).subscribe((translations: any) => {
      this.translations = translations;
      this.titleService.setTitle(ucFirst(translations[`${this.action}_contact`]) + environment.windowTitleSuffix);
      this.navigationService.setBodyTitle(ucFirst(translations[`${this.action}_contact`]));
    });

    if (this.action === 'edit') {
      this.loadData();
    }
  }

  ngOnInit() {
    if (!this.company && this.parent) {
      this.company = this.parent.company;
    }

    if (this.action !== 'add') {
      this.loadingService.register(this.loaderName);
    }
    this.initForm();
  }

  initForm() {
    /**
     * Set up our form
     */
    this.form = this.formBuilder.group({
      fname: [this.passenger.fname, [Validators.required]],
      lname: [this.passenger.lname, [Validators.required]],
      email: [{value: this.passenger.email, disabled: (this.passenger.id)}, {validators: [Validators.required, Validators.email]}],
      phoneNumber: [this.passenger.phoneNumber, {
        validators: [Validators.required, Validators.minLength(8), phoneNumberValidator(this.company.country)],
        updateOn: 'blur'
      }],
    }, {validators: []});

    /**
     * Subscribe to value changes in the form
     */
    this.form.valueChanges.subscribe(() => this.onValueChanged());
  }

  loadData(): void {
    const filter = {where: {id: this.id, or: [{isDeleted: true}, {isDeleted: false}]}};
    this._passengerService.getAll(filter, this.context).subscribe((passengers: Passenger[]) => {
      if (passengers[0]) {
        this.passenger = passengers[0];
        this.initForm();
        this.onValueChanged();
        this.loadingService.resolve(this.loaderName);
      }
    }, error => {
      console.error(error);
      this.loadingService.resolve(this.loaderName);
    })
  }

  onValueChanged() {
    this.validateAllFormFields(this.form);
  }

  delete(): void {
    const self = this;
    const name = `${this.passenger.fname} ${this.passenger.lname} (${this.passenger.phoneNumber})`;
    const message = self.translateService.instant('message_delete_x').formatUnicorn(name);
    const title = self.translateService.instant('delete_x').formatUnicorn(name);

    self.dialogService.openConfirm({
      message: message,
      disableClose: false,
      title: title,
      cancelButton: 'Cancel',
      acceptButton: self.translateService.instant('delete'),
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        self._passengerService.delete(self.passenger.id, this.context).subscribe(() => {
          self.dialogService.openConfirm({
            message: self.translations['x_deactivated'].formatUnicorn(name),
            disableClose: true,
            acceptButton: 'Ok',
          }).afterClosed().subscribe(() => {
            if (self.context === 'driver') {
              self._router.navigate([`/debtors`]);
            } else if (self.debtorId) {
              self._router.navigate([`/groups/${self.companyId}/debtors/${self.debtorId}/view`]);
            } else {
              self._router.navigate([`/groups/${self.companyId}/debtors`], {queryParams: {return: this.activeTab}});
            }
          });
        });
      }
    });
  }

  activate(): void {
    const self = this;
    const name = `${this.passenger.fname} ${this.passenger.lname} (${this.passenger.phoneNumber})`;
    const message = self.translations['message_activate_x'].formatUnicorn(name);
    const title = self.translations['activate_x'].formatUnicorn(name);

    self.dialogService.openConfirm({
      message: message,
      disableClose: false,
      title: title,
      cancelButton: 'Cancel',
      acceptButton: self.translations['activate'],
    }).afterClosed().subscribe((accept: boolean) => {
      if (accept) {
        self._passengerService.activate(self.passenger.id, {...this.passenger, isDeleted: false}, this.context).subscribe(() => {
          self.dialogService.openConfirm({
            message: self.translations['x_activated'].formatUnicorn(name),
            disableClose: true,
            acceptButton: 'Ok',
          }).afterClosed().subscribe(() => {
            if (self.context === 'driver') {
              self._router.navigate([`/debtors`]);
            } else {
              self._router.navigate([`/groups/${self.companyId}/debtors/${self.debtorId}/view`]);
            }
          });
        });
      }
    });
  }

  /**
   * Saves the passenger data and calls the API to perform the necessary actions.
   */
  save(): void {
    const self = this;
    self.loadingService.register();

    const user:any = this.vault.getObject(`${environment.vaultPrefix}.user`);
    const data = this.form.value;
    const passenger = this.passenger;

    // Remove unnecessary properties from passenger object
    delete passenger.modified;
    delete passenger.created;

    // Copy the values from the form to the passenger object
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        passenger[key] = data[key];
      }
    }

    // Set the realm and phoneNumber properties of the passenger object
    passenger.realm = this.company.id;
    if (data.phoneNumber && data.phoneNumber.e164Number) {
      passenger.phoneNumber = data.phoneNumber.e164Number;
    }

    /**
     * Call the API based on the action type
     */
    if (self.action.toLowerCase() === 'add') {
      delete passenger.id;

      self._daAppInstallService.getAll({
        where: {
          daAppId: environment.webbookerDaAppId,
          companyId: self.company.id,
        }
      }).subscribe((bookers) => {
        if (bookers.length === 0) {
          self.loadingService.resolve();

          // Open an alert dialog if no webbooker is found
          self.dialogService.openAlert({
            message: ucFirst(self.translations['no_webbooker_found']),
            disableClose: true,
            title: ucFirst(self.translations['no_webbooker_found_title']),
            closeButton: self.translations['ok']
          }).afterClosed().subscribe(() => {

          });
        } else {
          bookers.map((booker) => {
            if (booker.formId) {
              // Get the JWT token for the webbooker
              booker.JWTtoken = self.vault.getItem(`${environment.vaultPrefix}.${booker.formId}.jwtToken`);
              if (!booker.JWTtoken) {
                // Refresh the token if it doesn't exist
                self._daAppInstallService.refreshToken(booker.formId)
                  .then((token) => {
                    booker.JWTtoken = token;
                    self.vault.setItem(`${environment.vaultPrefix}.${booker.formId}.jwtToken`, token);
                  });
              }

              // Set the URL for the webbooker
              booker.url = `${environment.orderFormFrontEndUrl}/dashboard/${user.locale.toLowerCase()}/${booker.formId}`;

              // Make an API call to retrieve the webbooker data
              return self._webbookerService.get(booker.formId, {}, {'Authorization': `Bearer ${booker.JWTtoken}`})
                .subscribe((b) => {
                  // Set the branding information for the passenger
                  passenger.branding = {
                    name: self.company.name,
                    logo: b.config.logo,
                    url: booker.url,
                    sendAccountEmail: true,
                  };

                  // Insert the passenger and the debtor
                  self._passengerService.insert(passenger, self.context).subscribe((nPass: Passenger) => {
                    self._passengerService.debtorInsert({
                      debtorId: self.debtorId,
                      passengerId: nPass.id,
                    }, self.context).subscribe(() => {
                    });

                    setTimeout(function () {
                      // Show a success dialog after a delay
                      self.dialogService.openAlert({
                        message: ucFirst(self.translations['contact_added_message']),
                        disableClose: true,
                        title: ucFirst(self.translations['contact_added_title']),
                        closeButton: self.translations['ok']
                      }).afterClosed().subscribe(() => {
                        self.loadingService.resolve();

                        if (self.parent && self.parent.dialog) {
                          // Load debtors, set the debtorId, select the debtor, and close the dialog
                          self.parent.loadDebtors();
                          self.parent.form.controls['debtorId'].setValue('contact-' + nPass.id);
                          self.parent.selectDebtor();
                          self.parent.dialog.close();
                        } else if (self.data) {
                          self.data.parent.data.parent.loadData();
                          self.dialogRef.close();
                        } else {
                          if (self.context === 'driver') {
                            self._router.navigate([`/debtors/${self.debtorId}/view`]);
                          } else {
                            self._router.navigate([`/groups/${self.companyId}/debtors`], {queryParams: {return: self.activeTab}});
                          }
                        }
                      });
                    }, 500);
                  }, error => {
                    self.loadingService.resolve();
                    if (error.status === 409 && error.error.error.name === 'DUPLICATE_PHONENUMBER') {
                      self.dialogService.openAlert({
                        message: ucFirst(self.translations['passenger_duplicate_phone_error_message']),
                        disableClose: false,
                        title: ucFirst(self.translations['passenger_duplicate_phone_error_title']),
                        closeButton: self.translations['ok']
                      });
                    } else if (error.status === 422 && error.error.error.name === 'ValidationError') {
                      if (error.error.error.details && error.error.error.details.codes && error.error.error.details.codes.email) {
                        self.dialogService.openAlert({
                          message: ucFirst(self.translations['contact_duplicate_error_title']),
                          disableClose: false,
                          title: ucFirst(self.translations['contact_duplicate_error_message']),
                          closeButton: self.translations['ok']
                        });
                      }
                    }
                  })
                })
            }
          });
        }
      });
    } else {
      if (data.phoneNumber && data.phoneNumber.e164Number) {
        data.phoneNumber = data.phoneNumber.e164Number;
      }
      this._passengerService.update(this.id, data, 'portal')
        .subscribe(() => {
          setTimeout(function () {
            self.loadingService.resolve();
            const notification = self.snackBar.open(ucFirst(self.translations['passenger_updated_message']), self.translations['ok'], {
              duration: 3000
            });
            self._router.navigate([`/${(self.company.type === 'business' ? 'groups' : 'dispatchgroup')}/${self.companyId}/debtors`]);
          }, 500);
        }, error => {
          console.error(error);
          self.loadingService.resolve();
        });
    }
  }

  webbookerBranding(): Observable<any> {
    const self = this;
    return
  }


}
