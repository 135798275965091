<div [mediaClasses]="['push-sm']" class="push-bottom-sm push-top-sm push-left-sm push-right-sm" layout-gt-sm="row"
     tdMediaToggle="gt-xs">
  <div flex-gt-sm="50">
    <mat-card class="details-card">
      <mat-card-title>{{'job_details'|translate|ucFirst}}</mat-card-title>
      <mat-card-subtitle>{{'job_details_subtitle'|translate|ucFirst}}</mat-card-subtitle>
      <mat-divider></mat-divider>
      <ng-template tdLoading="ride.details">
        <mat-list>

          <!-- Direct assign driver -->
          <ng-template *ngIf="!(media.registerQuery('gt-md') | async) &&
          !['completed','canceled','canceled_driver','canceled_passenger'].includes(ride.status)" tdLoading="ride.details.driver">
            <app-ride-edit-assign (reloadData)="reloadDriverData()" [driverNote]="ride.driverNote"
                                  [receiptUrl]="(ride.resources ? ride.resources.receiptSummaryUrl : null)"
                                  [ride]="ride"></app-ride-edit-assign>
            <mat-divider class="push-bottom-sm"></mat-divider>
          </ng-template>

          <!-- Ride Details Data -->
          <app-ride-details-data [ride]="ride"></app-ride-details-data>
          <mat-divider class="push-bottom-sm"></mat-divider>

          <!-- Ride Location Data -->
          <app-ride-location-data [actualDeparture]="ride.events?.started?.address"
                                  [actualDestination]="ride.events?.completed?.address"
                                  [departure]="ride.departure"
                                  [destination]="ride.destination"
                                  [metrics]="ride.metrics"></app-ride-location-data>
          <mat-divider class="push-bottom-sm"></mat-divider>

          <!-- Ride Time Data -->
          <app-ride-time-data [created]="ride.created"
                              [pickupDate]="ride.pickupDate"
                              [requestedDate]="ride.requestedDate"
                              [rideEvents]="ride.events"></app-ride-time-data>
          <mat-divider class="push-bottom-sm"></mat-divider>

          <!-- Ride Passenger Data -->
          <app-ride-passenger-data [flightNumber]="ride.flightNumber"
                                   [passengerCount]="ride.passengerCount"
                                   [passenger]="ride.passenger"></app-ride-passenger-data>
          <mat-divider class="push-bottom-sm"></mat-divider>

          <!-- Ride Driver Data -->
          <div *ngIf="ride.Driver">
            <app-ride-driver-data [driverNote]="ride.driverNote" [driver]="ride.Driver"></app-ride-driver-data>
            <mat-divider class="push-bottom-sm"></mat-divider>
          </div>

          <!-- Ride Dispatcher Data -->
          <div *ngIf="ride.Dispatcher">
            <app-ride-dispatcher-data [dispatcher]="ride.Dispatcher"
                                      [operatorNote]="ride.operatorNote"></app-ride-dispatcher-data>
            <mat-divider class="push-bottom-sm"></mat-divider>
          </div>

          <!-- Ride Price Data -->
          <div *ngIf="ride.price">
            <app-ride-price-data [isOwner]="isOwner" [paymentMeta]="ride.paymentMeta" [price]="ride.price"
                                 [ride]="ride"></app-ride-price-data>
          </div>

          <!-- Ride Payment Data -->
          <div *ngIf="ride.paymentMeta" class="push-bottom-sm">
            <app-ride-payment-data [paymentMeta]="ride.paymentMeta"></app-ride-payment-data>
          </div>

          <!-- Ride Commission Data -->
          <div *ngIf="ride.commission && ride.commissionRules">
            <mat-divider class="push-bottom-sm"></mat-divider>
            <app-ride-commission-data [commissionRules]="ride.commissionRules"
                                      [commission]="ride.commission"
                                      [currency]="ride.price.currency"
                                      [driverPrice]="ride.driverPrice"
                                      [driverSettlement]="ride.driverSettlement"
                                      [isDriver]="isDriver"
                                      [isOwner]="isOwner">
            </app-ride-commission-data>
          </div>

          <!-- Ride Debtor Data -->
          <div *ngIf="ride.Debtor && ride.ownerId == driverId" class="push-bottom-sm">
            <mat-divider class="push-bottom-sm"></mat-divider>
            <app-ride-debtor-data [debtor]="ride.Debtor"></app-ride-debtor-data>
          </div>

        </mat-list>
      </ng-template>
    </mat-card>
  </div>
  <div flex-gt-sm="50">
    <mat-card class="details-card">
      <ng-template tdLoading="ride.details2">
        <mat-list>
          <!-- Direct assign driver -->
          <ng-template *ngIf="(media.registerQuery('gt-md') | async) &&
          !['completed','canceled','canceled_driver','canceled_passenger'].includes(ride.status)" tdLoading="ride.details.driver">
            <app-ride-edit-assign (reloadData)="reloadDriverData()" [driverNote]="ride.driverNote"
                                  [receiptUrl]="(ride.resources ? ride.resources.receiptSummaryUrl : null)"
                                  [ride]="ride"></app-ride-edit-assign>
            <mat-divider class="push-bottom-sm"></mat-divider>
          </ng-template>

          <!-- Ride Vehicle Data -->
          <div *ngIf="ride.Vehicle">
            <app-ride-vehicle-data [vehicle]="ride.Vehicle"></app-ride-vehicle-data>
            <mat-divider class="push-bottom-sm"></mat-divider>
          </div>
        </mat-list>
      </ng-template>
    </mat-card>

    <mat-card class="example-card">
      <mat-card-title>{{'map'|translate|ucFirst}}</mat-card-title>
      <mat-card-subtitle>{{'ride_map_subtitle'|translate|ucFirst}}</mat-card-subtitle>
      <mat-divider></mat-divider>
      <mat-card-content>
        <ng-template tdLoading="ride.map">
          <agm-map (mapReady)="mapReady($event)" [usePanning]="true" [zoom]="10">
            <app-ride-map [gpsLogEntries]="gpsLogEntries" [ride]="ride" [shift]="shift"></app-ride-map>
          </agm-map>
        </ng-template>
      </mat-card-content>
    </mat-card>
    <mat-card class="push-top">
      <mat-card-title>{{'ratings'|translate|ucFirst}}</mat-card-title>
      <mat-card-subtitle>{{'ride_details_rating_subtitle'|translate|ucFirst}}</mat-card-subtitle>
      <mat-divider></mat-divider>
      <ng-template tdLoading="ride.ratings">
        <mat-list class="will-load">
          <div *ngIf="ride.Ratings?.length === 0" class="mat-padding" layout="row" layout-align="center center">
            <h3>No ratings to display.</h3>
          </div>
          <ng-template [ngForOf]="ride.Ratings" let-last="last" let-rating ngFor>
            <mat-list-item>
              <mat-icon *ngIf="rating.type == 'passenger'" mat-list-avatar>local_taxi</mat-icon>
              <mat-icon *ngIf="rating.type == 'operator'" mat-list-avatar>headset_mic</mat-icon>
              <h3 mat-line>
              <span *ngFor="let score of [1,2,3,4,5]; let i = index;">
                <mat-icon *ngIf="score <= rating.score">star</mat-icon>
                <mat-icon *ngIf="score > rating.score">star_border</mat-icon>
              </span>
              </h3>
              <p mat-line title="{{rating.comment}}">{{rating.comment}}</p>
            </mat-list-item>
            <mat-divider *ngIf="!last" mat-inset></mat-divider>
          </ng-template>
        </mat-list>
      </ng-template>
    </mat-card>
    <mat-card *ngIf="isOwner && !isDriver" class="push-top">
      <mat-card-title>{{'operator_rating'|translate|ucFirst}}</mat-card-title>
      <mat-card-subtitle>{{'operator_rating_subtitle'|translate|ucFirst}}</mat-card-subtitle>
      <mat-divider class="push-bottom"></mat-divider>
      <ng-template tdLoading="ride.operator.rating">
        <div class="operator-rating-first-field push-left push-right" layout="row">
          <mat-form-field flex>
            <mat-select [(ngModel)]="operatorRating.score" flex placeholder="Score">
              <mat-option [value]="1">1</mat-option>
              <mat-option [value]="2">2</mat-option>
              <mat-option [value]="3">3</mat-option>
              <mat-option [value]="4">4</mat-option>
              <mat-option [value]="5">5</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="push-left push-right" layout="row">
          <mat-form-field flex>
            <textarea [(ngModel)]="operatorRating.comment" matInput
                      placeholder="{{'comment'|translate|ucFirst}}">{{operatorRating.comment}}</textarea>
          </mat-form-field>
        </div>
      </ng-template>
      <mat-divider></mat-divider>
      <mat-card-actions>
        <button (click)="saveOperatorRating()" mat-raised-button>Save rating</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
