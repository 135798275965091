import {Component, Input, OnInit} from '@angular/core';
import {Driver} from '../../models/driver';
import {Vehicle} from '../../models/vehicle';

@Component({
  selector: 'driver-profile-widget',
  templateUrl: './driver-profile-widget.component.html',
  styleUrls: ['./driver-profile-widget.component.scss']
})
export class DriverProfileWidgetComponent implements OnInit {

  @Input() title: string = '';
  @Input() subTitle: string = '';
  @Input() cssClasses: string = '';
  @Input() buttonLink: string;
  @Input() buttonText: string;

  @Input() driver: Driver;
  @Input() vehicles: Vehicle[];

  defaultCssClasses: string = 'mat-card';

  constructor() {
  }

  ngOnInit() {
  }

}
