import {Timeframe} from 'app/models/timeframe';
import {Product} from './product';

export enum OnMeterTypes {
  true = 'allow_on_meter',
  false = 'dont_allow_on_meter',
}

export enum FixedTypes {
  true = 'use_as_fixed_price',
  false = 'no',
}

export interface RuleLink {
  _id?: string;
  ruleId: string;
  isAllowedOnMeter: boolean;
  isFixed: boolean;
  rule: Rule;
  ruleableId?: string;
  ruleableType?: string;
}

export class Price {
  _id?: string;
  priceDynamic: PriceDynamic;
  priceFixed: PriceFixed;
  priceHourly: PriceHourly;
  productId: string;
  isEnabled: boolean;
  minuteWaitingPrice: number;
  cascadingThresholdCalculation: boolean;
  ruleId: string;

  constructor(
    public product: Product,
    public products?: Product[],
  ) {
    this.priceDynamic = new PriceDynamic();
    this.priceFixed = new PriceFixed();
    this.priceHourly = new PriceHourly();
    this.productId = this.product._id;
    this.isEnabled = true;
    this.minuteWaitingPrice = 0;
    this.cascadingThresholdCalculation = true;
  }
}

export class PriceDynamic {
  _id?: string;
  dynamicStartPrice: number;
  dynamicMinimumPrice: number;
  dynamicMinutePrice: number;
  dynamicDistancePrice: number;
  thresholds: Threshold[];
  priceId: string;

  constructor() {
    this.dynamicStartPrice = 0;
    this.dynamicMinimumPrice = 0;
    this.dynamicMinutePrice = 0;
    this.dynamicDistancePrice = 0;
    this.thresholds = [];
  }
}

export class PriceHourly {
  _id?: string;
  hourlyStartPrice: number;
  hourlyMinimumPrice: number;
  hourlyPrice: number;
  thresholds: Threshold[];
  priceId: string;

  constructor() {
    this.hourlyStartPrice = 0;
    this.hourlyMinimumPrice = 0;
    this.hourlyPrice = 0;
    this.thresholds = [];
  }
}

export class PriceFixed {
  _id?: string;
  thresholds: Threshold[];
  fixedPrice: number;
  priceId: string;

  constructor() {
    this.thresholds = [];
    this.fixedPrice = 0;
  }
}

export class Threshold {
  _id?: string;
  type: 'distance' | 'duration';
  threshold: number;
  value: number;

  constructor() {
  }
}

export enum RuleTypes {
  dynamic = 'dynamic',
  fixed = 'location to location',
  meter = 'meter',
  hourly = 'hourly',
}

export class Rule {
  _id?: string;
  companyId: string;
  destinationId?: string;
  departureId?: string;
  destination?: Location;
  departure?: Location;
  name: string;
  taxiMeter?: string;
  timeLimit?: string;
  type: 'dynamic' | 'fixed' | 'hourly';
  ruleLocation: 'noLimit' | 'either' | 'and';
  passengerMultiply: boolean;
  passengerPercentage?: number[];
  priority: number;
  timeframes: Timeframe[];
  prices?: Price[] = [];
  products?: Product[];
  product?: Product;
  parentRuleId?: string;
  childRules?: Rule[];

  constructor(companyId?: string, parentRuleId?: string, products?: Product[]) {
    this.companyId = companyId;
    this.name = '';
    this.type = 'dynamic';
    this.ruleLocation = 'noLimit';
    this.priority = 1;
    this.timeframes = [new Timeframe()];
    this.parentRuleId = parentRuleId;
    this.childRules = [];

    this.initRelations(products);
  }

  static ruleTypeNamesMapping = (type: 'fixed' | 'dynamic' | 'meter' | 'hourly') =>
    RuleTypes[type] || 'Rule type name not found';

  initRelations(products: Product[]) {
    if (products) {
      products.forEach(product => {
        this.prices.push(new Price(product));
      });
      this.products = products;
    }
  }

  setPrices(prices: Price[]) {
    this.prices = prices;
  }

  getPrices() {
    return this.prices;
  }

  setProducts(products: Product[]) {
    this.products = products;
  }

  getProducts() {
    return this.products;
  }
}
