<mat-card-content class="push-bottom-none">
  <mat-card-title>{{'connect_drivers_to_user_title'|translate | ucFirst}}</mat-card-title>
  <mat-card-subtitle>{{subTitle}}</mat-card-subtitle>

  <div class="driver-details push-bottom">
    <table>
      <tr>
        <td class="col_title">{{'drivername'|translate|ucFirst}}:</td>
        <td>{{drivers[0].fname}} {{drivers[0].lname}}</td>
      </tr>
      <tr>
        <td class="col_title">{{'driverphone'|translate|ucFirst}}:</td>
        <td>{{drivers[0].phoneNumber}}</td>
      </tr>
      <tr>
        <td class="col_title">{{'driveremail'|translate|ucFirst}}:</td>
        <td>{{drivers[0].emailAddress}}</td>
      </tr>
    </table>
  </div>

  <div class="sub-text">{{'signup_connect_invite_send_text2'|translate | ucFirst}}</div>

  <mat-divider></mat-divider>

  <mat-card-actions>
    <button (click)="save('accept')" class="accept-button convert-button push-bottom" flex
            mat-raised-button>
      <mat-icon>check</mat-icon>
      {{'confirm'|translate|ucFirst}}</button>

    <button (click)="save('ignore')" class="convert-button push-bottom" color="warn" flex mat-raised-button>
      <mat-icon>clear</mat-icon>
      {{'decline'|translate|ucFirst}}</button>
  </mat-card-actions>
</mat-card-content>
