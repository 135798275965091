import {Component} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {Contract} from '../../../models/contract';
import {Company} from '../../../models/company';

@Component({
  selector: 'app-business-license',
  templateUrl: './business-license.component.html',
  styleUrls: ['./business-license.component.scss']
})
export class BusinessLicenseComponent {
  company: Company;

  setBusinessContract(vault) {
    const driver: any = vault.getObject(`${environment.vaultPrefix}.driver`);
    if (driver && driver.Installation && driver.Installation.Subscription && driver.Installation.Subscription.plan === 'dispatcher') {
      this.company.hasDispatcherLicense = true;
    }

    if (this.company.ActiveContract && !this.company.ActiveContract.foreignStatus) {
      const contract = new Contract();
      contract.foreignStatus = 'active';
      this.company.ActiveContract = contract;
    }
  }
}
