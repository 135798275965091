import {Component, Inject, OnInit} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';
import {AuthService} from '../services/auth.service';
import {ActivatedRoute, Router, RoutesRecognized} from '@angular/router';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  user: any;

  constructor(private _iconRegistry: MatIconRegistry,
              private _domSanitizer: DomSanitizer,
              private _router: Router,
              private _route: ActivatedRoute,
              private _vault: CoolLocalStorage,
              private _authService: AuthService,
              @Inject(DOCUMENT) private document: Document) {
    // Register the logo SVG as an asset
    this._iconRegistry.addSvgIconInNamespace(
      'assets',
      'logo',
      this._domSanitizer.bypassSecurityTrustResourceUrl(`assets/${environment.buildName}/logo.svg`)
    );
    this.user = this._vault.getObject(`${environment.vaultPrefix}.user`);
    if (!this.user || !this.user.ydaSupportUser) {
      this._router.events.subscribe((data) => {
        if (data instanceof RoutesRecognized) {
          if (data.state.root.firstChild.params['id']) {
            if (this._vault.getItem(`${environment.vaultPrefix}.allowedCompanies`) && !this._vault.getItem(`${environment.vaultPrefix}.allowedCompanies`).includes(data.state.root.firstChild.params['id'])) {
              console.warn('Not allowed');
              this._authService.logout();
            }
          }
        }
      });
    }
    if (this.user && !this.user.ydaSupportUser) {
      (window as any).intercomSettings = {
        api_base: 'https://api-iam.intercom.io',
        app_id: 'm20dk4wh',
        name: `${this.user.firstName} ${this.user.lastName}`, // Full name
        user_id: this.user.id, // a UUID for your user
        email: this.user.email, // the email for your user
        phone: this.user.phoneNumber, // the email for your user
        created_at: this.user.created // Signup date as a Unix timestamp
      };
      // console.log((window as any).intercomSettings);
    }
  }

  ngOnInit(): void {
    this.createGTM();
  }

  gtag(event, data): void {
    if ((window as any).dataLayer) {
      (window as any).dataLayer.push(arguments);
    }
  }

  createGTM(): void {
    const position = this.document.getElementsByTagName('script')[0];
    const script = this.document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.gtmId}`;
    position.parentNode.insertBefore(script, position);
    this.gtag('js', new Date());
    this.gtag('config', environment.gtmId);
    this.gtag('pageview', '/signup');
  }
}
