import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UserService} from '../../../services/user.service';
import {TranslateService} from '@ngx-translate/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {environment} from '../../../../environments/environment';
import {CoolLocalStorage} from '@angular-cool/storage';
import {AuthService} from '../../../services/auth.service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {
  validated = false;
  token: string;
  translations: string[];

  constructor(private _route: ActivatedRoute,
              private _snackbar: MatSnackBar,
              private _authService: AuthService,
              private _vault: CoolLocalStorage,
              private _userService: UserService,
              private _translateService: TranslateService) {
    const self = this;
    this.token = this._route.snapshot.params['token'];
    this._vault.removeItem(`${environment.vaultPrefix}.user`);
    this._vault.removeItem(`${environment.vaultPrefix}.accessToken`);
    this._vault.removeItem(`${environment.vaultPrefix}.redirectAfterSignup`);
    this._vault.removeItem(`${environment.vaultPrefix}.trialAllowed`);
    this._vault.removeItem(`${environment.vaultPrefix}.loginJob`);
    this._vault.removeItem(`${environment.vaultPrefix}.driver`);
    this._vault.removeItem(`${environment.vaultPrefix}.companyDriverId`);
    this._vault.removeItem(`${environment.vaultPrefix}.operator`);
    this._vault.clear();
    this._authService.setAuthState(false);

    _translateService.get([
      'new_user_email_verified_success'
    ]).subscribe((translations: any) => {
      self.translations = translations;
      self._userService.verifyEmailAddress({'resetToken': self.token}, 'company').subscribe(() => {
        self.validated = true;
        self._snackbar.open(self.translations['new_user_email_verified_success'], '', {duration: 10000});
      });
    });
  }

  ngOnInit(): void {
  }

}
