<mat-card class="{{defaultCssClasses}} {{cssClasses}}">
  <mat-card-title>{{title|translate|ucFirst}}</mat-card-title>
  <mat-card-subtitle>{{subTitle|translate|ucFirst}}</mat-card-subtitle>
  <mat-divider></mat-divider>
  <mat-tab-group>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="push-right-sm">person</mat-icon>
        {{'personal_data'|translate|ucFirst}}
      </ng-template>
      <mat-list>
        <mat-list-item class="user-avatar">
          <img *ngIf="driver.avatar" alt="avatar" mat-list-avatar src="{{driver.avatar.small}}">
          <mat-icon *ngIf="!driver.avatar" mat-list-icon>account_circle</mat-icon>
          <h3 mat-line>{{driver.fname}} {{driver.lname}}</h3>
          <p mat-line>{{'name'|translate|ucFirst}}</p>
        </mat-list-item>
        <mat-list-item>
          <mat-icon mat-list-icon>email</mat-icon>
          <h3 mat-line>{{driver.emailAddress}}</h3>
          <p mat-line>{{'email'|translate|ucFirst}}</p>
        </mat-list-item>
        <mat-list-item >
          <mat-icon mat-list-icon>phone</mat-icon>
          <h3 mat-line>{{driver.phoneNumber}}</h3>
          <p mat-line>{{'phone_number'|translate|ucFirst}}</p>
        </mat-list-item>
        <mat-list-item *ngIf="driver.meta && driver.meta.licenseNumber">
          <h3 mat-line>{{driver.licenseNumber}}</h3>
          <p mat-line>{{'license_number'|translate|ucFirst}}</p>
        </mat-list-item>
      </mat-list>
    </mat-tab>
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="push-right-sm">local_taxi</mat-icon>
        {{'vehicles'|translate|ucFirst}}
      </ng-template>
      <mat-list *ngIf="vehicles.length > 0">
        <mat-list-item *ngFor="let vehicle of vehicles">
          <h3 mat-line>{{vehicle.brand}} {{vehicle.model}}</h3>
          <p mat-line>{{vehicle.licensePlate}} ({{vehicle.maxPassengers}} {{'passengers'|translate|ucFirst}})</p>
          <p mat-line>{{'type'|translate|ucFirst}}: {{vehicle.type|translate|ucFirst}}</p>
        </mat-list-item>
      </mat-list>
      <div *ngIf="vehicles?.length === 0" class="mat-padding" layout="row" layout-align="center center">
        <h4>{{'no_data'|translate|ucFirst}}</h4>
      </div>
    </mat-tab>
  </mat-tab-group>

  <span *ngIf="buttonText">
    <mat-divider></mat-divider>
    <mat-card-actions>
      <a [routerLink]="[buttonLink]" color="accent" mat-raised-button>
        <span>{{buttonText|translate|ucFirst}}</span>
      </a>
    </mat-card-actions>
  </span>
</mat-card>
