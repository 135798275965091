import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Company} from '../../../models/company';
import {ActivatedRoute} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {CoolLocalStorage} from '@angular-cool/storage';
import {Contract} from '../../../models/contract';
import {environment} from '../../../../environments/environment';
import {CompanyService} from '../../../services/company.service';
import {CompanyWebUserService} from '../../../services/companyWebUser.service';
import {UtilityService} from '../../../services/utility.service';
import {TranslateService} from '@ngx-translate/core';
import {LicensesComponent} from '../licenses/licenses.component';
import {TdDialogService} from '@covalent/core/dialogs';
import {User} from '../../../models/user';

@Component({
  selector: 'app-no-contract',
  templateUrl: './no-contract.component.html',
  styleUrls: ['./no-contract.component.scss'],
  animations: [
    trigger('fade', [
      state('open', style({
        opacity: 1
      })),
      state('closed', style({
        opacity: 0
      })),
      transition('open => closed', [
        animate('1s')
      ]),
      transition('closed => open', [
        animate('0.5s')
      ]),
    ])
  ]
})
export class NoContractComponent implements AfterViewInit, OnInit {
  @Input() companyId: string;
  @Input() contract: Contract;
  @Input() type: string;
  @Input() step = 1;
  company: Company;
  driver: any;
  isOwner = false;
  translations: string[] = [];
  state = 'closed';

  constructor(private _route: ActivatedRoute,
              private _companyService: CompanyService,
              private _translationService: TranslateService,
              private _dialogService: TdDialogService,
              private _companyWebUserService: CompanyWebUserService,
              private _vault: CoolLocalStorage) {
    const self = this;
    if (!this.companyId) {
      const {company} = this._route.parent.snapshot.data;
      UtilityService.setBrowserTimeStamp(company);
      this.company = company;
      this.companyId = company.id;
    }

    const user: any = this._vault.getObject(`${environment.vaultPrefix}.user`);
    const driver: any = this._vault.getObject(`${environment.vaultPrefix}.driver`);

    if (user.ydaSupportUser) {
      this.isOwner = true;
    } else if (this.company.ownerType === 'WebPortalUser' && this.company.ownerId === user.id) {
      this.isOwner = true;
    } else if (this.company.ownerType === 'CompanyWebUser') {
      this._companyWebUserService.getAll({where: {'companyId': this.company.id, type: 'owner'}})
        .subscribe((users) => {
          if (users.filter((u) => {
            return (u.userId === user.id);
          }).length > 0) {
            self.isOwner = true;
          }
        }, (error) => {
          console.log(error);
        });
    } else if (this.company.ownerType === 'Driver') {
      _companyService.get(this.company.id, {include: ['Owner']})
        .subscribe((c) => {
          if (c.Owner.phoneNumber === driver.phoneNumber) {
            self.isOwner = true;
          }
        });
    }
  }

  ngOnInit(): void {
    if (!this.type) {
      this.type = 'fleet';
    }

    if (!this.company.ActiveContract) {
      this.step = 2;
    }

    this._translationService.get(['feature_not_included', `customer_license_${this.type}`]).subscribe((translations) => {
      this.translations = translations;
      this.translations['feature_not_included'] = translations['feature_not_included'].replace('{type}', translations[`customer_license_${this.type}`]);
      if (this.step === 2) {
        this.openUpgradeContract();
      }
    });
  }

  ngAfterViewInit(): void {
    const self = this;
    setTimeout(function () {
      self.state = 'open'
    }, 200);
  }

  openUpgradeContract(): void {
    const user: User = this._vault.getObject(`${environment.vaultPrefix}.user`);
    this.step = 2;
    // @ts-ignore
    this._dialogService.open(LicensesComponent, {
      maxHeight: '90%',
      disableClose: (!user.ydaSupportUser),
      panelClass: 'no-dialog-padding',
      maxWidth: (window.innerWidth < 600 ? '100%' : '750px'),
      data: {
        parent: this,
        company: this.company,
      },
    });
  }
}
