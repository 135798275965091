<div [formGroup]="form" flex="100" class="condition-pane">
  <div formArrayName="actions">
    <div [formGroupName]="i">
      <div formArrayName="messages">
        <div [formGroupName]="translationIndex">
          <mat-card-title>{{ 'message_center_action_sms_title' | translate | ucFirst }}</mat-card-title>
          <mat-form-field *ngIf="action === 'add'">
            <mat-select (selectionChange)="changeSelectionMatchKey($event, i)"
                        class="row-select"
                        flex="100"
                        formControlName="language" required>
              <mat-option *ngFor="let language of languages"
                          [value]="language.short">{{ language.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <div class="textarea-wrapper push-bottom">
            <textarea class="inputTextArea {{(hasError? 'input-warning' : '')}}" matInput
                      cdkTextareaAutosize
                      [mention]="items"
                      [mentionConfig]="{triggerChar:'{',maxItems:20,labelKey:'name', 'mentionSelect': formatMention, disableSearch:false}"
                      formControlName="message"
                      rows="5" type="text" (keydown)="valueChange(i, $event)"
                      (keyup)="valueChange(i, $event);hasError=false"></textarea>

            <span class="remaning">{{ remainingText[i] }}/{{ initMaxText }}</span>
            <div class="sms_remark" *ngIf="Math.ceil(currentLength[i]/160) > 1">
              {{ 'sms_costs_messages_notification' | translate | ucFirst }}
              <strong>{{ Math.ceil(currentLength[i] / 160) }}</strong>
              {{ 'sms_messages' | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<mat-divider></mat-divider>
<mat-card-actions>
  <button (click)="save()" mat-raised-button color="accent" class="td-menu-button">
    {{ 'save' | translate | ucFirst }}
  </button>
  <button (click)="(action==='edit' ? close() : delete())" mat-flat-button class="td-menu-button">
    {{ 'cancel' | translate | ucFirst }}
  </button>
  <button (click)="delete()" *ngIf="action==='edit'" mat-flat-button class="td-menu-button no-right-padding">
    {{ 'remove_translation' | translate | ucFirst }}
  </button>
</mat-card-actions>
