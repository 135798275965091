<mat-list-item>
  <mat-icon mat-list-icon>person_pin</mat-icon>
  <h4 mat-line>{{'vehicle'|translate|ucFirst}}</h4>
  <p mat-line>{{'ride_vehicle_subtitle'|translate|ucFirst}}</p>
</mat-list-item>
<div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'brand'|translate|ucFirst}}</p>
    <p mat-line>{{vehicle.brand || ''}}</p>
  </mat-list-item>
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'model'|translate|ucFirst}}</p>
    <p mat-line>{{vehicle.model || ''}}</p>
  </mat-list-item>
</div>
<div [mediaClasses]="['push-sm']" class="pull-top-sm" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <mat-list-item class="value small push-bottom" flex-gt-sm="100">
    <p mat-line>{{'license_plate'|translate|ucFirst}}</p>
    <p mat-line>{{vehicle.licensePlate || ''}}</p>
  </mat-list-item>
</div>
