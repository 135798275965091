<mat-card class="{{defaultCssClasses}} {{cssClasses}}">
  <mat-card-title>{{'driver_score'|translate}}</mat-card-title>
  <mat-card-subtitle>{{'driver_score_subtitle'|translate}}</mat-card-subtitle>
  <mat-divider></mat-divider>
  <mat-card-content *ngIf="driverScore" class="text-center">
    <div layout="row">
      <mat-card class="pad-md" flex="100" layout="column">
        <div *ngIf="driverScore.total" class="mat-headline">
          {{driverScore.total|number:'1.1-1'}}
        </div>
        <div *ngIf="!driverScore.total" class="mat-headline">
          -
        </div>
        <mat-icon class="icon mat-primary">account_box</mat-icon>
        <div layout="column">
          <div class="mat-subhead">{{'total_score'|translate}}</div>
        </div>
      </mat-card>
    </div>
    <p>{{'driver_score_built_up'|translate}}</p>
    <div class="" layout="row">
      <mat-card (click)="showRatings('passenger')" class="pad-md clickable" flex="33" layout="column">
        <div *ngIf="driverScore.passengerRating" class="mat-headline">
          {{driverScore.passengerRating.average|number:'1.1-1'}}
        </div>
        <div *ngIf="!driverScore.passengerRating" class="mat-headline">
          -
        </div>
        <mat-icon class="icon mat-primary">local_taxi</mat-icon>
        <div layout="column">
          <div class="mat-subhead">{{'customer_rating'|translate}}</div>
        </div>
      </mat-card>
      <mat-card (click)="showRatings('operator')" class="pad-md clickable" flex="33" layout="column">
        <div *ngIf="driverScore.operatorRating" class="mat-headline">
          {{driverScore.operatorRating.average|number:'1.1-1'}}
        </div>
        <div *ngIf="!driverScore.operatorRating" class="mat-headline">
          -
        </div>
        <mat-icon class="icon mat-primary">headset_mic</mat-icon>
        <div layout="column">
          <div class="mat-subhead">{{'operator_rating'|translate}}</div>
        </div>
      </mat-card>
      <mat-card class="pad-md" flex="33" layout="column">
        <div *ngIf="driverScore.jobHandleScore" class="mat-headline">
          {{driverScore.jobHandleScore.average|number:'1.1-1'}}
        </div>
        <div *ngIf="!driverScore.jobHandleScore" class="mat-headline">
          -
        </div>
        <mat-icon class="icon mat-primary">update</mat-icon>
        <div layout="column">
          <div class="mat-subhead">{{'response'|translate}}</div>
        </div>
      </mat-card>
    </div>
  </mat-card-content>
  <mat-card-content *ngIf="!driverScore">
    <p>{{'driver_score_no_data'|translate}}</p>
  </mat-card-content>
</mat-card>
