export class PaymentMeta {
  description?: string;
  method?: string;
  moment?: string;
  origin?: string;
  psp?: string;
  paymentMethodId?: string;
  hourlyRate?: number;
  bookedDuration?: number;
  currency?: string;

  constructor() {
    this.description = '';
    this.method = '';
    this.moment = '';
    this.origin = '';
    this.psp = '';
  }
}
