import {Injectable} from '@angular/core';
import {UtilityService} from './utility.service';
import {ActivatedRoute} from '@angular/router';

@Injectable()
export class TimeZoneService {
  constructor(_route: ActivatedRoute) {
    const {company} = _route.parent.snapshot.data;
    console.log('TimeZoneService');
    if (company) {
      UtilityService.setBrowserTimeStamp(company);
    }
  }
}
