import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RideService} from '../../../../../../services/ride.service';
import {Ride} from '../../../../../../models/ride';
import {Company} from '../../../../../../models/company';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Passenger} from '../../../../../../models/passenger';
import {TdDialogService} from '@covalent/core/dialogs';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-update-payment-method',
  templateUrl: './update-payment-method.component.html',
  styleUrls: ['./update-payment-method.component.scss'],
  providers: [RideService]
})
export class UpdatePaymentMethodComponent implements OnInit {
  ride: Ride;
  company: Company;
  passenger: Passenger;
  parent: any;
  translations: string[];
  form: UntypedFormGroup;

  constructor(
    private _rideService: RideService,
    private formBuilder: UntypedFormBuilder,
    private _dialogService: TdDialogService,
    private _translateService: TranslateService,
    public dialogRef: MatDialogRef<UpdatePaymentMethodComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const self = this;

    self.parent = data.parent;
    self.company = data.parent.company;

    _translateService.get([
      'no_passenger_selected_title',
      'ok',
    ]).subscribe((translations: any) => {
      self.translations = translations;
      _rideService.get(data.rideId, {'include': 'Passenger'}).subscribe((r) => {
        self.ride = r;
        self.passenger = r.passenger;

        self.form = this.formBuilder.group({
          paymentMeta: this.formBuilder.group({
            origin: [self.ride.paymentMeta.origin, Validators.required],
            paymentMethodId: [self.ride.passenger.paymentMethodId, Validators.required],
          }),
        });
        self.passenger.id = r.passengerId;
      });
    });
  }

  ngOnInit(): void {
  }

  save(): void {
    this.dialogRef.close()
    this.parent._loadingService.register();
    this._rideService.update(this.ride.id, {'paymentMeta': this.form.controls['paymentMeta'].value})
      .subscribe(() => {
        this.parent.loadData();
        this.parent.filter(this.parent.selectFilter);
        this.parent._loadingService.resolve();
      })
  }
}
