import {Timeframe} from 'app/models/timeframe';

export interface DiscountLink {
  _id?: string;
  discountId: string;
  discount: Discount;
}

export class Discount {
  _id?: string;
  companyId: string;
  name: string;
  value: number;
  type: 'fixed' | 'percentage';
  ruleLocation: 'noLimit' | 'either' | 'and';
  modifier: 1 | -1;
  priority: number;
  timeframes: Timeframe[];
  departureId: string;
  destinationId: string;
  destination?: Location;
  departure?: Location;

  constructor() {
    this.type = 'fixed';
    this.ruleLocation = 'and';
    this.value = 200;
    this.priority = 1;
    this.name = 'New Special Rate';
    this.timeframes = [new Timeframe()];
  }
}
