<div [mediaClasses]="['push-sm']" class="push-bottom-sm push-top-sm push-left-sm push-right-sm" layout-gt-md="row"
     tdMediaToggle="gt-md">
  <div flex-gt-md="50">
    <mat-card class="details-card left-card">
      <mat-card-title>{{'job_details'|translate|ucFirst}}</mat-card-title>
      <mat-card-subtitle>{{'job_details_subtitle'|translate|ucFirst}}</mat-card-subtitle>
      <mat-divider></mat-divider>
      <ng-template tdLoading="ride.details">
        <mat-list>
          <!-- Direct assign driver -->
          <ng-template *ngIf="!(media.registerQuery('gt-md') | async) &&
          !['completed','canceled','canceled_driver','canceled_passenger'].includes(ride.status) &&
this.company.ActiveContract.type !== 'webbooker_license'" tdLoading="ride.details.driver2">
            <app-ride-edit-assign (reloadData)="reloadDriverData()" [driverNote]="ride.driverNote"
                                  [receiptUrl]="(ride.resources ? ride.resources.receiptSummaryUrl : null)"
                                  [ride]="ride"></app-ride-edit-assign>
            <mat-divider class="push-bottom-sm"></mat-divider>
          </ng-template>

          <!-- Ride Details Data -->
          <app-ride-details-data [ride]="ride"></app-ride-details-data>
          <mat-divider class="push-bottom-sm"></mat-divider>

          <!-- Ride Location Data -->
          <app-ride-location-data [actualDeparture]="ride.events?.started?.address"
                                  [actualDestination]="ride.events?.completed?.address"
                                  [departure]="ride.departure"
                                  [stopOvers]="ride.stopOvers"
                                  [destination]="ride.destination"
                                  [metrics]="ride.metrics"></app-ride-location-data>
          <mat-divider class="push-bottom-sm"></mat-divider>

          <!-- Ride Time Data -->
          <app-ride-time-data [canceled]="(ride.events && ride.events.canceled ?
                              moment(ride.events.canceled.date).toDate() :
                               (ride.events && ride.events.canceled_passenger ?
                              moment(ride.events.canceled_passenger.date).toDate() :
                               null))"
                              [completed]="(ride.events && ride.events.completed ? moment(ride.events.completed.date).toDate() : null)"
                              [created]="ride.created"
                              [pickupDate]="ride.pickupDate"
                              [requestedDate]="ride.requestedDate"
                              [rideEvents]="ride.events"
                              [status]="ride.status"></app-ride-time-data>
          <mat-divider class="push-bottom-sm"></mat-divider>

          <!-- Ride Passenger Data -->
          <app-ride-passenger-data [flightNumber]="ride.flightNumber"
                                   [passengerCount]="ride.passengerCount"
                                   [passenger]="ride.passenger"
                                   [rideId]="ride.id"></app-ride-passenger-data>
          <mat-divider class="push-bottom-sm"></mat-divider>

          <div *ngIf="hasPassengerRating">
            <mat-list-item>
              <mat-icon mat-list-icon>star</mat-icon>
              <h4 mat-line>{{'passenger_rating_subtitle'|translate|ucFirst}}</h4>
              <p mat-line>{{'passenger_rating'|translate|ucFirst}}</p>
            </mat-list-item>
            <div class="value-container push first">
              <p class="label">{{'score'|translate|ucFirst}}</p>
              <p class="value">
                <span *ngFor="let score of [1,2,3,4,5]; let i = index;">
                  <mat-icon *ngIf="score <= passengerRating.score">star</mat-icon>
                  <mat-icon *ngIf="score > passengerRating.score">star_border</mat-icon>
                </span>
              </p>
            </div>
            <div class="value-container push">
              <p class="label">{{'comment'|translate|ucFirst}}</p>
              <p class="value">{{passengerRating.comment}}</p>
            </div>
            <mat-divider class="push-bottom-sm"></mat-divider>
          </div>

          <!-- Ride Price Data -->
          <div *ngIf="ride.price">
            <app-ride-price-data [coupon]="ride.Coupon" [isOwner]="isOwner" [price]="ride.price"
                                 [ride]="ride"
                                 [vehicleProduct]="vehicleProduct"></app-ride-price-data>
          </div>

          <!-- Ride Payment Data -->
          <div *ngIf="ride.paymentMeta" class="push-bottom-sm">
            <app-ride-payment-data [paymentMeta]="ride.paymentMeta" [priceMeta]="ride.priceMeta"
                                   [ride]="ride"></app-ride-payment-data>
          </div>

          <!-- Ride Commission Data -->
          <div *ngIf="ride.commission && ride.commissionRules">
            <mat-divider class="push-bottom-sm"></mat-divider>
            <app-ride-commission-data [commissionRules]="ride.commissionRules"
                                      [commission]="ride.commission"
                                      [currency]="ride.price.currency"
                                      [driverPrice]="ride.driverPrice"
                                      [driverSettlement]="ride.driverSettlement"
                                      [isDriver]="isDriver"
                                      [isOwner]="isOwner">
            </app-ride-commission-data>
          </div>
        </mat-list>
      </ng-template>
    </mat-card>
  </div>
  <div flex-gt-md="50">
    <mat-card class="details-card">
      <ng-template tdLoading="ride.details2">
        <mat-list>
          <!-- Direct assign driver -->
          <ng-template *ngIf="(media.registerQuery('gt-md') | async) &&
          !['completed','canceled','canceled_driver','canceled_passenger'].includes(ride.status) &&
          this.company.ActiveContract.type !== 'webbooker_license'" tdLoading="ride.details.driver">
            <app-ride-edit-assign (reloadData)="reloadDriverData()" [driverNote]="ride.driverNote"
                                  [receiptUrl]="(ride.resources ? ride.resources.receiptSummaryUrl : null)"
                                  [ride]="ride"></app-ride-edit-assign>
            <mat-divider class="push-bottom-sm"></mat-divider>
          </ng-template>

          <!-- Ride Dispatcher Data -->
          <div *ngIf="ride.Dispatcher">
            <app-ride-dispatcher-data [dispatcher]="ride.Dispatcher"
                                      [operatorNote]="ride.operatorNote"></app-ride-dispatcher-data>
            <mat-divider class="push-bottom-sm"></mat-divider>
          </div>

          <!-- Ride Driver Data -->
          <div *ngIf="ride.Driver && ride.resources &&
          (['completed','canceled','canceled_driver','canceled_passenger'].includes(ride.status) && this.companyId)">
            <app-ride-driver-data (reloadData)="reloadDriverData()" [driverNote]="ride.driverNote" [driver]="ride.Driver"
                                  [receiptUrl]="ride.resources.receiptSummaryUrl"
                                  [ride]="ride"></app-ride-driver-data>
            <mat-divider class="push-bottom-sm"></mat-divider>
          </div>

          <!-- Ride Vehicle Data -->
          <div *ngIf="ride.Vehicle">
            <app-ride-vehicle-data [vehicle]="ride.Vehicle"></app-ride-vehicle-data>
            <mat-divider class="push-bottom"></mat-divider>
          </div>
        </mat-list>
      </ng-template>
    </mat-card>

    <app-ride-messagelog-data *ngIf="ride.MessageLog" [ride]="ride"></app-ride-messagelog-data>

    <app-ride-documents (reloadData)="loadData()" *ngIf="ride.resources" [ride]="ride"></app-ride-documents>

    <mat-card *ngIf="(media.registerQuery('gt-md') | async)">
      <mat-list-item>
        <mat-icon mat-list-icon>location_pin</mat-icon>
        <h4 mat-line>{{'map'|translate|ucFirst}}</h4>
        <p mat-line>{{'ride_map_subtitle'|translate|ucFirst}}</p>
      </mat-list-item>
      <mat-divider></mat-divider>
      <mat-card-content>
        <ng-template tdLoading="ride.details.map">
          <agm-map (mapReady)="mapReady($event)"
                   [latitude]="(ride.departure && ride.departure.gps ? ride.departure.gps.lat : null )"
                   [longitude]="(ride.departure && ride.departure.gps ? ride.departure.gps.lng : null )"
                   [usePanning]="true"
                   [zoom]="10">
            <app-ride-map [gpsLogEntries]="gpsLogEntries" [ride]="ride" [shift]="shift"></app-ride-map>
          </agm-map>
        </ng-template>
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="this.ride.driverId">
      <mat-card-title>{{'operator_rating'|translate|ucFirst}}</mat-card-title>
      <div class="push-left" matLine>
        <span *ngFor="let score of [1, 2, 3, 4, 5];">
          <mat-icon *ngIf="score <= operatorRating.score">star</mat-icon>
          <mat-icon *ngIf="score > operatorRating.score">star_border</mat-icon>
        </span>
      </div>
      <mat-divider></mat-divider>
      <mat-card-actions>
        <button (click)="openRatingDialog()" mat-raised-button>{{'add_rating'| translate | ucFirst}}</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
