<div [formGroup]="form" *ngIf="this.cancelStatus=='false'" style="width:700px">
  <mat-card-title class="push-bottom">{{'cloud_connect_contract_edit_cancel_title'|translate|ucFirst}}</mat-card-title>
  <mat-card-subtitle
    class="push-bottom">{{'cloud_connect_contract_edit_cancel_subtitle'|translate|ucFirst}}</mat-card-subtitle>
  <mat-divider></mat-divider>


  <div class="textarea-wrapper push-bottom push-top">
    <mat-form-field class="full-width">
      <mat-label>{{'cancellation_reason'|translate|ucFirst}}</mat-label>
      <textarea class="inputTextArea" matInput formControlName="reason"></textarea>
    </mat-form-field>
  </div>

  <div class="full-width" flex="100">
    <button (click)="this.updateContractStatus(true)" class="push-top" color="primary" flex
            mat-raised-button>
      {{'cloud_connect_contract_cancel_at'|translate|ucFirst}}</button>
  </div>
</div>

<div *ngIf="this.cancelStatus!=='false'" style="width:700px">
  <mat-card-title class="push-bottom">{{'cloud_connect_contract_auto_renew_title'|translate|ucFirst}}</mat-card-title>
  <mat-card-subtitle
    class="push-bottom">{{'cloud_connect_contract_auto_renew_subtitle'|translate|ucFirst}}</mat-card-subtitle>
  <mat-divider></mat-divider>

  <button (click)="this.updateContractStatus(false)" class="push-top" color="primary" flex
          mat-raised-button>
    {{'cloud_connect_contract_auto_renew_submit'|translate|ucFirst}}</button>
</div>
