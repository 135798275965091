<!-- Required to make google places search work -->
<div #searchDisplayer></div>

<mat-card [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <ng-template tdLoading="location">

    <mat-card-title *ngIf=form class="text-truncate">
      {{ (form.value.name || 'New ' + type) | ucFirst }}
    </mat-card-title>
    <mat-card-subtitle>
      {{ subtitle() | translate | ucFirst }}
    </mat-card-subtitle>

    <mat-divider></mat-divider>

    <form *ngIf="form" [formGroup]="form">
      <mat-card-content class="push-bottom-none">

        <!-- Search -->
        <div *ngIf="!hasShape()" class="flex">
          <!-- Search -->
          <mat-form-field flex="80">
            <input #autocomplete [formControl]="autoComplete"
                   [matAutocomplete]="auto"
                   matInput
                   placeholder="{{ 'search' | translate | ucFirst }}"
                   type="text">
            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                (onSelectionChange)="import(res)"
                *ngFor="let res of results | async"
                [value]="res.description">
                  <span *ngIf="res.description">
                    {{ res.description }}
                  </span>
                <span *ngIf="res.isShared">
                    <mat-icon>{{ getIcon(res.descriptor) }}</mat-icon>
                  {{ res.name | ucFirst }}
                  </span>
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field flex="20" class="radius">
            <mat-select class="row-select"
                        flex="100"
                        formControlName="radius"
                        placeholder="{{ 'radius' | translate | ucFirst }}" required>
              <mat-option *ngFor="let type of kmOptions" [value]="type">
                {{ type | ucFirst }} {{ 'km'|distanceFormat:company:false | translate | ucFirst }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <!-- Descriptor -->
        <mat-form-field *ngIf="hasShape()" class="type-select">
          <mat-select class="row-select"
                      flex="33"
                      formControlName="descriptor"
                      placeholder="{{'type' | translate | ucFirst}}"
                      required>
            <mat-option
              *ngFor="let descriptor of descriptors()"
              [value]="descriptor">
              {{ descriptor | translate | ucFirst }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Name -->
        <mat-form-field *ngIf="hasShape()" class="push-right" flex>
          <input formControlName="name"
                 matInput
                 placeholder="{{ 'name' | translate | ucFirst }}"
                 type="text">
        </mat-form-field>


        <button (click)="clearLocation()" *ngIf="hasShape()"
                flex
                class="push-right-small push-bottom clear-map"
                mat-stroked-button>
          {{ 'clear_location'|translate|ucFirst }}
        </button>

        <!-- Address -->
        <mat-form-field *ngIf="hasShape() && isPoint()" flex
                        style="width: 100%">
          <input [readonly]="true"
                 formControlName="address"
                 matInput
                 placeholder="{{ 'address' | translate | ucFirst }}"
                 style="color: gray"
                 type="text">
        </mat-form-field>

        <mat-form-field *ngIf="this.apps.includes(environment.passengerDaAppId)">
          <mat-select class="row-select"
                      flex="100"
                      formControlName="locationPolicyId"
                      placeholder="{{'location_policy'|translate}}">
            <mat-option [value]="''">{{'none'|translate|ucFirst}}</mat-option>
            <mat-option
              *ngFor="let policy of locationPolicies"
              [value]="policy._id">
              {{ policy.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </mat-card-content>

      <!-- Map -->
      <mat-card-content class="map-container {{(!hasShape() ? 'hidden-visibility' : '')}}">
        <app-map
          (mapReady)="onMapReady($event)"
          (nrShapes)="onNrShapes($event)"
          [location]="form"
          [style.height]="'50vh'">
        </app-map>
      </mat-card-content>
    </form>

    <!-- Actions -->
    <mat-card-actions>
      <button (click)="importArea(currentPlace)"  *ngIf="!hasShape()" [disabled]="!currentPlace"
              color="accent"
              flex
              mat-raised-button>
        {{'import_area'|translate|ucFirst}}
      </button>
      <button (click)="save()" [disabled]="formDisabled()" *ngIf="hasShape()"
              color="accent"
              flex
              mat-raised-button>
        {{'save'|translate|ucFirst}}
      </button>
      <button (click)="delete()" *ngIf="!isNew()"
              class="float-right"
              color="warn"
              flex
              mat-raised-button>
        {{'delete'|translate|ucFirst}}
      </button>
    </mat-card-actions>
  </ng-template>
</mat-card>
