import {Component, Injector, isDevMode, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {CollectionComponent} from '../../../global/collection/collection.component';
import {UtilityService} from '../../../../services/utility.service';
import {RideService} from '../../../../services/ride.service';
import {ucFirst} from '../../../../pipes/uc-first.pipe';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TdDialogService} from '@covalent/core/dialogs';
import {UpdatePaymentMethodComponent} from './parts/update-payment-method/update-payment-method.component';
import {UpdateCardDetailsComponent} from './parts/update-card-details/update-card-details.component';
import {Price} from '../../../../models/price';
import {Company} from 'app/models/company';
import moment from 'moment-timezone';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {environment} from '../../../../../environments/environment';
import {ExporterService} from '../../../../services/exporter.service';

@Component({
  selector: 'app-payment-overview',
  templateUrl: './payment-overview.component.html',
  styleUrls: ['./payment-overview.component.scss'],
  providers: [],
})
export class PaymentOverviewComponent extends CollectionComponent implements OnInit {
  static translations: string[];
  actionName = 'openPayments';
  modelName = 'rides';
  columns: any[] = [
    {name: 'reference', label: 'reference', sortable: true, active: false, fixedSize: 'w-80', maxSize: null},
    {name: 'foreignDispatchId', label: 'foreignId', sortable: true, active: false, fixedSize: 'w-80', maxSize: null},
    {name: 'requestedDate', label: 'requested_date', sortable: true, active: true, maxSize: null},
    // { name: 'passengerCount', label: 'passengers',      sortable: true,   active: false , maxSize: 'maxSize950' },
    {name: 'departure', label: 'departure', sortable: true, active: false, fixedSize: 'w-200', maxSize: 'max-width-300'},
    {name: 'status', label: 'job_status', sortable: true, active: true, maxSize: null},
    {name: 'paymentMethod', label: 'payment_method', sortable: true, active: false},
    {name: 'balance', label: 'payment_amount', sortable: true, active: false},
    {name: 'paymentStatus', label: 'payment_status', sortable: true, active: false},
    {name: 'origin', label: 'origin', sortable: true, active: false},
    {name: 'actions', label: 'actions', sortable: true, active: false}
  ];
  columnsRefunded: any[] = [
    {name: 'reference', label: 'reference', sortable: true, active: false, fixedSize: 'w-80', maxSize: null},
    {name: 'foreignDispatchId', label: 'foreignId', sortable: true, active: false, fixedSize: 'w-80', maxSize: null},
    {name: 'requestedDate', label: 'requested_date', sortable: true, active: true, maxSize: null},
    // { name: 'passengerCount', label: 'passengers',      sortable: true,   active: false , maxSize: 'maxSize950' },
    {name: 'departure', label: 'departure', sortable: true, active: false, fixedSize: 'w-200', maxSize: 'max-width-300'},
    {name: 'status', label: 'job_status', sortable: true, active: true, maxSize: null},
    {name: 'paymentMethod', label: 'payment_method', sortable: true, active: false},
    {name: 'balance', label: 'payment_amount', sortable: true, active: false},
    {name: 'origin', label: 'origin', sortable: true, active: false},
    {name: 'actions', label: 'actions', sortable: true, active: false}
  ];
  columnsSettled: any[] = [
    {name: 'reference', label: 'reference', sortable: true, active: false, fixedSize: 'w-80', maxSize: null},
    {name: 'foreignDispatchId', label: 'foreignId', sortable: true, active: false, fixedSize: 'w-80', maxSize: null},
    {name: 'requestedDate', label: 'requested_date', sortable: true, active: true, maxSize: null},
    // { name: 'passengerCount', label: 'passengers',      sortable: true,   active: false , maxSize: 'maxSize950' },
    {name: 'departure', label: 'departure', sortable: true, active: false, fixedSize: 'w-200', maxSize: 'max-width-300'},
    {name: 'status', label: 'job_status', sortable: true, active: true, maxSize: null},
    {name: 'paymentMethod', label: 'payment_method', sortable: true, active: false},
    {name: 'balance', label: 'payment_amount', sortable: true, active: false},
    {name: 'origin', label: 'origin', sortable: true, active: false},
    {name: 'actions', label: 'actions', sortable: true, active: false}
  ];
  type = 'all';

  // tableSortBy = 'timestap';
  // tableSortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;
  activeTab = 'openPayments';
  paging = {
    'openPayments': {
      fromRow: 1,
      currentPage: 1,
      pageSize: 25
    },
    'refunded': {
      fromRow: 1,
      currentPage: 1,
      pageSize: 25
    },
    'closed': {
      fromRow: 1,
      currentPage: 1,
      pageSize: 25
    },
    'all': {
      fromRow: 1,
      currentPage: 1,
      pageSize: 25
    }
  };
  originalData = {
    all: [],
    openPayments: [],
    refunded: [],
    closed: [],
  }
  filteredData = {
    all: [],
    openPayments: [],
    refunded: [],
    closed: [],
    grouped: {},
  }
  selectFilter = {
    where: {
      'paymentMeta.origin': {'in': ['creditcardOnline', 'online']},
      'status': {'in': ['completed', 'canceled']},
      'ownerId': '',
      'ownerType': 'Company',
    },
    include: [
      'Debtor', 'Passenger', 'PaymentBalance', 'Payments'
    ],
    fields: ['id', 'rideId',  'foreignDispatchId', 'reference', 'price', 'debtorId', 'departure', 'passenger', 'passengerCount', 'status', 'paymentMeta', 'requestedDate', 'origin', 'foreignMeta', 'paymentProcessStatus'],
    limit: 5000,
    order: 'requestedDate ASC',
  };
  waitingForCapture = [];
  totalOpen: Price = {
    total: 0,
    currency: ''
  };
  company: Company;

  defaultFilterStatus = 'all';
  todoFilterStatus = ['unassigned', 'assigned', 'driving_to_pickup', 'arrived_at_pickup', 'started', 'arrived_at_destination'];
  defaultFilterFromDate: Date;
  filterRideStatus = `${this.defaultFilterStatus}`;
  rideStatuses: string[] = ['todo', 'canceled', 'completed'];

  static showPaymentStatus(translations, company, payment: any, ride: any): any {
    let total = 0;
    const item = {
      paymentStatus: '',
      paymentStatusColor: '',
      error: '',
    };

    if (ride.PaymentBalance) {
      ride.PaymentBalance.forEach((p) => {
        if (p.status !== 'PENDING' && p.amount) {
          total = total + p.amount.total;
        }
      });
      ride.balance = {
        total: total,
        currency: ride.price.currency,
      };
    }

    if (payment) {
      item.paymentStatus = `payment_status_${payment.status.toLowerCase()}`;
      item.error = payment.error;
    }

    if (isDevMode() && payment) {
      console.groupCollapsed(`ID: ${ride.reference}`);
      console.log(ride.PaymentBalance);
      console.log(ride.status);
      console.log(ride.balance);
      console.log(ride.Payments);
      console.log(payment);
      console.log(total);
    }

    if (payment && total === 0) {
      if (payment.status === 'SUCCESS' || payment.status === 'CAPTURED') {
        item.paymentStatusColor = `green`;
      } else if (payment.status === 'AUTHORIZED') {
        item.paymentStatusColor = `green`;
      } else if (['completed', 'canceled'].includes(ride.status)) {
        item.paymentStatus = `action_required`;
        item.paymentStatusColor = `red`;
      } else {
        item.paymentStatus = `payment_status_none`;
        item.paymentStatusColor = `black`;
      }
    } else if (payment && total !== 0 && ['completed', 'canceled'].includes(ride.status) && payment.status !== 'PENDING') {
      if (total > 0) {
        item.paymentStatus = `open_refundable_amount`;
      } else {
        item.paymentStatus = `action_required`;
      }
      item.paymentStatusColor = `red`;
    } else if (payment && payment.status === 'PENDING' && total !== 0) {
      item.paymentStatusColor = `blue`;
    } else if (payment && payment.status === 'REQUIRES_ACTION' && total !== 0) {
      item.paymentStatusColor = `blue`;
    } else if (payment && payment.status === 'FAILED' && total !== 0) {
      item.paymentStatusColor = `red`;
    } else if (payment && payment.status === 'AUTHORIZED' && total !== 0) {
      item.paymentStatusColor = `green`;
    } else if (total === 0) {
      item.paymentStatus = `no_payment_required`;
      item.paymentStatusColor = `black`;
    } else if (ride.paymentMeta.paymentMoment === 'pickuptime') {
      item.paymentStatus = translations[`payment_status_pickup_at`].replace('{time}', moment(ride.paymentMeta.plannedPaymentMoment).format((company && company.dateFormat ? `${company.clockFormat} ${company.dateFormat}` : 'HH:mm DD-MM-YYYY')));
      item.paymentStatusColor = `blue`;
    } else if (ride.paymentMeta && moment(ride.paymentMeta.plannedPaymentMoment).isBefore(moment())) {
      item.paymentStatus = `payment_status_awaiting_capture`;
      item.paymentStatusColor = `blue`;
    } else {
      item.paymentStatus = `payment_status_none`;
      item.paymentStatusColor = `black`;
    }

    if (isDevMode()) {
      if (item.error) {
        console.log(item.error);
      }
      // console.log(item.paymentStatusColor);
      // console.log(ride.paymentMeta);
      console.groupEnd();
    }
    return item;
  }

  constructor(
    protected injector: Injector,
    private _translateService: TranslateService,
    private _titleService: Title,
    private _rideService: RideService,
    private _dialogService: TdDialogService,
    private _snackBar: MatSnackBar,
    private _exporter: ExporterService,
    private _activatedRoute: ActivatedRoute,
  ) {
    super(injector);
    this.modelService = _rideService;
    this.selectFilter.where.ownerId = this.companyId;
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);

    const translationArray = ['ok', 'payment_authorized_message', 'payment_processed_message', 'payment_authorized_confirm_title', 'payment_authorized_confirm_message', 'payment_capture_confirm_title', 'payment_capture_confirm_message', 'payment_refund_confirm_title', 'payment_refund_confirm_message', 'payment_refunded_message', 'payment_status_pickup_at', 'payment_close_confirm_message', 'open_payments', 'refunded', 'settled', 'payment_close_confirm_message_title', 'payed_externally_no_refund_granted', 'open_amount_no_refund', 'payed_externally_no_refund_granted', 'action_required', 'open_refundable_amount', 'no_payment_information', 'paypal', 'stripe'];

    this.columns.forEach((c) => {
      translationArray.push(c.name)
    });
    this.columnsSettled.forEach((c) => {
      translationArray.push(c.name)
    });
    this.columnsSettled.forEach((c) => {
      translationArray.push(c.name)
    });

    _translateService.get(translationArray).subscribe((translations: any) => {
      this.translations = translations;
    });

    const {company} = this._route.parent.snapshot.data;
    this.company = company;

    const defaultFromDate = new Date();
    const storedDate = this._vault.getItem(`${environment.vaultPrefix}.paymentFromFilter`);
    if (storedDate) {
      this.defaultFilterFromDate = moment(storedDate).toDate();
      this.filterDates.from = moment(storedDate).toDate();
    } else {
      defaultFromDate.setMonth(defaultFromDate.getMonth() - 1);
      this.filterDates.from = defaultFromDate;
      this.defaultFilterFromDate = defaultFromDate;
    }

    this.addDateToFilterQuery();
  }

  setLink() {

  }

  onTabChange(event: MatTabChangeEvent): void {
    // this.holdLoading = true;
    this.tabIndex = event.index;

    const defaultFromDate = new Date();
    defaultFromDate.setMonth(defaultFromDate.getMonth() - 1);
    if (!this.filterDates.from) {
      this.filterDates.from = defaultFromDate;
      this.defaultFilterFromDate = defaultFromDate;
    } else {
      this.defaultFilterFromDate = this.filterDates.from;
    }
    if (event.index === 0) {
      this.activeTab = 'openPayments';
      // this.startLoader();
      // this.filterDates.to = null;
      // this.addDateToFilterQuery();
      // this.loadData();
      // this.stopLoader();
    }
    if (event.index === 2) {
      this.activeTab = 'closed';
      // this.startLoader();
      // this.addDateToFilterQuery();
      // this.loadData();
      // this.stopLoader();
    }
    if (event.index === 1) {
      this.activeTab = 'refunded';
      // this.startLoader();
      // this.addDateToFilterQuery();
      // this.loadData();
      // this.stopLoader();
    }
  }

  startLoader = () => {
    this._loadingService.register(this.actionName);
    this._loadingService.register(`${this.actionName}_2`);
  }

  /**
   * Stop the spinning loader.
   */
  stopLoader = () => {
    this._loadingService.resolve(this.actionName);
    this._loadingService.resolve(`${this.actionName}_2`);
  }

  ngOnDestroy = () => {
    this._navigationService.setActionLink('');
    this._navigationService.setInfoAction('');
  };

  groupData = (data) => {
    const returnData = {};
    returnData['all'] = data;
    this.totalOpen.total = 0;
    console.log('##############RELOAD#################');

    returnData['all'].forEach((i) => {
      if (i.Passenger) {
        i.passenger = `${i.Passenger.firstName} ${i.Passenger.lastName}`;
      }

      i.departure = UtilityService.getLocationString(i.departure);
      if (i.paymentMeta) {
        i.paymentMethod = i.paymentMeta.origin;
      }

      i.timestamp = new Date(i.requestedDate).getTime();

      if (i.status) {
        if (i.status === 'canceled') {
          i.rideStatusColor = `red`;
        }
      }

      if (i.foreignMeta && i.foreignMeta.payPallID) {
        i.paymentDescription = this.translations['paypal'];
      } else if (i.foreignMeta && i.foreignMeta.stripeId) {
        i.paymentDescription = this.translations['stripe'];
      }

      i.hasRefundedPayment = (i.Payments && i.Payments.filter((p) => {
        return (p.status === 'REFUNDED')
      }).length > 0);
      i.hasCanceledBalanceItem = (i.PaymentBalance.filter((p) => {
        return (p.code === 'JOB_CANCELED')
      }).length > 0);
      i.hasRefundedBalanceItem = (i.PaymentBalance.filter((p) => {
        return (p.code === 'REFUND_PROCESSED')
      }).length > 0);
      i.hasCapturedPayment = (i.Payments && i.Payments.filter((p) => {
        return (p.status === 'CAPTURED')
      }).length > 0);

      i.origin = (i.foreignMeta && i.foreignMeta.origin ? i.foreignMeta.origin : i.origin.name);

      if (i.PaymentBalance) {
        let total = 0;
        i.PaymentBalance.forEach((p) => {
          if (p.status !== 'PENDING' && p.amount) {
            total = total + p.amount.total;
          }
        });
        i.balance = {
          total: total,
          currency: i.price.currency,
        };
      }

      if (isDevMode()) {
        console.groupCollapsed(`ProcessJob ID: ${i.reference}`);
        console.log(i);
      }

      let total = 0;
      if (this.waitingForCapture.includes(i.id)) {
        if (isDevMode()) {
          console.group(`waitingForCapture: ${i.reference}`);
        }
        i.paymentStatusColor = `blue`;
        if (i.PaymentBalance) {
          i.PaymentBalance.forEach((p) => {
            if (p.status !== 'PENDING' && p.amount) {
              total = total + p.amount.total;
            }
          });
          i.balance = {
            total: total,
            currency: i.price.currency,
          };
          if (total > 0) {
            i.paymentStatus = `payment_status_awaiting_refund`;
          } else {
            i.paymentStatus = `payment_status_awaiting_capture`;
          }
        }
      } else if (i.paymentMeta.origin === 'online' && i.Payments.length === 0) {
        // i.paymentStatus =  `payment_status_completed`;
        i.paymentStatusColor = `blue`;
        if (i.PaymentBalance) {
          i.PaymentBalance.forEach((p) => {
            if (p.status !== 'PENDING' && p.amount) {
              total = total + p.amount.total;
            }
          });
          i.balance = {
            total: total,
            currency: i.price.currency,
          };
          // if (total > 0) {
          //   i.paymentStatus = `payment_status_awaiting_refund`;
          // } else {
          //   i.paymentStatus = `payment_status_awaiting_capture`;
          // }
        }
      } else {
        const details = (PaymentOverviewComponent.showPaymentStatus(this.translations, this.company, ((i.Payments && i.Payments.length > 0) ? i.Payments[i.Payments.length - 1] : null), i));
        i.paymentStatus = details.paymentStatus;
        i.error = details.error;
        i.paymentStatusColor = details.paymentStatusColor;
      }

      if (isDevMode()) {
        console.log('status:', i.status);
        console.log('PaymentBalance:', (i.balance ? i.balance.total : 0));
        console.log('paymentProcessStatus:', i.paymentProcessStatus);
        console.log('PaymentsFound:', i.Payments.length);
        console.log('hasCapturedPayment:', i.hasCapturedPayment);
        console.log('hasRefundedPayment:', i.hasRefundedPayment);
        console.log('hasCanceledBalanceItem:', i.hasCanceledBalanceItem);
        console.log('hasRefundedBalanceItem:', i.hasRefundedBalanceItem);
        console.groupEnd();
      }
    });

    returnData['closed'] = JSON.parse(JSON.stringify(returnData['all'])).filter((i) => {
      let isClosed = false;
      if (!i.paymentProcessStatus && i.balance && i.balance.total !== 0 && i.hasCanceledBalanceItem && i.hasRefundedPayment) {
        isClosed = true;
      }
      if ((i.balance && i.balance.total === 0 && i.Payments.length > 0)) {
        isClosed = true;
      }
      return isClosed;
    });
    returnData['closed'].forEach((i) => {
      i.type = 'closed';
      if (i.balance.total === 0 && i.paymentStatus !== `payment_status_captured`) {
        i.paymentStatus = `payment_status_settled`;
        i.paymentStatusColor = `black`;
        i.error = ``;
      }
      i.Payments = i.Payments.filter((p) => {
        return (p.status !== 'REQUIRES_ACTION');
      });

      if (i.Payments[i.Payments.length - 1]) {
        i.balance = (i.Payments[i.Payments.length - 1].status !== 'REFUNDED' ? i.Payments[i.Payments.length - 1].amount : 0);
        i.paymentDate = i.Payments[i.Payments.length - 1].created;
      }
      if (i.balance && i.balance.total < 0) {
        i.balance.total = 0 - i.balance.total;
      }
    });

    returnData['refunded'] = JSON.parse(JSON.stringify(returnData['all'])).filter((item) => {
      let refunded = false;
      if ((item.PaymentBalance.length > 0 || item.balance.total === 0) && item.hasCanceledBalanceItem && item.hasRefundedBalanceItem) {
        refunded = true;
      }
      return refunded;
    });

    returnData['refunded'].forEach((i) => {
      i.type = 'refunded';
    });

    returnData['openPayments'] = JSON.parse(JSON.stringify(returnData['all'])).filter((i) => {
      let isOpen = false;
      if (i.status === 'canceled') {
        if (!i.paymentProcessStatus && i.Payments && i.Payments.length > 0 && !i.hasRefundedPayment && !i.hasRefundedBalanceItem) {
          isOpen = true
        }

        if (!i.paymentProcessStatus && i.balance && i.balance.total !== 0 && i.hasCanceledBalanceItem && !i.hasRefundedPayment) {
          isOpen = true
        }

        if (!i.paymentProcessStatus && i.balance && i.balance.total === 0 && !i.hasRefundedBalanceItem && !i.hasRefundedPayment) {
          isOpen = true
        }
      } else if ((i.balance && i.balance.total !== 0) && !i.paymentProcessStatus) {
        isOpen = true;
      }

      return isOpen;
    });

    returnData['openPayments'].forEach((i) => {
      i.type = 'openPayments';

      if (i.paymentStatus === `payment_status_awaiting_capture`) {
        return;
      } else if (i.Payments && !i.hasCanceledBalanceItem && i.hasCapturedPayment) {
        i.paymentStatus = `open_amount_no_refund`;
        i.paymentStatusColor = `black`;
      } else if (i.Payments.length === 0 && i.status === 'canceled' && !i.hasRefundedBalanceItem && !i.hasCanceledBalanceItem) {
        i.paymentStatus = `payed_externally_no_refund_granted`;
        i.paymentStatusColor = `black`;
      } else if (i.Payments.length === 0) {
        i.paymentStatus = `no_payment_information`;
        i.paymentStatusColor = `black`;
      }
      this.totalOpen.total = this.totalOpen.total + i.balance.total;
      this.totalOpen.currency = i.balance.currency;

      if (i.Payments[i.Payments.length - 1]) {
        i.paymentDate = i.Payments[i.Payments.length - 1].created;
      }
    });
    return returnData;
  }

  rideFilterChanged(data: any): void {
    // console.log(`[RidesComponent.rideFilterChanged]: data`, data);
    this.searchTerm = data.filterSearchTerm;
    if (data.filterStatus !== '') {
      this.filterRideStatus = data.filterStatus;
      if (this.filterRideStatus === 'todo') {
        this.filteredData[this.activeTab] = this.filteredData[this.activeTab].filter((ride) => {
          return (this.todoFilterStatus.includes(ride.status));
        });
      } else if (this.filterRideStatus !== 'all') {
        this.filteredData[this.activeTab] = this.filteredData[this.activeTab].filter(ride => ride.status === this.filterRideStatus);
      }
    }

    if (data.filterDates.from !== '') {
      this.onFromFilterChanged(data.filterDates.from);
    }

    if (data.filterDates.to !== '') {
      this.onToFilterChanged(data.filterDates.to);
    }

    this.filterAllTabs();
  }

  open(id, $event): void {
    if ($event.ctrlKey) {
      window.open(`/groups/${this.companyId}/jobs/${id}/details`);
    } else {
      this._router.navigate([`/groups/${this.companyId}/jobs/${id}/details`]);
    }
  }

  export() {
    const ogData = JSON.parse(JSON.stringify(this.originalData));
    const data = [
      {
        title: 'open_payments',
        headers: this.columns.map((c) => {
          return {
            name: c.name,
            label: c.name
          }
        }),
        rows: ogData[this.activeTab].map((record) => {
          return this.exportFormat(record)
        })
      },
      {
        title: `refunded`,
        headers: this.columnsRefunded.map((c) => {
          return {
            name: c.name,
            label: c.name
          };
        }),
        rows: ogData[this.activeTab].map((record) => {
          return this.exportFormat(record)
        })
      },
      {
        title: `settled`,
        headers: this.columnsSettled.map((c) => {
          return {
            name: c.name,
            label: c.name
          };
        }),
        rows: ogData[this.activeTab].map((record) => {
          return this.exportFormat(record)
        })
      }
    ];
    this._exporter.generate(`payments_${this.company.name.toLowerCase().replace(/\ /g, '_')}`, this.translations, data);
  }

  exportFormat(record: any) {
    if (record.balance && record.balance.total) {
      record.balance = `${record.balance.currency} ${(record.balance.total / 100)}`;
    } else if (record.balance && record.balance.currency) {
      record.balance = `${record.balance.currency} 0`;
    }

    if (record.paymentStatus && this.translations[record.paymentStatus]) {
      record.paymentStatus = this.translations[record.paymentStatus];
    }
    return record;
  }

  authorizePayment(id): void {
    const self = this;
    self._dialogService.openConfirm({
      'message': self.translations['payment_authorized_confirm_message'],
      'disableClose': false,
      'maxWidth': (window.innerWidth < 600 ? '100%' : '500px'),
      'width': (window.innerWidth < 600 ? '100%' : '500px'),
      'title': self.translations['payment_authorized_confirm_title'],
      'acceptButton': self.translations['ok'],
    }).afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          self._loadingService.register();
          this._rideService.authorizePayment(id).subscribe((result) => {
            self._snackBar.open(ucFirst(self.translations['payment_authorized_message']), self.translations['ok'], {
              duration: 3000
            });
            self.loadData(this.selectFilter);
            self._loadingService.resolve();
          });
        }
      });
  }

  processPayment(id): void {
    const self = this;
    self._dialogService.openConfirm({
      'message': self.translations['payment_capture_confirm_message'],
      'disableClose': false,
      'maxWidth': (window.innerWidth < 600 ? '100%' : '500px'),
      'width': (window.innerWidth < 600 ? '100%' : '500px'),
      'title': self.translations['payment_capture_confirm_title'],
      'acceptButton': self.translations['ok'],
    }).afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          self._loadingService.register();
          this._rideService.processPayment(id).subscribe((result) => {
            self._snackBar.open(ucFirst(self.translations['payment_processed_message']), self.translations['ok'], {
              duration: 3000
            });
            self.waitingForCapture.push(id);
            self.loadData(this.selectFilter);

            setTimeout(function () {
              self.waitingForCapture = self.waitingForCapture.filter((i) => {
                return (i !== id)
              });
              self.loadData(self.selectFilter);
            }, 10000);

            self._loadingService.resolve();
          });
        }
      });
  }

  processRefund(id): void {
    const self = this;
    self._dialogService.openConfirm({
      'message': self.translations['payment_refund_confirm_message'],
      'disableClose': false,
      'maxWidth': (window.innerWidth < 600 ? '100%' : '500px'),
      'width': (window.innerWidth < 600 ? '100%' : '500px'),
      'title': self.translations['payment_refund_confirm_title'],
      'acceptButton': self.translations['ok'],
    }).afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          self._loadingService.register();
          this._rideService.processPayment(id).subscribe((result) => {
            self._snackBar.open(ucFirst(self.translations['payment_refunded_message']), self.translations['ok'], {
              duration: 3000
            });
            console.log(id);
            self.waitingForCapture.push(id);
            self.loadData(this.selectFilter);

            setTimeout(function () {
              self.loadData(self.selectFilter);
            }, 10000);

            self._loadingService.resolve();
          }, (error) => {
            console.log(error);
            self._loadingService.resolve();
          });
        }
      });
  }

  closePaymentStatus(id): void {
    const self = this;
    self._dialogService.openConfirm({
      'message': self.translations['payment_close_confirm_message'],
      'disableClose': false,
      'maxWidth': (window.innerWidth < 600 ? '100%' : '500px'),
      'width': (window.innerWidth < 600 ? '100%' : '500px'),
      'title': self.translations['payment_close_confirm_message_title'],
      'acceptButton': self.translations['ok'],
    }).afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          self._rideService.closePayment(id).subscribe((result) => {
            self.waitingForCapture.push(id);
            self.loadData(self.selectFilter);
            self._snackBar.open(ucFirst(self._translateService.instant('payment_closed_message')), self.translations['ok'], {
              duration: 3000,
              panelClass: ['snack-success']
            });

            setTimeout(function () {
              self.waitingForCapture = self.waitingForCapture.filter((i) => {
                return (i !== id)
              });
              self.loadData(self.selectFilter);
            }, 10000);

          }, (error) => {
            console.log(error);
            self._loadingService.resolve();
          });
        }
      });
  }

  updateDetails(id): void {
    const self = this;
    self._dialogService.open(UpdatePaymentMethodComponent, {
      'maxHeight': '80%',
      'maxWidth': (window.innerWidth < 600 ? '100%' : '500px'),
      'width': (window.innerWidth < 600 ? '100%' : '500px'),
      'minWidth': '500px',
      'data': {
        rideId: id,
        openFromDialog: true,
        parent: this
      },
    });
  }

  updateCardDetails(id): void {
    const self = this;
    self._dialogService.open(UpdateCardDetailsComponent, {
      maxHeight: '80%',
      maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
      minWidth: '500px',
      data: {
        rideId: id,
        openFromDialog: true,
        parent: this
      },
    });
  }
}
