import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-ob-calendar-select',
  templateUrl: './ob-calendar-select.component.html',
  styleUrls: ['./ob-calendar-select.component.scss']
})
export class ObCalendarSelectComponent implements OnInit {
  calendars: any[];
  selected = '';
  forceSelect = false;

  constructor(
    public dialogRef: MatDialogRef<ObCalendarSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.calendars = data.calendars;
    this.forceSelect = data.forceSelect;

    this.calendars = this.calendars.sort(function (a, b) {
      if (a && b) {
        return (a['summary'] > b['summary']) ? 1 : (a['summary'] < b['summary']) ? -1 : 0;
      }
    });
    const foundSelected = this.calendars.filter((c) => {
      return (c.id === this.data.calendar.primary)
    })[0];

    if (foundSelected) {
      this.selected = foundSelected.id;
    } else if (!this.selected) {
      this.selected = 'new';
    }
  }

  ngOnInit(): void {

  }

  save(): void {
    this.data.parent.selectCalendar(this.selected);
    this.dialogRef.close();
  }
}
