<div class="push-top push-bottom">
  <mat-card-subtitle>
    {{ 'pricing_select_daappinstalls' | translate | ucFirst}}
  </mat-card-subtitle>

  <div [formGroup]="form">
    <div class="paymentMethod-pane" flex="100" layout="row">
      <div *ngFor="let install of daAppInstalls; let i=index" class="daAppInstallBox" layout="column">
        <mat-checkbox (change)="onCheckboxChangeMeter($event)" *ngIf="false"
                      [checked]="(checkArrayMeter.includes(install.id))"
                      [value]="install.id"
                      type="checkbox">
          {{'onmeter_app_select' | translate | ucFirst}}
        </mat-checkbox>

        <mat-checkbox (change)="onCheckboxChangeFixed($event)"
                      [checked]="(checkArrayFixed.includes(install.id))"
                      [value]="install.id"
                      type="checkbox">
          <span class="wrap-line">{{getDaAppName(install.DaApp)}}
            <span *ngIf="install.settings">- {{install.settings.name|translate|ucFirst}}</span>
          </span>
        </mat-checkbox>
      </div>
    </div>
  </div>
</div>
