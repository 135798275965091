<mat-card flex tdMediaToggle="gt-xs">
  <mat-card-title>{{'job_process_cancel_title' | translate | ucFirst}}</mat-card-title>
  <mat-card-subtitle>{{'job_process_cancel_subtitle' | translate | ucFirst}}</mat-card-subtitle>

  <mat-divider></mat-divider>

  <mat-card-content class="push-bottom-none" flex>
    <form *ngIf="form" [formGroup]="form">
      <h3 class="push-top-none">
        {{'driver_cancellations'|translate|ucFirst}}
      </h3>

      <div layout="row">
        <strong class="push-top-none">
          {{'passenger_no_show_flow'|translate|ucFirst}}
        </strong>
      </div>
      <div layout="row">
        <div flex="50" layout="column">
          <mat-form-field class="pull-bottom"
                          flex="100">
            <mat-label>{{'when_a_driver_cancels'|translate|ucFirst}}</mat-label>
            <mat-select class="row-select" flex="100"
                        formControlName="driverNoShowFlow" placeholder="{{'select'|translate|ucFirst}}">
              <mat-option *ngFor="let option of driverCancellationOptions"
                          [value]="option.value">{{option.name | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div layout="row">
        <div flex="50" layout="column">
          <mat-form-field *ngIf="this.form.controls['driverNoShowFlow'].value === 'autoapprove_delay'" flex="100">
            <p [innerHtml]="'cancellation_delay_minutes'|translate|ucFirst" class="field-description"></p>
            <input
              formControlName="driverNoShowDelay"
              id="driverNoShowDelay"
              matInput
              name="driverNoShowDelay"
              type="number">
          </mat-form-field>
        </div>
      </div>

      <div class="push-top" layout="row">
        <strong class="push-top-none">
          {{'driver_sends_canceled_by_passenger_flow'|translate|ucFirst}}
        </strong>
      </div>
      <div layout="row">
        <div flex="50" layout="column">
          <mat-form-field class="pull-bottom" flex="100">
            <mat-label>{{'when_a_driver_cancels'|translate|ucFirst}}</mat-label>
            <mat-select class="row-select" flex="100"
                        formControlName="driverCancellationFlow" placeholder="{{'select'|translate|ucFirst}}">
              <mat-option *ngFor="let option of driverCancellationOptions"
                          [value]="option.value">{{option.name | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div layout="row">
        <div flex="50" layout="column">
          <mat-form-field *ngIf="this.form.controls['driverCancellationFlow'].value === 'autoapprove_delay'" flex="100">
            <p [innerHtml]="'cancellation_delay_minutes'|translate|ucFirst" class="field-description"></p>
            <input
              formControlName="driverCancellationDelay"
              id="driverCancellationDelay"
              matInput
              name="driverCancellationDelay"
              type="number">
          </mat-form-field>
        </div>
      </div>

      <div class="push-top" layout="row">
        <strong class="push-top-none">
          {{'request_unassign'|translate|ucFirst}}:
        </strong>
      </div>
      <div layout="row">
        <div flex="50" layout="column">
          <mat-form-field flex="100">
            <mat-label>{{'when_a_driver_cancels'|translate|ucFirst}}</mat-label>
            <mat-select class="row-select" flex="100"
                        formControlName="driverUnassignFlow" placeholder="{{'select'|translate|ucFirst}}">
              <mat-option *ngFor="let option of driverCancellationOptions"
                          [value]="option.value">{{option.name | translate}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div layout="row">
        <div flex="50" layout="column">
          <mat-form-field *ngIf="this.form.controls['driverUnassignFlow'].value === 'autoapprove_delay'" flex="100">
            <p [innerHtml]="'cancellation_delay_minutes'|translate|ucFirst" class="field-description"></p>
            <input
              formControlName="driverUnassignDelay"
              id="driverUnassignDelay"
              matInput
              name="driverUnassignDelay"
              type="number">
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-card-content>

  <mat-divider></mat-divider>

  <mat-card-actions>
    <button (click)="saveCancellationSettings()"
            color="accent"
            flex
            mat-raised-button>{{'save'|translate|ucFirst}}</button>
  </mat-card-actions>
</mat-card>
