import {AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {FieldConfig} from '../../../../../../../global/dynamic-form/models/field-config.interface';
import {DynamicFormComponent} from '../../../../../../../global/dynamic-form/containers/dynamic-form.component';
import {DaAppInstallService} from '../../../../../../../../services/da-app-install.service';
import {DaAppInstall} from '../../../../../../../../models/da-app-install';
import {commissionRuleValidator} from 'app/components/global/dynamic-form/components/commission-input/commission-input.component';

@Component({
  selector: 'app-commission-tab',
  templateUrl: './commission-tab.component.html',
  styleUrls: ['./commission-tab.component.scss']
})
export class CommissionTabComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() daAppInstall: DaAppInstall;
  @ViewChild(DynamicFormComponent) form: DynamicFormComponent;

  config: FieldConfig[] = [];

  constructor(private _daAppInstallService: DaAppInstallService) {
  }

  ngOnInit() {

  }

  ngOnChanges() {
    this.config = [
      {
        type: 'commission',
        label: 'Rules',
        name: 'rules',
        value: (this.daAppInstall && this.daAppInstall.settings ? this.daAppInstall.settings.commissionRules : []),
        options: [],
        placeholder: '',
        cssClass: 'full-width',
        validation: [commissionRuleValidator()]
      },
      {
        label: 'Save rules',
        name: 'submit',
        options: ['primary'],
        type: 'materialButton'
      }
    ];
  }

  ngAfterViewInit() {
    let previousValid = this.form.valid;
    this.form.setDisabled('submit', !previousValid);
    this.form.changes.subscribe(() => {
      if (this.form.valid !== previousValid) {
        previousValid = this.form.valid;
        this.form.setDisabled('submit', !previousValid);
      }
    });
  }

  onSubmit(values: any): void {
    const rules = values.rules;
    // console.log(`[CommissionTabComponent.onSubmit]: rules`, rules);
    const settings = {...this.daAppInstall.settings, commissionRules: rules};

    this._daAppInstallService.update(this.daAppInstall.id, {settings}, 'company').subscribe((daAppInstall) => {
      window.location.href = `${window.location.href.split('?')[0]}?saved=true`
    }, (error) => {
      console.log(`[CommissionTabComponent.onSubmit]: error`, error);
    })
  }

}
