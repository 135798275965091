import {Component, Inject, OnInit} from '@angular/core';
import {Rating} from '../../../../../../../models/rating';
import {Driver} from '../../../../../../../models/driver';
import {environment} from '../../../../../../../../environments/environment';
import {TdLoadingService} from '@covalent/core/loading';
import {RatingService} from '../../../../../../../services/rating.service';
import {CoolLocalStorage} from '@angular-cool/storage';
import {DriverService} from '../../../../../../../services/driver.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Ride} from '../../../../../../../models/ride';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {PricingRuleDialogComponent} from '../../../../../settings/apps/parts/apps-upsert/parts/pricing-rule-dialog/pricing-rule-dialog.component';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
  providers: [RatingService],
})
export class RatingComponent implements OnInit {
  hasPassengerRating = false;
  hasOperatorRating = false;

  passengerRating: Rating = new Rating();
  operatorRating: Rating = new Rating();
  translations: string[] = [];
  ride: Ride = new Ride();

  constructor(
    public snackBar: MatSnackBar,
    private loadingService: TdLoadingService,
    private _ratingService: RatingService,
    private vault: CoolLocalStorage,
    private driverService: DriverService,
    public dialogRef: MatDialogRef<PricingRuleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.parent && data.parent.translations) {
      this.translations = data.parent.translations;
    }
    if (data.parent && data.parent.ride) {
      this.ride = data.parent.ride;

      this.ride.Ratings.forEach(r => {
        if (r.type === 'operator') {
          this.operatorRating = r;
          this.hasOperatorRating = true;
        }
      });
    }
  }

  ngOnInit(): void {
  }

  saveOperatorRating(): void {
    this.loadingService.register('ride.operator.rating');
    const self = this;

    if (this.hasOperatorRating) {
      const id = this.operatorRating.id;
      delete this.operatorRating.created;
      delete this.operatorRating.id;
      delete this.operatorRating['modified'];
      this._ratingService.update(id, this.operatorRating, 'company').subscribe((operatorRating: Rating) => {
        this.operatorRating = operatorRating;
        setTimeout(() => {
          const driver: Driver = this.vault.getObject(`${environment.vaultPrefix}.driver`);
          if (self.ride.Driver) {
            const message = self.translations['operator_rating_saved'].formatUnicorn(`${driver.fname} ${driver.lname}`, `${self.ride.Driver.fname} ${self.ride.Driver.lname}`);
            self.loadingService.resolve('ride.operator.rating');
            self.snackBar.open(message, 'OK', {
              duration: 3000,
            });
          }
          self.data.reloadDriverData();
          self.dialogRef.close();
        }, 500);
      }, () => {
        setTimeout(() => {
          self.loadingService.resolve('ride.operator.rating');
        }, 500);
      });
    } else {
      const driver: Driver = this.vault.getObject(`${environment.vaultPrefix}.driver`);
      this.driverService.getAll({
        where: {
          phoneNumber: encodeURIComponent(driver.phoneNumber),
          companyId: this.ride.companyId
        }
      }).subscribe((drivers: Driver[]) => {
        if (drivers[0]) {
          this.operatorRating.type = 'operator';
          this.operatorRating.rideId = this.ride.id;
          this.operatorRating.driverId = this.ride.driverId;
          this.operatorRating.ownerId = this.ride.companyId;
          this.operatorRating.ownerType = 'Company';
          this.operatorRating.creatorId = drivers[0].id;
          this.operatorRating.creatorType = 'Driver';

          this._ratingService.create(this.ride.id, this.operatorRating, 'company').subscribe((operatorRating: Rating) => {
            self.operatorRating = operatorRating;
            setTimeout(() => {
              if (self.ride.Driver) {
                const message = self.translations['operator_rating_saved'].formatUnicorn(`${driver.fname} ${driver.lname}`, `${self.ride.Driver.fname} ${self.ride.Driver.lname}`);
                self.loadingService.resolve('ride.operator.rating');
                self.snackBar.open(message, 'OK', {
                  duration: 3000,
                });
              }
              self.data.reloadDriverData();
              self.dialogRef.close();
            }, 500);
          }, error => {
            console.error(error);
            setTimeout(() => {
              self.loadingService.resolve('ride.operator.rating');
            }, 500);
          });
        } else {
          alert('Group driver could not be found!');
        }
      });
    }
  }

}
