<mat-card tdMediaToggle="gt-xs">
  <mat-card-title *ngIf="this.context === 'driver'">{{'my_shifts'|translate|ucFirst}}</mat-card-title>
  <mat-card-title
    *ngIf="this.context === 'company' && this.driver">{{'shifts_of'|translate|ucFirst}} {{this.driver.fname}}
    {{this.driver.lname}}</mat-card-title>
  <mat-divider></mat-divider>
  <td-search-box (searchDebounce)="search($event)" [alwaysVisible]="true" class="push-left push-right"
                 placeholder="{{'search_here'|translate|ucFirst}}"></td-search-box>
  <!--<td-search-box #searchBox backIcon="arrow_back" class="push-right-sm" placeholder="Search here" (searchDebounce)="search($event)" flex></td-search-box>-->
  <mat-divider></mat-divider>
  <ng-template tdLoading="shifts.list">
    <table class="mat-clickable" td-data-table>
      <thead>
      <tr td-data-table-column-row>
        <th (sortChange)="sort($event)"
            *ngFor="let c of columns"
            [active]="(sortBy == c.name)"
            [name]="c.name"
            [numeric]="(c.numeric)"
            [sortOrder]="(sortOrder === 'ASC' ? 'DESC' : 'ASC')"
            [sortable]="(c.sortable)"
            td-data-table-column>
          {{c.label|translate|ucFirst}}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr (click)="open(item.id)" *ngFor="let item of filteredData" class="pointer" td-data-table-row>
        <td *ngFor="let c of columns" [numeric]="c.numeric" td-data-table-cell>
            <span [ngSwitch]="c.name">
              <span *ngSwitchCase="'vehicle'"><span
                *ngIf="item.Vehicle">{{item['Vehicle']['brand']}} {{item['Vehicle']['model']}}
                ({{item['Vehicle']['licensePlate']}})</span></span>
              <span *ngSwitchCase="'startTime'"><span
                *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span></span>
              <span *ngSwitchCase="'endTime'"><span
                *ngIf="item[c.name]">{{item[c.name] | amDateFormat:(this.company && this.company.dateFormat ? '' + this.company.dateFormat + ' ' + this.company.clockFormat : 'DD-MM-YYYY  HH:mm')}}</span><span
                *ngIf="!item[c.name]">-</span></span>
              <span *ngSwitchCase="'modified'"><span *ngIf="item[c.name]">{{item[c.name] | amTimeAgo}}</span></span>
              <span *ngSwitchCase="'duration'"><span *ngIf="item[c.name]">{{item[c.name] | millisecondsToHours}}</span></span>
              <span *ngSwitchDefault><span *ngIf="item[c.name]">{{item[c.name]}}</span></span>
            </span>
        </td>
      </tr>
      </tbody>
      <tbody *ngIf="filteredData.length === 0">
      <tr>
        <td [colSpan]="columns.length"
            class="table-placeholder">
          {{'no_shifts_in_overview'|translate|ucFirst}}
        </td>
      </tr>
      </tbody>
    </table>
    <td-paging-bar #pagingBar (change)="page($event)" [total]="filteredTotal">
      <span hide-xs td-paging-bar-label>{{'rows_p_page'|translate}}:</span>
      {{pagingBar.range}} <span hide-xs>of {{pagingBar.total}}</span>
    </td-paging-bar>
  </ng-template>
</mat-card>
