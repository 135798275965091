import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {RideEditPassengerComponent} from '../../ride-edit-passenger.component';

@Component({
  selector: 'app-passenger-confirm-add-contact-dialog',
  templateUrl: './passenger-confirm-add-contact-dialog.component.html',
  styleUrls: ['./passenger-confirm-add-contact-dialog.component.scss']
})
export class PassengerConfirmAddContactDialogComponent implements OnInit {
  parent: RideEditPassengerComponent;
  sendMail = true;

  constructor(
    public dialogRef: MatDialogRef<PassengerConfirmAddContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.parent = data.parent;
  }

  ngOnInit(): void {

  }
}
