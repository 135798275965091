import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Driver} from '../../../../../models/driver';
import {Company} from '../../../../../models/company';
import {DriverService} from '../../../../../services/driver.service';
import {OfferService} from '../../../../../services/offer.service';
import {ITdDataTableSortChangeEvent, TdDataTableService, TdDataTableSortingOrder} from '@covalent/core/data-table';
import {Offer} from '../../../../../models/offer';
import {GoogleMapsAPIWrapper, LAZY_MAPS_API_CONFIG, MapsAPILoader} from '@agm/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ucFirst} from '../../../../../pipes/uc-first.pipe';
import {TranslateService} from '@ngx-translate/core';
import {MatTabChangeEvent} from '@angular/material/tabs';
import {ContactService} from '../../../../../services/contact.service';
import {GoogleMapConfig} from '../../../../googleMapConfig';
import {CustomLazyAPIKeyLoader} from '../../../../../services/CustomLazyAPIKeyLoader';
import {TdLoadingService} from '@covalent/core/loading';

@Component({
  selector: 'app-dispatch-dialog',
  templateUrl: './dispatch-dialog.component.html',
  styleUrls: ['./dispatch-dialog.component.scss'],
  providers: [
    GoogleMapsAPIWrapper,
    {provide: MapsAPILoader, useClass: CustomLazyAPIKeyLoader},
    {provide: LAZY_MAPS_API_CONFIG, useClass: GoogleMapConfig}
  ],
})
export class DispatchDialogComponent implements OnInit, OnDestroy {
  parentData: any;
  translations: any[] = [];
  context: string;
  company: Company;
  rideId: string;
  onClose: any;
  selected: any;
  jobs = [];
  myData: any;
  members: any[] = [];
  allMembers: any[] = [];
  onlineMembers: any[] = [];
  offlineMembers: any[] = [];
  sortedAllMembers: any[] = [];
  sortedMembers: any[] = [];
  sortedMembersOnline: any[] = [];
  sortedOfflineMembers: any[] = [];
  sortedOnlineMembersTotal: number;
  sortedOfflineMembersTotal: number;
  selectedTabIndex = 0;
  myCompanies = [];
  driverIds = [];

  columns = [
    {name: 'name', label: 'name', sortable: true, active: true},
    {name: 'phoneNumber', label: 'phoneNumber', sortable: false, active: false, maxSize: 'maxSize600'},
    {name: 'distance', label: 'distance', sortable: true, active: false, maxSize: 'maxSize600'},
    {name: 'status', label: 'status', sortable: true, active: false},
    {name: 'radio', label: 'select', sortable: false, active: false}
  ];
  departureGps: any;
  searchTerm = '';
  fromRow = 1;
  currentPage = 1;
  pageSize = 10;
  sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Ascending;
  sortBy = 'distance';

  constructor(
    private driverService: DriverService,
    private _offerService: OfferService,
    private _dataTableService: TdDataTableService,
    private _loadingService: TdLoadingService,
    private contactService: ContactService,
    private mapsAPILoader: MapsAPILoader,
    public snackBar: MatSnackBar,
    private _translateService: TranslateService,
    private _driverService: DriverService,
    public dialogRef: MatDialogRef<DispatchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    _translateService.get([
      'job_dispatched',
      'driver',
      'ok',
    ]).subscribe((translations: string[]) => {
      this.translations = translations;
    });
    this.parentData = data.parent;
    if (!data.jobs) {
      this.rideId = data.parent.rideId;
      this.jobs = data.jobs;
    }

    this.company = data.parent.company;
    if (data.parent.company) {
      this.selected = data.parent.company.id;
    } else {
      this.selected = 'personal';
    }

    this.myData = data.parent;
    if (data.onClose) {
      this.onClose = data.onClose;
    }

    this.context = this.myData.context;
    this.mapsAPILoader.load().then(() => {
      this.loadMembers();
    });

    this.loadCompanies();
  }

  getSelectedIcon() {
    return (this.company ? 'business' : 'personal');
  }

  getCompanyName() {
    return (this.company ? this.company.name : 'personal');
  }

  loadCompanies(): void {
    const phoneNumber = (this.myData.driver ? this.myData.driver.phoneNumber :
      (this.myData.myDriver ? this.myData.myDriver.phoneNumber : ''));
    this.myCompanies = [];
    this._driverService.getAll({
      where: {
        phoneNumber: encodeURIComponent(phoneNumber)
      }, include: ['Company']
    }).subscribe((drivers) => {
      drivers.forEach((driver) => {
        if (driver.Company) {
          this.myCompanies.push(driver.Company);
        }
      });
    });
  }

  loadMembers(): void {
    const self = this;
    let query;
    this.allMembers = [];
    this.offlineMembers = [];
    this.onlineMembers = [];
    this.members = [];
    if (this.myData.context === 'company' && this.company) {
      query = {
        'where': {
          companyId: this.company.id,
          status: {in: ['accepted', 'invited']}
        },
        'include': 'ActiveVehicle'
      };
      this.driverService.getAll(query).subscribe((drivers: any[]) => {
        drivers.forEach(function (driver, i) {
          self.addDriverToArray(driver);
        });
        this.filter();
      });
    } else {
      query = {
        'where': {
          'driverId': (this.myData.driver ? this.myData.driver.id : this.myData.myDriver.id),
          'type': 'driver'
        },
        'include': [{
          'relation': 'ConnectedDriver'
        }]
      };

      this.contactService.getAll(query).subscribe((contacts: any[]) => {
        contacts.forEach(function (contact, i) {
          if (contact.ConnectedDriver) {
            self.addDriverToArray(contact.ConnectedDriver);
          }
        });

        const filter = {
          'include': [{
            'relation': 'ActiveVehicle'
          }]
        };
        this.driverService.get((this.myData.driver ? this.myData.driver.id :
          (this.myData.myDriver ? this.myData.myDriver.id : '')), filter).subscribe((driver: any[]) => {
          self.addDriverToArray(driver);
          this.filter();
        });
      });
    }
  }

  addDriverToArray(driver) {
    driver.name = `${driver.fname} ${(driver.lname && driver.lname !== '-' ? '' : '')}`;

    if (driver.ActiveVehicle && driver.ActiveVehicle.location && driver.ActiveVehicle.location.gps) {
      driver.distance = this.calculateDistance(driver.ActiveVehicle.location.gps);
    } else {
      driver.distance = '-';
    }

    if (driver.shadow) {
      driver.status = `invited`;
      driver.ActiveVehicle = {
        status: {
          state: 'offline'
        }
      };
    } else if (!driver.ActiveVehicle) {
      driver.status = `status_not_connected`;
      driver.ActiveVehicle = {
        status: {
          state: 'offline'
        }
      };
    } else if (driver.ActiveVehicle && !driver.ActiveVehicle.status.connected && driver.ActiveVehicle.status.state !== 'offline') {
      // driver.status = `status_not_connected`;
      driver.status = `offline`;
      driver.ActiveVehicle = {
        status: {
          state: 'offline'
        }
      };
    } else {
      driver.status = `status_${(driver.ActiveVehicle ? driver.ActiveVehicle.status.code : '199')}`;
    }

    if (driver.ActiveVehicle && driver.ActiveVehicle.status.state === 'offline') {
      this.offlineMembers.push(driver);
    } else if (driver.ActiveVehicle && driver.ActiveVehicle.status && (driver.ActiveVehicle.status.state !== 'available' ||
      driver.ActiveVehicle.status.connected !== true)) {
      this.offlineMembers.push(driver);
    } else {
      this.members.push(driver);
    }

    this.allMembers.push(driver);
  }

  calculateDistance(gps) {
    if (google.maps.geometry) {
      if (this.myData.departureGps) {
        const departure = new google.maps.LatLng(this.myData.departureGps.lat, this.myData.departureGps.lng);
        if (gps.lat && gps.lng) {
          const driver = new google.maps.LatLng(gps.lat, gps.lng);
          return Math.round((google.maps.geometry.spherical.computeDistanceBetween(departure, driver) / 1000)).toFixed(2);
        } else {
          return '-';
        }
      } else {
        return '-';
      }
    }
  }

  selectDriver(driverId, name): void {
    this.myData.driverId = driverId;
    this.myData.selectedDriverName = name;
  }

  //noinspection JSMethodCanBeStatic
  onTabChange(event: MatTabChangeEvent): void {

  }

  preAssignDriver(): void {
    const self = this;
    this._loadingService.register();
    if (!this.myData.driverId) {
      self.snackBar.open(ucFirst(self.translations['job_dispatch_select_driver']), self.translations['ok'], {
        duration: 3000
      });
      return;
    }

    const offer: any = {
      productId: this.myData.rideId,
      productType: 'ride',
      driverId: this.myData.driverId,
      preAssigned: true,
      status: 'success',
      type: 'driver'
    };
    if (this.context === 'company') {
      offer.companyId = this.company.id;
    }
    self._offerService.insert(offer, self.myData.context).subscribe((o: Offer[]) => {
      self.driverService.get(self.myData.driverId, {}).subscribe((driver: Driver) => {
        self.snackBar.open(ucFirst(self.translations['job_dispatched'].replace('{{driver}}', driver.fname + ' ' + driver.lname)), 'OK', {
          duration: 3000
        });
        self._loadingService.resolve();
        self.dialogRef.close();

        if (self.myData.newDriverEvent) {
          self.myData.newDriverEvent.emit(driver);
        } else if (self.myData.loadData) {
          self.myData.reloadAllData();
        } else if (self.myData.reloadDriverData) {
          self.myData.reloadDriverData();
        }
      });
    });
  }

  delayedPreAssignDriver(): void {
    const self = this;

    if (!this.myData.driverId) {
      self.snackBar.open(ucFirst(self.translations['job_dispatch_select_driver']), self.translations['ok'], {
        duration: 3000
      });
      return;
    }
    if (this.parentData.selectDriver) {
      this.parentData.selectDriver(this.myData.driverId);
    }
    self.dialogRef.close();
  }

  ngOnInit() {

  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }

  sort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.sortBy = sortEvent.name;
    this.sortOrder = sortEvent.order;
    this.filter();
  }

  filter(): void {
    let newAllData: any[] = this.allMembers;
    newAllData = this._dataTableService.filterData(newAllData, this.searchTerm, true);
    newAllData = this._dataTableService.sortData(newAllData, 'status', this.sortOrder);
    newAllData = this._dataTableService.pageData(newAllData, this.fromRow, this.currentPage * this.pageSize);
    this.sortedAllMembers = newAllData;

    newAllData = this.members;
    newAllData = this._dataTableService.filterData(newAllData, this.searchTerm, true);
    newAllData = this._dataTableService.sortData(newAllData, this.sortBy, this.sortOrder);
    newAllData = this._dataTableService.pageData(newAllData, this.fromRow, this.currentPage * this.pageSize);
    this.sortedMembers = newAllData;

    newAllData = this.onlineMembers;
    newAllData = this._dataTableService.filterData(newAllData, this.searchTerm, true);
    newAllData = this._dataTableService.sortData(newAllData, this.sortBy, this.sortOrder);
    newAllData = this._dataTableService.pageData(newAllData, this.fromRow, this.currentPage * this.pageSize);
    this.sortedMembersOnline = newAllData;

    newAllData = this.offlineMembers;
    newAllData = this._dataTableService.filterData(newAllData, this.searchTerm, true);
    newAllData = this._dataTableService.sortData(newAllData, this.sortBy, this.sortOrder);
    newAllData = this._dataTableService.pageData(newAllData, this.fromRow, this.currentPage * this.pageSize);
    this.sortedOfflineMembers = newAllData;
  }

  search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.filter();
  }

  selectGroup(event) {
    if (event.value) {
      const selected = this.myCompanies.filter((c) => {
        return (c.id === event.value)
      });
      if (selected.length > 0) {
        this.company = {
          id: selected[0].id,
          name: selected[0].name
        };
      } else {
        this.company = null;
      }
    } else {
      this.company = null;
    }
    this.loadMembers();
  }

  ngOnDestroy(): void {
    if (typeof this.onClose === 'function') {
      this.onClose();
    }
  }
}
