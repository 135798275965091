<td-layout-card-over cardSubtitle="{{'new_company_subtitle'|translate}}" cardTitle="{{'new_company_title'|translate}}"
                     cardWidth="50">
  <mat-card-content class="push-bottom-none">
    <ng-template tdLoading="profile">
      <form>
        <div class="push-bottom-sm push-top-sm" layout="row">
          <mat-form-field class="push-right" flex>
            <input [(ngModel)]="this.companyName" [(value)]="this.companyName" matInput name="companyName" placeholder="{{'new_group_name'|translate}}"
                   type="text"
                   value="">
          </mat-form-field>
        </div>
      </form>
    </ng-template>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <button (click)="createGroup()" color="accent" flex
            mat-raised-button>{{'create_new_group'|translate}}</button>
  </mat-card-actions>
</td-layout-card-over>
