import {Component, OnInit} from '@angular/core';
import {NavigationService} from '../../../../services/navigation.service';
import {ActivatedRoute} from '@angular/router';
import {MarkdownService} from 'ngx-markdown';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.scss']
})
export class ChangeLogComponent implements OnInit {
  changeLog: string;

  constructor(
    private _navigationService: NavigationService,
    private _route: ActivatedRoute,
    private markdownService: MarkdownService,
    private _http: HttpClient
  ) {
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);
    const self = this;
    this._http.get('/CHANGELOG.md', {responseType: 'text'}).subscribe((data) => {
      self.changeLog = markdownService.parse(data);
    })
  }

  ngOnInit(): void {
  }
}
