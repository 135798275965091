<app-zendesk-widget></app-zendesk-widget>
<router-outlet>
  <div id="splashscreen">
    <div class="loading">
      <div class="sk-folding-cube">
        <div class="sk-cube1 sk-cube"></div>
        <div class="sk-cube2 sk-cube"></div>
        <div class="sk-cube4 sk-cube"></div>
        <div class="sk-cube3 sk-cube"></div>
      </div>
    </div>
  </div>
</router-outlet>
