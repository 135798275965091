import {Injectable} from '@angular/core';
import {CoolLocalStorage} from '@angular-cool/storage';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {Ride} from '../models/ride';
import {HttpClient} from '@angular/common/http';
import {GpsLogEntry} from '../models/gps-log-entry';
import {UtilityService} from './utility.service';
import {GlobalService} from './global.service';

@Injectable()
export class RideService extends GlobalService {
  constructor(_http: HttpClient, _vault: CoolLocalStorage, _utilityService: UtilityService) {
    super(_http, _vault, _utilityService);
    this.setModel('rides');
  }

  getGpsLog(filter?: Object, context?: string): Observable<GpsLogEntry[]> {
    return this._http.get<GpsLogEntry[]>(
      `${environment.apiBaseUrl}/GpsLogs/?filter=${JSON.stringify(filter)}`,
      {headers: this.getHeaders(context)}
    );
  }

  getDispatchSelection(id: string): Observable<any> {
    const filter = JSON.stringify({
      where: {
        rideId: id
      },
      'include': [{
        'relation': 'Offer',
        'scope': {
          'include': [
            'JobOffers'
          ],
        },
      }, {
        'relation': 'Ride',
        'scope': {
          'include': [{
            'relation': 'RideLog',
            'scope': {
              'include': [
                'Entries'
              ],
            }
          }],
          'fields': ['id', 'rideLogId']
        },
      }],
    });
    return this._http.get<any>(`${environment.apiBaseUrl}/DispatchSelections/?filter=${filter}`, {headers: this.getHeaders()});
  }

  portalSearch(ownerId: string, ownerType: string, status: string, start: number, limit: number, fromDate: string = '', toDate: string = '', searchTerm: string = ''): Observable<any> {
    return this._http.get<any>(`${environment.apiBaseUrl}/rides/portalSearch/?ownerId=${ownerId}&ownerType=${ownerType}&status=${status}&start=${(start ? start : '0')}&limit=${(limit ? limit : '100')}&fromDate=${fromDate}&toDate=${toDate}&searchTerm=${encodeURIComponent(searchTerm)}`, {headers: this.getHeaders()});
  }

  portalCount(ownerId: string, ownerType: string, status: string, fromDate: string = '', toDate: string = '', searchTerm: string = ''): Observable<any> {
    return this._http.get<any>(`${environment.apiBaseUrl}/rides/portalCount/?ownerId=${ownerId}&ownerType=${ownerType}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&searchTerm=${encodeURIComponent(searchTerm)}`, {headers: this.getHeaders()});
  }

  cancelJob(id: string, ride: any, context?: string): Observable<Ride> {
    return this._http.patch<Ride>(`${environment.apiBaseUrl}/rides/${id}`, ride, {headers: this.getHeaders(context)});
  }

  completeJob(id: string, ride: any, context?: string): Observable<Ride> {
    return this._http.patch<Ride>(`${environment.apiBaseUrl}/rides/${id}`, ride, {headers: this.getHeaders(context)});
  }

  sendConfirmationEmail(id: string, context?: string): Observable<Ride> {
    return this._http.post<Ride>(`${environment.apiBaseUrl}/rides/${id}/sendConfirmationEmail`, {}, {headers: this.getHeaders(context)});
  }

  authorizePayment(id: string, context?: string): Observable<Ride> {
    return this._http.post<Ride>(`${environment.apiBaseUrl}/rides/${id}/authorizePaymentBalance`, {}, {headers: this.getHeaders(context)});
  }

  processPayment(id: string, context?: string): Observable<Ride> {
    return this._http.post<Ride>(`${environment.apiBaseUrl}/rides/${id}/settlePaymentBalance`, {
      'transferRefundAmount': true
    }, {headers: this.getHeaders(context)});
  }

  closePayment(id: string, context?: string): Observable<Ride> {
    return this._http.patch<Ride>(`${environment.apiBaseUrl}/rides/${id}`, {'paymentProcessStatus': 'closed'}, {headers: this.getHeaders(context)});
  }

  forwardRide(id: string, companyId: string, context?: string): Observable<Ride> {
    return this._http.post<Ride>(`${environment.apiBaseUrl}/rides/${id}/forward/${companyId}`, {}, {headers: this.getHeaders(context)});

  }
}
