import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/components/app.module';
import {environment} from './environments/environment';

declare global {
  interface String {
    formatUnicorn(value: string): string;
  }
}

/**
 * String formatting a la StackOverflow:
 * https://stackoverflow.com/a/18234317
 */
String.prototype.formatUnicorn = String.prototype.formatUnicorn ||
  function () {
    'use strict';
    let str = this.toString();
    if (arguments.length) {
      const t = typeof arguments[0];
      let key;
      const args = ('string' === t || 'number' === t) ?
        Array.prototype.slice.call(arguments)
        : arguments[0];

      for (key in args) {
        str = str.replace(new RegExp('\\{' + key + '\\}', 'gi'), args[key]);
      }
    }

    return str;
  };

if (environment.production && false) {
  enableProdMode();
  window.console.log = () => {
  }
  window.console.group = () => {
  }
  window.console.groupCollapsed = () => {
  }
  window.console.groupEnd = () => {
  }
}

platformBrowserDynamic().bootstrapModule(AppModule);
