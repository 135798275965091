<div layout-gt-sm="row" tdMediaToggle="gt-xs">
  <div class="input-pane" flex="66" flex-gt-md="50" flex-gt-sm="66">
    <mat-card class="job-details-card">
      <mat-card-title *ngIf="this.action==='add'">{{'job_add_title'|translate|ucFirst}}</mat-card-title>
      <mat-card-subtitle *ngIf="this.action==='add'">{{'job_add_subtitle'|translate|translate|replace : '{owner}' :
        this.ownerName |ucFirst}}</mat-card-subtitle>
      <mat-card-title *ngIf="this.action==='edit'">{{'job_edit_title'|translate|ucFirst}} -
        {{ride.reference}}</mat-card-title>
      <mat-divider></mat-divider>
      <ng-template tdLoading="{{this.loaderName}}">
        <form *ngIf="form && (!this.rideId || this.ride.id)">
          <div [formGroup]="form">
            <mat-list>
              <app-ride-edit-driver (newDriverEvent)="newDriverEventHander($event)"
                                    *ngIf="false"
                                    [company]="company"
                                    [context]="context"
                                    [departureGps]="ride.departure.gps"
                                    [driver]="ride.Driver"
                                    [myDriver]="driver"
                                    [reference]="ride.reference"
                                    [rideId]="ride.id"></app-ride-edit-driver>

              <div *ngIf="ride.Driver && false">
                <app-ride-driver-data (reloadData)="newDriverEventHander()" [driverNote]="ride.driverNote"
                                      [driver]="ride.Driver" [ride]="ride"></app-ride-driver-data>
              </div>

              <!-- Ride Location Data -->
              <app-ride-edit-location (addessUpdated)="addessUpdated()"
                                      [departure]="ride.departure"
                                      [destination]="ride.destination"
                                      [form]="form"></app-ride-edit-location>

              <mat-divider></mat-divider>

              <!-- Ride Time Data -->
              <app-ride-edit-requesteddate (newBookedDurationEvent)="newBookedDurationEvent($event)"
                                           (newRequestedDateEvent)="newRequestedDateEvent($event)"
                                           [formErrors]="formErrors"
                                           [form]="form"
                                           [requestedDateTime]="ride.requestedDateTimeFormat"
                                           [requestedDate]="ride.requestedDate"
                                           class="push-bottom"></app-ride-edit-requesteddate>
              <mat-divider></mat-divider>

              <!-- Ride Passenger Data -->
              <app-ride-edit-passenger (conditionalFormatting)="conditionalFormatting()"
                                       (passengerSelected)="newPassengerEventHander($event)"
                                       [context]="context"
                                       [debtor]="ride.Debtor"
                                       [form]="form"
                                       [passengerCount]="ride.passengerCount"
                                       [ride]="ride"
                                       [vehicleType]="ride.vehicleType"></app-ride-edit-passenger>
              <mat-divider></mat-divider>

              <!-- Ride Price Data -->
              <div *ngIf="ride.price">
                <app-ride-edit-price (conditionalFormatting)="conditionalFormatting()"
                                     (pricingError)="pricingError($event)"
                                     [company]="company"
                                     [context]="context"
                                     [formErrors]="formErrors"
                                     [form]="form"
                                     [isOwner]="isOwner"
                                     [mode]="this.action.toLowerCase()"
                                     [passenger]="passenger"
                                     [paymentMeta]="ride.paymentMeta"
                                     [priceMeta]="ride.priceMeta"
                                     [priceMethod]="ride.priceMethod"
                                     [price]="ride.price"
                                     [ride]="ride"
                                     class="push-bottom"></app-ride-edit-price>
              </div>

              <mat-divider *ngIf="ride.price"></mat-divider>


              <!-- Ride edit meta -->
              <app-ride-edit-meta [formErrors]="formErrors"
                                  [form]="form"
                                  [ride]="ride"></app-ride-edit-meta>

              <app-ride-edit-note [form]="form" [ride]="ride"
                                  layout="row"></app-ride-edit-note>

              <mat-divider></mat-divider>
            </mat-list>

            <app-ride-edit-assign (reloadData)="newDriverEventHander()" *ngIf="false"
                                  [ride]="ride"></app-ride-edit-assign>


            <mat-divider></mat-divider>

            <mat-card-actions class="align-job-buttons">
              <button (click)="saveJob()"
                      color="accent"
                      flex
                      mat-raised-button>{{this.actionTranslated|ucFirst}}</button>
              <button (click)="cancelJob()"
                      *ngIf="action !== 'add'"
                      color="warn"
                      flex
                      mat-raised-button>{{'cancel_job'|translate|ucFirst}}</button>
            </mat-card-actions>
          </div>
        </form>
      </ng-template>
    </mat-card>
  </div>
  <div class="map" flex="33" flex-gt-md="50" flex-gt-sm="33">
    <mat-card>
      <mat-card-title>{{'map'|translate|ucFirst}}</mat-card-title>
      <mat-divider></mat-divider>
      <mat-card-content>
        <ng-template tdLoading="ride.map">
          <agm-map
            (mapReady)="mapReady($event)"
            [maxZoom]="17">

            <app-agm-custom-waypoints #mapDirection [form]="form" [ride]="ride"></app-agm-custom-waypoints>

            <agm-marker
              (dragEnd)="onMarkerDragEnd($event, 'departure')"
              *ngIf="ride.departure && ride.departure.gps && ride.departure.gps.lat"
              [agmFitBounds]="true"
              [iconUrl]="departureIconUrl"
              [latitude]="ride.departure.gps.lat"
              [longitude]="ride.departure.gps.lng"
              [markerDraggable]="false"
              label="{{'departure'|translate|ucFirst}}">
            </agm-marker>

            <agm-marker
              (dragEnd)="onMarkerDragEnd($event, 'departure')"
              *ngIf="ride.destination && ride.destination.gps && ride.destination.gps.lat"
              [agmFitBounds]="true"
              [iconUrl]="destinationIconUrl"
              [latitude]="ride.destination.gps.lat"
              [longitude]="ride.destination.gps.lng"
              [markerDraggable]="false"
              label="{{'destination'|translate|ucFirst}}">
            </agm-marker>

          </agm-map>
        </ng-template>
      </mat-card-content>
    </mat-card>
  </div>
</div>
