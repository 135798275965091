<div [formGroup]="form" class="push-top">
  <strong class="strong-header-margin">{{ 'message_center_actions' | translate | ucFirst }}</strong>

  <table formArrayName="actions" class="actions-table">
    <tbody>
    <tr *ngFor="let c of currentActions(); index as i" [formGroupName]="i">
      <td>
        {{ (c.controls.actionType.value === 'sms' ? 'send_sms_message' : 'send_email_message') | translate | ucFirst }}
      </td>
      <td *ngIf="c.controls.messages">
        <img *ngFor="let m of c.controls.messages.controls; index as mi"
             class="flag"
             (click)="editTranslation(i, mi, this.form.controls['actions'].controls[i].value.actionType)"
             src="{{getCountryFlag(m['controls'].language.value)}}"
             matTooltipPosition="above"
             [matTooltip]="m['controls'].message.value">
      </td>
      <td *ngIf="c.controls.messages && false">
        <span *ngFor="let m of c.controls.messages.controls; index as mi">
          (<img
               class="flag"
               (click)="editTranslation(i, mi, this.form.controls['actions'].controls[i].value.actionType)"
               src="{{getCountryFlag(m['controls'].language.value)}}"
               matTooltipPosition="above" style="float:none"
               [matTooltip]="m['controls'].message.value"> {{m['controls'].language.value}})
        </span>
      </td>

      <td class="languages">
        <a
          (click)="addTranslation(i, this.form.controls['actions'].controls[i].value.actionType)"
          class="row-button yda-link-blue">
          {{ 'add_language' |translate | ucFirst }}
        </a>
        <button (click)="showPreview(i)" *ngIf="false"
                class="row-button button-dynamic"
                mat-icon-button
                matTooltip="{{ 'show_preview' | translate | ucFirst }}"
                matTooltipPosition="right">
          <mat-icon aria-label="{{'preview' |translate}}">
            preview
          </mat-icon>
        </button>
      </td>
    </tr>
    <tr *ngIf="(!hasEnglishEmail && hasEmailAction) || (!hasEnglishSms && hasSmsAction)">
      <td colspan='4' class="warning">
        <mat-icon>warning</mat-icon>
        {{ 'no_en_translation_message'|translate|ucFirst }}
      </td>
    </tr>
    </tbody>
  </table>
  <br>

  <button *ngIf="!hasSmsAction || !hasEmailAction" (click)="addActionRowConfirm()" color="accent" flex
          mat-flat-button class="td-menu-button">
    <mat-icon>add</mat-icon>
    {{ 'add_action'|translate|ucFirst }}
  </button>

  <table *ngIf="false">
    <tr *ngIf="!hasSmsAction" class="add-action-row">
      <td class="add-action">
        <button (click)="addActionRow('sms')" color="accent" flex
                mat-raised-button class="td-menu-button">
          <mat-icon>add</mat-icon>
          {{ 'add_action_sms'|translate|ucFirst }}
        </button>
      </td>
    </tr>
    <tr *ngIf="!hasEmailAction" class="add-action-row">
      <td class="add-action">
        <button (click)="addActionRow('email')" color="accent" flex
                mat-raised-button class="td-menu-button">
          <mat-icon>add</mat-icon>
          {{ 'add_action_email'|translate|ucFirst }}
        </button>
      </td>
    </tr>
  </table>
</div>

