<mat-list-item flex="50" flex-gt-sm="50">
  <mat-icon mat-list-icon>person_pin</mat-icon>
  <h4 mat-line>{{'driver'|translate|ucFirst}}</h4>
</mat-list-item>
<div *ngIf="driver" [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row"
     tdMediaToggle="gt-xs">
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'name'|translate|ucFirst}}</p>
    <p mat-line>{{(driver ? driver.fname + ' ' + driver.lname : '-')}}</p>
  </mat-list-item>
  <mat-list-item class="value small" flex-gt-sm="50">
    <p mat-line>{{'phone_number'|translate|ucFirst}}</p>
    <p mat-line>{{(driver ? driver.phoneNumber : '-')}}</p>
  </mat-list-item>
</div>
<div [mediaClasses]="['push-sm']" class="pull-top-sm push-bottom-sm" layout-gt-sm="row" tdMediaToggle="gt-xs">
  <mat-list-item *ngIf="driver" class="value small" flex-gt-sm="50">
    <p mat-line>{{'email'|translate|ucFirst}}</p>
    <p mat-line>{{(driver ? driver.emailAddress : '-')}}</p>
  </mat-list-item>
  <mat-list-item class="value small" flex="50" flex-gt-sm="50">
    <button (click)="openDispatchDialog()" color="primary"
            flex="50"
            flex-gt-sm="50"
            mat-raised-button>{{(!driver ? 'assign_to_driver' : 'reassign_to_driver')|translate|ucFirst}}</button>
  </mat-list-item>
</div>
