import {Component, EventEmitter, Injector, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {CollectionComponent} from '../../../../../../global/collection/collection.component';
import {PaymentBalanceService} from '../../../../../../../services/payment-balance.service';
import {ucFirst} from '../../../../../../../pipes/uc-first.pipe';
import {RideService} from '../../../../../../../services/ride.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TdDialogService} from '@covalent/core/dialogs';
import {PaymentBalanceRecordAddComponent} from './parts/payment-balance-record-add/payment-balance-record-add.component';

@Component({
  selector: 'app-payment-balance',
  templateUrl: './payment-balance.component.html',
  styleUrls: ['./payment-balance.component.scss'],
  providers: [PaymentBalanceService],
})
export class PaymentBalanceComponent extends CollectionComponent implements OnInit, OnDestroy {
  @Output() reloadData = new EventEmitter<string>();
  @Input() ride;
  translations: string[];
  actionName = 'openPayments';
  modelName = 'rides';
  columns: any[] = [
    {name: 'created', label: 'date', sortable: true, active: false, maxSize: null},
    {name: 'description', label: 'description', sortable: true, active: false, maxSize: null},
    {name: 'debit', label: 'debit', sortable: true, active: false},
    {name: 'credit', label: 'credit', sortable: true, active: false},
    {name: 'actions', label: 'actions', sortable: true, active: false},
  ];
  type = 'all';
  total = {
    total: 0,
    currency: ''
  };
  cTotal = {
    total: 0,
    currency: ''
  };
  even = 'odd';
  paging = {
    'all': {
      fromRow: 0,
      currentPage: 0,
      pageSize: 5
    }
  };
  selectFilter = {
    where: {
      'rideId': ''
    }
  };

  constructor(
    protected injector: Injector,
    private _translateService: TranslateService,
    private _titleService: Title,
    private _paymentBalanceService: PaymentBalanceService,
    private _activatedRoute: ActivatedRoute,
    private _injector: Injector,
    private _rideService: RideService,
    private _dialogService: TdDialogService,
    private _snackBar: MatSnackBar,
  ) {
    super(injector);
    this.modelService = _paymentBalanceService;

    if (this._route.routeConfig) {
      this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);
    }

    _translateService.get(['ok', 'payment_capture_confirm_message', 'payment_capture_confirm_title', 'payment_refund_confirm_message', 'payment_refund_confirm_title', 'payment_refunded_message', 'payment_processed_message', 'payment_record_delete_confirm_message', 'payment_record_delete_confirm_title']).subscribe((translations: any) => {
      this.translations = translations;
    });
  }

  _ngOnInit() {
    if (this.ride) {
      this.selectFilter.where.rideId = this.ride.id;
      this.originalData['all'] = this.ride.PaymentBalance;
      this.afterFilter();
    }
  }

  ngOnInit() {
    this._ngOnInit();
  }

  ngOnDestroy() {
    this._navigationService.setActionLink('');
    this._navigationService.setInfoAction('');
  }

  afterFilter() {
    this.total.total = 0;
    this.cTotal.total = 0;
    this.originalData['all'].forEach((item) => {
      item.trColor = this.even = (this.even === 'even-tr' ? 'odd-tr' : 'even-tr');

      if (item.code !== 'CUSTOM') {
        item.description = `payment_balance_code_${item.code.toLowerCase()}`;
      }


      if (item.code !== 'PAYMENT_AUTHORIZED') {
        if (item.amount && item.amount.total > 0) {
          item.debit = item.amount
        } else {
          item.credit = item.amount
        }

        this.total = {
          total: (this.total.total + item.amount.total),
          currency: this.ride.price.currency,
        };
        this.cTotal = {
          total: (this.cTotal.total + (0 - item.amount.total)),
          currency: this.ride.price.currency,
        };

        if (item.credit) {
          item.credit.total = 0 - item.credit.total;
        }
      } else {
        item.authorized = item.amount
      }
    });
  }

  captureAmount() {
    const self = this;
    self._dialogService.openConfirm({
      'message': self.translations['payment_capture_confirm_message'],
      'disableClose': false,
      'maxWidth': (window.innerWidth < 600 ? '100%' : '500px'),
      'width': (window.innerWidth < 600 ? '100%' : '500px'),
      'title': self.translations['payment_capture_confirm_title'],
      'acceptButton': self.translations['ok'],
    }).afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          self._loadingService.register();
          self._rideService.processPayment(this.ride.id).subscribe((result) => {
            self._snackBar.open(ucFirst(self.translations['payment_processed_message']), self.translations['ok'], {
              duration: 3000
            });
            self.loadData();
            self.reloadData.emit();
            setTimeout(function () {
              self.loadData();
            }, 10000);
            self._loadingService.resolve();
          });
        }
      });
  }

  refundAmount() {
    const self = this;
    self._dialogService.openConfirm({
      'message': self.translations['payment_refund_confirm_message'],
      'disableClose': false,
      'maxWidth': (window.innerWidth < 600 ? '100%' : '500px'),
      'width': (window.innerWidth < 600 ? '100%' : '500px'),
      'title': self.translations['payment_refund_confirm_title'],
      'acceptButton': self.translations['ok'],
    }).afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          self._loadingService.register();
          self._rideService.processPayment(this.ride.id).subscribe((result) => {
            self._snackBar.open(ucFirst(self.translations['payment_refunded_message']), self.translations['ok'], {
              duration: 3000
            });
            self.loadData();
            self.reloadData.emit();
            self._loadingService.resolve();
          }, (error) => {
            console.log(error);
            self._loadingService.resolve();
          });
        }
      });
  }

  addPaymentBalanceRecord() {
    const self = this;
    self._dialogService.open(PaymentBalanceRecordAddComponent, {
      maxHeight: '80%',
      maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
      data: {parent: this},
    });
  }

  deletePaymentBalanceRecord(id) {
    const self = this;
    self._dialogService.openConfirm({
      'message': self.translations['payment_record_delete_confirm_message'],
      'disableClose': false,
      'maxWidth': (window.innerWidth < 600 ? '100%' : '500px'),
      'width': (window.innerWidth < 600 ? '100%' : '500px'),
      'title': self.translations['payment_record_delete_confirm_title'],
      'acceptButton': self.translations['ok'],
    }).afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          self._loadingService.register();
          self._paymentBalanceService.delete(id, 'company').subscribe((result) => {
            self.loadData();
            self.reloadData.emit();
            self._loadingService.resolve();
          }, (error) => {
            console.log(error);
            self._loadingService.resolve();
          });
        }
      });
  }
}
