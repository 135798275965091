<form id="payment-form">
  <mat-card-title class="push-bottom">{{'add_card'|translate|ucFirst}}</mat-card-title>
  <mat-card-subtitle class="push-bottom">{{'add_card_subtitle'|translate|ucFirst}}</mat-card-subtitle>
  <mat-divider></mat-divider>
  <div id="card"></div>
  <div id="card-errors">{{cardError}}</div>

  <div class="action-pane">
    <button (click)="createToken()" class="push-top" flex
            mat-stroked-button>
      {{'add_payment_method'|translate|ucFirst}}</button>

    <button (click)="dialogRef.close()" class="push-top" flex
            mat-stroked-button>
      {{'cancel'|translate|ucFirst}}</button>
  </div>
</form>
