<section class="mat-typography">
  <h1>{{ 'toggle_pricing_rules' | translate | ucFirst }}</h1>
  <mat-card-subtitle>
    {{ 'apps_pricing_rules_dialog' | translate | ucFirst }}
  </mat-card-subtitle>
</section>

<div class="td-dialog-content">
  <table class="mat-clickable" td-data-table>
    <thead>
    <tr td-data-table-column-row>
      <th *ngFor="let c of data.parent.ruleColumns2 | slice:1"
          [name]="c.name"
          td-data-table-column>
        {{ c.label | translate | ucFirst}}
      </th>
    </tr>
    </thead>

    <tbody>
    <tr *ngFor="let item of data.parent.pricingRules"
        td-data-table-row>
      <td *ngFor="let c of data.parent.ruleColumns2 | slice:1"
          [numeric]="item.numeric"
          td-data-table-cell>

          <span [ngSwitch]="c.name">

            <mat-checkbox (change)="data.parent.toggleRuleLinkSetting(
                'isAllowedOnMeter',
                $event.checked,
                item
              )"
                          *ngSwitchCase="'isAllowedOnMeter'"
                          [checked]="item[c.name]">
            </mat-checkbox>

            <mat-checkbox (change)="data.parent.toggleRuleLinkSetting(
                'isFixed',
                $event.checked,
                item
              )"
                          *ngSwitchCase="'isFixed'"
                          [checked]="item[c.name]">
            </mat-checkbox>

            <span *ngSwitchCase="'type'">
              {{ ruleFrontendName(item[c.name]) | translate | ucFirst }}
            </span>

            <span *ngSwitchDefault>
              {{ item[c.name] | ucFirst }}
            </span>

          </span>
      </td>
    </tr>
    </tbody>
  </table>
</div>

<div class="td-dialog-actions">
  <span class="td-dialog-spacer"></span>
  <button (click)="close()" mat-raised-button>
    {{ 'save' | translate }}
  </button>
</div>
