import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {YoutubeResponse} from '../models/youtube.models';

@Injectable({
  providedIn: 'root'
})
export class YoutubeService {

  public nextPageToken = '';
  private youtubeUrl = 'https://www.googleapis.com/youtube/v3';
  // private apikey          = "AIzaSyBrd7sl74-EU7tqtWYK_jyd0i0quamBbQU";
  private apikey = 'AIzaSyA74mULdPwVdtA1JrGE8jCCgcuDNJV7Phg';
  private playList = 'PLbuEh1u9qJt0MsZiQzpz4k8Xu-CPnIN0Y';
  private channelId = 'UCvK-2BqBn_csvi_UzHJNYgw';

  constructor(
    private http: HttpClient
  ) {

  }

  getVideos(channelId = false) {
    const url = `${this.youtubeUrl}/playlistItems`;

    const params = new HttpParams()
      .set('part', 'snippet')
      .set('key', this.apikey)
      .set('playlistId', this.playList)
      .set('maxResults', '20')

    return this.http.get<YoutubeResponse>(url, {params})
      .pipe(
        map(videos => {
          return videos.items;
        }),
        map(items => items.map(video => video.snippet))
      )
  }

  getChannels() {
    const url = `${this.youtubeUrl}/playlists`;

    const params = new HttpParams()
      .set('part', 'snippet')
      .set('key', this.apikey)
      .set('channelId', this.channelId)
      // .set('playlistId', this.playList)
      .set('maxResults', '12')

    return this.http.get<YoutubeResponse>(url, {params})
      .pipe(
        map(videos => {
          return videos.items;
        })
      )
  }

  getChannelVideos(playlistId) {
    if (playlistId) {
      const url = `${this.youtubeUrl}/playlistItems`;

      const params = new HttpParams()
        .set('part', 'snippet')
        .set('key', this.apikey)
        .set('channelId', this.channelId)
        .set('playlistId', playlistId)
        .set('maxResults', '20')

      return this.http.get<YoutubeResponse>(url, {params})
        .pipe(
          map(videos => {
            this.nextPageToken = videos.nextPageToken;
            return videos.items;
          }),
          map(items => items.map(video => video.snippet))
        )
    }
  }
}
