import {Component, NgZone, OnInit, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {TdDialogService} from '@covalent/core/dialogs';
import {TdLoadingService} from '@covalent/core/loading';
import {Location, LocationType} from 'app/models/location';

import {NavigationService} from '../../../../../../services/navigation.service';
import {LocationService} from '../../../../../../services/tps/location.service';
import {MapComponent} from '../../../../drivers/map/map.component';

@Component({
  selector: 'app-location-import',
  templateUrl: './location-import.component.html',
  styleUrls: ['./location-import.component.scss'],
  providers: [LocationService],
})
export class LocationImportComponent implements OnInit {

  @ViewChild(MapComponent) mapComponent: MapComponent;

  action: string;
  type: LocationType;

  locationId: string;
  companyId: string;
  location: Location = new Location();
  form: UntypedFormGroup;

  numberOfShapes = 0;
  draggable = false;
  editable = false;

  types = [
    'point',
    'area',
  ]
  /**
   * Get icon.
   */
  getIcon = Location.getIcon;

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _locationService: LocationService,
    private _loadingService: TdLoadingService,
    private _navigationService: NavigationService,
    private _dialogService: TdDialogService,
    private _fb: UntypedFormBuilder,
    private _ngZone: NgZone,
  ) {
    this._navigationService.setActiveSubmenu(this._route.routeConfig['submenu']);
  }

  /**
   * Location descriptors by type.
   */
  descriptors = (): string[] => Location.descriptors[this.location.type] || [];

  /**
   * Query param and form type must be equal.
   */
  isNew = () => !this.locationId;
  isType = (type: 'point' | 'area') => () =>
    this.form && [this.form.value.type, this.type]
      .every(e => e === LocationType[type]);
  isPoint = this.isType('point');
  isArea = this.isType('area');
  hasShape = () => !!this.numberOfShapes;
  isShared = () => this.location.isShared;

  /**
   * Accept emitted events.
   */
  onNrShapes = (n) => this.numberOfShapes = n;
  onMapReady = (map) => null;

  /**
   * On component initialize, make form.
   */
  ngOnInit() {
    this._route.parent.params.subscribe(first => {
      this.companyId = first['id'];
      this._route.params.subscribe(second => {
        this.locationId = second['id'];
        this.action = second['action'];
        this.type = second['model'];
        this.startLoader();
        this.loadData();
      });
    });
  }

  /**
   * Clone a location that a user desires to have.
   */
  clone() {
    this._locationService.clone(this.location._id)
      .subscribe(location => this._router.navigate([
        `/groups/${this.companyId}/locations/${location.type}/${location._id}/details`
      ]));
  }

  private initForm() {
    this.form = this.makeFormFor(this.location);
  }

  private loadData = () =>
    this._locationService.get(this.locationId, {
      where: {companyId: this.companyId}
    }).subscribe((location: Location) => {
      this.location = location;
      this.initForm();
      this.stopLoader();
    });

  private makeFormFor = (location: Location): UntypedFormGroup => {
    const descriptor = location.descriptor;
    return this._fb.group({
      name: [location.name, [Validators.required]],
      type: [this.type],
      address: [location.address],
      descriptor: [
        location.descriptor
          ? descriptor
          : this.type === 'point' ? 'address' : 'city'
      ],
      point: [location.point, [Validators.required]],
      area: [location.area],
    });
  }

  private startLoader = (): boolean =>
    this._loadingService.register('location')

  private stopLoader = (): boolean =>
    this._loadingService.resolve('location')
}
