<img
  alt="" class="plan-img"
  src="https://www.yourdriverapp.com/wp-content/uploads/2020/11/cloudconnect.svg">
<mat-card-title>{{'cloud_connect_suspended_title'|translate|ucFirst}}</mat-card-title>
<mat-card-subtitle>{{'cloud_connect_suspended_subtitle'|translate|ucFirst}}</mat-card-subtitle>

<mat-divider></mat-divider>
<mat-card-content class="cloudconnecttext">
  <app-billing-history [billingHistory]="this.billingHistory" [dueContract]="true"></app-billing-history>
</mat-card-content>
