import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../shared/shared.module';
import {UcFirstPipeModule} from '../../pipes/uc-first.pipe';
import {ReplacePipeModule} from '../../pipes/replace.pipe';
import {KeepHtmlPipeModule} from '../../pipes/keep-html.pipe';
import {CovalentMessageModule} from '@covalent/core/message';
import {CovalentLayoutModule} from '@covalent/core/layout';
import {CovalentLoadingModule} from '@covalent/core/loading';
import {CovalentMediaModule} from '@covalent/core/media';
import {CovalentMenuModule} from '@covalent/core/menu';
import {CovalentDataTableModule} from '@covalent/core/data-table';
import {CovalentSearchModule} from '@covalent/core/search';
import {CovalentPagingModule} from '@covalent/core/paging';
import {CovalentExpansionPanelModule} from '@covalent/core/expansion-panel';
import {CovalentMarkdownModule} from '@covalent/markdown';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTabsModule} from '@angular/material/tabs';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatDialogModule} from '@angular/material/dialog';
import {OperatorRoutingModule} from './operator-routing.module';
import {AgmCoreModule} from '@agm/core';
import {MomentModule} from 'angular2-moment';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {WidgetsModule} from '../../widgets/widgets.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatStepperModule} from '@angular/material/stepper';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {DynamicFormModule} from '../global/dynamic-form/dynamic-form.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {LocalizedCurrencyPipeModule} from '../../pipes/localized-currency.pipe';
import {AuthService} from '../../services/auth.service';
import {UserService} from '../../services/user.service';
import {createTranslateLoader} from '../company/company.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    UcFirstPipeModule,
    ReplacePipeModule,
    KeepHtmlPipeModule,
    CovalentMessageModule,
    CovalentLayoutModule,
    CovalentLoadingModule,
    CovalentMediaModule,
    CovalentMenuModule,
    CovalentDataTableModule,
    CovalentSearchModule,
    CovalentPagingModule,
    CovalentExpansionPanelModule,
    CovalentMarkdownModule,
    MatSnackBarModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSidenavModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatTabsModule,
    MatGridListModule,
    MatDialogModule,
    OperatorRoutingModule,
    AgmCoreModule,
    MomentModule,
    FormsModule,
    ReactiveFormsModule,
    WidgetsModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatStepperModule,
    MatExpansionModule,
    MatAutocompleteModule,
    DynamicFormModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    MatButtonToggleModule,
    AutocompleteLibModule,
    FontAwesomeModule,
    LocalizedCurrencyPipeModule
  ],
  providers: [
    AuthService,
    UserService,
  ],
})
export class OperatorModule {
}
