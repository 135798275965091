import {NgModule, Pipe, PipeTransform} from '@angular/core';
import {environment} from '../../environments/environment';

@Pipe({
  name: 'speedPerHour'
})
export class SpeedPerHourPipe implements PipeTransform {
  transform(value: number, args?: any): any {
    return Math.ceil(value * environment.speedUnitMultiplier);
  }
}

@NgModule({
  declarations: [SpeedPerHourPipe],
  exports: [SpeedPerHourPipe]
})
export class SpeedPerHourPipeModule {
}

