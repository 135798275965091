<mat-divider></mat-divider>
<ng-template tdLoading="contract.edit">
  <div flex="100" layout="row">
    <div flex="90" layout="column">
      <mat-card-title class="second-title">{{"contract_extended_dispatcher_title" | translate}}</mat-card-title>
      <mat-card-subtitle [innerHTML]="'contract_extended_dispatcher_subtitle'|translate|keepHtml"></mat-card-subtitle>
    </div>
    <div class="button-column" layout="column">
      <button (click)="this.openExtendedDispatcher()" class="push-right push-top push-bottom edit-button" color="primary" flex
              mat-raised-button>
        {{'activate_extended_dispatcher'|translate|ucFirst}}</button>
    </div>
  </div>
</ng-template>
