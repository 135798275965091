class ScoreObject {
  entries: number;
  total: number;
  average: number;

  constructor() {
    this.entries = 0;
    this.total = 0;
    this.average = 0;
  }
}

export class DriverScore {
  id: string;
  driverId: string;
  total: number;
  passengerRating: ScoreObject;
  operatorRating: ScoreObject;
  jobHandleScore: ScoreObject;

  constructor() {
    this.id = '';
    this.driverId = '';
    this.total = 0;
    this.passengerRating = new ScoreObject;
    this.operatorRating = new ScoreObject;
    this.jobHandleScore = new ScoreObject;
  }
}
