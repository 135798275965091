<mat-card-title>{{'operator_rating'|translate|ucFirst}}</mat-card-title>
<mat-card-subtitle>{{'operator_rating_subtitle'|translate|ucFirst}}</mat-card-subtitle>
<mat-divider></mat-divider>
<ng-template tdLoading="ride.operator.rating">
  <div class="operator-rating-first-field" layout="row">
    <mat-form-field flex>
      <mat-select [(ngModel)]="operatorRating.score" flex placeholder="Score">
        <mat-option [value]="5">5</mat-option>
        <mat-option [value]="4">4</mat-option>
        <mat-option [value]="3">3</mat-option>
        <mat-option [value]="2">2</mat-option>
        <mat-option [value]="1">1</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div layout="row">
    <mat-form-field flex>
      <textarea [(ngModel)]="operatorRating.comment" color="primary" matInput
                placeholder="{{'comment'|translate|ucFirst}}">{{operatorRating.comment}}</textarea>
    </mat-form-field>
  </div>
</ng-template>

<mat-divider></mat-divider>
<mat-card-actions>
  <button (click)="saveOperatorRating()" class="save-button" mat-raised-button>{{'save_rating'| translate | ucFirst}}
  </button>
</mat-card-actions>
