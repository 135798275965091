import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {InvoiceService} from '../../../../services/pas/invoice.service';
import {InvoiceFilter} from '../invoice-filter';
import {ITdDataTableSortChangeEvent, TdDataTableService, TdDataTableSortingOrder} from '@covalent/core/data-table';
import {IPageChangeEvent} from '@covalent/core/paging';
import {CoolLocalStorage} from '@angular-cool/storage';
import {environment} from '../../../../../environments/environment';
import {Driver} from '../../../../models/driver';
import {UtilityService} from '../../../../services/utility.service';
import {ActivatedRoute} from '@angular/router';
import {MultiActionComponent} from '../../dispatchpanel/parts/multi-action/multi-action.component';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {TdDialogService} from '@covalent/core/dialogs';
import {TranslateService} from '@ngx-translate/core';
import {TdLoadingService} from '@covalent/core/loading';
import {FinalizeDialogComponent} from './parts/finalize-dialog/finalize-dialog.component';
import {InvoiceFilterComponent} from '../invoice-filter/invoice-filter.component';
import {ResendInvoicesDialogComponent} from './parts/resend-invoices-dialog/resend-invoices-dialog.component';
import {Company} from '../../../../models/company';
import {ExporterService} from '../../../../services/exporter.service';
import {RideService} from '../../../../services/ride.service';
import moment from 'moment';
import {ucFirst} from '../../../../pipes/uc-first.pipe';

interface ModelService {
  getAll(filter: {}, context: string);
}

@Component({
  selector: 'app-open-invoices',
  templateUrl: './open-invoices.component.html',
  styleUrls: ['./open-invoices.component.scss'],
  providers: [InvoiceService, ExporterService],
  animations: [
    trigger('multiSelect', [
      state('collapsed', style({
        height: '0px',
        padding: '0px 15px',
      })),
      state('expanded', style({
        height: (window.innerWidth < 750 && window.innerWidth > 302 ? '80px' : (window.innerWidth < 302 ? '120px' : '40px')),
        padding: '15px',
      })),
      transition('void => *', animate(200)),
      transition('collapsed => expanded', animate(200)),
      transition('expanded => collapsed', animate(200)),
    ]),
  ]
})
export class OpenInvoicesComponent implements OnInit {
  @Input() companyId: string;
  @Input() debtorId: string;
  @Input() context: string;
  @Input() type: string;
  @Input() contactId: string;
  @Input() invoiceView: string;
  @Input() searchIds: string[] = [];
  @Output() count = new EventEmitter<number>();
  @Output() switchTab = new EventEmitter<number>();
  @Output() reloadAllDataEmitter = new EventEmitter<number>();
  @Output() exportCount = new EventEmitter<number>();
  @ViewChild('invoiceFilter') invoiceFilter!: InvoiceFilterComponent;
  jobDetails: any[] = [];

  driver: Driver;
  company: Company;
  draftColumns = [
    {name: 'check', label: '', sortable: false, active: false, fixedSize: 'w-30', maxSize: null},
    {name: 'created', label: 'invoice_date', sortable: true, active: false},
    // { name: 'jobReference',   label: 'reference',       sortable: true,   active: false },
    {name: 'contact', label: 'contact', sortable: true, active: false},
    {name: 'email', label: 'email', sortable: true, active: false},
    {name: 'status', label: 'status', sortable: true, active: false},
    {name: 'amount', label: 'amount', sortable: true, active: false, numeric: true},
  ];
  columns = [
    {name: 'check', label: '', sortable: false, active: false, fixedSize: 'w-30', maxSize: null},
    {name: 'created', label: 'invoice_date', sortable: true, active: false},
    {name: 'contact', label: 'contact', sortable: true, active: false},
    {name: 'email', label: 'email', sortable: true, active: false},
    {name: 'reference', label: 'invoice_id', sortable: true, active: true},
    {name: 'displayStatus', label: 'status', sortable: true, active: false},
    {name: 'amount', label: 'amount', sortable: true, active: false, numeric: true},
  ];

  exportColumns = [
    {name: 'reference', label: 'invoice_id', sortable: true, active: true},
    {name: 'created', label: 'invoice_date', sortable: true, active: false},
    {name: 'contact', label: 'contact', sortable: true, active: false},
    {name: 'email', label: 'email', sortable: true, active: false},
    {name: 'exportStatus', label: 'export_status', sortable: true, active: false},
    {name: 'amount', label: 'amount', sortable: true, active: false, numeric: true},
  ];

  loader = ``;

  modelService: ModelService;
  data: any[] = [];
  filteredData: any[] = [];
  filteredTotal: number;
  searchTerm = '';
  fromRow = 1;
  currentPage = 1;
  pageSize = 500;
  total = {
    currency: '',
    total: 0,
  };
  sortBy = 'reference';
  sortOrder: TdDataTableSortingOrder = TdDataTableSortingOrder.Descending;
  filterData: InvoiceFilter;
  draftSelected = false;
  processedSelected = false;
  paidSelected = false;
  finalSelected = false;
  allSelected = false;
  multiSelected = false;
  multiSelectedState = 'collapsed';
  multiSelectBox = {
    'draft': [],
    'pending': [],
    'final': [],
    'paid': []
  };
  multiSelect = {
    'draft': [],
    'pending': [],
    'final': [],
    'paid': []
  };
  translations = [];
  status = ['draft'];
  pendingSelected: boolean;
  currentOpen: string;
  currentOpenOld: string;

  constructor(
    private _invoiceService: InvoiceService,
    private _vault: CoolLocalStorage,
    private route: ActivatedRoute,
    private _translateService: TranslateService,
    private _dialogService: TdDialogService,
    private _dataTableService: TdDataTableService,
    private _loadingService: TdLoadingService,
    private _exporter: ExporterService,
    private _rideService: RideService,
  ) {
    this.context = this.route.routeConfig['context'] || 'driver';
    this.count.emit(0);
    this.driver = this._vault.getObject(`${environment.vaultPrefix}.driver`);

    const {company} = this.route.parent.snapshot.data;
    this.company = company;

    _translateService.get(['confirm_job_invoice_unpaid_multi_title',
      'no', 'yes',
      `confirm`,
      `billable`,
      `invoice_id`,
      `email`,
      `vat`,
      `subtotal`,
      `job_invoices`,
      `invoices`,
      `total`,
      `requested_date`,
      `driver`,
      `status`,
      `reference`,
      `departure`,
      `payment_method`,
      `confirm_job_invoice_process_multi`,
      `confirm_job_invoices_send_email_multi`,
      `confirm_job_invoice_unpaid_multi`,
      `confirm_job_invoice_paid_multi`,
      `confirm_job_invoice_create_credit_multi`,
      `confirm_job_delete_draft_multi`,
      `confirm_job_invoice_process_multi_title`,
      `confirm_job_invoices_send_email_multi_title`,
      `confirm_job_invoice_unpaid_multi_title`,
      `confirm_job_invoice_paid_multi_title`,
      `confirm_job_invoice_create_credit_title`,
    ]).subscribe((translations: string[]) => {
      this.translations = translations;
    });

    this.filterData = {
      year: new Date().getFullYear(),
      from: 1,
      to: 12,
      status: ['all'],
      context: this.context
    };
  }

  ngOnInit() {
    this.loader = `driver.settlement.${this.type}`;
    if (['draft'].includes(this.type)) {
      this.filterData.status = ['draft'];
      this.columns = this.draftColumns;
    } else if (['pending'].includes(this.type)) {
      this.filterData.status = ['pending'];
      this.columns = this.draftColumns;
    } else if (this.type === 'export') {
      this.columns = this.exportColumns;
    } else {
      this.filterData.status = ['paid', 'final'];
    }
    this.loadData();
  }

  invoiceFilterChanged(data: any): void {
    this.filterData = data;
    this.loadData();
  }

  doExport() {
    this.export();
  }

  export() {
    /**
     * Get headers from data keys
     */
    const headers = [
      {name: 'id', label: ucFirst(this.translations['invoice_id'])},
      {name: 'billable', label: ucFirst(this.translations['billable'])},
      {name: 'email', label: ucFirst(this.translations['email'])},
      {name: 'status', label: ucFirst(this.translations['status'])},
      {name: 'subtotal', label: ucFirst(this.translations['subtotal'])},
      {name: 'vat', label: ucFirst(this.translations['vat'])},
      {name: 'total', label: ucFirst(this.translations['total'])},
      {name: 'total2', label: ''},
    ];
    const jobInvoiceData = [];

    const mRides = [[]];
    let mRideKey = 0;
    const mainPromise = new Promise<void>((mainResolve) => {
      this.data.forEach((invoice) => {
        const specLines = invoice.specificationLines || [];
        specLines.forEach(line => {
          if (line.rideId) {
            if (mRides[mRideKey].length < 50) {
              mRides[mRideKey].push(line.rideId);
            } else {
              mRideKey = mRideKey + 1;
              mRides[mRideKey] = [];
              mRides[mRideKey].push(line.rideId);
            }
          }
        });
      });

      const prom = new Promise<void>((resolve) => {
        const promArray = [];
        mRides.forEach((rideIds, iX, index) => {
          promArray.push(new Promise<void>((resolve2) => {
            this._rideService.getAll({where: {id: {inq: rideIds}}, include: ['Driver']})
              .subscribe((rides: any[]) => {
                // Combine `ride` details with `SpecificationLines`
                this.data.forEach((invoice, i) => {

                  let foundLine = null;
                  invoice.specificationLines.forEach((line) => {
                    foundLine = rides.find(r => r.id === line.rideId);
                  });

                  if (foundLine) {
                    this.data[i].specificationLines = invoice.specificationLines.map(line => ({
                      ...line,
                      ride: foundLine
                    }));
                  }

                });
                resolve2();
              });
          }));
        });

        Promise.all(promArray).then(() => {
          return resolve();
        })
      });
      prom.then(() => {
        this.data.forEach((invoice) => {
          const rowHeaders: any = {
            'id': ucFirst(this.translations['invoice_id']) + '|bold',
            'billable': ucFirst(this.translations['billable']) + '|bold',
            'email': ucFirst(this.translations['email']) + '|bold',
            'status': ucFirst(this.translations['departure']) + '|bold',
            'subtotal': ucFirst(this.translations['payment_method']) + '|bold',
            'vat': ucFirst(this.translations['subtotal']) + '|bold',
            'total': ucFirst(this.translations['vat']) + '|bold',
            'total2': ucFirst(this.translations['total']) + '|bold',
          };
          jobInvoiceData.push(rowHeaders);
          jobInvoiceData.push({
            'id': invoice.reference,
            'billable': invoice.billable,
            'email': invoice.email,
            'status': invoice.status,
            'subtotal': ((invoice.total.total - invoice.total.tax) / 100),
            'vat': (invoice.total.tax / 100),
            'total': (invoice.total.total / 100),
          });

          const specHeaders: any = {
            'id': ucFirst(this.translations['reference']) + '|bold',
            'billable': ucFirst(this.translations['driver']) + '|bold',
            'email': ucFirst(this.translations['requested_date']) + '|bold',
            'status': ucFirst(this.translations['departure']) + '|bold',
            'subtotal': ucFirst(this.translations['payment_method']) + '|bold',
            'vat': ucFirst(this.translations['subtotal']) + '|bold',
            'total': ucFirst(this.translations['vat']) + '|bold',
            'total2': ucFirst(this.translations['total']) + '|bold',
          };

          jobInvoiceData.push(specHeaders);
          invoice.specificationLines.forEach((line) => {
            if (line.ride) {
              jobInvoiceData.push({
                'id': line.ride.reference,
                'billable': (line.ride.Driver ? `${line.ride.Driver.fname} ${line.ride.Driver.lname}` : ``),
                'email': moment(line.ride.requestedDate).format((this.company && this.company.dateFormat ? `${this.company.clockFormat} ${this.company.dateFormat}` : 'HH:mm DD-MM-YYYY')),
                'status': UtilityService.getLocationString(line.ride.departure),
                'subtotal': line.ride.paymentMeta.origin,
                'vat': (line.total.tax / 100),
                'total': (line.total.total / 100),
                'total2': (line.ride.driverSettlement.total / 100),
              });
            }
          });

          jobInvoiceData.push({});

          mainResolve();
        });
      });
    });

    mainPromise.then(() => {
      const data = [
        {
          title: `invoices`,
          headers: headers,
          colWidths: [{ wch: 12 }, { wch: 30 }, { wch: 30 }, { wch: 12 }, { wch: 14 }, { wch: 14 }, { wch: 14 }],
          colFormats: ['', '', '', '',  '"' + this.company.currency + ' "#,##0.00', '"' + this.company.currency + ' "#,##0.00', '"' + this.company.currency + ' "#,##0.00'],
          rows: this.data.map((row) => {
            return {
              'id': '',
              'billable': row.billable,
              'email': row.email,
              'status': row.status,
              'subtotal': ((row.total.total - row.total.tax) / 100),
              'vat': (row.total.tax / 100),
              'total': (row.total.total / 100),
            };
          }),
        },
        {
          title: `job_invoices`,
          rowHeaders: headers,
          rows: jobInvoiceData,
          colWidths: [{ wch: 12 }, { wch: 30 }, { wch: 30 }, { wch: 42 }, { wch: 20 }, { wch: 12 }, { wch: 12 }, { wch: 12 }],
          colFormats: ['', '', '', '', '', '"' + this.company.currency + ' "#,##0.00', '"' + this.company.currency + ' "#,##0.00', '"' + this.company.currency + ' "#,##0.00'],
        }];


      this._exporter.generate(
        `${this.company.name.toLowerCase().replace(/\ /g, '_')}`,
        this.translations,
        data);
    });
  }

  loadData(): void {
    this._loadingService.register(this.loader);
    if (this.filterData.status[0] === 'all') {
      this.filterData.status = ['paid', 'final'];
    }

    let query;
    if (this.invoiceView === 'Debtor') {
      if (this.debtorId) {
        query = {
          where: {
            and: [
              {'status': {inq: this.filterData.status}},
              {billableId: this.debtorId},
              {billableType: 'Debtor'}
            ]
          },
        };
      } else {
        query = {
          where: {
            and: [
              {'status': {inq: this.filterData.status}},
              {
                or: [
                  {
                    issuerId: {
                      inq: this.searchIds
                    }
                  },
                  {
                    billableId: {
                      inq: this.searchIds
                    }
                  }
                ]
              },
              {
                or: [
                  {
                    billableType: 'Debtor'
                  },
                  {
                    billableType: 'Passenger'
                  }
                ]
              }
            ]
          },
        };
      }
    } else if (this.invoiceView === 'Contact') {
      if (this.contactId) {
        query = {
          where: {
            and: [
              {'status': {inq: this.filterData.status}},
              {billableId: this.contactId},
              {billableType: 'Passenger'}
            ]
          },
        };
      }
    } else {
      query = {
        where: {
          and: [
            {'status': {inq: this.filterData.status}},
            {
              or: [
                {
                  issuerId: {
                    inq: this.searchIds
                  }
                },
                {
                  billableId: {
                    inq: this.searchIds
                  }
                }
              ]
            },
            {
              or: [
                {
                  billableType: 'Driver'
                },
                {
                  billableType: 'Company'
                }
              ]
            }
          ]
        },
      };
    }

    if (this.type === 'export') {
      /**
       * Add exportStatus = exporting or pending
       */
      query.where = {...query.where, ...{'exportStatus': {inq: ['exporting', 'pending', 'error']}}};
    }

    this._invoiceService.getInvoices(query, this.filterData).subscribe((invoices: any[]) => {
      this.count.emit(invoices.length);
      this.formatData(invoices);
    }, error => {
      console.log(error);
    })
  }

  resetFilter(): void {
    this.filterData = {
      year: new Date().getFullYear(),
      from: 1,
      to: 12,
      status: ['all'],
      context: this.context
    };
    this.invoiceFilter.resetFilter();
  }

  formatData(invoices: any[]): void {
    // console.log(`[OpenInvoicesComponent.formatData]: invoices`, invoices);
    invoices.forEach((invoice, index) => {
      invoices[index].amount = {...invoice.total};
      invoices[index].email = (invoice.Billable ? (invoice.Billable.emailAddress ? invoice.Billable.emailAddress : invoice.Billable.email) : '');
      invoices[index].displayStatus = (invoices[index].status === 'final' ? 'unpaid' : invoices[index].status);
      invoices[index].exportStatus = invoice.exportStatus;
      invoices[index].errorCode = invoice.errorCode;

      invoices[index].issuer = invoice.Issuer ?
        (invoice.Issuer.name ? invoice.Issuer.name : `${invoice.Issuer.fname} ${invoice.Issuer.lname}`) :
        '';
      if (invoice.billableType === 'Debtor') {
        invoices[index].billable = invoice.Billable.companyName;
      } else {
        invoices[index].billable = invoice.Billable ?
          (invoice.Billable.name ? invoice.Billable.name : `${invoice.Billable.fname} ${invoice.Billable.lname}`) :
          '';
      }

      if (!this.jobDetails[invoice.id]) {
        this.jobDetails[invoice.id] = 'collapsed';
      }

      if (this.context === 'company') {
        if (invoice.issuerId === this.companyId) {
          invoices[index].debit = true;
          invoices[index].contact = invoices[index].billable;
        } else {
          invoices[index].credit = true;
          invoices[index].contact = invoices[index].issuer;
        }
      } else {
        if (invoice.issuerId === this.driver.id) {
          invoices[index].debit = true;
          invoices[index].contact = invoices[index].billable;
        } else {
          invoices[index].credit = true;
          invoices[index].contact = invoices[index].issuer;
        }
      }
    });
    // console.log(`[OpenInvoicesComponent.formatData]: formatted invoices`, invoices);

    this.data = invoices;
    this.filter(true);
  }

  search(searchTerm: string): void {
    this.searchTerm = searchTerm;
    this.filter();
  }

  sort(sortEvent: ITdDataTableSortChangeEvent): void {
    this.sortBy = sortEvent.name;
    this.sortOrder = sortEvent.order;
    this.filter(false, true);
  }

  page(pagingEvent: IPageChangeEvent): void {
    this.fromRow = pagingEvent.fromRow;
    this.currentPage = pagingEvent.page;
    this.pageSize = pagingEvent.pageSize;
    this.filter();
  }

  filter(firstLoad: boolean = false, sorting: boolean = false): void {
    let newData: any[] = this.data;

    newData = this._dataTableService.filterData(newData, this.searchTerm.trim(), true, [
      'id',
      'billableId',
      'issuerId',
      'modified'
    ]);

    if (!firstLoad && sorting) {
      if (this.sortOrder === 'DESC') {
        this.sortOrder = TdDataTableSortingOrder.Ascending;
      } else {
        this.sortOrder = TdDataTableSortingOrder.Descending;
      }
    }

    if (this.sortBy === 'created') {
      newData = UtilityService.tableDateSort(newData, 'created', this.sortOrder);
    } else {
      newData = this._dataTableService.sortData(newData, this.sortBy, this.sortOrder);
    }

    newData = this._dataTableService.pageData(newData, this.fromRow, this.currentPage * this.pageSize);

    this.filteredData = newData;
    this.filteredTotal = this.data.length;
    this.total = {
      currency: this.company.currency,
      total: 0,
    };

    this.filteredData.forEach((i) => {
      this.total = {
        currency: i.total.currency,
        total: this.total.total + i.amount.total,
      }
    })
    if (this.type === 'export') {
      this.exportCount.emit(this.filteredTotal);
    }

    this._loadingService.resolve(this.loader);
  }

  open(url: string): void {
    window.open(url, '_blank');
  }

  toggleMultiActionAll(): void {
    const self = this;
    self.filteredData.forEach((item) => {
      self.toggleMultiAction(this.type, item.id, (this.allSelected ? 'unset' : 'set'));
    });
  }

  multiAction(action, cnt): void {
    const self = this;
    let status;
    if (['invoice_process', 'delete_draft', 'pending'].includes(action)) {
      status = 'draft';
    } else if (['invoices_send_email', 'invoice_create_credit'].includes(action)) {
      status = 'paid';
    } else if (action === 'invoice_unpaid') {
      status = 'paid';
    } else if (action === 'invoice_paid') {
      status = 'final';
    }

    self._dialogService.openConfirm({
      message: self.translations[`confirm_job_${action}_multi`].replace('{cnt}', cnt),
      disableClose: false,
      title: self.translations[`confirm`].replace('{cnt}', cnt),
      cancelButton: self.translations['no'],
      acceptButton: self.translations['yes'],
    }).afterClosed()
      .subscribe((accept: boolean) => {
        if (accept) {
          let selectedRides = [];
          selectedRides = this.filteredData.filter((ride) => {
            return (self.multiSelect[status].includes(ride.id));
          });
          if (['invoices_send_email', 'invoice_create_credit'].includes(action)) {
            selectedRides = selectedRides.concat(this.filteredData.filter((ride) => {
              return (self.multiSelect['final'].includes(ride.id));
            }));
          }

          self._dialogService.open(MultiActionComponent, {
            maxHeight: '80vw',
            maxWidth: (window.innerWidth < 600 ? '100%' : '80%'),
            disableClose: true,
            data: {
              parent: self,
              context: self.context,
              action: action,
              items: selectedRides
            },
          });
        }
      });
  }

  finalizeSelectedInvoices(action, cnt): void {
    const self = this;
    let selectedRides = [];
    selectedRides = selectedRides.concat(this.filteredData.filter((ride) => {
      return (self.multiSelect[this.type].includes(ride.id));
    }));

    this._dialogService.open(FinalizeDialogComponent, {
      maxHeight: '80%',
      width: '570px',
      data: {
        parent: self,
        context: self.context,
        action: action,
        cnt: cnt,
        items: selectedRides
      }
    });
    return;
  }

  toggleMultiAction(nStatus?: string, id?: string, forceSet?: string): void {
    const self = this;
    if (!self.multiSelect) {
      self.multiSelect = {
        'draft': [],
        'pending': [],
        'final': [],
        'paid': []
      };
    }
    if (nStatus) {
      if (forceSet === 'set') {
        if (!self.multiSelect[nStatus].includes(id)) {
          self.multiSelect[nStatus].push(id);
          self.multiSelectBox[nStatus][id] = true;
          self.multiSelected = true;
        }
      } else if (forceSet === 'unset') {
        self.multiSelectBox = self.multiSelect = {
          'draft': [],
          'pending': [],
          'final': [],
          'paid': []
        };
      } else {
        if (!self.multiSelect[nStatus].includes(id)) {
          self.multiSelect[nStatus].push(id);
          self.multiSelectBox[nStatus][id] = true;
          self.multiSelected = true;
        } else {
          self.allSelected = false;
          /**
           * Remove value with id using
           */
          self.multiSelectBox[nStatus][id] = false;
          self.multiSelect[nStatus] = self.multiSelect[nStatus].filter((item) => item !== id);
        }
      }
    } else {
      Object.keys(self.multiSelectBox).forEach(function (status) {
        Object.keys(self.multiSelectBox[status]).forEach(function (key) {
          if (!self.multiSelectBox[status][key]) {
            self.multiSelect[status].push(key);
            self.multiSelected = true;
            self.multiSelectBox[status][key] = true;
          } else {
            self.allSelected = false;
            self.multiSelectBox[status][key] = false;
          }
        });
      });
    }

    self.multiSelectedState = (self.multiSelected ? 'expanded' : 'collapsed');

    if (self.multiSelect[nStatus].length > 0) {
      self[`${nStatus}Selected`] = true;
    } else {
      self[`${nStatus}Selected`] = false;
    }
  }

  reloadAllData() {
    this.draftSelected = false;
    this.processedSelected = false;
    this.paidSelected = false;
    this.finalSelected = false;
    this.allSelected = false;
    this.multiSelected = false;

    this.multiSelectedState = 'collapsed';
    this.multiSelectBox = {
      'draft': [],
      'pending': [],
      'final': [],
      'paid': []
    };
    this.multiSelect = {
      'draft': [],
      'pending': [],
      'final': [],
      'paid': []
    };
    this.loadData();
    this.reloadAllDataEmitter.emit();
  }

  resendInvoices(cnt) {
    const self = this;
    let selectedRides = [];
    selectedRides = selectedRides.concat(this.filteredData.filter((ride) => {
      return (self.multiSelect['final'].includes(ride.id));
    }));
    selectedRides = selectedRides.concat(this.filteredData.filter((ride) => {
      return (self.multiSelect['paid'].includes(ride.id));
    }));

    this._dialogService.open(ResendInvoicesDialogComponent, {
      maxHeight: '80%',
      width: '570px',
      data: {
        parent: self,
        context: self.context,
        cnt: cnt,
        items: selectedRides
      }
    });
    return;
  }

  downloadInvoices() {
    const self = this;
    this._loadingService.register();
    let selectedInvoices = [];
    selectedInvoices = this.filteredData.filter((ride) => {
      return (self.multiSelect['paid'].includes(ride.id));
    });
    selectedInvoices = selectedInvoices.concat(this.filteredData.filter((ride) => {
      return (self.multiSelect['final'].includes(ride.id));
    }));
    self._invoiceService.downloadInvoices(self.multiSelect['paid'].concat(self.multiSelect['final']), self.companyId)
      .subscribe((result: any) => {
        window.open(result.downloadUrl, '_blank');
        this._loadingService.resolve();
      }, (error) => {
        this._loadingService.resolve();
      });
  }

  switchTabEmit(tab) {
    this.switchTab.emit(tab);
  }

  toggleDetails(jobId: string): void {
    const self = this;
    self.currentOpen = jobId;

    setTimeout(function () {
      const action = (self.jobDetails[jobId] === 'collapsed' ? 'expanded' : 'collapsed');
      Object.keys(self.jobDetails).forEach((i) => {
        self.jobDetails[i] = 'collapsed';
      })
      self.jobDetails[jobId] = action;
      setTimeout(function () {
        self.currentOpenOld = `${jobId}`;
      }, 300);
    }, 200);
  }
}
