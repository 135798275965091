import {Component, Input, OnInit} from '@angular/core';
import {TdLoadingService} from '@covalent/core/loading';
import {UserService} from '../../../services/user.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../../environments/environment';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CoolLocalStorage} from '@angular-cool/storage';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../../services/auth.service';
import {ucFirst} from '../../../pipes/uc-first.pipe';
import {TdDialogService} from '@covalent/core/dialogs';
import {NavigationService} from '../../../services/navigation.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [UserService]
})
export class ResetPasswordComponent implements OnInit {
  @Input() verifyToken;
  username: string;
  token: string;
  translations: string[];
  loggedIn = false;
  message = true;
  messageLabel: string;
  messageText: string;
  showMessage = false;
  error = false;
  resetRequested = false;
  user: any;
  resetForm: UntypedFormGroup;
  formErrors: any = {
    password: '',
    passwordConfirm: ''
  };
  validationMessages = {
    'password': {
      'required': 'Password is required',
      'equals': 'Passwords don\'t match'
    },
    'passwordConfirm': {
      'required': 'Confirm password is required',
      'equals': 'Passwords don\'t match'
    }
  };

  constructor(public snackBar: MatSnackBar,
              private _router: Router,
              private _loadingService: TdLoadingService,
              private _route: ActivatedRoute,
              private _fb: UntypedFormBuilder,
              private _vault: CoolLocalStorage,
              private _authService: AuthService,
              private _userService: UserService,
              private _dialogService: TdDialogService,
              private _translateService: TranslateService,
              private _navigationService: NavigationService,
              private _titleService: Title) {
    _translateService.get([
      'reset_password_title',
      'password_updated',
      'password_reset_update_failed',
      'password_reset_update_failed_missing',
      'ok',
      'change_password',
    ]).subscribe((translations: any) => {
      this.translations = translations;
      this._titleService.setTitle(ucFirst(this.translations['welcome'] + environment.windowTitleSuffix));
      this._navigationService.setBodyTitle(ucFirst(this.translations['welcome']));
    });
  }

  ngOnInit() {
    this.token = this._route.snapshot.params['token'];
    this._route.queryParams.subscribe(params => {
      this.username = params['emailAddress'];
    });

    if (this.token) {
      this._authService.setAuthState(false);
    }
    this.user = this._vault.getObject(`${environment.vaultPrefix}.user`);
    this.loggedIn = (!!this.user);

    if (!this.loggedIn) {
      this._translateService.get((this.verifyToken ? 'set_password' : 'reset_password')).subscribe((translation: string) => {
        this._titleService.setTitle(translation + environment.windowTitleSuffix);
      });
    } else {
      this._translateService.get('change_password').subscribe((translation: string) => {
        this._titleService.setTitle(translation + environment.windowTitleSuffix);
      });
    }

    /**
     * Set up our form
     */
    this.resetForm = this._fb.group({
      password: ['', [Validators.required]],
      passwordConfirm: ['', [Validators.required]]
    });

    /**
     * Subscribe to value changes in the form
     */
    this.resetForm.valueChanges.subscribe(() => this.onValueChanged());

    /**
     * (re)set validation messages now
     */
    this.onValueChanged();
  }

  onValueChanged() {
    if (!this.resetForm) {
      return;
    }
    const form = this.resetForm;
    if (this.formErrors) {
      for (const field in this.formErrors) {
        /**
         * clear previous error message (if any)
         */
        this.formErrors[field] = '';
        const control = form.get(field);
        /**
         * Loop through the fields and check for errors, set messages accordingly
         */
        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            this.formErrors[field] += messages[key] + ' ';
          }
        }
      }
    }
  }

  confirmPassword(): void {
    const passwordCtrl: UntypedFormControl = (<any>this.resetForm).controls.password;
    const passwordConfirmCtrl: UntypedFormControl = (<any>this.resetForm).controls.passwordConfirm;

    if (passwordCtrl.value === '' || passwordConfirmCtrl.value === '') {
      return null;
    }

    const valid = (passwordCtrl.value === passwordConfirmCtrl.value);

    if (!valid) {
      // passwordCtrl.setErrors({'equals': true});
      passwordConfirmCtrl.setErrors({'equals': true});
    } else {
      // passwordCtrl.markAsPristine();
      passwordConfirmCtrl.markAsPristine();
    }

    this.onValueChanged();
  }

  requestReset(): void {
    if (!this.resetRequested) {
      this.resetRequested = true;
      this._loadingService.register('reset');
      const credentials: any = {
        'url': environment.portalUrl,
        'username': `${this.username}_${environment.companyId}`,
        'email': `${this.username}`
      };

      this._userService.requestReset(credentials).subscribe(() => {
        this._loadingService.resolve('reset');
        if (!this.loggedIn) {
          return this._router.navigate(['/login'], {queryParams: {reset: 'requested'}});
        }
        this._translateService.get('password_reset_sent').subscribe((translation) => {
          this.showMessage = true;
          this.error = false;
          this.messageText = translation;
        });
      }, error => {
        this.resetRequested = false;
        console.error(error);
        this._translateService.get('password_reset_not_sent').subscribe((translation) => {
          this.showMessage = true;
          this.error = true;
          this.messageText = translation;
        });
        this._loadingService.resolve('reset');
      });
    }
  }

  reset(): void {
    this._loadingService.register('reset');
    const data = this.resetForm.value;
    delete data.passwordConfirm;
    data.token = this.token;

    this._userService.reset(data).subscribe(() => {
      if (!this.loggedIn) {
        return this._router.navigate(['/login'], {queryParams: {reset: 'success'}});
      }
      this._translateService.get('password_reset_updated').subscribe((translation) => {
        this.showMessage = true;
        this.error = false;
        this.messageText = translation;
      });
    }, (error) => {
      if (error.status === 404) {
        this.messageText = this.translations['password_reset_update_failed_missing'];
      } else {
        this.messageText = this.translations['password_reset_update_failed'];
      }
      this.showMessage = true;
      this.error = true;
      this._loadingService.resolve('reset');
    });
  }

  update(): void {
    this._loadingService.register('reset');
    const id = this.user.id;
    const self = this;

    this._userService.update(id, {'password': this.resetForm.value.password}).subscribe(() => {
      this._loadingService.resolve('reset');
      this.resetForm.reset();
      self._dialogService.openAlert({
        message: ucFirst(self.translations['password_updated']),
        disableClose: true,
        closeButton: self.translations['ok']
      }).afterClosed().subscribe(() => {
        self._router.navigate([`/login`]);
      });
    }, error => {
      console.error(error);
      this._loadingService.resolve('reset');
    });
  }
}
