<mat-card [mediaClasses]="['push-sm']" tdMediaToggle="gt-xs">
  <mat-card-content class="push-bottom-none">
    <ng-template tdLoading="coupon-policy">
      <form *ngIf="form" [formGroup]="form">
        <div layout="row">
          <h3 class="push-top-none">
            {{(this.form.controls.name.value ? this.form.controls.name.value : 'name')|translate}}
          </h3>
        </div>

        <div flex-gt-md="25" flex-lt-md="100" layout="row">
          <mat-form-field class="push-right {{(formErrors['code'] ? 'push-bottom' : '')}}" flex>
            <input (keypress)="(formErrors.code ? this.validateFormField(this.form, 'code') : '')" formControlName="code" matInput
                   onkeyup="this.value = this.value.toUpperCase()"
                   placeholder="{{'coupon_code'|translate|ucFirst}}"
                   type="text">
            <mat-hint align="start" class="warning push-bottom">
              <span [hidden]="!formErrors.code" class="tc-red-600">
                <mat-icon color="warn">warning</mat-icon>
                <span>{{ formErrors.code }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
        </div>

        <div flex-gt-md="25" flex-lt-md="100" layout="row">
          <mat-form-field class="push-right push-bottom" flex>
            <input (keypress)="(formErrors['name'] ? this.validateFormField(this.form, 'name') : '')"
                   formControlName="name" matInput placeholder="{{'name'|translate|ucFirst}}"
                   type="text">
            <mat-hint align="start" class="warning">
              <span [hidden]="!formErrors.name" class="tc-red-600">
                <mat-icon color="warn">warning</mat-icon>
                <span>{{ formErrors.name }}</span>
              </span>
            </mat-hint>
          </mat-form-field>
        </div>

        <div flex-gt-md="50" flex-lt-md="100" layout="row" style="min-width: 530px">
          <div class="push-bottom" flex="30">
            <input formControlName="discountType" type="radio"
                   value="percentage">
            <mat-label>{{'radio_coupon_percentage_radio'|translate|ucFirst}}</mat-label>
          </div>

          <div class="push-bottom" flex="30">
            <input formControlName="discountType" max="100" min="0"
                   placeholder="{{'coupon_fixed_radio'|translate|ucFirst}}"
                   type="radio"
                   value="fixed">
            <mat-label>{{'radio_coupon_fixed_radio'|translate|ucFirst}}</mat-label>
          </div>

          <div class="push-bottom" flex="30">
            <input formControlName="discountType" max="100" min="0"
                   placeholder="{{'coupon_fixed_radio'|translate|ucFirst}}"
                   type="radio"
                   value="balance">
            <mat-label>{{'radio_coupon_credit'|translate|ucFirst}}</mat-label>
          </div>
        </div>

        <div flex-gt-md="50" flex-lt-md="100">
          <div *ngIf="this.form.controls['discountType'].value==='percentage'" class="full-width" layout="row">
            <mat-form-field flex-gt-sm="25" style="min-width: 200px;">
              <input (keypress)="(formErrors['discountPercentage'] ? this.validateFormField(this.form, 'discountPercentage') : '')" formControlName="discountPercentage" matInput
                     placeholder="{{'discount'|translate|ucFirst}}"
                     type="number">
              <span matSuffix>%</span>
              <mat-hint align="start" class="warning">
                  <span [hidden]="!formErrors['discountPercentage']" class="tc-red-600">
                    <mat-icon color="warn">warning</mat-icon>
                    <span>{{ formErrors['discountPercentage'] }}</span>
                  </span>
              </mat-hint>
            </mat-form-field>
          </div>

          <div *ngIf="this.form.controls['discountType'].value==='fixed'" class="full-width" layout="row">
            <mat-form-field flex-gt-sm="25" style="min-width: 200px;">
              <span matPrefix>{{this.currencySymbol}}&thinsp;</span>
              <mat-label>{{'radio_coupon_fixed_radio'|translate|ucFirst}}</mat-label>
              <input #discountFixedAmount (blur)="valOrZero($event.target)"
                     (focus)="valOrZero($event.target); inputFocused($event)"
                     (keypress)="(formErrors['discountFixedAmount'] ? this.validateFormField(this.form, 'discountFixedAmount') : '')"
                     (load)="valOrZero($event.target)"
                     autocomplete="off"
                     formControlName="discountFixedAmount"
                     matInput
                     min="0"
                     placeholder="0,00"
                     step='0.01'
                     type="number">
              <mat-hint align="start" class="warning">
                    <span [hidden]="!formErrors['discountFixedAmount']" class="tc-red-600">
                      <mat-icon color="warn">warning</mat-icon>
                      <span>{{ formErrors['discountFixedAmount'] }}</span>
                    </span>
              </mat-hint>
            </mat-form-field>
          </div>

          <div *ngIf="this.form.controls['discountType'].value==='balance'" class="full-width" layout="row">
            <mat-form-field flex-gt-sm="25" style="min-width: 200px;">
              <span matPrefix>{{this.currencySymbol}}&thinsp;</span>
              <mat-label>{{'coupon_balance_limit'|translate|ucFirst}}</mat-label>
              <input #discountCreditAmount (blur)="valOrZero($event.target)"
                     (focus)="valOrZero($event.target); inputFocused($event)"
                     (keypress)="(formErrors['balanceLimitByCustomerB'] ? this.validateFormField(this.form, 'balanceLimitByCustomerB') : '')"
                     (load)="valOrZero($event.target)"
                     autocomplete="off"
                     formControlName="balanceLimitByCustomerB"
                     matInput
                     min="0"
                     placeholder="0,00"
                     step='0.01'
                     type="number">
              <mat-hint align="start" class="warning">
                    <span [hidden]="!formErrors['balanceLimitByCustomerB']" class="tc-red-600">
                      <mat-icon color="warn">warning</mat-icon>
                      <span>{{ formErrors['balanceLimitByCustomerB'] }}</span>
                    </span>
              </mat-hint>
            </mat-form-field>
          </div>
        </div>

        <h3>{{'coupon_redeem_title'|translate|ucFirst}}</h3>
        <div flex-gt-md="50" flex-lt-md="100">
          <div class="full-width push-bottom" layout="row">
            <input class="push-right"
                   formControlName="limitByDate"
                   type="checkbox" value="true">
            <mat-label>{{'checkbox_coupon_limit_dates'|translate|ucFirst}}</mat-label>
          </div>

          <div *ngIf="this.form.controls['limitByDate'].value" class="full-width" layout="row">
            <mat-form-field [ngStyle]="{'width': '100%'}" class="date-input push-right">
              <input [max]="maxStartDate"
                     [min]="now"
                     formControlName="startDate"
                     matInput
                     placeholder="{{ 'start_date' | translate | ucFirst }}"
                     type="datetime-local">

              <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.startDate" class="tc-red-600">
                  <span>{{ formErrors.startDate }}</span>
                </span>
              </mat-hint>
            </mat-form-field>

            <mat-form-field [ngStyle]="{'width': '100%'}" class="date-input">
              <input [min]="minDate"
                     formControlName="endDate"
                     matInput
                     placeholder="{{ 'end_date' | translate | ucFirst }}"
                     type="datetime-local">

              <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors.endDate" class="tc-red-600">
                  <span>{{ formErrors.endDate }}</span>
                </span>
              </mat-hint>
            </mat-form-field>
          </div>
        </div>

        <div flex-gt-md="50" flex-lt-md="100">
          <div class="full-width push-bottom" layout="row">
            <input class="push-right"
                   formControlName="limitByUsage"
                   type="checkbox" value="true">
            <mat-label>{{'checkbox_usage_count_type'|translate|ucFirst}}</mat-label>
          </div>

          <div *ngIf="this.form.controls['limitByUsage'].value" class="full-width push-bottom" layout="row">
            <mat-form-field flex-gt-sm="25">
              <input (keypress)="(formErrors['usageLimit'] ? this.validateFormField(this.form, 'usageLimit') : '')" formControlName="usageLimit"
                     matInput
                     ng-pattern="/^[0-9]*$/"
                     type="number"/>
              <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors['usageLimit']" class="tc-red-600">
                  <mat-icon color="warn">warning</mat-icon>
                  <span>{{ formErrors['usageLimit'] }}</span>
                </span>
              </mat-hint>
            </mat-form-field>
            <span style="line-height: 50px;">{{'times'|translate|ucFirst}}</span>
          </div>
        </div>

        <div flex-gt-md="50" flex-lt-md="100">
          <div class="full-width push-bottom" layout="row">
            <input class="push-right"
                   formControlName="limitByCustomer"
                   type="checkbox" value="true">
            <mat-label>{{'checkbox_coupon_limit_usage_count_per_customer'|translate|ucFirst}}</mat-label>
          </div>

          <div *ngIf="this.form.controls['limitByCustomer'].value" class="full-width push-bottom" layout="row">
            <mat-form-field flex-gt-sm="25">
              <input (keypress)="(formErrors['usageLimitPerCustomer'] ? this.validateFormField(this.form, 'usageLimitPerCustomer') : '')" formControlName="usageLimitPerCustomer"
                     matInput
                     ng-pattern="[0-9]*$/"
                     type="number"/>
              <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors['usageLimitPerCustomer']" class="tc-red-600">
                  <mat-icon color="warn">warning</mat-icon>
                  <span>{{ formErrors['usageLimitPerCustomer'] }}</span>
                </span>
              </mat-hint>
            </mat-form-field>
            <span style="line-height: 50px;">{{'times'|translate|ucFirst}}</span>
          </div>
        </div>

        <div *ngIf="(this.form.controls['discountType'].value !== 'balance')" flex-gt-md="50" flex-lt-md="100">
          <div class="full-width push-bottom" layout="row">
            <input class="push-right"
                   formControlName="LimitByBalanceByCustomer"
                   type="checkbox" value="true">
            <mat-label>{{'checkbox_coupon_limit_by_balance_per_customer'|translate|ucFirst}}</mat-label>
          </div>

          <div *ngIf="this.form.controls['LimitByBalanceByCustomer'].value" class="full-width" layout="row">
            <mat-form-field class="{{(formErrors['balanceLimitByCustomer'] ? 'push-bottom' : '')}}" flex-gt-sm="25"
                            style="min-width: 200px;">
              <span matPrefix>{{this.currencySymbol}}&thinsp;</span>
              <mat-label>{{'coupon_balance_limit'|translate|ucFirst}}</mat-label>
              <input #discountFixedAmount (blur)="valOrZero($event.target)"
                     (focus)="valOrZero($event.target); inputFocused($event)"
                     (keypress)="(formErrors['balanceLimitByCustomer'] ? this.validateFormField(this.form, 'balanceLimitByCustomer') : '')"
                     (load)="valOrZero($event.target)"
                     autocomplete="off"
                     formControlName="balanceLimitByCustomer"
                     matInput
                     min="0"
                     placeholder="0,00"
                     step='0.01'
                     type="number">
              <mat-hint align="start" class="warning">
                <span [hidden]="!formErrors['balanceLimitByCustomer']" class="tc-red-600">
                  <mat-icon color="warn">warning</mat-icon>
                  <span>{{ formErrors['balanceLimitByCustomer'] }}</span>
                </span>
              </mat-hint>
            </mat-form-field>
          </div>
        </div>
      </form>
    </ng-template>
  </mat-card-content>
  <mat-divider></mat-divider>
  <mat-card-actions>
    <button (click)="save()"
            color="accent"
            flex
            mat-raised-button>{{'save'|translate|ucFirst}}</button>
    <button (click)="delete()"
            *ngIf="action !== 'add'"
            class="float-right"
            color="warn"
            flex
            mat-raised-button>{{'delete_coupon_button'|translate|ucFirst}}</button>
  </mat-card-actions>
</mat-card>
