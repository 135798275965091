import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthService} from '../auth.service';
import {LocationPolicy} from '../../models/tps/location-policy';

const locationModel = 'LocationPolicies';
const base = environment.tpsBaseUrl;

@Injectable()
export class LocationPolicyService {

  constructor(
    private _http: HttpClient,
    private _auth: AuthService,
  ) {
  }

  getAll = (filter = {}): Observable<LocationPolicy[]> =>
    this._http.get<LocationPolicy[]>(
      `${base}/${locationModel}?filter=${JSON.stringify(filter)}`,
      {headers: this.getHeaders()},
    );

  get = (id: string, filter?: Object): Observable<LocationPolicy> =>
    this._http.get<LocationPolicy>(
      `${base}/${locationModel}/${id}?filter=${JSON.stringify(filter)}`,
      {headers: this.getHeaders()},
    );

  insert = (location: LocationPolicy): Observable<LocationPolicy> =>
    this._http.post<LocationPolicy>(
      `${base}/${locationModel}`, location,
      {headers: this.getHeaders()},
    );

  update = (id: string, location: {}): Observable<LocationPolicy> =>
    this._http.patch<LocationPolicy>(
      `${base}/${locationModel}/${id}`, location,
      {headers: this.getHeaders()},
    );

  delete = (id: string): Observable<{ count: boolean }> =>
    this._http.delete<{ count: boolean }>(
      `${base}/${locationModel}/${id}`,
      {headers: this.getHeaders()},
    );

  addToDaAppInstall = (id: string, daAppInstallId: string): Observable<LocationPolicy> =>
    this._http.post<any>(
      `${base}/${locationModel}/${id}/addToDaAppInstall`,
      {daAppInstallId},
      {headers: this.getHeaders()},
    );

  removeFromDaAppInstall = (id: string, daAppInstallId: string): Observable<LocationPolicy> =>
    this._http.post<any>(
      `${base}/${locationModel}/${id}/removeFromDaAppInstall`,
      {daAppInstallId},
      {headers: this.getHeaders()},
    );

  private getHeaders = () => this._auth.getJWTHeaders();
}
